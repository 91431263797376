<template>
  <form @submit.prevent="doSubmit">
    <KTextField
      :validator="$v.form.oldPassword"
      autocomplete="current-password"
      label="forms.passwordReset.oldPassword_title"
      :type="hidePassword ? 'password' : 'text'"
      v-model="form.oldPassword"
    >
      <template v-slot:append>
        <FaI :icon="hidePassword ? 'eye' : 'eye-slash'" @click="hidePassword = !hidePassword" fixed-width/>
      </template>
    </KTextField>

    <KTextField
      :validator="$v.form.password"
      autocomplete="new-password"
      label="forms.passwordReset.password_title"
      :type="hidePassword ? 'password' : 'text'"
      :value="form.password"
      :loading="$v.form.password.$pending"
      @input="debouncedPasswordInput"
    >
      <template v-slot:append>
        <FaI :icon="hidePassword ? 'eye' : 'eye-slash'" @click="hidePassword = !hidePassword" fixed-width/>
      </template>
    </KTextField>

    <PasswordTestFeedback :password="form.password" :email="email"/>

    <KTextField
      :validator="$v.form.passwordConfirmation"
      autocomplete="new-password"
      label="forms.passwordReset.passwordConfirmation_title"
      :type="hidePassword ? 'password' : 'text'"
      v-model="form.passwordConfirmation"
    >
      <template v-slot:append>
        <FaI :icon="hidePassword ? 'eye' : 'eye-slash'" @click="hidePassword = !hidePassword" fixed-width/>
      </template>
    </KTextField>

    <KBtnSubmit :mode="mode" class="mt-3">
      <span v-t="'forms.passwordChange.title'"/>
    </KBtnSubmit>

  </form>
</template>

<script>
  import FormMixin from 'forms/elements/FormMixin.js'
  import Queries from 'queries/index.js'
  import PasswordTestFeedback from "./PasswordTestFeedback.vue";
  import {KBtnSubmit, KTextField} from "forms/elements/index.js"
  import {mapState} from "vuex";
  import {passwordTest, required, sameAs, maxLength} from 'validators/index.js'
  import {debounce} from "lodash-es";
  import FormModes from "forms/elements/FormModes.js";

  export default {
    mixins: [
      FormMixin,
    ],

    components: {
      KBtnSubmit,
      KTextField,
      PasswordTestFeedback,
    },

    data: () => ({
      hidePassword: true,
      form: {
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
      },
    }),

    validations() {
      return {
        form: {
          oldPassword: {
            required,
            maxLength: maxLength(100),
          },
          password: {
            required,
            // minLength: minLength(6),
            maxLength: maxLength(100),
            passwordTest: passwordTest(() => this.email),
          },
          passwordConfirmation: {
            required,
            sameAs: sameAs('password'),
          },
        },
      };
    },

    computed: {
      ...mapState('session', ['me']),

      email() {
        return this.me.email;
      }
    },

    methods: {
      debouncedPasswordInput: debounce(
        function (v) {
          return this.form.password = v;
        }, 500, {maxWait: 2000}
      ),

      async createAction() {
        const {form: {oldPassword, password, passwordConfirmation}} = this;
        const variables = {
          oldPassword,
          password,
          passwordConfirmation,
        };

        return await this.$apollo.mutate({
          mutation: Queries.Auth.Password.Update,
          variables,
        });
      },
    },

    created() {
      this.$on('reset', () => {
        Object.keys(this.form).forEach((key) => {
          this.form[key] = '';
        });
      });
    },
  }
</script>
