import Vue from 'vue'
import VueApollo from 'vue-apollo'
import store from 'store/index.js'
import {ApolloLink, HttpLink, ApolloClient, InMemoryCache, from, split} from '@apollo/client/core'
import {createUploadLink} from 'apollo-upload-client'
import {BatchHttpLink} from '@apollo/client/link/batch-http'
import {onError} from '@apollo/client/link/error'
import {possibleTypes} from './fragmentTypes.json'
import subscriptionLink from "./subscriptionLink.js";
import cacheRedirectFunctions from "./cacheRedirectFunctions.js";

const AUTH_TOKEN = 'auth-token';
// const API_BATCH_SIZE = 'api-batch-size';

// const batchMax = Math.min(localStorage.getItem(API_BATCH_SIZE) ?? 10, 20);

// HTTP connection to the API
// File uploads cannot be batched; this will batch queries which do not include a file upload
const uri = '/graphql';
const httpLink = split(
  (op) => op.getContext().hasUpload,
  createUploadLink({
    uri,
  }),
  split((op) => op.getContext().batch === false,
    new HttpLink({
      uri,
    }),
    new BatchHttpLink({
      uri,
      batchMax: 10,
    }),
  ),
);

const authorization = () => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return token ? `Bearer ${token}` : null;
};

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: authorization(),
    },
  }));

  return forward(operation);
});

const mergePolicies = [
  'NameParsed',
  'ProjectMeta',
  'ProjectSettings',
  'ProjectRecordDeviceCount',
  'ProjectRecordClosedScopeFinancials',
  'ProjectRecordOpenScopeFinancials',
  'ProjectRecordSales',
  'ProjectRecordInternal',
  'ProjectRecordLost',
  'ProjectRecordActive',
  'ProjectRecordHold',
  'ProjectRecordComplete',
  'UserSettings',
].reduce((accumulator, item) => {
  accumulator[item] = {merge: true};
  return accumulator;
}, {});

// Cache implementation
const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    ...mergePolicies,
    Query: {
      fields: {
        ...cacheRedirectFunctions,
      }
    }
  },
});

const errorLink = onError(({ graphQLErrors, networkError}) => {
  if (networkError) {
    console.log(`[Network Error]: ${networkError}`);
  }

  if (graphQLErrors) {
    let reportError = false;

    for (let err of graphQLErrors) {
      if (err.debugMessage === 'Unauthenticated.') { // temporary
        store.dispatch('flash/danger', 'Your session has expired. Please sign in again.');
        // set auth error in state
        store.commit('session/setAuthError', err);
        // purge local storage
        store.dispatch('session/clearLocalStorage');
      } else if (err?.extensions?.category === 'internal') {
        reportError = true;
        console.error('Server internal error', err);
      } else if (err?.extensions?.category !== 'authentication') {
        reportError = true;
        console.error('Unhandled API error', err);
      }
    }

    if (reportError) {
      store.dispatch('flash/danger', 'The server generated an internal error. If this issue persists please report to an administrator.');
    }
  }
});

const echoSubscriptionLink = new subscriptionLink();

const link = from([
  authMiddleware,
  errorLink,
  echoSubscriptionLink,
  httpLink,
]);

// Create the apollo client
export const defaultClient = new ApolloClient({
  link,
  cache,
});

const apolloProvider = new VueApollo({
  defaultClient,
});

Vue.use(VueApollo);

export const client = defaultClient;
export const provider = apolloProvider;
