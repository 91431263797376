<template>
  <form @submit.prevent="doSubmit">
    <VRow align="center">
      <VCol cols="12" sm="10">
        <KSuggest
            model="user"
            label="models.user.self"
            v-model="form.userId"
            :validator="$v.form.userId"
        />
      </VCol>
      <VCol cols="12" sm="2">
        <KBtnSubmit :mode="mode"/>
      </VCol>
    </VRow>
  </form>
</template>

<script>
import FormMixin from "forms/elements/FormMixin.js";
import Queries from "queries/index.js";
import {KBtnSubmit, KSelect, KSuggest} from "forms/elements/index.js";
import {required, primaryKey} from "validators/index.js";

export default {
  mixins: [
    FormMixin,
  ],

  components: {
    KBtnSubmit,
    KSelect,
    KSuggest,
  },

  data: () => ({
    form: {
      userId: undefined,
    },
  }),

  props: {
    id: {
      type: [Number, String],
      required: true,
    },

    roleId: [Number, String],
  },

  validations: {
    form: {
      userId: {
        required,
        primaryKey,
      },
    },
  },

  methods: {
    async createAction() {
      const {id, roleId: role, form: {userId: user}} = this,
          {data: {teamAttachUsers: response}} = await this.$apollo.mutate({
            mutation: Queries.Team.Write.AttachUsers,
            variables: {
              id,
              input: [{
                user,
                role,
              }],
            },
          });

      return response;
    },

    formReset() {
      this.form.userId = undefined;
    },
  },

  created() {
    this.$on('success', this.formReset);
  },
}
</script>
