<template>
  <KBottomNavigation
    :nav-links="navLinks"
  />
</template>

<script>
  import {KBottomNavigation} from "elements/index.js";

  export default {
    components: {
      KBottomNavigation,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      slug: {
        type: String,
        required: true,
      },
    },

    computed: {
      navLinks() {
        const {id, slug} = this,
          params = {id, slug};

        return [
          {
            exact: true,
            to: {name: 'performance', params},
            message: 'performance.main.title',
            icon: 'plus',
          },
          {
            to: {name: 'performance.setup', params},
            message: 'performance.setup.title',
            icon: 'gear',
          },
        ];
      },
    },
  }
</script>
