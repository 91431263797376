<template>
  <div>
    <VBtn v-if="canBillingRateUpdate" class="mr-1" icon small @click="$emit('update', id)">
      <FaI icon="pen" size="sm"/>
    </VBtn>
    <ClickConfirm v-if="canBillingRateDelete" icon small @confirm="$emit('delete', id)">
      <FaI icon="trash" size="sm"/>
    </ClickConfirm>
  </div>
</template>

<script>
import ClickConfirm from '../../../elements/ClickConfirm.vue';

export default {
  components: {
    ClickConfirm,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  apollo: {
    canBillingRateUpdate() {
      return this.policyChecker({
        policy: 'update',
        type: 'BillingRate',
        id: this.id,
      });
    },

    canBillingRateDelete() {
      return this.policyChecker({
        policy: 'delete',
        type: 'BillingRate',
        id: this.id,
      });
    },
  },
}
</script>
