const TIMEOUT_DEFAULT = 5000;

const MESSAGE_DEFAULT = {
    text: 'Alert',
    type: 'info',
    timeout: TIMEOUT_DEFAULT
};

const MAX_MESSAGES = 5;

const data = {
    namespaced: true,

    state: {
        counter: 0,
        messages: []
    },

    getters: {
        messages: (state) => state.messages,

        message: (state) => (id) => state.messages.find((e) => e.id === id)
    },

    mutations: {
        incrementCounter(state) {
            state.counter++;
        },

        /**
         * @param state
         * @param {String|Object} message
         */
        addMessage(state, message) {
            state.messages.push(message);
        },

        /**
         * @param state
         * @param {Number} key
         */
        destroyMessage(state, key) {
            state.messages.splice(key, 1);
        },
    },

    actions: {
        add({dispatch, commit, state}, message) {
            commit('incrementCounter');
            const buffer = Object.assign({}, MESSAGE_DEFAULT, typeof message === 'string' ? {text: message} : message, {id: state.counter});
            commit('addMessage', buffer);

            // Limit the number of displayed flash messages
            if (state.messages.length > MAX_MESSAGES) {
                const {id: oldestId} = state.messages.reduce((res, obj) => obj.id < res.id ? obj : res);
                dispatch('destroy', oldestId);
            }

            if (buffer.timeout > 0) {
                setTimeout(() => {
                    dispatch('destroy', buffer.id);
                }, buffer.timeout);
            }
        },

        destroy({commit, state}, id) {
            const target = state.messages.findIndex((e) => e.id === id);
            if (target >= 0) {
                commit('destroyMessage', target);
            }
        },

        success({dispatch}, text) {
            dispatch('add', Object.assign({type: 'success'}, typeof text === 'string' ? {text} : text));
        },

        info({dispatch}, text) {
            dispatch('add', Object.assign({type: 'info'}, typeof text === 'string' ? {text} : text));
        },

        warning({dispatch}, text) {
            dispatch('add', Object.assign({type: 'warning'}, typeof text === 'string' ? {text} : text));
        },

        error({dispatch}, text) {
            dispatch('add', Object.assign({type: 'error'}, typeof text === 'string' ? {text} : text));
        },

        // Alias (from Bootstrap)
        danger({dispatch}, text) {
            dispatch('error', text);
        },
    },
};

export default data;