import {req, ref, withParams } from 'vuelidate/lib/validators/common'
import {client} from 'api/index.js'
import Queries from 'queries/index.js'

export default (email, score = 4) =>
  withParams({type: 'passwordTest', email, score}, async (password, parentVm) => {
    if (!req(password)) {
      return true;
    }

    const emailValue = ref(email, this, parentVm);
    const response = await client.query({
      query: Queries.Auth.Password.Test,
      variables: {
        password,
        email: emailValue,
      },
      // fetchPolicy: 'network-only',
    });
    const result = response?.data?.passwordTest?.score;

    return Boolean(result && result >= score);
    }
  )
