import gql from 'graphql-tag';
import User from './user.js';
import ThumbnailFragment from "./thumbnailFragment.js";

// Fragments

const MembersDisplayFragment = gql`
  fragment teamMembersDisplayFragment on Team {
    id
    roleGroups {
      id
      role {
        id
        name
        displayName
        displayNamePlural
      }
      users {
        ...userThumbnailFragment
      }
#      permissions(policies: [
#        {policy: "attachUser"}
#        {policy: "detachUser"}
#        {policy: "join"}
#        {policy: "leave"}
#      ]) {
#        id
#        allowed
#      }
    }
  }
  ${ThumbnailFragment}
`;

// Single

const Main = gql`
  query(
    $id: ID!
  ) {
    team(
      id: $id
    ) {
      id
      name
      displayName
      context {
        id
        __typename
      }
    }
  }
`;

const Members = gql`
  query(
    $id: ID!
  ) {
    team(
      id: $id
    ) {
      ...teamMembersDisplayFragment
    }
  }
  ${MembersDisplayFragment}
`;

const Roles = gql`
  query(
    $id: ID!
  ) {
    team(
      id: $id
    ) {
      id
      roles {
        id
        name
        displayName
      }
    }
  }
`;


// Write

const AttachUsers = gql`
  mutation(
    $id: ID!
    $input: [TeamAttachUserInput!]!
  ) {
    teamAttachUsers(
      id: $id
      input: $input
    ) {
      id
      users {
        ...userThumbnailFragment
      }
    }
  }
  ${ThumbnailFragment}
`;

const DetachUsers = gql`
  mutation(
    $id: ID!
    $input: [TeamDetachUserInput!]!
  ) {
    teamDetachUsers(
      id: $id
      input: $input
    ) {
      id
      users {
        id
      }
    }
  }
`;


// Export

const Fragments = {
  MembersDisplay: MembersDisplayFragment,
};

const Single = {
  Main,
  Members,
  Roles,
};

const Write = {
  AttachUsers,
  DetachUsers,
};

export default {
  Fragments,
  Single,
  Write,
};
