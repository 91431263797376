<template>
  <NowLoading v-if="$apollo.loading"/>
  <div v-else>
    <VToolbar dense>
      <VAppBarNavIcon v-if="isMobile" @click="drawerActivated = !drawerActivated"/>
      <VToolbarTitle>
        <small>
          <span v-t="{path: 'models.company.self', choice: 1}"/>:
        </small>
        <span class="text-truncate">
          {{company.name}}
        </span>
      </VToolbarTitle>
    </VToolbar>
    <VNavigationDrawer clipped v-model="showDrawer" :permanent="!isMobile" :absolute="isMobile" :app="!isMobile" :clipped="!isMobile" color="grey lighten-3">
      <VList nav>
        <VListItem v-for="{name, title, icon} in navItems" :key="name" :to="{name, params: {slug, id}}" exact>
          <VListItemIcon>
            <FaI :icon="icon"/>
          </VListItemIcon>
          <VListItemContent v-t="title"/>
        </VListItem>
      </VList>
    </VNavigationDrawer>
    <KeepAlive>
      <RouterView/>
    </KeepAlive>
  </div>
</template>

<script>
import Queries from 'queries/index.js';

export default {
  data: () => ({
    drawerActivated: false,
  }),

  props: {
    id: {
      type: [String, Number],
      required: true,
    },

    slug: {
      type: String,
      required: true,
    },
  },

  apollo: {
    company: {
      query: Queries.Company.Single.Main,
      variables() {
        return {
          id: this.id,
        };
      },
    },

    canView() {
      return this.policyChecker({
        policy: 'view',
        type: 'Company',
        id: this.id,
      });
    },

    // canProjectViewAny() {
    //   return this.policyChecker({
    //     policy: 'projectViewAny',
    //     type: 'Company',
    //     id: this.id,
    //   });
    // },

    canInvoiceViewAny() {
      return this.policyChecker({
        policy: 'invoiceViewAny',
        type: 'Company',
        id: this.id,
      });
    },
  },

  computed: {
    navItems() {
      const {canView, canInvoiceViewAny} = this;
      const payload = [];

      if (canView) {
        payload.push({
          name: 'client',
          title: 'ui.overview',
          icon: 'briefcase',
        });
      }

      // if (canProjectViewAny) {
      //   payload.push({
      //     name: 'client.projects',
      //     title: {path: 'models.projects.self', choice: 2},
      //     icon: 'briefcase',
      //   });
      // }

      if (canInvoiceViewAny) {
        payload.push({
          name: 'client.invoicing',
          title: {path: 'models.invoice.self', choice: 2},
          icon: 'file-invoice-dollar',
        });
      }

      return payload;
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    showDrawer: {
      set(v) {
        this.drawerActivated = v;
      },
      get() {
        return !this.isMobile || this.drawerActivated;
      }
    },
  },

  watch: {
    isMobile(v) {
      this.drawerActivated = false;
    },
  },
}
</script>
