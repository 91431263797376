<template>
  <VContainer>
    <ListBrowser :title="{path: 'models.project.self', choice: 2}" query="Project.Browse.List" :create-route="canCreate ? {name: 'projectCreate'} : undefined">
      <template #filter="{setVariables, setIsFiltered, showFilter}">
        <ProjectTeamMemberToggle @input="filterDefaults = $event"/>
        <ProjectsFilter @input="setVariables" :defaults="filterDefaults" :disables="filterDisables" @filtered="setIsFiltered" :show-filter="showFilter"/>
      </template>
      <template #item="{item}">
        <ProjectListItem :id="item.id" :key="item.id"/>
      </template>
    </ListBrowser>
  </VContainer>
</template>

<script>
  import ListBrowser from "elements/list/ListBrowser.vue";
  import ProjectsFilter from "./elements/ProjectsFilter.vue";
  import ProjectListItem from "./elements/ProjectListItem.vue";
  import ProjectTeamMemberToggle from "./elements/ProjectTeamMemberToggle.vue";

  export default {
    components: {
      ListBrowser,
      ProjectsFilter,
      ProjectListItem,
      ProjectTeamMemberToggle,
    },

    data: () => ({
      filterDefaults: {},
    }),

    apollo: {
      canCreate() {
        return this.policyChecker({
          policy: 'create',
          type: 'Project',
        });
      },
    },

    computed: {
      filterDisables() {
        const payload = {};
        Object.keys(this.filterDefaults).forEach((key) => {
          payload[key] = true;
        });

        return payload;
      }
    },
  }
</script>
