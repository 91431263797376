import fontAwesome from './fontAwesome.js'
import policyChecker from "./policyChecker.js";
import {NowLoading} from 'elements/index.js'

export default {
  components: {
    ...fontAwesome,
    NowLoading,
  },

  methods: {
    getRandomId() {
      return Math.floor(Math.random() * 2147483648);
    },

    parseDisplayMessage(message) {
      if (message === false || message === null || message === undefined) {
        return undefined;
      }

      if (typeof message === 'string') {
        if (message.includes('.') && !message.includes(' ')) {
          return this.$tc(message);
        }

        return message;
      }

      return this.$tc(message.path, message.choice);
    },

    policyChecker,
  }
}
