<template>
  <Home v-if="isSignedIn"/>
  <Greeter v-else/>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Greeter from './top/Greeter.vue'
  import Home from './top/Home.vue'

  export default {
    components: {
      Home,
      Greeter,
    },

    computed: {
      ...mapGetters('session', ['isSignedIn']),
    },
  }
</script>
