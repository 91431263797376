import gql from 'graphql-tag';
import PaginatorFragment from './paginatorFragment.js'

// Fragments

const Fragment = gql`
  fragment partitionsSetFragment on PartitionsSet
  {
    id
    description
    partitions {
      id
      isTimeslipBillable
      isExpenseBillable
      comment
      weight
      project {
        id
        slug
        name
      }
    }
  }
`;


// Browse

const List = gql`
  query(
    $search: String
    $user: ID
    $projects: [ID!]
    $orderBy: [QueryPartitionsSetsOrderByOrderByClause!]
    $first: Int!
  ) {
    partitionsSets(
      search: $search
      user: $user
      projects: $projects
      orderBy: $orderBy
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        ...partitionsSetFragment
      }
    }
  }
  ${PaginatorFragment}
  ${Fragment}
`;


// Forms

const Search = gql`
  query(
    $search: String!
    $user: ID!
    $first: Int!
  ) {
    partitionsSetSuggestions(
      search: $search
      user: $user
      first: $first
    ) {
      data {
        ...partitionsSetFragment
      }
    }
  }
  ${Fragment}
`;


// Single

const Main = gql`
  query(
    $id: ID!
  ) {
    partitionsSet(
      id: $id
    ) {
      ...partitionsSetFragment
    }
  }
  ${Fragment}
`;

const IDFromContext = gql`
  query partitionsSetIdFromContext(
    $id: ID!
    $context: PartitionsSetContext!
  ) {
    partitionsSetFromActivity(
      id: $id
      context: $context
    ) {
      id
    }
  }
`;

const ExpansionPanelHeader = gql`
  query(
    $id: ID!
  ) {
    partitionsSet(
      id: $id
    ) {
      id
      description
      partitions {
        id
        isTimeslipBillable
        isExpenseBillable
        weight
        project {
          id
        }
      }
    }
  }
`;

const ExpansionPanelContent = gql`
  query(
    $id: ID!
  ) {
    partitionsSet(
      id: $id
    ) {
      id
      description
      partitions {
        id
        isTimeslipBillable
        isExpenseBillable
        comment
        weight
        project {
          id
          slug
          name
        }
      }
      createdAt
      updatedAt
    }
  }
`;


// Write

const Create = gql`
  mutation(
    $description: String!
    $partitions: [PartitionInput!]!
  ) {
    partitionsSetCreate(
      input: {
        description: $description
        partitions: $partitions
      }
    ) {
      ...partitionsSetFragment
    }
  }
  ${Fragment}
`;

const Update = gql`
  mutation(
    $id: ID!
    $description: String
    $partitions: [PartitionInput!]
  ) {
    partitionsSetUpdate(
      id: $id
      input: {
        description: $description
        partitions: $partitions
      }
    ) {
      ...partitionsSetFragment
    }
  }
  ${Fragment}
`;

const Delete = gql`
  mutation(
    $id: ID!
  ) {
    partitionsSetDelete(
      id: $id
    ) {
      id
    }
  }
`;


// Export

const Browse = {
  List,
};

const Forms = {
  Search,
  Populate: Main,
};

const Single = {
  Main,
  IDFromContext,
  ExpansionPanelHeader,
  ExpansionPanelContent,
};

const Write = {
  FormFill: Main,
  Create,
  Update,
  Delete,
};

export default {
  Browse,
  Forms,
  Single,
  Write,
};
