import KFormElementMixin from "./KFormElementMixin.js";
import KTimeZonePicker from "./KTimeZonePicker.vue";
import {addDays, isBefore, isMatch, parse, startOfDay} from "date-fns";
import {toDate, utcToZonedTime} from "date-fns-tz";
import {format, formatISODate} from "top/date-fns-format.js";
import {mapGetters, mapState} from "vuex";
import Queries from "queries/index.js";

const dateParser = (value, timeZone) => (value instanceof Date) ? format(utcToZonedTime(value, timeZone), 'HH:mm') : value;

const defaultTimeFormats = {
  'en-CA': 'ampm',
  'fr-CA': '24hr',
};

const timeHoursRegex = /^[^:]*/;
const timeMinutesRegex = /[^:]*$/;

export default {
  mixins: [
    KFormElementMixin,
  ],

  components: {
    KTimeZonePicker,
  },

  data: () => ({
    textFieldValue: undefined,
    localTimeZone: undefined,
    timeFormat: 'default',
    locale: 'en-CA',
  }),

  props: {
    value: {
      type: [String, Object],
      required: false,
      validator: (v) => v instanceof Date || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v), // 24 clock format with optional leading digit
    },

    getDateObject: Boolean,

    pivot: {
      type: Object,
      default: () => startOfDay(new Date()),
      validator: (v) => v instanceof Date,
    },

    clearable: Boolean,

    timeZone: [String, Boolean],

    min: [String, Object],

    max: [String, Object],
  },

  apollo: {
    timeFormat: {
      query: Queries.User.Single.TimeFormat,
      variables() {
        return {
          id: this.meId,
        };
      },
      update: ({user}) => user?.settings?.timeFormat || 'default',
      skip() {
        return !this.meId;
      },
    },

    locale: {
      query: Queries.User.Single.Locale,
      variables() {
        return {
          id: this.meId,
        };
      },
      update: ({user}) => user?.settings?.locale || 'en-CA',
      skip() {
        return !this.meId;
      },
    },
  },

  computed: {
    ...mapState('session', {sessionTimeZone: 'timeZone'}),

    ...mapGetters('session', ['meId']),

    parsedTimeFormat() {
      const {locale, timeFormat} = this;
      return timeFormat !== 'default' ? timeFormat : defaultTimeFormats[locale];
    },

    usingAmPm() {
      return this.parsedTimeFormat === 'ampm';
    },

    parsedInput() {
      const {localTimeZone, value} = this;
      return dateParser(value, localTimeZone);
    },

    minParsed() {
      const {localTimeZone, min} = this;
      return dateParser(min, localTimeZone);
    },

    maxParsed() {
      const {max, localTimeZone} = this;
      return dateParser(max, localTimeZone);
    },

    showTimeZonePicker() {
      return Boolean(this.timeZone);
    },

    pickerDefault() {
      return this.parsedInput;
    },

    textFieldDefault() {
      return this.parsedInput && this.parsedTimeFormat === 'ampm' ? format(parse(this.parsedInput, 'HH:mm', new Date()), 'p') : this.parsedInput;
    },

    isOnMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    valueHour() {
      return this.parsedInput ? parseInt(timeHoursRegex.exec(this.parsedInput)[0]) : undefined;
    },

    minHour() {
      return this.minParsed ? parseInt(timeHoursRegex.exec(this.minParsed)[0]) : undefined;
    },

    maxHour() {
      return this.maxParsed ? parseInt(timeHoursRegex.exec(this.maxParsed)[0]) : undefined;
    },

    allowedHours() {
      const {minHour, maxHour} = this;

      if (!minHour && !maxHour) {
        return undefined;
      }

      if (!maxHour) {
        return (v) => v >= minHour;
      }

      if (!minHour) {
        return (v) => v <= maxHour;
      }

      if (minHour > maxHour) {
        return (v) => v >= minHour || v <= maxHour;
      }

      return (v) => v >= minHour && v <= maxHour;
    },

    allowedMinutes() {
      const {valueHour, minHour, maxHour, minParsed, maxParsed} = this;

      if (!minParsed && !maxParsed) {
        return undefined;
      }

      if (minHour === maxHour) {
        const minMatch = parseInt(timeMinutesRegex.exec(minParsed)[0]);
        const maxMatch = parseInt(timeMinutesRegex.exec(maxParsed)[0]);
        return (v) => v >= minMatch && v <= maxMatch;
      }

      if (valueHour === minHour) {
        const minMatch = parseInt(timeMinutesRegex.exec(minParsed)[0]);
        return (v) => v >= minMatch;
      }

      if (valueHour === maxHour) {
        const maxMatch = parseInt(timeMinutesRegex.exec(maxParsed)[0]);
        return (v) => v <= maxMatch;
      }

      return (v) => true;
    },
  },

  watch: {
    timeZone: {
      handler(v) {
        this.localTimeZone = typeof v === 'string' ? v : this.sessionTimeZone;
      },
      immediate: true,
    },

    localTimeZone(v) {
      if (typeof this.timeZone === 'string') {
        this.$emit('update:time-zone', v);
      }
    },

    textFieldDefault: {
      handler(v) {
        this.textFieldValue = v;
      },
      immediate: true,
    },

    pivot(v) {
      this.onPickerInput(this.pickerDefault);
    },
  },

  methods: {
    onPickerInput(v) {
      this.onInput(this.parseResponse(v));
    },

    parseResponse(v) {
      const {getDateObject, pivot, localTimeZone} = this;

      if (!v) {
        return undefined;
      }

      if (!getDateObject) {
        return v;
      }
      const dateString = formatISODate(pivot || new Date());

      let response = toDate(`${dateString}T${v}`, {timeZone: localTimeZone});
      if (pivot && isBefore(response, pivot)) {
        response = addDays(response, 1);
      }

      return response;
    },

    onTextFieldInput(v) {
      const {clearable, usingAmPm} = this;
      if (!v) {
        this.textFieldValue = undefined;
        this.onPickerInput(undefined);
        return;
      }

      let numerical = v.replace(/^0+|[^\d]/g, '').substring(0,4);
      const length = numerical.length;
      let hours, minutes;
      if (length === 2) {
        hours = parseInt(numerical[0]);
        minutes = parseInt(numerical[1]);
      } else if (length === 3 || length === 4) {
        hours = parseInt(numerical.substring(0, length-2));
        minutes = parseInt(numerical.substring(length-2));
      } else {
        this.textFieldValue = numerical;
        return;
      }

      let suffix = '';
      if (length >= 3) {
        if (usingAmPm) {
          //let [hours, minutes] = output.split(':', 2).map((e) => parseInt(e));
          if (hours === 0) {
            hours = 12;
            suffix = ' AM';
          } else if (hours > 12) {
            hours -= 12;
            suffix = ' PM';
          } else {
            const am = v.search(/[aA-]/);
            const pm = v.search(/[pP+]/);

            suffix = pm > am ? ' PM' : ' AM';
          }
        }

        minutes = minutes.toString().padStart(2, '0');
      }

      const payload = `${hours}:${minutes}${suffix}`;
      this.textFieldValue = payload;

      const style = usingAmPm ? 'p' : 'H:mm';

      if (length >= 3 && isMatch(payload, style)) {
        this.onPickerInput(format(parse(payload, style, new Date()), 'HH:mm'));
      }
    },

    onChange(v) {
      this.$emit('change', this.parseResponse(v));
    },

    onFocus(event) {
      if (!this.isOnMobile) {
        event.target.select();
      }
    },

    onBlur() {
      this.textFieldValue = this.textFieldDefault;
      this.doTouch();
    },

    onClear() {
      this.onChange(undefined);
      this.onPickerInput(undefined);
    },
  },
}
