<template>
  <VExpansionPanelHeader ripple>
    <VSkeletonLoader type="list-item" v-if="$apollo.loading"/>
    <VRow align="center" no-gutters v-else style="max-width: 100%; padding-right: 28px; margin-right: -24px">
      <VCol class="mr-2" cols="auto" v-if="!simple">
        <UserThumbnail :id="expense.user.id" disabled size="sm"/>
      </VCol>
      <VCol>
        <VRow no-gutters>
          <VCol cols="12" sm="auto">
            <span class="mr-2">
              {{expense.date}} - <strong>{{amountFormatted}}</strong>
            </span>
            <FaI v-if="hasMultipleProjects" class="ml-1 blue--text text--lighten-2" icon="layer-group"/>
            <FaI v-if="isBillable" class="ml-1 orange--text text--darken-2" icon="file-invoice-dollar"/>
            <FaLayers v-if="!expense.isReimbursable" class="ml-1">
              <FaI icon="money-bill-wave" size="sm" class="green--text"/>
              <FaI :icon="['far', 'ban']" size="lg" class="red--text" transform="left-1.3"/>
            </FaLayers>
            <FaI v-if="hasReceipt" icon="paperclip" class="ml-1 grey--text"/>
          </VCol>
          <VCol cols="12" class="text-nowrap">
            <VRow no-gutters>
              <VCol cols="12" sm="auto">
                {{expenseType.name}}
              </VCol>
              <VCol cols="auto" class="mx-1 hidden-xs-only">-</VCol>
              <VCol class="d-flex flex-nowrap">
                <div class="flex-shrink-1 text-truncate">
                  {{project.name}}
                </div>
                <div v-if="hasMultipleProjects" class="flex-shrink-0">
                  <VChip x-small color="blue lighten-3" class="ml-1">
                    +{{expense.expensePartitions.length - 1}}
                  </VChip>
                </div>
                <small v-else class="ml-1 grey--text flex-shrink-0">
                  ({{expense.expensePartitions[0].project.id}})
                </small>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VExpansionPanelHeader>
</template>

<script>
  import Queries from 'queries/index.js'
  import currencyMixin from "top/mixins/currencyMixin.js";
  import {UserThumbnail} from 'elements/index.js'
  import {mapGetters} from 'vuex'

  export default {
    mixins: [
      currencyMixin,
    ],

    components: {
      UserThumbnail,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      focusProject: [Number, String],

      simple: Boolean,
    },

    apollo: {
      expense: {
        query: Queries.Expense.Single.ExpansionPanelHeader,
        variables() {
          return {
            id: this.id,
          };
        },
      },

      project: {
        query: Queries.Project.Forms.Populate,
        variables() {
          return {
            id: this.featureProjectId,
          }
        },
        skip() {
          return !this.featureProjectId;
        },
      },

      expenseType: {
        query: Queries.ExpenseType.Forms.Populate,
        variables() {
          return {
            id: this.expense.expenseType.id,
          };
        },
        skip() {
          return !this.expense;
        },
      },
    },

    computed: {
      ...mapGetters('session', ['meId']),

      featureProjectId() {
        const {expense, focusProject} = this;

        if (!expense) {
          return undefined;
        }

        if (focusProject && expense.expensePartitions.some((e) => e.project.id === focusProject)) {
          return focusProject;
        }

        return expense.expensePartitions[0].project.id;
      },

      isMe() {
        const {expense, meId} = this;
        return expense ? expense.user.id === meId : false;
      },

      amountFormatted() {
        const {expense, getCurrency} = this;
        return expense ? getCurrency(expense.amount) : '';
      },

      hasMultipleProjects() {
        const {expense} = this;
        return expense ? expense.expensePartitions.length > 1 : false;
      },

      isBillable() {
        const {expense} = this;
        return expense ? expense.expensePartitions.findIndex((e) => e.isBillable) !== -1 : false;
      },

      hasReceipt() {
        return Boolean(this.expense?.receipts?.length);
      },
    },
  }
</script>
