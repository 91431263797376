// Types

export const gif = {
  type: 'image/gif',
  icon: 'file-image',
  canPreview: true,
  canConvert: true,
};

export const jpg = {
  type: 'image/jpeg',
  icon: 'file-image',
  canPreview: true,
  canConvert: true,
};

export const png = {
  type: 'image/png',
  icon: 'file-image',
  canPreview: true,
  canConvert: true,
};

export const pdf = {
  type: 'application/pdf',
  icon: 'file-pdf',
  canConvert: true,
};

export const wpd = {
  type: 'application/vnd.wordperfect',
  icon: 'file-word',
};

export const doc = {
  type: 'application/msword',
  icon: 'file-word',
};

export const docx = {
  type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  icon: 'file-word',
};

export const docm = {
  type: 'application/vnd.ms-word.document.macroEnabled.12',
  icon: 'file-word',
};

export const xls = {
  type: 'application/vnd.ms-excel',
  icon: 'file-spreadsheet',
};

export const xlsx = {
  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  icon: 'file-spreadsheet',
};

export const xlsm = {
  type: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  icon: 'file-spreadsheet',
};

export const ppt = {
  type: 'application/vnd.ms-powerpoint',
  icon: 'file-powerpoint',
};

export const pptx = {
  type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  icon: 'file-powerpoint',
};

export const pptm = {
  type: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  icon: 'file-powerpoint',
};

export const txt = {
  type: 'text/plain',
  icon: 'file-alt',
};

export const csv = {
  type: 'text/csv',
  icon: 'file-csv',
};

export const zip = {
  type: 'application/zip',
  icon: 'file-archive',
};

export const zipAlt = {
  type: 'application/x-zip-compressed',
  icon: 'file-archive',
};

export const z7z = {
  type: 'application/x-7z-compressed',
  icon: 'file-archive',
};


// Groups

export const images = {
  gif,
  jpg,
  png,
};

export const documents = {
  pdf,
  doc,
  docx,
  docm,
  wpd,
  txt,
};

export const data = {
  xls,
  xlsx,
  xlsm,
  csv,
};

export const presentations = {
  ppt,
  pptx,
  pptm,
};

export const archives = {
  zip,
  zipAlt,
  z7z,
};

export const receipts = {
  jpg,
  pdf,
  png,
};

export const all = {
  ...images,
  ...documents,
  ...data,
  ...presentations,
  ...archives,
};

export const findExtensionByMimeType = (mimeType) => {
  for (const key of Object.keys(all)) {
    if (all[key].type === mimeType) {
      return key; // Return the key that matches the MIME type
    }
  }
  return null; // Return null if no matching MIME type is found
};


// Default

export default {
  images,
  documents,
  data,
  presentations,
  archives,
  receipts,
  all,
};
