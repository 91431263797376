import gql from 'graphql-tag';
import DataExport from './dataExport.js'
import PaginatorFragment from './paginatorFragment.js'

// Fragments

const TimeslipFragment = gql`
  fragment timeslipFragment on Timeslip
  {
    id
    date
    duration
    description
    isFromTimekeeper
    durationMismatch
    isBillable
    amount
    startAt
    startAtTimeZone
    stopAt
    stopAtTimeZone
    createdAt
    updatedAt
    timeslipPartitions {
      id
      weight
      amount
      duration
      isBillable
      billingRate
      comment
      project {
        id
        slug
        name
      }
    }
    timeslipType {
      id
      name
    }
    user {
      id
    }
  }
`;

const ActiveTimeslipFragment = gql`
  fragment activeTimeslipFragment on Timeslip
  {
    ...timeslipFragment
    timeslipCollection {
      id
    }
  }
  ${TimeslipFragment}
`;


// Browse

const List = gql`
  query timeslipsList(
    $date: DateRange
    $projectId: ID
    $userId: ID
    $userOfficeId: ID
    $projectOfficeId: ID
    $timeslipTypeIds: [ID!]
    $isBillable: BillableScope
    $orderBy: [QueryTimeslipsOrderByOrderByClause!]
    $page: Int
    $first: Int!
  ) {
    timeslips(
      date: $date
      project: $projectId
      user: $userId
      userOffice: $userOfficeId
      projectOffice: $projectOfficeId
      isBillable: $isBillable
      timeslipTypes: $timeslipTypeIds
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        ...timeslipFragment
      }
    }
  }
  ${PaginatorFragment}
  ${TimeslipFragment}
`;

const Export = gql`
  mutation(
    $uuid: UUID!
    $date: DateRange
    $projectId: ID
    $userId: ID
    $userOfficeId: ID
    $projectOfficeId: ID
    $timeslipTypeIds: [ID!]
    $isBillable: BillableScope
    $orderBy: [MutationTimeslipsExportOrderByOrderByClause!]
  ) {
    timeslipsExport(
      uuid: $uuid
      date: $date
      project: $projectId
      user: $userId
      userOffice: $userOfficeId
      projectOffice: $projectOfficeId
      isBillable: $isBillable
      timeslipTypes: $timeslipTypeIds
      orderBy: $orderBy
    ) {
      ...dataExportDownloadFragment
    }
  }
  ${DataExport.Fragments.DownloadFragment}
`;


// Single

const Main = gql`
  query(
    $id: ID!
  ) {
    timeslip(
      id: $id
    ) {
      ...timeslipFragment
    }
  }
  ${TimeslipFragment}
`;

const TimekeeperItem = gql`
  query(
    $id: ID!
  ) {
    timeslip(
      id: $id
    ) {
      id
      description
      isBillable
      project {
        id
      }
      timeslipType {
        id
      }
    }
  }`;

const ActiveTimeslip = gql`
  query(
    $userId: ID!
  ) {
    activeTimeslip(
      user: $userId
    ) {
      ...activeTimeslipFragment
    }
  }
  ${ActiveTimeslipFragment}
`;

const ActiveTimeslipCollection = gql`
  query(
    $userId: ID!
  ) {
    activeTimeslip(
      user: $userId
    ) {
      timeslipCollection {
        id
      }
    }
  }`;

const ExpansionPanelHeader = gql`
  query(
    $id: ID!
  ) {
    timeslip(
      id: $id
    ) {
      id
      date
      isBillable
      duration
      amount
      isFromTimekeeper
      durationMismatch
      createdAt
      updatedAt
      startAt
      startAtTimeZone
      stopAt
      stopAtTimeZone
      timeslipPartitions {
        id
        project {
          id
          name
        }
      }
      timeslipType {
        id
      }
      user {
        id
      }
    }
  }
`;

const ExpansionPanelContent = gql`
  query(
    $id: ID!
  ) {
    timeslip(
      id: $id
    ) {
      id
      description
      duration
      isFromTimekeeper
      durationMismatch
      startAt
      startAtTimeZone
      stopAt
      stopAtTimeZone
      createdAt
      updatedAt
      user {
        id
      }
      timeslipPartitions {
        id
        amount
        isBillable
        billingRate
        weight
        duration
        comment
        project {
          id
          name
        }
      }
    }
  }
`;


// Subscriptions

const SubExpansionPanelHeader = gql`
  subscription onTimeslipUpdated(
    $id: ID!
  ) {
    timeslipUpdated(
      id: $id
    ) {
      id
      date
      duration
      timeslipPartitions {
        id
        isBillable
        billingRate
        weight
        duration
        comment
        project {
          id
          name
        }
      }
      timeslipType {
        id
      }
      user {
        id
      }
    }
  }
`;

const SubExpansionPanelContent = gql`
  subscription onTimeslipUpdated(
    $id: ID!
  ) {
    timeslipUpdated(
      id: $id
    ) {
      id
      description
      createdAt
      updatedAt
      user {
        id
      }
    }
  }
`;

const ActiveTimeslipChanged = gql`
  subscription onActiveTimeslipChanged(
    $user: ID!
  ) {
    activeTimeslipChanged (
      user: $user
    )
    {
      ...activeTimeslipFragment
    }
  }
  ${ActiveTimeslipFragment}
`;


// Write

const FormFill = gql`
  query(
    $id: ID!
  ) {
    timeslip(
      id: $id
    ) {
      id
      date
      duration
      description
      startAt
      startAtTimeZone
      stopAt
      stopAtTimeZone
      timeslipPartitions {
        id
        weight
        isBillable
        billingRate
        comment
        project {
          id
          name
        }
      }
      timeslipType {
        id
      }
      user {
        id
      }
    }
  }
`;

const Create = gql`
  mutation(
    $date: Date!
    $duration: Int!
    $description: String!
    $startAt: DateTimeTz
    $startAtTimeZone: String
    $stopAt: DateTimeTz
    $stopAtTimeZone: String
    $userId: ID!
    $timeslipTypeId: ID!
    $timeslipPartitions: [TimeslipPartitionCreateOnTimeslipRelationship!]!
  ) {
    timeslipCreate(
      input: {
        date: $date
        duration: $duration
        description: $description
        startAt: $startAt
        startAtTimeZone: $startAtTimeZone
        stopAt: $stopAt
        stopAtTimeZone: $stopAtTimeZone
        timeslipPartitions: {
          create: $timeslipPartitions
        }
        user: {
          connect: $userId
        }
        timeslipType: {
          connect: $timeslipTypeId
        }
      }
    ) {
      ...activeTimeslipFragment
    }
  }
  ${ActiveTimeslipFragment}
`;

const Update = gql`
  mutation(
    $id: ID!
    $date: Date
    $duration: Int
    $description: String
    $startAt: DateTimeTz
    $startAtTimeZone: String
    $stopAt: DateTimeTz
    $stopAtTimeZone: String
    $timeslipTypeId: ID
    $timeslipPartitionsCreate: [TimeslipPartitionCreateOnTimeslipRelationship!]
    $timeslipPartitionsUpdate: [TimeslipPartitionUpdateOnTimeslipRelationship!]
    $timeslipPartitionsDelete: [ID!]
  ) {
    timeslipUpdate(
      id: $id
      input: {
        date: $date
        duration: $duration
        description: $description
        startAt: $startAt
        startAtTimeZone: $startAtTimeZone
        stopAt: $stopAt
        stopAtTimeZone: $stopAtTimeZone
        timeslipType: {
          connect: $timeslipTypeId
        }
        timeslipPartitions: {
          create: $timeslipPartitionsCreate
          update: $timeslipPartitionsUpdate
          delete: $timeslipPartitionsDelete
        }
      }
    ) {
      ...activeTimeslipFragment
    }
  }
  ${ActiveTimeslipFragment}
`;

const Delete = gql`
  mutation(
    $id: ID!
  ) {
    timeslipDelete(
      id: $id
    ) {
      id
    }
  }
`;

const Start = gql`
  mutation(
    $description: String!
    $timeZone: String
    $timeslipTypeId: ID!
    $timeslipPartitions: [TimeslipPartitionCreateOnTimeslipRelationship!]!
  ) {
    timeslipStart(
      input: {
        description: $description
        timeZone: $timeZone
        timeslipType: {
          connect: $timeslipTypeId
        }
        timeslipPartitions: {
          create: $timeslipPartitions
        }
      }
    ) {
      ...activeTimeslipFragment
    }
  }
  ${ActiveTimeslipFragment}
`;

const Stop = gql`
  mutation(
    $timeZone: String
    $stopAt: DateTimeTz
    $durationDates: DateRange
  ) {
    timeslipStop(
      input: {
        timeZone: $timeZone
        stopAt: $stopAt
      }
    ) {
      timeslipCollection {
        id
        duration(dates: $durationDates)
      }
      ...activeTimeslipFragment
    }
  }
  ${ActiveTimeslipFragment}
`;

const Cancel = gql`
  mutation {
    timeslipCancel {
      ...activeTimeslipFragment
    }
  }
  ${ActiveTimeslipFragment}
`;


// Export

const Fragments = {
  Main: TimeslipFragment,
  ActiveFragment: ActiveTimeslipFragment,
};

const Browse = {
  List,
  Export,
  // Ids,
};

const Single = {
  Main,
  TimekeeperItem,
  ActiveTimeslip,
  ActiveTimeslipCollection,
  ExpansionPanelHeader,
  ExpansionPanelContent,
};

const Subscriptions = {
  // ExpansionPanelHeader: SubExpansionPanelHeader,
  // ExpansionPanelContent: SubExpansionPanelContent,
  ActiveTimeslipChanged,
};

const Write = {
  FormFill,
  Create,
  Update,
  Delete,
  Start,
  Stop,
  Cancel,
};

export default {
  Browse,
  Fragments,
  Single,
  Subscriptions,
  Write,
};
