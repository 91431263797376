import gql from 'graphql-tag';
import Location from './location.js'
// Fragments

const ContactStandardFragment = gql`
  fragment contactStandardFragment on Contact
  {
    email
    phones
    location {
      id
      street
      streetExtra
      postalCode
      ...locationCityFlatFragment
    }
  }
  ${Location.Fragments.CityFlat}
`;

const ContactFormFillFragment = gql`
  fragment contactFormFillFragment on Contact
  {
    email
    phones
    location {
      id
      ...locationFormFillFragment
    }
  }
  ${Location.Fragments.FormFill}
`;


// Write

const FormFill = gql`
  query(
    $id: ID!
  ) {
    contact(
      id: $id
    ) {
      id
      ...contactFormFillFragment
    }
  }
  ${ContactFormFillFragment}
`;


// Export

const Fragments = {
  FormFill: ContactFormFillFragment,
  Standard: ContactStandardFragment,
};

const Write = {
  FormFill,
};

export default {
  Fragments,
  Write,
};
