<template>
  <ListBrowserFilterWrapper :show-search="true" :show-filter="showFilter" :order-options="parsedOrderOptions" :form.sync="form" @input="onInput" @filtered="onFiltered">
    <VRow>
      <VCol class="py-0" cols="12">
        <KApiSelect
          clearable
          model="office"
          :disabled="disables.officeId"
          v-model="form.officeId"
        />
      </VCol>
    </VRow>
  </ListBrowserFilterWrapper>
</template>

<script>
  import ListBrowserFilterWrapper from "elements/list/ListBrowserFilterWrapper.vue";
  import {KApiSelect} from "forms/elements/index.js";

  const filterDefaults = {
    officeId: undefined,
  };
  const orderOptions = [
    {
      text: 'models.user.name',
      value: 'NAME',
      default: true,
    },
    {
      text: 'models.user.email',
      value: 'EMAIL',
      secondary: true,
    },
  ];

  export default {
    components: {
      ListBrowserFilterWrapper,
      KApiSelect,
    },

    data() {
      return {
        form: Object.assign({}, filterDefaults, this.defaults),
      };
    },

    props: {
      showFilter: Boolean,

      defaults: {
        type: Object,
        default: () => ({}),
      },

      disables: {
        type: Object,
        default: () => ({}),
      },

      sortDefault: {
        type: String,
        default: 'NAME',
      },

      orderDescDefault: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      parsedDefaults() {
        const payload = {};

        for (const key in filterDefaults) {
          payload[key] = this.defaults.hasOwnProperty(key) ? this.defaults[key] : filterDefaults[key];
        }

        return payload;
      },

      parsedOrderOptions() {
        const {sortDefault, orderDescDefault} = this;
        const payload = [];

        orderOptions.forEach((item) => {
          payload.push(item.value === sortDefault
              ? Object.assign({}, item, {default: true, defaultDesc: orderDescDefault})
              : item
          );
        });

        return payload;
      },
    },

    methods: {
      onInput(v) {
        this.$emit('input', v);
      },

      onFiltered(v) {
        this.$emit('filtered', v);
      }
    },
  }
</script>
