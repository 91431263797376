<template>
<!--  <VSkeletonLoader-->
<!--    type="list-item-one-line"-->
<!--    v-if="$apollo.loading"-->
<!--  />-->
  <VListItem color="blue" class="my-0">
    <VListItemAction>
      <FaI
        class="orange--text text--darken-2"
        icon="file-invoice-dollar"
        size="lg"
        v-if="expensePartition.isBillable"
      />
    </VListItemAction>
    <VListItemContent class="py-1">
      <div class="d-flex flex-nowrap align-center">
        <strong>
          {{amountFormatted}}
        </strong>
        <ProjectListItem :id="expensePartition.project.id" dense class="ml-2"/>
      </div>
      <div v-if="expensePartition.comment" class="text--primary mt-2">
        {{expensePartition.comment}}
      </div>
    </VListItemContent>
  </VListItem>
</template>

<script>
  import Queries from 'queries/index.js'
  import currencyMixin from "top/mixins/currencyMixin.js";
  import ProjectListItem from "../../projects/browse/elements/ProjectListItem.vue";

  export default {
    mixins: [currencyMixin],

    components: {
      ProjectListItem,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    apollo: {
      expensePartition: {
        query: Queries.ExpensePartition.Single.Main,
        variables() {
          return {
            id: this.id,
          };
        }
      },

      project: {
        query: Queries.Project.Forms.Populate,
        variables() {
          return {
            id: this.expensePartition.project.id,
          }
        },
        skip() {
          return !this.expensePartition;
        }
      },
    },

    computed: {
      amountFormatted() {
        const {expensePartition, getCurrency} = this;
        return expensePartition ? getCurrency(expensePartition.amount) : '';
      },
    }
  }
</script>
