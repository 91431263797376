import {ApolloLink, Observable} from '@apollo/client/core'
import echoClient from "./echo.js";

class SubscriptionsLink extends ApolloLink {
  constructor() {
    super();
    this.subscriptions = [];
    this.echo = echoClient;
  }

  request(operation, forward) {
    return new Observable((observer) => {
      let subscriptionChannel;

      // Check the result of the operation
      forward(operation).subscribe({
        next: (data) => {
          // If the operation has the subscription extension, it's a subscription
          subscriptionChannel = this._getChannel(data);

          if (subscriptionChannel) {
            this._createSubscription(subscriptionChannel, observer);
          } else {
            // No subscription found in the response, pipe data through
            observer.next(data);
            observer.complete();
          }
        },
      });

      // Return an object that will unsubscribe _if_ the query was a subscription.
      return {
        closed: false,
        unsubscribe: () => {
          if (subscriptionChannel) {
            this._leaveSubscription(subscriptionChannel);
          }
        },
      };
    });
  }

  _getChannel(response) {
    return response?.extensions?.lighthouse_subscriptions?.channel ?? null;
  }

  _createSubscription(subscriptionChannel, observer) {
    const privateChannelName = subscriptionChannel.split('private-').pop();

    if (!this.subscriptions.find(s => s.channel === subscriptionChannel)) {
      this.subscriptions.push({
        channel: subscriptionChannel,
        observer: observer,
      });
    }

    this.echo.private(privateChannelName).listen('.lighthouse-subscription', (payload) => {
      if (!payload.more || observer._subscription._state === 'closed') {
        this._leaveSubscription(subscriptionChannel, observer);
        return;
      }
      const result = payload.result;
      if (result) {
        if (result.errors) {
          console.warn('API subscription server-side internal error', {channel: subscriptionChannel, errors: result.errors})
          return;
        }
        observer.next({
          data: result.data,
          extensions: result.extensions,
        });
      }
    });
  }

  _leaveSubscription(channel) {
    const subscription = this.subscriptions.find(s => s.channel === channel);
    this.echo.leave(channel);
    if (subscription) {
      subscription.observer.complete();
      this.subscriptions.splice(this.subscriptions.indexOf(subscription), 1);
    } else {
      console.warn('Subscription observer is missing.', {channel});
    }
  }
}

export default SubscriptionsLink;
