<template>
  <VContainer>
    <VToolbar dense color="grey lighten-3" class="mx-1">
      <VToolbarTitle v-t="{path: 'models.timeslip.self', choice: 2}"/>
      <VSpacer/>
      <VFadeTransition>
        <VBtn v-show="showFilterControl" @click="toggleFilter" text>
          <FaI size="2x" icon="chevron-down" :rotation="showFilter ? 180 : 0" style="transition: all 0.3s ease;"/>
          <div>
            <VFadeTransition leave-absolute>
              <FaI size="2x" key="a" v-if="isFiltered" icon="filter"/>
              <FaI size="2x" key="b" v-else :icon="['far', 'filter']"/>
            </VFadeTransition>
          </div>
        </VBtn>
      </VFadeTransition>
      <template v-slot:extension>
        <VTabs icons-and-text centered show-arrows>
          <VTab key="default" :to="{name: 'timeslips'}" exact v-if="canCreate">
            <span v-t="'actions.new'"/>
            <FaI icon="circle-plus" size="lg"/>
          </VTab>
          <VTab v-for="page in pages" :key="page.id" :to="{name: page.to}" exact>
            <span v-t="page.name"/>
            <FaI :icon="page.icon" size="lg"/>
          </VTab>
        </VTabs>
      </template>
    </VToolbar>

    <VTabsItems :value="tab" touchless class="px-1">
      <VTabItem key="default" value="default" v-if="canCreate">
        <TimeslipBrowseTab
          :filter-disables="{userId: true, officeId: true}"
          :filter-defaults="{userId: meId}"
          sort-default="UPDATED_AT"
          :order-desc-default="true"
          :show-filter="false"
          v-slot:default="{onCreate}"
          simple
        >
          <TimeslipDaysChart v-if="$vuetify.breakpoint.smAndUp" :from="chartFrom" :to="chartTo"/>
          <ActivityAnalyticsCard
            context="USER"
            :id="meId"
            subscribe
          />
          <FormTimeslip @date-update="date = $event" @success="onCreate" class="my-2"/>
          <VDivider/>
          <VCardTitle v-t="'views.timeslips.historyTitle'"/>
        </TimeslipBrowseTab>
      </VTabItem>
      <VTabItem v-for="{id, disables, defaults, sort, sortDesc, simple} in pages" :key="id" :value="id">
        <TimeslipBrowseTab
          :filter-disables="disables"
          :filter-defaults="defaults"
          :sort-default="sort"
          :order-desc-default="sortDesc"
          :show-filter="showFilter"
          :simple="simple"
          @filtered="setIsFiltered"
        />
      </VTabItem>
    </VTabsItems>
  </VContainer>
</template>

<script>
  import FormTimeslip from "./elements/FormTimeslip.vue";
  import TimeslipDaysChart from "../timekeeper/TimeslipDaysChart.vue";
  import {mapGetters} from "vuex";
  import TimeslipBrowseTab from "./elements/TimeslipBrowseTab.vue";
  import {formatISODate} from "../../date-fns-format.js";
  import {parseISO, addDays, subDays} from 'date-fns';
  import ActivityAnalyticsCard from "./elements/ActivityAnalyticsCard.vue";

  export default {
    components: {
      ActivityAnalyticsCard,
      FormTimeslip,
      TimeslipDaysChart,
      TimeslipBrowseTab,
    },

    data: () => ({
      showFilter: false,
      isFilteredStore: {},
      date: formatISODate(new Date()),
    }),

    props: {
      tab: {
        type: String,
        default: 'default',
      },
    },

    apollo: {
      canCreate() {
        return this.policyChecker({
          policy: 'create',
          type: 'Timeslip',
          args: {
            user: {
              connect: this.meId,
            },
          },
        });
      },

      canViewScoped() {
        return this.policyChecker({
          policy: 'viewScoped',
          type: 'Timeslip',
          args: {
            user: this.meId,
          },
        });
      },

      canViewAny() {
        return this.policyChecker({
          policy: 'viewAny',
          type: 'Timeslip',
        });
      },
    },

    computed: {
      ...mapGetters('session', ['meId']),

      pages() {
        const {meId, canViewScoped, canViewAny} = this;

        const payload = [];

        if (canViewScoped) {
          payload.push({
            id: 'review',
            name: 'ui.review',
            to: 'timeslips.review',
            icon: 'clock-rotate-left',
            defaults: {
              userId: meId,
            },
            disables: {
              userId: true,
              officeId: true,
            },
            sort: 'DATE',
            sortDesc: true,
            simple: true,
          });
        }

        if (canViewAny) {
          payload.push({
            id: 'admin',
            name: 'ui.admin',
            to: 'timeslips.admin',
            icon: 'screwdriver-wrench',
            sort: 'UPDATED_AT',
            sortDesc: true,
          });
        }

        return payload;
      },

      isFiltered() {
        const {isFilteredStore, tab} = this;
        return isFilteredStore[tab] || false;
      },

      showFilterControl() {
        return this.tab !== 'default';
      },

      chartFrom() {
        return formatISODate(subDays(parseISO(this.date), 11));
      },

      chartTo() {
        return formatISODate(addDays(parseISO(this.date), 2));
      },
    },

    methods: {
      toggleFilter() {
        this.showFilter = !this.showFilter;
      },

      setIsFiltered(v) {
        this.$set(this.isFilteredStore, this.tab, v);
      },
    },
  }
</script>
