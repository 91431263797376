import gql from 'graphql-tag';

// Forms

const Select = gql`
  query(
    $country: ID!
  ) {
    countryDivisions(
      country: $country
    ) {
      id
      name
    }
  }
`;

const Populate = gql`
  query(
    $id: ID!
  ) {
    countryDivision(
      id: $id
    ) {
      id
      name
      country {
        id
      }
    }
  }
`;


// Single

const Name = gql`
  query(
    $id: ID!
  ) {
    countryDivision(
      id: $id
    ) {
      id
      name
    }
  }
`;



// Export

const Forms = {
  Select,
  Populate,
};

const Single = {
  Main: Populate,
  Name,
};

export default {
  Forms,
  Single,
};
