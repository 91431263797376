<template>
  <form @submit.prevent="doSubmit">

    <KTextField
      :validator="$v.email"
      autocomplete="email"
      label="models.user.email"
      type="email"
      v-model="email"
    />

    <KTextField
      :validator="$v.password"
      autocomplete="current-password"
      label="models.user.password"
      type="password"
      v-model="password"
    />

    <div class="mt-3 d-flex justify-space-between justify-md-start">
      <KBtnSubmit :mode="mode">
        <span v-t="'ui.signIn'"/>
      </KBtnSubmit>
      <VBtn :to="{name: 'forgotPassword'}" class="ml-md-10">
        <span v-t="'views.forgotPassword.title'"/>
      </VBtn>
    </div>
  </form>
</template>

<script>
  import FormMixin from 'forms/elements/FormMixin.js'
  import {KBtnSubmit, KTextField} from "forms/elements/index.js"
  import {email, maxLength, minLength, required} from 'validators/index.js'

  export default {
    mixins: [
      FormMixin,
    ],

    components: {
      KBtnSubmit,
      KTextField,
    },

    data: () => ({
      email: '',
      password: '',
    }),

    validations: {
      email: {
        required,
        email,
        maxLength: maxLength(255)
      },
      password: {
        required,
        minLength: minLength(6)
      },
    },

    methods: {
      async createAction() {
        const credentials = {
          username: this.email,
          password: this.password,
        };

        return await this.$store.dispatch('session/signIn', credentials);
      },
    },
  }
</script>
