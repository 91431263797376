<template>
  <NowLoading v-if="$apollo.queries.project.loading"/>
  <TeamMembersList v-else :id="teamId"/>
</template>

<script>
import TeamMembersList from "top/views/team/TeamMembersList.vue";
import Queries from 'queries/index.js';

export default {
  components: {
    TeamMembersList,
  },

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  apollo: {
    project: {
      query: Queries.Project.Single.Team,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },

  computed: {
    teamId() {
      return this.project?.team?.id;
    },
  },
}
</script>
