import Main from './Main.vue'
import Export from './export/Main.vue'
import ProjectRecords from './projectRecords/Main.vue'
import Analytics from './analytics/Main.vue'

export default {
  Main,
  Export,
  ProjectRecords,
  Analytics,
}
