<template>
  <VList max-width="400px">
    <PerformancePatternButtons @start="onStart" @advance="onAdvance"/>
    <VSlideYTransition group>
      <template v-if="isActive" v-for="field in sortedFields">
        <VDivider v-if="field === 'd1' || field === 'd2'" :key="field"/>
        <PerformancePatternItem
          v-else
          :key="field"
          :field="field"
          :device-id="deviceId"
        />
      </template>
    </VSlideYTransition>
  </VList>
</template>

<script>
  import {PROGRAMS} from './timerPatterns.js'
  import PerformancePatternButtons from './PerformancePatternButtons.vue'
  import PerformancePatternItem from './PerformancePatternItem.vue'

  const fieldSorter = (programState) => (a, b) => {
    // This is broken, I know
    const aStartDiff = programState[a].startDiff,
      aStopDiff = programState[a].stopDiff,
      bStartDiff = programState[b].startDiff,
      bStopDiff = programState[b].stopDiff;

    // Both have finished running, reverse sort on stop order
    if (aStopDiff <= 0 && bStopDiff <= 0) {
      return bStopDiff - aStopDiff || bStartDiff - aStartDiff;
    }

    // If both are running, sort by which stops sooner
    if ((aStartDiff <= 0 && aStopDiff > 0) && (bStartDiff <= 0 && bStopDiff > 0)) {
      return aStopDiff - bStopDiff || aStartDiff - bStartDiff;
    }

    // If neither are running yet, sort by which starts sooner
    if (aStartDiff > 0 && bStartDiff > 0) {
      return aStartDiff - bStartDiff || aStopDiff - bStopDiff;
    }

    // If A is finished and B isn't, B is higher
    if (aStopDiff <= 0 && bStopDiff > 0) {
      return 1;
    }

    // If B is finished and A isn't, A is higher
    if (aStopDiff > 0 && bStopDiff <= 0) {
      return -1;
    }

    // If A is running and B isn't, A is higher
    if (aStartDiff <= 0 && bStartDiff > 0) {
      return -1;
    }

    // If B is running and A isn't, B is higher
    if (aStartDiff > 0 && bStartDiff <= 0) {
      return 1;
    }

    // In theory no sort should get here
    return 0;
  };

  export default {
    components: {
      PerformancePatternButtons,
      PerformancePatternItem,
    },

    inject: [
      'performancePattern',
    ],

    props: {
      devices: {
        type: Array,
        required: true,
      },
    },

    computed: {
      deviceId() {
        return this.devices.length === 1 ? this.devices[0] : null;
      },

      mode() {
        return this.performancePattern.mode;
      },

      step() {
        return this.performancePattern.step;
      },

      isActive() {
        return this.mode !== null;
      },

      hasRearEntrance() {
        return false;
      },

      unsortedFields() {
        const {mode} = this;
        return mode ? Object.keys(PROGRAMS[mode]) : [];
      },

      programState() {
        const {mode} = this;
        if (!mode) {
          return null;
        }

        const program = PROGRAMS[mode],
          {unsortedFields, step} = this,
          payload = {};

        for(let i = 0, len = unsortedFields.length; i < len; i++) {
          const key = unsortedFields[i];
          payload[key] = {
            startDiff: program[key].start - step,
            stopDiff: program[key].stop - step,
          };
        }

        return payload;
      },

      sortedFields() {
        const {programState} = this;

        if (programState === null) {
          return [];
        }

        const sorted = [...this.unsortedFields].sort(fieldSorter(programState)),
          stagedDivider = sorted.findIndex((field) => programState[field].startDiff > 0);

        if (stagedDivider > 0) {
          sorted.splice(stagedDivider, 0, 'd1');
        }

        const finishedDivider = sorted.findIndex((field) => programState[field] && programState[field].stopDiff <= 0);

        if (finishedDivider > 0) {
          sorted.splice(finishedDivider, 0, 'd2');
        }

        return sorted;
      },
    },

    methods: {
      onStart(event) {
        this.$emit('start', event);
      },

      onAdvance(event) {
        this.$emit('advance', event);
      },
    },
  }
</script>
