import gql from 'graphql-tag';

// Single

const Main = gql`
  query(
    $id: ID!
  ) {
    expensePartition(
      id: $id
    ) {
      id
      amount
      weight
      isBillable
      comment
      project {
        id
      }
    }
  }
`;


// Export

const Single = {
  Main,
};

export default {
  Single,
};
