<template>
  <VBtn
    :disabled="disabled"
    :outlined="localRunning"
    :color="localRunning ? 'red' : 'green'"
    @click="toggleTimer"
  >
    <FaI :class="iconClasses" :icon="icon" size="lg"/>
  </VBtn>
</template>

<script>
  import {differenceInMilliseconds} from 'date-fns'

  export default {
    data: () => ({
      startTime: null,
      localRunning: false,
    }),

    props: {
      disabled: {
        type: Boolean,
        default: false
      },

      interval: {
        type: Number,
        default: 100, // milliseconds
        validator: (value) => value >= 1 && value <= 1000
      },

      precision: {
        type: Number,
        default: 1, // decimal places
        validator: (value) => value >= 0 && value <= 4
      },

      running: Boolean,

      timeout: {
        type: Number,
        default: 99,
        validator: (value) => value >= 1 && value <= 3600
      },

      value: { // Run/stop
        type: [Number, String],
        default: false,
      }
    },

    computed: {
      iconClasses() {
        return {
          'fa-spin': this.localRunning,
        };
      },

      icon() {
        return ['far', this.disabled ? 'circle-stop' : 'clock'];
      },
    },

    timers: {
      ticker: {time: 100, repeat: true}
    },

    watch: {
      disabled(v) {
        if (v && this.localRunning) {
          this.endTimer();
        }
      },

      running(v) {
        if (v) {
          this.startTimer();
        } else {
          this.endTimer();
        }
      }
    },

    methods: {
      ticker() {
        this.updateValue();
        // todo implement timeout
      },

      toggleTimer() {
        if (!this.localRunning && !this.disabled) {
          this.startTimer();
        } else {
          this.endTimer();
        }
      },

      startTimer() {
        this.startTime = new Date;
        this.localRunning = true;
        this.$timer.start('ticker');
        this.updateValue();
        this.$emit('update:running', true);
      },

      endTimer() {
        this.$timer.stop('ticker');
        this.updateValue();
        this.localRunning = false;
        this.$emit('update:running', false);
      },

      updateValue() {
        this.$emit('input', Number(Math.min(
          this.timeout,
          differenceInMilliseconds(new Date, this.startTime) / 1000
        ).toFixed(this.precision)));
      },
    }
  }
</script>
