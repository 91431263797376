<template>
  <div>
    <SettingsContainer name="timeFormat" :items="items"/>
    <KTimePicker class="mt-2" label="views.settings.timeFormat.demo" get-date-object v-model="demoTime"/>
    <small>
      <span v-t="'views.settings.timeFormat.example'"/>: {{testTimestamp}}
    </small>
  </div>
</template>

<script>
import {parseISO} from "date-fns";
import {format} from "top/date-fns-format.js"
import {KTimePicker} from "forms/elements/index.js"
import SettingsContainer from "./SettingsContainer.vue";

const options = [
  'default',
  'ampm',
  '24hr',
];

export default {
  components: {
    SettingsContainer,
    KTimePicker,
  },

  data: () => ({
    demoTime: new Date(),
  }),

  computed: {
    items() {
      return options.map((value) => ({
        label: this.$t(`views.settings.timeFormat.${value}`),
        value,
      }));
    },

    testTimestamp() {
      const timestamp = '2021-03-15T20:52:41+00:00';
      return format(parseISO(timestamp));
    },
  },
}
</script>
