<template>
  <VRow>
    <VCol cols="12">
      <KTextField
        v-model.number="form.hoursAnnumBudgeted"
        :validator="$v.form.hoursAnnumBudgeted"
        label="models.projectRecord.internal.hoursAnnumBudgeted"
        hint="forms.projectRecord.internal.hoursAnnumBudgeted_hint"
        type="number"
      />
    </VCol>
  </VRow>
</template>

<script>
import {KTextField, scopedFormsMixin} from "forms/elements/index.js";
import {between, integer, required} from "validators/index.js";

export default {
  mixins: [
    scopedFormsMixin,
  ],

  components: {
    KTextField,
  },

  data: () => ({
    form: {
      hoursAnnumBudgeted: null,
    },
  }),

  validations: {
    form: {
      hoursAnnumBudgeted: {
        required,
        integer,
        between: between(2, 99999),
      },
    },
  },
}
</script>
