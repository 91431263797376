<template>
  <VDialog fullscreen hide-overlay transition="dialog-bottom-transition">
    <template #activator="{on, attrs}">
      <VBtn icon v-on="on" v-bind="attrs">
        <FaI size="2x" icon="gear"/>
      </VBtn>
    </template>
    <template #default="dialog">
      <VCard>
        <VCardText>
          <Main :user="user">
            <template #actions>
              <VBtn text class="ml-4" @click.prevent="dialog.value = false">
                <FaI icon="times" size="lg"/>&nbsp;
                <span class="mr-1" v-t="'actions.close'"/>
              </VBtn>
            </template>
          </Main>
        </VCardText>
      </VCard>
    </template>
  </VDialog>
</template>

<script>
  import Main from './Main.vue';

  export default {
    components: {
      Main,
    },

    props: {
      user: [Number, String],
    },
  }
</script>
