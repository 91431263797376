import gql from "graphql-tag";


// Single

const Identity = gql`
  query(
    $id: ID!
  ) {
    device(
      id: $id
    ) {
      id
      designation
    }
  }
`;


// Others

const IdentityList = gql`
  query(
    $ids: [ID!]!
  ) {
    devices(
      ids: $ids
    ) {
      id
      designation
    }
  }
`;


// Export

const Single = {
  Identity,
};

export default {
  Single,
  IdentityList,
};
