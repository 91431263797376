import {camelCase} from "lodash-es";
import getPolicyTestId from "top/getPolicyTestId.js"
function cacheRedirectsFunction(__typename) {
  return {
    read: (_, {args, toReference}) => toReference({__typename, id: args.id})
  };
}

function paginatedFieldPolicy(keyArgs = undefined) {
  return {
    merge(existing, incoming, {args, args: {page = 1}}) {
      const merged = existing ? {...existing} : {};
      merged[page] = incoming;
      // console.debug('merge', {args, page, merged, existing, incoming});
      return merged;
    },
    read(existing, {args, args: {page = undefined}}) {
      const localPage = page ?? (existing && Math.max(...Object.keys(existing).map((e) => parseInt(e)))) ?? 0;
      const sorted = existing &&
        Object.entries(existing)
        .filter(([key, value]) => Number(key) <= Number(localPage))
        .sort(([keyA, valueA], [keyB, valueB]) => keyA - keyB);
      const data = sorted &&
        sorted.reduce((prev, [key, value]) => prev.concat(value.data), []);
      const paginatorInfo = sorted &&
        {
          ...sorted[localPage - 1][1].paginatorInfo,
          firstPage: Number(sorted[0][0]),
          lastPage: Number(sorted[localPage - 1][0]),
        };

      // console.debug('read', {args, page, data, paginatorInfo, existing, localPage, sorted});
      return {data, paginatorInfo};
    },
    ...keyArgs && {keyArgs: [...keyArgs, 'first']},
  };
}

const cacheIdRedirectsList = [
  'ActivityExport',
  'BillingRate',
  'City',
  'Company',
  'CompanyType',
  'Contact',
  'Conversation',
  'Country',
  'CountryDivision',
  // 'DataExport',
  'Device',
  'DeviceGroup',
  'DeviceType',
  'Expense',
  'ExpensePartition',
  'ExpenseType',
  'Inspection',
  'InspectionActivity',
  'InspectionArea',
  'InspectionPerformance',
  'InspectionType',
  'Invoice',
  'InvoiceLine',
  'Issue',
  'IssueComment',
  'IssueEvent',
  'IssueEventType',
  'Location',
  'Media',
  'MediaConversion',
  'Message',
  'Office',
  'PartitionsSet',
  'Project',
  'ProjectRecord',
  'ProjectStatus',
  'ProjectType',
  'QBLink',
  'Role',
  'SalesTaxCode',
  'Team',
  'Timeslip',
  'TimeslipCollection',
  'TimeslipDay',
  'TimeslipType',
  'User',
];

const paginatedFieldList = {
  'activityExports': ['createdBy', 'orderBy'],
  'buildings': ['search', 'orderBy'],
  'cities': ['search', 'scope'],
  'companies': ['search', 'companyType', 'orderBy'],
  'expenses': ['date', 'user', 'project', 'userOffice', 'projectOffice', 'expenseTypes', 'isBillable', 'isReimbursable', 'orderBy'],
  'inspections': ['search', 'orderBy'],
  'invoices': ['transactionDate', 'dueDate', 'isPaid', 'isOverdue', 'template', 'project', 'client', 'user', 'projectOffice', 'userOffice', 'orderBy'],
  'projects': ['teamMember', 'search', 'name', 'projectType', 'projectStatus', 'office', 'orderBy', 'recordDueAt'],
  'projectRecords': ['project', 'projectType', 'projectStatuses', 'createdBy', 'updatedBy', 'orderBy'],
  'timeslips': ['date', 'project', 'userOffice', 'projectOffice', 'user', 'timeslipTypes', 'isBillable', 'orderBy'],
  'users': ['search', 'email', 'orderBy', 'office'],
};

const cacheRedirectFunctions = {};

for (name of cacheIdRedirectsList) {
  cacheRedirectFunctions[camelCase(name)] = cacheRedirectsFunction(name);
}

for (const [name, keyArgs] of Object.entries(paginatedFieldList)) {
  cacheRedirectFunctions[name] = paginatedFieldPolicy(keyArgs);
}

cacheRedirectFunctions['devices'] = {
  read: (_, {args, toReference}) =>
    args.ids.map((id) => toReference({__typename: 'Device', id}))
};

cacheRedirectFunctions['medias'] = {
  read: (_, {args, toReference}) => args.ids.map((id) => toReference({__typename: 'Media', id})),
};

cacheRedirectFunctions['mediasByUuid'] = {
  read: (_, {args, toReference}) => args.uuids.map((uuid) => toReference({__typename: 'Media', uuid})),
};

cacheRedirectFunctions['policyTest'] = {
  read: (_, {args: {user = undefined, policy, contextType, contextId, contextArguments}, toReference}) =>
    toReference({
      __typename: 'PolicyTest',
      id: getPolicyTestId({user, policy, contextType, contextId, contextArguments}),
    }),
};

Object.freeze(cacheRedirectFunctions);

export default cacheRedirectFunctions;
