import KFormElementMixin from './KFormElementMixin.js'

export default {
  mixins: [
    KFormElementMixin,
  ],

  props: {
    autofocus: Boolean,

    clearable: Boolean,

    counter: [Boolean, Number, String],

    placeholder: String,

    suffix: String,
  },

  computed: {
    placeholderParsed() {
      return this.parseDisplayMessage(this.placeholder);
    },
  },
}
