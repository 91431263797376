import store from 'store/index.js'

export default (seconds, locale = null) => {
  if (!seconds) {
    return undefined;
  }

  const localLocale = locale || store.getters['session/locale'],
    separator = localLocale === 'fr-CA' ? ' h ' : ':',
    hours = ~~(seconds/3600),
    minutes = Math.abs(~~((seconds%3600)/60)),
    operator = seconds < 0 && !(hours === 0 && minutes === 0) ? '-' : '',
    paddedMinutes = localLocale === 'fr-CA' ? minutes : minutes.toString().padStart(2, '0');

  return `${operator}${hours}${separator}${paddedMinutes}`
}
