<template>
  <VContainer>
    <VAlert v-if="currentBillingRate" type="info" text>
      <span v-t="'views.users.billingRates.currentRate'"/> <strong>{{currentRateFormatted}}</strong>
    </VAlert>
    <BillingRatesList :id="id" context="USER" @refetched="refreshSuggestion"/>
  </VContainer>
</template>

<script>
import BillingRatesList from '../../billingRates/elements/BillingRatesList.vue';
import Queries from "api/queries";
import {mapGetters} from "vuex";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  mixins: [
    currencyMixin,
  ],

  components: {
    BillingRatesList,
  },

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  apollo: {
    currentBillingRate: {
      query: Queries.BillingRate.Single.Suggestion,
      variables() {
        const {id, today} = this;
        return {
          user: id,
          date: today,
        };
      },
      update: ({billingRateSuggestion}) => billingRateSuggestion?.rate,
    },
  },

  computed: {
    ...mapGetters('session', ['today']),

    currentRateFormatted() {
      return this.getCurrency(this.currentBillingRate);
    },
  },

  methods: {
    refreshSuggestion() {
      this.$apollo.queries.currentBillingRate.refetch();
    },
  },
}
</script>
