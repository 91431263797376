<template>
  <VApp>
    <MainToolbar
      @toggleLeft="showLeftDrawer = !showLeftDrawer"
      @toggleRight="showRightDrawer = !showRightDrawer"
    />

    <MainNavigation
      v-if="isSignedIn"
      :show.sync="showRightDrawer"
    />

    <VMain>
      <VProgressLinear indeterminate v-if="isLoading"/>
      <VContainer
        class="pa-0"
        fluid
      >
        <RouterView/>
      </VContainer>
    </VMain>

    <RouterView name="bottom"/>

    <Timekeeper/>

    <Flash/>
  </VApp>
</template>

<script>
  import {Flash, MainNavigation, MainToolbar} from 'elements/index.js'
  import Timekeeper from "top/views/timekeeper/Timekeeper.vue";
  import {mapGetters, mapState} from 'vuex'
  import Queries from "queries/index.js";

  const STORAGE_ME = 'me';

  export default {
    components: {
      Flash,
      MainNavigation,
      MainToolbar,
      Timekeeper,
    },

    data: () => ({
      showLeftDrawer: false,
      showRightDrawer: false,
    }),

    computed: {
      ...mapGetters('session', ['isSignedIn', 'locale']),

      ...mapState('session', ['isLoading']),
    },

    apollo: {
      me: {
        query: Queries.Auth.Me,
        update({me}) {
          this.$store.dispatch('session/storeMe', me);
          return me;
        },
      }
    },

    watch: {
      locale: {
        handler(v) {
          this.$vuetify.lang.current = v.split('-', 1)[0];
        },
        immediate: true,
      },

      isSignedIn(v) {
        if (!v) {
          this.showLeftDrawer = false;
          this.showRightDrawer = false;
        }
      }
    },

    beforeCreate() {
      const me = JSON.parse(localStorage.getItem(STORAGE_ME));
      if (me) {
        this.$apollo.provider.defaultClient.writeQuery({
          query: Queries.Auth.Me,
          data: {me},
        });
      }

      this.$store.dispatch('initialize', {api: this.$apollo});
    },
  }
</script>
