<template>
  <VContainer>
    <span class="text-h6" v-t="'forms.passwordChange.title'"/>
    <FormPasswordUpdate @success="onPasswordUpdateSuccess"/>
  </VContainer>
</template>

<script>
  import FormPasswordUpdate from "top/views/auth/FormPasswordUpdate.vue";
  import {UserThumbnail} from "elements/index.js";

  export default {
    components: {
      UserThumbnail,
      FormPasswordUpdate,
    },

    methods: {
      onPasswordUpdateSuccess() {
        this.$store.dispatch(
          'flash/success',
          {
            text: 'forms.messages.passwordUpdated',
          }
        )
      },
    },
  }
</script>
