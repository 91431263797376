<template>
  <VFadeTransition mode="out-in">
    <FaI key="success" v-if="isSuccess" :size="size" icon="check" class="success--text"/>
    <FaI key="error" v-else-if="isError" :size="size" icon="xmark" class="error--text"/>
  </VFadeTransition>
</template>

<script>
  const IDLE = 0;
  const SUBMIT = 10;
  const SUCCESS = 20;
  const ERROR = 99;

  export default {
    data: () => ({
      status: IDLE,
    }),

    props: {
      size: {
        type: String,
        default: 'lg',
      },
    },

    timers: {
      displayTimer: {
        time: 2000,
      }
    },

    computed: {
      isSubmitting() {
        return this.status === SUBMIT;
      },

      isSuccess() {
        return this.status === SUCCESS;
      },

      isError() {
        return this.status === ERROR;
      },
    },

    methods: {
      startSubmit() {
        this.$timer.stop('displayTimer');
        this.status = SUBMIT;
      },

      startSuccess() {
        this.status = SUCCESS;
        this.timers.displayTimer.time = 2000;
        this.startDisplay();
      },

      startError() {
        this.status = ERROR;
        this.timers.displayTimer.time = 5000;
        this.startDisplay();
      },

      startDisplay() {
        this.$timer.stop('displayTimer');
        this.$timer.start('displayTimer');
      },

      displayTimer() {
        this.status  = IDLE;
      },
    },

    created() {
      this.$on('submit', this.startSubmit);
      this.$on('success', this.startSuccess);
      this.$on('error', this.startError);
    },
  }
</script>
