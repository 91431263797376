<template>
  <div>
    <VToolbar dense>
      <VToolbarTitle v-t="'views.admin.projectRecords.export.title'"/>
    </VToolbar>
    <VSkeletonLoader type="article" v-if="$apollo.loading"/>
    <VContainer fluid v-else>
      <VRow>
        <VCol
          cols="12"
          sm="6"
          xl="3" order-xl="3"
        >
          <KApiSelect v-model="form.projectStatuses" model="projectStatus" :label="{path: 'models.projectStatus.self', choice: 2}" multiple clearable/>
        </VCol>
        <VCol
          cols="12"
          sm="6"
          xl="3" order-xl="4"
        >
          <KApiSelect v-model="form.projectTypes" model="projectType" :label="{path: 'models.projectType.self', choice: 2}" multiple clearable/>
        </VCol>
        <VCol
          cols="12"
          sm="6"
          xl="3" order-xl="4"
        >
          <KApiSelect v-model="form.offices" model="office" :label="{path: 'models.office.self', choice: 2}" multiple clearable/>
        </VCol>
        <VCol
          cols="12"
          sm="6"
          xl="2" order-xl="2"
        >
          <KDatePicker v-model="form.date" label="views.admin.projectRecords.export.date" :min="dateMin" :max="dateMax"/>
        </VCol>
        <VCol
          cols="12"
          sm="2"
          xl="1" order-xl="1"
          align-self="center"
        >
          <ExportButton mutation="ProjectRecord.Browse.Snapshot" :variables="variables"/>
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script>
  import ExportButton from "elements/ExportButton.vue";
  import {KDatePicker, KSelect, KApiSelect} from "forms/elements/index.js"
  import {formatISODate} from "top/date-fns-format.js";
  import {subDays} from "date-fns"
  import {betweenDate, required} from "validators/index.js";

  const dateMin = '2021-01-01';
  const dateMax = formatISODate(new Date());

  export default {
    data: () => ({
      form: {
        date: formatISODate(subDays(new Date(), 1)),
        projectTypes: undefined,
        projectStatuses: undefined,
        offices: undefined,
      },
      dateMin,
      dateMax,
    }),

    components: {
      ExportButton,
      KDatePicker,
      KSelect,
      KApiSelect,
    },

    variables: {
      date: {
        required,
        betweenDate: betweenDate(dateMin, dateMax),
      },
    },

    computed: {
      variables() {
        const {form: {projectStatuses, projectTypes, offices, date}} = this;

        return {
          date,
          ...projectStatuses && {projectStatuses},
          ...projectTypes && {projectTypes},
          ...offices && {offices},
        };
      },
    },
  }
</script>
