<template>
  <VExpansionPanelContent class="pa-0 ma-0">
    <VSkeletonLoader type="list-item-two-line, list-item-two-line" v-if="$apollo.loading"/>
    <VContainer fluid v-else-if="partitionsSet" class="ma-0 pa-0" v-show="!edit">
      <VList class="ma-0 pa-0">
        <template v-for="(partition, index) in partitionsSet.partitions">
          <VDivider :key="`d-${partition.id}`" v-if="index !== 0"/>
          <ProjectListItem :id="partition.project.id" dense>
            <template #action>
              <div class="d-flex flex-no-wrap align-center">
                <VChip v-if="partition.isTimeslipBillable" color="orange lighten-5" class="mr-2">
                  <FaI icon="clock" class="grey--text text--darken-1 mr-1"/>
                  <FaI icon="file-invoice-dollar" class="orange--text text--darken-2"/>
                </VChip>
                <VChip v-if="partition.isExpenseBillable" color="orange lighten-5" class="mr-2">
                  <FaI icon="receipt" class="grey--text text--darken-1 mr-1"/>
                  <FaI icon="file-invoice-dollar" class="orange--text text--darken-2"/>
                </VChip>
                <strong>
                  {{getPartitionWeight(partition)}}%
                </strong>
              </div>
            </template>
          </ProjectListItem>
          <VListItem v-if="partition.comment" :key="`c-${partition.id}`">
            <VListItemContent>
              <VListItemSubtitle>
                {{partition.comment}}
              </VListItemSubtitle>
            </VListItemContent>
          </VListItem>
        </template>
      </VList>
      <VRow class="grey--text py-2" justify="space-between" no-gutters>
        <VCol class="d-flex flex-column" cols="auto">
          <small>
            {{createdTimestamp}}
          </small>
          <small v-if="updatedTimestamp">
            <FaI icon="pen"/>
            {{updatedTimestamp}}
          </small>
        </VCol>
        <VCol cols="auto" v-if="canInteract">
          <VDialog max-width="250" v-if="canDelete">
            <template v-slot:activator="{on, attrs}">
              <VBtn :disabled="isDeleting" :loading="isDeleting" color="red" fab small v-on="on" v-bind="attrs">
                <FaI icon="trash"/>
              </VBtn>
            </template>
            <template #default="dialog">
              <VCard>
                <VCardTitle class="text-h5" v-t="'actions.confirm'"/>
                <VCardActions>
                  <VSpacer/>
                  <VBtn @click="dialog.value = false; $emit('delete');" color="red darken-1" text>
                    <span v-t="'actions.delete'"/>
                  </VBtn>
                  <VBtn @click="dialog.value = false" color="grey" text>
                    <span v-t="'actions.cancel'"/>
                  </VBtn>
                </VCardActions>
              </VCard>
            </template>
          </VDialog>
          <VBtn :disabled="isDeleting" @click="$emit('edit')" class="ml-6" color="amber" fab small v-if="canUpdate">
            <FaI icon="pen-to-square"/>
          </VBtn>
        </VCol>
      </VRow>
    </VContainer>
    <FormPartitionsSet :target="id" v-if="edit" @cancel="$emit('reset')" @success="onFormSuccess"/>
  </VExpansionPanelContent>
</template>

<script>
import tickerMixin from "top/mixins/tickerMixin.js"
import ProjectListItem from "../../../projects/browse/elements/ProjectListItem.vue";
import FormPartitionsSet from '../../elements/FormPartitionsSet.vue';
import Queries from 'queries/index.js'

export default {
  mixins: [
    tickerMixin,
  ],

  components: {
    ProjectListItem,
    FormPartitionsSet,
  },

  data: () => ({
    edit: false,
    isDeleting: false,
  }),

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  inject: ['expansionPanel'],

  apollo: {
    partitionsSet: {
      query: Queries.PartitionsSet.Single.ExpansionPanelContent,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.isVisible;
      },
    },

    canUpdate() {
      return this.policyChecker({
        policy: 'update',
        type: 'PartitionsSet',
        id: this.id,
        skip: () => !this.isVisible,
      });
    },

    canDelete() {
      return this.policyChecker({
        policy: 'delete',
        type: 'PartitionsSet',
        id: this.id,
        skip: () => !this.isVisible,
      });
    },
  },

  computed: {
    isVisible() {
      return this.expansionPanel.isActive;
    },

    totalWeight() {
      return this.partitionsSet.partitions.reduce((accum, current) => accum + current.weight, 0);
    },

    canInteract() {
      return this.canUpdate || this.canDelete;
    },

    isEdited() {
      const {partitionsSet} = this;
      return partitionsSet.createdAt !== partitionsSet.updatedAt;
    },

    createdTimestamp() {
      const {partitionsSet, getTickerForHumans} = this;
      return `${this.$t('actions.submitted')} ${getTickerForHumans(partitionsSet.createdAt)}`;
    },

    updatedTimestamp() {
      const {isEdited, partitionsSet, getTickerForHumans} = this;
      return isEdited ? `${this.$t('actions.updated')} ${getTickerForHumans(partitionsSet.updatedAt)}` : '';
    },
  },

  watch: {
    isVisible(v) {
      if (!v) {
        this.$emit('reset');
      }
    },
  },

  methods: {
    getPartitionWeight(partition) {
      return Math.round(partition.weight / this.totalWeight * 100);
    },

    async doDelete() {
      this.$emit('deleting');
      try {
        const {data: {partitionsSetDelete: response}} = await this.$apollo.mutate({
          mutation: Queries.PartitionsSet.Write.Delete,
          variables: {
            id: this.id,
          },
        });
        this.$emit('deleted', response);
      } finally {
        this.$emit('reset');
      }
    },

    onFormSuccess(response) {
      this.$emit('updated', response);
      this.$emit('reset');
    },

    onDeleting() {
      this.isDeleting = true;
    },

    onDeleted() {
      this.isDeleting = false;
    },

    doEdit() {
      this.edit = true;
    },

    onReset() {
      this.isDeleting = false;
      this.edit = false;
    },
  },

  created() {
    this.$on('delete', this.doDelete);
    this.$on('deleting', this.onDeleting);
    this.$on('deleted', this.onDeleted);
    this.$on('edit', this.doEdit);
    this.$on('reset', this.onReset);
  },
}
</script>
