import gql from 'graphql-tag';

// Single

const Main = gql`
  query(
    $contextType: ActivityAnalyticsContextTypes!
    $contextId: ID!
    $date: DateRange!
  ) {
    activityAnalytics(
      contextType: $contextType
      contextId: $contextId
      date: $date
    ) {
      awr
      apwr
      abwr
    }
  }
`;


// Subscriptions

const ActivityAnalyticsUpdated = gql`
  subscription onActivityAnalyticsUpdated(
    $contextType: ActivityAnalyticsContextTypes!
    $contextId: ID!
    $date: DateRange!
  ) {
    activityAnalyticsUpdated(
      contextType: $contextType
      contextId: $contextId
      date: $date
    )
    {
      awr
      apwr
      abwr
    }
  }
`;



// Export

const Single = {
  Main,
};

const Subscriptions = {
  ActivityAnalyticsUpdated,
};

export default {
  Single,
  Subscriptions,
};
