<template>
  <VSkeletonLoader v-if="!isReady" :type="dense ? 'list-item-two-line' : 'list-item-three-line'"/>
  <VListItem v-else :to="to" :three-line="!dense" :two-line="dense" v-bind="$attrs">
    <VListItemAvatar tile v-if="icon" size="32">
      <FaI icon="briefcase" class="grey--text text--darken-1"/>
    </VListItemAvatar>
    <VListItemContent>
      <VListItemTitle class="d-flex" :class="{'flex-nowrap': !isMobile}">
        <strong>{{project.name}}</strong>
        <small class="grey--text" :class="{'ml-1': !isMobile}">({{project.id}})</small>
      </VListItemTitle>
      <VListItemSubtitle class="text--primary">
        {{projectType.name}} - {{projectStatus.name}} - {{office.slug.toUpperCase()}}
      </VListItemSubtitle>
      <VListItemSubtitle v-if="!dense">
        <span v-t="'models.general.createdAt'"/> {{createdAt}} - <span v-t="'models.general.updatedAt'"/> {{updatedAt}}
      </VListItemSubtitle>
    </VListItemContent>
    <VListItemAction v-if="$scopedSlots.action">
      <slot name="action"/>
    </VListItemAction>
  </VListItem>
</template>

<script>
  import Queries from 'queries/index.js';
  import {parseISO} from 'date-fns'
  import {formatISODate} from "top/date-fns-format.js";

  const isReadyQueries = ['project', 'projectStatus', 'projectType', 'office'];

  export default {
    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      dense: Boolean,

      icon: Boolean,
    },

    apollo: {
      project: {
        query() {
          return this.dense ? Queries.Project.Browse.RowDense : Queries.Project.Browse.Row;
        },
        variables() {
          return {
            id: this.id,
          };
        },
      },

      projectStatus: {
        query: Queries.ProjectStatus.Single.Name,
        variables() {
          return {
            id: this.project.projectStatus.id,
          };
        },
        skip() {
          return !this.project;
        },
      },

      projectType: {
        query: Queries.ProjectType.Single.Name,
        variables() {
          return {
            id: this.project.projectType.id,
          };
        },
        skip() {
          return !this.project;
        },
      },

      office: {
        query: Queries.Office.Single.Main,
        variables() {
          return {
            id: this.project.office.id,
          };
        },
        skip() {
          return !this.project;
        },
      },

      canView() {
        return this.policyChecker({
          policy: 'view',
          type: 'Project',
          id: this.id,
        });
      },
    },

    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly;
      },

      isReady() {
        return !isReadyQueries.some((query) => this.$apollo.queries[query].loading);
      },

      to() {
        const {canView, project} = this,
            {id, slug} = project;
        return canView && project ? {name: 'project', params: {id, slug}} : undefined;
      },

      createdAt() {
        return this.project ? formatISODate(parseISO(this.project.createdAt)) : null;
      },

      updatedAt() {
        return this.project ? formatISODate(parseISO(this.project.updatedAt)) : null;
      },
    },
  }
</script>
