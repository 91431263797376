<template>
  <form @submit.prevent="doSubmit" autocomplete="off">
    <VContainer>
      <VRow>
        <VCol class="py-0" cols="6" lg="2">
          <KDatePicker
            :validator="$v.form.date"
            hint="forms.general.required"
            label="models.expense.date"
            v-model="form.date"
          />
        </VCol>
        <VCol class="py-0" cols="6" lg="3">
          <KCurrencyField
            :validator="$v.form.amount"
            hint="forms.general.required"
            label="models.expense.amount"
            v-model="form.amount"
          />
        </VCol>
        <VCol class="py-0" cols="12" sm="6" lg="5">
          <KApiSelect
            v-model="form.expenseTypeId"
            :validator="$v.form.expenseTypeId"
            model="expenseType"
            hint="forms.general.required"
          />
        </VCol>
        <VCol class="py-0" cols="6" sm="6" lg="2">
          <KSelect
            v-model="form.salesTaxCodeId"
            :validator="$v.form.salesTaxCodeId"
            :loading="$apollo.queries.salesTaxCodes.loading"
            :items="filteredSalesTaxCodes"
            label="models.salesTaxCode.self"
            hint="forms.general.required"
          />
        </VCol>
        <VCol class="py-0" cols="6" sm="4" lg="3" xl="2" order-sm="2">
          <KCheckbox
            v-model="form.isReimbursable"
          >
            <template #label>
              <FaI class="green--text mr-1" icon="money-bill-wave"/>
              <span v-t="'models.expense.isReimbursable'"/>
              <VDialog max-width="600">
                <template #activator="{on, attrs}">
                  <VBtn icon v-on="on" v-bind="attrs" color="info" class="ml-1">
                    <FaI :icon="['far', 'circle-question']"/>
                  </VBtn>
                </template>
                <VCard>
                  <VCardText>
                    <span v-t="'forms.expense.reimbursable_help'"/>
                  </VCardText>
                </VCard>
              </VDialog>
            </template>
          </KCheckbox>
        </VCol>
        <VCol class="py-0" cols="12" sm="8" lg="9" xl="10" order-sm="1">
          <KTextarea
            :validator="$v.form.description"
            auto-grow
            hint="forms.general.required"
            label="models.expense.description"
            rows="1"
            v-model="form.description"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12">
          Receipts
          <KMediaCollection
            @form-change="onReceiptsChange"
            @is-ready-to-submit-change="isReceiptsReady = $event"
            :initial-ids="receiptIds"
            :max-items="5"
            allowed-types="receipts"
            :max-size="8*1024"
            :key="mediaRenderKey"
          />
        </VCol>
        <VExpandTransition>
          <VCol cols="12" v-if="!hasReceipts">
            <ReceiptAttachAlert/>
          </VCol>
        </VExpandTransition>
      </VRow>
      <VRow>
        <VCol class="py-0" cols="12">
          <FormPartitions
            :partitions="partitionsInitial"
            context="expense"
            :total="form.amount"
            :date="form.date"
            :is-update="isUpdate"
            :bus="bus"
            @update="form.expensePartitions = $event"
            @validator="partitionsValidator = $event"
          />
        </VCol>
      </VRow>
      <VRow class="mt-4">
        <VCol cols="auto">
          <KBtnSubmit :mode="mode" :is-update="isUpdate"/>
        </VCol>
        <VCol cols="auto">
          <VBtn v-if="isUpdate" @click="$emit('cancel')">
            <FaI icon="rotate-left"/>&nbsp;
            <span v-t="'actions.cancel'"/>
          </VBtn>
        </VCol>
      </VRow>
    </VContainer>
  </form>
</template>

<script>
import Queries from 'api/queries';
import FormPartitions from '../../partitionsSet/elements/FormPartitions.vue';
import {
  FormMixin,
  KApiSelect,
  KBtnSubmit,
  KCheckbox,
  KCurrencyField,
  KDatePicker,
  KSelect,
  KSuggest,
  KSwitch,
  KTextarea,
  KTextField,
  ProjectPicker
} from "@/forms/elements"
import {
  betweenLength,
  currency,
  between,
  primaryKey,
  required,
  validatorIsValid,
} from 'validators/index.js'
import {formatISODate} from "@/date-fns-format.js"
import {mapGetters} from "vuex";
import mitt from 'mitt';
import KMediaCollection from "forms/elements/KMediaCollection.vue";
import ReceiptAttachAlert from "views/expenses/elements/ReceiptAttachAlert.vue";

const getBaseFormDefaults = () => ({
  date: formatISODate(new Date()),
  amount: null,
  isReimbursable: true,
  description: '',
  expenseTypeId: '',
  salesTaxCodeId: '',
  receipts: [],
});

const expensePartitionDefaults = {
  weight: 1,
  comment: '',
  isBillable: false,
  projectId: '',
  randomId: 'notsorandom',
};

const expensePartitionToForm = ({id, weight, comment, isBillable, project}) => ({
  weight,
  comment: comment ?? null,
  isBillable,
  projectId: project?.id,
  randomId: id,
});

// const mediaToForm = ({uuid, name, size, mimeType, conversions}) => ({
//   uuid,
//   name,
//   preview_url: conversions?.find(({name}) => name === 'thumbnail')?.url,
//   size,
//   mime_type: mimeType,
//   "extension": "png"
// });

const getFormDefaults = () => ({
  ...getBaseFormDefaults(),
  expensePartitions: [{...expensePartitionDefaults}],
});

const parseUpdatePartitions = (existing, form) => {
  const partitionsCreate = [];
  const partitionsUpdate = [];
  const partitionsDelete = [];

  for (const partition of form) {
    const match = existing.find((e) => partition.projectId === e.project.id);
    if (match) {
      partitionsUpdate.push({id: match.id, ...partition});
    } else {
      partitionsCreate.push(partition);
    }
  }

  for (const partition of existing) {
    const match = form.findIndex((e) => e.projectId === partition.project.id);
    if (match === -1) {
      partitionsDelete.push(partition.id);
    }
  }

  return {
    ...partitionsCreate && {create: partitionsCreate},
    ...partitionsUpdate && {update: partitionsUpdate},
    ...partitionsDelete && {delete: partitionsDelete},
  };
};

export default {
  mixins: [
    FormMixin,
  ],

  components: {
    KApiSelect,
    KBtnSubmit,
    KCheckbox,
    KCurrencyField,
    KSelect,
    KSuggest,
    KSwitch,
    KTextarea,
    KTextField,
    KDatePicker,
    ProjectPicker,
    FormPartitions,
    KMediaCollection,
    ReceiptAttachAlert,
  },

  data: () => ({
    form: getFormDefaults(),
    defaults: getFormDefaults(),
    partitionByAmount: false,
    partitionsValidator: null,
    bus: mitt(),
    isReceiptsReady: false,
    mediaRenderKey: 0,
  }),

  props: {
    userId: [Number, String],
  },

  apollo: {
    expense: {
      query: Queries.Expense.Write.FormFill,
      variables() {
        return {
          id: this.target,
        };
      },
      skip() {
        return !this.isUpdate;
      },
    },

    salesTaxCodes: {
      query: Queries.SalesTaxCode.Forms.Select,
    },

    expenseType: {
      query: Queries.ExpenseType.Forms.Populate,
      variables() {
        return {
          id: this.form.expenseTypeId,
        };
      },
      skip() {
        return !this.form.expenseTypeId;
      },
    },
  },

  validations() {
    return {
      isReceiptsReady: {
        ready: (val) => val === true,
      },
      form: {
        date: {
          required,
        },
        amount: {
          required,
          currency,
          between: between(0.01, 100000),
        },
        description: {
          required,
          betweenLength: betweenLength(2, 1000),
        },
        expenseTypeId: {
          primaryKey,
          required,
        },
        salesTaxCodeId: {
          primaryKey,
          required,
        },
      },
      partitionsValidator: {
        validatorIsValid,
      },
    };
  },

  computed: {
    ...mapGetters('session', ['meId']),

    partitionsInitial() {
      return this.expense?.expensePartitions?.map(expensePartitionToForm)
        ?? this.defaults?.expensePartitions
        ?? [{...expensePartitionDefaults}];
    },

    isTaxExempt() {
      return Boolean(this.expenseType && !this.expenseType.isTaxable);
    },

    filteredSalesTaxCodes() {
      if (!this.salesTaxCodes) {
        return [];
      }

      return this.isTaxExempt ? this.salesTaxCodes.filter((item) => !item.isTaxable) : this.salesTaxCodes;
    },

    receiptIds() {
      return this.expense?.receipts?.map((e) => e.id);
    },

    hasReceipts() {
      return this.form.receipts?.length > 0;
    },
  },

  watch: {
    expense(v) {
      if (!v) {
        return;
      }

      const {form} = this;

      const weightsInvalid = form.expensePartitions.some((e) => e.weight == false); // Falsy comparison is intentional

      form.date = v.date;
      form.amount = v.amount;
      form.isReimbursable = Boolean(v.isReimbursable);
      form.description = v.description;
      form.expenseTypeId = v.expenseType.id;
      form.salesTaxCodeId = v.salesTaxCode.id;

      form.receipts = v.receipts.map(({uuid, name}) => ({
        uuid,
        name,
      }));

      form.expensePartitions = [];
      for (const partition of v.expensePartitions) {
        const partitionForm = Object.assign({}, expensePartitionDefaults);
        partitionForm.projectId = partition.project.id;
        partitionForm.weight = weightsInvalid ? partition.amount : partition.weight;
        partitionForm.isBillable = Boolean(partition.isBillable);
        partitionForm.comment = partition.comment || '';
        partitionForm.randomId = this.getRandomId();
        form.expensePartitions.push(partitionForm);
      }

      for (const e of Object.values(this.partitionsValidator.$each.$iter)) {
        e.projectId.$touch();
      }
    },

    // 'form.amount'(v) {
    //   this.computePartitionAmounts();
    // },

    // partitionByAmount(v) {
    //   if (!v) {
    //     this.computeWeightsFromAmounts();
    //     this.computePartitionAmounts();
    //   }
    // },

    // weightTotal(v) {
    //   this.computePartitionAmounts();
    // },

    filteredSalesTaxCodes(v) {
      if (v.length === 1) {
        this.form.salesTaxCodeId = v[0].id;
      }
    },

    '$v.$dirty'(v) {
      if (v) {
        this.partitionsValidator.$touch();
      }
    },
  },

  methods: {
    expensePartitionVariables({id, amount, weight, comment, isBillable, projectId}) {
      return {
        amount,
        weight: this.partitionByAmount ? null : weight,
        comment: comment ?? null,
        isBillable,
        project: {connect: projectId},
        ...id && {id},
      };
    },

    async createAction() {
      const {expensePartitionVariables, setDefaults, userId, meId, form: {date, amount, isReimbursable, description, expenseTypeId, salesTaxCodeId, expensePartitions, receipts}} = this;
      const variables = {
        date,
        amount,
        isReimbursable,
        userId: userId ?? meId,
        expenseTypeId,
        salesTaxCodeId,
        expensePartitions: expensePartitions.map(expensePartitionVariables),
        ...description && {description},
        receipts: receipts.map(({uuid, name}) => ({
          uuid,
          name,
        })),
      };
      const {data: {expenseCreate: response}} = await this.$apollo.mutate({
        mutation: Queries.Expense.Write.Create,
        variables,
      });

      setDefaults(response);

      return response;
    },

    async updateAction() {
      const {expensePartitionVariables, form: {date, amount, isReimbursable, description, expenseTypeId, salesTaxCodeId, expensePartitions, receipts}, target: id, setDefaults, expense: {expensePartitions: partitionsCurrent}} = this;
      const partitionsOutput = parseUpdatePartitions(partitionsCurrent, expensePartitions);

      const variables = {
        id,
        date,
        amount,
        isReimbursable,
        expenseTypeId,
        salesTaxCodeId,
        description: description ? description : null,
        receipts: receipts.map(({uuid, name}) => ({
          uuid,
          name,
        })),

        ...partitionsOutput.create && {
          expensePartitionsCreate: partitionsOutput.create.map(expensePartitionVariables),
        },
        ...partitionsOutput.update && {
          expensePartitionsUpdate: partitionsOutput.update.map(expensePartitionVariables),
        },
        ...partitionsOutput.delete && {
          expensePartitionsDelete: partitionsOutput.delete
        },
      };
      const {data: {expenseUpdate: response}} = await this.$apollo.mutate({
        mutation: Queries.Expense.Write.Update,
        variables,
      });

      setDefaults(response);

      return response;
    },

    // computeWeightsFromAmounts() {
    //   const {partitionBalance, weightTotal, form: {amount, expensePartitions}} = this;
    //
    //   if (partitionBalance !== 0 || !expensePartitions.some((partition) => Math.round(partition.amount/amount*100) !== Math.round(partition.weight/weightTotal*100))) {
    //     return;
    //   }
    //
    //   const last = expensePartitions.length - 1;
    //   let accumulator = 0;
    //
    //   expensePartitions.forEach((partition, index) => {
    //     if (index === last) {
    //       partition.weight = (10000 - accumulator) / 100;
    //     } else {
    //       partition.weight = Math.round(partition.amount/amount*10000)/100;
    //       accumulator += Math.round(partition.weight*100);
    //     }
    //   });
    // },

    // computePartitionAmounts() {
    //   const {partitionByAmount, weightTotal, multiple, form: {amount, expensePartitions}} = this;
    //
    //   if (!multiple) {
    //     expensePartitions[0].amount = amount;
    //     return;
    //   }
    //
    //   if (partitionByAmount || !weightTotal) {
    //     return;
    //   }
    //
    //   const last = expensePartitions.length - 1;
    //   expensePartitions.forEach((e, index, array) => e.amount = index !== last
    //     ? Math.round(amount * (e.weight ?? 0)/weightTotal*100)/100
    //     : Math.round((amount - array.map((e) => e.amount).slice(0, -1).reduce((prev, e) => prev + e, 0))*100)/100
    //   );
    // },

    setDefaults(response) {
      const {defaults} = this;

      defaults.date = response.date;
      defaults.expenseTypeId = response.expenseType.id;
      defaults.salesTaxCodeId = response.salesTaxCode.id;
      defaults.expensePartitions = response.expensePartitions.map(({project, isBillable, weight}) => ({
        ...expensePartitionDefaults,
        projectId: project.id,
        isBillable: Boolean(isBillable),
        weight,
        randomId: this.getRandomId(),
      }));
    },

    formReset() {
      const {form, defaults} = this;
      Object.keys(getBaseFormDefaults()).forEach((key) => {
        form[key] = defaults[key];
      });
      form.expensePartitions = defaults.expensePartitions.map((row) => ({...row}));
      this.mediaRenderKey++;
      this.bus.emit('reset');
    },

    onReceiptsChange(payload) {
      this.form.receipts = payload ?? [];
    },
  },

  created() {
    this.$on('success', this.formReset);
  },
}
</script>
