import gql from 'graphql-tag';

const SignIn = gql`
  mutation(
    $username: String!
    $password: String!
  ) {
    signin(
      input: {
        username: $username
        password: $password
      }
    ) {
      access_token
    }
  }
`;

const SignOut = gql`
  mutation {
    signout {
      status
      message
    }
  }
`;

const Me = gql`
  query {
    me {
      id
      name
      email
      title
      slug
#      roles {
#        id
#        name
#      }
      settings {
        locale
        timeslipsFormExpand
        projectSearchScoped
        projectPickerScoped
        autoDecimal
        timeFormat
        durationFormat
      }
    }
  }
`;


// Password

const PasswordTest = gql`
  query(
    $password: String!
    $email: String!
  ) {
    passwordTest (
      password: $password
      email: $email
    ) {
      score
      warning
      suggestions
    }
  }
`;

const PasswordForgot = gql`
  mutation(
    $email: String!
  ) {
    forgotPassword (
      input: {
        email: $email
      }
    ) {
      status
      message
    }
  }
`;

const PasswordReset = gql`
  mutation(
    $email: String!
    $token: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updateForgottenPassword (
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $passwordConfirmation
      }
    ) {
      status
      message
    }
  }
`;

const PasswordUpdate = gql`
  mutation(
    $oldPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updatePassword (
      input: {
        old_password: $oldPassword
        password: $password
        password_confirmation: $passwordConfirmation
      }
    ) {
      status
      message
    }
  }
`;


// Settings

const Locale = gql`
  query {
    me {
      id
      settings {
        locale
      }
    }
  }
`;

const TimeslipsFormExpand = gql`
  query {
    me {
      id
      settings {
        timeslipsFormExpand
      }
    }
  }
`;


// Export

const Password = {
  Forgot: PasswordForgot,
  Reset: PasswordReset,
  Test: PasswordTest,
  Update: PasswordUpdate,
};

const Settings = {
  Locale,
  TimeslipsFormExpand,
};

export default {
  SignIn,
  SignOut,
  Me,
  Password,
  Settings,
}
