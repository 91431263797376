<template>
  <VDialog
    scrollable
    transition="dialog-top-transition"
    v-model="showTimekeeper"
  >
    <TimekeeperUI @hide="showTimekeeper = false"/>
  </VDialog>
</template>

<script>
  import TimekeeperUI from "./TimekeeperUI.vue";

  export default {
    components: {
      TimekeeperUI,
    },

    computed: {
      showTimekeeper: {
        get() {
          return this.$route.query.timekeeper !== undefined;
        },
        set(v) {
          if (!v) {
            const current = this.$router.currentRoute;
            const newRoute = {};
            newRoute.path = current.path;
            newRoute.query = current.query ? Object.assign({}, current.query) : {};
            if (typeof newRoute.query.timekeeper !== 'undefined') {
              delete newRoute.query.timekeeper;
            }
            return this.$router.replace(newRoute);
          }
        },
      },
    },
  }
</script>
