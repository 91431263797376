<template>
  <NowLoading v-if="$apollo.loading"/>
  <ListBrowser v-else :title="{path: 'models.project.self', choice: 2}" query="Project.Browse.List">
    <template v-slot:filter="{setVariables, setIsFiltered, showFilter}">
      <ProjectsFilter @input="setVariables" @filtered="setIsFiltered" :show-filter="showFilter" :disables="{officeId: true}" :defaults="defaults"/>
    </template>
    <template v-slot:item="{item}">
      <ProjectListItem :id="item.id" :key="item.id"/>
    </template>
  </ListBrowser>
</template>

<script>
import ListBrowser from "elements/list/ListBrowser.vue";
import ProjectsFilter from "../../projects/browse/elements/ProjectsFilter.vue";
import ProjectListItem from "../../projects/browse/elements/ProjectListItem.vue";

export default {
  components: {
    ListBrowser,
    ProjectsFilter,
    ProjectListItem,
  },

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  computed: {
    defaults() {
      return {officeId: this.id};
    },
  },
}
</script>
