import gql from 'graphql-tag';

// Forms

const Populate = gql`
  query(
    $id: ID!
  ) {
    timeslipType(
      id: $id
    ) {
      id
      name
    }
  }
`;

const Select = gql`
  query {
    timeslipTypes {
      id
      name
    }
  }
`;


// Export

const Forms = {
  Populate,
  Select,
};

export default {
  Forms,
};
