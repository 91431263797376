<template>
  <VTextarea
    :auto-grow="autoGrow"
    :autofocus="autofocus"
    :clearable="clearable"
    :counter="counter"
    :disabled="disabled"
    :error-messages="errorMessages"
    :hint="hintParsed"
    :label="labelParsed"
    :loading="loading"
    :outlined="outlined"
    :persistent-hint="localPersistentHint"
    :placeholder="placeholderParsed"
    :rows="rows"
    :suffix="suffix"
    :type="type"
    :value="value"
    @blur="doTouch"
    @input="onInput"
    @keydown="onKeydown"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </VTextarea>
</template>

<script>
  import KFormTextElementMixin from './KFormTextElementMixin.js'

  export default {
    mixins: [
      KFormTextElementMixin,
    ],

    props: {
      autoGrow: {
        type: Boolean,
        default: false,
      },
      rows: {
        type: [Number, String],
        default: 5,
      },
      type: {
        type: String,
        default: 'text',
      },
    },

    methods: {
      onKeydown(e) {
        this.$emit('keydown', e);
      },
    },
  }
</script>
