<template>
  <NowLoading v-if="$apollo.loading"/>
  <div v-else>
    <VToolbar dense>
      <VAppBarNavIcon v-if="isMobile" @click="drawerActivated = !drawerActivated"/>
      <VToolbarTitle>
        <small>
          <span v-t="{path: 'models.project.self', choice: 1}"/>:
        </small>
        <span class="text-truncate">
          {{project.name}}
        </span>
        <small>
          ({{project.id}})
        </small>
      </VToolbarTitle>
    </VToolbar>
    <VNavigationDrawer clipped v-model="showDrawer" :permanent="!isMobile" :absolute="isMobile" :app="!isMobile" :clipped="!isMobile" color="grey lighten-3">
      <VList nav>
        <VListItem v-for="{name, title, icon} in navItems" :key="name" :to="{name, params: {slug, id}}" exact>
          <VListItemIcon>
            <FaI :icon="icon"/>
          </VListItemIcon>
          <VListItemContent v-t="title"/>
        </VListItem>
      </VList>
    </VNavigationDrawer>
    <KeepAlive>
      <RouterView/>
    </KeepAlive>
  </div>
</template>

<script>
import Queries from 'queries/index.js';

export default {
  data: () => ({
    drawerActivated: false,
  }),

  props: {
    id: {
      type: [String, Number],
      required: true,
    },

    slug: {
      type: String,
      required: true,
    },
  },

  apollo: {
    project: {
      query: Queries.Project.Single.Main,
      variables() {
        return {
          id: this.id,
        };
      },
    },

    canView() {
      return this.policyChecker({
        policy: 'view',
        type: 'Project',
        id: this.id,
        subscribe: true,
      });
    },

    canUpdate() {
      return this.policyChecker({
        policy: 'update',
        type: 'Project',
        id: this.id,
        subscribe: true,
      });
    },

    canTeamViewAny() {
      return this.policyChecker({
        policy: 'viewOnContext',
        type: 'Team',
        args: {
          contextType: 'projects',
          contextId: this.id,
        },
      });
    },

    canTimeslipViewAny() {
      return this.policyChecker({
        policy: 'timeslipViewAny',
        type: 'Project',
        id: this.id,
        subscribe: true,
      });
    },

    canExpenseViewAny() {
      return this.policyChecker({
        policy: 'expenseViewAny',
        type: 'Project',
        id: this.id,
        subscribe: true,
      });
    },

    canProjectRecordViewAny() {
      return this.policyChecker({
        policy: 'projectRecordViewAny',
        type: 'Project',
        id: this.id,
        subscribe: true,
      });
    },

    canInvoiceViewAny() {
      return this.policyChecker({
        policy: 'invoiceViewAny',
        type: 'Project',
        id: this.id,
        subscribe: true,
      });
    },

    canBillingRateViewAny() {
      return this.policyChecker({
        policy: 'billingRateViewAny',
        type: 'Project',
        id: this.id,
        subscribe: true,
      });
    },
  },

  computed: {
    navItems() {
      const {canView, canUpdate, canTeamViewAny, canProjectRecordViewAny, canTimeslipViewAny, canExpenseViewAny, canInvoiceViewAny, canBillingRateViewAny} = this;
      const payload = [];

      if (canView) {
        payload.push({
          name: 'project',
          title: 'ui.overview',
          icon: 'briefcase',
        });
      }

      if (canTeamViewAny) {
        payload.push({
          name: 'project.team',
          title: {path: 'models.team.self', choice: 1},
          icon: 'users',
        });
      }

      if (canProjectRecordViewAny) {
        payload.push({
          name: 'project.projectRecords',
          title: {path: 'models.projectRecord.self', choice: 2},
          icon: 'chart-mixed',
        });
      }

      if (canTimeslipViewAny) {
        payload.push({
          name: 'project.timeslips',
          title: {path: 'models.timeslip.self', choice: 2},
          icon: 'clock',
        });
      }

      if (canExpenseViewAny) {
        payload.push({
          name: 'project.expenses',
          title: {path: 'models.expense.self', choice: 2},
          icon: 'receipt',
        });
      }

      if (canInvoiceViewAny) {
        payload.push({
          name: 'project.invoicing',
          title: {path: 'models.invoice.self', choice: 2},
          icon: 'file-invoice-dollar',
        });
      }

      if (canBillingRateViewAny) {
        payload.push({
          name: 'project.billingRates',
          title: {path: 'models.billingRate.self', choice: 2},
          icon: 'money-check-dollar',
        });
      }

      if (canUpdate) {
        payload.push({
          name: 'project.edit',
          title: 'actions.edit',
          icon: 'pen-to-square',
        });
      }

      return payload;
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    showDrawer: {
      set(v) {
        this.drawerActivated = v;
      },
      get() {
        return !this.isMobile || this.drawerActivated;
      }
    },
  },

  watch: {
    isMobile(v) {
      this.drawerActivated = false;
    },
  },
}
</script>
