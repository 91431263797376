import gql from 'graphql-tag'
import Location from './location.js'
import PaginatorFragment from './paginatorFragment.js'
// Browse

const List = gql`
  query(
    $orderBy: [QueryBuildingsOrderByOrderByClause!]
    $search: String
    $page: Int
    $first: Int!
  ) {
    buildings(
      orderBy: $orderBy
      search: $search
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        id
        name
        slug
        location {
          id
          ...locationCityFlatFragment
        }
      }
    }
  }
  ${PaginatorFragment}
  ${Location.Fragments.CityFlat}
`;


// Single

const IdFromSlug = gql`
  query(
    $slug: String!
  ) {
    buildingBySlug(
      slug: $slug
    )
    {
      id
      slug
    }
  }
`;

const Main = gql`
  query(
    $id: ID!
  ) {
    building(
      id: $id
    ) {
      id
      name
      slug
      location {
        id
        ...locationCityFlatFragment
      }
    }
  }
  ${Location.Fragments.CityFlat}
`;

const Devices = gql`
  query(
    $id: ID!
  ) {
    building(
      id: $id
    ) {
      id
      devices {
        id
        designation
        deviceType {
          id
          name
        }
      }
    }
  }
`;


// Write

const FormFill = gql`
  query(
    $id: ID!
  ) {
    building(
      id: $id
    ) {
      id
      name
      location {
        id
        ...locationFormFillFragment
      }
    }
  }
  ${Location.Write.FormFill}
`;

const Create = gql`
  mutation(
    $name: String
    $street: String
    $streetExtra: String
    $postalCode: String
    $cityId: ID!
  ) {
    buildingCreate(
      input: {
        name: $name
        location: {
          create: {
            street: $street
            streetExtra: $streetExtra
            postalCode: $postalCode
            city: {
              connect: $cityId
            }
          }
        }
      }
    ) {
      id
      name
      location {
        id
        ...locationCityFlatFragment
      }
    }
  }
  ${Location.Fragments.CityFlat}
`;


// Export

const Browse = {
  List,
};

const Single = {
  IdFromSlug,
  Main,
  Devices,
};

const Write = {
  FormFill,
  Create,
};

export default {
  Browse,
  Single,
  Write,
};
