import {client as apolloClient} from "api/index.js";
import store from 'store/index.js'

const backup = {name: 'top'}

const getIdFromSlugQuery = async (query, slug) => {
  const response = await apolloClient.query({
    query,
    variables: {slug},
  });

  if (!response || !response.data) {
    return undefined;
  }

  const payload = Object.values(response.data)[0];

  return payload && payload.id ? payload.id : undefined;
};

export default async (to, from, next) => {
  // If any matched route public requires signin (not public), check if signed in, redirect if not
  const query = to.matched.find((record) => record.meta.slugToID)?.meta?.slugToID;
  if (query) {
    const {params} = to;
    if (!params.id) {
      const id = await getIdFromSlugQuery(query, params.slug);
      if (!id) {
        store.dispatch('flash/warning', 'auth.404redirect');
        next(backup);
      }

      params.id = id;
    } else {
      // Fire query if not done already to populate cache, intentionally not waiting for response in this case
      getIdFromSlugQuery(query, params.slug);
    }
  }

  next();
}
