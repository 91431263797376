<template>
  <VListItem>
    <VLazy v-model="isVisible" width="100%">
      <VSkeletonLoader v-if="!isVisible || $apollo.loading" type="list-item-two-line"/>
      <VListItemContent v-else>
        <VContainer>
          <VRow dense>
            <VCol cols="auto" class="mr-auto">
              {{ project.id }}: {{ project.name }}
            </VCol>
            <VCol cols="auto">
              {{project.office.code}}, {{ createdAt }}
            </VCol>
          </VRow>
          <VRow align="center" dense>
            <VCol cols="6" md="2">
              <KSubmitOnSelect
                :value="project.projectStatus.id"
                :id="id"
                model="projectStatus"
                mutation="Project.Write.Dispatcher"
              />
            </VCol>
            <VCol cols="6" md="2">
              <KSubmitOnSelect
                :value="project.projectType.id"
                :id="id"
                model="projectType"
                mutation="Project.Write.Dispatcher"
              />
            </VCol>
            <VCol cols="12" md="4">
              <span>Lead(s):</span>
              <span>
                {{leadNames}}
              </span>
            </VCol>
            <VCol cols="12" md="4">
              <KSuggest
                :disabled="teamLeadRunning"
                model="user"
                label="Reassign Lead"
                v-model="projectLead"
              >
                <template #append-outer>
                  <VBtn small color="primary" @click="onSetProjectLead" :disabled="!projectLead" :loading="teamLeadRunning">
                    Reassign
                  </VBtn>
                </template>
              </KSuggest>
            </VCol>
          </VRow>
        </VContainer>
      </VListItemContent>
    </VLazy>
  </VListItem>
</template>

<script>
import Queries from 'queries/index.js';
import {mapGetters} from 'vuex';
import {KSubmitOnSelect, KSuggest} from "forms/elements/index.js";
import {formatISODate} from "top/date-fns-format.js";
import {parseISO} from "date-fns";

export default {
  components: {
    KSubmitOnSelect,
    KSuggest,
  },

  data: () => ({
    projectLead: undefined,
    isVisible: false,
    teamLeadRunning: false,
  }),

  props: {
    id: {
      type: [Number, String],
      required: true,
    },

    userId: [Number, String],
  },

  apollo: {
    project: {
      query: Queries.Project.Single.Dispatcher,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.isVisible;
      }
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),
    leads() {
      return this.project?.team?.roleGroups?.find((roleGroup) => roleGroup.role.id === '9')?.users ?? [];
    },

    leadNames() {
      return this.leads.map((e) => e.name).join(', ') || 'None';
    },

    createdAt() {
      return this.project ? formatISODate(parseISO(this.project.createdAt)) : null;
    },
  },

  methods: {
    async onSetProjectLead() {
      const {leads, project, projectLead} = this;
      const newLead = leads.findIndex((e) => e.id === projectLead) === -1 ? projectLead : null;
      const removeLeads = leads.map((e) => e.id).filter((id) => id !== projectLead);

      this.teamLeadRunning = true;

      try  {
        if (newLead) {
          await this.$apollo.mutate({
            mutation: Queries.Team.Write.AttachUsers,
            variables: {
              id: project.team.id,
              input: [
                {
                  user: newLead,
                  role: "9", // ProjectLead
                }
              ],
            }
          });
        }

        if (removeLeads.length) {
          await this.$apollo.mutate({
            mutation: Queries.Team.Write.DetachUsers,
            variables: {
              id: project.team.id,
              input: removeLeads.map((id) => ({user: id})),
            },
          });
        }

        this.projectLead = undefined;

        this.$emit('lead', project.id);
      } finally {
        this.teamLeadRunning = false;
      }
    },
  },
}
</script>
