<template>
  <NowLoading v-if="$apollo.loading"/>
  <VContainer v-else>
    <VToolbar dense color="grey lighten-3">
      <VToolbarTitle v-t="{path: 'models.invoice.self', choice: 2}"/>
      <VSpacer/>
      <VFadeTransition>
        <VBtn @click="toggleFilter" text>
          <FaI size="2x" icon="chevron-down" :rotation="showFilter ? 180 : 0" style="transition: all 0.3s ease;"/>
          <div>
            <VFadeTransition leave-absolute>
              <FaI size="2x" key="a" v-if="isFiltered" icon="filter"/>
              <FaI size="2x" key="b" v-else :icon="['far', 'filter']"/>
            </VFadeTransition>
          </div>
        </VBtn>
      </VFadeTransition>
    </VToolbar>
    <InvoiceBrowseTab
        :filter-disables="{user: true}"
        :filter-defaults="{user: id}"
        :show-filter="showFilter"
        @filtered="setIsFiltered"
    />
  </VContainer>
</template>

<script>
import InvoiceBrowseTab from "../../invoices/browse/elements/InvoiceBrowseTab.vue";

export default {
  components: {
    InvoiceBrowseTab,
  },

  data: () => ({
    showFilter: false,
    isFiltered: false,
  }),

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  watch: {
    showFilter(v) {
      if (v) {
        this.showCreate = false;
      }
    },
  },

  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    setIsFiltered(v) {
      this.isFiltered = v;
    },
  },
}
</script>
