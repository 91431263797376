<template>
  <VRow>
    <VCol cols="12">
      <VCard>
        <VCardTitle v-t="titleMsg"/>
        <VCardText>
          <span v-t="helpMsg"/>
          <FormPasswordForgot @success="handleSuccess"/>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
  import FormPasswordForgot from "./FormPasswordForgot.vue";

  export default {
    components: {
      FormPasswordForgot,
    },

    computed: {
      isNewUser() {
        return this.$route.query.newuser !== undefined;
      },

      titleMsg() {
        return `views.forgotPassword.${this.isNewUser ? 'newuser.' : ''}title`;
      },

      helpMsg() {
        return `views.forgotPassword.${this.isNewUser ? 'newuser.' : ''}help`;
      },
    },

    methods: {
      handleSuccess({data: {forgotPassword: {status, message}}}) {
        if (status === 'EMAIL_SENT') {
          this.$store.dispatch('flash/info', message);
          const redirect = this.$route.query.redirect;
          if (redirect) {
            this.$router.push(redirect);
          } else {
            this.$router.push({name: 'signin'});
          }
        } else { // HTTP response success but server returned 'rejected' response
          this.$store.dispatch('flash/error', message);
        }
      },

      handleError(response) {
        this.$store.dispatch('flash/error', 'auth.failed');
      },
    },
  }
</script>
