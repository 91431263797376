<template>
  <div>
    <SettingsContainer name="autoDecimal" :items="items"/>
    <KCurrencyField class="mt-2" label="views.settings.autoDecimal.demo" v-model="demoCurrency"/>
  </div>
</template>

<script>
import {KCurrencyField} from "forms/elements/index.js";
import SettingsContainer from "./SettingsContainer.vue";

const options = [
  {
    label: 'auto',
    value: true,
  },
  {
    label: 'manual',
    value: false,
  },
];

export default {
  components: {
    SettingsContainer,
    KCurrencyField,
  },

  data: () => ({
    demoCurrency: 5.00,
  }),

  computed: {
    items() {
      return options.map(({label, value}) => ({
        label: this.$t(`views.settings.autoDecimal.${label}`),
        value,
      }));
    },
  },
}
</script>
