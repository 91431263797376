import gql from 'graphql-tag';

// Fragments

const timelineFragment = gql`
  fragment timelineFragment on Issue
  {
    timeline {
      ... on TimelineInterface {
        id
        createdAt
        user {
          id
        }
      }
      ... on IssueEvent {
        issueEventType {
          id
        }
        device {
          id
        }
      }
      ... on IssueComment {
        updatedAt
        comment
      }
    }
  }
`;


// Subscriptions

const OnIssueCommentCreated = gql`
  subscription onIssueCommentCreated($issueId: ID!) {
    issueCommentCreated(issue: $issueId) {
      id
      comment
      createdAt
      updatedAt
      user {
        id
      }
    }
  }
`;


// Single

const Inspection = gql`
  query(
    $id: ID!
  ) {
    issue(
      id: $id
    ) {
      id
      subject
      createdAt
      updatedAt
      closedAt
      issueCommentsCount
      inspection {
        id
      }
      inspectionArea {
        id
        name
      }
      devices {
        id
      }
      user {
        id
      }
      latestEvents {
        id
        device {
          id
          designation
        }
        issueEventType {
          id
        }
      }
    }
  }
`;

const InspectionContent = gql`
  query(
    $id: ID!
  ) {
    issue(
      id: $id
    ) {
      id
      ...timelineFragment
    }
  }
  ${timelineFragment}
`;


// Write

const FormFill = gql`
  query(
    $id: ID!
  ) {
    issue(
      id: $id
    ) {
      id
      subject
      inspection {
        id
      }
      inspectionArea {
        id
        name
      }
      devices {
        id
      }
    }
  }
`;

const Devices = gql`
  query(
    $id: ID!
  ) {
    issue(
      id: $id
    ) {
      id
      devices {
        id
        designation
        building {
          id
        }
        deviceGroup {
          id
        }
      }
    }
  }
`;

const InspectionId = gql`
  query(
    $id: ID!
  ) {
    issue(
      id: $id
    ) {
      id
      inspection {
        id
      }
    }
  }
`;

const Create = gql`
  mutation(
    $subject: String!
    $inspectionId: ID!
    $inspectionAreaId: ID!
    $deviceIds: [ID!]!
  ) {
    issueCreate(
      input: {
        subject: $subject
        inspection: {
          connect: $inspectionId
        },
        inspectionArea: {
          connect: $inspectionAreaId
        },
        devices: {
          connect: $deviceIds
        }
      }
    ) {
      id
      subject
      createdAt
      updatedAt
      closedAt
      inspection {
        id
      }
      inspectionArea {
        id
        name
      }
      devices {
        id
      }
      user {
        id
      }
    }
  }
`;

const Update = gql`
  mutation(
    $id: ID!
    $subject: String
    $inspectionAreaId: ID
  ) {
    issueUpdate(
      id: $id,
      input: {
        subject: $subject
        inspectionArea: {
          connect: $inspectionAreaId
        }
      }
    ) {
      id
      subject
      createdAt
      updatedAt
      closedAt
      inspection {
        id
      }
      inspectionArea {
        id
        name
      }
      devices {
        id
      }
      user {
        id
      }
    }
  }
`;

const AddDevices = gql`
  mutation(
    $id: ID!
    $deviceIds: [ID!]!
  ) {
    issueUpdate(
      id: $id,
      input: {
        devices: {
          connect: $deviceIds
        }
      }
    ) {
      id
      createdAt
      updatedAt
      closedAt
      devices {
        id
      }
    }
  }
`;


// Export

const Subscriptions = {
  OnIssueCommentCreated,
};

const Single = {
  Inspection,
  InspectionContent,
};

const Write = {
  FormFill,
  Devices,
  InspectionId,
  Create,
  Update,
  AddDevices,
};

export default {
  Subscriptions,
  Single,
  Write,
};
