import gql from 'graphql-tag';

// Forms

const Search = gql`
  query(
    $search: String!
    $country: ID
    $countryDivision: ID
#    $scopeType: CityScopeType!
#    $scopeId: ID!
    $first: Int!
  ) {
    cities(
      search: $search
      country: $country
      countryDivision: $countryDivision
#      scope: {
#        type: $scopeType
#        id: $scopeId
#      }
      first: $first
    ) {
      data {
        id
        name
        countryDivision {
          id
        }
        country {
          id
        }
      }
    }
  }
`;

const Populate = gql`
  query(
    $id: ID!
  ) {
    city(
      id: $id
    ) {
      id
      name
      countryDivision {
        id
      }
      country {
        id
      }
    }
  }
`;


// Export

const Forms = {
  Search,
  Populate,
};

export default {
  Forms,
};
