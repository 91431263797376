<template>
  <KCurrencyField
    :disabled="!isBillable"
    :loading="$apollo.loading"
    :validator="validator"
    hint="forms.timeslip.billingRate_helpblock"
    label="models.timeslipPartition.billingRate"
    :value="value"
    @input="updateValue"
  >
    <template #append v-if="isBillable">
      <VFadeTransition hide-on-leave>
        <small class="grey--text" v-if="isBillingRateDefault">
          &nbsp;(<span v-t="'forms.timeslip.billingRate_defaultLabel'"/>)
        </small>
        <VBtn
          v-else-if="!$apollo.loading && defaultBillingRate"
          color="grey lighten-2"
          @click="applyDefaultBillingRate"
          x-small
        >
          <span v-t="'forms.timeslip.billingRate_setDefaultBtn'"/>
        </VBtn>
      </VFadeTransition>
    </template>
  </KCurrencyField>
</template>

<script>
import Queries from 'queries/index.js'
import KCurrencyField from "forms/elements/KCurrencyField.vue";
import currencyMixin from "top/mixins/currencyMixin.js";

export default {
  mixins: [
    currencyMixin,
  ],

  components: {
    KCurrencyField,
  },

  props: {
    value: Number,

    isBillable: Boolean,

    lock: Boolean,

    userId: {
      type: [Number, String],
      required: true,
    },

    projectId: [Number, String],

    date: [Number, String],

    validator: Object,
  },

  apollo: {
    defaultBillingRate: {
      query: Queries.BillingRate.Single.Suggestion,
      variables() {
        const {userId, projectId, date} = this;
        return {
          user: userId,
          ...projectId && {project: projectId},
          ...date && {date},
        };
      },
      update: ({billingRateSuggestion}) => billingRateSuggestion?.rate,
    },
  },

  computed: {
    isBillingRateDefault() {
      return this.defaultBillingRate === this.value;
    },
  },

  watch: {
    value(v) {
      if (v === null || v === undefined) {
        this.applyDefaultBillingRate();
      }
    },

    defaultBillingRate(v) {
      if (!this.lock) {
        this.updateValue(v);
      }
    },

    isBillable(v) {
      if (v && !this.value) {
        this.applyDefaultBillingRate();
      }
    }
  },

  methods: {
    updateValue(v) {
      this.$emit('input', v);
    },

    applyDefaultBillingRate() {
      this.updateValue(this.defaultBillingRate);
    },
  },
}
</script>
