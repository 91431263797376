<template>
    <div>
      <strong v-if="!isActive" v-t="'inspection.performanceCab.selectOne'"/>
      <VContainer v-else>
        <PerformanceDataCell
          v-for="field in fields"
          :key="field"
          :device="device"
          :field="field"
          :settings="settings"
          :show-title="true"
          :value="deviceData[field]"
          @input="onInput(field, $event)"
        />
      </VContainer>
    </div>
</template>

<script>
  import {NAMES} from './perfConsts.js'
  import PerformanceDataCell from './PerformanceDataCell.vue'

  const speedFields = ['SpeedUp', 'SpeedDown'];

  export default {
    components: {
      PerformanceDataCell,
    },

    props: {
      devices: {
        type: Array,
        required: true,
      },

      store: {
        type: Object,
        required: true,
      },

      settings: {
        type: Object,
        default: () => ({}),
      },
    },

    inject: [
      'performancePattern',
    ],

    computed: {
      device() {
        return this.devices.length === 1 ? this.devices[0] : null;
      },

      isActive() {
        return this.device !== null;
      },

      deviceData() {
        return this.device ? this.store.find((e) => e.name === this.device)?.data : undefined;
      },

      fields() {
        return this.settings?.speed === 'hide' ? NAMES.filter((e) => !speedFields.includes(e)) : NAMES;
      },
    },

    methods: {
      onInput(key, value) {
        this.$emit('change', {
          name: this.device,
          key,
          value
        });
      },
    },
  }
</script>
