<template>
  <div class="d-flex flex-nowrap">
    <VSelect
      :items="optionsLocalized"
      :label="labels.orderBy"
      :value="column"
      @input="$emit('update:column', $event)"
      :clearable="clearable"
    />
    <VSwitch :input-value="orderDesc" @change="$emit('update:order-desc', $event)">
      <template v-slot:prepend>
        <span class="grey--text" v-t="'forms.controls.order'"/>
      </template>
      <template v-slot:append>
        <FaI :icon="orderIcon" class="black--text"/>
        <span class="black--text ml-1">
          {{orderMsg}}
        </span>
      </template>
    </VSwitch>
  </div>
</template>

<script>
  export default {
    props: {
      options: {
        type: Array,
        required: true,
        validator: (v) => {
          const props = ['text', 'value'];
          return !v.some((e) => // false if any object test fails
            typeof e !== 'object' || e === null || props.some((prop) =>  // true if object is missing any prop
              !e.hasOwnProperty(prop) // true if prop is missing
            )
          );
        },
      },

      column: String,

      orderDesc: Boolean,

      clearable: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      labels() {
        return {
          orderBy: this.$t('forms.controls.orderBy'),
          orderAsc: this.$t('forms.controls.asc'),
          orderDesc: this.$t('forms.controls.desc'),
        };
      },

      orderIcon() {
        return this.orderDesc ? 'arrow-down-big-small' : 'arrow-up-small-big';
      },

      orderMsg() {
        const {orderDesc, labels} = this;
        return orderDesc ? labels.orderDesc : labels.orderAsc;
      },

      optionsLocalized() {
        const optionListParse = ({text, value}) => ({
          text: this.$t(text),
          value,
        });

        return this.options.map(optionListParse);
      },

      disableOrderDirection() {
        return Boolean(!this.column); // falsy comparison is intentional
      },
    },
  }
</script>
