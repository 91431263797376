<template>
  <VHover v-model="hover" :disabled="disabled">
    <VChip small :outlined="!projectPickerScoped" @click="onScopeToggle" :disabled="disabled" color="blue lighten-3">
      <FaI icon="magnifying-glass" class="mr-1"/>
      <VExpandXTransition>
        <div v-show="hover" class="mr-1">
          <span v-t="'forms.project.searchScope.title'"/>:
          <span v-if="projectPickerScoped" key="member" v-t="'forms.project.searchScope.member'"/>
          <span v-else key="all" v-t="'forms.project.searchScope.all'"/>
        </div>
      </VExpandXTransition>
      <VScrollXTransition mode="out-in">
        <FaI size="lg" :key="`icon-${projectPickerScoped}`" :icon="projectPickerScoped ? 'user' : 'globe'" class="blue--text"/>
      </VScrollXTransition>
    </VChip>
  </VHover>
</template>

<script>
import {mapGetters} from 'vuex';
import Queries from "queries/index.js";

export default {
  data: () => ({
    hover: false,
  }),

  props: {
    disabled: Boolean,
  },

  apollo: {
    projectPickerScoped: {
      query: Queries.User.Single.ProjectPickerScoped,
      variables() {
        return {
          id: this.meId,
        };
      },
      update: ({user}) => user?.settings?.projectPickerScoped,
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    scopeVariables() {
      return this.projectPickerScoped ? {teamMember: {user: this.meId}} : undefined;
    },
  },

  methods: {
    onScopeToggle() {
      if (this.$apollo.queries.projectPickerScoped.loading) {
        return;
      }

      const {meId: id, projectPickerScoped} = this;
      this.$apollo.mutate({
        mutation: Queries.User.Settings.ProjectPickerScoped,
        variables: {
          id,
          projectPickerScoped: Boolean(!projectPickerScoped),
        },
        optimisticResponse: {
          userUpdate: {
            __typename: "User",
            id,
            settings: {
              __typename: "UserSettings",
              projectPickerScoped: Boolean(!projectPickerScoped),
            },
          },
        },
      });
    },
  },
}
</script>
