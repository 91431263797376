<template>
  <VExpansionPanelHeader ripple>
    <VSkeletonLoader type="list-item" v-if="$apollo.loading"/>

    <VRow align="center" no-gutters v-else style="max-width: 100%; padding-right: 28px; margin-right: -24px">
      <VCol v-if="partitionsSet.description" cols="12">
        <em>
          {{partitionsSet.description}}
        </em>
      </VCol>
      <VCol cols="12">
        {{project.name}}
        <small class="text">
          ({{project.id}})
        </small>
        - <strong>
          {{featureWeight}}%
        </strong>
        <VChip small color="blue lighten-3" class="ml-1" v-on="on" :input-value="value">
          +{{othersCount}}
        </VChip>
        <VChip small v-if="isTimeslipBillable" class="mx-1" color="orange lighten-5">
          <FaI icon="clock" class="grey--text text--darken-1 mr-1"/>
          <FaI icon="file-invoice-dollar" class="orange--text text--darken-2"/>
        </VChip>
        <VChip small v-if="isExpenseBillable" class="mx-1" color="orange lighten-5">
          <FaI icon="receipt" class="grey--text text--darken-1 mr-1"/>
          <FaI icon="file-invoice-dollar" class="orange--text text--darken-2"/>
        </VChip>
      </VCol>
    </VRow>
  </VExpansionPanelHeader>
</template>

<script>
import Queries from 'queries/index.js'

export default {
  props: {
    id: {
      type: [Number, String],
      required: true,
    },

    focusProject: [Number, String],
  },

  apollo: {
    partitionsSet: {
      query: Queries.PartitionsSet.Single.ExpansionPanelHeader,
      variables() {
        return {
          id: this.id,
        };
      },
    },

    project: {
      query: Queries.Project.Forms.Populate,
      variables() {
        return {
          id: this.featureProjectId,
        }
      },
      skip() {
        return !this.featureProjectId;
      },
    },
  },

  computed: {
    firstPartition() {
      return this.partitionsSet.partitions[0];
    },

    featureProjectId() {
      return this.firstPartition?.project?.id;
    },

    featureWeight() {
      const target = this.firstPartition.weight;
      return this.totalWeight ? Math.round(target/this.totalWeight*100) : 100;
    },

    totalWeight() {
      return this.partitionsSet.partitions.reduce((accum, current) => accum + current.weight, 0);
    },

    isTimeslipBillable() {
      return this.partitionsSet.partitions.some((e) => Boolean(e?.isTimeslipBillable));
    },

    isExpenseBillable() {
      return this.partitionsSet.partitions.some((e) => Boolean(e?.isExpenseBillable));
    },

    othersCount() {
      return (this.partitionsSet.partitions.length - 1) ?? 0;
    },
  },

  methods: {
    isBillableTestMethod(partition) {
      const {context} = this;
      return ((!context || context === 'timeslip') && Boolean(partition.isTimeslipBillable))
        || ((!context || context === 'expense') && Boolean(partition.isExpenseBillable));
    },
  },
}
</script>
