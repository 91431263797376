<template>
  <div>
    <SettingsContainer name="durationFormat" :items="items"/>
    <KDurationPicker class="mt-2" label="views.settings.durationFormat.demo" v-model="demoValue"/>
  </div>
</template>

<script>
import SettingsContainer from "./SettingsContainer.vue";
import {KDurationPicker} from "forms/elements/index.js"

const options = [
  'clock',
  'decimal',
];

export default {
  components: {
    SettingsContainer,
    KDurationPicker,
  },

  data: () => ({
    demoValue: 9000,
  }),

  computed: {
    items() {
      return options.map((value) => ({
        label: this.$t(`views.settings.durationFormat.${value}`),
        value,
      }));
    },
  },
}
</script>
