<template>
  <VMenu
    bottom
    left
  >
    <template
      v-slot:activator="{on}"
    >
      <VBtn
        class="ma-0"
        dark
        icon
        v-on="on"
      >
        <FaI
          icon="ellipsis-v"
        />
      </VBtn>
    </template>

    <VList>
      <KMenuItem
        :icon="item.icon"
        :key="item.id"
        :title="item.title"
        :to="item.to"
        v-for="item in links"
      />
    </VList>
  </VMenu>
</template>

<script>
  import KMenuItem from "elements/KMenuItem.vue";

  export default {
    components: {
      KMenuItem,
    },

    props: {
      links: {
        type: Array,
        required: true,
      },
    },
  }
</script>
