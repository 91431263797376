export default {
  props: {
    disabled: Boolean,

    hint: [String, Object],

    label: [String, Object],

    loading: [Boolean, String],

    outlined: Boolean,

    persistentHint: Boolean,

    validator: Object,

    value: {
      type: [Number, String],
      required: true,
    },
  },

  computed: {
    errorMessages() {
      const validator = this.validator;
      if (!(validator && validator.$error)) {
        return [];
      }

      const buffer = [];
      const keys = Object.keys(validator);

      for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        if (!key.startsWith('$') && typeof validator[key] === 'boolean' && !validator[key]) {
          buffer.push(
            this.$t(
              `vuelidate.${key}`,
              Object.assign({attribute: this.labelParsed || ''}, validator.$params[key])
            )
          );
        }
      }

      return buffer;
    },

    hintParsed() {
      return this.parseDisplayMessage(this.hint);
    },

    labelParsed() {
      return this.parseDisplayMessage(this.label);
    },

    localPersistentHint() {
      return this.persistentHint !== undefined ? this.persistentHint : true
    }
  },

  methods: {
    onInput(v) {
      this.$emit('input', v);
      this.doTouch();
    },

    doTouch() {
      if (this.validator) {
        this.validator.$touch();
      }
    },
  },
}
