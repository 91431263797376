<template>
  <div class="d-flex flex-nowrap align-center">
    <KCheckbox
      :disabled="isLocked"
      v-bind="$attrs"
      v-on="$listeners"
      :loading="$apollo.queries.projectData.loading"
      :hint="localHint"
      :value="value"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name"/>
      <!--    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">-->
      <!--      <slot :name="name" v-bind="slotData" />-->
      <!--    </template>-->
    </KCheckbox>

    <VDialog  max-width="500px">
      <template #activator="{on, attrs}">
        <VFadeTransition>
          <VBtn v-if="showHint" :color="iconColor" icon elevation="0" v-on="on" v-bind="attrs">
            <FaI :icon="hintIcon"/>
          </VBtn>
        </VFadeTransition>
      </template>
      <template #default="dialog">
        <VCard>
          <VCardTitle v-t="'forms.activityPartition.billableCheckbox_dialog'"/>
          <VCardText class="text--primary">
            {{localHint}}
          </VCardText>
          <VCardActions>
            <VSpacer/>
            <VBtn color="primary" text @click="dialog.value = false">
              <span v-t="'ui.ok'"/>
            </VBtn>
          </VCardActions>
        </VCard>
      </template>
    </VDialog>
  </div>
</template>

<script>
import KCheckbox from '../../../forms/elements/KCheckbox.vue';
import Queries from "api/queries";

export default {
  components: {
    KCheckbox,
  },

  props: {
    project: [Number, String],

    context: {
      type: String,
      required: true,
      validator: (v) => ['timeslip', 'expense'].includes(v),
    },

    value: {
      type: Boolean,
      required: true,
    },

    isUpdate: Boolean,

    lock: Boolean,
  },

  apollo: {
    projectData: {
      query: Queries.Project.Single.IsBillable,
      variables() {
        return {
          id: this.project,
        };
      },
      skip() {
        return !this.project;
      },
      update: (response) => response.project,
    },
  },

  watch: {
    defaultValue(v) {
      if (!this.lock && !this.isUpdate && v !== null) {
        this.$emit('input', v);
      }
    },
  },

  computed: {
    rule() {
      return (this.context === 'timeslip' ? this.projectData?.settings?.isTimeslipBillable : this.projectData?.settings?.isExpenseBillable) ?? 'NEUTRAL';
    },

    isLocked() {
      return this.lock || ['ALWAYS', 'NEVER'].includes(this.rule);
    },

    defaultValue() {
      if (['ALWAYS', 'USUALLY'].includes(this.rule)) {
        return true;
      }

      if (['SOMETIMES', 'NEVER'].includes(this.rule)) {
        return false;
      }

      return null;
    },

    localHint() {
      return this.$t(`forms.activityPartition.billableType.${this.rule.toLowerCase()}.hint`);
    },

    showHint() {
      return !this.lock && this.defaultValue !== null && (this.value !== this.defaultValue || this.isLocked);
    },

    hintIcon() {
      return this.isLocked ? ['far', 'circle-info'] : 'circle-exclamation';
    },

    iconColor() {
      return this.isLocked ? 'grey' : 'orange darken-2';
    },
  },
}
</script>
