<template>
  <VContainer>
    <VRow>
      <VCol cols="12" class="text-h5" v-t="'ui.services'"/>
      <VSkeletonLoader type="actions" v-if="$apollo.loading"/>
      <VCol cols="12">
        <VScrollXTransition group class="d-flex flex-wrap">
          <VCard v-for="link in links" :key="link.route" :to="{name: link.route}" :disabled="link.disabled" class="ma-3">
            <VRow dense>
              <VCol cols="auto" class="pa-0">
                <VBtn color="blue lighten-3" :disabled="link.disabled" height="75" width="150">
                  <VCol class="black--text lighten-5">
                    <VRow cols="12" justify="center">
                      <FaI :icon="link.icon" size="2x"/>
                    </VRow>
                    <VRow cols="12" class="mt-3" justify="center">
                      {{link.message}}
                    </VRow>
                  </VCol>
                </VBtn>
              </VCol>
            </VRow>
          </VCard>
        </VScrollXTransition>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <ProjectRecordStatus v-if="canViewProjectRecordInterface"/>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
  import ProjectRecordStatus from "./ProjectRecordStatus.vue";
  import {mapGetters} from "vuex";
  import Queries from 'queries/index.js';

  export default {
    components: {
      ProjectRecordStatus,
    },

    apollo: {
      canCreateTimeslip() {
        return this.policyChecker({
          policy: 'create',
          type: 'Timeslip',
          args: {
            user: {
              connect: this.meId,
            }
          },
        });
      },

      canCreateExpense() {
        return this.policyChecker({
          policy: 'create',
          type: 'Expense',
          args: {
            user: {
              connect: this.meId,
            }
          },
        });
      },

      canViewProjects() {
        return this.policyChecker({
          policy: 'viewAny',
          type: 'Project',
        });
      },

      canViewProjectRecordInterface() {
        return this.policyChecker({
          policy: 'viewInterface',
          type: 'ProjectRecord',
        });
      },

      canDoInvoicing() {
        return this.policyChecker({
          policy: 'viewScoped',
          type: 'Invoice',
          args: {
            user: this.meId,
          },
        });
      },

      canDoPerformance() {
        return this.policyChecker({
          policy: 'performanceTool.interface',
        });
      },

      projectRecordsStatus: {
        query: Queries.ProjectRecord.Single.StatusReport,
        variables() {
          return {
            id: this.meId
          };
        },
        skip() {
          return !this.canViewProjectRecordInterface;
        },
        update: (response) => response?.user,
      },
    },

    computed: {
      ...mapGetters('session', ['isSignedIn', 'meId']),

      links() {
        const {canCreateTimeslip, canCreateExpense, canViewProjects, canDoInvoicing, canDoPerformance, canViewProjectRecordInterface} = this;
        const payload = [];
        if (canCreateTimeslip) {
          payload.push({
            message: this.$tc('models.timeslip.self', 2),
            route: 'timeslips',
            icon: 'clock',
          });
        }

        if (canCreateExpense) {
          payload.push({
            message: this.$tc('models.expense.self', 2),
            route: 'expenses',
            icon: 'receipt',
          });
        }

        if (canViewProjects) {
          payload.push({
            message: this.$tc('models.project.self', 2),
            route: 'projects',
            icon: 'briefcase',
          });
        }

        if (canViewProjectRecordInterface) {
          payload.push({
            message: this.$tc('models.projectRecord.shorthand', 2),
            route: 'projectRecords',
            icon: 'chart-mixed',
          });

          payload.push({
            message: this.$tc('views.dispatcher.title'),
            route: 'dispatcher',
            icon: 'signs-post'
          });
        }

        if (canDoInvoicing) {
          payload.push({
            message: this.$t('views.invoicing.title'),
            route: 'invoicing',
            icon: 'file-invoice-dollar',
          });
        }

        if (canDoPerformance) {
          payload.push({
            message: this.$t('views.performance.short'),
            route: 'performance',
            icon: 'stopwatch',
          });
        }

        return payload;
      }
    }
  }
</script>
