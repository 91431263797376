<template>
  <VCard color="grey lighten-4">
    <VToolbar dark color="primary">
      <VToolbarTitle v-t="'views.timekeeper.title'"/>
      <VSpacer/>
      <VBtn class="mr-2" icon :input-value="showChart" outlined v-if="$vuetify.breakpoint.xsOnly" @click="showChart = !showChart">
        <FaI icon="chart-column" size="lg"/>
      </VBtn>
      <VBtn text dark @click="$emit('hide')">
        <FaI icon="xmark" size="lg" class="mr-2"/>
        <span v-t="'actions.close'"/>
      </VBtn>
    </VToolbar>
    <TimeslipDaysChart v-if="$vuetify.breakpoint.smAndUp || showChart" :from="timekeeperDurationFrom" :to="today"/>
    <ActivityAnalyticsCard
      v-if="$vuetify.breakpoint.smAndUp || showChart"
      context="USER"
      :id="meId"
      subscribe
    />
    <FormTimekeeper/>
    <TimekeeperRecentActivity/>
  </VCard>
</template>

<script>
  import FormTimekeeper from "./FormTimekeeper.vue";
  import TimeslipDaysChart from "./TimeslipDaysChart.vue";
  import TimekeeperRecentActivity from "./TimekeeperRecentActivity.vue";
  import ActivityAnalyticsCard from '../timeslips/elements/ActivityAnalyticsCard.vue';
  import {mapGetters} from 'vuex';

  export default {
    data: () => ({
      showChart: false,
    }),

    components: {
      FormTimekeeper,
      TimeslipDaysChart,
      ActivityAnalyticsCard,
      TimekeeperRecentActivity,
    },

    computed: {
      ...mapGetters('session', ['today', 'timekeeperDurationFrom', 'meId']),
    },
  }
</script>
