<template>
  <ListBrowserFilterWrapper
    export-mutation="Project.Browse.Export"
    :form.sync="form"
    :form-default="parsedDefaults"
    :order-options="parsedOrderOptions"
    :show-filter="showFilter"
    :form-function="$options.formFunction"
    :show-search="!hideSearch"
    @input="onInput"
    @filtered="onFiltered"
  >
    <VRow>
      <VCol class="py-0" cols="12">
        <KSuggest
          :disabled="disables.user"
          clearable
          model="user"
          label="models.user.self"
          v-model="form.user"
        />
      </VCol>
    </VRow>
  </ListBrowserFilterWrapper>
</template>

<script>
  import ListBrowserFilterWrapper from "elements/list/ListBrowserFilterWrapper.vue";
  import {KSuggest} from "forms/elements/index.js";

  const filterDefaults = {
    user: undefined,
  };

  const orderOptions = [
    {
      text: 'models.general.createdAt',
      value: 'CREATED_AT',
      secondary: true,
    },
    {
      text: 'models.general.updatedAt',
      value: 'UPDATED_AT',
    },
  ];

  const formFunction = ({user}) => {
    return {
      ...user && {user},
    };
  };

  export default {
    formFunction,

    components: {
      ListBrowserFilterWrapper,
      KSuggest,
    },

    data() {
      return {
        form: Object.assign({}, filterDefaults, this.defaults),
      };
    },

    props: {
      showFilter: Boolean,

      defaults: {
        type: Object,
        default: () => ({}),
      },

      disables: {
        type: Object,
        default: () => ({}),
      },

      sortDefault: {
        type: String,
        default: 'CREATED_AT',
      },

      orderDescDefault: {
        type: Boolean,
        default: true,
      },

      hideSearch: Boolean,
    },

    computed: {
      parsedDefaults() {
        const payload = {};

        for (const key in filterDefaults) {
          payload[key] = this.defaults.hasOwnProperty(key) ? this.defaults[key] : filterDefaults[key];
        }

        return payload;
      },

      parsedOrderOptions() {
        const {sortDefault, orderDescDefault} = this;
        const payload = [];

        orderOptions.forEach((item) => {
          payload.push(item.value === sortDefault
              ? Object.assign({}, item, {default: true, defaultDesc: orderDescDefault})
              : item
          );
        });

        return payload;
      },
    },

    methods: {
      onInput(v) {
        this.$emit('input', v);
      },

      onFiltered(v) {
        this.$emit('filtered', v);
      }
    },
  }
</script>
