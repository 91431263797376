<template>
  <div>
    <VBtnToggle>
      <VBtn
        :color="btnColor"
        small
        :disabled="isDisabled"
        :href="remoteUrl"
        @click="onClick"
        v-bind="$attrs"
        :width="mainButtonLength"
      >
        <VFadeTransition hide-on-leave>
          <span key="i" v-if="isIdle">
            <FaI icon="file-spreadsheet"/>&nbsp;
            <span v-t="'actions.export'"/>
          </span>
          <span key="r" v-else-if="isReady">
            <FaI icon="download"/>&nbsp;
            <span v-t="'ui.ready'"/>
          </span>
          <span key="f" v-else-if="isError">
            <FaI icon="exclamation-triangle"/>&nbsp;
            <span v-t="'ui.error'"/>
          </span>
          <VProgressLinear
              :value="progressValue"
              buffer-value="0"
              color="info"
              height="10"
              key="p"
              rounded
              stream
              striped
              v-else
          />
        </VFadeTransition>
      </VBtn>
<!--      <VBtn small :color="btnColor" @click="doShare()" text v-if="isReady && allowsShare">-->
<!--        <FaI :icon="['far', 'share-nodes']" class="mr-1"/>-->
<!--      </VBtn>-->
      <VMenu v-if="isIdle && hasOptions" offset-y left :close-on-content-click="false">
        <template v-slot:activator="{on, attrs, value}">
            <VBtn small :color="btnColor" v-bind="attrs" v-on="on" width="50">
              <VBadge :value="localOptions.length > 0" color="deep-purple accent-4" dot offset-y="5">
                <FaI icon="chevron-down" :rotation="value ? 180 : 0" style="transition: all 0.3s ease;"/>
              </VBadge>
            </VBtn>
        </template>
        <VListItemGroup v-model="localOptions" multiple style="background-color: white">
          <VListItem
              dense
              v-for="i in options"
              :key="i.name"
              :value="i.name"
              active-class="deep-purple--text text--accent-4"
          >
            <template v-slot:default="{ active }">
              <VListItemTitle v-t="i.text"/>
              <VListItemAction>
                <VCheckbox
                  dense
                  :input-value="active"
                  color="deep-purple accent-4"
                ></VCheckbox>
              </VListItemAction>
            </template>
          </VListItem>
        </VListItemGroup>
      </VMenu>
      <VBtn v-else-if="isError || isReady" small :color="btnColor" width="50" @click="$emit('reset')">
        <FaI icon="rotate-left"/>
      </VBtn>
    </VBtnToggle>
    <VSnackbar :color="snackbarColor" :timeout="10000" :vertical="isReady" v-model="showSnackbar">
      <span v-if="isReady" v-t="'views.export.isReady'"/>
      <span v-else-if="isError" v-t="'views.export.isError'"/>
      <span v-else v-t="'views.export.isSubmitted'"/>
      <template v-if="isReady || isError" v-slot:action="{attrs}">
        <VBtn :href="remoteUrl" @click="showSnackbar = false" text v-bind="attrs" v-if="isReady">
          <FaI icon="download" class="mr-1"/>
          <span v-t="'actions.download'"/>
        </VBtn>
<!--        <VBtn @click="doShare()" text v-bind="attrs" v-if="isReady && allowsShare">-->
<!--          <FaI :icon="['far', 'share-nodes']" class="mr-1"/>-->
<!--          <span v-t="'actions.share'"/>-->
<!--        </VBtn>-->
        <VBtn @click="showError = true; showSnackbar = false" text v-if="isError">
          <span v-t="'ui.learnMore'"/>
        </VBtn>
        <VBtn @click="showSnackbar = false" class="ml-4" text v-bind="attrs">
          <FaI icon="xmark"/>
        </VBtn>
      </template>
    </VSnackbar>
    <VDialog max-width="600" v-if="isError" v-model="showError">
      <VCard>
        <VCardTitle v-t="'views.export.isError'"/>
        <VCardText>
        <span v-if="dataExportLocal && dataExportLocal.message">
          {{ dataExportLocal.message }}
        </span>
          <span v-else v-t="'forms.general.submitError'"/>
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import Queries from 'queries/index.js';
import {get} from "lodash-es";
import {uuidv7} from "@kripod/uuidv7";
import {findExtensionByMimeType} from './media/mimeTypes.js';

export default {
  inheritAttrs: false,

  data: () => ({
    dataExportId: undefined,
    dataExportUuid: undefined,
    isRunning: false,
    localError: false,
    showSnackbar: false,
    showError: false,
    pollCount: 0,
    subscription: undefined,
    localOptions: [],
    // file: undefined,
    // filename: undefined,
    // url: undefined,
  }),

  props: {
    mutation: {
      type: [Object, String],
      required: true,
    },

    variables: {
      type: Object,
      default: () => {},
    },

    disabled: Boolean,

    options: {
      type: Array,
      validator: (v) => v.every((i) => {
        if (typeof i !== 'object' || i === null) return false;

        return 'name' in i &&
            typeof i.name === 'string' &&
            'text' in i &&
            typeof i.text === 'string' &&
            (!('default' in i) || typeof i.default === 'boolean');
      }),
    },
  },

  apollo: {
    dataExport: {
      query: Queries.DataExport.Single.Main,
      variables() {
        return {
          uuid: this.dataExportUuid,
        };
      },
      skip() {
        return !this.dataExportUuid;
      },
      subscribeToMore: {
        document: Queries.DataExport.Subscriptions.DataExportUpdated,
        variables() {
          return {
            uuid: this.dataExportUuid,
          };
        },
        skip() {
          return !this.dataExportUuid;
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          const cache = subscriptionData?.data?.dataExportUpdated ?? {};
          const broadcast = previousResult?.dataExport ?? {};
          const dataExport = {...cache, ...broadcast};

          return {
            dataExport,
          };
        },
      },
    },
  },

  computed: {
    getMutation() {
      const {mutation} = this;
      let payload = undefined;

      if (typeof mutation === "object" && mutation !== null) {
        payload = mutation;
      } else if (typeof mutation === 'string') {
        payload = get(Queries, mutation);
      }

      if (payload === undefined) {
        console.warn('ExportButton mutation is invalid');
      }

      return payload;
    },

    dataExportLocal() {
      return this.dataExportUuid && this.dataExportUuid === this.dataExport?.uuid ? this.dataExport : undefined;
    },

    mutationName() {
      return this.getMutation?.definitions?.[0]?.selectionSet?.selections?.[0]?.name?.value;
    },

    status() {
      if (this.localError) {
        return 'Error';
      }

      if (this.isRunning) {
        return 'Submitting';
      }

      if (this.url) {
        return 'Ready';
      }

      return this.dataExportLocal?.status ?? 'Idle';
    },

    isIdle() {
      return this.status === 'Idle';
    },

    isReady() {
      return this.status === 'Ready';
    },

    isError() {
      return this.status === 'Error';
    },

    remoteUrl() {
      return this.dataExportLocal?.payload?.url;
    },

    isDisabled() {
      return this.disabled || !['Idle', 'Error', 'Ready'].includes(this.status);
    },

    progressValue() {
      switch(this.status) {
        case 'Queued':
          return 40;
        case 'Processing':
          return 80;
        default:
          return 10;
      }
    },

    btnColor() {
      switch(this.status) {
        case 'Ready':
          return 'success';
        case 'Error':
          return 'error';
        default:
          return 'grey lighten-5';
      }
    },

    snackbarColor() {
      switch(this.status) {
        case 'Ready':
          return 'success';
        case 'Error':
          return 'error';
        default:
          return 'info';
      }
    },

    hasOptions() {
      return this.options?.length > 0;
    },

    mainButtonLength() {
      return !(this.isIdle && this.hasOptions) && !this.isError && !this.isReady ? 150 : 100;
    },

    // allowsShare() {
    //   return Boolean(navigator.share);
    // },
  },

  watch: {
    variables: {
      handler(v) {
        this.$emit('reset');
      },
      deep: true,
    },

    mutation(v) {
      this.$emit('reset');
    },

    status(v) {
      this.$emit(v.toLowerCase())
    },

    options: {
      handler(v) {
        this.setDefaultOptions();
      },
      immediate: true,
    },

    // dataExportLocal(v) {
    //   if (v) {
    //     this.getFile();
    //   }
    // }
  },

  methods: {
    onClick() {
      if (this.status === 'Idle') {
        return this.doExport();
      }

      if (this.status === 'Error') {
        this.showError = true;
      }

      // if (this.status === 'Ready') {
      //   this.doShare();
      // }
    },

    setDefaultOptions() {
      const newOptions = [];
      if (this.options) {
        this.options.forEach((i) => {
          if (i.default) {
            newOptions.push(i.name);
          }
        });
      }
      this.localOptions = newOptions;
    },

    async doExport() {
      const {mutationName, getMutation: mutation, variables: filterVariables, localOptions} = this;
      this.$emit('submitting');
      this.dataExportUuid = uuidv7();
      try {
        const variables = {
          ...filterVariables,
          uuid: this.dataExportUuid,
        };

        localOptions.forEach((e) => {
          variables[e] = true;
        });
        const response = await this.$apollo.mutate({
          mutation,
          variables,
        });
        this.$emit('success', response.data[mutationName]);
        return response;
      } catch {
        this.localError = true;
      } finally {
        this.$emit('submitted');
      }
    },

    async doDelete(uuid) {
      const response = await this.$apollo.mutate({
        mutation: Queries.DataExport.Write.Delete,
        variables: {
          uuid,
        },
      });
      this.$emit('deleted', response.data.dataExportDelete.id);
    },

    // async getFile() {
    //   const url = this.dataExportLocal?.payload?.url;
    //   if (!url) {
    //     return;
    //   }
    //
    //   const response = await fetch(url);
    //   if (!response.ok) {
    //     return;
    //   }
    //
    //   // Extract the filename from the Content-Disposition header, if available
    //   const contentDisposition = response.headers.get('Content-Disposition');
    //   const mimeType = response.headers.get('Content-Type');
    //   let filename = null;
    //   if (contentDisposition && contentDisposition.includes('attachment')) {
    //     const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    //     const matches = filenameRegex.exec(contentDisposition);
    //     if (matches != null && matches[1]) {
    //       filename = matches[1].replace(/['"]/g, ''); // Remove any surrounding quotes
    //     }
    //   }
    //
    //   if (!filename) {
    //     const extension = findExtensionByMimeType(mimeType);
    //     if (!extension) {
    //       return;
    //     }
    //     filename = `Export.${extension}`;
    //   }
    //
    //   const blob = await response.blob();
    //   this.file = new File([blob], filename, {type: blob.type});
    //   this.filename = filename;
    //   this.url = URL.createObjectURL(this.file);
    // },
    //
    // async doShare() {
    //   if (!navigator.share || !navigator.canShare(this.file)) {
    //     return;
    //   }
    //
    //   try {
    //     await navigator.share({
    //       title: this.filename,
    //       text: this.filename,
    //       files: [this.file],
    //     });
    //   } catch (e) {
    //     console.error('Error sharing:', e.name, e.message);
    //   }
    // },

    doCleanup() {
      if (this.dataExportUuid) {
        this.doDelete(this.dataExportUuid); // Not waiting for promise on purpose
      }
      this.dataExportUuid = undefined;
      // if(this.url) {
      //   URL.revokeObjectURL(this.url);
      //   this.url = undefined;
      // }
      // this.file = undefined;
      // this.filename = undefined;
    }
  },

  created() {
    this.$on('reset', function () {
      this.doCleanup();
      this.dataExportId = undefined;
      this.isLoading = false;
      this.localError = false;
      this.showSnackbar = false;
      this.showError = false;
      this.pollCount = 0;
    });
    this.$on('submitting', function () {
      this.showSnackbar = true;
      this.isRunning = true;
    });
    this.$on('submitted', function () {
      this.isRunning = false;
    });
    this.$on('success', function (response) {
      this.dataExportId = response.id;
    });
    this.$on('error', function () {
      this.showSnackbar = true;
    });
    this.$on('ready', function () {
      this.showSnackbar = true;
    });
  },

  beforeDestroy() {
    this.doCleanup();
  },
}
</script>
