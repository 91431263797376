<template>
  <VBottomNavigation
    app
    dark
  >
    <VBtn
      :exact="item.exact || false"
      :key="index"
      :to="item.to"
      v-for="(item, index) in navLinks"
    >
      <span
        v-t="item.message"
      />
      <FaI
        :icon="item.icon"
      />
    </VBtn>
  </VBottomNavigation>
</template>

<script>
  export default {
    props: {
      navLinks: {
        type: Array,
        required: true,
      },
    },
  }
</script>
