<template>
  <VListItem :color="color">
    <VListItemIcon>
      <FaI :icon="icon"/>
    </VListItemIcon>

    <VListItemContent>
      <VListItemTitle>
        <VRow no-gutters>
          <VCol>
            {{name}}
          </VCol>
          <VCol v-if="showValue" cols="auto">
            {{value}}
          </VCol>
        </VRow>
      </VListItemTitle>
    </VListItemContent>
  </VListItem>
</template>

<script>
  import {STATUSES, STYLES, PROGRAMS} from './timerPatterns.js'

  export default {
    props: {
      field: {
        type: String,
        required: true,
      },

      deviceId: {
        type: [String, Number],
        required: true,
      }
    },

    inject: [
      'performancePattern',
    ],

    computed: {
      name() {
        return this.$t(`forms.inspectionPerformance.${this.field}.title`);
      },

      value() {
        if (!this.showValue) {
          return null;
        }

        const {timestamps} = this.performancePattern;

        let ms;

        if (this.status === STATUSES.RUNNING) {
          ms = timestamps[this.step] - timestamps[this.start - 1];
        } else { // IF FINISHED
          ms = timestamps[this.stop - 1] - timestamps[this.start - 1];
        }

        return (ms/1000).toFixed(1);
      },

      mode() {
        return this.performancePattern.mode;
      },

      step() {
        return this.performancePattern.step;
      },

      start() {
        return this.mode ? PROGRAMS[this.mode][this.field].start : null;
      },

      stop() {
        return this.mode ? PROGRAMS[this.mode][this.field].stop : null;
      },

      status() {
        if (!this.mode) {
          return STATUSES.IDLE;
        }

        const step = this.step,
          startDiff =  this.start - step;

        if (startDiff === 1) {
          return STATUSES.STARTING;
        } else if (startDiff > 0) {
          return STATUSES.STAGED;
        }

        const stopDiff = this.stop - step;

        if (stopDiff === 1) {
          return STATUSES.STOPPING;
        } else if (stopDiff > 0) {
          return STATUSES.RUNNING;
        }

        return STATUSES.FINISHED;
      },

      icon() {
        return STYLES[this.status].icon || null;
      },

      color() {
        return STYLES[this.status].class || null;
      },

      showValue() {
        return this.status >= STATUSES.RUNNING;
      }
    }
  }
</script>
