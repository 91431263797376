import ActivityExport from './activityExport.js'
import Auth from './auth.js'
import ActivityRatio from './activityRatio.js'
import ActivityAnalytics from './activityAnalytics.js'
import BillingRate from './billingRate.js'
import Building from './building.js'
import City from './city.js'
import Company from './company.js'
import CompanyType from './companyType.js'
import Contact from './contact.js'
import Country from './country.js'
import CountryDivision from './countryDivision.js'
import DataExport from './dataExport.js'
import Device from './device.js'
import Expense from './expense.js'
import ExpensePartition from './expensePartition.js'
import ExpenseType from './expenseType.js'
import Inspection from './inspection.js'
import InspectionActivity from './inspectionActivity.js'
import InspectionArea from './inspectionArea.js'
import InspectionPerformance from './inspectionPerformance.js'
import InspectionType from './inspectionType.js'
import Invoice from "./invoice.js"
import InvoiceLine from "./invoiceLine.js"
import Issue from './issue.js'
import IssueComment from './issueComment.js'
import IssueEvent from './issueEvent.js'
import IssueEventType from './issueEventType.js'
import Location from './location.js'
import Media from './media.js'
import Office from './office.js'
import PolicyTest from './policyTest.js'
import Project from './project.js'
import ProjectRecord from './projectRecord.js'
import ProjectStatus from './projectStatus.js'
import ProjectType from './projectType.js'
import Role from './role.js'
import SalesTaxCode from './salesTaxCode.js'
import Team from './team.js'
import TimeslipCollection from './timeslipCollection.js'
import TimeslipDay from './timeslipDay.js'
import TimeslipType from './timeslipType.js'
import Timeslip from './timeslip.js'
import User from './user.js'
import PartitionsSet from './partitionsSet.js'
import TimeslipPartition from './timeslipPartition.js'
import {enableExperimentalFragmentVariables} from "graphql-tag";

enableExperimentalFragmentVariables();

export default {
  ActivityExport,
  Auth,
  ActivityRatio,
  ActivityAnalytics,
  BillingRate,
  Building,
  City,
  Company,
  CompanyType,
  Contact,
  Country,
  CountryDivision,
  DataExport,
  Device,
  Expense,
  ExpensePartition,
  ExpenseType,
  Inspection,
  InspectionActivity,
  InspectionArea,
  InspectionPerformance,
  InspectionType,
  Invoice,
  InvoiceLine,
  Issue,
  IssueComment,
  IssueEvent,
  IssueEventType,
  Location,
  Media,
  Office,
  PartitionsSet,
  PolicyTest,
  Project,
  ProjectRecord,
  ProjectStatus,
  ProjectType,
  Role,
  SalesTaxCode,
  Team,
  TimeslipCollection,
  TimeslipDay,
  TimeslipPartition,
  TimeslipType,
  Timeslip,
  User,
};
