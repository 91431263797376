import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters('session', ['locale']),
  },

  methods: {
    getCurrency(value, currency = 'CAD') {
      // Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat

      if (!value && value !== 0) {
        return '';
      }

      const formatter = new Intl.NumberFormat(
        this.locale,
        {
          style: 'currency',
          currency,
        });

      return formatter.format(value);
    },
  },
}
