<template>
  <VExpansionPanelContent class="grey lighten-4">
    <VSkeletonLoader type="list-item-two-line" v-if="$apollo.queries.projectRecord.loading"/>
    <div v-else-if="projectRecord" v-show="!edit">
      <VRow>
        <VCol cols="12">
          <JsonPretty :data="projectRecordForDisplay"/>
<!--          <pre>-->
<!--            {{projectRecordForDisplay}}-->
<!--          </pre>-->
        </VCol>
        <VCol cols="12" md="7" lg="8">
          <span v-t="{path: 'models.project.self', choice: 1}"/>:
          <VCard flat>
            <ProjectListItem :id="projectRecord.project.id" dense/>
          </VCard>
        </VCol>
      </VRow>
      <VRow class="grey--text py-2" justify="space-between" no-gutters>
        <VCol class="d-flex flex-column" cols="auto">
          <small>
            {{createdTimestamp}}
          </small>
          <small v-if="updatedTimestamp">
            <FaI icon="pen"/>
            {{updatedTimestamp}}
          </small>
        </VCol>
        <VCol cols="auto" v-if="canInteract">
          <VDialog max-width="250" v-if="canDelete" v-model="showDelete">
            <template v-slot:activator="{on}">
              <VBtn :disabled="isDeleting" :loading="isDeleting" color="red" fab small v-on="on">
                <FaI icon="trash"/>
              </VBtn>
            </template>

            <VCard>
              <VCardTitle class="text-h5" v-t="'actions.confirm'"/>
              <VCardActions>
                <VSpacer/>
                <VBtn @click="doDelete" color="red darken-1" text>
                  <span v-t="'actions.delete'"/>
                </VBtn>
                <VBtn @click="showDelete = false" color="grey" text>
                  <span v-t="'actions.cancel'"/>
                </VBtn>
              </VCardActions>
            </VCard>
          </VDialog>
<!--          <VBtn :disabled="isDeleting" @click="toggleEdit" class="ml-6" color="amber" fab small v-if="canUpdate">-->
<!--            <FaI icon="pen-to-square"/>-->
<!--          </VBtn>-->
        </VCol>
      </VRow>
    </div>
<!--    <FormExpense :target="expense.id" @cancel="endEdit" @success="onUpdate" v-if="edit"/>-->
  </VExpansionPanelContent>
</template>

<script>
import tickerMixin from "top/mixins/tickerMixin.js";
import ProjectListItem from "top/views/projects/browse/elements/ProjectListItem.vue";
import UserThumbnail from "elements/UserThumbnail.vue";
import Queries from "queries/index.js";
import {JsonPretty} from "elements/index.js";
import {mapGetters} from "vuex";

export default {
  mixins: [
    tickerMixin,
  ],

  components: {
    JsonPretty,
    ProjectListItem,
    UserThumbnail,
  },

  data: () => ({
    edit: false,
    showDelete: false,
    isDeleting: false,
  }),

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  inject: ['expansionPanel'],

  apollo: {
    projectRecord: {
      query: Queries.ProjectRecord.Single.ExpansionPanelContent,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.isVisible;
      },
    },

    // canUpdate() {
    //   return this.policyChecker({
    //     policy: 'update',
    //     type: 'ProjectRecord',
    //     id: this.id,
    //     skip: () => !this.isVisible,
    //   });
    // },

    canDelete() {
      return this.policyChecker({
        policy: 'delete',
        type: 'ProjectRecord',
        id: this.id,
        skip: () => !this.isVisible,
      });
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    isVisible() {
      return this.expansionPanel.isActive;
    },

    isMe() {
      const {projectRecord, meId} = this;
      return projectRecord ? projectRecord.createdBy.id === meId : false;
    },

    canInteract() {
      const {canUpdate, canDelete} = this;
      return canUpdate || canDelete;
    },

    createdTimestamp() {
      const {projectRecord, getTickerForHumans} = this;
      return projectRecord
          ? `${this.$t('actions.created')} ${getTickerForHumans(projectRecord.createdAt)}`
          : '';
    },

    updatedTimestamp() {
      const {projectRecord, getTickerForHumans} = this;
      return projectRecord && projectRecord.updatedAt !== projectRecord.createdAt
          ? `${this.$t('actions.updated')} ${getTickerForHumans(projectRecord.updatedAt)}`
          : '';
    },

    projectRecordForDisplay() {
      const {projectRecord} = this;
      // return projectRecord ? JSON.stringify(JSON.parse(projectRecord.recordRaw), null, 2) : null;
      return projectRecord ? JSON.parse(projectRecord.recordRaw) : null;
    },
  },

  watch: {
    'expansionPanel.isActive'(v) {
      if (!v) {
        this.endEdit();
      }
    },
  },

  methods: {
    onUpdate(response) {
      this.$emit('update', response);
      this.endEdit();
    },

    async doDelete() {
      this.showDelete = false;
      await this.deleteAction();
    },

    async deleteAction() {
      const {id} = this;

      this.isDeleting = true;
      try {
        const {data: {projectRecordDelete: response}} = await this.$apollo.mutate({
          mutation: Queries.ProjectRecord.Write.Delete,
          variables: {
            id,
          },
        });
        this.$emit('delete', response);
      } finally {
        this.isDeleting = false;
      }
    },

    toggleEdit() {
      this.edit = !this.edit;
    },

    endEdit() {
      this.edit = false;
    },
  }
}
</script>
