<template>
  <VAvatar
    :size="thumbnailSize"
    color="grey lighten-3"
    rounded
    v-if="!id || $apollo.loading"
  >
    <FaI
      :size="placeholderSize"
      class="grey--text"
      icon="user"
    />
  </VAvatar>
  <VTooltip
    bottom
    v-else
    :disabled="noTooltip"
  >
    <template
      v-slot:activator="{on}"
    >
      <VAvatar
        :class="avatarClass"
        :size="thumbnailSize"
        rounded
        @click="onClick"
        v-on="on"
      >
        <img :src="imgUrl" :alt="company.name">
      </VAvatar>
    </template>
    <span>
      {{company.name}}
    </span>
  </VTooltip>
</template>

<script>
  import Queries from 'queries/index.js';
  import generateAvatar from 'top/avatars.js';

  const placeholderSizes = {
    lg: '3x',
    md: '2x',
    sm: 'lg',
    xs: 'md',
  },
    thumbnailSizes = {
    lg: 96,
    md: 48,
    sm: 32,
    xs: 24,
  };

  export default {
    props: {
      id: [Number, String],

      disabled: Boolean,

      size: {
        type: [String],
        default: 'md',
        validator: (v) => ['lg', 'md', 'sm', 'xs'].includes(v),
      },

      noTooltip: Boolean,
    },

    apollo: {
      company: {
        query: Queries.Company.Single.Thumbnail,
        variables() {
          return {
            id: this.id,
          };
        },
        skip() {
          return !this.id;
        },
      },
    },

    computed: {
      avatarClass() {
        return this.disabled ? null : 'cursor-pointer';
      },

      placeholderSize() {
        return placeholderSizes[this.size];
      },

      thumbnailSize() {
        return thumbnailSizes[this.size];
      },

      imgUrl() {
        const {company} = this;
        return company ? company?.identicon?.url || generateAvatar(company.name) : undefined;
      },
    },

    methods: {
      onClick() {
        if (!this.disabled) {
          const {company} = this;
          this.$router.push({name: 'company', params: {slug: company.slug, id: company.id}});
        }
      },
    },
  }
</script>
