 <template>
  <VRow>
    <VCol cols="12">
      <KTextField
        v-model="form.comment"
        :validator="$v.form.comment"
        label="models.projectRecord.lost.comment"
        hint="forms.general.required"
      />
    </VCol>

    <VCol cols="12" sm="6">
      <KTextField
        v-model="form.awardedFirm"
        :validator="$v.form.awardedFirm"
        label="models.projectRecord.lost.awardedFirm"
        hint="forms.general.whenKnown"
      />
    </VCol>

    <VCol cols="12" sm="6">
      <KCurrencyField
        v-model="form.awardedFee"
        :validator="$v.form.awardedFee"
        label="models.projectRecord.lost.awardedFee"
        hint="forms.general.whenKnown"
      />
    </VCol>
  </VRow>
</template>

<script>
import {KTextField, KCurrencyField, scopedFormsMixin} from "forms/elements/index.js";
import {between, betweenLength, required, currency} from "validators/index.js";

export default {
  mixins: [
    scopedFormsMixin,
  ],

  components: {
    KCurrencyField,
    KTextField,
  },

  data: () => ({
    form: {
      comment: null,
      awardedFirm: null,
      awardedFee: null,
    },
  }),

  validations: {
    form: {
      comment: {
        required,
        betweenLength: betweenLength(2, 250),
      },
      awardedFirm: {
        betweenLength: betweenLength(2, 100),
      },
      awardedFee: {
        currency,
        between: between(0, 9999999.99),
      },
    },
  },
}
</script>
