<template>
  <div class="d-flex flex-nowrap">
    <div>{{firstPartition.project.name}}</div>
    <small class="text mx-1">
      ({{firstPartition.project.id}})
    </small>
    <div class="mr-1">-</div>
    <strong>
      {{featureWeight}}%
    </strong>
    <VChip x-small color="blue lighten-3" class="ml-1">
      +{{othersCount}}
    </VChip>
    <em class="grey--text text--darken-1">
      {{partitionsSet.description}}
    </em>
  </div>
</template>

<script>
import PartitionsSetPickerDialog from './PartitionsSetPickerDialog.vue';
import Queries from 'queries/index.js';

const contexts = ['timeslip', 'expense'];

export default {
  components: {
    PartitionsSetPickerDialog,
  },

  props: {
    context: {
      type: String,
      validator: (v) => contexts.includes(v),
    },

    id: {
      type: [Number, String],
      required: true,
    },
  },

  apollo: {
    partitionsSet: {
      query: Queries.PartitionsSet.Single.Main,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },

  computed: {
    firstPartition() {
      return this.partitionsSet.partitions[0];
    },

    featureWeight() {
      const target = this.firstPartition.weight;
      return this.totalWeight ? Math.round(target/this.totalWeight*100) : 100;
    },

    totalWeight() {
      return this.partitionsSet.partitions.reduce((accum, current) => accum + current.weight, 0);
    },

    isBillable() {
      return this.partitionsSet.partitions.some(this.isBillableTestMethod);
    },

    othersCount() {
      return (this.partitionsSet.partitions.length - 1) ?? 0;
    },
  },

  methods: {
    isBillableTestMethod(partition) {
      const {context} = this;
      return ((!context || context === 'timeslip') && Boolean(partition.isTimeslipBillable))
      || ((!context || context === 'expense') && Boolean(partition.isExpenseBillable));
    },
  },
}
</script>
