<template>
  <TransitionGroup name="slide-from-right" class="flash-container" tag="div">
    <FlashMessage v-for="message in messages" :key="message.id" :message="message"/>
  </TransitionGroup>
</template>

<script>
  import FlashMessage from './FlashMessage.vue';

  export default {
    components: {
      FlashMessage,
    },

    computed: {
      messages() {
        return this.$store.getters['flash/messages'];
      },
    },
  }
</script>

<!--<style>-->
<!--  .flash-container {-->
<!--    position: fixed;-->
<!--    right: 0;-->
<!--    bottom: 32px;-->
<!--    z-index: 1080; /* Bootstrap 4's highest z-index is 1070 */-->
<!--  }-->

<!--  /* Vue transition 'slide-from-right' */-->
<!--  .slide-from-right-enter-active {-->
<!--    transition: .5s ease-out;-->
<!--  }-->


<!--  .slide-from-right-leave-active {-->
<!--    transition: .5s ease-in;-->
<!--  }-->

<!--  .slide-from-right-enter, .slide-from-right-leave-to {-->
<!--    transform: translateX(100%);-->
<!--  }-->

<!--  .slide-from-right-move {-->
<!--    transition: transform .5s;-->
<!--  }-->
<!--</style>-->
