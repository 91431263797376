<template>
  <form @submit.prevent="doSubmit" @keyup.enter="doSubmit" style="width: 100%">
    <VContainer>
      <VRow>
        <VCol cols="12">
          <KDatePicker
            v-model="form.startAt"
            :validator="$v.form.startAt"
            label="models.billingRate.startAt"
            hint="forms.general.required"
            :min="startAtMin"
            :max="startAtMax"
          />
        </VCol>

        <VCol cols="12">
          <KDatePicker
            v-model="form.endAt"
            :validator="$v.form.endAt"
            label="models.billingRate.endAt"
            hint="forms.general.optional"
            :min="endAtMin"
            :max="endAtMax"
            clearable
          />
        </VCol>

        <VCol cols="12">
          <KCurrencyField
            v-model="form.rate"
            :validator="$v.form.rate"
            label="models.billingRate.rate"
            hint="forms.general.required"
          />
        </VCol>
      </VRow>

      <KBtnSubmit :mode="mode" class="mt-3"/>
    </VContainer>
  </form>
</template>

<script>
import Queries from 'queries/index.js';
import {KDatePicker, KCurrencyField, FormMixin, KBtnSubmit} from "forms/elements/index.js"
import {required, maxDate, betweenDate, minDate, currency, between} from 'validators/index.js'
import {formatISODate} from "@/date-fns-format";
import {addYears, subYears} from "date-fns";

export default {
  mixins: [
    FormMixin,
  ],

  components: {
    KBtnSubmit,
    KDatePicker,
    KCurrencyField,
  },

  data() {
    return {
      form: {
        startAt: undefined,
        endAt: undefined,
        rate: '',
      },
    };
  },

  props: {
    projects: Array,

    users: Array,
  },

  apollo: {
    billingRate: {
      query: Queries.BillingRate.Write.FormFill,
      variables() {
        return {
          id: this.target,
        };
      },
      skip() {
        return !this.isUpdate;
      },
    },
  },

  validations() {
    return {
      form: {
        startAt: {
          required,
          betweenDate: betweenDate(this.startAtMin, this.startAtMax),
        },
        endAt: {
          betweenDate: betweenDate(this.endAtMin, this.endAtMax),
          minDate: minDate(this.form.startAt),
        },
        rate: {
          required,
          currency,
          between: between(20, 9999),
        },
      },
    };
  },

  computed: {
    startAtMin() {
      return formatISODate(subYears(new Date(), 5));
    },

    startAtMax() {
      return this.form.endAt ? this.form.endAt : formatISODate(addYears(new Date(), 1));
    },

    endAtMin() {
      return this.form.startAt ? this.form.startAt : formatISODate(subYears(new Date(), 5));
    },

    endAtMax() {
      return formatISODate(addYears(new Date(), 20));
    },
  },

  watch: {
    billingRate(v) {
      if (!v) {
        return;
      }

      const form = this.form;
      form.startAt = v.startAt;
      form.endAt = v.endAt ?? undefined;
      form.rate = v.rate;
    },
  },

  methods: {
    async createAction() {
      const {form: {startAt, endAt, rate}, users, projects} = this;
      const {data: {billingRateCreate: response}} = await this.$apollo.mutate({
        mutation: Queries.BillingRate.Write.Create,
        variables: {
          startAt,
          endAt: endAt ? endAt : null,
          rate,
          users,
          projects,
        }
      });
      return response;
    },

    async updateAction() {
      const {billingRate: {id}, form: {startAt, endAt, rate}} = this;
      const {data: {billingRateUpdate: response}} = await this.$apollo.mutate({
        mutation: Queries.BillingRate.Write.Update,
        variables: {
          id,
          startAt,
          endAt: endAt ? endAt : null,
          rate,
        }
      });
      return response;
    },
  },
}
</script>
