import Queries from "queries/index.js"
import getIdFromSlug from "api/getIdFromSlug.js";

const getId = getIdFromSlug(Queries.Office.Single.IdFromSlug, {name: 'offices'});

export default {
  beforeRouteEnter: getId,

  beforeRouteUpdate: getId,
}
