const AUTH_TOKEN = 'auth-token';

import store from 'store/index.js'

export default (to, from, next) => {
  // If any matched route public requires signin (not public), check if signed in, redirect if not
  if (to.matched.some((record) => !record.meta.allowPublic) && !localStorage.getItem(AUTH_TOKEN)) {
    store.dispatch('flash/info', 'auth.authGuard');
    next({
      name: 'signin',
      query: {
        redirect: to.fullPath
      }
    });
  } else if (to.matched.some((record) => record.meta.disallowAuthorized) && localStorage.getItem(AUTH_TOKEN)) {
    store.dispatch('flash/info', 'auth.permissionGuard');
    next({name: 'top',});
  } else {
    next();
  }
}
