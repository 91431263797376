<template>
  <ListBrowserFilterWrapper
    :form.sync="form"
    :form-default="parsedDefaults"
    :order-options="parsedOrderOptions"
    :show-filter="showFilter"
    :form-function="$options.formFunction"
    @input="onInput"
    @filtered="onFiltered"
  >
    <VRow>
      <VCol class="py-0" cols="12" md="3">
        <KDatePicker
          clearable
          label="models.invoice.transactionDate"
          range
          v-model="form.transactionDate"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="3">
        <KDatePicker
          clearable
          label="models.invoice.dueDate"
          range
          v-model="form.dueDate"
        />
      </VCol>
      <VCol class="py-0" cols="4" md="2">
        <VCheckbox
          :label="$t('models.invoice.isPaid')"
          v-model="isPaid"
          :disabled="disables.isPaid"
        />
      </VCol>
      <VCol class="py-0" cols="4" md="2">
        <VCheckbox
          :label="$t('models.invoice.notPaid')"
          v-model="isNotPaid"
          :disabled="disables.isPaid"
        />
      </VCol>
      <VCol class="py-0" cols="4" md="2">
        <VCheckbox
          :label="$t('models.invoice.isOverdue')"
          v-model="form.isOverdue"
          :disabled="disables.isOverdue"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="4">
        <KApiSelect
          :disabled="disables.userOffice"
          label="views.invoicing.filter.userOffice"
          clearable
          model="office"
          v-model="form.userOffice"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="8">
        <KSuggest
          :disabled="disables.user"
          :label="{path: 'models.user.self', choice: 1}"
          clearable
          model="user"
          v-model="form.user"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="4">
        <KApiSelect
          :disabled="disables.projectOffice"
          label="views.invoicing.filter.projectOffice"
          clearable
          model="office"
          v-model="form.projectOffice"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="4">
        <KSuggest
          :disabled="disables.client"
          :label="{path: 'models.company.client', choice: 1}"
          clearable
          model="company"
          :variables="{companyType: '3'}"
          v-model="form.client"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="8">
        <ProjectPicker
          clearable
          :disabled="disables.project"
          v-model="form.project"
        />
      </VCol>
    </VRow>
  </ListBrowserFilterWrapper>
</template>

<script>
  import ListBrowserFilterWrapper from "elements/list/ListBrowserFilterWrapper.vue";
  import {KApiSelect, KDatePicker, KSuggest, ProjectPicker} from "forms/elements/index.js";

  const formFunction = ({project, client, projectOffice, user, userOffice, transactionDate, dueDate, isPaid, isOverdue}) => ({
    ...project && {project},
    ...client && {client},
    ...projectOffice && {projectOffice},
    ...user && {user},
    ...userOffice && {userOffice},
    ...transactionDate && transactionDate.length === 2 && {transactionDate: {from: transactionDate[0], to: transactionDate[1]}},
    ...dueDate && dueDate.length === 2 && {dueDate: {from: dueDate[0], to: dueDate[1]}},
    ...isPaid !== undefined && {isPaid},
    ...isOverdue && {isOverdue},
  });

  const sortOptions = [
    {
      text: 'models.invoice.transactionDate',
      value: 'TRANSACTION_DATE',
    },
    {
      text: 'models.invoice.dueDate',
      value: 'DUE_DATE',
    },
    {
      text: 'models.project.id',
      value: 'PROJECT_ID',
    },
    {
      text: 'models.invoice.isPaid',
      value: 'IS_PAID',
    },
    {
      text: 'models.general.createdAt',
      value: 'CREATED_AT',
      secondary: true,
    },
    {
      text: 'models.general.updatedAt',
      value: 'UPDATED_AT',
    },
  ];

  const defaults = {
    transactionDate: undefined,
    dueDate: undefined,
    client: undefined,
    project: undefined,
    projectOffice: undefined,
    userOffice: undefined,
    user: undefined,
    isPaid: undefined,
    isOverdue: false,
  };

  export default {
    formFunction,

    components: {
      ListBrowserFilterWrapper,
      KApiSelect,
      KDatePicker,
      KSuggest,
      ProjectPicker,
    },

    data() {
      return {
        form: Object.assign({}, defaults, this.defaults),
      };
    },

    props: {
      showFilter: Boolean,

      defaults: {
        type: Object,
        default: () => ({}),
      },

      disables: {
        type: Object,
        default: () => ({}),
      },

      sortDefault: {
        type: String,
        default: 'TRANSACTION_DATE',
      },

      orderDescDefault: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      parsedDefaults() {
        const payload = {};

        for (const key in defaults) {
          payload[key] = this.defaults.hasOwnProperty(key) ? this.defaults[key] : defaults[key];
        }

        return payload;
      },

      parsedOrderOptions() {
        const {sortDefault, orderDescDefault} = this;
        const payload = [];

        sortOptions.forEach((item) => {
          payload.push(item.value === sortDefault
            ? Object.assign({}, item, {default: true, defaultDesc: orderDescDefault})
            : item
          );
        });

        return payload;
      },

      isPaid: {
        get() {
          return this.form.isPaid === true || this.form.isPaid === undefined;
        },
        set(v) {
          this.form.isPaid = v ? undefined : false;
        },
      },

      isNotPaid: {
        get() {
          return this.form.isPaid === false || this.form.isPaid === undefined;
        },
        set(v) {
          this.form.isPaid = v ? undefined : true;
        },
      },
    },

    watch: {
      'form.isOverdue'(v) {
        if (v) {
          this.isPaid = false;
        }
      },

      isPaid(v) {
        if (v === true || v === undefined) {
          this.form.isOverdue = false;
        }
      },
    },

    methods: {
      onInput(v) {
        this.$emit('input', v);
      },

      onFiltered(v) {
        this.$emit('filtered', v);
      }
    },
  }
</script>
