<template>
  <VDialog v-model="show" max-width="300" :disabled="disabled">
    <template v-slot:activator="{on}">
      <VBtn color="grey lighten-3" :small="!showXSmall" :x-small="showXSmall" v-on="on" :disabled="disabled">
        {{displayValue}}
      </VBtn>
    </template>
    <VCard color="white">
      <VCardTitle v-t="'forms.timeZone.title'"/>
      <VCardText>
        <VRadioGroup v-model="localValue" :disabled="disabled" @mouseup="show = false">
          <VRadio v-for="{label, value} in items" :key="value" :label="label" :value="value"/>
        </VRadioGroup>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
  const timeZones = [
    'America/Vancouver',
    'America/Edmonton',
    'America/Regina',
    'America/Winnipeg',
    'America/Toronto',
    'America/Halifax',
    'America/St_Johns',
  ];

  import {format} from "top/date-fns-format.js";

  export default {
    data: () => ({
      show: false,
    }),

    props: {
      disabled: Boolean,

      reference: {
        type: Object,
        default: () => new Date(),
        validator: (v) => v instanceof Date,
      },

      value: {
        type: String,
        required: true,
        validator: (v) => timeZones.includes(v),
      },
    },

    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },

      items() {
        const {reference} = this;
        return timeZones.map((value) => {
          const zone = format(reference, 'z', {timeZone: value});
          const city = value.slice(value.lastIndexOf('/') + 1).replace('_', ' ');
          return {
            value,
            label: `${zone} (${city})`,
          };
        });
      },

      displayValue() {
        const {reference, localValue: timeZone} = this;
        return format(reference, 'z', {timeZone});
      },

      showXSmall() {
        return this.$vuetify.breakpoint.xsOnly;
      },
    },

    watch: {
      disabled(v) {
        if (v) {
          this.show = false;
        }
      },
    },
  }
</script>
