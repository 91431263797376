<template>
  <VDialog :value="value" @input="onInput" scrollable :max-width="maxWidth">
    <VCard>
      <VCardTitle>
        <span v-if="base" class="heading">
          {{base.name}}
        </span>
        <span v-else class="heading grey--text">
          <span v-t="'ui.loading'"/>...
        </span>
      </VCardTitle>
      <VCardText class="px-0">
        <VImg v-if="showThumbnail" :src="largeUrl" :lazy-src="thumbnailUrl" :aspect-ratio="aspectRatio" :max-height="maxHeight" contain/>
        <VRow
          v-else
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <FaI :icon="icon" color="grey" size="6x"/>
        </VRow>
      </VCardText>
      <VCardActions>
        <VBtn text @click="onCloseDialog">
          <span v-t="'actions.close'"/>
        </VBtn>
        <VSpacer/>
        <VProgressCircular v-if="$apollo.queries.download.loading" size="20" width="3" color="blue lighten-1" indeterminate class="mr-10"/>
        <VBtn v-else-if="!noDownload && download" text :href="downloadUrl" class="blue--text" download>
          <VCol cols="auto" v-t="'actions.download'"/> ({{download.sizeForHumans}})
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
  import Queries from 'queries/index.js'
  import {all as mimeTypes} from "elements/media/mimeTypes.js";

  export default {
    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      value: {
        type: Boolean,
        required: true,
      },

      noDownload: Boolean,
    },

    apollo: {
      base: {
        query: Queries.Media.Single.MediaListBase,
        variables() {
          return {
            id: this.id,
          };
        },
        update: ({media}) => media,
      },

      large: {
        query: Queries.Media.Single.MediaListDialog,
        variables() {
          return {
            id: this.id,
          };
        },
        update: ({media}) => media?.conversions?.[0],
        skip() {
          const {base, value} = this;
          return !value || !base?.allowsConversion;
        },
      },

      download: {
        query: Queries.Media.Single.DownloadUrl,
        variables() {
          return {
            id: this.id,
          };
        },
        update: ({media}) => media,
        skip() {
          const {noDownload, value} = this;
          return !value && !noDownload;
        },
      },
    },

    computed: {
      showThumbnail() {
        return Boolean(this.base?.allowsConversion);
      },

      thumbnail() {
        return this.base?.conversions?.[0];
      },

      aspectRatio() {
        return this.base?.aspectRatio;
      },

      maxHeight() {
        const {base} = this;
        return base?.height ? Math.min(base.height, 1000) : 1000;
      },

      maxWidth() {
        const {base} = this;
        return base?.height ? Math.max(Math.min(base.width, 1000), 500) : base?.allowsConversion ? 1000 : 500;
      },

      thumbnailUrl() {
        return this.thumbnail?.url;
      },

      largeUrl() {
        return this.large?.url;
      },

      downloadUrl() {
        return this.download?.url;
      },

      icon() {
        const {base} = this;
        return base ? Object.values(mimeTypes).find((e) => e.type === base.mimeType)?.icon || 'file' : 'file';
      },
    },

    methods: {
      onInput(v) {
        this.$emit('input', v);
      },

      onCloseDialog() {
        this.onInput(false);
      }
    },
  }
</script>
