<template>
  <KSuggest
    label="views.partitionsSets.template"
    model="partitionsSet"
    fetch-policy="cache-and-network"
    refs="search"
    :variables="localVariables"
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    @input="onInput"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData"/>
    </template>
    <template #item="{item}">
      <PartitionsSetPickerItem :id="item.id" :context="context" :key="item.id"/>
    </template>
    <template #selection="{item}">
      <PartitionsSetPickerSelection :id="item.id" :context="context" :key="item.id"/>
    </template>
    <template #append-outer>
      <TemplatesDialog/>
    </template>
  </KSuggest>
</template>

<script>
import {KSuggest} from "top/forms/elements/index.js";
import PartitionsSetPickerDialog from './PartitionsSetPickerDialog.vue';
import PartitionsSetPickerItem from './PartitionsSetPickerItem.vue';
import PartitionsSetPickerSelection from './PartitionsSetPickerSelection.vue';
import TemplatesDialog from '../browse/TemplatesDialog.vue';
import {mapGetters} from 'vuex';

export default {
  components: {
    KSuggest,
    PartitionsSetPickerDialog,
    PartitionsSetPickerSelection,
    PartitionsSetPickerItem,
    TemplatesDialog,
  },

  props: {
    context: {
      type: String,
      required: true,
      validator: (v) => ['timeslip', 'expense'].includes(v),
    },

    value: {
      type: [Number, String],
      required: true,
    },

    variables: Object,
  },

  computed: {
    ...mapGetters('session', ['meId']),

    localVariables() {
      return {
        user: this.meId,
        ...this.variables && {...this.variables},
      };
    },
  },

  methods: {
    getPartitionWeight(item) {
      const totalWeight = item.partitions.reduce((accum, current) => accum + current.weight, 0);
      const target = item.partitions[0].weight;
      return Math.round(target/totalWeight*100);
    },

    getOthersCount(item) {
      return item.partitions.length - 1;
    },

    onInput(v) {
      return this.$emit('input', v);
    },
  },
}
</script>
