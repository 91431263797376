<template>
    <VDataTable
      :headers="headers"
      :items="billingRates"
      item-key="id"
      hide-default-footer
      class="elevation-1"
      disable-sort
      :loading="$apollo.loading"
      :no-data-text="noDataText"
    >
      <template v-slot:top>
        <VToolbar
          dense
          flat
          color="grey lighten-3"
        >
          <VToolbarTitle>
            <span v-t="{path: 'models.billingRate.self', choice: 2}"/>
          </VToolbarTitle>
          <VSpacer/>
          <VDialog
            v-if="canBillingRateCreate"
            max-width="600px"
          >
            <template #activator="{ on, attrs }">
              <VBtn text v-bind="attrs" v-on="on">
                <FaI size="2x" icon="circle-plus"/>&nbsp;
                <span v-t="'actions.new'"/>
              </VBtn>
            </template>
            <template #default="dialog">
              <VCard>
                <VCardTitle>
                  <span v-t="'actions.new'"/>&nbsp;<span v-t="{path: 'models.billingRate.self', choice: 1}"/>
                </VCardTitle>

                <VCardText>
                  <FormBillingRate :projects="context === 'PROJECT' ? [id] : undefined" :users="context === 'USER' ? [id] : undefined" @success="dialog.value = false; $emit('created', $event)" @cancel="dialog.value = false"/>
                </VCardText>
              </VCard>
            </template>
          </VDialog>
          <VDialog
            :value="editTarget > 0"
            max-width="600px"
            @click:outside="endUpdate"
          >
            <VCard>
              <VCardTitle>
                <span v-t="'actions.edit'"/>&nbsp;
                <span v-t="{path: 'models.billingRate.self', choice: 1}"/>
              </VCardTitle>

              <VCardText>
                <FormBillingRate v-if="editTarget" :target="editTarget" @success="onUpdate" @cancel="endUpdate"/>
              </VCardText>
            </VCard>
          </VDialog>
        </VToolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <BillingRatesListActions :id="item.id" @update="startEdit(item.id)" @delete="doDelete(item.id)"/>
      </template>
      <template v-slot:item.endAt="{ item }">
        <span v-if="item.endAt">{{item.endAt}}</span>
        <em v-else v-t="'ui.none'"/>
      </template>
      <template v-slot:item.rate="{ item }">
        {{ getRateFormatted(item.rate) }}
      </template>
    </VDataTable>
</template>

<script>
import Queries from 'queries/index.js';
import FormBillingRate from './FormBillingRate.vue';
import ClickConfirm from '../../../elements/ClickConfirm.vue';
import BillingRatesListActions from './BillingRatesListActions.vue';
import currencyMixin from "top/mixins/currencyMixin.js";

export default {
  data: () => ({
    editTarget: null,
    headers: [
      { text: 'Start At', value: 'startAt' },
      { text: 'End At', value: 'endAt' },
      { text: 'Rate', value: 'rate' },
      { text: 'Actions', value: 'actions'},
    ],
  }),

  mixins: [
    currencyMixin,
  ],

  components: {
    FormBillingRate,
    BillingRatesListActions,
    ClickConfirm,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },

    context: {
      type: String,
      required: true,
      validator: (v) => ['USER', 'PROJECT'].includes(v),
    }
  },

  apollo: {
    billingRates: {
      query: Queries.BillingRate.Browse.List,
      variables() {
        return {
          context: this.context,
          id: this.id,
        };
      },
    },

    canBillingRateCreate() {
      return this.policyChecker({
        policy: 'create',
        type: 'BillingRate',
        args: {
          ...this.context === 'USER' && {users: {
            connect: [this.id],
          }},
          ...this.context === 'PROJECT' && {projects: {
            connect: [this.id],
          }},
        },
      });
    },
  },

  computed: {
    noDataText() {
      return this.$t('ui.noResults');
    },
  },

  methods: {
    async doDelete(id) {
      this.$emit('deleting', id);

      await this.$apollo.mutate({
        mutation: Queries.BillingRate.Write.Delete,
        variables: {
          id,
        }
      });

      this.$emit('deleted', id);
    },

    startEdit(id) {
      this.editTarget = id;
    },

    endUpdate() {
      this.editTarget = null;
    },

    onUpdate(response) {
      this.endUpdate();
      this.$emit('updated', response);
    },

    refetchList() {
      this.$emit('refetched');
      this.$apollo.queries.billingRates.refetch();
    },

    getRateFormatted(value) {
      return this.getCurrency(value);
    },
  },

  created() {
    this.$on('created', this.refetchList);
    this.$on('updated', this.refetchList);
    this.$on('deleted', this.refetchList);
  }
}
</script>
