<template>
  <VSkeletonLoader v-if="$apollo.queries.project.loading" type="card"/>
  <form v-else @submit.prevent="doSubmit" @keyup.enter="doSubmit" style="width: 100%">
    <VContainer>
      <VCol cols="12" lg="6">
        <KTextField
          v-model="form.name"
          :validator="$v.form.name"
          label="models.project.name"
          hint="forms.general.required"
        />
      </VCol>

      <VCol cols="12" lg="6">
        <KApiSelect
          v-model="form.officeId"
          :validator="$v.form.officeId"
          model="office"
          hint="forms.general.required"
        />
      </VCol>

      <VCol cols="12" lg="6">
        <KApiSelect
          v-model="form.projectTypeId"
          :validator="$v.form.projectTypeId"
          model="projectType"
          hint="forms.general.required"
        />
      </VCol>

      <VCol cols="12" lg="6">
        <KApiSelect
          :value="projectStatusId"
          model="projectStatus"
          disabled
          persistent-hint
          dense
        />
        <small class="grey--text">
          <span v-t="'forms.project.projectStatus_hint'"/>
          <RouterLink :to="{name: 'project.projectRecords', params: {id: target}}">
            <span v-t="'forms.project.projectStatus_hint_link'"/>
          </RouterLink>
        </small>
      </VCol>

      <VCol cols="12" lg="6">
        <KApiSelect
          v-model="form.workRegion"
          :validator="$v.form.workRegion"
          model="countryDivision"
          :variables="{country: '1'}"
          label="models.project.settings.workRegion"
          hint="forms.project.workRegion_hint"
          persistent-hint
        />
      </VCol>

      <VCol cols="12" lg="6">
        <KSelect
          v-model="form.isTimeslipBillable"
          :items="billableTypes"
          label="models.project.settings.isTimeslipBillable"
          hint="forms.project.isTimeslipBillable_hint"
          persistent-hint
        >
          <template #item="{item, on, attrs}">
            <VListItem two-line v-on="on" v-bind="attrs">
              <VListItemContent>
                <VListItemTitle>
                  {{item.name}}
                </VListItemTitle>
                <VListItemSubtitle class="text--primary">
                  {{item.help}}
                </VListItemSubtitle>
              </VListItemContent>
            </VListItem>
          </template>
        </KSelect>
      </VCol>

      <VCol cols="12" lg="6">
        <KSelect
          v-model="form.isExpenseBillable"
          :items="billableTypes"
          label="models.project.settings.isExpenseBillable"
          hint="forms.project.isExpenseBillable_hint"
          persistent-hint
        >
          <template #item="{item, on, attrs}">
            <VListItem two-line v-on="on" v-bind="attrs">
              <VListItemContent>
                <VListItemTitle>
                  {{item.name}}
                </VListItemTitle>
                <VListItemSubtitle class="text--primary">
                  {{item.help}}
                </VListItemSubtitle>
              </VListItemContent>
            </VListItem>
          </template>
        </KSelect>
      </VCol>

      <VCol cols="12">
        <VRadioGroup :label="localeLabel" v-model="form.locale" row>
          <VRadio v-for="{label, value} in localeItems" :key="value" :label="label" :value="value"/>
        </VRadioGroup>
      </VCol>

      <KBtnSubmit :mode="mode" class="mt-3"/>
    </VContainer>
  </form>
</template>

<script>
import FormMixin from 'forms/elements/FormMixin.js'
import Queries from 'queries/index.js';
import {KApiSelect, KBtnSubmit, KCheckbox, KCurrencyField, KSelect, KSuggest, KTextField} from "forms/elements/index.js"
import {betweenLength, primaryKey, required} from 'validators/index.js'

const locales = [
  'en-CA',
  'fr-CA',
];

const billableTypes = [
  'ALWAYS',
  'USUALLY',
  'NEUTRAL',
  'SOMETIMES',
  'NEVER',
];

export default {
  billableTypes,

  mixins: [
    FormMixin,
  ],

  components: {
    KBtnSubmit,
    KCheckbox,
    KCurrencyField,
    KApiSelect,
    KSelect,
    KSuggest,
    KTextField,
  },

  data() {
    return {
      form: {
        name: '',
        locale: this.$store.getters['session/locale'],
        workRegion: null,
        isTaxExempt: false,
        officeId: '',
        projectTypeId: '',
        isTimeslipBillable: 'SOMETIMES',
        isExpenseBillable: 'SOMETIMES',
      },
    };
  },

  apollo: {
    project: {
      query: Queries.Project.Write.FormFill,
      variables() {
        return {
          id: this.target,
        };
      },
      skip() {
        return !this.isUpdate;
      },
    },
  },

  validations: {
    form: {
      name: {
        required,
        betweenLength: betweenLength(3, 250),
      },
      officeId: {
        required,
        primaryKey,
      },
      projectTypeId: {
        required,
        primaryKey,
      },
      workRegion: {
        required,
        primaryKey,
      },
    },
  },

  computed: {
    localeLabel() {
      return this.$t('models.project.settings.locale');
    },

    localeItems() {
      return locales.map((value) => ({
        label: this.$t(`locales.${value}`),
        value,
      }));
    },

    projectStatusId() {
      return this.project?.projectStatus?.id;
    },

    billableTypes() {
      return billableTypes.map((id) => ({
        id,
        name: this.$t(`forms.activityPartition.billableType.${id.toLowerCase()}.name`),
        help: this.$t(`forms.activityPartition.billableType.${id.toLowerCase()}.description`),
      }));
    },
  },

  watch: {
    project(v) {
      if (!v) {
        return;
      }

      const form = this.form;

      form.name = v.name;
      form.locale = v?.settings?.locale || 'en-CA';
      form.officeId = v.office.id;
      form.projectTypeId = v.projectType.id;
      form.workRegion = v.settings?.workRegion;
      form.isTaxExempt = Boolean(v.settings?.isTaxExempt);
      form.isTimeslipBillable = v.settings?.isTimeslipBillable ?? 'SOMETIMES';
      form.isExpenseBillable = v.settings?.isExpenseBillable ?? 'SOMETIMES';
    },
  },

  methods: {
    async updateAction() {
      const {project: {id}, form: {name, locale, officeId, projectTypeId, workRegion, isTaxExempt, isTimeslipBillable, isExpenseBillable}} = this;
      const {data: {projectUpdate: response}} = await this.$apollo.mutate({
        mutation: Queries.Project.Write.Update,
        variables: {
          id,
          name,
          locale,
          officeId,
          projectTypeId,
          workRegion,
          isTaxExempt,
          isTimeslipBillable,
          isExpenseBillable,
        }
      });
      return response;
    },
  },
}
</script>
