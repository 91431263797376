<template>
  <VAutocomplete
    :autofocus="autofocus"
    :clearable="clearable"
    :counter="counter"
    :disabled="disabled"
    :error-messages="errorMessages"
    :filter="filter"
    :hide-no-data="hideNoData"
    :hint="hintParsed"
    :item-text="itemText"
    :item-value="itemValue"
    :items="items"
    :label="labelParsed"
    :loading="loading"
    :multiple="multiple"
    :no-data-text="noDataTextParsed"
    :no-filter="noFilter"
    :outlined="outlined"
    :persistent-hint="localPersistentHint"
    :placeholder="placeholderParsed"
    :return-object="returnObject"
    :search-input="searchInput"
    :suffix="suffix"
    :value="value"
    @blur="doTouch"
    @input="onInput"
    v-on:update:search-input="onSearchInput"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </VAutocomplete>
</template>

<script>
  import KFormTextElementMixin from './KFormTextElementMixin.js'

  export default {
    mixins: [
      KFormTextElementMixin,
    ],

    props: {
      value: {
        required: true,
      },

      items: {
        type: Array,
        default: [],
      },

      itemText: {
        type: [String, Array, Function],
        default: 'name',
      },

      itemValue: {
        type: [String, Array, Function],
        default: 'id',
      },

      multiple: {
        type: Boolean,
        default: false,
      },

      returnObject: {
        type: Boolean,
        default: false,
      },

      // For autocomplete

      filter: {
        type: Function,
        default: (item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1,
      },

      placeholder: {
        type: String,
        default: 'forms.general.searchPlaceholder'
      },

      noDataText: {
        type: String,
        default: 'ui.noMatches'
      },

      searchInput: String,

      noFilter: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      noDataTextParsed() {
        return this.parseDisplayMessage(this.noDataText);
      },

      hideNoData() {
        const {searchInput, loading} = this;
        return loading || !searchInput || searchInput.length <= 1;
      },
    },

    methods: {
      onSearchInput(v) {
        this.$emit('update:searchInput', v);
      },
    },
  }
</script>
