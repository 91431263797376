<template>
  <NowLoading v-if="$apollo.loading"/>
  <VList v-else>
    <template v-for="(item, index) in overviewItems">
      <VDivider v-if="index !== 0" :key="`div-${item.name}`"/>
      <VListItem :key="item.name">
        <VListItemContent>
          <VListItemTitle>
            <span v-t="{path: item.name, choice: 1}"/>:
            <strong>
              {{item.value}}
            </strong>
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
    </template>
  </VList>
</template>

<script>
  import Queries from 'queries/index.js'
  import currencyMixin from "../../../mixins/currencyMixin.js";

  export default {
    mixins: [
      currencyMixin
    ],

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    apollo: {
      countryDivision: {
        query: Queries.CountryDivision.Single.Name,
        variables() {
          return {
            id: this.project.settings.workRegion,
          }
        },
        skip() {
          return !this.project?.settings?.workRegion;
        },
      },

      project: {
        query: Queries.Project.Single.Overview,
        variables() {
          return {
            id: this.id,
          };
        },
      },
    },

    computed: {
      overviewItems() {
        const {project, countryDivision} = this;

        if (!project) {
          return [];
        }

        const payload = [
          {
            name: 'models.general.id',
            value: project.id,
          },
          {
            name: 'models.projectType.shorthand',
            value: project.projectType.name,
          },
          {
            name: 'models.projectStatus.shorthand',
            value: project.projectStatus.name,
          },
          {
            name: 'models.office.self',
            value: project.office.name,
          },
          {
            name: 'models.projectStatus.shorthand',
            value: project.projectStatus.name,
          },
          {
            name: 'models.project.recordDueAt',
            value: project.recordDueAt,
          },
          {
            name: 'models.project.settings.workRegion',
            value: countryDivision?.name ?? this.$t('ui.notSet'),
          },
          {
            name: 'models.project.settings.isTaxExempt',
            value: this.$t(`ui.${project.settings.isTaxExempt ? 'yes' : 'no'}`),
          },
          {
            name: 'models.project.settings.locale',
            value: this.$t(`locales.${project.settings.locale}`),
          },
        ];

        if (project?.meta?.balance !== undefined) {
          payload.push({
            name: 'models.project.meta.balance',
            value: this.getCurrency(project?.meta?.balance),
          });
        }

        return payload;
      },
    },
  }
</script>
