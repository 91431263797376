import {
  FontAwesomeIcon as FaI,
  FontAwesomeLayers as FaLayers,
  FontAwesomeLayersText as FaLayersText
} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';

import {
  faBan as farBan,
  faCalendarDay as farCalendarDay,
  faCircle as farCircle,
  faCircleCaretUp as farCircleCaretUp,
  faCircleCaretDown as farCircleCaretDown,
  faCircleCheck as farCircleCheck,
  faCircleInfo as farCircleInfo,
  faCircleStop as farCircleStop,
  faCirclePlay as farCirclePlay,
  faCircleQuestion as farCircleQuestion,
  faClock as farClock,
  faCircleDot as farCircleDot,
  faFilter as farFilter,
  faInboxIn as farInboxIn,
  faMoneyCheckAlt as farMoneyCheckAlt,
  faReflectHorizontal as farReflectHorizontal,
  faShareNodes as farShareNodes,
  faSquare as farSquare,
  faStar as farStar,
  faTimer as farTimer,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faAngleLeft,
  faArrowCircleLeft,
  faArrowDownBigSmall,
  faArrowLeft,
  faArrowToTop,
  faArrowUpSmallBig,
  faBan,
  faBars,
  faBriefcase,
  faBuilding,
  faBuildingColumns,
  faCaretDown,
  faChairOffice,
  faChartColumn,
  faChartMixed,
  faChartPie,
  faCheck,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faCircleXmark,
  faClipboard,
  faClock,
  faClockRotateLeft,
  faComment,
  faDollarSign,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEmptySet,
  faEnvelopeOpenDollar,
  faExclamation,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileCsv,
  faFileImage,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePowerpoint,
  faFileSpreadsheet,
  faFileWord,
  faFilter,
  faFlag,
  faFlagCheckered,
  faGear,
  faGlobe,
  faHandshakeAngle,
  faHouse,
  faInfo,
  faKeyboard,
  faLayerGroup,
  faLayerMinus,
  faLayerPlus,
  faLink,
  faList,
  faListCheck,
  faLocation,
  faMagnifyingGlass,
  faMapLocation,
  faMinus,
  faMoneyBillWave,
  faMoneyCheckDollar,
  faPaperclip,
  faPen,
  faPenToSquare,
  faPlay,
  faPlus,
  faQuestion,
  faReceipt,
  faRightFromBracket,
  faRightToBracket,
  faRotate,
  faRotateLeft,
  faScrewdriverWrench,
  faSignsPost,
  faSlash,
  faSlidersUp,
  faSortUp,
  faSquareCheck,
  faSquareMinus,
  faStar,
  faStarHalf,
  faStepForward,
  faStop,
  faStopwatch,
  faTable,
  faTimer,
  faThumbsUp,
  faTrash,
  faTriangleExclamation,
  faUnlink,
  faUpRightFromSquare,
  faUser,
  faUserClock,
  faUserMinus,
  faUserPlus,
  faUsers,
  faUsersGear,
  faUserSlash,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  // For Vuetify - comments are Vuetify equivalent from Material framework
  // regular
  farSquare, // checkboxOff
  farCircleDot, // radioOn
  farCircle, // radioOff
  farStar, // ratingEmpty

  // solid
  faBriefcase,
  faCheck, // complete
  faCircleXmark, // cancel, delete, clear
  faXmark, // close
  faCircleCheck, // success
  faInfo, // info
  faExclamation, // warning
  faTriangleExclamation, // error
  faChevronLeft, // prev
  faChevronRight, // next
  faSquareCheck, // checkboxOn
  faSquareMinus, // checkboxIndeterminate
  faCircle, // delimeter
  faSortUp, // sort
  faChevronDown, // expand
  faBars, // menu
  faCaretDown, // subgroup, dropdown
  faPen, // edit
  faStar, // ratingFull
  faStarHalf, // ratingHalf

  faPaperclip,

  // Others not for Vuetify
  faAngleLeft,
  faArrowCircleLeft,
  faArrowDownBigSmall,
  faArrowLeft,
  faArrowToTop,
  faArrowUpSmallBig,
  faBan,
  faBuilding,
  faBuildingColumns,
  faChairOffice,
  faChartColumn,
  faChartMixed,
  faChartPie,
  faChevronDoubleRight,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faClipboard,
  faClock,
  faClockRotateLeft,
  faComment,
  faDollarSign,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEmptySet,
  faEnvelopeOpenDollar,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileCsv,
  faFileImage,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePowerpoint,
  faFileSpreadsheet,
  faFileWord,
  faFilter,
  faFlag,
  faFlagCheckered,
  faGear,
  faGlobe,
  faHandshakeAngle,
  faHouse,
  faKeyboard,
  faLayerGroup,
  faLayerMinus,
  faLayerPlus,
  faLink,
  faList,
  faListCheck,
  faLocation,
  faMagnifyingGlass,
  faMapLocation,
  faMinus,
  faMoneyBillWave,
  faMoneyCheckDollar,
  faPenToSquare,
  faPlay,
  faPlus,
  faQuestion,
  faReceipt,
  faRightFromBracket,
  faRightToBracket,
  faRotate,
  faRotateLeft,
  faSlash,
  faStepForward,
  faScrewdriverWrench,
  faSignsPost,
  faSlidersUp,
  faStop,
  faStopwatch,
  faTable,
  faTimer,
  faThumbsUp,
  faTrash,
  faUnlink,
  faUpRightFromSquare,
  faUser,
  faUserClock,
  faUsersGear,
  faUserSlash,
  faUserPlus,
  faUserMinus,
  faUsers,

  farBan,
  farCalendarDay,
  farCircleCaretUp,
  farCircleCaretDown,
  farCircleCheck,
  farCircleInfo,
  farCircleStop,
  farCirclePlay,
  farCircleQuestion,
  farClock,
  farFilter,
  farInboxIn,
  farMoneyCheckAlt,
  farReflectHorizontal,
  farShareNodes,
  farTimer,
);

export default {
  FaI,
  FaLayers,
  FaLayersText,
  FontAwesomeIcon: FaI,
};
