import gql from 'graphql-tag';

const MyInspectionLocation = gql`
  query{
    myInspectionLocation {
      id
      createdAt
      updatedAt
      closedAt
      inspection {
        id
      }
      building {
        id
      }
      inspectionArea {
        id
      }
      devices {
        id
      }
    }
  }
`;

// Write

const Create = gql`
  mutation(
    $inspectionId: ID!
    $buildingId: ID
    $inspectionAreaId: ID
    $deviceIds: [ID!]
  ) {
    inspectionActivityCreate(
      input: {
        inspection: {
          connect: $inspectionId
        },
        building: {
          connect: $buildingId
        },
        inspectionArea: {
          connect: $inspectionAreaId
        },
        devices: {
          connect: $deviceIds
        }
      }
    ) {
      id
      createdAt
      updatedAt
      closedAt
      inspection {
        id
      }
      building {
        id
      }
      inspectionArea {
        id
      }
      devices {
        id
      }
    }
  }
`;

const Ping = gql`
  mutation {
    inspectionActivityPing {
      id
      createdAt
      updatedAt
      closedAt
    }
  }
`;


// Export

const Single = {
  MyInspectionLocation,
};

const Write = {
  Create,
  Ping,
};

export default {
  Single,
  Write,
};
