<template>
  <NowLoading v-if="$apollo.loading"/>
  <VContainer v-else>
    <VToolbar dense>
      <VAppBarNavIcon v-if="isMobile" @click="drawerActivated = !drawerActivated"/>
      <VToolbarTitle>
        <small>
          <span v-t="{path: 'models.office.self', choice: 1}"/>:
        </small>
        <span class="text-truncate">
          {{office.name}}
        </span>
      </VToolbarTitle>
    </VToolbar>
    <VNavigationDrawer clipped v-model="showDrawer" :permanent="!isMobile" :absolute="isMobile" :app="!isMobile" :clipped="!isMobile" color="grey lighten-3">
      <VList nav>
        <VListItem v-for="{name, title, icon} in navItems" :key="name" :to="{name, params: {slug, id}}" exact>
          <VListItemIcon>
            <FaI :icon="icon"/>
          </VListItemIcon>
          <VListItemContent v-t="title"/>
        </VListItem>
      </VList>
    </VNavigationDrawer>
    <KeepAlive>
      <RouterView/>
    </KeepAlive>
  </VContainer>
</template>

<script>
  import Queries from 'queries/index.js';
  import officeGetSlugMixin from "./officeGetSlugMixin.js";

  export default {
    mixins: [
      officeGetSlugMixin,
    ],

    data: () => ({
      drawerActivated: false,
    }),

    props: {
      id: {
        type: [String, Number],
        required: true,
      },

      slug: {
        type: String,
        required: true,
      },
    },

    apollo: {
      office: {
        query: Queries.Office.Single.Main,
        variables() {
          return {
            id: this.id,
          };
        },
      },

      canUpdate() {
        return this.policyChecker({
          policy: 'update',
          type: 'Office',
          id: this.id,
        });
      },

      canProjectRecordViewAny() {
        return this.policyChecker({
          policy: 'projectRecordViewAny',
          type: 'Office',
          id: this.id,
        });
      },

      canProjectViewAny() {
        return this.policyChecker({
          policy: 'projectViewAny',
          type: 'Office',
          id: this.id,
        });
      },

      canTimeslipViewAny() {
        return this.policyChecker({
          policy: 'timeslipViewAny',
          type: 'Office',
          id: this.id,
        });
      },

      canExpenseViewAny() {
        return this.policyChecker({
          policy: 'expenseViewAny',
          type: 'Office',
          id: this.id,
        });
      },

      canTeamViewAny() {
        return this.policyChecker({
          policy: 'viewOnContext',
          type: 'Team',
          args: {
            contextType: 'offices',
            contextId: this.id,
          },
        });
      },

      canInvoiceViewAny() {
        return this.policyChecker({
          policy: 'invoiceViewAny',
          type: 'Office',
          id: this.id,
        });
      },
    },

    computed: {
      navItems() {
        const {canProjectViewAny, canProjectRecordViewAny, canTimeslipViewAny, canExpenseViewAny, canTeamViewAny, canInvoiceViewAny} = this;
        const payload = [
          {
            name: 'office',
            title: 'ui.overview',
            icon: 'chair-office',
          },
        ];

        if (canTeamViewAny) {
          payload.push({
            name: 'office.team',
            title: {path: 'models.team.self', choice: 1},
            icon: 'users',
          });
        }

        if (canProjectViewAny) {
          payload.push({
            name: 'office.projects',
            title: {path: 'models.project.self', choice: 2},
            icon: 'briefcase',
          });
        }

        if (canProjectRecordViewAny) {
          payload.push({
            name: 'office.projectRecords',
            title: {path: 'models.projectRecord.self', choice: 2},
            icon: 'chart-mixed',
          });
        }

        if (canTimeslipViewAny) {
          payload.push({
            name: 'office.timeslips',
            title: {path: 'models.timeslip.self', choice: 2},
            icon: 'clock',
          });
        }

        if (canExpenseViewAny) {
          payload.push({
            name: 'office.expenses',
            title: {path: 'models.expense.self', choice: 2},
            icon: 'receipt',
          });
        }

        if (canInvoiceViewAny) {
          payload.push({
            name: 'office.invoicing',
            title: 'views.invoicing.title',
            icon: 'file-invoice-dollar',
          });
        }

        // if (canUpdate) {
        //   payload.push({
        //     name: 'office.edit',
        //     title: 'actions.edit',
        //     icon: 'edit',
        //   });
        // }
        return payload;
      },

      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      },

      showDrawer: {
        set(v) {
          this.drawerActivated = v;
        },
        get() {
          return !this.isMobile || this.drawerActivated;
        }
      },
    },

    watch: {
      isMobile(v) {
        this.drawerActivated = false;
      },
    },
  }
</script>
