<template>
  <span :class="shadowClass">{{ howLong }}</span>
</template>

<script>
  import Queries from 'queries/index.js';
  import tickerMixin from "top/mixins/tickerMixin.js";
  import {mapGetters} from 'vuex'

  export default {
    mixins: [
      tickerMixin,
    ],

    props: {
      shadow: {
        type: String,
        validator: (v) => ['black', 'white'].includes(v),
      },

      base: {
        type: Number,
        default: 0,
      },
    },

    apollo: {
      activeTimeslip: {
        query: Queries.Timeslip.Single.ActiveTimeslip,
        variables() {
          return {userId: this.meId};
        },
        skip() {
          return !this.isSignedIn;
        },
      },
    },

    computed: {
      ...mapGetters('session', ['isSignedIn', 'meId']),

      howLong() {
        const {getTimeDuration, activeTimeslip, base} = this;
        return activeTimeslip ? getTimeDuration(activeTimeslip.startAt, base) : undefined;
      },

      shadowClass() {
        const {shadow} = this;
        return shadow ? `timeslip-duration-shadow-${shadow}` : null;
      }
    },
  }
</script>
