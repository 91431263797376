import gql from 'graphql-tag';

const Populate = gql`
  query(
    $id: ID!
  ) {
    companyType(
      id: $id
    ) {
      id
      name
    }
  }
`;

const Select = gql`
  query {
    companyTypes {
      id
      name
    }
  }
`;

const Forms = {
  Populate,
  Select,
};

const Single = {
  Name: Populate,
};

export default {
  Forms,
  Single,
};
