import Main from './Main.vue'
import Overview from './Overview.vue'
import Team from './Team.vue'
import ProjectRecords from './ProjectRecords.vue';
import Projects from './Projects.vue'
import Timeslips from "./Timeslips.vue";
import Expenses from "./Expenses.vue"
import Invoicing from "./Invoicing.vue";

export default {
  Main,
  Overview,
  Team,
  ProjectRecords,
  Projects,
  Timeslips,
  Expenses,
  Invoicing,
}
