<template>
  <NowLoading v-if="$apollo.loading"/>
  <div v-else>
    <VToolbar dense>
      <VAppBarNavIcon v-if="isMobile" @click="drawerActivated = !drawerActivated"/>
      <VToolbarTitle>
        <UserThumbnail :id="user.id" size="xs" disabled no-tooltip/>
        <span class="text-truncate">
          {{user.name}}
        </span>
      </VToolbarTitle>
    </VToolbar>
    <VNavigationDrawer clipped v-model="showDrawer" :permanent="!isMobile" :absolute="isMobile" :app="!isMobile" :clipped="!isMobile" color="grey lighten-3">
      <VList nav>
        <VListItem v-for="{name, title, icon} in navItems" :key="name" :to="{name, params: {slug, id}}" exact>
          <VListItemIcon>
            <FaI :icon="icon"/>
          </VListItemIcon>
          <VListItemContent v-t="title"/>
        </VListItem>
      </VList>
    </VNavigationDrawer>
    <KeepAlive>
      <RouterView/>
    </KeepAlive>
  </div>
</template>

<script>
  import {KActionBar, KStandardHeader, UserThumbnail} from 'elements/index.js'
  import Queries from 'queries/index.js'
  import userGetSlugMixin from "./userGetSlugMixin.js";

  export default {
    mixins: [
      userGetSlugMixin,
    ],

    components: {
      KActionBar,
      KStandardHeader,
      UserThumbnail,
    },

    data: () => ({
      drawerActivated: false,
    }),

    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      slug: {
        type: String,
        required: true,
      },
    },

    apollo: {
      user: {
        query: Queries.User.Single.Main,
        variables() {
          return {
            id: this.id,
          };
        },
      },

      canView() {
        return this.policyChecker({
          policy: 'view',
          type: 'User',
          id: this.id,
        });
      },

      canUpdate() {
        return this.policyChecker({
          policy: 'update',
          type: 'User',
          id: this.id,
        });
      },

      canTeamViewAny() {
        return this.policyChecker({
          policy: 'viewOnContext',
          type: 'Team',
          args: {
            contextType: 'users',
            contextId: this.id,
          },
        });
      },

      canTimeslipViewAny() {
        return this.policyChecker({
          policy: 'timeslipViewAny',
          type: 'User',
          id: this.id,
        });
      },

      canExpenseViewAny() {
        return this.policyChecker({
          policy: 'expenseViewAny',
          type: 'User',
          id: this.id,
        });
      },

      canInvoiceViewAny() {
        return this.policyChecker({
          policy: 'invoiceViewAny',
          type: 'User',
          id: this.id,
        });
      },

      canBillingRateViewAny() {
        return this.policyChecker({
          policy: 'billingRateViewAny',
          type: 'User',
          id: this.id,
        });
      },
    },

    computed: {
      navItems() {
        const {canView, canUpdate, canTimeslipViewAny, canTeamViewAny, canExpenseViewAny, canInvoiceViewAny, canBillingRateViewAny} = this;
        const payload = [];

        if (canView) {
          payload.push({
            name: 'user',
            title: 'ui.overview',
            icon: 'user',
          });
        }

        if (canTeamViewAny) {
          payload.push({
            name: 'user.team',
            title: 'models.user.team',
            icon: 'users',
          });
        }

        if (canTimeslipViewAny) {
          payload.push({
            name: 'user.timeslips',
            title: {path: 'models.timeslip.self', choice: 2},
            icon: 'clock',
          });
        }

        if (canExpenseViewAny) {
          payload.push({
            name: 'user.expenses',
            title: {path: 'models.expense.self', choice: 2},
            icon: 'receipt',
          });
        }

        if (canInvoiceViewAny) {
          payload.push({
            name: 'user.invoicing',
            title: {path: 'models.invoice.self', choice: 2},
            icon: 'file-invoice-dollar',
          });
        }

        if (canBillingRateViewAny) {
          payload.push({
            name: 'user.billingRates',
            title: {path: 'models.billingRate.self', choice: 2},
            icon: 'money-check-dollar',
          });
        }

        if (canUpdate) {
          payload.push({
            name: 'user.edit',
            title: 'actions.edit',
            icon: 'pen-to-square',
          });
        }

        return payload;
      },

      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      },

      showDrawer: {
        set(v) {
          this.drawerActivated = v;
        },
        get() {
          return !this.isMobile || this.drawerActivated;
        }
      },
    },

    watch: {
      isMobile(v) {
        this.drawerActivated = false;
      },
    },
  }
</script>
