<template>
  <VSkeletonLoader v-if="!isReady" type="list-item-two-line"/>
  <VListItem v-else :to="to" two-line v-bind="$attrs">
    <VListItemAvatar rounded size="32">
      <CompanyThumbnail :id="id" disabled no-tooltip size="sm"/>
    </VListItemAvatar>
    <VListItemContent>
      <VListItemTitle>
        <strong>{{company.name}}</strong>
      </VListItemTitle>
      <VListItemSubtitle v-if="locationSubtitle">
        {{ locationSubtitle }}
<!--        <span v-t="'models.general.createdAt'"/> {{createdAt}} - <span v-t="'models.general.updatedAt'"/> {{updatedAt}}-->
      </VListItemSubtitle>
    </VListItemContent>
  </VListItem>
</template>

<script>
  import Queries from 'queries/index.js';
  import {CompanyThumbnail} from "elements/index.js";
  // import {parseISO} from 'date-fns'
  // import {formatISODate} from "top/date-fns-format.js";

  const isReadyQueries = ['company'];

  export default {
    components: {
      CompanyThumbnail,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      dense: Boolean,
    },

    apollo: {
      company: {
        query: Queries.Company.Browse.Row,
        variables() {
          return {
            id: this.id,
          };
        },
      },

      canView() {
        return this.policyChecker({
          policy: 'view',
          type: 'Company',
          id: this.id,
        });
      },
    },

    computed: {
      isReady() {
        return !isReadyQueries.some((query) => this.$apollo.queries[query].loading);
      },

      to() {
        const {canView, company} = this;

        if (!(canView && company)) {
          return undefined;
        }

        const {id, slug} = company;
        return {name: 'client', params: {id, slug}};
      },

      // createdAt() {
      //   return this.company ? formatISODate(parseISO(this.company.createdAt)) : null;
      // },
      //
      // updatedAt() {
      //   return this.company ? formatISODate(parseISO(this.company.updatedAt)) : null;
      // },

      locationSubtitle() {
        const {company} = this;
        const city = company?.contact?.location?.city;

        return city ? `${city.name}, ${city.countryDivision.code}` : null;
      }
    },
  }
</script>
