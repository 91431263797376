<template>
  <VDialog v-model="showConfirm" :disabled="suppress" :width="dialogWidth">
    <template v-slot:activator="{on}">
      <slot name="activator" :on="on">
        <VBtn v-on="on" :class="btnClass" @click.stop.prevent="onClick" v-bind="$attrs">
          <slot>
            <FaI v-if="icon" :icon="icon" class="mr-2"/>
            <span v-t="message"/>
          </slot>
        </VBtn>
      </slot>
    </template>
    <VCard>
      <VCardTitle primary-title class="text-h5">
        <slot name="title">
          <span v-t="'actions.confirm'"/>
        </slot>
      </VCardTitle>
      <VCardText>
        <slot name="prompt">
          <span v-t="'actions.confirmPrompt'"/>
        </slot>
      </VCardText>
      <VDivider/>
      <VCardActions>
        <VSpacer/>
        <VBtn color="primary" text @click="onConfirm">
          <FaI icon="thumbs-up" class="mr-2"/>
          <span v-t="'actions.confirm'"/>
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
  export default {
    inheritAttrs: false,

    data: () => ({
      showConfirm: false,
    }),

    props: {
      message: {
        type: String,
        default: 'actions.submit'
      },

      suppress: Boolean,

      btnClass: String,

      dialogWidth: {
        type: [String, Number],
        default: 200,
      },

      icon: String,
    },

    methods: {
      onClick() {
        if (this.suppress) {
          this.onConfirm();
        }
      },

      onConfirm() {
        this.showConfirm = false;
        this.$emit('confirm');
      }
    }
  }
</script>
