<template>
  <VContainer>
    <ExpandPanelBrowser :title="{path: 'models.partitionsSet.self', choice: 2}" query="PartitionsSet.Browse.List" :bus="bus">
      <template #filter="{setVariables, setIsFiltered, showFilter}">
        <PartitionSetsFilter @input="setVariables" :defaults="filterDefaults" :disables="filterDisables" @filtered="setIsFiltered" :show-filter="showFilter"/>
      </template>
      <template #item="{item, onUpdate, onDelete}">
        <PartitionSetItem :id="item.id" :key="item.id" @update="onUpdate" @delete="bus.emit('refetch');"/>
      </template>
      <template #actions>
        <VDialog>
          <template #activator="{on, attrs}">
            <VBtn text v-on="on" v-bind="attrs" color="black">
              <FaI size="2x" icon="circle-plus"/>&nbsp;
              <span v-t="'actions.new'"/>
            </VBtn>
          </template>
          <template #default="dialog">
            <VCard>
              <VCardTitle>
                <span v-t="'actions.new'"/>&nbsp;
                <span v-t="{path: 'models.partitionsSet.self', choice: 1}"/>
                <VSpacer/>
                <VBtn text @click="dialog.value = false">
                  <FaI icon="times" size="2x"/>
                </VBtn>
              </VCardTitle>
              <VCardText>
                <FormPartitionsSet @success="dialog.value = false; bus.emit('refetch');" @cancel="dialog.value = false"/>
              </VCardText>
            </VCard>
          </template>
        </VDialog>
        <slot name="actions"/>
      </template>
    </ExpandPanelBrowser>
  </VContainer>
</template>

<script>
  import ExpandPanelBrowser from "elements/list/ExpandPanelBrowser.vue";
  import PartitionSetsFilter from "./elements/PartitionSetsFilter.vue";
  import PartitionSetItem from "./elements/PartitionSetItem.vue";
  import FormPartitionsSet from '../elements/FormPartitionsSet.vue';
  import {mapGetters} from 'vuex';
  import mitt from 'mitt';

  export default {
    components: {
      FormPartitionsSet,
      ExpandPanelBrowser,
      PartitionSetsFilter,
      PartitionSetItem,
    },

    data: () => ({
      bus: mitt(),
    }),

    props: {
      user: [Number, String],
    },

    apollo: {
      canCreate() {
        return this.policyChecker({
          policy: 'create',
          type: 'PartitionsSet',
        });
      },
    },

    computed: {
      ...mapGetters('session', ['meId']),

      filterDefaults() {
        return {
          user: this.user || this.meId,
        };
      },

      filterDisables() {
        return {
          user: true,
        };
      },
    },
  }
</script>
