<template>
  <ListBrowserFilterWrapper
    export-mutation="ProjectRecord.Browse.Export"
    :form.sync="form"
    :form-default="parsedDefaults"
    :order-options="parsedOrderOptions"
    :show-filter="showFilter"
    :form-function="$options.formFunction"
    @filtered="onFiltered"
    @input="onInput"
  >
    <VRow>
      <VCol
        class="py-0"
        cols="12"
        sm="8"
        xl="4"
      >
        <ProjectPicker
          clearable
          :disabled="disables.project"
          v-model="form.project"
        />
      </VCol>
      <VCol
        class="py-0"
        cols="12"
        sm="4"
        xl="2"
      >
        <KApiSelect
          :disabled="disables.offices"
          label="models.office.self"
          clearable
          multiple
          model="office"
          v-model="form.offices"
        />
      </VCol>
      <VCol
        class="py-0"
        cols="12"
        sm="6"
        lg="4"
        xl="3"
      >
        <KApiSelect
          :disabled="disables.projectTypes"
          label="models.projectType.self"
          clearable
          multiple
          model="projectType"
          v-model="form.projectTypes"
        />
      </VCol>
      <VCol
        class="py-0"
        cols="12"
        sm="6"
        lg="4"
        xl="3"
      >
        <KApiSelect
          :disabled="disables.projectStatuses"
          label="models.projectStatus.self"
          clearable
          multiple
          model="projectStatus"
          v-model="form.projectStatuses"
        />
      </VCol>
      <VCol
        class="py-0"
        cols="12"
        sm="6"
        lg="4"
        xl="3"
      >
        <KDatePicker
          :disabled="disables.recordDueBy"
          label="views.projectRecords.recordDueBy"
          clearable
          v-model="form.recordDueBy"
          :min="dateMin"
          :max="dateMax"
        />
      </VCol>
      <VCol
        class="py-0"
        cols="12"
        sm="6"
        lg="5"
        xl="3"
      >
        <KDatePicker
          :disabled="disables.createdAt"
          label="views.projectRecords.createdAt"
          clearable
          range
          v-model="form.createdAt"
          :min="dateMin"
          :max="today"
        />
      </VCol>
      <VCol
        class="py-0"
        cols="12"
        lg="7"
        xl="6"
      >
        <KSuggest
          :disabled="disables.createdBy"
          clearable
          model="user"
          label="views.projectRecords.createdBy"
          v-model="form.createdBy"
        />
      </VCol>
    </VRow>
  </ListBrowserFilterWrapper>
</template>

<script>
import ListBrowserFilterWrapper from "elements/list/ListBrowserFilterWrapper.vue";
import {KApiSelect, KDatePicker, KSuggest, ProjectPicker} from "forms/elements/index.js";
import {mapGetters} from 'vuex';
import {addYears, parseISO} from 'date-fns';
import {formatISODate} from "top/date-fns-format.js";

const formFunction = ({project, createdBy, projectTypes, projectStatuses, offices, teamMember, recordDueBy, createdAt}) => ({
  ...project && {project},
  ...projectTypes && projectTypes.length !== 0 && {projectTypes},
  ...projectStatuses && projectStatuses.length !== 0 && {projectStatuses},
  ...offices && offices.length !== 0 && {offices},
  ...teamMember && {teamMember},
  ...recordDueBy && {recordDueBy},
  ...createdBy && {createdBy},
  ...createdAt && createdAt.length === 2 && {createdAt: {from: createdAt[0], to: createdAt[1]}},
  // ...dateRange && dateRange.length === 2 && {date: {from: dateRange[0], to: dateRange[1]}},
});

const sortOptions = [
  {
    text: 'models.general.createdAt',
    value: 'CREATED_AT',
    secondary: true,
  },
  {
    text: 'models.project.id',
    value: 'PROJECT_ID',
  },
];

const defaults = {
  project: undefined,
  projectTypes: [],
  projectStatuses: [],
  offices: [],
  recordDueBy: undefined,
  createdBy: undefined,
  createdAt: [],
};

export default {
  formFunction,

  components: {
    ListBrowserFilterWrapper,
    KApiSelect,
    KDatePicker,
    KSuggest,
    ProjectPicker,
  },

  data() {
    return {
      form: Object.assign({}, defaults, this.defaults),
    };
  },

  props: {
    showFilter: Boolean,

    defaults: {
      type: Object,
      default: () => ({}),
    },

    disables: {
      type: Object,
      default: () => ({}),
    },

    sortDefault: {
      type: String,
      default: 'CREATED_AT',
    },

    orderDescDefault: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters('session', ['today']),

    parsedDefaults() {
      const payload = {};

      for (const key in defaults) {
        payload[key] = this.defaults.hasOwnProperty(key) ? this.defaults[key] : defaults[key];
      }

      return payload;
    },

    parsedOrderOptions() {
      const {sortDefault, orderDescDefault} = this;
      const payload = [];

      sortOptions.forEach((item) => {
        payload.push(item.value === sortDefault
          ? Object.assign({}, item, {default: true, defaultDesc: orderDescDefault})
          : item
        );
      });

      return payload;
    },

    dateMin() {
      return '2021-01-01';
    },

    dateMax() {
      return formatISODate(addYears(parseISO(this.today), 1));
    },
  },

  methods: {
    onInput(v) {
      this.$emit('input', v);
    },

    onFiltered(v) {
      this.$emit('filtered', v);
    }
  },
}
</script>
