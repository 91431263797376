<template>
  <VExpansionPanelContent class="grey lighten-4">
    <VSkeletonLoader type="list-item-two-line" v-if="$apollo.queries.timeslip.loading"/>
    <VContainer fluid v-else-if="timeslip" v-show="!edit">
      <VRow v-if="timeslip.isBillable">
        <VCol cols="auto">
          <VCard class="pa-2" color="orange lighten-5">
            <FaI class="orange--text text--darken-2" icon="file-invoice-dollar" size="lg"/>
            <span v-t="{path: 'views.timeslips.billableMsg', args: {amount: billings, rate: billingRate}}"/>
          </VCard>
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol>
          {{timeslip.description}}
        </VCol>
      </VRow>
      <VRow v-if="showDurationError">
        <VCol>
          <VAlert text type="warning">
            <span v-t="{path: 'views.timeslips.durationErrorMsg', args: {range: durationErrorRange}}"/>
          </VAlert>
        </VCol>
      </VRow>
      <VRow v-if="isActive">
        <VCol>
          <VAlert text type="info">
            <span v-t="'views.timeslips.isActiveMsg'"/>
          </VAlert>
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12" v-if="hasMultipleProjects">
          <VCard outlined>
            <VCardTitle class="blue lighten-5">
              <FaI class="blue--text text--lighten-2 mr-2" icon="layer-group"/>
              <span v-t="{path: 'models.project.self', choice: 2}"/>
              <VSpacer/>
              <FaI v-if="hasActivityTemplate" icon="star" class="orange--text text--darken-2" size="sm"/>
              <PartitionsSetDialog v-else-if="canCopyToPartitionsSet" :partitions="partitionsForSet" @success="onPartitionsSetSuccess"/>
            </VCardTitle>
            <VList>
              <template v-for="(partition, index) in timeslip.timeslipPartitions">
                <VDivider :key="`d-${partition.id}`" v-if="index !== 0"/>
                <TimeslipItemPartition :id="partition.id" :key="partition.id"/>
              </template>
            </VList>
          </VCard>
        </VCol>
        <VCol v-if="!hasMultipleProjects" cols="12" md="7" lg="8">
          <VCard flat>
            <ProjectListItem :id="timeslip.timeslipPartitions[0].project.id" dense/>
          </VCard>
        </VCol>
        <VCol cols="12" :md="!hasMultipleProjects ? '5' : undefined" :lg="!hasMultipleProjects ? '4' : undefined">
          <VCard flat>
            <UserListItem :id="timeslip.user.id"/>
          </VCard>
        </VCol>
      </VRow>
      <VRow class="grey--text py-2" justify="space-between" no-gutters>
        <VCol class="d-flex flex-column" cols="auto">
          <small>
            {{createdTimestamp}}
          </small>
          <small v-if="updatedTimestamp">
            <FaI icon="pen"/>
            {{updatedTimestamp}}
          </small>
        </VCol>
        <VCol cols="auto" v-if="canInteract">
          <VDialog max-width="250" v-if="canDelete" v-model="showDelete">
            <template v-slot:activator="{on}">
              <VBtn :disabled="isDeleting" :loading="isDeleting" color="red" small v-on="on">
                <FaI icon="trash"/>&nbsp;
                <span v-t="'actions.delete'"/>
              </VBtn>
            </template>

            <VCard>
              <VCardTitle class="text-h5" v-t="'actions.confirm'"/>
              <VCardActions>
                <VSpacer/>
                <VBtn @click="doDelete" color="red darken-1" text>
                  <span v-t="'actions.delete'"/>
                </VBtn>
                <VBtn @click="showDelete = false" color="grey" text>
                  <span v-t="'actions.cancel'"/>
                </VBtn>
              </VCardActions>
            </VCard>
          </VDialog>
          <VBtn :disabled="isDeleting" @click="toggleEdit" class="ml-6" color="amber" small v-if="canUpdate">
            <FaI icon="pen-to-square"/>&nbsp;
            <span v-t="'actions.edit'"/>
          </VBtn>
        </VCol>
      </VRow>
    </VContainer>
    <FormTimeslip :target="id" @cancel="endEdit" @success="onUpdate" v-if="edit"/>
  </VExpansionPanelContent>
</template>

<script>
import tickerMixin from "top/mixins/tickerMixin.js"
import FormTimeslip from './FormTimeslip.vue'
import PartitionsSetDialog from '../../partitionsSet/elements/PartitionsSetDialog.vue';
import ProjectListItem from "../../projects/browse/elements/ProjectListItem.vue";
import TimeslipItemPartition from "./TimeslipItemPartition.vue";
import UserListItem from "../../users/browse/UserListItem.vue";
import Queries from 'queries/index.js'
import {mapGetters} from 'vuex'
import {parseISO} from "date-fns";
import {format} from "top/date-fns-format.js";
import currencyMixin from "../../../mixins/currencyMixin.js";

export default {
  mixins: [
    tickerMixin,
    currencyMixin,
  ],

  components: {
    FormTimeslip,
    PartitionsSetDialog,
    ProjectListItem,
    TimeslipItemPartition,
    UserListItem,
  },

  data: () => ({
    edit: false,
    showDelete: false,
    isDeleting: false,
  }),

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  inject: ['expansionPanel'],

  apollo: {
    timeslip: {
      query: Queries.Timeslip.Single.ExpansionPanelContent,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.isVisible;
      },
    },

    partitionsSetFromActivity: {
      query: Queries.PartitionsSet.Single.IDFromContext,
      variables() {
        return {
          id: this.id,
          context: 'TIMESLIP',
        };
      },
      skip() {
        return !this.isVisible;
      },
    },

    canUpdate() {
      return this.policyChecker({
        policy: 'update',
        type: 'Timeslip',
        id: this.id,
        skip: () => !this.isVisible,
      });
    },

    canDelete() {
      return this.policyChecker({
        policy: 'delete',
        type: 'Timeslip',
        id: this.id,
        skip: () => !this.isVisible,
      });
    },

    canCopyToPartitionsSet() {
      return this.policyChecker({
        policy: 'copyToPartitionsSet',
        type: 'Timeslip',
        id: this.id,
        skip: () => !this.isVisible,
      });
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    isVisible() {
      return this.expansionPanel.isActive;
    },

    isMe() {
      const {timeslip, meId} = this;
      return timeslip ? timeslip.user.id === meId : false;
    },

    isEdited() {
      const {timeslip} = this;
      if (!timeslip) {
        return false;
      }
      return timeslip.isFromTimekeeper
          ? timeslip.stopAt && timeslip.stopAt !== timeslip.updatedAt
          : timeslip.createdAt !== timeslip.updatedAt;
    },

    isActive() {
      const {timeslip} = this;
      return Boolean(timeslip?.startAt && !timeslip?.stopAt);
    },

    canInteract() {
      const {canUpdate, canDelete} = this;
      return canUpdate || canDelete;
    },

    billings() {
      const {timeslip, getCurrency} = this;
      return timeslip?.isBillable && timeslip?.duration ? getCurrency(timeslip.duration / 3600 * timeslip.billingRate) : undefined;
    },

    billingRate() {
      const {timeslip, getCurrency} = this;
      return timeslip?.isBillable ? getCurrency(timeslip.billingRate) : undefined;
    },

    createdTimestamp() {
      const {timeslip, getTickerForHumans} = this;
      return `${this.$t('actions.submitted')} ${getTickerForHumans(timeslip.createdAt)}`;
    },

    updatedTimestamp() {
      const {isEdited, timeslip, getTickerForHumans} = this;
      return isEdited ? `${this.$t('actions.updated')} ${getTickerForHumans(timeslip.updatedAt)}` : '';
    },

    startAt() {
      const {timeslip} = this;
      return timeslip?.startAt ? format(parseISO(timeslip.startAt), 'p') : undefined;
    },

    stopAt() {
      const {timeslip} = this;
      return timeslip?.stopAt ? format(parseISO(timeslip.stopAt), 'p') : undefined;
    },

    showDurationError() {
      return Boolean(this.timeslip?.durationMismatch);
    },

    durationErrorRange() {
      const {showDurationError, timeslip: {startAt, stopAt, startAtTimeZone, stopAtTimeZone}} = this;
      if (!showDurationError) {
        return undefined;
      }

      const p1 = format(parseISO(startAt), 'p', startAtTimeZone);
      const p2 = format(parseISO(stopAt), 'p z', stopAtTimeZone);

      return `${p1} - ${p2}`
    },

    hasMultipleProjects() {
      const {timeslip} = this;
      return timeslip ? timeslip.timeslipPartitions.length > 1 : false;
    },

    partitionsForSet() {
      return !this.timeslip ? undefined : this.timeslip.timeslipPartitions.map(({weight, comment, isBillable, project: {id: projectId}}) => ({
        weight,
        comment,
        isTimeslipBillable: isBillable,
        projectId,
      }));
    },

    hasActivityTemplate() {
      return Boolean(this.partitionsSetFromActivity);
    },
  },

  watch: {
    'expansionPanel.isActive'(v) {
      if (!v) {
        this.endEdit();
      }
    }
  },

  methods: {
    onUpdate(response) {
      this.$emit('update', response);
      this.endEdit();
    },

    async doDelete() {
      this.showDelete = false;
      await this.deleteAction();
    },

    async deleteAction() {
      const {id} = this,
          variables = {
            id,
          };

      this.isDeleting = true;
      try {
        const {data: {timeslipDelete: response}} = await this.$apollo.mutate({
          mutation: Queries.Timeslip.Write.Delete,
          variables,
        });
        this.$emit('delete', response);
      } finally {
        this.isDeleting = false;
      }
    },

    toggleEdit() {
      this.edit = !this.edit;
    },

    endEdit() {
      this.edit = false;
    },

    onPartitionsSetSuccess(event) {
      const apolloClient = this.$apollo.provider.defaultClient;
      apolloClient.writeQuery({
        query: Queries.PartitionsSet.Single.IDFromContext,
        variables: {
          id: this.id,
          context: 'TIMESLIP',
        },
        data: {
          partitionsSetFromActivity: {
            __typename: 'PartitionsSet',
            id: event.id,
          }
        }
      });
      this.$apollo.queries.canCopyToPartitionsSet.refetch();
    },
  }
}
</script>
