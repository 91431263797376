import gql from "graphql-tag";


// Write

const FormFill = gql`
  query(
    $id: ID!
  ) {
    issueComment(
      id: $id
    ) {
      id
      comment
    }
  }
`;

const Create = gql`
  mutation(
    $issueId: ID!
    $comment: String!
  ) {
    issueCommentCreate(
      input: {
        issue: {
          connect: $issueId
        }
        comment: $comment
      }
    ) {
      id
      comment
      issue {
        id
      }
      user {
        id
      }
      createdAt
      updatedAt
    }
  }
`;

const Update = gql`
  mutation(
    $id: ID!
    $comment: String!
  ) {
    issueCommentUpdate(
      id: $id
      input: {
        comment: $comment
      }
    ) {
      id
      comment
      issue {
        id
      }
      user {
        id
      }
      createdAt
      updatedAt
    }
  }
`;


// Export

const Write = {
  FormFill,
  Create,
  Update,
};

export default {
  Write,
};
