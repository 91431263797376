import gql from 'graphql-tag'
import PolicyTest from './policyTest.js'
import PaginatorFragment from './paginatorFragment.js'
// Fragments

const ThumbnailFragment = gql`
  fragment companyThumbnailFragment on Company {
    id
    name
    slug
    identicon {
      id
      url
    }
    permissions(policies: [{policy: "view"}]) {
      ...isAllowedFragment
    }
  }
  ${PolicyTest.Fragments.IsAllowed}
`;

const CompanyListFragment = gql`
  fragment companyListFragment on Company
  {
    ...companyThumbnailFragment
    nameShort
    createdAt
    updatedAt
    contact {
      id
      location {
        id
        city {
          id
          name
          countryDivision {
            id
            code
          }
        }
      }
    }
    companyType {
      id
    }
  }
  ${ThumbnailFragment}
`;


// Browse

const List = gql`
  query(
    $search: String
    $companyType: ID
    $orderBy: [QueryCompaniesOrderByOrderByClause!]
    $page: Int
    $first: Int!
  ) {
    companies(
      search: $search
      companyType: $companyType
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        ...companyListFragment
      }
    }
  }
  ${PaginatorFragment}
  ${CompanyListFragment}
`;

const Row = gql`
  query(
    $id: ID!
  ) {
    company(
      id: $id
    ) {
      ...companyListFragment
    }
  }
  ${CompanyListFragment}
`;


// Forms

const Populate = gql`
  query(
    $id: ID!
  ) {
    company(
      id: $id
    ) {
      id
      name
    }
  }
`;

const Search = gql`
  query(
    $search: String
    $companyType: ID
    $page: Int
    $first: Int!
  ) {
    companies(
      search: $search
      companyType: $companyType
      page: $page
      first: $first
    ) {
      data {
        id
        slug
        name
      }
    }
  }
`;


// Single

const IdFromSlug = gql`
    query(
        $slug: String!
    ) {
        companyBySlug(
            slug: $slug
        )
        {
            id
            slug
        }
    }
`;

const Main = gql`
  query(
    $id: ID!
  ) {
    company(
      id: $id
    ) {
      id
      name
      nameShort
    }
  }
`;

const Thumbnail = gql`
  query(
    $id: ID!
  ) {
    company(
      id: $id
    ) {
      ...companyThumbnailFragment
    }
  }
  ${ThumbnailFragment}
`;


// Export

const Fragments = {
  ThumbnailFragment,
  CompanyListFragment,
};

const Browse = {
  List,
  Row,
};

const Forms = {
  Populate,
  Search,
};

const Single = {
  IdFromSlug,
  Main,
  Overview: Main,
  Thumbnail,
};

export default {
  Fragments,
  Browse,
  Forms,
  Single,
}
