<template>
  <VSkeletonLoader v-if="$apollo.queries.office.loading" type="list-item-two-line"/>
  <VListItem v-else :to="to" two-line>
    <VListItemContent>
      <VListItemTitle>
        {{office.name}}
      </VListItemTitle>
      <VListItemSubtitle>
        {{office.contact.location.addressInline}}
      </VListItemSubtitle>
    </VListItemContent>
  </VListItem>
</template>

<script>
import Queries from 'queries/index.js'

export default {
  props: {
    id: {
      type: [String, Number],
      require: true,
    },
  },

  apollo: {
    office: {
      query: Queries.Office.Browse.ListItem,
      variables() {
        return {
          id: this.id
        };
      },
    },

    canView() {
      return this.policyChecker({
        policy: 'view',
        type: 'Office',
        id: this.id,
      });
    },
  },

  computed: {
    to() {
      const {canView, office} = this,
          {id, slug} = office;
      return canView && office ? {name: 'office', params: {id, slug}} : undefined;
    },
  },
}
</script>
