<template>
  <VCard tile>
    <VCardTitle class="pb-0 subtitle-2 grey--text text--darken-3">
      <span v-t="'views.activityAnalytics.title'"/>
      <VDialog max-width="600">
        <template #activator="{on, attrs}">
          <VBtn icon class="ml-2" v-bind="attrs" v-on="on">
            <FaI :icon="['far', 'circle-info']"/>
          </VBtn>
        </template>
        <template #default="dialog">
          <VCard>
            <VCardTitle v-t="'views.activityAnalytics.help.title'"/>
            <VCardText>
              <span v-t="'views.activityAnalytics.help.description'"/>
              <ul>
                <li v-for="item in helpItems" :key="item">
                  <strong v-t="'views.activityAnalytics.' + item"/>
                  <strong>({{item.toUpperCase()}}):</strong>
                  <span v-t="'views.activityAnalytics.help.' + item"/>
                </li>
              </ul>
            </VCardText>
            <VDivider/>
            <VCardActions>
              <VSpacer/>
              <VBtn text @click="dialog.value = false">
                <span v-t="'actions.close'"/>
              </VBtn>
            </VCardActions>
          </VCard>
        </template>
      </VDialog>
    </VCardTitle>
    <VCardText>
      <VDataTable
        dense
        disable-pagination
        disable-filtering
        disable-sort
        hide-default-footer
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        :loading="$apollo.loading"
        class="elevation-1"
      >
        <template v-for="h in helpItems" v-slot:[`item.${h}`]="{value}">
          {{getPercentage(value)}}
        </template>
        <template v-for="h in helpItems" v-slot:[`header.${h}`]="{ header }">
          <VTooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{h.toUpperCase()}}</span>
            </template>
            <span v-t="'views.activityAnalytics.' + h"/>
          </VTooltip>
        </template>
      </VDataTable>
    </VCardText>
  </VCard>
</template>

<script>
import {formatISODate, format} from 'top/date-fns-format.js';
import {startOfMonth, endOfMonth, subMonths} from 'date-fns';
import percentageMixin from 'top/mixins/percentageMixin.js';
import Queries from "queries/index.js";
import {mapState} from 'vuex';

const updateQuery = function(previousResult, { subscriptionData }) {
    const item = subscriptionData?.data?.activityAnalyticsUpdated;

    return !item ? previousResult : {
      activityAnalytics: Object.assign({}, previousResult.activityAnalytics, item),
    }
  };

export default {
  mixins: [
    percentageMixin,
  ],

  data: () => ({
    helpItems: ['awr', 'apwr', 'abwr'],
    headers: [
      { text: 'Month', value: 'month' },
      { text: 'AWR', value: 'awr' },
      { text: 'APWR', value: 'apwr' },
      { text: 'ABWR', value: 'abwr' },
    ],
  }),

  props: {
    context: {
      type: String,
      required: true,
    },

    id: {
      type: [Number, String],
      required: true,
    },

    subscribe: Boolean,
  },

  apollo: {
    lastMonthData: {
      query: Queries.ActivityAnalytics.Single.Main,
      variables() {
        const {context, lastMonthFrom: from, lastMonthTo: to, id} = this;
        return {
          date: {
            from,
            to,
          },
          contextType: context,
          contextId: id,
        };
      },
      update: (response) => response?.activityAnalytics,
      context: {batch: false},
      subscribeToMore: {
        skip() {
          return !this.subscribe;
        },
        document: Queries.ActivityAnalytics.Subscriptions.ActivityAnalyticsUpdated,
        variables() {
          const {lastMonthFrom: from, lastMonthTo: to, context, id} = this;
          return {
            date: {
              from,
              to,
            },
            contextType: context,
            contextId: id,
          };
        },
        updateQuery,
      },
    },

    thisMonthData: {
      query: Queries.ActivityAnalytics.Single.Main,
      variables() {
        const {context, thisMonthFrom: from, thisMonthTo: to, id} = this;
        return {
          date: {
            from,
            to,
          },
          contextType: context,
          contextId: id,
        };
      },
      update: (response) => response?.activityAnalytics,
      context: {batch: false},
      subscribeToMore: {
        skip() {
          return !this.subscribe;
        },
        document: Queries.ActivityAnalytics.Subscriptions.ActivityAnalyticsUpdated,
        variables() {
          const {thisMonthFrom: from, thisMonthTo: to, context, id} = this;
          return {
            date: {
              from,
              to,
            },
            contextType: context,
            contextId: id,
          };
        },
        updateQuery,
      },
    },
  },

  computed: {
    ...mapState('session', ['now']),

    lastMonth() {
      return subMonths(this.now, 1);
    },

    lastMonthFrom() {
      return formatISODate(startOfMonth(this.lastMonth));
    },

    lastMonthTo() {
      return formatISODate(endOfMonth(this.lastMonth));
    },

    thisMonthFrom() {
      return formatISODate(startOfMonth(this.now));
    },

    thisMonthTo() {
      return formatISODate(this.now);
    },

    lastMonthTitle() {
      return format(this.lastMonth, 'MMMM');
    },

    thisMonthTitle() {
      return format(this.now, 'MMMM');
    },

    items() {
      return [
        {
          month: this.thisMonthTitle,
          ...this.thisMonthData,
        },
        {
          month: this.lastMonthTitle,
          ...this.lastMonthData,
        },
      ];
    },
  },
}
</script>
