import gql from 'graphql-tag';

// Fragments

const LocationCityFlatFragment = gql`
  fragment locationCityFlatFragment on Location
  {
    city {
      id
      name
    }
    countryDivision {
      id
      name
    }
    country {
      id
      name
    }
  }
`;

const LocationCityNestedFragment = gql`
  fragment locationCityNestedFragment on Location
  {
    city {
      id
      name
      country {
        id
        name
      }
      countryDivision {
        id
        name
      }
    }
  }
`;

const LocationFormFillFragment = gql`
  fragment locationFormFillFragment on Location
  {
    street
    streetExtra
    postalCode
    ...locationCityNestedFragment
  }
  ${LocationCityNestedFragment}
`;


// Write

const FormFill = gql`
  query(
    $id: ID!
  ) {
    location(
      id: $id
    ) {
      ...locationForFormFragment
    }
  }
  ${LocationFormFillFragment}
`;


// Export

const Fragments = {
  CityFlat: LocationCityFlatFragment,
  CityNested: LocationCityNestedFragment,
  FormFill: LocationFormFillFragment,
};

const Write = {
  FormFill,
};

export default {
  Fragments,
  Write,
};
