<template>
  <VExpansionPanelHeader ripple>
    <VSkeletonLoader type="list-item" v-if="$apollo.loading"/>
    <VRow align="center" no-gutters v-else style="max-width: 100%; padding-right: 28px; margin-right: -24px">
      <VCol class="mr-2" cols="auto" v-if="!simple">
        <UserThumbnail :id="timeslip.user.id" disabled size="sm"/>
      </VCol>
      <VCol>
        <VRow no-gutters>
          <VCol cols="12" sm="auto">
            <span class="mr-2">
              {{timeslip.date}} - <strong>{{time}}</strong>
            </span>
            <FaI class="orange--text text--darken-2 mr-2" icon="file-invoice-dollar" v-if="simple && timeslip.isBillable"/>
            <FaI :icon="timeslip.isFromTimekeeper ? 'timer' : 'keyboard'" class="grey--text mr-2"/>
            <FaI v-if="hasMultipleProjects" class="mr-2 blue--text text--lighten-2" icon="layer-group"/>
            <FaI icon="pen" v-if="isEdited" class="grey--text mr-2"/>
            <FaI v-if="isActive" :icon="['far', 'circle-play']" class="green--text text--lighten-1 mr-2"/>
          </VCol>
          <VCol cols="12" sm="auto">
            <span v-if="showTimekeeperTimes && startAtDisplay">
              {{startAtDisplay}}
            </span>
            <span v-if="showTimekeeperTimes && stopAtDisplay">
              - {{stopAtDisplay}}
            </span>
          </VCol>
          <VCol v-if="!simple && timeslip.isBillable" cols="12">
            <FaI class="orange--text text--darken-2 mr-1" icon="file-invoice-dollar"/>
            <strong>
              {{amountFormatted}}
            </strong>
          </VCol>
          <VCol cols="12" class="text-nowrap">
            <VRow no-gutters>
              <VCol cols="12" sm="auto">
                {{timeslipType.name}}
              </VCol>
              <VCol cols="auto" class="mx-1 hidden-xs-only">-</VCol>
              <VCol class="d-flex flex-nowrap">
                <div class="flex-shrink-1 text-truncate">
                  {{project.name}}
                </div>
                <div v-if="hasMultipleProjects" class="flex-shrink-0">
                  <VChip x-small color="blue lighten-3" class="ml-1">
                    +{{timeslip.timeslipPartitions.length - 1}}
                  </VChip>
                </div>
                <small v-else class="ml-1 grey--text flex-shrink-0">
                  ({{timeslip.timeslipPartitions[0].project.id}})
                </small>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VExpansionPanelHeader>
</template>

<script>
  import Queries from 'queries/index.js'
  import getTimestampFromSeconds from "top/getTimestampFromSeconds.js";
  import currencyMixin from "../../../mixins/currencyMixin.js";
  import {UserThumbnail} from 'elements/index.js'
  import {mapGetters, mapState} from 'vuex'
  import {parseISO, differenceInCalendarDays} from "date-fns";
  import {utcToZonedTime} from "date-fns-tz";
  import {format} from "top/date-fns-format.js";

  export default {
    mixins: [
      currencyMixin,
    ],

    components: {
      UserThumbnail,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      focusProject: [Number, String],

      simple: Boolean,
    },

    apollo: {
      timeslip: {
        query: Queries.Timeslip.Single.ExpansionPanelHeader,
        variables() {
          return {
            id: this.id,
          };
        },
      },

      project: {
        query: Queries.Project.Forms.Populate,
        variables() {
          return {
            id: this.featureProjectId,
          }
        },
        skip() {
          return !this.featureProjectId;
        },
      },

      timeslipType: {
        query: Queries.TimeslipType.Forms.Populate,
        variables() {
          return {
            id: this.timeslip.timeslipType.id,
          }
        },
        skip() {
          return !this.timeslip;
        },
      },
    },

    computed: {
      ...mapGetters('session', ['meId']),

      ...mapState('session', ['timeZone']),

      isMe() {
        const {timeslip, meId} = this;
        return timeslip ? timeslip.user.id === meId : false;
      },

      featureProjectId() {
        const {timeslip, focusProject} = this;

        if (!timeslip) {
          return undefined;
        }

        if (focusProject && timeslip.timeslipPartitions.some((e) => e.project.id === focusProject)) {
          return focusProject;
        }

        return timeslip.timeslipPartitions[0].project.id;
      },

      hasMultipleProjects() {
        const {timeslip} = this;
        return timeslip ? timeslip.timeslipPartitions.length > 1 : false;
      },

      isEdited() {
        const {timeslip} = this;
        if (!timeslip) {
          return false;
        }
        return timeslip.isFromTimekeeper
          ? timeslip.stopAt && timeslip.stopAt !== timeslip.updatedAt
          : timeslip.createdAt !== timeslip.updatedAt;
      },

      isActive() {
        const {timeslip} = this;
        return Boolean(timeslip?.startAt && !timeslip?.stopAt);
      },

      time() {
        return getTimestampFromSeconds(this.timeslip?.duration);
      },

      showTimekeeperTimes() {
        return !this.timeslip?.durationMismatch;
      },

      startAt() {
        const {timeslip} = this;
        return timeslip?.startAt ? parseISO(timeslip.startAt) : undefined;
      },

      stopAt() {
        const {timeslip} = this;
        return timeslip?.stopAt ? parseISO(timeslip.stopAt) : undefined;
      },

      startAtTimeZone() {
        const {timeslip} = this;
        return timeslip?.startAtTimeZone;
      },

      stopAtTimeZone() {
        const {timeslip} = this;
        return timeslip?.stopAtTimeZone ?? timeslip?.startAtTimeZone;
      },

      showStartAtZone() {
        const {startAtTimeZone, stopAtTimeZone, stopAt, timeZone} = this;
        return startAtTimeZone && (startAtTimeZone !== timeZone || (stopAt && startAtTimeZone !== stopAtTimeZone));
      },

      showEndAtZone() {
        const {stopAtTimeZone, startAtTimeZone, timeZone} = this;
        return stopAtTimeZone && (stopAtTimeZone !== timeZone || stopAtTimeZone !== startAtTimeZone);
      },

      startAtDisplay() {
        const {startAt, startAtTimeZone: timeZone, showStartAtZone} = this;
        return startAt ? format(utcToZonedTime(startAt, timeZone), showStartAtZone ? 'p z' : 'p', {timeZone}) : undefined;
      },

      stopAtDisplay() {
        const {startAt, stopAt, stopAtTimeZone: timeZone, showEndAtZone} = this;

        if (!stopAt) {
          return undefined;
        }

        const days = differenceInCalendarDays(stopAt, startAt);
        const time = format(utcToZonedTime(stopAt, timeZone), showEndAtZone ? 'p z' : 'p', {timeZone});
        return days ? `+${days} ${time}` : time;
      },

      amountFormatted() {
        const {timeslip, getCurrency} = this;
        return getCurrency(timeslip?.amount);
      },
    },
  }
</script>
