<template>
  <VRow>
    <VCol cols="12" lg="9" xl="10">
      <KTextField
        v-model="form.comment"
        :validator="$v.form.comment"
        label="models.projectRecord.hold.comment"
        hint="forms.projectRecord.hold.comment_hint"
      />
    </VCol>

    <VCol cols="12" lg="3" xl="2">
      <KDatePicker
        v-model="form.scheduledResumption"
        :validator="$v.form.scheduledResumption"
        label="models.projectRecord.hold.scheduledResumption"
        hint="forms.general.required"
        :min="scheduledResumptionMin"
        :max="scheduledResumptionMax"
      />
    </VCol>
  </VRow>
</template>

<script>
import {KDatePicker, KTextField, scopedFormsMixin} from "forms/elements/index.js";
import {betweenLength, betweenDate, required} from "validators/index.js";
import {formatISODate} from "top/date-fns-format.js";
import {addDays, addYears} from "date-fns";

export default {
  mixins: [
    scopedFormsMixin,
  ],

  components: {
    KDatePicker,
    KTextField,
  },

  data: () => ({
    form: {
      comment: null,
      scheduledResumption: null,
    },
  }),

  validations() {
    const {scheduledResumptionMin, scheduledResumptionMax} = this;
    return {
      form: {
        comment: {
          required,
          betweenLength: betweenLength(2, 250),
        },
        scheduledResumption: {
          required,
          betweenDate: betweenDate(scheduledResumptionMin, scheduledResumptionMax),
        },
      },
    };
  },

  computed: {
    scheduledResumptionMin() {
      return formatISODate(addDays(new Date(), 1));
    },

    scheduledResumptionMax() {
      return formatISODate(addYears(new Date(), 2));
    }
  },
}
</script>
