<template>
  <div>
    <span class="text-h6" v-t="localTitle"/>
    <div class="d-flex flex-nowrap align-baseline">
      <VRadioGroup hide-details row v-model="value" :disabled="isLoading">
        <VRadio v-for="{label, value} in items" :label="label" :key="value" :value="value"/>
      </VRadioGroup>
      <FormEventIndicator ref="indicator" class="ml-2"/>
    </div>
  </div>
</template>

<script>
import Queries from "queries/index.js";
import {FormEventIndicator} from "elements/index.js";
import {mapGetters} from 'vuex';
import {camelCase, upperFirst} from "lodash-es";

const locales = [
    'en-CA',
    'fr-CA',
  ];

  export default {
    components: {
      FormEventIndicator,
    },

    data: () => ({
      isActive: false,
    }),

    props: {
      id: String,

      name: {
        type: String,
        required: true,
      },

      items: {
        type: Array,
        required: true,
        validator: (i) => !i.some((o) => !o.hasOwnProperty('value') || o.hasOwnProperty('label')),
      },

      title: String,
    },

    apollo: {
      apiValue: {
        query() {
          return Queries.User.Single[this.namePascalCase];
        },
        variables() {
          return {
            id: this.id ?? this.meId,
          };
        },
        update({user}) {
          return user?.settings?.[this.nameCamelCase]
        },
      },
    },

    computed: {
      ...mapGetters('session', ['meId']),

      nameCamelCase() {
        return camelCase(this.name);
      },

      namePascalCase() {
        return upperFirst(camelCase(this.name));
      },

      localTitle() {
        const {nameCamelCase, title} = this;
        return title ?? `models.user.settings.${nameCamelCase}`;
      },

      value: {
        get() {
          return this.apiValue;
        },
        set(v) {
          this.setValue(v);
        },
      },

      isLoading() {
        return this.$apollo.loading || this.isActive;
      },
    },

    methods: {
      async setValue(value) {
        this.$refs.indicator.$emit('submit');
        this.isActive = true;

        try {
          const response = await this.$apollo.mutate({
            mutation: Queries.User.Settings[this.namePascalCase],
            variables: {
              id: this.id ?? this.meId,
              [this.nameCamelCase]: value,
            },
          });
          this.$refs.indicator.$emit('success', response);
        } catch(e) {
          this.$refs.indicator.$emit('error', e);
        }
        finally {
          this.isActive = false;
        }
      },
    },
  }
</script>
