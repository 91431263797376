import Main from './Main.vue'
import Overview from './Overview.vue'
import BillingRates from './BillingRates.vue'
import Timeslips from './Timeslips.vue'
import Edit from './Edit.vue'
import Expenses from './Expenses.vue'
import Invoicing from './Invoicing.vue'
import ProjectRecord from './ProjectRecord.vue'
import Team from './Team.vue'
// import Devices from './Devices.vue'
// import Inspections from './Inspections.vue'
// import CreateInspections from './CreateInspection.vue'

export default {
  // CreateInspections,
  Main,
  Overview,
  BillingRates,
  Timeslips,
  Edit,
  Expenses,
  Invoicing,
  ProjectRecord,
  Team,
  // Devices,
  // Inspections,
}
