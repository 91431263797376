<template>
  <VueJsonPretty v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
//import 'vue-json-pretty/lib/styles.css';

export default {
  components: {
    VueJsonPretty,
  },
}
</script>
