import Vue from 'vue'
import Queries from 'queries/index.js';
import stringify from 'json-stringify-deterministic';
import {subDays} from "date-fns";
import {formatISODate} from "top/date-fns-format.js";
import {enCA, frCA} from "date-fns/locale";

const TICKER_INTERVAL = 30000; // milliseconds
const STORAGE_AUTH_TOKEN = 'auth-token';
const STORAGE_ME = 'me';
const STORAGE_USER_ID = 'user-id';
const localeLibrary = {
  'en-CA': enCA,
  'fr-CA': frCA,
};

const getTimeZone = () => Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'America/Toronto';

let tickerPointer = null;

const data = {
  namespaced: true,

  state: {
    now: new Date,
    me: null,
    authError: null,
    timeZone: getTimeZone(),
    isLoading: false,
  },

  getters: {
    isSignedIn: (state) => state.me !== null,

    meId: (state) => state?.me?.id,

    locale: (state) => state?.me?.settings?.locale || 'en-CA',

    timeFormat: (state) => state?.me?.settings?.timeFormat || 'default',

    dateLocaleObject: (state, getters) => {
      const locale = localeLibrary[getters.locale];
      const timeFormat = getters.timeFormat;

      if (timeFormat === 'default') {
        return locale;
      }

      const time = timeFormat === '24hr' ? frCA.formatLong.time : enCA.formatLong.time;

      const customLocale = Object.assign({}, locale);
      customLocale.formatLong = Object.assign({}, customLocale.formatLong, {time});

      return customLocale;
    },

    today: (state) => formatISODate(state.now),

    timekeeperDurationFrom: (state) => formatISODate(subDays(state.now, 13)),
  },

  mutations: {
    refreshTicker(state) {
      state.now = new Date;
    },

    clearAuth(state) {
      state.me = null;
    },

    storeMe(state, payload) {
      Vue.set(state, 'me', payload);
    },

    setAuthError(state, err) {
      state.authError = err;
    },

    refreshTimeZone(state) {
      state.timeZone = getTimeZone();
    },

    setLoading(state, payload) {
      state.isLoading = Boolean(payload);
    },
  },

  actions: {
    startTicker({commit}) {
      if (tickerPointer === null) {
        tickerPointer = setInterval(() => {
          commit('refreshTicker');
        }, TICKER_INTERVAL);
      }
    },

    getAuthFromLocalStorage({commit, dispatch}) {
      const me = JSON.parse(localStorage.getItem(STORAGE_ME));
      if (me) {
        commit('storeMe', me);
        dispatch('getMe');
      }
    },

    haltTicker({state}) {
      clearInterval(tickerPointer);
      state.now = null;
      tickerPointer = null;
    },

    signIn: async ({commit, dispatch, rootState}, {username, password}) => {
      const {data: {signin: {access_token: token}}} = await rootState.api.mutate({
        mutation: Queries.Auth.SignIn,
        variables: {
          username,
          password
        }
      });

      localStorage.setItem(STORAGE_AUTH_TOKEN, token);

      const client = rootState.api.getClient();

      await client.clearStore().then(() => {
        client.resetStore();
      });

      const response =  await dispatch('getMe');
      dispatch('flash/success', 'auth.signIn', {root: true});
      return response;
    },

    signOut: async ({getters, dispatch, rootState}) => {
      if (!getters.isSignedIn) {
        return false;
      }

      await rootState.api.mutate({
        mutation: Queries.Auth.SignOut
      });

      dispatch('clearLocalStorage');

      const client = rootState.api.getClient();

      await client.clearStore().then(() => {
        client.resetStore();
      });

      dispatch('flash/info', 'auth.signOut', {root: true});

      return true;
    },

    clearLocalStorage({commit}) {
      localStorage.clear();
      commit('clearAuth');
    },

    getMe: async ({dispatch, rootState}) => {
      const {data: {me}} = await rootState.api.query({
        query: Queries.Auth.Me,
        fetchPolicy: "network-only",
      });
      dispatch('storeMe', me);
      return me;
    },

    storeMe({commit, dispatch}, me) {
      if (me) {
        localStorage.setItem(STORAGE_ME, stringify(me));
        localStorage.setItem(STORAGE_USER_ID, me?.id);
      } else {
        dispatch('clearLocalStorage');
      }
      commit('storeMe', me);
    },

    refreshTimeZone({commit}) {
      commit('refreshTimeZone');
    },

    startLoading({commit}) {
      commit('setLoading', true);
    },

    stopLoading({commit}) {
      commit('setLoading', false);
    },
  },
};

export default data;
