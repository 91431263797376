import gql from 'graphql-tag';

// Select

const Inspection = gql`
  query(
    $id: ID!
  ) {
    inspectionPerformance(
      id: $id
    ) {
      id
      field
      value
      comment
      createdAt
      updatedAt
      inspection {
        id
      }
      device {
        id
      }
      user {
        id
      }
    }
  }
`;


// Write

const Create = gql`
  mutation(
    $inspectionId: ID!
    $deviceId: ID!
    $field: String!
    $value: Float
    $comment: String
  ) {
    inspectionPerformanceCreate(
      input: {
        inspection: {
          connect: $inspectionId
        },
        device: {
          connect: $deviceId
        },
        field: $field
        value: $value
        comment: $comment
      }
    ) {
      id
      field
      value
      comment
      createdAt
      updatedAt
      inspection {
        id
      }
      device {
        id
      }
      user {
        id
      }
    }
  }
`;

const Update = gql`
  mutation(
    $id: ID!
    $value: Float
    $comment: String
  ) {
    inspectionPerformanceCreate(
      id: $id,
      input: {
        value: $value
        comment: $comment
      }
    ) {
      id
      field
      value
      comment
      createdAt
      updatedAt
      inspection {
        id
      }
      device {
        id
      }
      user {
        id
      }
    }
  }
`;

const Submit = gql`
  mutation(
    $inspectionId: ID!
    $fields: [InspectionPerformanceSubmitField!]!
  ) {
    inspectionPerformanceCreate(
      input: {
        inspectionId: $inspectionId
      }
      fields: $fields
    ) {
      id
      field
      value
      comment
      createdAt
      updatedAt
      inspection {
        id
      }
      device {
        id
      }
      user {
        id
      }
    }
  }
`;


// Export

const Single = {
  Inspection,
};

const Write = {
  Create,
  Submit,
  Update,
};

export default {
  Single,
  Write,
};
