import gql from 'graphql-tag';
import Media from './media.js'
import PolicyTest from './policyTest.js'
import PaginatorFragment from './paginatorFragment.js'

// Browse

const DisplayFragment = gql`
  fragment activityExportDisplayFragment on ActivityExport {
    id
    from
    to
    status
    message
    includeRetired
    createdAt
    updatedAt
    createdBy {
      id
    }
    payload {
      ...mediaListBaseFragment
    }
    permissions(policies: [{policy: "delete"}]) {
      ...isAllowedFragment
    }
  }
  ${Media.Fragments.MediaListBaseFragment}
  ${PolicyTest.Fragments.IsAllowed}
`;

const List = gql`
  query(
    $createdBy: ID
    $orderBy: [QueryActivityExportsOrderByOrderByClause!]
    $page: Int
    $first: Int!
  ) {
    activityExports(
      createdBy: $createdBy
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        id
        ...activityExportDisplayFragment
      }
    }
  }
  ${PaginatorFragment}
  ${DisplayFragment}
`;


// Single

const Main = gql`
  query(
    $id: ID!
  ) {
    activityExport(
      id: $id
    ) {
      ...activityExportDisplayFragment
    }
  }
  ${DisplayFragment}
`;


// Write

const Create = gql`
  mutation(
    $from: Date!
    $to: Date!
    $includeRetired: Boolean
  ) {
    activityExportCreate(
      input: {
        from: $from
        to: $to
        includeRetired: $includeRetired
      }
    ) {
      ...activityExportDisplayFragment
    }
  }
  ${DisplayFragment}
`;

const Delete = gql`
  mutation(
    $id: ID!
  ) {
    activityExportDelete(
      id: $id
    ) {
      id
    }
  }
`;


// Export

const Browse = {
  List,
};

const Single = {
  Main,
};

const Write = {
  Create,
  Delete,
};

export default {
  Browse,
  Single,
  Write,
};
