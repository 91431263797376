<template>
  <VExpansionPanel>
    <TimeslipItemHeader
      :id="id"
      :simple="simple"
    />
    <TimeslipItemContent
      :id="id"
      @delete="onDelete"
      @update="onUpdate"
    />
  </VExpansionPanel>
</template>

<script>
  import TimeslipItemContent from './TimeslipItemContent.vue'
  import TimeslipItemHeader from './TimeslipItemHeader.vue'

  export default {
    components: {
      TimeslipItemContent,
      TimeslipItemHeader,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      simple: Boolean,
    },

    methods: {
      onDelete(response) {
        this.$emit('delete', response);
      },

      onUpdate(response) {
        this.$emit('update', response);
      },
    }
  }
</script>
