<template>
  <div>
    <VToolbar v-if="!hideToolbar" dense color="grey lighten-3">
      <VToolbarTitle v-if="titleParsed">
        {{titleParsed}}
      </VToolbarTitle>
      <VSpacer/>
      <VBtn :disabled="$apollo.queries.handler.loading" @click="$emit('refetch')" text>
        <FaI size="2x" icon="rotate" :class="$apollo.queries.handler.loading ? 'fa-spin' : ''"/>
      </VBtn>
      <VBtn v-if="hasFilter" @click="toggleFilter" text>
        <FaI size="2x" key="c" icon="chevron-down" :rotation="showFilter ? 180 : 0" style="transition: all 0.3s ease;"/>
        <div>
          <VFadeTransition leave-absolute>
            <FaI size="2x" key="a" v-if="isFiltered" icon="filter"/>
            <FaI size="2x" key="b" v-else :icon="['far', 'filter']"/>
          </VFadeTransition>
        </div>
      </VBtn>
      <div class="ml-4">
        <slot name="actions"></slot>
        <VBtn text v-if="createRoute" :to="createRoute">
          <FaI size="2x" icon="circle-plus"/>
        </VBtn>
      </div>
    </VToolbar>
    <VCard tile>
      <VCardText class="py-0">
        <slot
          name="filter"
          :set-variables="updateVariables"
          :set-is-filtered="updateIsFiltered"
          :show-filter="showFilter"
        />
      </VCardText>
      <slot
        :data="data"
        :variables="variables"
        :is-loading="isLoading"
        :on-create="onCreate"
        :on-update="onUpdate"
        :on-delete="onDelete"
      />
      <VProgressLinear :indeterminate="isLoading"/>
    </VCard>
    <VExpansionPanels v-model="panel">
      <VScrollYTransition group style="width: 100%">
        <template v-for="(item, index) in data">
          <slot name="item" :item="item" :index="index" :on-delete="onDelete">
            <VExpansionPanel :key="item.id">
              <VListItemTitle>
                {{item.name || item.title || item.id}}
              </VListItemTitle>
            </VExpansionPanel>
          </slot>
        </template>
      </VScrollYTransition>
    </VExpansionPanels>
    <InfiniteScroll
      :data="handler"
      :loading="isLoading && page > 0"
      @triggered="fetchMore"
    />
  </div>
</template>

<script>
  import Queries from 'queries/index.js'
  import InfiniteScroll from "elements/InfiniteScroll.vue";
  import {get} from 'lodash-es'

  const DEFAULT_PAGE_SIZE = 30;

  export default {
    components: {
      InfiniteScroll,
    },

    data: () => ({
      showFilter: false,
      isFiltered: false,
      variables: undefined,
      panel: undefined,
    }),

    props: {
      createRoute: [String, Object],

      title: String,

      query: {
        type: [Object, String],
        required: true,
      },

      hideToolbar: Boolean,

      filter: {
        type: Function,
        default: (item) => true, // 100% passthrough
      },

      position: {
        // Provide the array index before which the new entry should be inserted for using in a findIndex method, ie. the identified item will be positioned immediately after the new item. 0 = start of list, -1 = end of list
        type: Function,
        default: (item) => -1,
      },

      defaultVariables: Object,

      bus: Object,
    },

    apollo: {
      handler: {
        query() {
          return this.getQuery;
        },
        variables() {
          // console.debug('variables', {variables: this.localVariables});
          return this.localVariables;
        },
        update(response) {
          // console.debug('update', {response});
          return response[this.queryName];
        },
        skip() {
          const {getQuery, variables, defaultVariables} = this;
          return (variables === undefined && defaultVariables === undefined) || getQuery === undefined;
        },

        fetchPolicy: 'cache-and-network',
      },
    },

    computed: {
      localVariables() {
        const {variables, defaultVariables} = this;
        return {
          ...variables,
          ...defaultVariables !== undefined && {...defaultVariables},
          first: DEFAULT_PAGE_SIZE,
        };
      },

      getQuery() {
        const {query} = this;
        let payload = undefined;

        if (typeof query === "object" && query !== null) {
          payload = query;
        } else if (typeof query === 'string') {
          payload = get(Queries, query);
        }

        if (payload === undefined) {
          console.warn('ListBrowser query is invalid');
        }

        return payload;
      },

      queryName() {
        return this.getQuery?.definitions?.[0]?.selectionSet?.selections?.[0]?.name?.value;
      },

      hasFilter() {
        return Boolean(this.$scopedSlots['filter']);
      },

      data() {
        const {handler} = this;
        return handler ? handler.data : [];
      },

      page() {
        const {handler} = this;
        return handler ? handler.paginatorInfo.lastPage : 0;
      },

      hasMore() {
        const {handler} = this;
        return handler ? handler.paginatorInfo.hasMorePages : 0;
      },

      isLoading() {
        return this.$apollo.queries.handler.loading;
      },

      titleParsed() {
        return this.parseDisplayMessage(this.title);
      },
    },

    methods: {
      toggleFilter() {
        this.showFilter = !this.showFilter;
      },

      updateVariables(v) {
        this.variables = v;
      },

      updateIsFiltered(v) {
        this.isFiltered = Boolean(v);
      },

      async fetchMore() {
        const {page, localVariables} = this;
        const variables = {...localVariables, page: page + 1};

        // console.debug('fetchMore', {page, localVariables, variables});

        try {
          const response = await this.$apollo.queries.handler.fetchMore({
            variables,
            // updateQuery(previous, stuff) {
            //   console.debug('updateQuery', {previous, stuff});
            // },
          });
          // await this.$apollo.queries.handler.refresh();
        } catch (e) {
          console.warn('fetchMore action error:', e);
        }
      },

      onCreate(response) {
        this.modifyCache(response, 'c');
      },

      onUpdate(response) {
        this.modifyCache(response, 'u');
      },

      onDelete(response) {
        this.modifyCache(response, 'd');
      },

      modifyCache(response, action = 'c') {
        const client = this.$apollo.provider.defaultClient;
        const {getQuery: query, localVariables: variables, filter, position, queryName: name, hasMore} = this;
        const data = client.readQuery({query, variables});
        const list = [...data[name].data];

        let changeCount = 0;

        // Remove existing item from data set
        if (action === 'u' || action === 'd') {
          const match = list.findIndex((e) => e.id === response.id);

          if (match !== -1) {
            list.splice(match, 1);
            changeCount--;
          }
        }

        // Add item to new data set (could be same position as before if update)
        if ((action === 'c' || action === 'u') && filter(response)) {
          const match = list.findIndex(position);

          if (match === -1) {
            list.push(response);
            changeCount++;
          } else if (match >= 0) {
            list.splice(match, 0, response);
            changeCount++;
          }
        }

        if (action === 'c') {
          this.focus = list.length - 1;
        }

        if (changeCount < 0 && hasMore) {
          // todo
        }

        const newData = {
          ...data,
          [name]: {
            ...data[name],
            data: list,
          }
        }

        client.writeQuery({query, variables, data: newData});
      },

      doRefresh() {
        this.$apollo.queries.handler.refresh();
      },

      collapseAll() {
        this.panel = undefined;
      }
    },

    created() {
      this.$on('create', this.onCreate);
      this.$on('update', this.onUpdate);
      this.$on('delete', this.onDelete);
      this.$on('refetch', this.doRefresh);
      this.$on('collapse', this.collapseAll);

      const {bus} = this;
      if (bus) {
        bus.on('*', (type, e) => this.$emit(type, e));
      }
    },
  }
</script>
