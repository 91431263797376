<template>
  <VTextField
    :autocomplete="autocomplete"
    :autofocus="autofocus"
    :clearable="clearable"
    :counter="counter"
    :disabled="disabled"
    :error-messages="errorMessages"
    :hint="hintParsed"
    :label="labelParsed"
    :loading="loading"
    :outlined="outlined"
    :persistent-hint="localPersistentHint"
    :placeholder="placeholderParsed"
    :readonly="readonly"
    :suffix="suffix"
    :type="type"
    :value="value"
    @blur="doTouch"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </VTextField>
</template>

<script>
  import KFormTextElementMixin from './KFormTextElementMixin.js'

  export default {
    mixins: [
      KFormTextElementMixin,
    ],

    props: {
      autocomplete: String,

      type: {
        type: String,
        default: 'text',
      },

      readonly: Boolean,
    },
  }
</script>
