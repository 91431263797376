import gql from 'graphql-tag';
import DataExport from "./dataExport.js";

const Export = gql`
  mutation(
    $uuid: UUID!
    $date: DateRange
  ) {
    activityRatiosExport(
      uuid: $uuid
      date: $date
    ) {
      ...dataExportDownloadFragment
    }
  }
  ${DataExport.Fragments.DownloadFragment}
`;


export default {
  Export,
}
