import gql from 'graphql-tag';

// Fragments

const Fragment = gql`
  fragment timeslipCollectionFragment on TimeslipCollection
  {
    id
    description
    isFavorite
    duration(dates: $durationDates)
    timeslipType {
        id
    }
    partitions {
      id
      isBillable
      billingRate
      comment
      weight
      project {
          id
          slug
      }
    }
  }
`;


// Browse

const List = gql`
  query(
    $durationDates: DateRange
    $isFavorite: Boolean
    $limit: Int
  ) {
    myTimeslipSuggestions(
      isFavorite: $isFavorite
      limit: $limit
    ) {
      ...timeslipCollectionFragment
    }
  }
  ${Fragment}
`;


// Single

const Main = gql`
  query(
    $id: ID!
    $durationDates: DateRange
  ) {
    timeslipCollection(
      id: $id
    ) {
      ...timeslipCollectionFragment
    }
  }
  ${Fragment}
`;

const Dialog = gql`
  query(
    $id: ID!
  ) {
    timeslipCollection(
      id: $id
    ) {
      id
      partitions {
        id
        weight
        project {
          id
          slug
        }
      }
    }
  }
  ${Fragment}
`;


// Write

const Update = gql`
  mutation(
    $id: ID!
    $isFavorite: Boolean
  ) {
    timeslipCollectionUpdate(
      id: $id
      input: {
        isFavorite: $isFavorite
      }
    ) {
      id
      isFavorite
    }
  }
`;


// Subscriptions

// const NewTimeslipCollection = gql`
//   subscription onNewTimeslipCollection {
//     newTimeslipCollection {
//       ...timeslipCollectionFragment
//     }
//   }
//   ${Fragment}
// `;


// Export

const Fragments = {
  Main: Fragment,
};

const Browse = {
  List,
};

const Single = {
  Main,
  Dialog,
};

const Write = {
  Update,
};

// const Subscriptions = {
//   NewTimeslipCollection,
// };

export default {
  Browse,
  Fragments,
  Single,
  Write,
  // Subscriptions,
};
