<template>
  <NowLoading v-if="$apollo.loading"/>
  <VList v-else>
    <template v-for="(item, index) in overviewItems">
      <VDivider
          v-if="index !== 0"
          :key="`div-${item.name}`"
      />
      <VListItem :key="item.name">
        <VListItemContent>
          <VListItemTitle>
            <small>
              <span v-t="{path: item.name, choice: 1}"></span>:
            </small>
            <span v-html="item.value"/>
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
    </template>
  </VList>
</template>

<script>
  import Queries from 'queries/index.js'

  export default {
    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    apollo: {
      user: {
        query: Queries.User.Single.Overview,
        variables() {
          return {
            id: this.id,
          };
        },
      },
    },

    computed: {
      overviewItems() {
        const {user} = this;

        if (!user) {
          return [];
        }

        return [
          {
            name: 'models.location.address',
            value: user.location ? user.location.addressInline : '<em>' + this.$t('ui.none') + '</em>',
          },
          {
            name: 'models.user.title',
            value: user.title,
          },
        ];
      },
    },
  }
</script>
