<template>
  <VSkeletonLoader
    type="list-item"
    v-if="$apollo.loading"
  />
  <VListItem v-else color="blue" class="my-0">
    <VListItemAction>
      <strong>
        {{time}}
      </strong>
    </VListItemAction>
    <VListItemContent>
      <div class="d-flex flex-nowrap align-center">
        <ProjectListItem :id="timeslipPartition.project.id" dense/>
        <div class="ml-2 d-flex flex-nowrap">
          <FaI
            class="orange--text text--darken-2 mr-1"
            icon="file-invoice-dollar"
            v-if="timeslipPartition.isBillable"
          />
          <span v-if="timeslipPartition.isBillable" class="text-no-wrap">
            <strong>{{amountFormatted}}</strong> @ {{billingRateFormatted}}<span v-t="'units.perHour'"/>
          </span>
        </div>
      </div>
      <div v-if="timeslipPartition.comment" class="text--primary mt-2">
        {{timeslipPartition.comment}}
      </div>
    </VListItemContent>
  </VListItem>
</template>

<script>
  import Queries from 'queries/index.js';
  import currencyMixin from "top/mixins/currencyMixin.js";
  import ProjectListItem from "../../projects/browse/elements/ProjectListItem.vue";
  import getTimestampFromSeconds from "../../../getTimestampFromSeconds.js";

  export default {
    mixins: [currencyMixin],

    components: {
      ProjectListItem,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    apollo: {
      timeslipPartition: {
        query: Queries.TimeslipPartition.Single.Main,
        variables() {
          return {
            id: this.id,
          };
        },
      },

      project: {
        query: Queries.Project.Forms.Populate,
        variables() {
          return {
            id: this.timeslipPartition.project.id,
          }
        },
        skip() {
          return !this.timeslipPartition;
        },
      },
    },

    computed: {
      amountFormatted() {
        const {timeslipPartition, getCurrency} = this;
        return getCurrency(timeslipPartition?.amount);
      },

      billingRateFormatted() {
        const {timeslipPartition, getCurrency} = this;
        return getCurrency(timeslipPartition?.billingRate);
      },

      time() {
        return getTimestampFromSeconds(this.timeslipPartition?.duration);
      },
    }
  }
</script>
