import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './messages.js';

const numberFormats = {
    'en-CA': {
        currency: {
            style: 'currency', currency: 'CAD'
        }
    },
    'fr-CA': {
        currency: {
            style: 'currency', currency: 'CAD'
        }
    }
}

Vue.use(VueI18n);

export default new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
    numberFormats,
});
