<template>
  <VCard>
    <VCardTitle v-t="'ui.signIn'"/>
    <VCardText>
      <FormSignIn @success="handleSuccess" @error="handleError"/>
    </VCardText>
  </VCard>
</template>

<script>
  import FormSignIn from './FormSignIn.vue'

  export default {
    components: {
      FormSignIn,
    },

    methods: {
      handleSuccess(response) {
        // Flash message handled by "signin" method
        const redirect = this.$route.query.redirect;
        if (redirect) {
          this.$router.push(redirect);
        } else {
          this.$router.push({name: 'top'});
        }
      },

      handleError(response) {
        this.$store.dispatch('flash/error', 'auth.failed');
      },
    },
  }
</script>
