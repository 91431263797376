<template>
  <VRow dense>
    <VCol v-show="!hasDevices" cols="12">
      <em v-t="'inspection.performance.awaitingDevice'"/>
    </VCol>
    <VCol v-show="hasDevices" cols="12">
      <PerformanceMachineRoom v-if="inspectionArea === '4'" :store="store" :devices="devices" :settings="settings" @change="onChange"/>
      <PerformanceLobby v-else-if="inspectionArea === '3'" :store="store" :devices="devices":settings="settings" @change="onChange"/>
      <PerformancePattern v-if="inspectionArea === '2'" :devices="devices" @start="patternStart" @advance="patternAdvance"/>
      <PerformanceCab v-if="inspectionArea === '2'" v-show="pattern.mode === null" :store="store" :settings="settings" :devices="devices" @change="onChange"/>
    </VCol>
  </VRow>
</template>

<script>
  import {STATUSES as PATTERN_STATUSES, PROGRAMS} from './timerPatterns.js'
  import PerformanceButtons from './PerformanceButtons.vue'
  import PerformanceCab from './PerformanceCab.vue'
  import PerformanceMachineRoom from './PerformanceMachineRoom.vue'
  import PerformanceLobby from './PerformanceLobby.vue';
  import PerformancePattern from './PerformancePattern.vue'
  import FormMixin from 'forms/elements/FormMixin.js'

  export default {
    mixins: [
      FormMixin,
    ],

    components: {
      PerformanceButtons,
      PerformanceCab,
      PerformanceLobby,
      PerformanceMachineRoom,
      PerformancePattern,
    },

    data: () => ({
      pattern: {
        mode: null,
        status: PATTERN_STATUSES.IDLE,
        step: -1,
        timestamps: {
          0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0,
        },
      },
    }),

    props: {
      inspectionArea: [Number, String],

      store: Array,

      devices: Array,

      bus: {
        type: Object,
        required: true,
      },

      settings: {
        type: Object,
        default: () => ({}),
      },
    },

    provide() {
      return {
        performancePattern: this.pattern,
        performanceMeta: {
          mode: this.mode,
        }
      };
    },

    timers: {
      perfCounter: { time: 100, repeat: true }
    },

    computed: {
      hasDevices() {
        return this.devices.length !== 0;
      },
    },

    methods: {
      patternStart(mode) {
        const {pattern} = this;
        pattern.mode = mode;
        pattern.step = 0;
      },

      patternAdvance(next) {
        const pattern = this.pattern;
        const step   = pattern.step;

        if (step === 0) {
          this.$timer.start('perfCounter');
        }

        pattern.timestamps[step] = (new Date).getTime();

        pattern.step++;
        pattern.timestamps[pattern.step] = pattern.timestamps[step];
        if (!next) {
          this.patternStop();
        }
      },

      patternStop() {
        const {pattern} = this;
        const {mode, step, timestamps} = pattern;

        if (step > 1) {
          const program = Object.entries(PROGRAMS[mode]);
          const deviceId = this.devices[0];

          for (let [key, value] of program) {
            const {start, stop} = value;
            if (stop < step) {
              this.bus.emit('change', {
                name: deviceId,
                key,
                value: ((timestamps[stop - 1] - timestamps[start - 1])/1000).toFixed(1),
              });
            }
          }
        }

        this.$timer.stop('perfCounter');
        pattern.mode = null;
        pattern.step = -1;
      },

      perfCounter() {
        const pattern = this.pattern;
        pattern.timestamps[pattern.step] = (new Date).getTime();
      },

      onChange(event) {
        this.bus.emit('change', event);
      },
    },
  }
</script>
