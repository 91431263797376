import gql from 'graphql-tag'
import Company from './company.js'
import PaginatorFragment from './paginatorFragment.js'
import DataExport from './dataExport.js'

// Fragments

const InvoiceFragment = gql`
  fragment invoiceFragment on Invoice
  {
    id
    reference
    transactionDate
    dueDate
    subtotal
    salesTaxPercentage
    salesTaxTotal
    balance
    isPaid
    isOverdue
    paidDate
    template
    message
    createdAt
    updatedAt
    project {
      id
      name
    }
    client {
      id
    }
    user {
      id
    }
    invoiceLines {
      id
      description
      amount
    }
  }
`;

const InvoiceListFragment = gql`
fragment invoiceListFragment on Invoice
{
  id
  reference
  transactionDate
  subtotal
  salesTaxTotal
  isPaid
  isOverdue
  project {
    id
    name
  }
  client {
    ...companyThumbnailFragment
  }
}
${Company.Fragments.ThumbnailFragment}
`;


// Browse

const List = gql`
  query(
    $transactionDate: DateRange
    $dueDate: DateRange
    $project: ID
    $user: ID
    $projectOffice: ID
    $userOffice: ID
    $isPaid: Boolean
    $isOverdue: Boolean
    $orderBy: [QueryInvoicesOrderByOrderByClause!]
    $page: Int
    $first: Int!
  ) {
    invoices(
      transactionDate: $transactionDate
      dueDate: $dueDate
      project: $project
      user: $user
      projectOffice: $projectOffice
      userOffice: $userOffice
      isPaid: $isPaid
      isOverdue: $isOverdue
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        ...invoiceListFragment
      }
    }
  }
  ${PaginatorFragment}
  ${InvoiceListFragment}
`;


// Single

const Main = gql`
  query(
    $id: ID!
  ) {
    invoice(
      id: $id
    ) {
      ...invoiceFragment
    }
  }
  ${InvoiceFragment}
`;

const ListItemHeader = gql`
  query(
    $id: ID!
  ) {
    invoice(
      id: $id
    ) {
      ...invoiceListFragment
    }
  }
  ${InvoiceListFragment}
`;

const ListItemContent = gql`
  query(
    $id: ID!
  ) {
    invoice(
      id: $id
    ) {
      id
      salesTaxCode
      salesTaxPercentage
      salesTaxTotal
      message
      isPaid
      isOverdue
      dueDate
      paidDate
      createdAt
      updatedAt
      invoiceLines {
        id
        description
        amount
      }
      project {
        id
      }
      client {
        id
        contact {
          id
          email
        }
      }
      user {
        id
      }
    }
  }
`;


const Browse = {
  List,
};

const Single = {
  Main,
  ListItemHeader,
  ListItemContent,
};

export default {
  Browse,
  Single,
};
