import { req, withParams } from 'vuelidate/lib/validators/common';
import {client} from 'api/index.js';
import Queries from 'queries/index.js';

export default (userId, contextType) =>
  withParams({type: 'projectTimeslipPolicyTest', userId, contextType}, (value) => {
    if (!req(value)) {
      return true;
    }

    const partitionsName = `${contextType}Partitions`;

    return new Promise((resolve, reject) => {
      client.query({
        query: Queries.PolicyTest.Single.Main,
        variables: {
          policy: 'create',
          contextType,
          contextArguments: {
            [partitionsName]: {
              create: [
                {
                  project: {connect: value},
                },
              ],
            },
            user: {connect: userId},
          },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
      }).then((response) => {
        resolve(Boolean(response?.data?.policyTest?.allowed));
      });
    });
    }
  )
