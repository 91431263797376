<template>
  <VContainer fluid>
    <VRow>
      <VCol cols="12">
        <VCard class="mb-4" color="blue lighten-5">
          <VCardTitle v-t="'views.admin.analytics.title'"/>
          <VCardText>
            <VRow>
              <VCol>
                <KDatePicker
                  :min="min"
                  :max="max"
                  range
                  month
                  v-model="dateRange"
                />
              </VCol>
              <VCol cols="auto">
                <ExportButton small :mutation="exportMutation" :variables="exportVariables" :disabled="$v.$invalid" class="mt-2"/>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import {KDatePicker} from "forms/elements/index.js";
import ExportButton from "elements/ExportButton.vue";
import Queries from "queries/index.js";
import {betweenLength, required} from "validators/index.js";
import {format, parse, startOfMonth, endOfMonth} from "date-fns";
import {formatISODate} from "../../../date-fns-format.js";

export default {
  components: {
    KDatePicker,
    ExportButton,
  },

  data: () => ({
    dateRange: [],
  }),

  validations: {
    dateRange: {
      required,
      betweenLength: betweenLength(1, 2),
    },
  },

  computed: {
    min() {
      return '2018-01';
    },

    max() {
      return format(new Date(), 'yyyy-MM');
    },

    exportMutation() {
      return Queries.ActivityRatio.Export;
    },

    exportVariables() {
      const {dateRange} = this;

      if (this.$v.$invalid) {
        return null;
      }

      return {
        date: {
          from: formatISODate(startOfMonth(parse(dateRange[0], 'yyyy-MM', new Date()))),
          to: formatISODate(endOfMonth(parse(dateRange[1] ?? dateRange[0], 'yyyy-MM', new Date()))),
        },
      };
    },
  },
}
</script>
