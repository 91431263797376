<template>
  <VExpandTransition>
    <span v-if="!formName" key="null">
      Awaiting selection...
    </span>
    <component v-else :is="formName" :key="formName" :project-type="projectType" :financials-optional="financialsOptional" v-model="form" @validator="$emit('validator', $event)"/>
  </VExpandTransition>
</template>

<script>
import FormProjectRecordActive from "./statuses/FormProjectRecordActive.vue";
import FormProjectRecordComplete from "./statuses/FormProjectRecordComplete.vue";
import FormProjectRecordHold from "./statuses/FormProjectRecordHold.vue";
import FormProjectRecordInternal from "./statuses/FormProjectRecordInternal.vue";
import FormProjectRecordLost from "./statuses/FormProjectRecordLost.vue";
import FormProjectRecordSales from "./statuses/FormProjectRecordSales.vue";

const getComponentCode = (projectStatus) => {
  switch(projectStatus) {
    case '1': // Active
      return 'Active';
    case '2': // Complete
      return 'Complete';
    case '3': // Lost
      return 'Lost';
    case '4':
      return 'Sales';
    case '5':
      return 'Internal';
    case '7':
      return 'Hold';
    default:
      return null;
  }
}

export default {
  components: {
    FormProjectRecordActive,
    FormProjectRecordComplete,
    FormProjectRecordHold,
    FormProjectRecordInternal,
    FormProjectRecordLost,
    FormProjectRecordSales,
  },

  data: () => ({
    form: {},
  }),

  props: {
    projectType: String,

    projectStatus: String,

    financialsOptional: Boolean,
  },

  watch: {
    formOutput: {
      handler(v) {
        this.$emit('input', v);
      },
      immediate: true,
    },
  },

  computed: {
    formName() {
      if (!this.projectType || !this.projectStatus) {
        return null;
      }

      return `FormProjectRecord${getComponentCode(this.projectStatus)}`;
    },

    exportCode() {
      return this.projectStatus ? getComponentCode(this.projectStatus).toLowerCase() : null;
    },

    formOutput() {
      return this.exportCode ? {[this.exportCode]: this.form} : null;
    },
  },

  methods: {
    clearTypeRecord() {
      this.form.typeRecord = null;
    },

    setTypeRecord(key, value) {
      this.form.typeRecord = {[key]: value};
    },
  },
}
</script>
