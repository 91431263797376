<template>
  <VCard>
    <VCardTitle v-t="'views.performance.setup.devices.title'"/>
    <VCardText>
      <p>
        <span v-t="'views.performance.setup.devices.current'"/>:
        <em v-if="!hasDevices" v-t="'ui.none'"/>
        <span v-else>
          {{currentDevices}}
        </span>
      </p>
      <p>
        <span v-t="'views.performance.setup.devices.new'"/>
      </p>
      <VRow dense>
        <VCol cols>
          <KListField
            v-model="localDevices"
            hint="views.performance.setup.devices.help"
            label="views.performance.setup.devices.title"
            persistent-hint
          />
        </VCol>
        <VCol cols="auto">
          <VBtn @click="doUpdateDevices" :disabled="localDevices.length === 0">
            <span v-t="'actions.update'"/>
          </VBtn>
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>

<script>
import {KListField} from '@/forms/elements';

export default {
  components: {
    KListField,
  },

  data: () => ({
    localDevices: [],
  }),

  props: {
    devices: {
      type: Array,
      required: true,
    },
  },

  computed: {
    hasDevices() {
      return this.currentDevices?.length > 0;
    },

    currentDevices() {
      return this.devices.join(', ');
    },
  },

  methods: {
    doUpdateDevices() {
      this.$emit('update:devices', this.localDevices);
    },
  },
}
</script>
