import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters('session', ['locale']),
  },

  methods: {
    getPercentage(value, maximumFractionDigits = 1) {
      if (!value && value !== 0) {
        return '';
      }

      const formatter = new Intl.NumberFormat(
        this.locale,
        {
          style: 'percent',
          maximumFractionDigits,
        });

      return formatter.format(value);
    },
  },
}
