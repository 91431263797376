<template>
  <div>
    <VExpandTransition>
      <ListBrowserSearchbar v-if="showSearch" v-model="search"/>
    </VExpandTransition>
    <VExpandTransition>
      <VContainer v-show="showFilter">
        <slot></slot>
        <VRow justify="space-between" align="center">
          <VCol class="py-0" v-if="hasOrderOptions" cols="auto">
            <ListBrowserSorter :options="orderOptions" :clearable="isSearching" :column.sync="orderColumn" :order-desc.sync="orderDesc"/>
          </VCol>
          <VCol class="py-0" cols="auto" v-if="allowExport">
            <ExportButton small :mutation="exportMutation" :variables="variables" :options="exportOptions">
              <template v-slot:menu-items>
                <slot name="export-menu-items"/>
              </template>
            </ExportButton>
          </VCol>
          <VCol class="py-0" cols="auto">
            <VBtn @click="doReset" :disabled="!isFiltered">
              <FaI icon="rotate-left"/>&nbsp;
              <span v-t="'actions.reset'"/>
            </VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VExpandTransition>
  </div>
</template>

<script>
import ExportButton from "../ExportButton.vue";
import ListBrowserSearchbar from "./ListBrowserSearchbar.vue";
import ListBrowserSorter from "./ListBrowserSorter.vue";
import {isEmpty, isEqual} from 'lodash-es';

const findOrderBy = (options, key = 'default') => options.find((e) => Boolean(e[key])) || this.options[0];

export default {
  components: {
    ExportButton,
    ListBrowserSearchbar,
    ListBrowserSorter,
  },

  data: () => ({
    isMounted: false,
    orderColumn: undefined,
    orderDesc: false,
    search: '',
  }),

  created: function () {
    if (!isEmpty(this.$router.currentRoute.query)) {
      this.sortFromQuery();
      this.searchFromQuery();
    }
    this.$emit('update:form', {...this.formDefault, ...this.query});
  },

  props: {
    orderOptions: {
      type: Array,
      validator: (v) => {
        const props = ['text', 'value'];
        return !v.some((e) => // false if any object test fails
            typeof e !== 'object' || e === null || props.some((prop) =>  // true if object is missing any prop
              !e.hasOwnProperty(prop) // true if prop is missing
            )
        );
      },
    },

    form: {
      type: Object,
      required: true,
    },

    formFunction: {
      type: Function,
      default: (v) => {
        const payload = {};
        Object.entries(v).forEach(([key, value]) => {
          if (!isEmpty(value)) {
            payload[key] = value;
          }
        });
        return payload;
      },
    },

    formDefault: {
      type: Object,
      default: () => ({}),
    },

    showSearch: Boolean,

    showFilter: Boolean,

    exportMutation: String,

    exportOptions: {
      type: Array,
      validator: (v) => v.every((i) => {
        if (typeof i !== 'object' || i === null) return false;

        return 'name' in i &&
          typeof i.name === 'string' &&
          'text' in i &&
          typeof i.text === 'string' &&
          (!('default' in i) || typeof i.default === 'boolean');
      }),
    },

    enablePersistFilters: {
      type: Boolean,
      default: () => true
    },

    name: String,
  },

  apollo: {
    allowExport() {
      return this.policyChecker({
        policy: 'showExport',
        type: this.exportType,
        ...!isEmpty(this.formDefault) && {args: this.formDefault},
        skip: () => this.exportType === null,
      });
    },
  },

  computed: {
    hasOrderOptions() {
      return Boolean(this.orderOptions);
    },

    isFiltered() {
      const {orderByIsDefault, formIsDefault} = this;
      return !orderByIsDefault || !formIsDefault;
    },

    defaultOrderBy() {
      const {orderOptions, isSearching} = this;
      return isSearching ? undefined : findOrderBy(orderOptions).value;
    },

    defaultOrderDesc() {
      return Boolean(findOrderBy(this.orderOptions).defaultDesc);
    },

    secondarySort() {
      const match = findOrderBy(this.orderOptions, 'secondary');
      return match.value;
    },

    orderByIsDefault() {
      const {orderColumn, orderDesc, defaultOrderDesc, defaultOrderBy} = this;
      return orderColumn === defaultOrderBy && (defaultOrderBy === undefined || orderDesc === defaultOrderDesc);
    },

    formIsDefault() {
      const {form, formDefault} = this;
      return !Object.entries(form).some(([key, value]) => value !== formDefault?.[key]);
    },

    orderByVariables() {
      const {orderColumn: column, orderDesc, secondarySort} = this;

      if (!column) {
        return null;
      }

      const order = orderDesc ? 'DESC' : 'ASC';
      const response = [{column, order}];

      if (column !== secondarySort) {
        response.push({column: secondarySort, order});
      }

      return response;
    },

    variables() {
      const {formFunction, orderByVariables: orderBy, search, form} = this;

      return {
        ...formFunction(form),
        ...orderBy && {orderBy},
        ...search && {search},
      };
    },

    isSearching() {
      const {search} = this;
      return Boolean(search && search.length !== 0);
    },

    exportType() {
      return this.exportMutation ? this.exportMutation.split('.')[0] : null;
    },

    filterPrefix() {
      return "fltr-" + (this.name ? this.name + "-" : "")
    },

    query: {
      get() {
        let result = {};
        if (this.enablePersistFilters) {
          for (const [key, value] of Object.entries(this.$router.currentRoute.query)) {
            if (key.startsWith(this.filterPrefix)) {
              result[key.substring(5)] = value;
            }
          }

          if (result.sortDesc) {
            this.orderDesc = result.sortDesc;
            delete result.sortDesc;
          }

          if (result.sortByColumn) {
            this.orderColumn = result.sortByColumn;
            delete result.sortByColumn;
          }

          if (result.search) {
            this.search = result.search;
            delete result.search;
          }
        }

        return result;
      },

      set(v) {
        if (this.enablePersistFilters) {
          let newQuery = {};


          for (const [key, value] of Object.entries(this.$router.currentRoute.query)) {
            if (!key.startsWith(this.filterPrefix))
              newQuery[key] = value;
          }

          for (const [key, value] of Object.entries(v)) {
            if (!isEmpty(value) && this.formDefault[key] !== value) {
              newQuery[`${this.filterPrefix}${key}`] = value;
            }
          }

          // FIXME: Side-effects galore. The logic of this component needs to be refactored.
          if (!this.orderByIsDefault) {
            if (this.orderDesc !== this.defaultOrderDesc)
              newQuery[`${this.filterPrefix}sortDesc`] = this.orderDesc;
            if (this.orderColumn !== this.defaultOrderBy)
              newQuery[`${this.filterPrefix}sortByColumn`] = this.orderColumn;
          }

          if (this.search) {
            newQuery[`${this.filterPrefix}search`] = this.search;
          }

          if (!isEqual(newQuery, this.$router.currentRoute.query))
            this.$router.replace({
              ...this.$router.currentRoute,
              query: newQuery,
            });
        }
      }
    },
  },

  watch: {
    isFiltered: {
      handler(v) {
        this.$emit('filtered', v);
      },
      immediate: true,
    },

    defaultOrderBy: {
      handler(v, oldv) {
        if (this.orderColumn === oldv) {
          this.orderColumn = v;
        }
      },
      immediate: true,
    },

    defaultOrderDesc: {
      handler(v) {
        this.orderDesc = v;
      },
      immediate: true,
    },

    formDefault(v, oldv) {
      const equal = isEqual(v, oldv);
      if (!equal) {
        this.applyDefaultValues(oldv);
      }
    },

    variables(v) {
      this.$emit('input', v);
      this.query = this.form;
    },
  },

  methods: {
    sortFromQuery() {
      const sortDesc = this.$router.currentRoute.query[`${this.filterPrefix}sortDesc`];
      const sortByColumn = this.$router.currentRoute.query[`${this.filterPrefix}sortByColumn`];
      if (sortDesc) {
        this.orderDesc = sortDesc;
      }
      if (sortByColumn) {
        this.orderColumn = sortByColumn;
      }
    },

    searchFromQuery() {
      const search = this.$router.currentRoute.query[`${this.filterPrefix}search`];
      if (search) {
        this.search = search;
      }
    },

    doReset() {
      this.orderByReset();

      this.applyDefaultValues();
    },

    orderByReset() {
      const {defaultOrderDesc, defaultOrderBy} = this;
      this.orderColumn = defaultOrderBy;
      this.orderDesc = defaultOrderDesc;
    },

    applyDefaultValues(old = undefined) {
      const {form, formDefault} = this;
      const newForm = {...form};

      for (let [key, value] of Object.entries(formDefault)) {
        newForm[key] = value;
      }

      if (old) {
        for (let key of Object.keys(old)) {
          if (!formDefault.hasOwnProperty(key)) {
            newForm[key] = undefined;
          }
        }
        Object.assign(newForm, this.query)
      }

      this.query = newForm;
      this.$emit('update:form', newForm);
    },
  },
}
</script>
