import {differenceInDays, differenceInSeconds, isBefore, parseISO} from "date-fns";
import {format, formatDistance} from "top/date-fns-format.js";
import {mapState} from "vuex";
import getTimestampFromSeconds from "top/getTimestampFromSeconds.js";

const getParsed = (time) => time instanceof Date ? time : parseISO(time);

export default {
  computed: {
    ...mapState('session', ['now']),
  },

  methods: {
    getTickerForHumans(time) {
      if (!time) {
        return '';
      }

      const {now} = this,
        parsed = getParsed(time);

      if (differenceInDays(now, parsed) > 1) {
        return format(parsed);
      }

      return formatDistance(
        isBefore(now, parsed) ? now : parsed,
        now,
        {addSuffix: true,});
    },

    getTimeDuration(time, base = 0) {
      if (!time) {
        return '';
      }

      const parsed = getParsed(time),
        seconds = differenceInSeconds(this.now, parsed) + base;

      return getTimestampFromSeconds(seconds);
    }
  },
}
