import Vue from 'vue'
import Vuex from 'vuex'
import flash from './flash.js'
import session from './session.js'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api: null,
  },

  modules: {
    flash,
    session,
  },

  mutations: {
    setApi(state, {api}) {
      state.api = api;
    }
  },

  actions: {
    initialize({commit, dispatch}, {api}) {
      commit('setApi', {api});
      dispatch('session/startTicker');
      dispatch('session/getAuthFromLocalStorage');
    },
  },
});
