import { req, withParams } from 'vuelidate/lib/validators/common'
import {client} from 'api/index.js'

export default (query, field = 'name', except = null) =>
  withParams({type: 'unique', query, field, except}, async (value) => {
    if (!req(value)) {
      return true;
    }

    const response = await client.query({
      query,
      variables: {
        [field]: value,
      },
      fetchPolicy: 'network-only',
    });

    const data = response?.data;

    if (!data || typeof data !== 'object') {
      return false;
    }

    let target = Object.values(data)[0]?.data;

    if (!Array.isArray(target)) {
      return false;
    }

    if (except) {
      target = target.filter((v) => v.id !== except);
    }

    return target.length === 0;
    }
  )
