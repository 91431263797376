import gql from "graphql-tag";


// Write

const FormFill = gql`
  query(
    $id: ID!
  ) {
    issueEvent(
      id: $id
    ) {
      id
      issueEventType {
        id
      }
      devices {
        id
      }
    }
  }
`;

const Create = gql`
  mutation(
    $issueId: ID!
    $issueEventTypeId: ID!
    $deviceId: ID!
  ) {
    issueEventCreate(
      input: {
        issue: {
          connect: $issueId
        }
        issueEventType: {
          connect: $issueEventTypeId
        }
        device: {
          connect: $deviceId
        }
      }
    ) {
      id
      createdAt
      updatedAt
      issue {
        id
      }
      issueEventType {
        id
      }
      device {
        id
      }
      user {
        id
      }
    }
  }
`;


// Export

const Write = {
  FormFill,
  Create,
};

export default {
  Write,
};
