<template>
  <VNavigationDrawer
    :value="show"
    @input="updateShow"
    app
    class="accent"
    right
    temporary
    clipped
  >
    <NowLoading v-if="$apollo.loading"/>
    <VList v-else>
      <VListItem
        link
        :to="{name: 'user', params: {id: meId, slug: me.slug}}"
        v-if="canViewSelf"
      >
        <VListItemAction>
          <UserThumbnail :id="meId" size="xs" disabled no-tooltip/>
        </VListItemAction>
        <VListItemContent>
          <VListItemTitle>
            {{me.name}}
          </VListItemTitle>
        </VListItemContent>
      </VListItem>

      <VDivider/>

      <KMenuItem
        :to="{name: 'top'}"
        exact
        icon="house"
        title="ui.home"
      />

      <KMenuItem
        :to="{name: 'timeslips'}"
        icon="clock"
        :title="{path: 'models.timeslip.self', choice: 2}"
        v-if="canCreateTimeslips"
      />

      <KMenuItem
        :to="{name: 'expenses'}"
        icon="receipt"
        :title="{path: 'models.expense.self', choice: 2}"
        v-if="canCreateExpenses"
      />

      <KMenuItem
        :to="{name: 'projectRecords'}"
        icon="chart-mixed"
        :title="{path: 'models.projectRecord.shorthand', choice: 2}"
        v-if="canViewProjectRecordInterface"
      />

      <KMenuItem
        :to="{name: 'dispatcher'}"
        icon="signs-post"
        title="views.dispatcher.title"
        v-if="canViewProjectRecordInterface"
      />

      <KMenuItem
        :to="{name: 'invoicing'}"
        icon="file-invoice-dollar"
        title="views.invoicing.title"
        v-if="canDoInvoicing"
      />

      <KMenuItem
        :to="{name: 'performance'}"
        icon="stopwatch"
        title="views.performance.short"
        v-if="canDoPerformance"
      />

      <VDivider/>

      <KMenuItem
        :to="{name: 'users'}"
        icon="users"
        :title="{path: 'models.user.alt', choice: 2}"
        v-if="canViewUsers"
        exact
      />

      <KMenuItem
        :to="{name: 'offices'}"
        icon="chair-office"
        :title="{path: 'models.office.self', choice: 2}"
        v-if="canViewOffices"
      />

      <KMenuItem
        :to="{name: 'projects'}"
        icon="briefcase"
        :title="{path: 'models.project.self', choice: 2}"
        v-if="canViewProjects"
      />

      <KMenuItem
        :to="{name: 'clients'}"
        icon="building-columns"
        :title="{path: 'models.company.self', choice: 2}"
        v-if="canViewClients"
      />

      <!--      <KMenuItem-->
      <!--          icon="clipboard"-->
      <!--          :title="{path: 'models.inspection.self', choice: 2}"-->
      <!--          disabled-->
      <!--      />-->

      <!--      <KMenuItem-->
      <!--          icon="building"-->
      <!--          :title="{path: 'models.building.self', choice: 2}"-->
      <!--          disabled-->
      <!--      />-->

      <VDivider/>

      <KMenuItem
        icon="sliders-up"
        title="ui.settings"
        :to="{name: 'settings'}"
      />

      <KMenuItem
        icon="screwdriver-wrench"
        title="ui.admin"
        :to="{name: 'admin.export'}"
        v-if="canAdminToolsView"
      />

      <VListItem/>

      <KMenuItem
        @click="doSignOut"
        icon="right-from-bracket"
        title="ui.signOut"
        v-if="!isSigningOut"
      />

      <VListItem link disabled v-if="isSigningOut">
        <VListItemAction>
          <VProgressCircular
            size="16"
            width="2"
            indeterminate
          />
        </VListItemAction>
        <VListItemContent>
          <VListItemTitle>
            <span v-t="'ui.signingOut'"/>...
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
    </VList>
  </VNavigationDrawer>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import KMenuItem from './KMenuItem.vue'
import UserThumbnail from "./UserThumbnail.vue";

export default {
  components: {
    KMenuItem,
    UserThumbnail,
  },

  data: () => ({
    isSigningOut: false,
  }),

  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },

  apollo: {
    canViewUsers() {
      return this.policyChecker({
        policy: 'viewAny',
        type: 'User',
      });
    },

    canViewOffices() {
      return this.policyChecker({
        policy: 'viewAny',
        type: 'Office',
      });
    },

    canViewProjects() {
      return this.policyChecker({
        policy: 'viewAny',
        type: 'Project',
      });
    },

    canViewClients() {
      return this.policyChecker({
        policy: 'viewScoped',
        type: 'Company',
        args: {
          companyType: '3' // Clients
        }
      });
    },

    canCreateTimeslips() {
      return this.policyChecker({
        policy: 'create',
        type: 'Timeslip',
        args: {
          user: {
            connect: this.meId,
          }
        },
      });
    },

    canCreateExpenses() {
      return this.policyChecker({
        policy: 'create',
        type: 'Expense',
        args: {
          user: {
            connect: this.meId,
          }
        },
      });
    },

    canAdminToolsView() {
      return this.policyChecker({
        policy: 'create',
        type: 'ActivityExport',
      });
    },

    canViewSelf() {
      return this.policyChecker({
        policy: 'view',
        type: 'User',
        id: this.meId,
      });
    },

    canViewProjectRecordInterface() {
      return this.policyChecker({
        policy: 'viewInterface',
        type: 'ProjectRecord',
      });
    },

    canDoInvoicing() {
      return this.policyChecker({
        policy: 'viewScoped',
        type: 'Invoice',
        args: {
          user: this.meId,
        },
      });
    },

    canDoPerformance() {
      return this.policyChecker({
        policy: 'performanceTool.interface',
      });
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    ...mapState('session', ['me']),
  },

  methods: {
    updateShow(v) {
      this.$emit('update:show', v);
    },

    async doSignOut() {
      this.isSigningOut = true;
      try {
        const status = await this.$store.dispatch('session/signOut');

        if (status && this.$route.name !== 'top') {
          await this.$router.push({name: 'top'});
        }
      } finally {
        this.isSigningOut = false;
      }
    },
  }
}
</script>
