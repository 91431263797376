<template>
  <v-currency-field
    :autofocus="autofocus"
    :auto-decimal-mode="autoDecimal"
    :clearable="clearable"
    :counter="counter"
    :disabled="disabled"
    :error-messages="errorMessages"
    :hint="hintParsed"
    :label="labelParsed"
    :loading="loading"
    :outlined="outlined"
    :persistent-hint="localPersistentHint"
    :placeholder="placeholderParsed"
    :suffix="suffix"
    :value="value"
    @blur="doTouch"
    @change="doTouch"
    @input="onInput"
    :locale="locale"
    v-bind="$attrs"
    v-on="$listeners"
    currency="CAD"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-currency-field>
</template>

<script>
  import KFormTextElementMixin from "./KFormTextElementMixin.js";
  import {mapGetters} from "vuex";
  import Queries from "queries/index.js";

  export default {
    inheritAttrs: false,

    mixins: [
      KFormTextElementMixin,
    ],

    data: () => ({
      autoDecimal: true,
    }),

    // props: {
    //   autocomplete: String,
    //
    //   type: {
    //     type: String,
    //     default: 'text',
    //   },
    //
    //   readonly: Boolean,
    // },

    apollo: {
      autoDecimal: {
        query: Queries.User.Single.AutoDecimal,
        variables() {
          return {
            id: this.meId,
          };
        },
        update: ({user}) => Boolean(user?.settings?.autoDecimal ?? true),
        skip() {
          return !this.meId;
        },
      },
    },

    computed: {
      ...mapGetters('session', ['locale', 'meId']),
    },

    methods: {
      onInput(v) {
        this.$emit('input', v);
      },
    },
  }
</script>
