import Queries from 'queries/index.js';
import stringify from 'json-stringify-deterministic'

export default (
  {
    policy,
    user = undefined,
    type = undefined,
    id = undefined,
    args = undefined,
    skip = false,
    subscribe = false,
    fetchPolicy = 'cache-first'
  }
  ) => {
  const variables = {
    user,
    policy,
    contextType: type,
    contextId: id,
    ...(args !== undefined) && {contextArguments: stringify(args)},
  };
  return {
    query: Queries.PolicyTest.Single.Main,
    variables: () => variables,
    update: (response) => Boolean(response?.policyTest?.allowed),
    skip,
    fetchPolicy,
    // fetchPolicy: 'cache-and-network',
    ...subscribe && {
      subscribeToMore: {
        document: Queries.PolicyTest.Subscriptions.Main,
        variables: () => variables,
        updateQuery: (previousResult, { subscriptionData }) => {
          return {
            policyTest: subscriptionData?.data?.policyTestUpdated,
          };
        },
      },
    },
  };
};
