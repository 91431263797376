<template>
  <VContainer>
    <VToolbar dense color="grey lighten-3" class="mx-1">
      <VToolbarTitle>
        {{titleMsg}}
      </VToolbarTitle>
      <VSpacer/>
      <VFadeTransition>
        <VBtn v-show="showFilterControl" @click="toggleFilter" text>
          <FaI size="2x" icon="chevron-down" :rotation="showFilter ? 180 : 0" style="transition: all 0.3s ease;"/>
          <div>
            <VFadeTransition leave-absolute>
              <FaI size="2x" key="a" v-if="isFiltered" icon="filter"/>
              <FaI size="2x" key="b" v-else :icon="['far', 'filter']"/>
            </VFadeTransition>
          </div>
        </VBtn>
      </VFadeTransition>
      <template v-slot:extension>
        <VTabs icons-and-text centered show-arrows>
          <VTab key="default" :to="{name: 'expenses'}" exact v-if="canCreate">
            <span v-t="'actions.new'"/>
            <FaI icon="circle-plus" size="lg"/>
          </VTab>
          <VTab v-for="page in pages" :key="page.id" :to="{name: page.to}" exact>
            <span v-t="page.name"/>
            <FaI :icon="page.icon" size="lg"/>
          </VTab>
        </VTabs>
      </template>
    </VToolbar>

    <VTabsItems :value="tab" touchless class="px-1">
      <VTabItem key="default" value="default" v-if="canCreate">
        <ExpenseBrowseTab
            :filter-disables="{userId: true, officeId: true}"
            :filter-defaults="{userId: meId}"
            sort-default="UPDATED_AT"
            :order-desc-default="true"
            :show-filter="false"
            v-slot:default="{onCreate}"
            simple
        >
          <FormExpense @success="onCreate"/>
          <VDivider/>
          <VCardTitle v-t="'views.expenses.historyTitle'"/>
        </ExpenseBrowseTab>
      </VTabItem>
      <VTabItem v-for="{id, disables, defaults, sort, sortDesc, simple} in pages" :key="id" :value="id">
        <ExpenseBrowseTab
            :filter-disables="disables"
            :filter-defaults="defaults"
            :sort-default="sort"
            :order-desc-default="sortDesc"
            :show-filter="showFilter"
            :simple="simple"
            @filtered="setIsFiltered"
        />
      </VTabItem>
    </VTabsItems>
  </VContainer>
</template>

<script>
//import FormExpense from "./elements/FormExpense.vue";
import FormExpense from "./elements/FormExpense.vue";
import {mapGetters} from "vuex";
import ExpenseBrowseTab from "./elements/ExpenseBrowseTab.vue";

export default {
  components: {
    FormExpense,
    ExpenseBrowseTab,
  },

  data: () => ({
    showFilter: false,
    isFilteredStore: {},
  }),

  props: {
    tab: {
      type: String,
      default: 'default',
    },
  },

  apollo: {
    canCreate() {
      return this.policyChecker({
        policy: 'create',
        type: 'Expense',
        args: {
          user: {
            connect: this.meId,
          },
        },
      });
    },

    canViewScoped() {
      return this.policyChecker({
        policy: 'viewScoped',
        type: 'Expense',
        args: {user: this.meId},
      });
    },

    canViewAny() {
      return this.policyChecker({
        policy: 'viewAny',
        type: 'Expense',
      });
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    pages() {
      const {canViewAny, canViewScoped, meId} = this;

      const payload = [];

      if (canViewScoped) {
        payload.push({
          id: 'review',
          name: 'ui.review',
          to: 'expenses.review',
          icon: 'clock-rotate-left',
          defaults: {
            userId: meId,
          },
          disables: {
            userId: true,
            officeId: true,
          },
          sort: 'DATE',
          sortDesc: true,
          simple: true,
        });
      }

      if (canViewAny) {
        payload.push({
          id: 'admin',
          name: 'ui.admin',
          to: 'expenses.admin',
          icon: 'screwdriver-wrench',
          sort: 'UPDATED_AT',
          sortDesc: true,
        });
      }

      return payload;
    },

    isFiltered() {
      const {isFilteredStore, tab} = this;
      return isFilteredStore[tab] || false;
    },

    titleMsg() {
      return this.$tc('models.expense.self', 2);
    },

    showFilterControl() {
      return this.tab !== 'default';
    }
  },

  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    setIsFiltered(v) {
      this.$set(this.isFilteredStore, this.tab, v);
    },
  },
}
</script>
