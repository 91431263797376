// Define regex patterns as constants
const incompleteRangeRegex = /-\s*$/;
const alphaRangeRegex = /^[A-Za-z]$/;
const alphanumericRegex = /^([A-Za-z]*)(\d*)$/;
const numericRegex = /^\d+$/;
const componentsSplitRegex = /\s*,\s*/;

export default function (input) {
  const outputSet = new Set(); // Use a Set to ensure uniqueness
  const components = input.split(componentsSplitRegex); // Split by comma and trim spaces

  for (const component of components) {
    if (incompleteRangeRegex.test(component)) {
      // Incomplete range, ignore
      continue;
    }

    const dashIndex = component.indexOf('-');
    if (dashIndex !== -1) {
      // It's a range
      let start = component.substring(0, dashIndex).trim();
      let end = component.substring(dashIndex + 1).trim();

      // Normalize case if either end is uppercase in an alphabetical range
      if (alphaRangeRegex.test(start) && alphaRangeRegex.test(end)) {
        if (start.toLowerCase() !== start || end.toLowerCase() !== end) {
          start = start.toUpperCase();
          end = end.toUpperCase();
        }
      }

      const startMatch = start.match(alphanumericRegex);
      const endMatch = end.match(alphanumericRegex);

      if (!startMatch || !endMatch || (startMatch[1].toLowerCase() !== endMatch[1].toLowerCase() && startMatch[2] && endMatch[2])) {
        // Ignore nonsensical ranges or if prefix doesn't match (ignoring case)
        continue;
      }

      const prefix = (startMatch[1] && endMatch[1] && startMatch[1].toUpperCase() === endMatch[1].toUpperCase()) ? startMatch[1].toUpperCase() : startMatch[1];
      const isNumericRange = numericRegex.test(startMatch[2]) && numericRegex.test(endMatch[2]);
      const isAlphaRange = alphaRangeRegex.test(start) && alphaRangeRegex.test(end);

      if (isNumericRange || isAlphaRange) {
        let startVal, endVal;
        const convertFunc = isNumericRange
          ? (num) => prefix + num.toString()
          : (code) => String.fromCharCode(code);

        if (isNumericRange) {
          startVal = parseInt(startMatch[2], 10);
          endVal = parseInt(endMatch[2], 10);
        } else { // isAlphaRange
          startVal = start.charCodeAt(0);
          endVal = end.charCodeAt(0);
        }

        const range = startVal <= endVal
          ? Array.from({length: endVal - startVal + 1}, (_, i) => convertFunc(startVal + i))
          : Array.from({length: startVal - endVal + 1}, (_, i) => convertFunc(startVal - i));

        range.forEach(value => outputSet.add(value));
      }
    } else if (component) {
      // Single value, not empty
      outputSet.add(component);
    }
  }

  return Array.from(outputSet); // Convert the Set back into an array
}
