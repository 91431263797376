import gql from 'graphql-tag';
import Team from "./team.js";
import ThumbnailFragment from "./thumbnailFragment.js";
import PaginatorFragment from './paginatorFragment.js'

// Browse

const List = gql`
  query(
    $orderBy: [QueryUsersOrderByOrderByClause!]
    $search: String
    $officeId: ID
    $page: Int
    $first: Int!
  ) {
    users(
      orderBy: $orderBy
      search: $search
      office: $officeId
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        ...userThumbnailFragment
      }
    }
  }
  ${PaginatorFragment}
  ${ThumbnailFragment}
`;


// Forms

const Search = gql`
  query(
    $search: String!
    $first: Int!
  ) {
    users(
      search: $search
      first: $first
    ) {
      data {
        id
        name
      }
    }
  }
`;

const Populate = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      name
    }
  }
`;

const UniqueTest = gql`
  query(
    $email: String
  ) {
    users(
      email: $email
      first: 1
    ) {
      data {
        id
      }
    }
  }
`;


// Single

const IdFromSlug = gql`
  query(
    $slug: String!
  ) {
    userBySlug(
      slug: $slug
    )
    {
      id
      slug
    }
  }
`;

const Main = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      name
      slug
    }
  }
`;

const Overview = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      email
      title
      contact {
        id
        location {
          id
          addressInline
        }
      }
    }
  }
`;

const UserTeam = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      team {
        ...teamMembersDisplayFragment
      }
    }
  }
  ${Team.Fragments.MembersDisplay}
`;

const HasRole = gql`
  query(
    $user: ID!
    $role: ID!
    $team: ID
  ) {
    userHasRole(
      user: $user
      role: $role
      team: $team
    )
  }
`;

const GetLocale = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      settings {
        locale
      }
    }
  }
`;

const GetTimeFormat = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      settings {
        timeFormat
      }
    }
  }
`;

const GetDurationFormat = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      settings {
        durationFormat
      }
    }
  }
`;

const GetAutoDecimal = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      settings {
        autoDecimal
      }
    }
  }
`;

const GetTimeslipsFormExpand = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      settings {
        timeslipsFormExpand
      }
    }
  }
`;

const GetProjectSearchScoped = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      settings {
        projectSearchScoped
      }
    }
  }
`;

const GetProjectPickerScoped = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      settings {
        projectPickerScoped
      }
    }
  }
`;


// Write

const FormFill = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      name
      nameParsed {
        source
      }
      email
      title
      company {
        id
        name
      }
      settings {
        locale
        employeeId
      }
#      contact {
#        id
#        ...contactFormFillFragment
#      }
    }
  }
`;

const Create = gql`
  mutation(
    $email: String!
    $name: String!
    $title: String
#    $phones: JSON
#    $street: String
#    $streetExtra: String
#    $postalCode: String
#    $cityId: ID!
    $companyId: ID!
    $employeeId: ID!
    $locale: String
    $billingRate: Float!
    $billingRateStartAt: Date!
    $roles: [BaseRole!]!
    $officeId: ID!
    $officeRole: OfficeRole!
  ) {
    userCreate(
      input: {
        email: $email
        name: $name
        title: $title
#        contact: {
#          upsert: {
#            phones: $phones
#            location: {
#              upsert: {
#                street: $street
#                streetExtra: $streetExtra
#                postalCode: $postalCode
#                city: {
#                  connect: $cityId
#                }
#              }
#            }
#          }
#        }
        company: {
          connect: $companyId
        }
        settings: {
          locale: $locale
          employeeId: $employeeId
        }
        roles: $roles
        billingRate: {
          rate: $billingRate
          startAt: $billingRateStartAt
        }
        office: {
          id: $officeId
          role: $officeRole
        }
      }
    ) {
      id
      slug
      name
      email
      title
      company {
        id
      }
      settings {
        employeeId
        locale
      }
    }
  }
`;

const Update = gql`
  mutation(
    $id: ID!
    $name: String
    $title: String
    $locale: String
    $employeeId: ID
#    $phones: [Phone!]
#    $street: String
#    $streetExtra: String
#    $postalCode: String
#    $cityId: ID
  ) {
    userUpdate(
      id: $id
      input: {
        name: $name
        title: $title
        settings: {
          locale: $locale
          employeeId: $employeeId
        }
#        contact: {
#          update: {
#            phones: $phones
#            location: {
#              update: {
#                street: $street
#                streetExtra: $streetExtra
#                postalCode: $postalCode
#                city: {
#                  connect: $cityId
#                }
#              }
#            }
#          }
#        }
      }
    ) {
      id
      slug
      name
      title
      settings {
        employeeId
        locale
      }
    }
  }
`;

const IdenticonSet = gql`
  mutation(
    $id: ID!
    $file: Upload!
  ) {
    userIdenticonSet(
      id: $id
      file: $file
    ) {
      ...userThumbnailFragment
    }
  }
  ${ThumbnailFragment}
`;

const IdenticonClear = gql`
  mutation(
    $id: ID!
  ) {
    userIdenticonClear(
      id: $id
    ) {
      ...userThumbnailFragment
    }
  }
  ${ThumbnailFragment}
`;


// Settings

const Locale = gql`
  mutation(
    $id: ID!
    $locale: String!
  ) {
    userUpdate(
      id: $id
      input: {
        settings: {
          locale: $locale
        }
      }
    )
    {
      id
      settings {
        locale
      }
    }
  }
`;

const TimeFormat = gql`
  mutation(
    $id: ID!
    $timeFormat: String!
  ) {
    userUpdate(
      id: $id
      input: {
        settings: {
          timeFormat: $timeFormat
        }
      }
    )
    {
      id
      settings {
        timeFormat
      }
    }
  }
`;

const DurationFormat = gql`
  mutation(
    $id: ID!
    $durationFormat: String!
  ) {
    userUpdate(
      id: $id
      input: {
        settings: {
          durationFormat: $durationFormat
        }
      }
    )
    {
      id
      settings {
        durationFormat
      }
    }
  }
`;

const AutoDecimal = gql`
  mutation(
    $id: ID!
    $autoDecimal: Boolean!
  ) {
    userUpdate(
      id: $id
      input: {
        settings: {
          autoDecimal: $autoDecimal
        }
      }
    )
    {
      id
      settings {
        autoDecimal
      }
    }
  }
`;

const TimeslipsFormExpand = gql`
  mutation(
    $id: ID!
    $timeslipsFormExpand: Boolean!
  ) {
    userUpdate(
      id: $id
      input: {
        settings: {
          timeslipsFormExpand: $timeslipsFormExpand
        }
      }
    )
    {
      id
      settings {
        timeslipsFormExpand
      }
    }
  }
`;

const ProjectSearchScoped = gql`
  mutation(
    $id: ID!
    $projectSearchScoped: Boolean!
  ) {
    userUpdate(
      id: $id
      input: {
        settings: {
          projectSearchScoped: $projectSearchScoped
        }
      }
    )
    {
      id
      settings {
        projectSearchScoped
      }
    }
  }
`;

const ProjectPickerScoped = gql`
  mutation(
    $id: ID!
    $projectPickerScoped: Boolean!
  ) {
    userUpdate(
      id: $id
      input: {
        settings: {
          projectPickerScoped: $projectPickerScoped
        }
      }
    )
    {
      id
      settings {
        projectPickerScoped
      }
    }
  }
`;


// Info

const Card = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      name
      slug
      email
    }
  }
`;

const Identity = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      id
      name
    }
  }
`;

const Thumbnail = gql`
  query(
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      ...userThumbnailFragment
    }
  }
  ${ThumbnailFragment}
`;

// Subscription

const UserRoleUpdated = gql`
  subscription onUserRoleUpdated(
    $user: ID!
    $role: ID!
    $team: ID
  ) {
    userRoleUpdated (
      user: $user
      role: $role
      team: $team
    )
  }
`;


// Export

const Fragment = {
  ThumbnailFragment
};

const Browse = {
  List,
};

const Forms = {
  Populate,
  Search,
  UniqueTest,
};

const Single = {
  IdFromSlug,
  Main,
  Overview,
  Team: UserTeam,
  HasRole,
  Locale: GetLocale,
  TimeFormat: GetTimeFormat,
  AutoDecimal: GetAutoDecimal,
  TimeslipsFormExpand: GetTimeslipsFormExpand,
  ProjectSearchScoped: GetProjectSearchScoped,
  ProjectPickerScoped: GetProjectPickerScoped,
  DurationFormat: GetDurationFormat,
};

const Subscriptions = {
  UserRoleUpdated,
};

const Write = {
  FormFill,
  Create,
  Update,
  IdenticonSet,
  IdenticonClear,
};

const SettingsWrite = {
  Locale,
  TimeFormat,
  DurationFormat,
  AutoDecimal,
  TimeslipsFormExpand,
  ProjectSearchScoped,
  ProjectPickerScoped,
};

const Info = {
  Card,
  Identity,
  Thumbnail,
};

export default  {
  Browse,
  Forms,
  Fragment,
  Single,
  Write,
  Subscriptions,
  Settings: SettingsWrite,
  Info,
}
