import { req, withParams } from 'vuelidate/lib/validators/common'
import {all as allowedTypes} from "elements/media/mimeTypes.js"

const getType = (file) => {
  if (file.type) {
    return file.type;
  }

  const extension = file.name.split('.').pop();
  const mime = allowedTypes[extension];

  return mime?.type;
};

export default (types) =>
  withParams({type: 'mimeTypes', types},
    (value) => {
      if (!req(value)) {
        return true;
      }

      const typesLocal = typeof types === 'string' ? [types] : types;

      return typesLocal.includes(getType(value));
    }
  )
