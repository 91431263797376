<template>
  <VRow>
    <VCol cols="12">
      <KTextField
        :value="form.name"
        @input="debouncedNameInput"
        :validator="$v.form.name"
        label="models.project.name"
        hint="forms.general.required"
        :loading="$v.form.name.$pending"
        persistent-hint
      >
        <template #append v-if="!$v.form.name.$invalid">
          <FaI icon="check" color="green"/>
        </template>
      </KTextField>
    </VCol>

    <VCol cols="12" lg="6">
      <KApiSelect
        v-model="form.officeId"
        :validator="$v.form.officeId"
        model="office"
        hint="forms.general.required"
        persistent-hint
      />
    </VCol>

    <VCol cols="12" lg="6">
      <KApiSelect
        v-model="form.projectTypeId"
        :validator="$v.form.projectTypeId"
        model="projectType"
        hint="forms.general.required"
        persistent-hint
      />
    </VCol>

    <VCol cols="12" lg="6">
      <KApiSelect
        v-model="form.workRegion"
        :validator="$v.form.workRegion"
        model="countryDivision"
        :variables="{country: '1'}"
        label="models.project.settings.workRegion"
        hint="forms.project.workRegion_hint"
        :loading="$apollo.queries.defaultWorkRegion.loading"
        persistent-hint
      />
    </VCol>

    <VCol cols="12" lg="6">
      <KCheckbox
        v-model="form.isTaxExempt"
        label="models.project.settings.isTaxExempt"
        hint="forms.project.isTaxExempt_hint"
        persistent-hint
      />
    </VCol>

    <VCol cols="12">
      <VRadioGroup :label="localeLabel" v-model="form.locale" row>
        <VRadio v-for="{label, value} in localeItems" :key="value" :label="label" :value="value"/>
      </VRadioGroup>
    </VCol>

    <VCol cols="12">
      <VExpandTransition tag="v-col" cols="12">
        <VCard v-if="hasSimilarProjects">
          <VCardTitle v-t="'forms.project.similarProjects.title'"/>
          <VCardSubtitle v-t="'forms.project.similarProjects.help'"/>
          <VCardText>
            <VRow>
              <VCol v-for="{id: projectId} in similarProjects" :key="projectId" cols="12" lg="6" xl="4">
                <VCard>
                  <ProjectListItem disabled :id="projectId"/>
                </VCard>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VExpandTransition>
    </VCol>
  </VRow>
</template>

<script>
import Queries from 'queries/index.js';
import ProjectListItem from "top/views/projects/browse/elements/ProjectListItem.vue";
import {KApiSelect, KCheckbox, KSuggest, KTextField, scopedFormsMixin} from "forms/elements/index.js";
import {debounce, union} from "lodash-es";
import {mapGetters} from 'vuex';
import {betweenLength, primaryKey, required, unique} from "validators/index.js";

const locales = [
  'en-CA',
  'fr-CA',
];

export default {
  mixins: [
    scopedFormsMixin,
  ],

  components: {
    KApiSelect,
    KCheckbox,
    KSuggest,
    KTextField,
    ProjectListItem,
  },

  data() {
    return {
      form: {
        name: null,
        officeId: null,
        projectTypeId: null,
        locale: this.$store.getters['session/locale'],
        workRegion: null,
        isTaxExempt: false,
      },
    };
  },

  apollo: {
    similarProjects: {
      query: Queries.Project.Browse.List,
      variables() {
        const {form: {name: search, projectTypeId}} = this;
        return {
          search,
          first: 6,
          ...projectTypeId && {projectTypeId},
        };
      },
      throttle: 1000,
      skip() {
        return !this.form.name || this.form.name.length === 0;
      },
      update: ({projects: {data}}) => data,
    },

    defaultWorkRegion: {
      query: Queries.Office.Single.GetLocationForProjectWorkRegion,
      variables() {
        return {
          id: this.form.officeId,
        };
      },
      skip() {
        return !this.form.officeId;
      },
      update: (response) => response?.office?.contact?.location?.countryDivision?.id,
    },
  },

  validations: {
    form: {
      name: {
        required,
        betweenLength: betweenLength(3, 250),
        unique: unique(Queries.Project.Forms.UniqueTest),
      },
      officeId: {
        required,
        primaryKey,
      },
      // clientCompanyId: {
      //   primaryKey,
      // },
      projectTypeId: {
        required,
        primaryKey,
      },
      locale: {
        required,
      },
      workRegion: {
        required,
        primaryKey,
      },
    },
  },

  computed: {
    ...mapGetters('session', {userLocale: 'locale'}),

    hasSimilarProjects() {
      return this.localName !== 0 && this.similarProjects && this.similarProjects.length !== 0;
    },

    localeLabel() {
      return this.$t('models.project.settings.locale');
    },

    localeItems() {
      return union([this.userLocale], locales).map((value) => ({
        label: this.$t(`locales.${value}`),
        value,
      }));
    },
  },

  watch: {
    defaultWorkRegion(v) {
      if (v) {
        this.form.workRegion = v;
      }
    }
  },

  methods: {
    debouncedNameInput: debounce(
      function (v) {
        return this.form.name = v;
      }, 500, {maxWait: 2000}
    ),
  },
}
</script>
