import gql from 'graphql-tag';

// Fragments

const MediaThumbnailFragment = gql`
  fragment mediaThumbnailFragment on Media
  {
    id
    uuid
    name
    mimeType
    extension
    size
    conversions(filter: ["thumbnail"]) {
      id
      name
      url
    }
  }
`;

const MediaListBaseFragment = gql`
  fragment mediaListBaseFragment on Media
  {
    id
    uuid
    name
    mimeType
    extension
    size
    width
    height
    aspectRatio
    allowsConversion
    conversions(filter: ["thumbnail"]) {
      id
      name
      url
    }
  }
`;

const MediaListDialogFragment = gql`
  fragment mediaListDialogFragment on Media
  {
    id
    conversions(filter: ["large"]) {
      id
      name
      url
    }
  }
`;

const MediaDownloadFragment = gql`
  fragment mediaDownloadFragment on Media
  {
    id
    fileName
    sizeForHumans
    url
  }
`;


// Single

const DownloadUrl = gql`
  query(
    $id: ID!
  ) {
    media(
      id: $id
    ) {
      ...mediaDownloadFragment
    }
  }
  ${MediaDownloadFragment}
`;

const MediaListBase = gql`
  query(
    $id: ID!
  ) {
    media(
      id: $id
    ) {
      ...mediaListBaseFragment
    }
  }
  ${MediaListBaseFragment}
`;

const MediaListDialog = gql`
  query(
    $id: ID!
  ) {
    media(
      id: $id
    ) {
      ...mediaListDialogFragment
    }
  }
  ${MediaListDialogFragment}
`;

const ConversionUrls = gql`
  query(
    $id: ID!
    $filter: [String!]
  ) {
    media(
      id: $id
    ) {
      id
      conversions(
        filter: $filter
      ) {
        id
        url
      }
    }
  }
`;

// Browse

const Medias = gql`
  query(
    $ids: [ID!]!
  ) {
    medias(
      ids: $ids
    ) {
      ...mediaThumbnailFragment
    }
  }
  ${MediaThumbnailFragment}
`;

const MediasByUuid = gql`
  query(
    $uuids: [UUID!]!
  ) {
    mediasByUuid(
      uuids: $uuids
    ) {
      ...mediaThumbnailFragment
    }
  }
  ${MediaThumbnailFragment}
`;


// Export

const Fragments = {
  MediaListBaseFragment,
  MediaListDialogFragment,
  MediaThumbnailFragment,
  MediaDownloadFragment,
};

const Single = {
  DownloadUrl,
  ConversionUrls,
  MediaListBase,
  MediaListDialog,
};

const Browse = {
  MediasByUuid,
  Medias,
};

export default {
  Fragments,
  Single,
  Browse,
};
