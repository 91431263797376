import Echo from "laravel-echo";
import Pusher from 'pusher-js';

const AUTH_TOKEN = 'auth-token';
window.Pusher = Pusher;

const authorization = () => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return token ? `Bearer ${token}` : null;
};

const echoClient = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  authEndpoint: '/graphql/subscriptions/auth',
  wsHost:  (window.location.hostname.split('.').pop() === 'test' ? '' : 'socket.') + window.location.hostname,
  // wsPort: 6001,
  // wssPort: 6001,
  forceTLS: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  auth: {
    headers: {
      authorization: authorization(),
    },
  },
});

window.Echo = echoClient;

export default echoClient;
