<template>
  <VExpansionPanelHeader ripple>
    <VSkeletonLoader type="list-item" v-if="$apollo.loading"/>
    <div v-else class="d-flex flex-nowrap align-center" style="max-width: 100%; padding-right: 28px; margin-right: -24px">
      <UserThumbnail v-if="!isMe" :id="projectRecord.createdBy.id" size="xs" disabled class="flex-shrink-0"/>
      <div class="flex-grow-1 pl-2 d-inline-flex flex-column" style="max-width: 100%; padding-right: 28px; margin-right: -24px">
        <div class="d-flex flex-column">
          <div class="d-flex flex-column flex-md-row flex-nowrap">
            <strong class="flex-shrink-0">
              {{projectStatus.name}}:&nbsp;
            </strong>
            <div class="flex-shrink-1 text-truncate">
              {{project.name}}
            </div>
            <small class="ml-1 grey--text flex-shrink-0">
              ({{project.id}})
            </small>
          </div>
          <div class="d-flex" v-if="comment">
            <em class="flex-shrink-1 text-truncate">
              &ldquo;{{comment}}&rdquo;
            </em>
          </div>
          <div>
            <small class="grey--text">
              {{createdTimestamp}}
            </small>
          </div>
        </div>
      </div>
    </div>
  </VExpansionPanelHeader>
</template>

<script>
  import Queries from "queries/index.js";
  import tickerMixin from "top/mixins/tickerMixin.js";
  import UserThumbnail from "elements/UserThumbnail.vue";
  import {mapGetters} from "vuex";

  export default {
    mixins: [
      tickerMixin,
    ],

    components: {
      UserThumbnail,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    apollo: {
      projectRecord: {
        query: Queries.ProjectRecord.Single.ExpansionPanelHeader,
        variables() {
          return {
            id: this.id,
          };
        },
      },

      project: {
        query: Queries.Project.Forms.Populate,
        variables() {
          return {
            id: this.projectRecord.project.id,
          }
        },
        skip() {
          return !this.projectRecord;
        }
      },

      projectStatus: {
        query: Queries.ProjectStatus.Forms.Populate,
        variables() {
          return {
            id: this.projectRecord.projectStatus.id,
          }
        },
        skip() {
          return !this.projectRecord;
        }
      },
    },

    computed: {
      ...mapGetters('session', ['meId']),

      isMe() {
        const {projectRecord, meId} = this;
        return projectRecord ? projectRecord.createdBy.id === meId : false;
      },

      createdTimestamp() {
        const {projectRecord, getTickerForHumans} = this;
        return projectRecord
          ? getTickerForHumans(projectRecord.createdAt)
          : '';
      },

      comment() {
        return this.projectRecord?.record?.comment;
      }
    },
  }
</script>
