<template>
  <ClickConfirm color="red" :loading="isDeleting" @confirm="deleteAction" v-bind="$attrs">
    <FaI icon="trash"/>
  </ClickConfirm>
</template>

<script>
  import Queries from "queries/index.js";
  import ClickConfirm from "./ClickConfirm.vue"

  export default {
    inheritAttrs: false,

    components: {
      ClickConfirm,
    },

    data: () => ({
      isDeleting: false,
    }),

    props: {
      id: {
        type: [String, Number],
        required: true,
      },

      model: {
        type: String,
        required: true,
      },
    },

    computed: {
      queryGroup() {
        const {model} = this;
        return model.charAt(0).toUpperCase() + model.slice(1);
      },

      query() {
        return Queries[this.queryGroup].Write.Delete;
      },

      queryName() {
        return this.query?.definitions?.[0]?.selectionSet?.selections?.[0]?.name?.value;
      },
    },

    methods: {
      async deleteAction() {
        const {id} = this,
          variables = {
            id,
          };

        this.isDeleting = true;
        try {
          const response = await this.$apollo.mutate({
            mutation: this.query,
            variables,
          });
          this.$emit('delete', response.data[this.queryName]);
        } finally {
          this.isDeleting = false;
        }
      },
    }
  }
</script>
