<template>
  <VRow>
    <VCol cols="12">
      <KApiSelect
        v-model="form.projectStatusId"
        :validator="$v.form.projectStatusId"
        hint="forms.general.required"
        model="projectStatus"
      />
    </VCol>

    <VCol cols="12">
      <FormProjectRecord
        :project-status="form.projectStatusId"
        :project-type="projectTypeId"
        @input="form.projectRecord = $event"
        @validator="recordValidator = $event"
        :financials-optional="true"
      />
    </VCol>
  </VRow>
</template>

<script>
import FormProjectRecord from "top/views/projectRecords/forms/FormProjectRecord.vue";
import {KApiSelect, scopedFormsMixin} from "forms/elements/index.js";
import {primaryKey, required, validatorIsValid} from "validators/index.js";

export default {
  mixins: [
    scopedFormsMixin,
  ],

  components: {
    FormProjectRecord,
    KApiSelect,
  },

  data: () => ({
    form: {
      projectStatusId: null,
      projectRecord: null,
    },
    recordValidator: null,
  }),

  props: {
    projectTypeId: [Number, String],
  },

  validations: {
    form: {
      projectStatusId: {
        required,
        primaryKey,
      },
    },
    recordValidator: {
      required,
      validatorIsValid,
    },
  },

  watch: {
    '$v.$dirty'(v) {
      if (v && this.recordValidator) {
        this.recordValidator.$touch();
      }
    },

    projectTypeId(v) {
      this.resetValidator();
    },

    'form.projectStatusId'(v) {
      this.resetValidator();
    },
  },

  methods: {
    resetValidator() {
      this.$v.$reset();
    },
  },
}
</script>
