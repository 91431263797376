<template>
  <VCard color="grey lighten-4">
    <VCardTitle v-t="'views.passwordFeedback.title'"/>
    <VExpandTransition>
      <VCardSubtitle v-if="this.$v.$invalid">
          <p v-t="'views.passwordFeedback.hint1'"/>
          <p v-t="'views.passwordFeedback.hint2'"/>
      </VCardSubtitle>
    </VExpandTransition>
    <VCardText>
      <VProgressLinear
        :value="progress"
        height="50"
        :color="progressColor"
        rounded
      >
        <span v-t="progressMsg"/>
      </VProgressLinear>
      <VExpandTransition>
        <VAlert v-if="warning" type="warning" text dense class="mt-2">
          {{warning}}
        </VAlert>
      </VExpandTransition>
      <VExpandTransition>
        <ul v-if="suggestions">
          <VScrollYTransition group>
            <li v-for="item in suggestions" :key="item">
              {{item}}
            </li>
          </VScrollYTransition>
        </ul>
      </VExpandTransition>
    </VCardText>
  </VCard>
</template>

<script>
  import Queries from 'queries/index.js'
  import {required, email} from 'validators/index.js'

  export default {
    props: {
      password: String,

      email: String,
    },

    apollo: {
      passwordTest: {
        query: Queries.Auth.Password.Test,
        variables() {
          const {email, password} = this;
          return {
            password,
            email,
          };
        },
        throttle: 1000,
        skip() {
          return this.$v.$invalid;
        },
      },
    },

    validations: {
      password: {
        required,
      },
      email: {
        required,
        email,
      }
    },

    watch: {
      '$v.$invalid'(v) {
        if (v) {
          this.passwordTest = undefined;
        }
      }
    },

    computed: {
      score() {
        return this.passwordTest?.score ?? null;
      },

      progress() {
        return Math.round(((this.score ?? 0)/4)*100);
      },

      warning() {
        return this.passwordTest?.warning;
      },

      suggestions() {
        return this.passwordTest?.suggestions;
      },

      progressColor() {
        switch (this.score) {
          case 0:
          case 1:
            return 'red';
          case 2:
            return 'orange';
          case 3:
            return 'yellow';
          case 4:
            return 'green';
          default:
            return 'grey';
        }
      },

      progressMsg() {
        const prefix = 'views.passwordFeedback.ratings.';
        if (this.$apollo.loading) {
          return `${prefix}loading`;
        }

        if (this.score !== null) {
          return `${prefix}${this.score}`;
        }

        return `${prefix}default`;
      }
    },
  }
</script>
