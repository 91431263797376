<template>
  <VContainer fluid>
    <VCard>
      <ProjectRecordsExport/>
    </VCard>
    <VCard class="mt-4">
      <ProjectRecordStatus/>
    </VCard>
  </VContainer>
</template>

<script>
  import ProjectRecordsExport from "./ProjectRecordsExport.vue";
  import ProjectRecordStatus from "./ProjectRecordsStatus.vue";

  export default {
    components: {
      ProjectRecordsExport,
      ProjectRecordStatus,
    },
  }
</script>
