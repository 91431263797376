<template>
  <VMenu
    :close-on-content-click="false"
    :nudge-right="40"
    min-width="290px"
    :disabled="disabled"
    offset-y
    ref="menu"
    transition="scale-transition"
    v-model="show"
  >
    <template v-slot:activator="{ on, attrs }">
      <VTextField
        :hint="hintParsed"
        :error-messages="errorMessages"
        :label="labelParsed"
        :disabled="disabled"
        :readonly="isOnMobile"
        v-model="textFieldValue"
        @input="onTextFieldInput"
        @blur="onBlur"
        @focus="onFocus"
        v-on="on"
        v-bind="attrs"
        v-mask="['#:##', '##:##']"
      >
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
          <slot :name="name" v-bind="slotData" />
        </template>
        <template v-slot:append>
          <FaI :icon="['far', 'timer']" color="grey"/>
        </template>
      </VTextField>
    </template>
    <VTimePicker
      :allowed-minutes="allowedStep"
      :value="localValue"
      :disabled="disabled"
      @click:minute="onClickMinute"
      @input="onLocalInput"
      format="24hr"
      :min="min"
      :max="max"
      full-width
      v-if="show"
    />
  </VMenu>
</template>

<script>
import KFormElementMixin from './KFormElementMixin.js'
import getTimestampFromSeconds from "top/getTimestampFromSeconds.js";

export default {
  mixins: [
    KFormElementMixin,
  ],

  data: () => ({
    textFieldValue: undefined,
    show: false,
  }),

  props: {
    min: String,

    max: String,
  },

  computed: {
    localValue() {
      return getTimestampFromSeconds(this.value, 'en-CA');
    },

    isOnMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },

  watch: {
    localValue: {
      handler(v) {
        this.textFieldValue = v;
      },
      immediate: true,
    },
  },

  methods: {
    onTextFieldInput(v) {
      if (!v && this.clearable) {
        this.onLocalInput(undefined);
      }

      if (/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v)) {
        this.onLocalInput(v, this.separator);
      }
    },

    onClickMinute(v) {
      this.$refs.menu.save(this.textFieldValue);
      this.show = false;
    },

    allowedStep: (v) => v % 5 === 0,

    onLocalInput(v) {
      if (!v) {
        this.onInput(v);
      }

      const [hour, minute] = v.split(':');

      this.onInput(Number(hour)*3600 + Number(minute)*60);
    },

    onFocus(event) {
      if (!this.isOnMobile) {
        event.target.select();
      }
    },

    onBlur() {
      this.textFieldValue = this.localValue;
      this.doTouch();
    },
  },
}
</script>
