<template>
  <div>
    <VRow>
      <VCol cols="12" sm="6" lg="8">
        <KCurrencyField
          :validator="$v.form.scheduledFees"
          label="models.projectRecord.financials.closedScope.scheduledFees"
          hint="forms.projectRecord.financials.closedScope.scheduledFees_hint"
          v-model="form.scheduledFees"
        />
      </VCol>

      <VCol cols="12" sm="6" lg="4">
        <KSelect
          v-model="form.timeslipsBillableType"
          :items="billableTypes"
          label="models.projectRecord.financials.closedScope.timeslipsBillableType"
          hint="forms.projectRecord.financials.closedScope.timeslipsBillableType_hint"
          persistent-hint
        >
          <template #item="{item, on, attrs}">
            <VListItem two-line v-on="on" v-bind="attrs">
              <VListItemContent>
                <VListItemTitle>
                  {{item.name}}
                </VListItemTitle>
                <VListItemSubtitle class="text--primary">
                  {{item.help}}
                </VListItemSubtitle>
              </VListItemContent>
            </VListItem>
          </template>
        </KSelect>
      </VCol>

<!--      <VCol cols="12" sm="6" lg="3" xl="1">-->
<!--        <KCheckbox-->
<!--          v-model="form.expensesBillable"-->
<!--          :label="`models.projectRecord.financials.closedScope.expensesBillable${$vuetify.breakpoint.lgAndUp ? '_shorthand' : ''}`"-->
<!--        />-->
<!--      </VCol>-->

      <VCol cols="12" lg="6" xl="3">
        <KCurrencyField
          :validator="$v.form.expenses"
          label="models.projectRecord.financials.closedScope.expenses"
          hint="forms.projectRecord.financials.closedScope.expenses_hint"
          v-model="form.expenses"
        />
      </VCol>

      <VCol cols="12" sm="6" lg="2" xl="1">
        <KTextField
          v-model.number="form.expensesMarkup"
          :validator="$v.form.expensesMarkup"
          :label="`models.projectRecord.financials.closedScope.expensesMarkup${$vuetify.breakpoint.lgAndUp ? '_shorthand' : ''}`"
          hint="forms.projectRecord.financials.closedScope.costMarkup_hint"
          type="number"
        >
          <template #append>
            %
          </template>
        </KTextField>
      </VCol>

      <VCol cols="12" sm="6" lg="4" xl="2">
        <KSelect
          v-model="form.expensesBillableType"
          :items="billableTypes"
          label="models.projectRecord.financials.closedScope.expensesBillableType"
          hint="forms.projectRecord.financials.closedScope.expensesBillableType_hint"
          persistent-hint
        >
          <template #item="{item, on, attrs}">
            <VListItem two-line v-on="on" v-bind="attrs">
              <VListItemContent>
                <VListItemTitle>
                  {{item.name}}
                </VListItemTitle>
                <VListItemSubtitle class="text--primary">
                  {{item.help}}
                </VListItemSubtitle>
              </VListItemContent>
            </VListItem>
          </template>
        </KSelect>
      </VCol>

      <VCol cols="12" lg="6" xl="3" offset-xl="1">
        <KCurrencyField
          :validator="$v.form.subconsultantCost"
          label="models.projectRecord.financials.closedScope.subconsultantCost"
          hint="forms.projectRecord.financials.closedScope.subconsultantCost_hint"
          v-model="form.subconsultantCost"
        />
      </VCol>

      <VCol cols="12" sm="6" lg="2" xl="1">
        <KTextField
          v-model.number="form.subconsultantCostMarkup"
          :validator="$v.form.subconsultantCostMarkup"
          :label="`models.projectRecord.financials.closedScope.subconsultantCostMarkup${$vuetify.breakpoint.lgAndUp ? '_shorthand' : ''}`"
          hint="forms.projectRecord.financials.closedScope.costMarkup_hint"
          type="number"
        >
          <template #append>
            %
          </template>
        </KTextField>
      </VCol>

      <VCol cols="12" sm="6" lg="4" xl="1">
        <KCheckbox
          v-model="form.subconsultantCostBillable"
          :label="`models.projectRecord.financials.closedScope.subconsultantCostBillable${$vuetify.breakpoint.lgAndUp ? '_shorthand' : ''}`"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12" sm="6" xl="3">
        <KCurrencyField
          :validator="$v.netFees"
          label="models.projectRecord.financials.closedScope.netFees"
          hint="forms.projectRecord.financials.closedScope.netFees_hint"
          v-model="netFees"
          persistent-hint
          disabled
        />
      </VCol>

      <VCol cols="12" sm="6" xl="3">
        <KCurrencyField
          :validator="$v.markupFees"
          label="models.projectRecord.financials.closedScope.markupFees"
          hint="forms.projectRecord.financials.closedScope.markupFees_hint"
          v-model="markupFees"
          persistent-hint
          disabled
        />
      </VCol>

      <VCol cols="12" sm="6" xl="3">
        <KCurrencyField
          :validator="$v.form.hourlyRateEstimate"
          label="models.projectRecord.financials.closedScope.hourlyRateEstimate"
          hint="forms.general.cannotChange"
          :value="form.hourlyRateEstimate"
          persistent-hint
          disabled
        />
      </VCol>

      <VCol cols="12" sm="6" xl="3">
        <KTextField
          :validator="$v.hoursEstimate"
          label="models.projectRecord.financials.closedScope.hoursEstimate"
          hint="forms.projectRecord.financials.closedScope.hoursEstimate_hint"
          v-model="hoursEstimate"
          persistent-hint
          disabled
        />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import {KCurrencyField, KCheckbox, KSelect, KTextField, scopedFormsMixin} from "forms/elements/index.js";
import {required, between, integer, currency} from "validators/index.js";

const isBillable = (v) => ['USUALLY', 'ALWAYS'].includes(v);

const billableTypes = [
  'ALWAYS',
  'USUALLY',
  'NEUTRAL',
  'SOMETIMES',
  'NEVER',
];

export default {
  mixins: [
    scopedFormsMixin,
  ],

  components: {
    KCheckbox,
    KCurrencyField,
    KSelect,
    KTextField,
  },

  data: () => ({
    consultingCostMarkupDialog: false,

    form: {
      scheduledFees: null,
      timeslipsBillableType: 'SOMETIMES',
      subconsultantCost: null,
      subconsultantCostBillable: false,
      subconsultantCostMarkup: 10,
      expenses: null,
      expensesBillableType: 'SOMETIMES',
      expensesMarkup: 10,
      hourlyRateEstimate: 200,
    },
  }),

  props: {
    optional: Boolean,
  },

  validations() {
    const {optional} = this;
    return {
      form: {
        scheduledFees: {
          ...!optional && {required},
          currency,
          between: between(0, 9999999.99),
        },
        subconsultantCost: {
          currency,
          between: between(0, 9999999.99),
        },
        subconsultantCostMarkup: {
          integer,
          between: between(0, 20),
        },
        expenses: {
          currency,
          between: between(0, 9999999.99),
        },
        expensesMarkup: {
          integer,
          between: between(0, 20),
        },
      },
      netFees: {
        ...!optional && {required},
        currency,
        between: between(0, 9999999.99),
      },
      markupFees: {
        ...!optional && {required},
        currency,
        between: between(0, 9999999.99),
      },
      hoursEstimate: {
        ...!optional && {required},
        integer,
        between: between(0, 99999),
      },
    };
  },

  computed: {
    billableTypes() {
      return billableTypes.map((id) => ({
        id,
        name: this.$t(`forms.activityPartition.billableType.${id.toLowerCase()}.name`),
        help: this.$t(`forms.activityPartition.billableType.${id.toLowerCase()}.description`),
      }));
    },

    netFees() {
      const {form: {scheduledFees, subconsultantCost, subconsultantCostBillable, expenses, expensesBillableType}} = this;
      return Math.round(
        (scheduledFees - (subconsultantCostBillable ? 0 : 1)*subconsultantCost - (isBillable(expensesBillableType) ? 0 : 1)*expenses)*100
      )/100;
    },

    markupFees() {
      const {form: {subconsultantCostBillable, subconsultantCost, subconsultantCostMarkup, expensesBillableType, expenses, expensesMarkup}} = this;
      return Math.round(
        (subconsultantCostBillable ? 1 : 0)*subconsultantCost*subconsultantCostMarkup
        + (isBillable(expensesBillableType) ? 1 : 0)*expenses*expensesMarkup
      )/100;
    },

    hoursEstimate() {
      const {netFees, markupFees, form: {hourlyRateEstimate}} = this;
      return hourlyRateEstimate ? Math.trunc((netFees + markupFees)/hourlyRateEstimate) : 0;
    },
  },

  methods: {
    getInput() {
      const {form, netFees, markupFees, hoursEstimate} = this;
      return {...form, netFees, markupFees, hoursEstimate};
    },
  },
}
</script>
