<template>
  <VRow>
    <VCol cols="12">
      <KTextField
        v-model="form.comment"
        :validator="$v.form.comment"
        label="models.projectRecord.sales.comment"
        hint="forms.projectRecord.sales.comment_hint"
      />
    </VCol>

    <VCol cols="12" sm="4" lg="2">
      <KCheckbox
        v-model="form.isCompetitive"
        label="models.projectRecord.sales.isCompetitive"
      />
    </VCol>

    <VCol cols="12" sm="8" lg="6">
      <KSlider
        v-model="form.awardProbability"
        :validator="$v.form.awardProbability"
        label="models.projectRecord.sales.awardProbability"
        hint="forms.projectRecord.sales.awardProbability_hint"
        min="0"
        max="100"
        step="10"
        ticks="always"
        thumb-label
        show-field
        persistent-hint
      />
    </VCol>

    <VCol cols="12" lg="3" offset-lg="1">
      <KDatePicker
        v-model="form.awardDateEstimate"
        :validator="$v.form.awardDateEstimate"
        label="models.projectRecord.sales.awardDateEstimate"
        hint="forms.general.required"
        :min="awardDateEstimateMin"
        :max="awardDateEstimateMax"
      />
    </VCol>

    <VCol cols="12">
      <FormProjectRecordDeviceCount v-model="form.deviceCount" @validator="deviceCountValidator = $event"/>
    </VCol>

    <VCol cols="12">
      <FormProjectRecordFinancials :project-type="projectType" :optional="financialsOptional" v-model="form.financials" @validator="financialsValidator = $event"/>
    </VCol>
  </VRow>
</template>

<script>
import FormProjectRecordDeviceCount from "../elements/FormProjectRecordDeviceCount.vue";
import FormProjectRecordFinancials from "../elements/FormProjectRecordFinancials.vue";
import {KCheckbox, KSlider, KTextField, KDatePicker, scopedFormsMixin} from "forms/elements/index.js";
import {required, between, betweenLength, validatorIsValid, betweenDate} from "validators/index.js";
import {formatISODate} from "top/date-fns-format.js";
import {addYears} from "date-fns";

export default {
  mixins: [
    scopedFormsMixin,
  ],

  components: {
    KTextField,
    KCheckbox,
    KSlider,
    FormProjectRecordDeviceCount,
    FormProjectRecordFinancials,
    KDatePicker,
  },

  data: () => ({
    form: {
      isCompetitive: false,
      awardProbability: 50,
      comment: null,
      awardDateEstimate: null,
      deviceCount: null,
      financials: null,
    },
    deviceCountValidator: null,
    financialsValidator: null,
  }),

  props: {
    projectType: {
      type: String,
      required: true,
    },

    financialsOptional: Boolean,
  },

  validations() {
    const {awardDateEstimateMin, awardDateEstimateMax} = this;
    return {
      form: {
        awardProbability: {
          required,
          between: between(0, 100),
        },
        comment: {
          required,
          betweenLength: betweenLength(2, 250),
        },
        awardDateEstimate: {
          required,
          betweenDate: betweenDate(awardDateEstimateMin, awardDateEstimateMax),
        },
      },
      deviceCountValidator: {
        required,
        validatorIsValid,
      },
      financialsValidator: {
        required,
        validatorIsValid,
      },
    };
  },

  watch: {
    '$v.$dirty'(v) {
      if (v) {
        this.deviceCountValidator.$touch();
        this.financialsValidator.$touch();
      }
    },
  },

  computed: {
    awardDateEstimateMin() {
      return formatISODate(new Date(), 1);
    },

    awardDateEstimateMax() {
      return formatISODate(addYears(new Date(), 2));
    },
  },
}
</script>
