<template>
  <VSlider
    :disabled="disabled"
    :error-messages="errorMessages"
    :hint="hintParsed"
    :label="labelParsed"
    :loading="loading"
    :persistent-hint="localPersistentHint"
    v-model="localValue"
    v-bind="$attrs"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
    <template v-slot:append>
      <VTextField
        v-model="localValue"
        class="mt-0 pt-0"
        hide-details
        single-line
        type="number"
        style="width: 60px"
      />
    </template>
  </VSlider>
</template>

<script>
  import KFormElementMixin from './KFormElementMixin.js'

  export default {
    mixins: [
      KFormElementMixin,
    ],

    props: {
      value: {
        type: Boolean,
        required: true,
      },

      showField: Boolean,
    },

    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },
    },
  }
</script>
