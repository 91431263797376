<template>
  <VRow>
    <VCol cols="12">
      <VCard>
        <VCardTitle v-t="'views.recoverPassword.title'"/>
        <VCardText>
          <FormPasswordRecover @success="handleSuccess" :token="token"/>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
  import FormPasswordRecover from "./FormPasswordRecover.vue";

  export default {
    components: {
      FormPasswordRecover,
    },

    props: {
      token: String,
    },

    methods: {
      handleSuccess({data: {updateForgottenPassword: {status, message}}}) {
        if (status === 'PASSWORD_UPDATED') {
          this.$store.dispatch('flash/info', message);
          const redirect = this.$route.query.redirect;
          if (redirect) {
            this.$router.push(redirect);
          } else {
            this.$router.push({name: 'signin'});
          }
        } else { // HTTP response success but server returned 'rejected' response
          this.$store.dispatch('flash/error', message);
        }
      },

      handleError(response) {
        this.$store.dispatch('flash/error', 'auth.failed');
      },
    },
  }
</script>
