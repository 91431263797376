<template>
  <VLayout
      row
      justify-center
      ma-4
  >
    <VFlex
        shrink
    >
      <VProgressCircular
          color="grey"
          :size="size"
          indeterminate
      />
    </VFlex>
  </VLayout>
</template>

<script>
  export default {
    props: {
      size: {
        type: [Number, String],
        default: 64,
      },
    },
  }
</script>
