<template>
  <VContainer fluid>
    <VRow>
      <VCol cols="12">
        <ListBrowser :title="{path: 'models.activityExport.self', choice: 2}" query="ActivityExport.Browse.List">
          <template v-slot:default="{onCreate}">
            <VCard class="mb-4" color="blue lighten-5">
              <VCardTitle>
                {{formMsg}}
              </VCardTitle>
              <VCardText>
                <FormActivityExport @success="onCreate"/>
              </VCardText>
            </VCard>
          </template>
          <template v-slot:filter="{setVariables, setIsFiltered, showFilter}">
            <ActivityExportFilter @input="setVariables" @filtered="setIsFiltered" :show-filter="showFilter"/>
          </template>
          <template v-slot:item="{item, onDelete}">
            <ActivityExportItem :id="item.id" :key="item.id" @delete="onDelete"/>
          </template>
        </ListBrowser>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
  import FormActivityExport from "./FormActivityExport.vue";
  import ActivityExportFilter from "./ActivityExportFilter.vue";
  import ActivityExportItem from "./ActivityExportItem.vue";
  import ListBrowser from "elements/list/ListBrowser.vue";

  export default {
    components: {
      ActivityExportFilter,
      ActivityExportItem,
      FormActivityExport,
      ListBrowser,
    },

    computed: {
      formMsg() {
        return this.$t('forms.messages.create', {type: this.$tc('models.activityExport.self', 1)});
      },
    },
  }
</script>
