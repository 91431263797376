<template>
  <VAppBar app color="indigo" dark clipped-left dense>
    <VBtn text tile class="d-flex align-center" width="3em" height="100%" @click="$router.push({name: 'top'})">
      <VImg src="/favicon.svg" max-width="3em" height="100%"/>
    </VBtn>
    <VToolbarTitle v-t="'ui.title'" @click="$router.push({name: 'top'})"/>

    <VSpacer/>

    <ToolbarTimekeeper v-if="showTimekeeper" class="mr-4"/>

    <VBtn :to="{name: 'signin'}" exact text v-if="!isSignedIn" v-t="'ui.signIn'"/>
    <VAppBarNavIcon v-show="isSignedIn" @click.stop="$emit('toggleRight')"/>
    <VBtn v-if="isSignedIn" @click.stop="$emit('toggleRight')" icon>
      <UserThumbnail size="md" :id="meId" disabled no-tooltip/>
    </VBtn>
  </VAppBar>
</template>

<script>
  import ToolbarTimekeeper from "top/views/timekeeper/ToolbarTimekeeper.vue";
  import UserThumbnail from "./UserThumbnail.vue";
  import {mapGetters} from 'vuex';

  export default {
    components: {
      ToolbarTimekeeper,
      UserThumbnail,
    },

    apollo: {
      canUseTimekeeper() {
        return this.policyChecker({
          policy: 'timekeeper.use',
          skip: () => !this.isSignedIn,
        });
      },
    },

    computed: {
      ...mapGetters('session', ['isSignedIn', 'meId']),

      showTimekeeper() {
        return this.isSignedIn && !this.$apollo.loading && this.canUseTimekeeper;
      },
    },
  }
</script>
