<template>
  <div>
    <VBtn
      v-bind="$attrs"
      :loading="$apollo.loading"
      @click="showDialog = !showDialog"
    >
      <slot>
        <FaI :icon="icon"/>
      </slot>
    </VBtn>
    <MediaDialog :id="id" v-model="showDialog"/>
  </div>
</template>

<script>
  import Queries from "queries/index.js";
  import MediaDialog from "./MediaDialog.vue";

  export default {
    inheritAttrs: false,

    components: {
      MediaDialog,
    },

    data: () => ({
      showDialog: false,
    }),

    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      disabled: Boolean,
    },

    apollo: {
      base: {
        query: Queries.Media.Single.MediaListBase,
        variables() {
          return {
            id: this.id,
          };
        },
        update: ({media}) => media,
      },
    },

    computed: {
      icon() {
        return Boolean(this.base?.allowsConversion) ? 'eye': 'download';
      },
    },
  }
</script>
