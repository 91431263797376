<template>
  <VSkeletonLoader type="article" v-if="$apollo.loading"/>
  <form v-else @submit.prevent="doSubmit" @keyup.enter="doSubmit" autocomplete="off">
    <KTextField
      :value="form.email"
      @input="debouncedEmailInput"
      :validator="$v.form.email"
      type="email"
      label="models.user.email"
      hint="forms.user.email_helpblock"
      :loading="$v.form.email.$pending"
    >
      <template #append v-if="!$v.form.email.$invalid">
        <FaI icon="check" color="green"/>
      </template>
    </KTextField>

    <span v-t="'forms.user.password_help'"/>

    <KTextField
      v-model="form.name"
      :validator="$v.form.name"
      label="models.user.fullName"
      hint="forms.user.fullName_helpblock"
    />

    <KTextField
      v-model="form.title"
      :validator="$v.form.title"
      label="models.user.title"
      hint="forms.general.optional"
    />

    <VRadioGroup v-model="form.locale" row :label="$t('models.user.settings.locale')">
      <VRadio v-for="{label, value} in localeItems" :label="label" :key="value" :value="value"/>
    </VRadioGroup>

    <VCard>
      <VCardTitle>
        <span v-t="'forms.user.role_title'"/>: {{staffRole.displayName}}
      </VCardTitle>
      <VCardSubtitle v-t="'forms.user.role_subtitle'"/>
      <VCardText>
        <KSelect
          v-model="form.roles"
          :items="rolesFiltered"
          label="forms.user.roles_title"
          hint="forms.user.roles_hint"
          item-text="displayName"
          item-value="name"
          chips
          multiple
          clearable
        />

        <KTextField
          v-model.number="form.staff.employeeId"
          :validator="$v.form.staff.employeeId"
          label="models.user.settings.employeeId"
          hint="forms.user.employeeId_hint"
          type="number"
        />

        <KCurrencyField
          v-model="form.staff.billingRate"
          :validator="$v.form.staff.billingRate"
          label="Billing rate"
          hint="forms.general.required"
          type="number"
        />

        <VCard>
          <VCardTitle>
            Home Office
          </VCardTitle>

          <VCardText>
            <span>Additional roles within the home office or across multiple offices may be assigned later.</span>
            <KApiSelect
              v-model="form.staff.officeId"
              :validator="$v.form.staff.officeId"
              model="office"
              hint="forms.general.required"
            />

            <KApiSelect
              v-model="form.staff.officeRole"
              :validator="$v.form.staff.officeRole"
              model="role"
              item-text="displayName"
              item-value="name"
              hint="forms.general.required"
              :variables="{context: 'OFFICES'}"
            />
          </VCardText>
        </VCard>
      </VCardText>
    </VCard>

<!--    <FormContact-->
<!--      v-model="form.contact"-->
<!--      :email-disable="true"-->
<!--      class="mt-3"-->
<!--    />-->

    <KBtnSubmit :mode="mode" class="mt-3"/>
  </form>
</template>

<script>
  import FormMixin from 'forms/elements/FormMixin.js'
  import Queries from 'queries/index.js';
  import {KCheckbox, KApiSelect, KSuggest, KSelect, KBtnSubmit, KTextField} from "forms/elements/index.js"
  import {betweenLength, required, email, maxLength, password, sameAs, primaryKey, between, unique} from 'validators/index.js'
  import KCurrencyField from "forms/elements/KCurrencyField.vue";
  import {formatISODate} from "top/date-fns-format.js"
  import {startOfMonth, subMonths} from "date-fns";
  import {debounce} from "lodash-es";

  const locales = [
    'en-CA',
    'fr-CA',
  ];

  export default {
    mixins: [
      FormMixin,
    ],

    components: {
      KCurrencyField,
      KApiSelect,
      KCheckbox,
      KSelect,
      KSuggest,
      KBtnSubmit,
      KTextField,
    },

    data: () => ({
      companyTypeId: '1',
      form: {
        name: '',
        email: '',
        title: '',
        // isStaff: true,
        // password: '',
        // passwordConfirm: '',
        companyId: '1',
        // employeeId: '',
        // contact: {...formDefault},
        locale: 'en-CA',
        staff: {
          employeeId: '',
          billingRate: null,
          officeId: undefined,
          officeRole: undefined,
          // supervisorId: undefined,
        },
        roles: [],
      },
    }),

    apollo: {
      // companyTypes: {
      //   query: Queries.CompanyType.Select,
      //   skip() {
      //     return this.isUpdate;
      //   }
      // },
      // company: {
      //   query: Queries.Company.Forms.Populate,
      //   skip() {
      //     return !this.isUpdate;
      //   }
      // },
      // offices: {
      //   query: Queries.Office.Select,
      // },
      // user: {
      //   query: Queries.User.Write.FormFill,
      //   variables() {
      //     return {
      //       id: this.target,
      //     };
      //   },
      //   skip() {
      //     return !this.isUpdate;
      //   },
      // },
      roles: {
        query: Queries.Role.Forms.Select,
        variables() {
          return {
            context: null,
          };
        },
      },
    },

    validations: {
      form: {
        name: {
          required,
          betweenLength: betweenLength(4, 128),
        },
        email: {
          required,
          email,
          unique: unique(Queries.User.Forms.UniqueTest, 'email'),
        },
        title: {
          betweenLength: betweenLength(2, 64),
        },
        locale: {
          required,
        },
        staff: {
          employeeId: {
            required,
            primaryKey,
          },
          billingRate: {
            required,
            between: between(10, 9999.99),
          },
          officeId: {
            required,
            primaryKey,
          },
          officeRole: {
            required,
          },
        },
      },
    },

    companyTypeId(v) {
      this.form.companyId = undefined;
    },

    computed: {
      // companyVariables() {
      //   return {
      //     companyType: this.companyTypeId,
      //     first: 10,
      //   };
      // },

      staffRole() {
        return this.roles ? this.roles.find((role) => role.name === 'staff') : null;
      },

      rolesFiltered() {
        const exclusions = ['staff', 'client', 'contractor'];
        return this.roles ? this.roles.filter((role) => !exclusions.includes(role.name)) : [];
      },

      localeItems() {
        return locales.map((value) => ({
          label: this.$t(`locales.${value}`),
          value,
        }));
      },
    },

    methods: {
      debouncedEmailInput: debounce(
        function (v) {
          return this.form.email = v;
        }, 500, {maxWait: 2000}
      ),

      async createAction() {
        const {form: {email, name, title, companyId, roles, locale, staff: {employeeId, billingRate, officeId, officeRole}}} = this;

          //{location, phones} = contact,
        const variables = {
          email,
          name,
          employeeId,
          billingRate,
          billingRateStartAt: formatISODate(subMonths(startOfMonth(new Date()), 1)),
          roles: ['staff', ...roles],
          officeId,
          officeRole,
          locale,
            ...title && {title},
            ...(companyId && {companyId}),
            // ...phones && {phones: JSON.stringify(phones)},
            // ...(location.cityId && location),
          },
          {data: { userCreate: response}} = await this.$apollo.mutate({
            mutation: Queries.User.Write.Create,
            variables,
          });
        return response;
      },

      // async updateAction() {
      //   const {form} = this,
      //     {contact, name, title, companyId, employeeId} = form,
      //     //{location, phones} = contact,
      //     variables = {
      //       id: this.user.id,
      //       name,
      //       title: title || null,
      //       ...employeeId && {employeeId},
      //       // ...(companyId && {companyId}),
      //       // ...phones && {phones: JSON.stringify(phones)},
      //       // ...(location.cityId && location),
      //     },
      //     {data: { userUpdate: response}} = await this.$apollo.mutate({
      //       mutation: Queries.User.Write.Update,
      //       variables,
      //     });
      //   return response;
      // },
    },
  }
</script>
