<template>
  <VListItem two-line>
    <VListItemContent>
      <VListItemTitle>
        {{firstPartition.project.name}}
        <small class="text">
          ({{firstPartition.project.id}})
        </small>
        - <strong>
        {{featureWeight}}%
      </strong>
        <VDialog max-width="600">
          <template #activator="{on, attrs, value}">
            <VChip small color="blue lighten-3" class="ml-1" v-on="on" v-bind="attrs" :input-value="value">
              +{{othersCount}}
              <FaI :icon="['far', 'info-circle']" class="ml-1"/>
            </VChip>
          </template>
          <template #default="dialog">
            <PartitionsSetPickerDialog :id="partitionsSet.id" @cancel="dialog.value = false"/>
          </template>
        </VDialog>
        <FaI v-if="isBillable" icon="file-invoice-dollar" class="ml-2 orange--text text--darken-2"/>
      </VListItemTitle>
      <VListItemSubtitle v-if="partitionsSet.description">
        <em>
          {{partitionsSet.description}}
        </em>
      </VListItemSubtitle>
    </VListItemContent>
  </VListItem>
</template>

<script>
import PartitionsSetPickerDialog from './PartitionsSetPickerDialog.vue';
import Queries from 'queries/index.js';

const contexts = ['timeslip', 'expense'];

export default {
  components: {
    PartitionsSetPickerDialog,
  },

  props: {
    context: {
      type: String,
      validator: (v) => contexts.includes(v),
    },

    id: {
      type: [Number, String],
      required: true,
    },
  },

  apollo: {
    partitionsSet: {
      query: Queries.PartitionsSet.Single.Main,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },

  computed: {
    firstPartition() {
      return this.partitionsSet.partitions[0];
    },

    featureWeight() {
      const target = this.firstPartition.weight;
      return this.totalWeight ? Math.round(target/this.totalWeight*100) : 100;
    },

    totalWeight() {
      return this.partitionsSet.partitions.reduce((accum, current) => accum + current.weight, 0);
    },

    isBillable() {
      return this.partitionsSet.partitions.some(this.isBillableTestMethod);
    },

    othersCount() {
      return (this.partitionsSet.partitions.length - 1) ?? 0;
    },
  },

  methods: {
    isBillableTestMethod(partition) {
      const {context} = this;
      return ((!context || context === 'timeslip') && Boolean(partition.isTimeslipBillable))
        || ((!context || context === 'expense') && Boolean(partition.isExpenseBillable));
    },
  },
}
</script>
