<template>
  <div>
    <VNavigationDrawer v-model="showDrawer" :permanent="!isMobile" :absolute="isMobile" :app="!isMobile" :clipped="!isMobile" color="grey lighten-3">
      <VList nav>
        <VListItem two-line>
          <VListItemContent>
            <VListItemTitle class="text-h5" v-t="'ui.admin'"/>
          </VListItemContent>
        </VListItem>

        <VDivider/>

        <VListItem v-if="canAccountingExport" :to="{name: 'admin.export'}" v-t="'views.admin.menu.export'" exact/>

        <VListItem v-if="canProjectRecords" :to="{name: 'admin.projectRecords'}" v-t="{path: 'models.projectRecord.self', choice: 2}" exact/>

        <VListItem v-if="canAnalytics" :to="{name: 'admin.analytics'}" v-t="'views.admin.menu.analytics'" exact/>
      </VList>
    </VNavigationDrawer>
    <div>
      <VToolbar dense v-if="isMobile" color="grey lighten-3">
        <VAppBarNavIcon @click="drawerActivated = !drawerActivated"/>
        <VToolbarTitle v-t="'ui.admin'"/>
      </VToolbar>
      <RouterView/>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawerActivated: false,
  }),

  apollo: {
    canAccountingExport() {
      return this.policyChecker({
        policy: 'adminTools.accountingExport',
      });
    },

    canProjectRecords() {
      return this.policyChecker({
        policy: 'adminTools.projectRecords',
      });
    },

    canAnalytics() {
      return this.policyChecker({
        policy: 'adminTools.analytics',
      });
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    showDrawer: {
      set(v) {
        this.drawerActivated = v;
      },
      get() {
        return !this.isMobile || this.drawerActivated;
      },
    },
  },

  watch: {
    isMobile(v) {
      this.drawerActivated = false;
    },
  },
}
</script>
