import gql from 'graphql-tag';

// Single

const Main = gql`
  query(
    $id: ID!
  ) {
    invoiceLine(
      id: $id
    ) {
      id
      description
      amount
    }
  }
`;


const Single = {
  Main,
};

export default {
  Single,
};
