<template>
  <NowLoading v-if="$apollo.loading"/>
  <div v-else>
    <VToolbar dense color="grey lighten-3">
      <VToolbarTitle v-t="{path: 'models.invoice.self', choice: 2}"/>
      <VSpacer/>
      <VBtn @click="toggleFilter" text>
        <FaI size="2x" icon="chevron-down" :rotation="showFilter ? 180 : 0" style="transition: all 0.3s ease;"/>
        <div>
          <VFadeTransition leave-absolute>
            <FaI size="2x" key="a" v-if="isFiltered" icon="filter"/>
            <FaI size="2x" key="b" v-else :icon="['far', 'filter']"/>
          </VFadeTransition>
        </div>
      </VBtn>
      <template v-slot:extension>
        <VTabs v-model="tab" icons-and-text centered show-arrows>
          <VTab>
            <span v-t="'models.staff.self'"/>
            <FaI icon="users" size="lg"/>
          </VTab>
          <VTab>
            <span v-t="{path: 'models.project.self', choice: 2}"/>
            <FaI icon="briefcase" size="lg"/>
          </VTab>
        </VTabs>
      </template>
    </VToolbar>

    <VTabsItems :value="tab" touchless>
      <VTabItem key="staff" :value="0">
        <InvoiceBrowseTab
          :filter-disables="{userOffice: true}"
          :filter-defaults="{userOffice: id}"
          :show-filter="showFilter"
          @filtered="setIsFiltered"
        />
      </VTabItem>
      <VTabItem key="projects" :value="1">
        <InvoiceBrowseTab
          :filter-disables="{projectOffice: true}"
          :filter-defaults="{projectOffice: id}"
          :show-filter="showFilter"
          @filtered="setIsFiltered"
        />
      </VTabItem>
    </VTabsItems>
  </div>
</template>

<script>
import InvoiceBrowseTab from "../../invoices/browse/elements/InvoiceBrowseTab.vue";

export default {
  components: {
    InvoiceBrowseTab,
  },

  data: () => ({
    showFilter: false,
    isFiltered: false,
    tab: 0,
  }),

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    setIsFiltered(v) {
      this.isFiltered = v;
    },
  },
}
</script>
