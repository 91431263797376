import gql from "graphql-tag";

const PaginatorFragment = gql`
  fragment standardPaginator on SimplePaginatorInfo {
    hasMorePages
    lastPage @client
  }
`;

export default PaginatorFragment;
