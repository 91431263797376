<template>
  <VCard v-if="projectTypeCode">
    <VCardTitle v-t="'models.projectRecord.financials.self'"/>
    <VCardSubtitle v-if="!optional" v-t="'forms.general.required'"/>
    <VCardText>
      <VAlert v-if="optional" type="info">
        <span v-t="'forms.projectRecord.financials.optional'"/>
      </VAlert>
      <FormProjectRecordFinancialsClosedScope :value="parsedValue" @input="onInput" @validator="$emit('validator', $event)" :optional="optional"/>
    </VCardText>
  </VCard>
</template>

<script>
import FormProjectRecordFinancialsClosedScope from "./FormProjectRecordFinancialsClosedScope.vue";

const projectTypeCodes = {
  '1': 'inspection',
  '2': 'newDesign',
  '4': 'e2m',
  '6': 'modernization',
  '7': 'legal',
  '10': 'consulting',
};

export default {
  components: {
    FormProjectRecordFinancialsClosedScope,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },

    projectType: {
      type: String,
      required: true,
    },

    optional: Boolean,
  },

  computed: {
    projectTypeCode() {
      return projectTypeCodes[this.projectType] ?? undefined;
    },

    parsedValue() {
      return this.value ? Object.values(this.value)?.[0] : {};
    },
  },

  watch: {
    projectTypeCode: {
      handler(v) {
        if (!v) {
          this.$emit('input', {});
          this.$emit('validator', null);
        }
      },
      immediate: true,
    }
  },

  methods: {
    onInput(e) {
      this.$emit('input', {[this.projectTypeCode]: e});
    },
  },
}
</script>
