<template>
  <ExpandPanelBrowser hide-toolbar query="Invoice.Browse.List">
    <template v-slot:filter="{setVariables}">
      <InvoicesFilter
        :defaults="filterDefaults"
        :disables="filterDisables"
        :order-desc-default="orderDescDefault"
        :show-filter="showFilter"
        :sort-default="sortDefault"
        @filtered="setIsFiltered"
        @input="setVariables"
      />
    </template>
    <template v-slot:default="{data, isLoading}">
      <slot
        :data="data"
        :is-loading="isLoading"
      />
    </template>
    <template v-slot:item="{item}">
      <InvoiceListItem :id="item.id" :key="item.id"/>
    </template>
  </ExpandPanelBrowser>
</template>

<script>
import ExpandPanelBrowser from "elements/list/ExpandPanelBrowser.vue";
import InvoicesFilter from "./InvoicesFilter.vue";
import InvoiceListItem from "./InvoiceListItem.vue";

export default {
  props: {
    filterDisables: Object,

    filterDefaults: {
      type: Object,
      default: () => ({
        transactionDate: undefined,
        dueDate: undefined,
        client: undefined,
        project: undefined,
        projectOffice: undefined,
        userOffice: undefined,
        user: undefined,
        isPaid: undefined,
        isOverdue: false,
      }),
    },

    sortDefault: {
      type: String,
      default: 'CREATED_AT',
    },

    orderDescDefault: {
      type: Boolean,
      default: true,
    },

    showFilter: Boolean,

    simple: Boolean,
  },

  components: {
    InvoicesFilter,
    InvoiceListItem,
    ExpandPanelBrowser,
  },

  methods: {
    setIsFiltered(v) {
      this.$emit('filtered', v);
    },
  },
}
</script>
