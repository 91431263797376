export default {
    "en": {
        "routes": {
            "office": {
                "browse": {
                    "title": "Coast-to-coast",
                    "subtitle": "As the only elevating devices consulting firm with a cross-country presence, we are always nearby."
                }
            }
        },
        "actions": {
            "action": "Action | Actions",
            "accept": "Accept",
            "accepted": "Accepted",
            "add": "Add",
            "assign": "Assign",
            "assigned": "Assigned",
            "attach": "Attach",
            "back": "Back",
            "begin": "Begin",
            "browse": "Browse",
            "cancel": "Cancel",
            "clear": "Clear",
            "close": "Close",
            "closed": "Closed",
            "collapse": "Collapse",
            "commit": "Commit",
            "complete": "Complete",
            "confirm": "Confirm",
            "confirmPrompt": "Are you sure?",
            "connect": "Connect",
            "connected": "Connected",
            "create": "Create",
            "created": "Created",
            "delete": "Delete",
            "deleted": "deleted",
            "deletingItems": "Deleting {count} {label}",
            "detach": "Detach",
            "download": "Download",
            "edit": "Edit",
            "edited": "Edited",
            "enable": "Enable",
            "enabled": "Enabled",
            "end": "End",
            "expand": "Expand",
            "export": "Export",
            "filter": "Filter",
            "finish": "Finish",
            "fix": "Fix",
            "fixNow": "Fix now",
            "help": "Help",
            "import": "Import",
            "interact": "Interact",
            "join": "Join",
            "leave": "Leave",
            "manage": "Manage",
            "new": "New",
            "next": "Next",
            "open": "Open",
            "opened": "Opened",
            "perform": "Perform",
            "purge": "Purge",
            "read": "Read",
            "refresh": "Refresh",
            "remove": "Remove",
            "reset": "Reset",
            "review": "Review",
            "revoke": "Revoke",
            "save": "Save",
            "search": "Search",
            "select": "Select",
            "selectMultiple": "Select multiple",
            "send": "Send",
            "share": "Share",
            "submit": "Submit",
            "submitted": "Submitted",
            "subscribe": "Subscribe",
            "subscribed": "Subscribed",
            "undo": "Undo",
            "unsubscribe": "Unsubscribe",
            "unsubscribed": "Unsubscribed",
            "update": "Update",
            "updated": "Updated",
            "userCommented": "{who} commented {when}",
            "userCreated": "{who} created this {when}",
            "userSubmitted": "{who} submitted this {when}",
            "userUpdated": "{who} updated this {when}",
            "view": "View"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "contains_any": "The {attribute} must include one of the following: {values}",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "multiple_of": "The {attribute} must be a multiple of {value}",
            "not_in": "The selected {attribute} is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": "The provided password is incorrect.",
            "phone": "The {attribute} field must be a valid phone number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "unique_with": "The {attribute} has already been taken with {values}.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "passwords": {
            "reset": "Your password has been changed. Please sign in with your new password.",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        },
        "forms": {
            "general": {
                "apiError": "An API error occurred. Please report to an administrator.",
                "autoIncrements": "Optional, will be automatically numerically incremented when blank",
                "cannotChange": "Cannot be changed",
                "error": "The form has errors. Please review and try again.",
                "future": "Not available, will be supported in the near future",
                "maxSize": "Maximum size {size}",
                "optional": "Optional",
                "recommended": "Recommended",
                "required": "Required",
                "searchPlaceholder": "Start typing to search",
                "record": "Status",
                "submit": "Submit",
                "submitError": "An unexpected error occurred. Please review your browser's console and report to an administrator if the issue persists.",
                "submitted": "Submitted",
                "success": "Success",
                "update": "Update",
                "whenKnown": "Optional, provide when known"
            },
            "messages": {
                "add": "Add {type}",
                "create": "Create {type}",
                "createNew": "Create New {type}",
                "edit": "Edit {type}",
                "modelCreated": "New {type} '{name}' created successfully",
                "modelUpdated": "{type} '{name}' updated successfully",
                "modelDeleted": "{type} '{name}' deleted successfully",
                "modelAttached": "Item(s) have been added to {type} '{name}' successfully.",
                "modelDetached": "Item(s) have been detached from {type} '{name}' successfully.",
                "modelSubscribed": "{type} have been subscribed to {parentType} '{parentName}'",
                "modelUnsubscribed": "{type} have been unsubscribed from {parentType} '{parentName}'",
                "parsed": "Parsed values",
                "passwordUpdated": "Your passphrase has been changed. Please use your new passphrase next time you sign in.",
                "new": "New {type}",
                "no": "No {type}",
                "remove": "Remove {type}",
                "submitNew": "Submit new {type}",
                "update": "Update: {type}"
            },
            "controls": {
                "asc": "Asc",
                "ascending": "Ascending",
                "desc": "Desc",
                "descending": "descending",
                "order": "Order",
                "orderBy": "Order By",
                "sort": "Sort",
                "sortBy": "Sort By"
            },
            "mediaLibraryCollection": {
                "fileTypeNotAllowed": "You must upload a file of type",
                "tooLarge": "File too large, max",
                "tooSmall": "File too small, min",
                "tryAgain": "Please try uploading this file again",
                "somethingWentWrong": "Something went wrong while uploading this file",
                "selectOrDrag": "Select or drag files",
                "selectOrDragMax": "Select or drag max {maxItems} {file}",
                "file": {
                    "singular": "file",
                    "plural": "files"
                },
                "anyImage": "any image",
                "anyVideo": "any video",
                "goBack": "Go back",
                "dropFile": "Drop file to upload",
                "dragHere": "Drag file here",
                "remove": "Remove",
                "download": "Download"
            },
            "location": {
                "countryDivision_helpblock": "Auto-populates, or specify to narrow City choices",
                "street_helpblock": "Optional when not known but otherwise recommended",
                "postalCode_helpblock": "Optional, if left blank will be auto-populated when possible",
                "submitError": "An error occurred during Address record creation. The parent model has been created and the Address may be populated on the parent's page at a later time."
            },
            "activityPartition": {
                "addPartition": "Add Project",
                "removePartition": "Remove Project",
                "createTemplate": "Create Template",
                "billableCheckbox_dialog": "Project's billings preference",
                "billableType": {
                    "always": {
                        "name": "Always",
                        "description": "Forced to selected.",
                        "hint": "This project requires all entries to be billable."
                    },
                    "usually": {
                        "name": "Usually",
                        "description": "Defaults to selected but can be changed.",
                        "hint": "Entries are usually but not always billable on this project."
                    },
                    "neutral": {
                        "name": "No preference",
                        "description": "No particular choice will be suggested.",
                        "hint": "No preference is ex"
                    },
                    "sometimes": {
                        "name": "Sometimes",
                        "description": "Defaults to cleared but can be changed.",
                        "hint": "Entries are most often not billable on this project."
                    },
                    "never": {
                        "name": "Never",
                        "description": "Forced to cleared.",
                        "hint": "This project disallows billable entries."
                    }
                }
            },
            "building": {
                "address_helpblock": "Provide the full address when known, otherwise the City is required as a minimum",
                "name_helpblock": "Required except when a street address is provided below, in which case this field is optional"
            },
            "changePassword": {
                "password_title": "Current password",
                "newPassword_title": "New password",
                "newPasswordConfirmation_title": "Confirm new password",
                "twoFactor_title": "Two-factor authentication token",
                "twoFactor_helpblock": "Required for identity confirmation.",
                "submit_button": "Change password"
            },
            "company": {
                "address_helpblock": "Provide corporate headquarters address when known, otherwise leave blank",
                "nameShort_helpblock": "Optional, leave blank for none"
            },
            "deviceGroup": {
                "devices_helpblock": "Optional but recommended to initialize the Device Group with its member Devices",
                "designation_helpblock": "Required to create Devices, comma-separated or hyphen-separated lists will be interpreted to generate multiple Devices, maximum {count} at once",
                "designation_tooMany": "Maximum {count} Devices may be created at once",
                "licence_helpblock": "Optional, comma-separated or hyphen-separated lists will be interpreted to generate multiple Licence IDs, when included the quality must match Designation quantity",
                "licence_count": "Exactly {count} Licence IDs required to match Designation field"
            },
            "expense": {
                "balance": "Remaining Balance",
                "by_value": "By value",
                "percentage_title": "Percentage",
                "receipt_title": "Attach Receipt",
                "receipt_current": "Current",
                "receipt_detaching": "Will be deleted",
                "receipt_help": "An expense record may be submitted without attaching a receipt. In most cases a receipt record must be attached promptly in order to be eligible for reimbursement. Small transactions for which it is common to not receive a receipt, such as transit fare, are usually exempted from this requirement. Some clients may impose very strict receipt requirements for every claim regardless of value, in which case a receipt record must always be provided.",
                "receipt_alert": "Please attach a receipt after creating this expense record.",
                "reimbursable_help": "Expenses for which an employee paid should be marked as \"reimbursable\" for the employee to receive compensation. If the Company paid the expense directly and the expense is being booked to schedule billings then the \"reimbursable\" box should be cleared."
            },
            "inspectionDevice": {
                "state": {
                    "none": "Unavailable",
                    "project": "Available",
                    "inspection": "Member"
                },
                "allDevices": {
                    "yes": "Add available Devices to Inspection",
                    "no": "No available Devices"
                }
            },
            "inspectionPerformance": {
                "SpeedUp": {
                    "title": "Speed Up",
                    "units": {
                        "m": "m/s",
                        "i": "fpm"
                    }
                },
                "SpeedDown": {
                    "title": "Speed Down",
                    "units": {
                        "m": "m/s",
                        "i": "fpm"
                    }
                },
                "OperatingUpTime": {
                    "title": "Operating Up Time",
                    "units": "sec"
                },
                "OperatingDownTime": {
                    "title": "Operating Down Time",
                    "units": "sec"
                },
                "DoorOpenTime": {
                    "title": "Door Open Time",
                    "units": "sec"
                },
                "DoorCloseTime": {
                    "title": "Door Close Time",
                    "units": "sec"
                },
                "CarCallDwellTime": {
                    "title": "Car Call Dwell Time",
                    "units": "sec"
                },
                "HallCallDwellTime": {
                    "title": "Hall Call Dwell Time",
                    "units": "sec"
                },
                "NudgingTime": {
                    "title": "Nudging Time",
                    "units": "sec"
                },
                "OperatingNoise": {
                    "title": "Operating Noise",
                    "units": "dBA"
                },
                "FanNoise": {
                    "title": "Fan Noise",
                    "units": "dBA"
                },
                "DoorNoise": {
                    "title": "Door Noise",
                    "units": "dBA"
                },
                "LevelingAccuracy": {
                    "title": "Leveling Accuracy",
                    "units": {
                        "m": "mm",
                        "i": "in"
                    }
                },
                "DoorForce": {
                    "title": "Door Force",
                    "units": {
                        "m": "N",
                        "i": "lbs-f"
                    }
                }
            },
            "login": {
                "forgotPassword_button": "Forgot Password",
                "submit_button": "Login",
                "remember_title": "Remember Me",
                "twoFactor_helpblock": "Required only if two-factor authentication is enabled on your account"
            },
            "oauthClient": {
                "none": "You have not created any OAuth clients."
            },
            "oauthPersonalAccess": {
                "none": "You have not created any personal access tokens.",
                "tokenMessage": "Here is your new personal access token. You may now use this token to make API requests. This is the only time it will be shown. Please immediately copy this access token in to your application, test functionality and then discard."
            },
            "office": {
                "code_helpblock": "Required, use three-character code from UN/LOCODE"
            },
            "passwordChange": {
                "title": "Change Passphrase"
            },
            "passwordRequest": {
                "submit_button": "Send Passphrase Reset Link"
            },
            "passwordReset": {
                "oldPassword_title": "Current Passphrase",
                "password_title": "New Passphrase",
                "passwordConfirmation_title": "Confirm New Passphrase",
                "token_title": "Token",
                "submit_button": "Reset Passphrase"
            },
            "profile": {
                "fullName_helpblock": "Required, provide your full name and title just as you would like it to appear on formal letterhead. This will be parsed apart automatically.",
                "address_helpblock": "Please maintain your contact info as up-to-date.",
                "submit_button": "Update Profile"
            },
            "project": {
                "steps": {
                    "general": "Project",
                    "roles": "Roles",
                    "status": "Status",
                    "review": "Review"
                },
                "searchScope": {
                    "title": "Search scope",
                    "all": "All",
                    "member": "Membership",
                    "help": {
                        "description": "Use this to optionally constrain the projects search scope.",
                        "selection": "Currently selected",
                        "all": "All projects",
                        "member": "Projects which you are a member of in any capacity"
                    }
                },
                "similarProjects": {
                    "title": "Similar Projects",
                    "help": "Please double-check that your new project does not already exist."
                },
                "step_error": "Please complete this section before moving on to the next.",
                "workRegion_hint": "The location of the property where site work will occur. Defaults to the office's location.",
                "isTaxExempt_hint": "Set this only when instructed by our Client.",
                "defaultRate_hint": "Optional. If set, billable timeslip records will default to this.",
                "isTimeslipBillable_hint": "Expectations for timeslip billings.",
                "isExpenseBillable_hint": "Expectations for expense billings.",
                "roleId_label": "Your Role in Project",
                "supervisorId_label": "Supervisor",
                "supervisorId_hint": "Optional but adhere to expectations of the Project's home office manager.",
                "roles_hint": "Additional team members may be added once the Project is created.",
                "client_label": "Client Company",
                "projectStatus_hint": "Cannot be changed here.",
                "projectStatus_hint_link": "Please submit a new Project Record to change the Status."
            },
            "projectBuilding": {
                "deleteNotAllowed": "Can't remove Building",
                "includeAllDevices_title": "Include all Devices in this Project",
                "submitBtn": "Add Building to Project",
                "success": "Building {building} added to Project {project}."
            },
            "projectDevice": {
                "state": {
                    "none": "Available",
                    "member": "Member"
                },
                "allDevices": {
                    "yes": "Add available Devices to Project",
                    "no": "No available Devices"
                },
                "building": {
                    "add": "Add Building to Project",
                    "empty": "This building has no devices assigned to it.",
                    "no": "No buildings have been added to this project yet."
                }
            },
            "projectDefaultTasks": {
                "tableHeader": "Default Task Types by Project Type"
            },
            "projectImport": {
                "projectDataImport": "KJA Project Data Spreadsheet for import",
                "scope": "Import scope",
                "scope_options": {
                    "1": "Existing Projects",
                    "2": "New Projects",
                    "3": "New and Existing Projects"
                },
                "selectAllUsers": "Select all users",
                "users": "Pull new projects for these users",
                "users_helpblock": "Required when pulling new Projects. Multiple selections are permitted."
            },
            "projectRecord": {
                "submittingFor": "Submitting for Project",
                "firstRecordHelp": "This is the first Record submission for this project. Please confirm that the Project Type is the best choice.",
                "financials": {
                    "optional": "For a new project, financial projections are optional and may be left blank for one week.",
                    "openScope": {
                        "feesAnnumEstimate_hint": "Required. Provide your best guess, precision is not as essential."
                    },
                    "closedScope": {
                        "scheduledFees_hint": "Expected fees to be assessed to the Client for services.",
                        "timeslipsBillableType_hint": "Determines default settings in forms.",
                        "expensesBillableType_hint": "Influences budgeting and determines default settings in forms.",
                        "subconsultantCost_hint": "The expected or estimated cost of subcontracted services, when applicable. Leave blank when none.",
                        "costMarkup_hint": "Leave this as the default unless you know for very good reason that you must adjust this for this project.",
                        "expenses_hint": "The expected or estimated cost of disbursements which will be incurred in service of this project. Leave blank when none.",
                        "netFees_hint": "Expected net billings for this project after deducting expected costs outside of internal labour.",
                        "markupFees_hint": "Expected additional billings attributed to markup on forwarded disbursements.",
                        "hoursEstimate_hint": "Budgeted time available to complete this project."
                    }
                },
                "active": {
                    "startDate_hint": "Required. Anticipated start of productive consulting work.",
                    "comment_hint": "Required. A very brief statement on the progress status.",
                    "interaction": {
                        "normal": "Normal",
                        "occasional": "Occasional",
                        "billings": "Billings"
                    },
                    "interaction_help": {
                        "heading": "This indicates how this project account is intended to be utilized within our company. This choice determines the frequency of activity reporting required.",
                        "normal": "Applies to most projects which require typical ongoing service.",
                        "occasional": "Interaction will be rare and brief, often with a year or more between activity. Such accounts are often a placeholder for intermittent billable work with key clients.",
                        "billings": "Entirely for the purpose of organizing recurring billings for a client. No timeslip activity may be booked against this account."
                    }
                },
                "complete": {
                    "clientReview_hint": "Date when client was invited to provide feedback on our performance.",
                    "marketingFollowUp_hint": "Date when client was solicited for potential additional work.",
                    "accountingFollowUp_hint": "Date when Accounting most recently reached out to client for any reason."
                },
                "hold": {
                    "comment_hint": "Required. Justification for the 'hold' status."
                },
                "internal": {
                    "hoursAnnumBudgeted_hint": "Estimate of the labour effort to be put toward this project over the next year."
                },
                "sales": {
                    "awardProbability_hint": "Required. Guess how likely it is for the project to be awarded.",
                    "comment_hint": "Required. A very brief statement on the status of sales effort."
                }
            },
            "timeEntry": {
                "billable_label": "Is billable?",
                "duration_helpblock": "Required only when the stop time is not provided"
            },
            "timeslip": {
                "durationMismatch": "The reported activity duration does not match the expected duration from the given start and stop times ({expectedDuration}). If submitted anyway, the form-submitted duration will be taken as correct.",
                "stopsNextDay": "This entry is split across two days. This is fine, but the full duration will be booked to {date}. You may optionally create separate time entries for each day.",
                "startAt_helpblock": "Optionally provide time bounds to automatically populate the duration.",
                "stopAt_helpblock": "Required with 'Start At'.",
                "duration_helpblock": "Required. Will be automatically determined from 'Start At' and 'Stop At' when provided.",
                "billingRate_helpblock": "Required. Defaults to your scheduled billing rate.",
                "billingRate_defaultLabel": "Default",
                "billingRate_setDefaultBtn": "Set default",
                "linkOffTooltip": "Unlink Duration from time bounds",
                "linkOnTooltip": "Sync Duration with time bounds",
                "timesOffTooltip": "Hide time bound fields",
                "timesOnTooltip": "Show time bound fields"
            },
            "timeZone": {
                "title": "Select Time Zone"
            },
            "twoFactorDisable": {
                "secret_title": "Token",
                "submit_button": "Disable Two-factor authentication"
            },
            "twoFactorEnable": {
                "secret_helpblock": "Required, obtained from authentication app",
                "secret_title": "Token",
                "submit_button": "Enable Two-factor authentication"
            },
            "user": {
                "email_helpblock": "Required, for security reasons once set this cannot be changed except by a system administrator",
                "fullName_helpblock": "Required, provide full form name as would be desired to appear on formal letterhead, and the person's name will be extracted from it.",
                "role_title": "Base role",
                "role_subtitle": "This is the only base role currently supported.",
                "roles_title": "Additional roles",
                "roles_hint": "Optional, these additional roles grant additional security permissions. Leave blank if you're unsure what this means.",
                "officeRoles_hint": "Additional roles within the home office or across multiple offices may be assigned later.",
                "password_help": "An email invitation will be sent to the above address as soon as this user account is created. Access to this email account is required to set a password and sign in.",
                "employeeId_hint": "Required, this is the company internal ID number assigned by Accounting."
            }
        },
        "models": {
            "general": {
                "self": "Model|Models",
                "item": "Item|Items",
                "id": "ID",
                "type": "Type",
                "createdAt": "Created",
                "updatedAt": "Updated"
            },
            "activity": {
                "self": "Activity"
            },
            "activityExport": {
                "self": "Activity Export|Activity Exports",
                "from": "From",
                "to": "To",
                "record": "Status",
                "retrievedAt": "Retrieved At",
                "createdBy": "Created By",
                "dateRange": "Date Range",
                "includeRetired": "Include Retired Projects"
            },
            "billingRate": {
                "self": "Billing Rate|Billing Rates",
                "startAt": "Start At",
                "endAt": "End At",
                "rate": "Rate"
            },
            "building": {
                "self": "Building|Buildings",
                "name": "Building Name"
            },
            "city": {
                "self": "City|Cities"
            },
            "contact": {
                "self": "Contact|Contacts",
                "email": "Email",
                "phones": {
                    "mobile": "Mobile",
                    "desk": "Desk",
                    "company": "Company",
                    "fax": "Fax",
                    "other": "Other"
                }
            },
            "company": {
                "self": "Company|Companies",
                "client": "Client|Clients",
                "contractor": "Contractor|Contractors",
                "name": "Full Name",
                "nameShort": "Shorthand / common name"
            },
            "companyType": {
                "self": "Company Type|Company Types",
                "alt": "Type|Types"
            },
            "country": {
                "self": "Country|Countries"
            },
            "countryDivision": {
                "self": "Country Division|Country Divisions"
            },
            "device": {
                "self": "Device|Devices",
                "designation": "Designation",
                "group": "Group",
                "licence": "Licence"
            },
            "deviceGroup": {
                "self": "Device Group|Device Groups",
                "name": "Name"
            },
            "deviceType": {
                "self": "Device Type|Devices Types"
            },
            "expense": {
                "self": "Expense|Expenses",
                "amount": "Amount",
                "date": "Date",
                "description": "Description",
                "isReimbursable": "Reimbursable",
                "notReimbursable": "Not Reimbursable",
                "receipt": "Receipt|Receipts"
            },
            "expensePartition": {
                "amount": "Portion",
                "weight": "Weight",
                "comment": "Comment",
                "isBillable": "Billable",
                "notBillable": "Not Billable"
            },
            "expenseType": {
                "self": "Expense Type|Expense Types",
                "shorthand": "Type|Types"
            },
            "inspection": {
                "self": "Inspection|Inspections",
                "name": "Inspection Name"
            },
            "inspectionArea": {
                "self": "Area|Areas"
            },
            "inspectionType": {
                "self": "Inspection Type|Inspection Types",
                "shorthand": "Type|Types"
            },
            "invoice": {
                "self": "Invoice|Invoices",
                "salesTaxCode": "Sales Tax Code",
                "transactionDate": "Transaction Date",
                "dueDate": "Due Date",
                "subtotal": "Subtotal",
                "salesTaxPercentage": "Sales Tax Percentage",
                "salesTaxTotal": "Sales Tax",
                "balance": "Balance",
                "isPaid": "Paid",
                "isOverdue": "Overdue",
                "notPaid": "Not Paid",
                "paidDate": "Date Paid",
                "template": "Template",
                "message": "Message",
                "tax": "Tax"
            },
            "invoiceLine": {
                "description": "Description",
                "amount": "Amount",
                "item": "Item",
                "class": "Class",
                "salesTaxCode": "Sales Tax Code"
            },
            "issue": {
                "self": "Issue|Issues",
                "subject": "Subject"
            },
            "issueComment": {
                "self": "Issue Comment|Issue Comments",
                "shorthand": "Comment|Comments",
                "comment": "Comment"
            },
            "issueEvent": {
                "self": "Issue Event|Issue Events",
                "shorthand": "Event|Events"
            },
            "issueEventType": {
                "self": "Issue Event Type|Issue Event Types",
                "shorthand": "Event Type|Event Types"
            },
            "location": {
                "self": "Location|Locations",
                "address": "Address",
                "street": "Street",
                "streetExtra": "Street Extra Line",
                "postalCode": "Postal Code"
            },
            "oauthAuthorizedClient": {
                "self": "Authorized Client|Authorized Clients",
                "secret": "Secret"
            },
            "oauthClient": {
                "self": "OAuth Client|OAuth Clients",
                "name": "Name",
                "redirect": "Redirect URL",
                "secret": "Secret"
            },
            "oauthPersonalAccess": {
                "self": "Personal Access Token|Personal Access Tokens",
                "shorthand": "Token|Tokens",
                "name": "Name",
                "scopes": "Scopes",
                "secret": "Secret"
            },
            "office": {
                "self": "Office|Offices",
                "name": "Office Name",
                "code": "Code"
            },
            "partition": {
                "self": "Partition|Partitions",
                "weight": "Weight",
                "comment": "Comment",
                "isTimeslipBillable": "Timeslips Billable",
                "isExpenseBillable": "Expenses Billable"
            },
            "partitionsSet": {
                "self": "Activity Template|Activity Templates",
                "description": "Description"
            },
            "permission": {
                "self": "Permission|Permissions"
            },
            "project": {
                "self": "Project|Projects",
                "alt": "Account|Accounts",
                "id": "Project ID",
                "name": "Project Name",
                "office": "Home Office",
                "team": "Team",
                "recordDueAt": "Record due",
                "meta": {
                    "balance": "Account balance"
                },
                "settings": {
                    "locale": "Client locale",
                    "workRegion": "Building location",
                    "isTaxExempt": "Is tax exempt",
                    "isTimeslipBillable": "Timeslip billing rule",
                    "isExpenseBillable": "Expense billing rule"
                },
                "user": {
                    "admin": "Administrator"
                }
            },
            "projectRecord": {
                "self": "Project Record|Project Records",
                "shorthand": "Record|Records",
                "deviceCount": {
                    "self": "Device quantity",
                    "escalators": "Escalators",
                    "elevators": "Elevators"
                },
                "financials": {
                    "self": "Financials",
                    "closedScope": {
                        "self": "Closed scope",
                        "scheduledFees": "Scheduled fees",
                        "timeslipsBillableType": "Timeslip billings expectations",
                        "timeslipsBillableType_shorthand": "Is billable",
                        "subconsultantCost": "Scheduled subconsultant cost",
                        "subconsultantCostBillable": "Subconsultant cost is billable",
                        "subconsultantCostBillable_shorthand": "Is billable",
                        "subconsultantCostMarkup": "Subconsultant cost markup",
                        "subconsultantCostMarkup_shorthand": "Markup",
                        "expenses": "Scheduled expenses",
                        "expensesBillable": "Expenses cost is billable",
                        "expensesBillable_shorthand": "Is billable",
                        "expensesBillableType": "Expense billings expectations",
                        "expensesBillableType_shorthand": "Is billable",
                        "expensesMarkup": "Expenses markup",
                        "expensesMarkup_shorthand": "Markup",
                        "netFees": "Expected net fees",
                        "markupFees": "Expected markup fees",
                        "hourlyRateEstimate": "Hourly rate for time estimate",
                        "hoursEstimate": "Budgeted hours"
                    }
                },
                "sales": {
                    "comment": "Comment",
                    "awardProbability": "Award probability",
                    "isCompetitive": "Competitive bid",
                    "awardDateEstimate": "Award date estimate"
                },
                "internal": {
                    "hoursAnnumBudgeted": "Budgeted hours during next annum"
                },
                "lost": {
                    "comment": "Explanation",
                    "awardedFirm": "Awarded firm",
                    "awardedFee": "Awarded fee"
                },
                "active": {
                    "interaction": "Interaction pattern",
                    "comment": "Comment",
                    "startDate": "Start date",
                    "endDateEstimate": "Estimated end date"
                },
                "hold": {
                    "comment": "Reason",
                    "scheduledResumption": "Scheduled resumption"
                },
                "complete": {
                    "clientReview": "Client review date",
                    "marketingFollowUp": "Marketing follow-up date",
                    "accountingFollowUp": "Accounting follow-up date"
                }
            },
            "projectStatus": {
                "self": "Project Status|Project Statuses",
                "shorthand": "Status|Statuses",
                "name": "Name",
                "indicatesActive": "Indicates Active"
            },
            "projectType": {
                "self": "Project Type|Project Types",
                "shorthand": "Type|Types",
                "name": "Name"
            },
            "role": {
                "self": "Role|Roles"
            },
            "salesTaxCode": {
                "self": "Sales Tax Code|Sales Tax Codes",
                "shorthand": "Sales Tax|Sales Taxes"
            },
            "staff": {
                "self": "Staff",
                "defaultRate": "Hourly Rate",
                "office": "Home Office",
                "supervisor": "Supervisor"
            },
            "team": {
                "self": "Team|Teams",
                "members": "Member|Members",
                "teamMember": "Team Member|Team Members"
            },
            "timeEntry": {
                "self": "Time Entry|Time Entries",
                "start": "Start Date/Time",
                "stop": "Stop Date/Time",
                "duration": "Duration (seconds)",
                "description": "Comment",
                "tags": "Tags"
            },
            "timeslip": {
                "self": "Timeslip|Timeslips",
                "date": "Date",
                "duration": "Duration",
                "description": "Description",
                "isBillable": "Billable",
                "notBillable": "Not Billable",
                "startAt": "Start At",
                "stopAt": "Stop At"
            },
            "timeslipCollection": {
                "self": "Timeslip Collection|Timeslip Collections"
            },
            "timeslipPartition": {
                "date": "Date",
                "comment": "Comment",
                "isBillable": "Billable",
                "billingRate": "Billing Rate",
                "notBillable": "Not Billable",
                "weight": "weight"
            },
            "activityForChart": {
                "durationAdmin": "Overhead",
                "durationAway": "Away",
                "durationBillable": "Billable Work",
                "durationNotBillable": "Fixed Fee Work",
                "targetDuration": "Target"
            },
            "timeslipType": {
                "self": "Timeslip Type|Timeslip Types",
                "shorthand": "Type|Types",
                "name": "Name"
            },
            "user": {
                "self": "User|Users",
                "alt": "Person|People",
                "company": "Company",
                "identicon": "Identicon",
                "email": "Email",
                "fullName": "Full Name",
                "password": "Passphrase",
                "twoFactor": "Two-factor authentication",
                "name": "Name",
                "team": "Support",
                "title": "Title",
                "settings": {
                    "locale": "Locale",
                    "employeeId": "Employee ID",
                    "timeslipsFormExpand": "Show expanded Timeslips form",
                    "projectSearchScoped": "Project scope in browser",
                    "projectPickerScoped": "Project scope in form picker",
                    "autoDecimal": "Automatic currency decimal",
                    "timeFormat": "Time format",
                    "durationFormat": "Duration format"
                }
            }
        },
        "vuelidate": {
            "alpha": "{attribute} may only contain letters.",
            "alphaDash": "{attribute} may only contain letters, numbers, and dashes.",
            "alphaNum": "{attribute} may only contain letters and numbers.",
            "between": "{attribute} must be between {min} and {max}.",
            "betweenDate": "{attribute} must be between {min} and {max}.",
            "betweenDatetime": "{attribute} must be between {min} and {max}.",
            "betweenLength": "{attribute} must be between {min} and {max} characters in length.",
            "currency": "{attribute} must be a currency value, either integer or decimal.",
            "decimal": "{attribute} must be a decimal number.",
            "email": "{attribute} must be a valid email address.",
            "equal": "{attribute} must equal {value}",
            "file": "{attribute} must be a file object.",
            "integer": "{attribute} must be an integer.",
            "length": "{attribute} must be {length} characters.",
            "max": "{attribute} may not be greater than {max}.",
            "maxDate": "{attribute} may not be later than {max}.",
            "maxFileLength": "{attribute} may not be larger than {max} bytes",
            "maxLength": "{attribute} may not be greater than {max} characters.",
            "mimeType": "{attribute} must be a supported file type.",
            "min": "{attribute} must be at least {min}.",
            "minDate": "{attribute} may not be earlier than {min}.",
            "minLength": "{attribute} must be at least {min} characters.",
            "password": "The {attribute} field must be without spaces and must feature a lower case letter, an upper case letter, a number and a special character.",
            "passwordTest": "The {attribute} must satisfy the quality test.",
            "policyTest": "This selection for {attribute} is not permitted.",
            "primaryKey": "{attribute} must be a positive integer.",
            "projectPolicyTest": "The {attribute} does not accept activity reporting.",
            "numeric": "{attribute} must be a number.",
            "required": "The {attribute} field is required.",
            "requiredIf": "{attribute} field is required when {condition}.",
            "requiredUnless": "The {attribute} field is required unless {condition}.",
            "sameAs": "{attribute} and {sameAs} must match.",
            "size": "{attribute} must be {size}.",
            "unique": "The {attribute} has already been taken.",
            "url": "The {attribute} format is invalid."
        },
        "ui": {
            "about": "About",
            "admin": "Admin",
            "all": "All",
            "allRights": "All rights reserved",
            "beta": "Beta",
            "complete": "Complete",
            "completed": "Completed",
            "empty": "Empty",
            "error": "Error",
            "field": "Field",
            "from": "From",
            "hitEscTo": "Hit Esc to",
            "history": "History",
            "home": "Home",
            "incomplete": "Incomplete",
            "invalidResponse": "The server returned an invalid response. If this continues, please inform a system administrator.",
            "learnMore": "Learn more",
            "loading": "Loading",
            "login": "Login",
            "logout": "Logout",
            "maxItems": "Max {count}",
            "maxReached": "Max reached",
            "no": "No",
            "noContactInfo": "No contact info available",
            "noMatches": "No matches found",
            "noResults": "No results",
            "none": "None",
            "notSet": "Not set",
            "offline": "Offline",
            "ok": "OK",
            "online": "Online",
            "overview": "Overview",
            "owned": "Owned",
            "panel": "Panel",
            "provideFeedback": "Provide feedback",
            "ready": "Ready",
            "register": "Register",
            "results": "Results",
            "review": "Review",
            "services": "Services",
            "signIn": "Sign in",
            "signingIn": "Signing in",
            "signOut": "Sign out",
            "signingOut": "Signing out",
            "since": "Since",
            "settings": "Settings",
            "split": "Split",
            "record": "Status",
            "title": "Hoistway",
            "to": "To",
            "today": "today",
            "tomorrow": "tomorrow",
            "top": "Top",
            "unknown": "Unknown",
            "until": "Until",
            "yes": "Yes",
            "you": "You",
            "copyToClipboard": {
                "error": "Copy to clipboard failed",
                "success": "Copied to clipboard"
            }
        },
        "cards": {
            "deviceGroup": {
                "recover": "Recover Devices",
                "orphaned": "{count} orphaned Devices found!"
            }
        },
        "locales": {
            "en-CA": "English",
            "fr-CA": "français"
        },
        "auth": {
            "404redirect": "The requested page does not exist. You have been redirected.",
            "authGuard": "Please sign in to continue.",
            "permissionGuard": "You are not permitted to access this page. You have been redirected to the front page.",
            "failed": "These credentials do not match our records.",
            "password": "The provided password is incorrect.",
            "signIn": "Signed in successfully.",
            "signOut": "Signed out successfully.",
            "throttle": "Too many login a tempts. Please try again in {seconds} seconds.",
            "register": "Your user account has been created successfully. Welcome!",
            "registerFailed": "Account creation failed. Ensure the email address is unique and the provided access code is correct."
        },
        "inspection": {
            "devices": {
                "title": "Device Management"
            },
            "general": {
                "inspect": "Inspect"
            },
            "issueEventGroup": {
                "opened": "Issue opened with {model} {list}.",
                "added": "{model} {list} added.",
                "assigned": "{model} {list} assigned record '{record}'."
            },
            "inspectionArea": {
                "notSelected": "Area not selected",
                "whereAreYou": "Where are you?"
            },
            "overview": {
                "contributors": "Contributors",
                "title": "Overview"
            },
            "performanceCab": {
                "selectOne": "Select One Device."
            },
            "settings": {
                "leftHanded": "Left-handed mode",
                "performanceAuto": "Auto-submit performance entries",
                "title": "Settings"
            },
            "performance": {
                "awaitingDevice": "Awaiting Device selection...",
                "title": "Performance Measurement",
                "short": "Performance"
            },
            "team": {
                "title": "Team",
                "notReported": "Not reported",
                "ping": {
                    "activeNow": "Active now",
                    "activeSince": "Active {when}",
                    "idle": "Idle since {when}",
                    "away": "Away since {when}",
                    "since": "Since {when}"
                }
            }
        },
        "views": {
            "activityAnalytics": {
                "title": "Activity Analytics",
                "awr": "Active Work Ratio",
                "abwr": "Active Billable Work Ratio",
                "apwr": "Active Project Work Ratio",
                "help": {
                    "title": "About Activity Analytics",
                    "description": "These metrics quantify reported work activity measured against theoretical full working days - typically 7.5 hours - for each given person.",
                    "awr": "Ratio of all time booked versus the scheduled expected time.",
                    "abwr": "Ratio of billable time measured against expected scheduled total time.",
                    "apwr": "Ratio of productive project time versus expected scheduled total time."
                }
            },
            "admin": {
                "menu": {
                    "export": "Export",
                    "analytics": "Analytics",
                    "invoicing": "Invoicing"
                },
                "projectRecords": {
                    "title": "Project Reporting Status",
                    "overdueMsg": "{count} records overdue back to {date}",
                    "okMsg": "Up to date (next due {date})",
                    "noneMsg": "No active projects",
                    "orderBy": {
                        "projectRecordDue": "Due date",
                        "projectRecordsOverdueCount": "Overdue count",
                        "name": "Name"
                    },
                    "export": {
                        "title": "Project Records export tool",
                        "date": "Sample Date"
                    }
                },
                "analytics": {
                    "title": "Export Activity Ratios"
                },
                "projectInvoicingExport": {
                    "title": "Active Project Invoicing Data Dump",
                    "description": "Invoicing status summary for all active Projects"
                }
            },
            "dispatcher": {
                "title": "Dispatcher"
            },
            "identicon": {
                "active": {
                    "title": "Your active identicon",
                    "description": "An \"identity icon\" is used to visually identify your contributions throughout this application. A personal identicon for you has not been submitted. This default identicon is currently being used to represent you.",
                    "remove_msg": "If you clear your identicon, this default identicon will be used to represent you."
                },
                "preview": {
                    "title": "Scaling preview"
                },
                "help": {
                    "title": "Identicon guide",
                    "list": {
                        "10": "Image may be up to 10 MB in size and must be JPEG, PNG or GIF.",
                        "30": "Image will be cropped to a circle at the centre of the image. Submitting a square image will offer the best control over the image framing.",
                        "40": "A full frame portrait image works best. Your identicon will be shown at various scales, including very tiny.",
                        "50": "Any workplace-appropriate image is acceptable, including illustrations or film characters. Simple high-contrast images work best.",
                        "60": "If you'd prefer to not use a photo, tools exist to craft an illustrated avatar:",
                        "60-1_title": "avataaars generator",
                        "60-1": "is basic but very easy",
                        "60-2_title": "avachara",
                        "60-2": "provides detailed controls"
                    }
                }
            },
            "expenses": {
                "filter": {
                    "billable": {
                        "label": "Billable to client",
                        "scopes": {
                            "all": "All",
                            "some": "Some",
                            "none": "None"
                        }
                    },
                    "projectOffice": "Project home Office",
                    "userOffice": "Staff members from Office"
                },
                "historyTitle": "History (newest at top)",
                "noReceipts": "A receipt record should almost always be provided.",
                "notReimbursable": "This expense is marked as not reimbursable, indicating that the submitting employee does not expect to be compensated for the expense. This is appropriate when the Company has paid for the expense directly."
            },
            "export": {
                "isReady": "Spreadsheet export is ready.",
                "isError": "Spreadsheet export failed.",
                "isSubmitted": "Spreadsheet export will be ready soon."
            },
            "invoicing": {
                "title": "Invoicing",
                "due": "Due",
                "filter": {
                    "projectOffice": "Project home Office",
                    "userOffice": "Staff members from Office"
                }
            },
            "partitionsSets": {
                "template": "Apply Template"
            },
            "performance": {
                "title": "Performance Measurement",
                "short": "Performance",
                "default": {
                    "title": "Measurement"
                },
                "overview": {
                    "title": "Results"
                },
                "pattern": {
                    "title": "Patterns",
                    "up": "Upward Trip",
                    "down": "Downward Trip",
                    "doors": "Door Cycle",
                    "up_down_help": "Place calls between three floors, with at least the first two floors being adjacent (two trips total). Perform measurements beginning with the door closing for the first trip. Continue advancing timing milestones throughout this trip. As the door closes at the second floor, step in to the doorway and interrupt to begin the nudging test. Timing ends as nudging begins (assuming it works). There is nothing to record for the second trip; it only is necessary to ensure nudging occurs.",
                    "doors_help": "Begin timing as the doors begin to open upon arriving at a floor. Timing ends as the doors return to the fully closed position.",
                    "help": "Use this tool to collect most time-based performance measurements in parallel. It's hard to explain how to use this - you have to just try it. Start by preparing to measure performance times on an up or down trip then click the Next button whenever the prompt hints that you should. After a few uses the necessary pattern will become apparent to you and you'll be able to collect times very quickly."
                },
                "setup": {
                    "title": "Setup",
                    "devices": {
                        "title": "Devices",
                        "current": "Current devices",
                        "new": "Set devices",
                        "help": "Devices may be entered via a hyphenated range, comma-separated values, or a combination of both."
                    },
                    "data": {
                        "title": "Data",
                        "clear": "Clear recorded values",
                        "reset": "Full reset"
                    },
                    "settings": {
                        "title": "Settings",
                        "units": {
                            "title": "Units",
                            "i": "Imperial",
                            "m": "Metric"
                        },
                        "speed": {
                            "title": "Travel speed fields",
                            "show": "Show",
                            "hide": "Hide"
                        }
                    }
                }
            },
            "projectRecords": {
                "title": "Your Project Records workflow",
                "help": "Project Records are presented in order of due date. Complete entries such that there are no records due until when you plan to return again.",
                "status": "Your Project Record reporting has been overdue since {date}. {count} projects require your attention.",
                "recordDueBy": "Record Due By",
                "createdAt": "Submission Date",
                "createdBy": "Submitted By"
            },
            "projects": {
                "billingRates": {
                    "currentRate": "Your default billing rate for this project is"
                },
                "export": {
                    "includeInvoicingActivity": "Include Invoicing Activity"
                }
            },
            "settings": {
                "menu": {
                    "profile": "Profile",
                    "personalization": "Personalization",
                    "notifications": "Notifications (soon)",
                    "security": "Account security"
                },
                "timeFormat": {
                    "default": "Default per locale",
                    "ampm": "12-hour (AM/PM)",
                    "24hr": "24-hour",
                    "demo": "Demo time picker",
                    "example": "Example timestamp"
                },
                "durationFormat": {
                    "clock": "Clock",
                    "decimal": "Decimal",
                    "demo": "Demo time picker"
                },
                "autoDecimal": {
                    "title": "Decimal placement in currency form fields",
                    "auto": "Automatic",
                    "manual": "Manual",
                    "demo": "Demo currency field"
                },
                "identicon": {
                    "file_label": "Attachment"
                }
            },
            "team": {
                "selectedCount": "{count} selected",
                "removeConfirm": "Remove {count} person from Team? | Remove {count} devices from Team?",
                "attachUsersTitle": "Add People to {name}",
                "empty": "This Team has no members."
            },
            "timeslips": {
                "historyTitle": "History (newest at top)",
                "mismatchMsg": "The duration was edited so that the start and stop times from Timekeeper no longer match.",
                "durationErrorMsg": "The recorded boundary times ({range}) are mismatched with the reported duration, likely because this Timeslip record was edited intentionally. The reported duration shall be taken as correct.",
                "isActiveMsg": "This record is open via Timekeeper and cannot be interacted with here until concluded.",
                "billableMsg": "Billable entry for {amount} at {rate}/hr",
                "filter": {
                    "billable": {
                        "label": "Billable to client",
                        "scopes": {
                            "all": "All",
                            "some": "Some",
                            "none": "None"
                        }
                    },
                    "projectOffice": "Project home Office",
                    "userOffice": "Staff members from Office"
                }
            },
            "timekeeper": {
                "active": "Current activity",
                "favorites": "Favorites",
                "finish": "Finish",
                "bookedActivity": "Booked activity (hours)",
                "recentActivity": "Recent activity",
                "activityDuration": "Past two weeks",
                "start": "Start",
                "stop": "Stop",
                "title": "Timekeeper",
                "onCancel": "The closed Timeslip for '{project}' was cancelled and discarded.",
                "createTitle": "Start new Timekeeper record",
                "updateTitle": "Active Timekeeper recording",
                "stopAtTitle": "Adjust Finish Time"
            },
            "forgotPassword": {
                "title": "Forgot Passphrase",
                "help": "To recover your account with a new passphrase, please identify your email address. A link to reset your passphrase will be emailed to you.",
                "newuser": {
                    "title": "Set Passphrase",
                    "help": "To access your new account, you must first set your passphrase. Please identify your email address. A link to set your passphrase will be emailed to you."
                }
            },
            "recoverPassword": {
                "title": "Recover Passphrase"
            },
            "passwordFeedback": {
                "title": "Passphrase feedback",
                "hint1": "Your new passphrase must satisfy a high quality standard. To assist you, your passphrase will be scored as you type.",
                "hint2": "Please choose a passphrase which is unique to this site. A password locker service is useful to assist with this. Otherwise, a nonsensical phrase which you can remember is preferred to a complex but short sequence with mixed characters which you cannot.",
                "ratings": {
                    "0": "Poor",
                    "1": "Poor",
                    "2": "Weak",
                    "3": "Almost...",
                    "4": "Good!",
                    "default": "Awaiting entry...",
                    "loading": "Checking..."
                }
            },
            "users": {
                "billingRates": {
                    "currentRate": "Today's billing rate is"
                }
            },
            "404": {
                "title": "Ermm...",
                "message": "You should take the next one.",
                "button": "Return to lobby",
                "error": "404 Page Not Found"
            }
        },
        "units": {
            "hours": "hour | hours",
            "perHour": "/hr"
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        }
    }
}
