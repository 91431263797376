<template>
  <VSelect
    :autofocus="autofocus"
    :chips="chips"
    :clearable="clearable"
    :disabled="disabled"
    :error-messages="errorMessages"
    :hint="hintParsed"
    :item-text="itemText"
    :item-value="itemValue"
    :items="items"
    :label="labelParsed"
    :loading="loading"
    :multiple="multiple"
    :outlined="outlined"
    :persistent-hint="localPersistentHint"
    :return-object="returnObject"
    :suffix="suffix"
    :value="value"
    @blur="doTouch"
    @input="onInput"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </VSelect>
</template>

<script>
  import KFormTextElementMixin from './KFormTextElementMixin.js'

  export default {
    mixins: [
      KFormTextElementMixin,
    ],

    props: {
      chips: Boolean,

      items: {
        type: Array,
        default: () => [],
      },

      itemText: {
        type: [String, Array, Function],
        default: 'name',
      },

      itemValue: {
        type: [String, Array, Function],
        default: 'id',
      },

      multiple: Boolean,

      returnObject: Boolean,

      value: {
        required: true,
      },
    },
  }
</script>
