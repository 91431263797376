<template>
  <VContainer>
    <FormUserEdit :target="id" @success="onSuccess"/>
  </VContainer>
</template>

<script>
  import FormUserEdit from 'top/forms/FormUserEdit.vue'

  export default {
    components: {
      FormUserEdit,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      }
    },

    methods: {
      onSuccess({id, slug, name}) {
        this.$store.dispatch(
          'flash/success',
          {
            text: 'forms.messages.modelUpdated',
            args: {
              type: 'models.user.self',
              name,
            },
          });

        this.$router.push({name: 'user', params: {id, slug}});
      }
    }
  }
</script>
