<template>
  <KSuggest
    :item-text="$options.displayText"
    :hint="hint"
    :clearable="clearable"
    :disabled="disabled"
    label="models.project.self"
    model="project"
    :validator="validator"
    :variables="scopeVariables"
    :value="value"
    @input="onInput"
    fetch-policy="cache-and-network"
    refs="search"
  >
    <template #item="{item}">
      <VListItemContent>
        <VListItemTitle>
          {{item.name}}
          <small class="black--text text--lighten-2">
            ({{item.id}})
          </small>
        </VListItemTitle>
        <VListItemSubtitle>
          {{item.projectType.name}} - {{item.office.name}} - {{item.projectStatus.name}}
        </VListItemSubtitle>
      </VListItemContent>
    </template>
    <template #append>
      <ProjectPickerScopeBtn :disabled="disabled" class="my-0"/>
    </template>
    <template #append-outer v-if="!hideInfo">
      <ProjectPickerDialog :disabled="disabled"/>
    </template>
  </KSuggest>
</template>

<script>
import KSuggest from "forms/elements/KSuggest.vue";
import ProjectPickerScopeBtn from "./ProjectPickerScopeBtn.vue";
import ProjectPickerDialog from "./ProjectPickerDialog.vue";
import {mapGetters} from 'vuex';
import Queries from "queries/index.js";

const displayText = ({name, id}) => `${name} (${id})`;

export default {
  displayText,

  data: () => ({
    hover: false,
  }),

  components: {
    KSuggest,
    ProjectPickerScopeBtn,
    ProjectPickerDialog,
  },

  props: {
    clearable: Boolean,

    disabled: Boolean,

    hideInfo: Boolean,

    hint: String,

    validator: Object,

    value: {
      type: String,
      required: true,
    },
  },

  apollo: {
    projectPickerScoped: {
      query: Queries.User.Single.ProjectPickerScoped,
      variables() {
        return {
          id: this.meId,
        };
      },
      update: ({user}) => user?.settings?.projectPickerScoped,
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    scopeVariables() {
      return this.projectPickerScoped ? {teamMember: {user: this.meId}} : undefined;
    },
  },

  methods: {
    onInput(v) {
      this.$emit('input', v);
    },
  },
}
</script>
