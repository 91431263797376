<template>
  <VListItem key="performance-buttons">
    <VCard color="grey lighten-3" v-if="!isActive">
      <VCardTitle>
        <span v-t="'views.performance.pattern.title'" class="mr-2"/>
        <VDialog>
          <template v-slot:activator="{on}">
            <VBtn icon color="blue lighten-2" small v-on="on">
              <FaI :icon="['far' ,'circle-question']"/>
            </VBtn>
          </template>
          <VCard>
            <VCardTitle v-t="'views.performance.pattern.title'"/>
            <VCardText v-t="'views.performance.pattern.help'"/>
          </VCard>
        </VDialog>
      </VCardTitle>
      <VCardText>
        <VTooltip
          v-for="({name, icon}, type) in $options.patternTypes"
          :key="type"
          :text="name"
        >
          <template v-slot:activator="{props}">
            <VBtn
              color="danger"
              @click="start(type)"
              :disabled="isDisabled"
              class="mx-1"
              v-bind="props"
            >
              <FaI size="lg" :icon="['far', icon]"/>
            </VBtn>
          </template>
        </VTooltip>
      </VCardText>
    </VCard>
    <VCard v-else flat>
      <VCardTitle>
        <FaI v-if="isActive" :icon="['far', typeIcon]" class="mr-2 grey--text text--darken-1"/>
        <span v-t="typeTitle" class="mr-2"/>
        <VDialog>
          <template v-slot:activator="{on}">
            <VBtn icon color="blue lighten-2" v-on="on" small v-show="isStarting">
              <FaI :icon="['far' ,'circle-question']"/>
            </VBtn>
          </template>
          <VCard>
            <VCardTitle>
              {{typeTitle}}
            </VCardTitle>
            <VCardText>
              {{typeHelp}}
            </VCardText>
          </VCard>
        </VDialog>
      </VCardTitle>
      <VCardText>
        <VBtn
          v-show="isActive"
          :color="goColor"
          @click="advance"
          x-large
        >
          <FaI :icon="goIcon"/>
          <span class="ml-2">
            {{goMsg}}
          </span>
        </VBtn>

        <VBtn
          v-show="isActive"
          color="danger"
          class="ml-5"
          @click="stop"
        >
          <FaI :icon="abortIcon"/>
          <span class="ml-2">
            {{ abortMsg }}
          </span>
        </VBtn>
      </VCardText>
    </VCard>
  </VListItem>
</template>

<script>
import {PROGRAMS, STATUSES, TYPES} from './timerPatterns.js'
import {STATUSES as perfStatuses} from './perfConsts.js'

export default {
  patternTypes: TYPES,

  inject: [
    'performancePattern',
  ],

  computed: {
    isDisabled() {
      return this.performancePattern.status === perfStatuses.SUBMIT;
    },

    isActive() {
      return this.status !== STATUSES.IDLE;
    },

    isStarting() {
      return this.status === STATUSES.STARTING;
    },

    goIcon() {
      switch (this.status) {
        case STATUSES.STARTING:
          return 'flag';
        case STATUSES.STOPPING:
          return 'flag-checkered';
        default:
          return 'step-forward';
      }
    },

    goColor() {
      return this.status === STATUSES.STARTING ? 'success' : 'primary';
    },

    goMsg() {
      return this.$t(`actions.${this.status === STATUSES.STARTING ? 'begin' : (this.status === STATUSES.STOPPING ? 'finish' : 'next')}`)
    },

    abortIcon() {
      return this.status === STATUSES.STARTING ? 'ban' : 'rotate-left';
    },

    abortMsg() {
      return this.$t(`actions.${this.status === STATUSES.STARTING ? 'cancel' : 'end'}`);
    },

    typeIcon() {
      return this.performancePattern.mode ? TYPES[this.performancePattern.mode].icon : null;
    },

    typeTitle() {
      const name = this.performancePattern?.mode ? TYPES[this.performancePattern.mode]?.name : null;
      return name ? this.$t(`views.performance.pattern.${name}`) : null;
    },

    typeHelp() {
      const help = this.performancePattern?.mode ? TYPES[this.performancePattern.mode]?.help : null;
      return help ? this.$t(`views.performance.pattern.${help}`) : null;
    },

    lastStep() {
      const {mode} = this.performancePattern;
      if (!mode) {
        return 0;
      }

      return Math.max(...Object.values(PROGRAMS[mode]).map((e) => e.stop));
    },

    status() {
      const {mode, step} = this.performancePattern;

      if (mode === null) {
        return STATUSES.IDLE;
      }

      if (step === 0) {
        return STATUSES.STARTING;
      }

      if (step === this.lastStep - 1) {
        return STATUSES.STOPPING;
      }

      if (step === this.lastStep) {
        return STATUSES.FINISHED;
      }

      return STATUSES.RUNNING;
    },
  },

  watch: {
    status(v) {
      if (v === STATUSES.FINISHED) {
        this.stop();
      }
    },
  },

  methods: {
    start(mode) {
      this.$emit('start', mode);
    },

    advance() {
      this.$emit('advance', true);
    },

    stop() {
      this.$emit('advance', false);
    }
  },
}
</script>
