<template>
  <VToolbar
    dense
  >
    <VToolbarItems>
      <VBtn
        :key="item.id"
        :to="item.to"
        :exact="item.exact || true"
        text
        v-for="item in links"
        v-t="item.message"
      />
    </VToolbarItems>
  </VToolbar>
</template>

<script>
  export default {
    props: {
      links: {
        type: Array,
        required: true,
      }
    },
  }
</script>
