<template>
  <VDialog max-width="600">
    <template #activator="{on, attrs}">
      <VBtn chip x-small v-bind="attrs" v-on="on" color="blue lighten-2">
        +{{count-1}}
      </VBtn>
    </template>
    <template #default="dialog">
      <VSkeletonLoader type="card" v-if="$apollo.loading"/>
      <VCard v-else>
        <VCardTitle>
          <span v-t="{path: 'models.timeslipCollection.self', choice: 1}"/>
        </VCardTitle>
        <VCardSubtitle>
          <strong>
            {{ timeslipType.name }}:
          </strong>
          <em>
            {{item.description}}
          </em>
        </VCardSubtitle>
        <VCardText>
          <VList>
            <ProjectListItem v-for="partition in item.partitions" :key="partition.project.id" :id="partition.project.id">
              <template #action>
                <FaI v-if="item.isBillable" class="ml-1 orange--text text--darken-2" icon="file-invoice-dollar"/>
                <strong>
                  {{Math.round(partition.weight/weightTotal*100,0)}}%
                </strong>
              </template>
            </ProjectListItem>
          </VList>
        </VCardText>
        <VCardActions>
          <VSpacer/>
          <VBtn text @click="dialog.value = false">
            <span v-t="'actions.close'"/>
          </VBtn>
        </VCardActions>
      </VCard>
    </template>
  </VDialog>
</template>

<script>
import ProjectListItem from '../projects/browse/elements/ProjectListItem.vue'
import Queries from 'queries/index.js';
import {KTextField} from 'top/forms/elements';
import {mapGetters} from "vuex";

export default {
  components: {
    KTextField,
    ProjectListItem,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  apollo: {
    item: {
      query: Queries.TimeslipCollection.Single.Main,
      variables() {
        const {id, today, timekeeperDurationFrom} = this;
        return {
          id,
          durationDates: {
            from: timekeeperDurationFrom,
            to: today,
          },
        };
      },
      update(response) {
        return response.timeslipCollection;
      },
    },

    timeslipType: {
      query: Queries.TimeslipType.Forms.Populate,
      variables() {
        return {id: this.item.timeslipType.id};
      },
      skip() {
        return !this.item;
      },
    },
  },

  computed: {
    ...mapGetters('session', ['today', 'timekeeperDurationFrom']),

    count() {
      return this.item?.partitions?.length ?? 0;
    },

    weightTotal() {
      return this.item ? this.item.partitions.reduce((a, v) => a + (v.weight ?? 0), 0) : 0;
    }
  }
}
</script>
