<template>
  <div>
    <VToolbar dense>
      <VToolbarTitle v-t="'views.admin.projectRecords.title'"/>
    </VToolbar>
    <VContainer fluid>
      <VRow>
        <VCol cols="12">
          <VSelect v-model="orderBy" :items="orderByItems" label="Sort by" item-text="text" item-value="value"/>
        </VCol>
      </VRow>
    </VContainer>
    <NowLoading v-if="$apollo.loading"/>
    <VList>
      <div v-if="users" v-for="({id}, index) in sortedUsers">
        <ProjectRecordStatusListItem :key="id" :id="id"/>
        <VDivider v-if="index !== 0" :key="`d-${id}`"/>
      </div>
    </VList>
  </div>
</template>

<script>
import Queries from "queries/index.js"
import ProjectRecordStatusListItem from "./ProjectRecordStatusListItem.vue";
import {orderBy} from "lodash-es";

const orderByValues = [
  'projectRecordDue',
  'projectRecordsOverdueCount',
  'name',
];

export default {
  data: () => ({
    orderBy: 'projectRecordDue'
  }),

  components: {
    ProjectRecordStatusListItem,
  },

  apollo: {
    users: {
      query: Queries.ProjectRecord.Browse.StatusReport,
      update: (response) => response?.users?.data ?? [],
      context: {batch: false},
    }
  },

  computed: {
    sortedUsers() {
      return orderBy(this.users, this.orderBy, this.orderBy === 'projectRecordsOverdueCount' ? 'desc' : 'asc');
    },

    orderByItems() {
      return orderByValues.map((value) => ({
        text: this.$t(`views.admin.projectRecords.orderBy.${value}`),
        value
      }));
    },
  },
}
</script>
