<template>
    <VTextField :label="label" v-model="localSearch" clearable type="search">
      <template v-slot:prepend-inner>
        <FaI icon="magnifying-glass"/>
      </template>
    </VTextField>
</template>

<script>
  import {debounce} from "lodash-es";

  export default {
    data: () => ({
      localSearch: '',
    }),

    props: {
      value: {
        type: String,
        required: true,
      }
    },

    computed: {
      label() {
        return this.$t('actions.search');
      },
    },

    watch: {
      localSearch(v) {
        if (this.value !== v) {
          this.debounceSearch();
        }
      },

      value: {
        handler(v)
        {
          this.localSearch = v;
        },
        immediate: true,
      }
    },

    methods: {
      debounceSearch: debounce(
        function () {
          this.$emit('input', this.localSearch);
        },
        500,
        {
          maxWait: 2000,
        }),
    },
  }
</script>
