<template>
  <VCard>
    <VCardText>
      <VRow align="center">
        <VCol cols="6" md="3" class="subtitle-1" v-t="'forms.project.searchScope.title'"/>
        <VCol cols="6" md="3" class="d-flex align-center">
          <span v-t="'forms.project.searchScope.all'"/>&nbsp;
          <FaI icon="globe"/>
          <VSwitch
            :input-value="isScoped"
            @change="onScopeToggle"
            dense
            hide-details
            class="mt-0"
          />
          <FaI icon="user"/>&nbsp;
          <span v-t="'forms.project.searchScope.member'"/>
        </VCol>
        <VExpandTransition>
          <VCol cols="12" md="6" v-show="isScoped" class="pb-0">
            <KApiSelect
              clearable
              model="role"
              :disabled="!isScoped"
              v-model="roleId"
              item-text="displayName"
              :variables="{context: 'PROJECTS'}"
              dense
            />
          </VCol>
        </VExpandTransition>
      </VRow>
    </VCardText>
  </VCard>
</template>

<script>
import {mapGetters} from 'vuex'
import {KApiSelect} from "forms/elements/index.js"
import Queries from "queries/index.js";

export default {
  components: {
    KApiSelect,
  },

  data: () => ({
    roleId: undefined,
  }),

  apollo: {
    isScoped: {
      query: Queries.User.Single.ProjectSearchScoped,
      variables() {
        return {
          id: this.meId,
        };
      },
      update: ({user}) => user?.settings?.projectSearchScoped,
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    output() {
      const {isScoped, meId, roleId} = this;
      return {
        ...isScoped && {userId: meId},
        ...isScoped && roleId && {roleId},
      };
    },
  },

  watch: {
    output(v) {
      this.$emit('input', v);
    }
  },

  methods: {
    onScopeToggle() {
      if (this.$apollo.queries.isScoped.loading) {
        return;
      }

      const {meId: id, isScoped} = this;
      this.$apollo.mutate({
        mutation: Queries.User.Settings.ProjectSearchScoped,
        variables: {
          id,
          projectSearchScoped: Boolean(!isScoped),
        },
        optimisticResponse: {
          userUpdate: {
            __typename: "User",
            id,
            settings: {
              __typename: "UserSettings",
              projectSearchScoped: Boolean(!isScoped),
            },
          },
        },
      });
    },
  },
}
</script>
