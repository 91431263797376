<template>
  <VCardTitle
    class="blue accent-2 white--text"
    primary-title
  >
    <span
      class="text-h5"
    >
      <small
        v-if="prefix"
      >
        <span
          v-t="prefix"
        ></span>:
      </small>
      <span
        class="grey--text"
        v-if="showTempTitle"
      >
        {{loadingTitle}}
      </span>
      <span
        v-else
      >
        {{title}}
      </span>
    </span>

    <VSpacer/>

    <KMenu
      v-if="menuLinks"
      :links="menuLinks"
    />
  </VCardTitle>
</template>

<script>
  import KMenu from "elements/KMenu.vue";

  export default {
    components: {
      KMenu,
    },

    props: {
      menuLinks: {
        type: Array,
      },

      prefix: {
        type: [Object, String],
      },

      loadingTitle: {
        type: String,
      },

      title: {
        type: String,
      },
    },

    computed: {
      showTempTitle() {
        return Boolean(this.loadingTitle && !this.title);
      },
    },
  }
</script>
