<template>
  <NowLoading v-if="$apollo.loading"/>
  <VContainer v-else>
    <VToolbar dense color="grey lighten-3">
      <VToolbarTitle v-t="{path: 'models.expense.self', choice: 2}"/>
      <VSpacer/>
      <VBtn text v-if="canCreate" :input-value="allowCreate" @click="toggleCreate">
        <FaI size="2x" icon="circle-plus"/>
      </VBtn>
      <VBtn @click="toggleFilter" text>
        <FaI size="2x" icon="chevron-down" :rotation="showFilter ? 180 : 0" style="transition: all 0.3s ease;"/>
        <div>
          <VFadeTransition leave-absolute>
            <FaI size="2x" key="a" v-if="isFiltered" icon="filter"/>
            <FaI size="2x" key="b" v-else :icon="['far', 'filter']"/>
          </VFadeTransition>
        </div>
      </VBtn>
    </VToolbar>
    <ExpenseBrowseTab
        :filter-disables="{userId: true}"
        :filter-defaults="{userId: id}"
        sort-default="UPDATED_AT"
        :order-desc-default="true"
        :show-filter="showFilter"
        @filtered="setIsFiltered"
        v-slot:default="{onCreate}"
    >
      <FormExpense v-if="allowCreate" :user-id="id" @success="onCreate"/>
    </ExpenseBrowseTab>
  </VContainer>
</template>

<script>
import ExpenseBrowseTab from "../../expenses/elements/ExpenseBrowseTab.vue";
import FormExpense from "../../expenses/elements/FormExpense.vue";

export default {
  components: {
    ExpenseBrowseTab,
    FormExpense,
  },

  data: () => ({
    showFilter: false,
    showCreate: false,
    isFiltered: false,
  }),

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  apollo: {
    canCreate() {
      return this.policyChecker({
        policy: 'create',
        type: 'Expense',
        args: {
          user: {
            connect: this.id,
          },
        },
      });
    },
  },

  computed: {
    allowCreate() {
      return this.canCreate && this.showCreate;
    },
  },

  watch: {
    showFilter(v) {
      if (v) {
        this.showCreate = false;
      }
    },

    showCreate(v) {
      if (v) {
        this.showFilter = false;
      }
    },
  },

  methods: {
    toggleCreate() {
      this.showCreate = !this.showCreate;
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    setIsFiltered(v) {
      this.isFiltered = v;
    },
  },
}
</script>
