<template>
  <VContainer>
    <VToolbar dense color="grey lighten-3">
      <VToolbarTitle v-t="'views.performance.title'"/>
    </VToolbar>
    <VCard tile>
      <VTabsItems v-model="tab" touchless>
        <VTabItem key="default">
          <LocationMain
            class="pb-4"
            :devices="devices"
            :inspection-area.sync="inspectionArea"
            :current-devices.sync="currentDevices"
          />
          <Performance :bus="bus" :devices="currentDevices" :settings="settings" :inspection-area="inspectionArea" :store="store"/>
        </VTabItem>
        <VTabItem key="overview">
          <Overview :store="store" :settings="settings"/>
        </VTabItem>
        <VTabItem key="setup">
          <Setup :devices.sync="devices" :settings.sync="settings" @clear="onClear" @reset="onReset"/>
        </VTabItem>
      </VTabsItems>
      </VCard>
    <VBottomNavigation
      app
      dark
      v-model="tab"
    >
      <VBtn key="default" :value="0">
        <span v-t="'views.performance.default.title'"/>
        <FaI icon="stopwatch"/>
      </VBtn>

      <VBtn key="setup" :value="1">
        <span v-t="'views.performance.overview.title'"/>
        <FaI icon="table"/>
      </VBtn>

      <VBtn key="setup" :value="2">
        <span v-t="'views.performance.setup.title'"/>
        <FaI icon="gear"/>
      </VBtn>
    </VBottomNavigation>
  </VContainer>
</template>

<script>
  import LocationMain from './location/LocationMain.vue';
  import {KStandardHeader} from 'elements/index.js';
  import perfConsts from './performance/perfConsts';
  import Performance from './performance/Main.vue';
  import Overview from './overview/Main.vue';
  import Setup from './setup/Main.vue';
  import mitt from 'mitt';
  import {debounce} from 'lodash-es';

  function getLocalStorageKey(key) {
    return `performance.${key}`;
  }

  function getColumnDefault(name) {
    const data = {};

    perfConsts.NAMES.forEach((name) => {
      data[name] = null;
    });

    return {
      name: name.toString(),
      data,
    };
  }

  function getStorageItem(key, serialize = false, def = null) {
    const response = localStorage.getItem(getLocalStorageKey(key));
    if (!response) {
      return def;
    }
    return serialize ? JSON.parse(response) : response;
  }

  function setStorageItem(key, value, serialize = false) {
    const payload = serialize ? JSON.stringify(value) : value;
    localStorage.setItem(getLocalStorageKey(key), payload);
  }

  export default {
    components: {
      LocationMain,
      KStandardHeader,
      Setup,
      Performance,
      Overview,
    },

    data: () => ({
      bus: mitt(),
      store: [],
      currentDevices: [],
      inspectionArea: null,
      settings: {},
      tab: 0,
    }),

    computed: {
      devices: {
        get() {
          return this.store.map(({name}) => name);
        },
        set(v) {
          this.onDevicesUpdate(v);
        },
      },
    },

    watch: {
      store: {
        handler(v) {
          this.debouncePersistStore();
        },
        deep: true,
      },
      inspectionArea(v) {
        this.persistInspectionArea();
      },
      currentDevices(v) {
        this.persistCurrentDevices();
      },
      devices(v) {
        this.pruneCurrentDevices();
      },
      settings(v) {
        this.persistSettings();
      }
    },

    methods: {
      onDevicesUpdate(newList) {
        // Step 1: Remove objects not in the update array
        this.store = this.store.filter((obj) => newList.includes(obj.name));

        // Step 2: Add new objects for missing names
        newList.forEach(name => {
          if (!this.store.some((obj) => obj.name === name)) {
            this.addDevice(name);
          }
        });

        // Step 3: Re-sort the array of objects to match the order in the update array
        this.store.sort((a, b) => newList.indexOf(a.name) - newList.indexOf(b.name));
      },

      addDevice(name) {
        this.store.push(getColumnDefault(name));
      },

      pruneCurrentDevices() {
        this.currentDevices = this.currentDevices.filter((e) => this.devices.includes(e));
      },

      reset() {
        this.store.splice(0);
        this.currentDevices.splice(0);
        this.inspectionArea = null;
      },

      debouncePersistStore: debounce(function () {
        this.persistStore();
      },1000),

      persistStore() {
        setStorageItem('store', this.store, true);
      },

      persistInspectionArea() {
        setStorageItem('inspectionArea', this.inspectionArea);
      },

      persistCurrentDevices() {
        setStorageItem('currentDevices', this.currentDevices, true);
      },

      persistSettings() {
        setStorageItem('settings', this.settings, true);
      },

      onValueChange(name, key, value) {
        const match = this.store.findIndex((e) => e.name === name);
        if (match > -1 && this.store[match].data.hasOwnProperty(key)) {
          this.store[match].data[key] = value;
        }
      },

      onClear() {
        this.store.forEach((device) => {
          Object.keys(device.data).forEach((key) => {
            device.data[key] = null;
          })
        });
      },

      onReset() {
        this.store = [];
        this.currentDevices = [];
        this.inspectionArea = null;
      },
    },

    mounted() {
      this.$set(this, 'store', getStorageItem('store', true, []));
      this.$set(this, 'currentDevices', getStorageItem('currentDevices', true, []));
      this.$set(this, 'inspectionArea', getStorageItem('inspectionArea'));
      this.$set(this, 'settings', getStorageItem('settings', true, {}));
      if (!this.devices.length) {
        this.tab = 2 // Setup
      }
    },

    created() {
      this.bus.on('change', ({name, key, value}) => this.onValueChange(name, key, value));
    },
  }
</script>
