<template>
  <VListItem :two-line="$vuetify.breakpoint.smAndDown">
    <VListItemContent>
      {{invoiceLine.description}}
    </VListItemContent>
    <VListItemIcon>
      <strong>
        {{amountFormatted}}
      </strong>
    </VListItemIcon>
  </VListItem>
</template>

<script>
  import Queries from 'queries/index.js'
  import currencyMixin from "top/mixins/currencyMixin.js";

  export default {
    mixins: [currencyMixin],

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    apollo: {
      invoiceLine: {
        query: Queries.InvoiceLine.Single.Main,
        variables() {
          return {
            id: this.id,
          };
        }
      },
    },

    computed: {
      amountFormatted() {
        const {invoiceLine, getCurrency} = this;
        return invoiceLine ? getCurrency(invoiceLine.amount) : '';
      },
    }
  }
</script>
