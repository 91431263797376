<template>
  <VSkeletonLoader type="list-item-two-line" v-if="$apollo.loading"/>
  <VListItem v-else>
    <VListItemContent>
      <VListItemTitle>
        <span class="grey--text text--darken-1">
          {{activityExport.id}}:
        </span>
        {{activityExport.from}} to {{activityExport.to}}
        <FaI v-if="activityExport.includeRetired" icon="trash" size="sm" class="grey--text text--darken-2 ml-1"/>
      </VListItemTitle>
      <VListItemSubtitle>
        {{createdAtMsg}}
      </VListItemSubtitle>
    </VListItemContent>
    <VListItemAction>
      <div class="d-flex flex-nowrap align-center">
        <MediaButton v-if="isReady" :id="activityExport.payload.id" color="info" width="75"/>
        <VDialog v-else-if="isError" max-width="600">
          <template v-slot:activator="{attrs, on}">
            <VBtn color="error" v-bind="attrs" v-on="on">
              <FaI icon="exclamation-triangle"/>&nbsp; <span v-t="'ui.error'"/>
            </VBtn>
          </template>
          <VCard>
            <VCardTitle>
              <span v-t="{path: 'models.activityExport.self', choice: 1}"/>&nbsp;<span v-t="'ui.error'"/>
            </VCardTitle>
            <VCardText>
              {{errorMsg}}
            </VCardText>
          </VCard>
        </VDialog>
        <div style="width: 125px" v-else-if="shouldKeepPolling">
          <VProgressLinear
            :value="progressValue"
            buffer-value="0"
            color="info"
            height="10"
            rounded
            stream
            striped
          />
        </div>
        <ApiDelete
            :id="activityExport.id"
            @delete="onDelete"
            btn-class="ml-4"
            icon
            model="activityExport"
            v-if="canDelete"
        />
      </div>
    </VListItemAction>
  </VListItem>
</template>

<script>
import Queries from 'queries/index.js';
import MediaButton from "elements/media/MediaButton.vue";
import ApiDelete from "elements/ApiDelete.vue"
import tickerMixin from "top/mixins/tickerMixin.js";
import {mapState, mapGetters} from "vuex";
import {differenceInMinutes, parseISO} from "date-fns";

export default {
  mixins: [
    tickerMixin,
  ],

  components: {
    ApiDelete,
    MediaButton,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  apollo: {
    activityExport: {
      query: Queries.ActivityExport.Single.Main,
      variables() {
        return {
          id: this.id,
        };
      },
      pollInterval: 2000,
      result(_, key) {
        if (!this.shouldKeepPolling) {
          this.$apollo.queries[key].stopPolling();
        }
      },
    },

    canDelete() {
      return this.policyChecker({
        policy: 'delete',
        type: 'ActivityExport',
        id: this.id,
      });
    },
  },

  computed: {
    ...mapState('session', ['now']),

    createdAtParse() {
      const {activityExport} = this;
      return activityExport ? parseISO(activityExport.createdAt) : undefined;
    },

    createdAtMsg() {
      const {createdAtParse, getTickerForHumans} = this;
      return createdAtParse ? getTickerForHumans(createdAtParse) : undefined;
    },

    isExpired() {
      const {createdAtParse, now} = this;
      return createdAtParse ? differenceInMinutes(now, createdAtParse) >= 10 : false;
    },

    status() {
      return this.activityExport?.status;
    },

    isReady() {
      return this.status === 'Ready';
    },

    isError() {
      const {isReady, isExpired, status} = this;
      return status === 'Error' || (isExpired && !isReady);
    },
    //
    // canDelete() {
    //   const {isReady, isError, meHasRole} = this;
    //   return (isReady || isError) && meHasRole('admin');
    // },

    shouldKeepPolling() {
      const {isExpired, status} = this;
      return !isExpired && ['New', 'Queued', 'Processing'].includes(status);
    },

    errorMsg() {
      return this.isError ? this.activityExport?.message || this.$t('ui.invalidResponse') : undefined;
    },

    progressValue() {
      switch(this.status) {
        case 'New':
          return 10;
        case 'Queued':
          return 30;
        case 'Processing':
          return 60;
        default:
          return 100;
      }
    },
  },

  methods: {
    onDelete(e) {
      this.$emit('delete', e);
    }
  },
}
</script>
