import gql from 'graphql-tag'
import PaginatorFragment from './paginatorFragment.js'

// Browse

const List = gql`
  query(
    $orderBy: [QueryInspectionsOrderByOrderByClause!]
    $search: String
    $scope: ProjectScope
    $page: Int
    $first: Int!
  ) {
    inspections(
      orderBy: $orderBy
      search: $search
      scope: $scope
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        id
        name
        slug
        project {
          id
          slug
          name
        }
      }
    }
  }
  ${PaginatorFragment}
`;

const Row = gql`
  query(
    $id: ID!
  ) {
    inspection(
      id: $id
    ) {
      id
      name
      slug
      createdAt
      updatedAt
      inspectionType {
        id
        name
      }
      buildings {
        id
        name
      }
      project {
        id
        admins {
          id
        }
      }
    }
  }
`;


// Single

const IdFromSlug = gql`
  query(
    $slug: String!
  ) {
    inspectionBySlug(
      slug: $slug
    ) {
      id
      slug
    }
  }
`;

const Main = gql`
  query(
    $id: ID!
  ) {
    inspection(
      id: $id
    ) {
      id
      slug
      name
      project {
        id
        slug
        name
      }
    }
  }
`;

const Overview = gql`
  query(
    $id: ID!
  ) {
    inspection(
      id: $id
    ) {
      id
      inspectionType {
        id
        name
      }
    }
  }
`;

const Issues = gql`
  query(
    $id: ID!
  ) {
    inspection(
      id: $id
    ) {
      id
      issues(first: 100) {
        data {
          id
          subject
          createdAt
          updatedAt
          user {
            id
          }
          inspectionArea {
            id
            name
          }
        }
      }
    }
  }
`;


// Inspect

const InspectIssues = gql`
  query(
    $id: ID!
  ) {
    inspection(
      id: $id
    ) {
      id
      issues(page: 1, first: 100) {
        data {
          id
        }
      }
    }
  }
`;

const InspectInspectionPerformances = gql`
  query(
    $id: ID!
  ) {
    inspection(
      id: $id
    ) {
      id
      inspectionPerformances {
        id
        field
        value
        comment
        createdAt
        updatedAt
        inspection {
          id
        }
        device {
          id
        }
        user {
          id
        }
      }
    }
  }
`;


// InspectSelect

const Buildings = gql`
  query(
    $id: ID!
  ) {
    inspection(
      id: $id
    ) {
      id
      buildings {
        id
        name
      }
    }
  }
`;

const Devices = gql`
  query(
    $id: ID!
  ) {
    inspection(
      id: $id
    ) {
      id
      devices(page: 1, first: 100) {
        data {
          id
          designation
          building {
            id
            name
          }
          deviceGroup {
            id
          }
        }
      }
    }
  }
`;

const DeviceGroups = gql`
  query(
    $id: ID!
  ) {
    inspection(
      id: $id
    ) {
      id
      deviceGroups {
        id
        name
        building {
          id
          name
        }
      }
    }
  }
`;


// Write

const FormFill = gql`
  query(
    $id: ID!
  ) {
    inspection(
      id: $id
    ) {
      id
      name
      project {
        name
        id
      }
      inspectionType {
        id
      }
    }
  }
`;

const Create = gql`
  mutation(
    $name: String!
    $projectId: ID!
    $inspectionTypeId: ID!
  ) {
    inspectionCreate(
      input: {
        name: $name
        project: {
          connect: $projectId
        },
        inspectionType: {
          connect: $inspectionTypeId
        }
      }
    ) {
      id
      name
      slug
    }
  }
`;

const Update = gql`
  mutation(
    $id: ID!
    $name: String
    $clientId: ID
    $clientDisconnect: Boolean
    $officeId: ID
    $projectTypeId: ID
    $projectStatusId: ID
  ) {
    inspectionUpdate(
      id: $id,
      input: {
        name: $name
        client: {
          connect: $clientId
          disconnect: $clientDisconnect
        },
        office: {
          connect: $officeId
        },
        projectType: {
          connect: $projectTypeId
        },
        projectStatus: {
          connect: $projectStatusId
        }
      }
    ) {
      id
      name
      slug
    }
  }
`;


// Export

const Browse = {
  List,
  Row,
};

const InspectSelect = {
  Buildings,
  Devices,
  DeviceGroups,
};

const Single = {
  IdFromSlug,
  Main,
  Overview,
  Issues,
};

const Inspect = {
  Issues: InspectIssues,
  InspectionPerformances: InspectInspectionPerformances,
  Select: InspectSelect,
};

const Write = {
  FormFill,
  Create,
  Update,
};

export default {
  Browse,
  Single,
  Inspect,
  Write,
};
