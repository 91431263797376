<template>
  <VContainer>
    <Locale/>
    <VDivider class="my-3"/>
    <TimeFormat/>
    <VDivider class="my-3"/>
    <AutoDecimal/>
    <VDivider class="my-3"/>
    <DurationFormat/>
  </VContainer>
</template>

<script>
import Locale from "./Locale.vue";
import TimeFormat from "./TimeFormat.vue"
import AutoDecimal from "./AutoDecimal.vue";
import DurationFormat from "./DurationFormat.vue";

export default {
  components: {
    Locale,
    TimeFormat,
    AutoDecimal,
    DurationFormat,
  },
}
</script>
