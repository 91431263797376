<template>
  <VSkeletonLoader v-if="$apollo.queries.user.loading" type="list-item-two-line"/>
  <UserListItem v-else :id="user.id" :class="backgroundColor">
    <div v-if="hasOverdue" v-t="{path: 'views.admin.projectRecords.overdueMsg', args: {count: user.projectRecordsOverdueCount, date: user.projectRecordDue}}"/>
    <div v-else-if="hasActiveProjects" v-t="{path: 'views.admin.projectRecords.okMsg', args: {date: user.projectRecordDue}}"/>
    <div v-else v-t="'views.admin.projectRecords.noneMsg'"/>
  </UserListItem>
</template>

<script>
import UserListItem from "../../users/browse/UserListItem.vue";
import Queries from 'queries/index.js'

export default {
  components: {
    UserListItem,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  apollo: {
    user: {
      query: Queries.ProjectRecord.Single.StatusReport,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },

  computed: {
    hasOverdue() {
      return this.user?.projectRecordsOverdueCount > 0;
    },

    hasActiveProjects() {
      return Boolean(this.user?.projectRecordDue);
    },

    backgroundColor() {
      const {hasActiveProjects, hasOverdue} = this;
      if (hasOverdue) {
        return 'red lighten-5';
      }

      if (hasActiveProjects) {
        return 'green lighten-5';
      }

      return 'grey lighten-5';
    },
  },
}
</script>
