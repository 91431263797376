<template>
  <VExpansionPanelHeader ripple>
    <VSkeletonLoader type="list-item" v-if="$apollo.loading"/>
    <VRow v-else no-gutters>
      <VCol cols="12">
        <strong>{{project.name}}</strong> <small class="grey--text">({{project.id}})</small>
      </VCol>
      <VCol cols="12">
        {{projectType.name}} - {{projectStatus.name}} - {{office.slug.toUpperCase()}}
      </VCol>
      <VCol cols="12" v-if="project.recordDueAt">
        <span v-t="'models.project.recordDueAt'"/> {{project.recordDueAt}}
      </VCol>
    </VRow>
  </VExpansionPanelHeader>
</template>

<script>
  import Queries from 'queries/index.js';

  export default {
    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    apollo: {
      project: {
        query() {
          return Queries.Project.Single.ProjectRecordsFastFillItem;
        },
        variables() {
          return {
            id: this.id,
          };
        },
      },

      projectStatus: {
        query: Queries.ProjectStatus.Single.Name,
        variables() {
          return {
            id: this.project.projectStatus.id,
          };
        },
        skip() {
          return !this.project;
        },
      },

      projectType: {
        query: Queries.ProjectType.Single.Name,
        variables() {
          return {
            id: this.project.projectType.id,
          };
        },
        skip() {
          return !this.project;
        },
      },

      office: {
        query: Queries.Office.Single.Main,
        variables() {
          return {
            id: this.project.office.id,
          };
        },
        skip() {
          return !this.project;
        },
      },
    },
  }
</script>
