import ForgotPassword from "./ForgotPassword.vue";
import RecoverPassword from "./RecoverPassword.vue";
// import Register from "./Register.vue";
import SignIn from "./SignIn.vue";

export default {
  ForgotPassword,
  RecoverPassword,
  // Register,
  SignIn,
}
