import gql from 'graphql-tag';

// Fragments

const CountryFragment = gql`
  fragment countryFragment on Country {
    id
    name
    divisionTitle
    postalCodeTitle
  }
`;

// Forms

const Select = gql`
  query {
    countries {
      ...countryFragment
    }
  }
  ${CountryFragment}
`;

const Populate = gql`
  query(
    $id: ID!
  ) {
    country(
      id: $id
    ) {
      ...countryFragment
    }
  }
  ${CountryFragment}
`;

const CountryDivisions = gql`
  query(
    $id: ID!
  ) {
    country(
      id: $id
    ) {
      id
      countryDivisions {
        id
        name
      }
    }
  }
`;


// Export

const Forms = {
  Select,
  Populate,
  CountryDivisions,
};

export default {
  Forms,
};