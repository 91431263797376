<template>
  <VBtn
    :disabled="disabled"
    @click="$emit('click', $event)"
    @contextmenu="onContextmenu"
    @touchend="$emit('touchend', $event)"
    @touchstart="$emit('touchstart', $event)"
    height="100"
    width="100"
  >
    <VSkeletonLoader height="100" type="image" v-if="$apollo.loading" width="100"/>
    <VHover v-else v-model="isHovering">
      <VCard :img="thumbnailUrl" class="d-flex" height="100" width="100">
        <div class="ma-auto">
          <FaI
            :class="{
            'media-item-preview-icon grey--text text--darken-1': hasPreview,
            'grey--text text--lighten-2': !hasPreview,
            }"
            v-if="!noIcon"
            :icon="localIcon"
            :size="hasPreview ? '2x' : '4x'"
          />
        </div>
        <div :class="{'media-item-file-title': !hasPreview}" class="d-flex align-center">
          <span
            :class="{'media-item-preview-title': hasPreview}"
            class="text-caption font-weight-black black--text"
            style="width: 100%"
            v-if="!noLabel"
          >
            {{base.name}}
          </span>
        </div>
        <VFadeTransition>
          <FaI
            :class="{
              'media-item-selected blue--text text--darken-1': localSelection,
              'media-item-unselected blue--text text--darken-1': !localSelection,
            }"
            :icon="localSelection ? 'circle-check' : ['far', 'circle']"
            @click.stop="toggleSelection"
            class="media-item-checkbox"
            size="2x"
            v-show="showSelection"
          />
        </VFadeTransition>
        <MediaDialog :disabled="noDialog" :id="id" :no-download="noDownload" v-model="showDialog"/>
      </VCard>
    </VHover>
  </VBtn>
</template>

<script>
  import Queries from "queries/index.js";
  import MediaDialog from "./MediaDialog.vue";
  import {all as mimeTypes} from "elements/media/mimeTypes.js";

  export default {
    components: {
      MediaDialog,
    },

    data: () => ({
      isHovering: false,
      isLongpressed: false,
      showDialog: false,
    }),

    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      disabled: Boolean,

      icon: [String, Array],

      noDialog: Boolean,

      noDownload: Boolean,

      noIcon: Boolean,

      noLabel: Boolean,

      selection: {
        type: [Array, Boolean],
        default: undefined,
      },

      allowSelection: Boolean,
    },

    apollo: {
      base: {
        query: Queries.Media.Single.MediaListBase,
        variables() {
          return {
            id: this.id,
          };
        },
        update: ({media}) => media,
      },
    },

    timers: {
      longpress: {time: 400},
    },

    computed: {
      usingSelection() {
        const {selection, allowSelection} = this;
        return Boolean(allowSelection) || (allowSelection === undefined && selection !== undefined);
      },

      selectionIsMultiple() {
        return Array.isArray(this.selection);
      },

      somethingIsSelected() {
        const {allowSelection, selectionIsMultiple, selection} = this;
        return Boolean(allowSelection) || (allowSelection === undefined && (selectionIsMultiple ? selection.length !== 0 : Boolean(selection)));
      },

      showSelection() {
        const {allowSelection, usingSelection, somethingIsSelected, isHovering, showDialog} = this;
        return Boolean(allowSelection) || (allowSelection === undefined && (usingSelection && !showDialog && (isHovering || somethingIsSelected)));
      },

      localSelection: {
        get() {
          const {id, selection, selectionIsMultiple} = this;
          return selectionIsMultiple ? selection.includes(id) : selection;
        },
        set(v) {
          const {selection, selectionIsMultiple, id} = this;
          if (selectionIsMultiple) {
            if (v) {
              if (!selection.includes(id)) {
                const payload = [...selection];
                payload.push(id);
                this.$emit('update:selection', payload);
              }
            } else {
              this.$emit('update:selection', selection.filter((e) => e !== id));
            }
          } else {
            this.$emit('update:selection', v);
          }
        },
      },

      localIcon() {
        const {icon, base} = this;
        return icon || (base ? Object.values(mimeTypes).find((e) => e.type === base.mimeType)?.icon || 'file' : 'file');
      },

      hasPreview() {
        return Boolean(this.base?.allowsConversion);
      },

      thumbnail() {
        return this.base?.conversions?.[0];
      },

      thumbnailUrl() {
        return this.thumbnail?.url;
      },
    },

    methods: {
      longpress() {
        const {usingSelection, toggleSelection} = this;
        this.isLongpressed = true;
        if (usingSelection) {
          toggleSelection();
        }
      },

      onContextmenu(e) {
        const {isLongpressed, timers} = this;
        if (isLongpressed || timers.longpress.isRunning) {
          e.preventDefault();
        }
      },

      toggleSelection() {
        this.localSelection = !this.localSelection;
      },
    },

    created() {
      this.$on('touchstart', () => {
        this.isLongpressed = false;
        this.$timer.stop('longpress');
        this.$timer.start('longpress');
      });

      this.$on('touchend', () => {
        this.$timer.stop('longpress');
      });

      this.$on('click', () => {
        if (this.isLongpressed) {
          this.isLongpressed = false;
        } else {
          if (this.somethingIsSelected) {
            this.localSelection = !this.localSelection;
          } else {
            this.showDialog = true;
          }
        }
      });
    }
  }
</script>

<!--<style>-->
<!--  .media-item-preview-icon {-->
<!--    position: absolute;-->
<!--    top: 3px;-->
<!--    left: 3px;-->
<!--    mix-blend-mode: difference;-->
<!--  }-->

<!--  .media-item-preview-title {-->
<!--    overflow: hidden;-->
<!--    position: absolute;-->
<!--    bottom: 0;-->
<!--    left: 0;-->
<!--    right: 0;-->
<!--    white-space: nowrap;-->
<!--    text-overflow: ellipsis;-->
<!--    backdrop-filter: blur(2px) contrast(50%) brightness(200%);-->
<!--  }-->

<!--  .media-item-file-title {-->
<!--    height: 100%;-->
<!--    overflow:hidden;-->
<!--    position: absolute;-->
<!--    top: 0;-->
<!--    left: 0;-->
<!--    right: 0;-->
<!--  }-->

<!--  .media-item-file-title:after {-->
<!--    content:"";-->
<!--    position:absolute;-->
<!--    bottom:0;-->
<!--    left:0;-->
<!--    height:1.5em;-->
<!--    width:100%;-->
<!--    background: linear-gradient(transparent, white);-->
<!--  }-->

<!--  .media-item-checkbox {-->
<!--    position: absolute;-->
<!--    top: 3px;-->
<!--    right: 3px;-->
<!--  }-->

<!--  .media-item-checkbox.media-item-selected {-->
<!--    border-radius: 50%; background: white;-->
<!--  }-->
<!--</style>-->
