<template>
  <VDialog v-model="show" max-width="400">
    <VCard color="grey lighten-3">
      <VCardTitle>
        <FaI icon="file-csv" class="mr-2"/>
        <span v-t="'actions.export'"/>
      </VCardTitle>
      <VCardText>
        <VBtn :download="name" :href="url" color="grey lighten-1">
          <FaI icon="download" class="mr-1"/>
          <span v-t="'actions.download'"/>
        </VBtn>
        <VBtn v-if="allowsShare" @click="doShare" class="ml-5" color="grey lighten-1">
          <FaI :icon="['far', 'share-nodes']" class="mr-1"/>
          <span v-t="'actions.share'"/>
        </VBtn>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import {format} from "date-fns";
import {zip} from 'lodash-es';

function getTimestampForFilename() {
  return format(new Date(), 'yyyy-MM-dd\'T\'HH-mm-ss');
}

function makeCsv(data, filename) {
  // This regex matches commas that are not preceded by a backslash
  const unescapedCommas = /(?<!\\),/g;

  let csvBuffer = '';
  data.forEach((row) => {
    csvBuffer += row
      .map((item) => typeof item === 'string' ? item.replace(unescapedCommas, '\\,') : item)
      .join(',') + '\n';
  });

  const blob = new Blob([csvBuffer], {type: 'text/csv;charset=utf-8,'});

  return new File([blob], filename, {
    type: 'text/csv',
  });
}

export default {
  data: () => ({
    file: undefined,
    name: undefined,
    url: undefined,
  }),

  props: {
    headers: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    prefix: [String, Object],

    transpose: Boolean,

    value: Boolean,
  },

  computed: {
    allowsShare() {
      return Boolean(navigator.share);
    },

    show: {
      get() {
        return this.value;
      },
      set(v) {
        return this.$emit('input', v);
      },
    },
  },

  watch: {
    show(v) {
      if (v) {
        this.createExport();
      } else {
        this.clearExport();
      }
    },
    transpose(v) {
      if (this.show) {
        this.createExport();
      }
    }
  },

  methods: {
    getExportFilename() {
      const prefix = this.prefix ? `${this.$t(this.prefix)} ` : '';

      return `${prefix}${this.$t('actions.export')} - ${getTimestampForFilename()}.csv`;
    },

    getExportData() {
      const data = [];

      data.push(this.headers.map(({text}) => text));
      this.items.forEach((item) => {
        data.push(this.headers.map(({value}) => item[value]));
      });

      return this.transpose ? zip(...data) : data;
    },

    createExport() {
      this.clearExport();

      const data = this.getExportData();

      this.name = this.getExportFilename();
      this.file = makeCsv(data, this.name);
      this.url = URL.createObjectURL(this.file);
      this.show = true;
    },

    doShare() {
      if (!navigator.share || !navigator.canShare(this.file)) {
        return;
      }

      navigator.share({
        title: this.name,
        text: this.name,
        files: [this.file],
      });
    },

    clearExport() {
      if (this.url) {
        URL.revokeObjectURL(this.url);
        this.url = undefined;
      }
      this.file = undefined;
      this.name = undefined;
    },
  },

  beforeDestroy() {
    this.clearExport();
  }
}
</script>
