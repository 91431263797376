<template>
  <MediaLibraryCollection
    v-bind="$attrs"
    v-on="$listeners"
    @change="onChange"
    :initial-value="initialValue"
    :validation-rules="mediaValidation"
  />
</template>

<script>
import Queries from 'queries/index.js';
import { MediaLibraryCollection } from "spatie-media-lib-pro/media-library-pro-vue2-collection";
import mimeTypes from '../../elements/media/mimeTypes.js';

const mediaToForm = ({uuid, name, size, mimeType, conversions, extension}) => ({
  uuid,
  name,
  preview_url: conversions?.find(({name}) => name === 'thumbnail')?.url,
  size,
  mime_type: mimeType,
  extension,
});

const mimeTypeParsed = (input) => {
  if (Array.isArray(input)) {
    return input;
  }

  const match = mimeTypes?.[input];

  return match ? Object.values(match).map(({type}) => type) : mimeTypes.all?.[input]?.type;
};

  export default {
    components: {
      MediaLibraryCollection,
    },

    props: {
      initialIds: Array,

      allowedTypes: [String, Array],

      maxSize: Number,
    },

    apollo: {
      medias: {
        query: Queries.Media.Browse.Medias,
        variables() {
          return {
            ids: this.initialIds,
          };
        },
        skip() {
          return !this.initialIds;
        },
      },
    },

    computed: {
      initialValue() {
        return this.medias?.map(mediaToForm);
      },

      mediaValidation() {
        return {
          ...this.allowedTypes && {accept: mimeTypeParsed(this.allowedTypes)},
          ...this.maxSize && {maxSizeInKB: this.maxSize},
        };
      },
    },

    methods: {
      onChange(event) {
        this.$emit('form-change', Object.values(event).map(({uuid, name}) => ({
          uuid,
          name,
        })));
      },
    },
  }
</script>
