<template>
  <ExpandPanelBrowser hide-toolbar query="Expense.Browse.List">
    <template v-slot:filter="{setVariables}">
      <ExpensesFilter
        :defaults="filterDefaults"
        :disables="filterDisables"
        :order-desc-default="orderDescDefault"
        :show-filter="showFilter"
        :sort-default="sortDefault"
        @filtered="setIsFiltered"
        @focus-project="setFilteredProject"
        @input="setVariables"
      />
    </template>
    <template v-slot:default="{data, isLoading, onCreate, onUpdate, onDelete}">
      <slot
        :data="data"
        :is-loading="isLoading"
        :on-create="onCreate"
        :on-update="onUpdate"
        :on-delete="onDelete"
      />
    </template>
    <template v-slot:item="{item: {id}, onUpdate, onDelete}">
      <ExpenseItem :focus-project="focusProject" :id="id" :key="id" @update="onUpdate" @delete="onDelete" :simple="simple"/>
    </template>
  </ExpandPanelBrowser>
</template>

<script>
import ExpandPanelBrowser from "elements/list/ExpandPanelBrowser.vue";
import ExpenseItem from "./ExpenseItem.vue";
import ExpensesFilter from "./ExpensesFilter.vue";

export default {
  data: () => ({
    focusProject: undefined,
  }),
  props: {
    filterDisables: Object,

    filterDefaults: {
      type: Object,
      default: () => ({
        dateRange: [],
        projectId: undefined,
        userOfficeId: undefined,
        projectOfficeId: undefined,
        userId: undefined,
        expenseTypeIds: [],
        isBillable: undefined,
      }),
    },

    sortDefault: {
      type: String,
      default: 'CREATED_AT',
    },

    orderDescDefault: {
      type: Boolean,
      default: true,
    },

    showFilter: Boolean,

    simple: Boolean,
  },

  components: {
    ExpensesFilter,
    ExpenseItem,
    ExpandPanelBrowser,
  },

  methods: {
    setIsFiltered(v) {
      this.$emit('filtered', v);
    },

    setFilteredProject(v) {
      this.focusProject = v;
    },
  },
}
</script>
