<template>
  <VBtn type="submit" :disabled="isDisabled" :loading="isRunning" :color="color">
    <slot>
      <FaI :icon="icon"/>&nbsp;
      {{ message }}
    </slot>
  </VBtn>
</template>

<script>
  import FormModes from './FormModes.js'

  export default {
    props: {
      mode: Number,

      isUpdate: Boolean,

      disabled: Boolean,
    },

    computed: {
      isDisabled() {
        const {disabled, mode} = this;
        return disabled || mode === FormModes.SUCCESS;
      },

      isRunning() {
        return this.mode === FormModes.RUNNING;
      },

      color() {
        return this.isUpdate ? 'amber' : 'info';
      },

      message() {
        return this.$t(`forms.general.${this.isUpdate ? 'update' : 'submit' }`);
      },

      icon() {
        return this.isUpdate ? 'pen-to-square' : 'plus';
      },
    },
  }
</script>
