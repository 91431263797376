import gql from 'graphql-tag';

// Forms

const Populate = gql`
  query(
    $id: ID!
  ) {
    role(
      id: $id
    ) {
      id
      name
      displayName
      context
    }
  }
`;

const Select = gql`
  query(
    $context: RoleContext
  ) {
    roles(
      context: $context
    ) {
      id
      name
      displayName
      context
    }
  }
`;


// Single

const Name = gql`
  query(
    $id: ID!
  ) {
    role(
      id: $id
    ) {
      id
      name
      displayName
      displayNamePlural
    }
  }
`;

const Description = gql`
  query(
    $id: ID!
  ) {
    role(
      id: $id
    ) {
      id
      description
    }
  }
`;


// Browse

const Team = gql`
  query(
    $context: RoleContext!
  ) {
    roles(
      context: $context
    ) {
      id
      name
      displayName
      displayNamePlural
    }
  }
`;


// Export

const Forms = {
  Populate,
  Select,
};

const Single = {
  Name,
  Description,
};

const Browse = {
  Team,
};

export default {
  Browse,
  Forms,
  Single,
};
