<template>
  <NowLoading v-if="$apollo.loading"/>
  <VList v-else>
    <template v-for="(item, index) in overviewItems">
      <VDivider v-if="index !== 0" :key="`div-${item.name}`"/>
      <VListItem :key="item.name">
        <VListItemContent>
          <VListItemTitle>
            <span v-t="{path: item.name, choice: 1}"/>:
            <strong>
              {{item.value}}
            </strong>
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
    </template>
  </VList>
</template>

<script>
  import Queries from 'queries/index.js'
  import currencyMixin from "../../../mixins/currencyMixin.js";

  export default {
    mixins: [
      currencyMixin
    ],

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    apollo: {
      company: {
        query: Queries.Company.Single.Overview,
        variables() {
          return {
            id: this.id,
          };
        },
      },
    },

    computed: {
      overviewItems() {
        const {company} = this;

        if (!company) {
          return [];
        }

        return [
          {
            name: 'models.company.name',
            value: company.name,
          },
          {
            name: 'models.company.nameShort',
            value: company.nameShort,
          },
        ];
      },
    },
  }
</script>
