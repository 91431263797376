<template>
  <VCard>
    <VCardTitle primary-title>
      <div>
        <div class="text-h5" v-t="'routes.office.browse.title'"/>
        <span v-t="'routes.office.browse.subtitle'"/>
      </div>
    </VCardTitle>
    <VCardText>
      <VFadeTransition leave-absolute>
        <VContainer v-if="$apollo.loading" key="loading">
          <VLayout row justify-center>
            <VFlex shrink>
              <VProgressCircular color="grey" size="64" indeterminate/>
            </VFlex>
          </VLayout>
        </VContainer>
        <VList v-else key="ready" two-line>
          <template v-for="(office, index) in offices">
            <VDivider v-if="index !== 0" :key="`div-${office.id}`"/>
            <OfficeListItem :id="office.id" :key="office.id"/>
          </template>
        </VList>
      </VFadeTransition>
    </VCardText>
  </VCard>
</template>

<script>
import Queries from 'queries/index.js'
import OfficeListItem from "./OfficeListItem.vue";

export default {
  components: {
    OfficeListItem,
  },

  data() {
    return {
      offices: [],
    };
  },

  apollo: {
    offices: {
      query: Queries.Office.Browse.List,
      variables: {
        orderBy: [
          {
            column: 'NAME',
            order: 'ASC',
          },
        ],
      },
    },
  },
}
</script>
