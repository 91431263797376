<template>
  <VExpansionPanel>
    <ExpenseItemHeader
      :id="id"
      :simple="simple"
      :focusProject="focusProject"
    />
    <ExpenseItemContent
      :id="id"
      @delete="onDelete"
      @update="onUpdate"
    />
  </VExpansionPanel>
</template>

<script>
  import ExpenseItemContent from './ExpenseItemContent.vue'
  import ExpenseItemHeader from './ExpenseItemHeader.vue'

  export default {
    components: {
      ExpenseItemContent,
      ExpenseItemHeader,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      simple: Boolean,

      focusProject: [Number, String],
    },

    methods: {
      onDelete(response) {
        this.$emit('delete', response);
      },

      onUpdate(response) {
        this.$emit('update', response);
      },
    },
  }
</script>
