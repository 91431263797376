import gql from "graphql-tag";
import Contact from './contact.js'
import User from './user.js'
import Team from './team.js'
import PolicyTest from './policyTest.js'

// Fragments

const listFragment = gql`
  fragment listFragment on Office {
    id
    slug
    name
    contact {
      id
      location {
        id
        addressInline
      }
    }
  }
`;


// Browse

const List = gql`
  query(
    $orderBy: [QueryOfficesOrderByOrderByClause!]
  ) {
    offices(
      orderBy: $orderBy
    ) {
      ...listFragment
      permissions(policies: [{policy: "view"}]) {
        ...isAllowedFragment
      }
    }
  }
  ${listFragment}
  ${PolicyTest.Fragments.IsAllowed}
`;

const ListItem = gql`
  query(
    $id: ID!,
  ) {
    office(
      id: $id
    ) {
      ...listFragment
    }
  }
  ${listFragment}
`;


// Single

const IdFromSlug = gql`
  query(
    $slug: String!
  ) {
    officeBySlug(
      slug: $slug
    )
    {
      id
      slug
    }
  }
`;


const Main = gql`
  query(
    $id: ID!
  ) {
    office(
      id: $id
    ) {
      id
      slug
      name
    }
  }
`;

const Overview = gql`
  query(
    $id: ID!
  ) {
    office(
      id: $id
    ) {
      id
      name
      contact {
        id
        ...contactStandardFragment
      }
    }
  }
  ${Contact.Fragments.Standard}
`;

const OfficeTeam = gql`
  query(
    $id: ID!
  ) {
    office(
      id: $id
    ) {
      id
      team {
        ...teamMembersDisplayFragment
      }
    }
  }
  ${Team.Fragments.MembersDisplay}
`;

const GetLocationForProjectWorkRegion = gql`
  query(
    $id: ID!
  ) {
    office(
      id: $id
    ) {
      id
      contact {
        id
        location {
          id
          countryDivision {
            id
          }
        }
      }
    }
  }
`;


// Forms

const Select = gql`
  query {
    offices {
      id
      name
    }
  }
`;

const Populate = gql`
  query(
    $id: ID!
  ) {
    office(
      id: $id
    ) {
      id
      name
    }
  }
`;

const GetManager = gql`
  query(
    $id: ID!
  ) {
    office(
      id: $id
    ) {
      id
      team {
        id
        roleGroups(roles: ["officeManager"]) {
          users {
            ...userThumbnailFragment
          }
        }
      }
    }
  }
  ${User.Fragment.ThumbnailFragment}
`;


// Write

const AttachUsers = gql`
  mutation(
    $id: ID!,
    $userIds: ID!,
    $role: OfficeTeamRole!
  ) {
    officeAttachUsers(
      id: $id,
      input: {
        users: $userIds,
        role: $role,
      }
    ) {
      id
      leads {
        ...userThumbnailFragment
      }
      members {
        ...userThumbnailFragment
      }
      supervisors {
        ...userThumbnailFragment
      }
      admins {
        ...userThumbnailFragment
      }
    }
  }
  ${User.Fragment.ThumbnailFragment}
`;

const DetachUsers = gql`
  mutation(
    $id: ID!,
    $userIds: ID!,
    $role: OfficeTeamRole
  ) {
    officeDetachUsers(
      id: $id,
      input: {
        users: $userIds,
        role: $role,
      }
    ) {
      id
      leads {
        ...userThumbnailFragment
      }
      members {
        ...userThumbnailFragment
      }
      supervisors {
        ...userThumbnailFragment
      }
      admins {
        ...userThumbnailFragment
      }
    }
  }
  ${User.Fragment.ThumbnailFragment}
`;


// Export

const Browse = {
  List,
  ListItem,
};

const Single = {
  IdFromSlug,
  Main,
  Overview,
  Team: OfficeTeam,
  GetLocationForProjectWorkRegion,
};

const Forms = {
  Select,
  Populate,
  GetManager,
};

const Write = {
  AttachUsers,
  DetachUsers,
};

export default {
  Browse,
  Single,
  Forms,
  Write,
};
