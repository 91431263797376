<template>
  <KDurationPickerDecimal v-if="showDecimal" v-model="localValue" v-bind="$attrs" v-on="$listeners"/>
  <KDurationPickerClock v-else v-model="localValue" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KDurationPickerClock from "./KDurationPickerClock.vue";
import KDurationPickerDecimal from "./KDurationPickerDecimal.vue";
import Queries from "queries/index.js";
import {mapGetters} from "vuex";

export default {
  components: {
    KDurationPickerClock,
    KDurationPickerDecimal,
  },

  props: {
    value: {
      type: Number,
      required: true,
    }
  },

  apollo: {
    showDecimal: {
      query: Queries.User.Single.DurationFormat,
      variables() {
        return {
          id: this.meId,
        };
      },
      update: ({user}) => user?.settings?.durationFormat === 'decimal',
      skip() {
        return !this.meId;
      },
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    localValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    },
  },
}
</script>
