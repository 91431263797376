import Main from './Main.vue'
import BottomNavigation from './PerformanceBottomNavigation.vue'
import Performance from './performance/Main.vue'
import Setup from './setup/Main.vue'

export default {
  Main,
  BottomNavigation,
  Performance,
  Setup,
}
