<template>
  <VExpandTransition>
    <VList two-line v-if="hasUsers || allowEdit">
      <VSubheader class="mt-4" v-bind="$attrs">
        {{usersCount === 1 ? role.displayName : role.displayNamePlural}}
        <VScrollXTransition>
          <TeamSubscribeBtn :id="teamId" :role="role.id" :users="users" text class="ml-2" v-if="allowEdit"/>
        </VScrollXTransition>
        <VSpacer/>
        <VBtn @click="showAttachUsers = !showAttachUsers" icon v-model="showAttachUsers" :disabled="isRunning" v-if="allowAttach">
          <FaI size="lg" icon="user-plus"/>
        </VBtn>
      </VSubheader>

      <VExpandTransition v-if="allowEdit">
        <VSkeletonLoader type="text" v-if="$apollo.queries.roleDescription.loading"/>
        <VListItem v-else dense>
          <VListItemTitle>
            {{roleDescription}}
          </VListItemTitle>
        </VListItem>
      </VExpandTransition>

      <VExpandTransition>
        <VCard tile v-if="allowAttach && showAttachUsers">
          <VCardTitle>
            {{ attachUsersTitle }}
          </VCardTitle>
          <VCardText>
            <FormTeamAttachUser :id="teamId" :role-id="role.id"/>
          </VCardText>
        </VCard>
      </VExpandTransition>

      <div v-if="!hasUsers" class="text-center">
        <FaI class="grey--text mt-3" icon="empty-set" size="lg"/>
      </div>

      <VListItemGroup v-else v-model="localSelections" multiple>
        <template v-for="({id: userId}, index) in users">
          <VDivider inset v-if="index !== 0" :key="`d-${userId}`"/>
          <UserListItem :id="userId" :key="userId" :selections.sync="allowDetach ? selections : undefined" :selection-value="`${roleGroup.id}:${userId}`"/>
        </template>
      </VListItemGroup>
    </VList>
  </VExpandTransition>
</template>

<script>
import UserListItem from "top/views/users/browse/UserListItem.vue";
import FormTeamAttachUser from "./FormTeamAttachUser.vue";
import TeamSubscribeBtn from "./TeamSubscribeBtn.vue";
import ClickConfirm from "elements/ClickConfirm.vue";
import Queries from 'queries/index.js';

export default {
  components: {
    ClickConfirm,
    FormTeamAttachUser,
    UserListItem,
    TeamSubscribeBtn,
  },

  data: () => ({
    showAttachUsers: false,
  }),

  props: {
    roleGroup: {
      type: Object,
      required: true,
    },

    allowEdit: Boolean,

    selections: Array,

    noSelections: Boolean,

    isDetaching: Boolean,
  },

  inject: ['teamId'],

  apollo: {
    roleDescription: {
      query: Queries.Role.Single.Description,
      variables() {
        return {
          id: this.role?.id,
        };
      },
      skip() {
        return !this.allowEdit;
      },
      update: (response) => response?.role?.description,
    },

    canAttach() {
      return this.policyChecker({
        policy: 'attachUser',
        type: 'Team',
        id: this.teamId,
        args: {
          role: this.role?.id,
        },
        skip: () => !this.allowEdit,
        fetchPolicy: 'cache-and-network',
      });
    },

    canDetach() {
      return this.policyChecker({
        policy: 'detachUser',
        type: 'Team',
        id: this.teamId,
        args: {
          role: this.role?.id,
        },
        skip: () => !this.allowEdit,
        fetchPolicy: 'cache-and-network',
      });
    },
  },

  watch: {
    allowEdit(v) {
      if (v) {
        this.$apollo.queries.canAttach.refetch();
        this.$apollo.queries.canDetach.refetch();
      }
      if (v) {
        this.showAttachUsers = false;
      }
    },
  },

  computed: {
    localSelections: {
      get() {
        return this.selections;
      },
      set(v) {
        this.$emit('update:selections', v);
      },
    },

    allowAttach() {
      return this.canAttach && this.allowEdit;
    },

    allowDetach() {
      return this.canDetach && this.allowEdit;
    },

    role() {
      return this.roleGroup?.role;
    },

    users() {
      return this.roleGroup?.users ?? [];
    },

    usersCount() {
      return this.users?.length ?? 0;
    },

    hasUsers() {
      return this.usersCount !== 0;
    },

    attachUsersTitle() {
      // Necessary due to transition animation causing unload of translated string before hide is complete
      return this.$t('views.team.attachUsersTitle', {name: this.role.displayNamePlural});
    },
  },
}
</script>
