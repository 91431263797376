<template>
  <NowLoading v-if="$apollo.loading"/>
  <div v-else>
    <VToolbar dense color="grey lighten-3">
      <VToolbarTitle v-t="{path: 'models.projectRecord.self', choice: 2}"/>
      <VSpacer/>
      <VBtn @click="toggleFilter" text>
        <FaI size="2x" icon="chevron-down" :rotation="showFilter ? 180 : 0" style="transition: all 0.3s ease;"/>
        <div>
          <VFadeTransition leave-absolute>
            <FaI size="2x" key="a" v-if="isFiltered" icon="filter"/>
            <FaI size="2x" key="b" v-else :icon="['far', 'filter']"/>
          </VFadeTransition>
        </div>
      </VBtn>
    </VToolbar>
    <ProjectRecordBrowseTab
      :filter-disables="{offices: true}"
      :filter-defaults="defaults"
      :show-filter="showFilter"
      @filtered="setIsFiltered"
    />
  </div>
</template>

<script>
import FormProjectRecordContainer from "top/views/projectRecords/forms/FormProjectRecordContainer.vue";
import ProjectRecordBrowseTab from "top/views/projectRecords/elements/ProjectRecordBrowseTab.vue";

export default {
  components: {
    FormProjectRecordContainer,
    ProjectRecordBrowseTab,
  },

  data: () => ({
    showFilter: false,
    isFiltered: false,
  }),

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  computed: {
    defaults() {
      return {offices: [this.id]};
    },
  },

  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    setIsFiltered(v) {
      this.isFiltered = v;
    },
  },
}
</script>
