<template>
  <VContainer>
    <VToolbar dense color="grey lighten-3">
      <VToolbarTitle v-t="{path: 'models.timeslip.self', choice: 2}"/>
      <VSpacer/>
      <VBtn @click="toggleFilter" text>
        <FaI size="2x" icon="chevron-down" :rotation="showFilter ? 180 : 0" style="transition: all 0.3s ease;"/>
        <div>
          <VFadeTransition leave-absolute>
            <FaI size="2x" key="a" v-if="isFiltered" icon="filter"/>
            <FaI size="2x" key="b" v-else :icon="['far', 'filter']"/>
          </VFadeTransition>
        </div>
      </VBtn>
    </VToolbar>
    <TimeslipBrowseTab
        :filter-disables="{projectId: true}"
        :filter-defaults="{projectId: id}"
        sort-default="UPDATED_AT"
        :order-desc-default="true"
        :show-filter="showFilter"
        @filtered="setIsFiltered"
    />
  </VContainer>
</template>

<script>
import TimeslipBrowseTab from "../../timeslips/elements/TimeslipBrowseTab.vue";

export default {
  components: {
    TimeslipBrowseTab,
  },

  data: () => ({
    showFilter: false,
    isFiltered: false,
  }),

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    setIsFiltered(v) {
      this.isFiltered = v;
    },
  },
}
</script>
