<template>
  <VSkeletonLoader type="article" v-if="$apollo.queries.team.loading"/>
  <div v-else>
    <VToolbar dense :color="allowEdit ? 'orange lighten-2' : 'grey lighten-3'">
      <VToolbarTitle>
        <span v-if="hasSelection" v-t="{path: 'views.team.selectedCount', choice: selectedCount}"/>
        <span v-else v-t="{path: 'models.team.self', choice: 1}"/>
      </VToolbarTitle>
      <VSpacer/>
      <VScaleTransition>
        <VBtn v-if="allowEdit" v-show="hasSelection" @click="doDetachUsers" class="mr-2" text :loading="isDetaching">
          <FaI icon="user-minus" size="lg"/>&nbsp;
          <span v-t="'actions.remove'"/>
        </VBtn>
      </VScaleTransition>
      <VBtn v-if="canInteract" text @click="allowEdit = !allowEdit" :input-value="allowEdit" :disabled="isDetaching">
        <FaI icon="users-gear" size="lg"/>
        <VExpandXTransition>
          <span v-show="!allowEdit" v-t="'actions.interact'" class="ml-2"/>
        </VExpandXTransition>
      </VBtn>
    </VToolbar>

    <VExpandTransition>
      <div class="d-flex justify-center">
        <VAlert v-if="isEmpty && !allowEdit" color="grey" outlined class="ma-4">
          <FaI icon="empty-set" size="lg"/>
          <span v-t="'views.team.empty'" class="ml-2"/>
        </VAlert>
      </div>
    </VExpandTransition>

    <template v-for="roleGroup in roleGroups">
      <TeamMembersListGroup :role-group="roleGroup" :key="roleGroup.id" :selections.sync="selections" :allow-edit="allowEdit"/>
    </template>
  </div>
</template>

<script>
import UserListItem from "top/views/users/browse/UserListItem.vue";
import FormTeamAttachUsers from "./FormTeamAttachUser.vue";
import TeamSubscribeBtn from "./TeamSubscribeBtn.vue";
import TeamMembersListGroup from "./TeamMembersListGroup.vue";
import ClickConfirm from "elements/ClickConfirm.vue";
import Queries from 'queries/index.js';
import {mapGetters} from 'vuex';

export default {
  components: {
    ClickConfirm,
    FormTeamAttachUsers,
    UserListItem,
    TeamMembersListGroup,
    TeamSubscribeBtn,
  },

  data: () => ({
    allowEdit: false,
    selections: [],
    isDetaching: false,
  }),

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  provide() {
    return {
      teamId: this.id,
    };
  },

  apollo: {
    team: {
      query: Queries.Team.Single.Members,
      variables() {
        const {id, meId: userId} = this;
        return {
          userId,
          id,
        };
      },
      subscribeToMore: {
        document: Queries.PolicyTest.Subscriptions.PoliciesOnModelUpdated,
        skip() {
          return !this.team || !this.allowEdit;
        },
        variables() {
          const {id, team} = this;
          const policies = ['attachUser', 'detachUser', 'join', 'leave'];
          const payload = team.roleGroups.map(({role: {id: role}}) =>
            policies.map((policy) => ({
              policy,
              arguments: JSON.stringify({role}),
            }))).flat();
          payload.push({
            policy: 'interact',
          });

          return {
            id,
            type: 'Team',
            policies: payload,
          };
        },
        updateQuery(previousResult, { subscriptionData }) {
          subscriptionData.data.policiesOnModelUpdated.forEach((data) => {
            this.$apollo.provider.defaultClient.writeFragment({
              id: data.id,
              fragment: Queries.PolicyTest.Fragments.IsAllowed,
              data
            });
          });
        },
      }
    },

    canInteract() {
      return this.policyChecker({
        policy: 'interact',
        type: 'Team',
        id: this.id,
        // subscribe: true,
      });
    },

    // canAttachUsers() {
    //   return this.policyChecker({
    //     policy: 'attachUser',
    //     type: 'Team',
    //     id: this.id,
    //     subscribe: true,
    //   });
    // },
    //
    // canDetachUsers() {
    //   return this.policyChecker({
    //     policy: 'detachUser',
    //     type: 'Team',
    //     id: this.id,
    //     subscribe: true,
    //   });
    // },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    roleGroups() {
      return this.team?.roleGroups ?? [];
    },

    selectedCount() {
      return this.selections.length;
    },

    hasSelection() {
      return this.selectedCount !== 0;
    },

    attachUsersTitle() {
      // Necessary due to transition animation causing unload of translated string before hide is complete
      return this.$t('views.team.attachUsersTitle');
    },

    isEmpty() {
      const {team} = this;
      return team ? team.roleGroups.reduce((a, b) => a + b.users.length, 0) === 0 : false;
    },
  },

  watch: {
    allowEdit(v) {
      if (!v) {
        this.selections = [];
      }
    },

    canInteract(v) {
      if (!v) {
        this.allowEdit = false;
      }
    }
  },

  methods: {
    async doDetachUsers() {
      const {id, selections} = this;

      this.isDetaching = true;
      try {
        const input = selections.map(function (value) {
          const [team, role, user] = value.split(':');
          return {
            user,
            role
          };
        });
        const response = await this.$apollo.mutate({
          mutation: Queries.Team.Write.DetachUsers,
          variables: {
            id,
            input,
          },
        });
      } finally {
        this.isDetaching = false;
        this.clearSelection();
      }
    },

    clearSelection() {
      this.selections = [];
    },
  },
}
</script>
