<template>
  <ListBrowserFilterWrapper
    export-mutation="Expense.Browse.Export"
    :form.sync="form"
    :form-default="parsedDefaults"
    :order-options="parsedSortOptions"
    :show-filter="showFilter"
    :form-function="$options.formFunction"
    @filtered="onFiltered"
    @input="onInput"
  >
    <VRow>
      <VCol class="py-0" cols="12" md="4">
        <KDatePicker
          clearable
          label="models.expense.date"
          range
          v-model="form.dateRange"
        />
      </VCol>
      <VCol class="py-0" cols="6" md="4">
        <VSelect
          :items="parsedBillableScope"
          clearable
          :label="$t('views.expenses.filter.billable.label')"
          v-model="form.isBillable"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="4">
        <KApiSelect
          :disabled="disables.expenseTypeIds"
          label="models.expenseType.shorthand"
          clearable
          multiple
          chips
          model="expenseType"
          v-model="form.expenseTypeIds"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="4">
        <KApiSelect
            :disabled="disables.userOfficeId"
            label="views.expenses.filter.userOffice"
            clearable
            model="office"
            v-model="form.userOfficeId"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="8">
        <KSuggest
          :disabled="disables.userId"
          clearable
          model="user"
          label="models.user.self"
          v-model="form.userId"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="4">
        <KApiSelect
          :disabled="disables.projectOfficeId"
          label="views.expenses.filter.projectOffice"
          clearable
          model="office"
          v-model="form.projectOfficeId"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="8">
        <ProjectPicker
          clearable
          :disabled="disables.projectId"
          v-model="form.projectId"
        />
      </VCol>
    </VRow>
  </ListBrowserFilterWrapper>
</template>

<script>
  import ListBrowserFilterWrapper from "elements/list/ListBrowserFilterWrapper.vue";
  import {KApiSelect, KDatePicker, KSuggest, ProjectPicker} from "forms/elements/index.js";

  const formFunction = ({projectId, userId, userOfficeId, projectOfficeId, expenseTypeIds, dateRange, isBillable}) => ({
    ...projectId && {projectId},
    ...userId && {userId},
    ...userOfficeId && {userOfficeId},
    ...projectOfficeId && {projectOfficeId},
    ...expenseTypeIds && expenseTypeIds.length !== 0 && {expenseTypeIds},
    ...dateRange && dateRange.length === 2 && {date: {from: dateRange[0], to: dateRange[1]}},
    ...isBillable && {isBillable},
  });

  const billableScope = [
    {
      text: 'views.expenses.filter.billable.scopes.all',
      value: 'ALL',
    },
    {
      text: 'views.expenses.filter.billable.scopes.some',
      value: 'SOME',
    },
    {
      text: 'views.expenses.filter.billable.scopes.none',
      value: 'NONE',
    }
  ];

  const sortOptions = [
    {
      text: 'models.expense.date',
      value: 'DATE',
    },
    {
      text: 'models.expense.amount',
      value: 'AMOUNT',
    },
    {
      text: 'models.expense.description',
      value: 'DESCRIPTION',
    },
    {
      text: 'models.expense.isReimbursable',
      value: 'IS_REIMBURSABLE',
    },
    {
      text: 'models.general.createdAt',
      value: 'CREATED_AT',
      secondary: true,
    },
    {
      text: 'models.general.updatedAt',
      value: 'UPDATED_AT',
    },
  ];

  const defaults = {
    dateRange: [],
    userId: undefined,
    projectId: undefined,
    userOfficeId: undefined,
    projectOfficeId: undefined,
    expenseTypeIds: [],
    isBillable: undefined,
  };

  export default {
    formFunction,

    components: {
      ListBrowserFilterWrapper,
      KApiSelect,
      KDatePicker,
      KSuggest,
      ProjectPicker,
    },

    data() {
      return {
        form: Object.assign({}, defaults, this.defaults),
      };
    },

    props: {
      showFilter: Boolean,

      defaults: {
        type: Object,
        default: () => ({}),
      },

      disables: {
        type: Object,
        default: () => ({}),
      },

      sortDefault: {
        type: String,
        default: 'DATE',
      },

      orderDescDefault: {
        type: Boolean,
        default: true,
      },
    },

    watch: {
      projectFilter: {
        handler(v) {
          this.$emit('focus-project', v);
        },
        immediate: true,
      },
    },

    computed: {
      parsedDefaults() {
        const payload = {};

        for (const key in defaults) {
          payload[key] = this.defaults.hasOwnProperty(key) ? this.defaults[key] : defaults[key];
        }

        return payload;
      },

      parsedSortOptions() {
        const {sortDefault, orderDescDefault} = this;
        const payload = [];

        sortOptions.forEach((item) => {
          payload.push(item.value === sortDefault
            ? Object.assign({}, item, {default: true, defaultDesc: orderDescDefault})
            : item
          );
        });

        return payload;
      },

      parsedBillableScope() {
        return billableScope.map(({text, value}) => ({
          text: this.$t(text),
          value,
        }));
      },

      projectFilter() {
        return this.form.projectId ?? undefined;
      },
    },

    methods: {
      onInput(v) {
        this.$emit('input', v);
      },

      onFiltered(v) {
        this.$emit('filtered', v);
      }
    },
  }
</script>
