export default {
  data: () => ({
    form: {},
    // formDefaults: null,
  }),

  props: {
    value: {
      type: Object,
    },
  },

  watch: {
    value: {
      handler(v) {
        this.setForm(v);
      },
      immediate: true,
      deep: true,
    },

    form: {
      handler(v) {
        this.$emit('input', this.getInput());
      },
      immediate: true,
      deep: true,
    },

    '$v': {
      handler(v) {
        this.$emit('validator', v);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    getInput() {
      return this.form;
    },

    setForm(v) {
      if (v && typeof v === 'object') {
        Object.keys(this.form).forEach((key) => {
          if (v.hasOwnProperty(key)) {
            this.form[key] = v[key];
          }
        });
      }
    },
  },
}
