import gql from 'graphql-tag';

// Fragments

const Fragment = gql`
  fragment timeslipDayFragment on TimeslipDay
  {
    id
    date
    duration
    context {
      __typename
      ... on BaseModel {
        id
      }
    }
  }
`;

const ActivityForChartsFragment = gql`
  fragment activityForChartsFragment on ActivityForCharts
  {
    id
    date
    durationBillable
    durationNotBillable
    durationAdmin
    durationAway
    isHoliday
    isWeekend
    targetDuration
    context {
      __typename
      ... on BaseModel {
        id
      }
    }
  }
`;


// Browse

const List = gql`
  query (
    $contextType: TimeslipDayContextTypes!
    $contextId: ID!
    $date: DateRange!
  ) {
    activityForCharts(
      contextType: $contextType
      contextId: $contextId
      date: $date
    ) {
      ...activityForChartsFragment
    }
  }
  ${ActivityForChartsFragment}
`;


// Single

const Main = gql`
  query(
    $id: ID!
  ) {
    timeslipDay(
      id: $id
    ) {
      ...timeslipDayFragment
    }
  }
  ${Fragment}
`;


// Subscriptions

const TimeslipDaySaved = gql`
  subscription onActivityForChartUpdated(
    $contextType: TimeslipDayContextTypes!
    $contextId: ID!
    $from: Date!
    $to: Date!
  ) {
    activityForChartUpdated (
      contextType: $contextType
      contextId: $contextId
      from: $from
      to: $to
    ) {
      ...activityForChartsFragment
    }
  }
  ${ActivityForChartsFragment}
`;


// Export

const Fragments = {
  Main: Fragment,
};

const Browse = {
  List,
};

const Single = {
  Main,
};

const Subscriptions = {
  TimeslipDaySaved,
};

export default {
  Browse,
  Fragments,
  Single,
  Subscriptions,
};
