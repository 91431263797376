import gql from 'graphql-tag';

// Fragments

const DownloadFragment = gql`
  fragment dataExportDownloadFragment on DataExport {
    id
    uuid
    status
    message
    payload {
      id
      url
    }
  }
`;


// Single

const Main = gql`
  query(
    $uuid: UUID!
  ) {
    dataExport(
      uuid: $uuid
    ) {
      ...dataExportDownloadFragment
    }
  }
  ${DownloadFragment}
`;


// Write

const Delete = gql`
  mutation(
    $uuid: UUID!
  ) {
    dataExportDelete(
      uuid: $uuid
    ) {
      id
    }
  }
`;


// Subscriptions

const DataExportUpdated = gql`
  subscription onDataExportUpdated(
    $uuid: UUID!
  ) {
    dataExportUpdated (
      uuid: $uuid
    )
    {
      ...dataExportDownloadFragment
    }
  }
  ${DownloadFragment}
`;


// Export

const Fragments = {
  DownloadFragment,
};

const Single = {
  Main,
};

const Write = {
  Delete,
};

const Subscriptions = {
  DataExportUpdated,
};

export default {
  Fragments,
  Single,
  Write,
  Subscriptions,
};
