<template>
  <form @submit.prevent="doSubmit">
    <VContainer>
      <VRow align="center">
        <VCol cols="12" md="6">
          <KDatePicker
            clearable
            label="models.activityExport.dateRange"
            :min="min"
            :max="max"
            range
            v-model="form.dateRange"
          />
        </VCol>
        <VCol cols="12" sm="6" md="4">
          <KCheckbox
            label="models.activityExport.includeRetired"
            v-model="form.includeRetired"
          />
        </VCol>
        <VCol cols="12" sm="6" md="2">
          <KBtnSubmit :mode="mode"/>
        </VCol>
      </VRow>
    </VContainer>
  </form>
</template>

<script>
  import Queries from 'queries/index.js';
  import {KBtnSubmit, KDatePicker, KCheckbox, FormMixin} from "forms/elements/index.js"
  import {length, required} from 'validators/index.js'
  import {subYears} from 'date-fns'
  import {formatISODate} from 'top/date-fns-format.js'

  export default {
    mixins: [
      FormMixin,
    ],

    components: {
      KBtnSubmit,
      KCheckbox,
      KDatePicker,
    },

    data: () => ({
      form: {
        dateRange: [],
        includeRetired: false,
      },
    }),

    validations: {
      form: {
        dateRange: {
          required,
          length: length(2),
        },
      },
      from: {
        required,
      },
      to: {
        required,
      }
    },

    computed: {
      min() {
        return formatISODate(subYears(new Date(), 1));
      },

      max() {
        return formatISODate(new Date());
      },

      from() {
        return this.form?.dateRange?.[0];
      },

      to() {
        return this.form?.dateRange?.[1];
      },
    },

    methods: {
      async createAction() {
        const {from, to, form: {includeRetired}} = this;

        const {data: {activityExportCreate: response}} = await this.$apollo.mutate({
            mutation: Queries.ActivityExport.Write.Create,
            variables: {
              from,
              to,
              includeRetired,
            },
          });
        return response;
      },

      formReset() {
        const {form} = this;
        form.dateRange = [];
        form.includeRetired = false;
      },
    },

    created() {
      this.$on('success', this.formReset);
    },
  }
</script>
