<template>
  <VFadeTransition>
    <VAlert text v-if="hasOverdue" type="error">
      <!--    <template v-slot:prepend>-->
      <!--      <FaI icon="analytics" size="lg" class="mr-4"/>-->
      <!--    </template>-->
      <VRow align="center">
        <VCol class="grow d-flex flex-nowrap">
          <span v-t="{path: 'views.projectRecords.status', args: {date: user.projectRecordDue, count: user.projectRecordsOverdueCount}}"/>
        </VCol>
        <VCol class="shrink">
          <VBtn color="primary" :to="{name: 'projectRecords'}">
            <FaI icon="chart-mixed" class="mr-2"/>
            <span v-t="'actions.fixNow'"/>
          </VBtn>
        </VCol>
      </VRow>
    </VAlert>
  </VFadeTransition>
</template>

<script>
  import {mapGetters} from "vuex";
  import Queries from 'queries/index.js';

  export default {
    apollo: {
      user: {
        query: Queries.ProjectRecord.Single.StatusReport,
        variables() {
          return {
            id: this.meId
          };
        },
        fetchPolicy: "cache-and-network",
      },
    },

    computed: {
      ...mapGetters('session', ['meId']),

      hasOverdue() {
        return this.user?.projectRecordsOverdueCount > 0;
      },
    }
  }
</script>
