<template>
  <ListBrowserFilterWrapper :show-filter="showFilter" :order-options="$options.orderOptions" :form.sync="form" :form-default="$options.filterDefaults" @input="onInput" @filtered="onFiltered">
    <VRow>
      <VCol class="py-0" cols="12" lg="4">
        <KSuggest
          clearable
          label="models.activityExport.createdBy"
          model="user"
          v-model="form.createdById"
        />
      </VCol>
    </VRow>
  </ListBrowserFilterWrapper>
</template>

<script>
  import ListBrowserFilterWrapper from "elements/list/ListBrowserFilterWrapper.vue";
  import {KSuggest} from "forms/elements/index.js";

  const filterDefaults = {
    createdById: undefined,
  };
  const orderOptions = [
    {
      text: 'models.general.id',
      value: 'ID',
      secondary: true,
    },
    {
      text: 'models.activityExport.from',
      value: 'FROM',
    },
    {
      text: 'models.activityExport.to',
      value: 'TO',
    },
    {
      text: 'models.activityExport.retrievedAt',
      value: 'RETRIEVED_AT',
    },
    {
      text: 'models.general.createdAt',
      value: 'CREATED_AT',
      default: true,
      defaultDesc: true,
    },
    {
      text: 'models.general.updatedAt',
      value: 'UPDATED_AT',
    },
  ];

  export default {
    orderOptions,

    filterDefaults,

    components: {
      ListBrowserFilterWrapper,
      KSuggest,
    },

    data: () => ({
      form: Object.assign({}, filterDefaults),
    }),

    props: {
      showFilter: Boolean,
    },

    methods: {
      onInput(v) {
        this.$emit('input', v);
      },

      onFiltered(v) {
        this.$emit('filtered', v);
      }
    },
  }
</script>
