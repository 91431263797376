const STATUSES = {
  IDLE: 0,
  RESET: 5,
  CHANGES: 10,
  SUBMIT: 20,
  SUCCESS: 40,
  MEASUREMENT: 50,
  PATTERN: 60,
  ERROR: 90
};

const SPEED = {
    type: 'n',
    max: 20, // metres per second
    hasImperial: true
  },
  OP = {
    type: 'n',
    max: 60, // seconds
    timer: true,
  },
  DOOR = {
    type: 'n',
    max: 20, // seconds
    timer: true,
  },
  DWELL = {
    type: 'n',
    max: 60, // seconds
    timer: true,
  },
  NUDGE = {
    type: 'i',
    max: 120, // seconds
    timer: true,
  },
  NOISE = {
    type: 'i',
    min: 40,
    max: 100, // dBA
  },
  LEVEL = {
    type: 'i',
    max: 150, // millimetres
    hasImperial: true
  },
  FORCE = {
    type: 'i',
    max: 200, // N
    hasImperial: true
  },
  FIELDS = {
    SpeedUp: SPEED,
    SpeedDown: SPEED,
    OperatingUpTime: OP,
    OperatingDownTime: OP,
    DoorOpenTime: DOOR,
    DoorCloseTime: DOOR,
    CarCallDwellTime: DWELL,
    HallCallDwellTime: DWELL,
    NudgingTime: NUDGE,
    OperatingNoise: NOISE,
    FanNoise: NOISE,
    DoorNoise: NOISE,
    LevelingAccuracy: LEVEL,
    DoorForce: FORCE,
  },
  ORDERED = Object.entries(FIELDS).map(([name, props]) => ({
    name,
    ...props,
  })),
  NAMES = Object.keys(FIELDS);

export {
  STATUSES,
  FIELDS,
  NAMES,
  ORDERED,
};

export default {
  STATUSES,
  FIELDS,
  NAMES,
  ORDERED,
};
