<template>
  <SettingsContainer name="locale" :items="items"/>
</template>

<script>
import SettingsContainer from "./SettingsContainer.vue";

const locales = [
  'en-CA',
  'fr-CA',
];

export default {
  components: {
    SettingsContainer,
  },

  computed: {
    items() {
      return locales.map((value) => ({
        label: this.$t(`locales.${value}`),
        value,
      }));
    },
  },
}
</script>
