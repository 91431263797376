<template>
  <VSkeletonLoader type="article" v-if="$apollo.loading"/>
  <form v-else @submit.prevent="doSubmit" @keyup.enter="doSubmit">
    <KTextField
      :value="user.email"
      label="models.user.email"
      hint="forms.general.cannotChange"
      disabled
    />

    <KTextField
      v-model="form.name"
      :validator="$v.form.name"
      label="models.user.fullName"
      hint="forms.user.fullName_helpblock"
    />

    <KTextField
      v-model="form.title"
      :validator="$v.form.title"
      label="models.user.title"
      hint="forms.general.optional"
    />

    <VRadioGroup v-model="form.locale" row :label="$t('models.user.settings.locale')">
      <VRadio v-for="{label, value} in localeItems" :label="label" :key="value" :value="value"/>
    </VRadioGroup>

    <VCard>
<!--      <VCardTitle>-->
<!--        Base role: {{staffRole.displayName}}-->
<!--      </VCardTitle>-->
<!--      <VCardSubtitle>-->
<!--        This is the only base role currently supported.-->
<!--      </VCardSubtitle>-->
      <VCardText>
<!--        <KSelect-->
<!--          v-model="form.roles"-->
<!--          :items="rolesFiltered"-->
<!--          label="Additional roles"-->
<!--          hint="Optional, these additional roles grant additional security permissions. Leave blank if unsure what this means."-->
<!--          item-text="displayName"-->
<!--          item-value="name"-->
<!--          chips-->
<!--          multiple-->
<!--          clearable-->
<!--        />-->

        <KTextField
          v-model.number="form.staff.employeeId"
          :validator="$v.form.staff.employeeId"
          label="models.user.settings.employeeId"
          hint="Required, this is the company internal ID number assigned by Accounting."
          type="number"
        />

<!--        <KCurrencyField-->
<!--          v-model="form.staff.billingRate"-->
<!--          :validator="$v.form.staff.billingRate"-->
<!--          label="Billing rate"-->
<!--          hint="forms.general.required"-->
<!--          type="number"-->
<!--        />-->
      </VCardText>
    </VCard>

<!--    <FormContact-->
<!--      v-model="form.contact"-->
<!--      :email-disable="true"-->
<!--      class="mt-3"-->
<!--    />-->

    <KBtnSubmit :mode="mode" class="mt-3"/>
  </form>
</template>

<script>
  import FormMixin from 'forms/elements/FormMixin.js'
  import Queries from 'queries/index.js';
  import {KCheckbox, KApiSelect, KSuggest, KSelect, KBtnSubmit, KTextField} from "forms/elements/index.js"
  import {betweenLength, required, email, maxLength, password, sameAs, primaryKey, between, unique} from 'validators/index.js'
  import KCurrencyField from "forms/elements/KCurrencyField.vue";
  import {formatISODate} from "top/date-fns-format.js"
  import {startOfToday} from "date-fns";
  import {debounce} from "lodash-es";

  const locales = [
    'en-CA',
    'fr-CA',
  ];

  export default {
    mixins: [
      FormMixin,
    ],

    components: {
      KCurrencyField,
      KApiSelect,
      KCheckbox,
      KSelect,
      KSuggest,
      KBtnSubmit,
      KTextField,
    },

    data: () => ({
      // companyTypeId: '1',
      // companyTypes: [],
      form: {
        name: '',
        title: '',
        locales: 'en-CA',
        staff: {
          employeeId: '',
        },
      },
    }),

    apollo: {
      // companyTypes: {
      //   query: Queries.CompanyType.Select,
      //   skip() {
      //     return this.isUpdate;
      //   }
      // },
      // company: {
      //   query: Queries.Company.Forms.Populate,
      //   skip() {
      //     return !this.isUpdate;
      //   }
      // },
      // offices: {
      //   query: Queries.Office.Select,
      // },
      user: {
        query: Queries.User.Write.FormFill,
        variables() {
          return {
            id: this.target,
          };
        },
        skip() {
          return !this.isUpdate;
        },
      },
      // roles: {
      //   query: Queries.Role.Forms.Select,
      //   variables() {
      //     return {
      //       context: null,
      //     };
      //   },
      // },
    },

    validations: {
      form: {
        name: {
          required,
          betweenLength: betweenLength(4, 128),
        },
        title: {
          betweenLength: betweenLength(2, 64),
        },
        staff: {
          employeeId: {
            required,
            primaryKey,
          },
        },
      },
    },

    watch: {
      user(v) {
        if (!v) {
          return;
        }

        // this.addItemToSelectList(v.company, this.companies);

        const {form} = this;
          // {contact} = form,
          // {location} = contact,
          // vContact = v.contact;

        form.name = v.nameParsed.source;
        form.title = v.title;
        form.locale = v.settings.locale;
        // form.companyId = v.company.id;
        form.staff.employeeId = v.settings.employeeId;
        // form.password = '';
        // form.passwordConfirm = '';

        // if (vContact) {
        //   const {email, phones} = vContact;
        //   contact.email = email;
        //   contact.phones = phones;
        //
        //   const vLocation = vContact.location;
        //   if (vLocation) {
        //     const {city, country, countryDivision, street, streetExtra, postalCode} = vLocation;
        //     location.cityId = city.id;
        //     location.countryId = country.id;
        //     location.countryDivisionId = countryDivision.id;
        //     location.street = street;
        //     location.streetExtra = streetExtra;
        //     location.postalCode = postalCode;
        //   }
        // }
      },
    },

    computed: {
      localeItems() {
        return locales.map((value) => ({
          label: this.$t(`locales.${value}`),
          value,
        }));
      },
    },

    methods: {
      // debouncedEmailInput: debounce(
      //   function (v) {
      //     return this.form.email = v;
      //   }, 500, {maxWait: 2000}
      // ),

      // async createAction() {
      //   const {form: {email, name, title, companyId, roles, staff: {employeeId, billingRate, officeId, officeRole}}} = this;
      //
      //     //{location, phones} = contact,
      //   const variables = {
      //       email,
      //       name,
      //       employeeId,
      //     billingRate,
      //     billingRateStartAt: formatISODate(startOfToday()),
      //     roles: ['staff', ...roles],
      //     officeId,
      //     officeRole,
      //       ...title && {title},
      //       ...(companyId && {companyId}),
      //       // ...phones && {phones: JSON.stringify(phones)},
      //       // ...(location.cityId && location),
      //     },
      //     {data: { userCreate: response}} = await this.$apollo.mutate({
      //       mutation: Queries.User.Write.Create,
      //       variables,
      //     });
      //   return response;
      // },

      async updateAction() {
        const {form: {name, title, locale, staff: {employeeId}}} = this;
          //{location, phones} = contact,
        const variables = {
          id: this.user.id,
          name,
          title: title || null,
          employeeId,
          ...locale && {locale},
          },
          {data: { userUpdate: response}} = await this.$apollo.mutate({
            mutation: Queries.User.Write.Update,
            variables,
          });
        return response;
      },
    },
  }
</script>
