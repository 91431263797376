<template>
  <VRow>
    <VCol>
      <VBtn :color="submitBtnColor" :disabled="submitDisabled" @click="doSubmit">
        <VProgressCircular
          v-if="isCountdown"
          v-model="countdownRad"
          :size="16"
          :width="3"
          :rotate="270"
        />
        <VProgressCircular
          v-else-if="isSubmit"
          :size="16"
          :width="3"
          :indeterminate="true"
        />
        <FaI v-else :fixed-width="true" :icon="submitBtnIcon"/>
        <span v-t="'actions.commit'"/>
      </VBtn>
      <VSpacer/>
      <VBtn @click="doReset" :disabled="resetDisabled">
        <FaI icon="rotate"/>
        <span v-t="'actions.reset'"/>
      </VBtn>
    </VCol>
  </VRow>
</template>

<script>
  import {STATUSES} from './perfConsts.js'

  const AUTO_SHOW_RADIAL = 5000;
  const AUTO_STEP_TIME = 500;

  export default {
    data: () => ({
      countdown: -1,
    }),

    inject: ['performanceMeta', 'performancePattern'],

    computed: {
      status() {
        return this.performancePattern.status;
      },

      submitBtnIcon() {
        switch (this.status) {
          case STATUSES.SUCCESS:
            return 'circle-check';
          case STATUSES.ERROR:
            return 'triangle-exclamation';
          default:
            return 'pen-to-square';
        }
      },

      submitBtnColor() {
        return this.status === STATUSES.SUCCESS ? 'success' : 'primary';
      },

      resetDisabled() {
        return this.status !== STATUSES.CHANGES && this.status !== STATUSES.ERROR;
      },

      submitDisabled() {
        return this.status !== STATUSES.CHANGES;
      },

      isCountdown() {
        return this.timers.ticker.isRunning;
      },

      isSubmit() {
        return this.status === STATUSES.SUBMIT;
      },

      countdownRad() {
        return this.isCountdown && this.countdown >= 0 ? Math.round(this.countdown / AUTO_SHOW_RADIAL * 100) : 100;
      },

      radialIndeterm() {
        return this.status === STATUSES.SUBMIT;
      },

      allowAutoSubmit() {
        return !this.submitDisabled && !this.isActive && this.performanceAuto
      }
    },

    timers: {
      ticker: {time: AUTO_STEP_TIME, repeat: true}
    },

    watch: {
      allowAutoSubmit(v) {
        if (v) {
          this.startAutoSubmit();
        } else if (this.isCountdown) {
          this.endAutoSubmit();
        }
      },
    },

    methods: {
      startAutoSubmit() {
        this.countdown = AUTO_SHOW_RADIAL;
        this.$timer.start('ticker');
      },

      doAutoSubmit() {
        this.endAutoSubmit();
        this.doSubmit();
      },

      endAutoSubmit() {
        this.countdown = -1;
        this.$timer.stop('ticker');
      },

      ticker() {
        if (this.countdown <= 0) {
          this.doAutoSubmit();
          return;
        }
        this.countdown -= AUTO_STEP_TIME;
      },
    }
  }
</script>
