import Vue from 'vue'
import Vuetify, { VTextField } from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors';
import VCurrencyField from "v-currency-field";
import { Intersect } from 'vuetify/lib/directives'
import {en, fr} from "vuetify/es5/locale"

const locales = {en, fr}

Vue.use(Vuetify, {
  directives: {
    Intersect,
  },
});

// VCurrencyField only works with global import, so for now, do it
Vue.component('v-text-field', VTextField); // For global VCurrencyField to counter treeshaking
Vue.use(VCurrencyField, {
  defaultValue: null,
});

const FAIcons = {
  complete: 'check',
  cancel: 'times-circle',
  close: 'xmark',
  delete: 'circle-xmark',
  clear: 'circle-xmark',
  success: 'circle-check',
  info: 'circle-info',
  warning: 'exclamation',
  error: 'triangle-exclamation',
  prev: 'chevron-left',
  next: 'chevron-right',
  checkboxOn: 'square-check',
  checkboxOff: ['far', 'square'],
  checkboxIndeterminate: 'square-minus',
  delimiter: 'circle',
  sort: 'sort-up',
  expand: 'chevron-down',
  menu: 'bars',
  subgroup: 'caret-down',
  dropdown: 'caret-down',
  radioOn: ['far', 'circle-dot'],
  radioOff: ['far', 'circle'],
  edit: 'pen',
  ratingEmpty: ['far', 'star'],
  ratingFull: 'star',
  ratingHalf: 'star-half',
  loading: 'rotate',
  first: 'step-backward',
  last: 'step-forward',
  unfold: 'arrows-alt-v',
  file: 'paperclip',
  plus: 'plus',
  minus: 'minus',
},
  FAIconsParsed = (() => {
    // Patch in the fa-svg icons until https://github.com/vuetifyjs/vuetify/pull/8092 is merged
    const icons = Object.entries(FAIcons),
      component = 'font-awesome-icon',
      payload = {};

    for (const [key, icon] of icons) {
      payload[key] = {
        component,
        props: {
          icon,
        },
      };
    }

    return payload;
  })();

export default new Vuetify({
  lang: {
    locales,
  },
  icons:{
    iconfont: 'faSvg', // Set Vuetify to use font-awesome 5 svg icon fonts
    values: FAIconsParsed,
  },
  // breakpoint: {
  //   mobileBreakpoint: 'sm',
  // },
  theme: {
    themes: {
      light: {
        primary: colors.indigo.base,
        secondary: colors.lightGreen.accent4,
        accent: colors.green.darken1,
        // error: colors.red.base,
        // warning: colors.yellow.base,
        // info: colors.blue.base,
        // success: colors.green.base,
      },
    },
  },
});
