<template>
  <VDialog scrollable>
    <template #activator="{on, attrs}">
      <slot name="activator" :on="on" :attrs="attrs">
        <VBtn color="orange lighten-4" class="orange--text text--darken-2" small v-bind="attrs" v-on="on">
          <FaI icon="plus"/>
          <FaI icon="star" class="mr-1"/>
          <span v-t="'forms.activityPartition.createTemplate'"/>
        </VBtn>
      </slot>
    </template>
    <template #default="dialog">
      <PartitionsSetContent
        v-bind="$attrs"
        @cancel="dialog.value = false"
        @success="$emit('success', $event); dialog.value = false"
      />
    </template>
  </VDialog>
</template>

<script>
import PartitionsSetContent from './PartitionsSetContent.vue';

export default {
  components: {
    PartitionsSetContent,
  },
}
</script>
