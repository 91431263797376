<template>
  <VContainer>
    <ListBrowser title="The Dispatcher" query="Project.Browse.Dispatcher" ref="listBrowser">
      <template #title-actions>
        <VDialog max-width="500">
          <template #activator="{on, attrs}">
            <VBtn color="grey" icon v-bind="attrs" v-on="on" class="ml-2">
              <FaI size="lg" :icon="['far', 'circle-info']"/>
            </VBtn>
          </template>
          <template #default="dialog">
            <VCard>
              <VCardTitle>
                About the Dispatcher
              </VCardTitle>
              <VCardText>
                <p>
                  This tool is temporarily available to optimize the transition from "Project Data" to Hoistway. Use this to quickly review the projects currently booked to you as Lead. You may reduce your Project Record reporting burden by either:
                </p>
                <ul>
                  <li>Change the Project Status to "complete" or "lost";</li>
                  <li>Reassign the Lead role to a more appropriate colleague.</li>
                </ul>
                <p>
                  This is also a good opportunity to verify the Project Types, especially for those assigned "consulting". Change it to a more appropriate type whenever possible.
                </p>
              </VCardText>
              <VCardActions>
                <VSpacer/>
                <VBtn text color="primary" @click="dialog.value = false">
                  <span v-t="'actions.close'"/>
                </VBtn>
              </VCardActions>
            </VCard>
          </template>
        </VDialog>
      </template>
      <template #default>
        <VExpandTransition>
          <VAlert dense type="warning" v-if="userId !== meId">
            <VRow align="center">
              <VCol class="grow d-flex flex-nowrap">
                <span v-if="userId === null">
                  You have exposed all projects for editing, including those which are not yours.
                </span>
                <span v-else>
                  You are managing projects on behalf of {{ user.name }}.
                </span>
              </VCol>
              <VCol class="shrink">
                <VBtn @click="userId = meId">
                  <span v-t="'actions.end'"/>
                </VBtn>
              </VCol>
            </VRow>
          </VAlert>
        </VExpandTransition>
      </template>
      <template #actions>
        <VDialog max-width="500">
          <template #activator="{on, attrs}">
            <VBtn :input-value="userId !== meId" text v-bind="attrs" v-on="on">
              <FaI size="2x" icon="users-gear"/>
            </VBtn>
          </template>
          <template #default="dialog">
            <VCard>
              <VCardTitle>
                Set Target User
              </VCardTitle>
              <VCardText>
                <p>
                  Dispatcher will present your own projects by default, but you may use this tool on behalf of another. Use this carefully and only with permission.
                </p>
                <KSuggest
                  model="user"
                  :label="{path: 'models.user.self', choice: 1}"
                  v-model="userId"
                  clearable
                >
                  <template #append-outer>
                    <VBtn small color="primary" @click="userId = meId" :disabled="userId === meId">
                      <span v-t="'actions.reset'"/>
                    </VBtn>
                  </template>
                </KSuggest>
              </VCardText>
              <VCardActions>
                <VSpacer/>
                <VBtn text color="primary" @click="dialog.value = false">
                  <span v-t="'actions.close'"/>
                </VBtn>
              </VCardActions>
            </VCard>
          </template>
        </VDialog>
      </template>
      <template #filter="{setVariables, setIsFiltered, showFilter}">
        <ProjectsFilter @input="setVariables" :defaults="filterDefaults" :disables="filterDisables" @filtered="setIsFiltered" :show-filter="showFilter" sort-default="ID" :order-desc-default="false"/>
      </template>
      <template #item="{item}">
        <ProjectDispatcherItem :id="item.id" :user-id="userId" :key="item.id" @lead="onNewLead"/>
      </template>
    </ListBrowser>
  </VContainer>
</template>

<script>
import ListBrowser from "elements/list/ListBrowser.vue";
import ProjectsFilter from "top/views/projects/browse/elements/ProjectsFilter.vue";
import ProjectDispatcherItem from "./ProjectDispatcherItem.vue";
import {mapGetters} from 'vuex'
import {KSuggest} from "forms/elements/index.js"
import Queries from 'queries/index.js'

export default {
  components: {
    ListBrowser,
    ProjectsFilter,
    ProjectDispatcherItem,
    KSuggest,
  },

  data() {
    return {
      userId: null,
    };
  },

  apollo: {
    user: {
      query: Queries.User.Forms.Populate,
      variables() {
        return {
          id: this.userId,
        };
      },
      skip() {
        return !this.userId;
      },
    }
  },

  computed: {
    ...mapGetters('session', ['meId']),

    filterDefaults() {
      return this.userId
        ? {
          userId: this.userId,
          roleId: "9", // projectLead
        }
        : undefined;
    },

    filterDisables() {
      return this.userId
        ? {
          userId: true,
        }
        : undefined;
    }
  },

  methods: {
    onNewLead() {
      this.$refs.listBrowser.$emit('ping');
    },
  },

  created() {
    this.userId = this.meId;
  },
}
</script>
