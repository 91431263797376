<template>
  <VCard outlined>
    <VToolbar color="blue lighten-5" dense elevation="0">
      <FaI class="grey--text mr-2" icon="paperclip"/>
      <VToolbarTitle>
        <span v-t="{path: 'models.expense.receipt', choice: 2}"/>
      </VToolbarTitle>
    </VToolbar>
    <VCardText>
      <ReceiptAttachAlert v-if="!hasAttachments && isMe"/>
      <div class="text-center" v-if="!hasAttachments">
        <FaI icon="empty-set"/>
      </div>
      <MediaListItem
        :id="attachment.id"
        :key="attachment.id"
        class="ma-2"
        v-else
        v-for="attachment in attachments"
      />
    </VCardText>
  </VCard>
</template>

<script>
  import Queries from "queries/index.js";
  import MediaListItem from "elements/media/MediaListItem.vue";
  import ReceiptAttachAlert from './ReceiptAttachAlert.vue';
  import {mapGetters} from "vuex";

  export default {
    components: {
      MediaListItem,
      ReceiptAttachAlert,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    apollo: {
      expense: {
        query: Queries.Expense.Single.Receipts,
        variables() {
          return {
            id: this.id,
          };
        },
      },
    },

    computed: {
      ...mapGetters('session', ['meId']),

      isMe() {
        const {expense, meId} = this;
        return expense ? expense.user.id === meId : false;
      },

      attachments() {
        return this.expense?.receipts ?? [];
      },

      hasAttachments() {
        return this.attachments.length !== 0;
      },
    },
  }
</script>
