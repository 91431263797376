<template>
  <VSkeletonLoader
    type="card@2"
    v-if="$apollo.loading"
  />
  <VContainer v-else>
    <span class="text-h5" v-t="'models.user.identicon'"/>
    <VContainer v-if="hasIdenticon">
      <span class="text-h6" v-t="'views.identicon.active.title'"/>
      <VRow align="end">
        <VCol cols="auto" v-for="size in identiconSizes" :key="size">
          <UserThumbnail :id="meId" disabled :size="size"/>
        </VCol>
        <VCol cols="12">
          <ClickConfirm color="red" :loading="isDeleting" @confirm="doIdenticonDelete">
            <FaI icon="trash"/>&nbsp;
            <span v-t="'actions.remove'"/>
          </ClickConfirm>
        </VCol>
      </VRow>
    </VContainer>
    <VAlert type="info" text>
      <div class="d-flex align-center">
        <div class="flex-shrink-1">
          <VAvatar>
            <img :src="defaultIcon" :alt="user.name">
          </VAvatar>
        </div>
        <div class="flex-grow-1 ml-2" v-t="`views.identicon.active.${hasIdenticon ? 'remove_msg' : 'description'}`"/>
      </div>
    </VAlert>
    <FormIdenticon :target="meId"/>
  </VContainer>
</template>

<script>
import FormIdenticon from "./FormIdenticon.vue";
import generateAvatar from "top/avatars.js";
import Queries from "queries/index.js";
import ClickConfirm from "elements/ClickConfirm.vue";
import {UserThumbnail} from "elements/index.js";
import {mapGetters} from "vuex";

const identiconSizes = ['lg', 'md', 'sm', 'xs'];

export default {
  components: {
    ClickConfirm,
    UserThumbnail,
    FormIdenticon,
  },

  data: () => ({
    isDeleting: false,
    identiconSizes,
  }),

  apollo: {
    user: {
      query: Queries.User.Info.Thumbnail,
      variables() {
        return {
          id: this.meId,
        };
      },
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    hasIdenticon() {
      const {user} = this;
      return Boolean(user?.identicon?.url);
    },

    defaultIcon() {
      const {user} = this;

      if (!user) {
        return undefined;
      }

      return generateAvatar(user.email);
    },
  },

  methods: {
    async doIdenticonDelete() {
      this.isDeleting = true;

      try {
        await this.$apollo.mutate({
          mutation: Queries.User.Write.IdenticonClear,
          variables: {
            id: this.meId,
          },
        });
      } finally {
        this.isDeleting = false;
      }
    }
  },
}
</script>
