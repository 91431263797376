<template>
  <VExpansionPanelContent class="grey lighten-4">
    <VSkeletonLoader type="list-item-two-line" v-if="$apollo.queries.expense.loading"/>
    <div v-else-if="expense" v-show="!edit">
      <VRow>
        <VCol cols="12" class="mt-2">
            {{expense.description}}
        </VCol>
        <VCol cols="12">
          <ExpenseAttachments :id="expense.id"/>
        </VCol>
        <VCol cols="12" v-if="hasMultipleProjects">
          <VCard outlined>
            <VCardTitle class="blue lighten-5">
              <FaI class="blue--text text--lighten-2 mr-2" icon="layer-group"/>
              <span v-t="{path: 'models.project.self', choice: 2}" class="mr-2"/>
              <FaI v-if="hasActivityTemplate" icon="star" class="orange--text text--darken-2" size="sm"/>
              <PartitionsSetDialog v-else-if="canCopyToPartitionsSet" :partitions="partitionsForSet" @success="onPartitionsSetSuccess"/>
            </VCardTitle>
            <VList>
              <template v-for="(partition, index) in expense.expensePartitions">
                <VDivider :key="`d-${partition.id}`" v-if="index !== 0"/>
                <ExpenseItemPartition :id="partition.id" :key="partition.id"/>
              </template>
            </VList>
          </VCard>
        </VCol>
        <VCol v-if="!hasMultipleProjects" cols="12" md="7" lg="8">
          <VCard flat>
            <ProjectListItem :id="expense.expensePartitions[0].project.id" dense/>
          </VCard>
        </VCol>
        <VCol cols="12" :md="!hasMultipleProjects ? '5' : undefined" :lg="!hasMultipleProjects ? '4' : undefined">
          <VCard flat>
            <UserListItem :id="expense.user.id"/>
          </VCard>
        </VCol>
      </VRow>
      <VRow v-if="!expense.isReimbursable">
        <VCol>
          <VAlert text :icon="false" type="warning">
            <template v-slot:prepend>
              <div class="mr-4">
                <FaLayers class="fa-2x">
                  <FaI icon="money-bill-wave" size="xs" class="green--text"/>
                  <FaI :icon="['far', 'ban']" class="red--text"/>
                </FaLayers>
              </div>
            </template>
            <span v-t="'views.expenses.notReimbursable'"/>
          </VAlert>
        </VCol>
      </VRow>
      <VRow class="grey--text py-2" justify="space-between" no-gutters>
        <VCol class="d-flex flex-column" cols="auto">
          <small>
            {{createdTimestamp}}
          </small>
          <small v-if="updatedTimestamp">
            <FaI icon="pen"/>
            {{updatedTimestamp}}
          </small>
        </VCol>
        <VCol cols="auto" v-if="canInteract">
          <VDialog max-width="250" v-if="canDelete" v-model="showDelete">
            <template v-slot:activator="{on}">
              <VBtn :disabled="isDeleting" :loading="isDeleting" color="red" small v-on="on">
                <FaI icon="trash"/>&nbsp;
                <span v-t="'actions.delete'"/>
              </VBtn>
            </template>

            <VCard>
              <VCardTitle class="text-h5" v-t="'actions.confirm'"/>
              <VCardActions>
                <VSpacer/>
                <VBtn @click="doDelete" color="red darken-1" text>
                  <span v-t="'actions.delete'"/>
                </VBtn>
                <VBtn @click="showDelete = false" color="grey" text>
                  <span v-t="'actions.cancel'"/>
                </VBtn>
              </VCardActions>
            </VCard>
          </VDialog>
          <VBtn :disabled="isDeleting" @click="toggleEdit" class="ml-6" color="amber" small v-if="canUpdate">
            <FaI icon="pen-to-square"/>&nbsp;
            <span v-t="'actions.edit'"/>
          </VBtn>
        </VCol>
      </VRow>
    </div>
    <FormExpense :target="expense.id" @cancel="endEdit" @success="onUpdate" v-if="edit"/>
  </VExpansionPanelContent>
</template>

<script>
import tickerMixin from "top/mixins/tickerMixin.js";
import MediaListItem from "elements/media/MediaListItem.vue";
import ExpenseAttachments from "./ExpenseAttachments.vue";
import ExpenseItemPartition from "./ExpenseItemPartition.vue";
import FormExpense from "./FormExpense.vue";
import PartitionsSetDialog from '../../partitionsSet/elements/PartitionsSetDialog.vue';
import ProjectListItem from "../../projects/browse/elements/ProjectListItem.vue";
import UserListItem from "../../users/browse/UserListItem.vue";
import Queries from "queries/index.js";
import {mapGetters} from "vuex";

export default {
  mixins: [
    tickerMixin,
  ],

  components: {
    ExpenseAttachments,
    ExpenseItemPartition,
    FormExpense,
    MediaListItem,
    PartitionsSetDialog,
    ProjectListItem,
    UserListItem,
  },

  data: () => ({
    edit: false,
    showDelete: false,
    isDeleting: false,
  }),

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  inject: ['expansionPanel'],

  apollo: {
    expense: {
      query: Queries.Expense.Single.ExpansionPanelContent,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.isVisible;
      },
    },

    salesTaxCode: {
      query: Queries.SalesTaxCode.Single.Name,
      variables() {
        return {
          id: this.expense.salesTaxCode.id,
        }
      },
      skip() {
        return !this.expense;
      }
    },

    partitionsSetFromActivity: {
      query: Queries.PartitionsSet.Single.IDFromContext,
      variables() {
        return {
          id: this.id,
          context: 'EXPENSE',
        };
      },
      skip() {
        return !this.isVisible;
      },
    },

    canUpdate() {
      return this.policyChecker({
        policy: 'update',
        type: 'Expense',
        id: this.id,
        skip: () => !this.isVisible,
      });
    },

    canDelete() {
      return this.policyChecker({
        policy: 'delete',
        type: 'Expense',
        id: this.id,
        skip: () => !this.isVisible,
      });
    },

    canCopyToPartitionsSet() {
      return this.policyChecker({
        policy: 'copyToPartitionsSet',
        type: 'Expense',
        id: this.id,
        skip: () => !this.isVisible,
      });
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    isVisible() {
      return this.expansionPanel.isActive;
    },

    isMe() {
      const {expense, meId} = this;
      return expense ? expense.user.id === meId : false;
    },

    canInteract() {
      const {canUpdate, canDelete} = this;
      return canUpdate || canDelete;
    },

    createdTimestamp() {
      const {expense, getTickerForHumans} = this;
      return expense
          ? `${this.$t('actions.created')} ${getTickerForHumans(expense.createdAt)}`
          : '';
    },

    updatedTimestamp() {
      const {expense, getTickerForHumans} = this;
      return expense && expense.updatedAt !== expense.createdAt
          ? `${this.$t('actions.updated')} ${getTickerForHumans(expense.updatedAt)}`
          : '';
    },

    hasMultipleProjects() {
      const {expense} = this;
      return expense ? expense.expensePartitions.length > 1 : false;
    },

    partitionsForSet() {
      return !this.expense ? undefined : this.expense.expensePartitions.map(({weight, comment, isBillable, project: {id: projectId}}) => ({
        weight,
        comment,
        isExpenseBillable: isBillable,
        projectId,
      }));
    },

    hasActivityTemplate() {
      return Boolean(this.partitionsSetFromActivity);
    },
  },

  watch: {
    'expansionPanel.isActive'(v) {
      if (!v) {
        this.endEdit();
      }
    },
  },

  methods: {
    onUpdate(response) {
      this.$emit('update', response);
      this.endEdit();
    },

    async doDelete() {
      this.showDelete = false;
      await this.deleteAction();
    },

    async deleteAction() {
      const {id} = this,
          variables = {
            id,
          };

      this.isDeleting = true;
      try {
        const {data: {expenseDelete: response}} = await this.$apollo.mutate({
          mutation: Queries.Expense.Write.Delete,
          variables,
        });
        this.$emit('delete', response);
      } finally {
        this.isDeleting = false;
      }
    },

    toggleEdit() {
      this.edit = !this.edit;
    },

    endEdit() {
      this.edit = false;
    },

    onPartitionsSetSuccess(event) {
      const apolloClient = this.$apollo.provider.defaultClient;
      apolloClient.writeQuery({
        query: Queries.PartitionsSet.Single.IDFromContext,
        variables: {
          id: this.id,
          context: 'EXPENSE',
        },
        data: {
          partitionsSetFromActivity: {
            __typename: 'PartitionsSet',
            id: event.id,
          }
        }
      });
      this.$apollo.queries.canCopyToPartitionsSet.refetch();
    },
  }
}
</script>
