<template>
  <form @submit.prevent="doSubmit">
    <KTextField
      :validator="$v.email"
      autocomplete="email"
      label="models.user.email"
      type="email"
      v-model="email"
    />

    <KBtnSubmit :mode="mode" class="mt-3">
      <span v-t="'forms.passwordRequest.submit_button'"></span>
    </KBtnSubmit>

  </form>
</template>

<script>
  import FormMixin from 'forms/elements/FormMixin.js'
  import {KBtnSubmit, KTextField} from "forms/elements/index.js"
  import {email, maxLength, required} from 'validators/index.js'
  import Queries from 'queries/index.js'

  export default {
    mixins: [
      FormMixin,
    ],

    components: {
      KBtnSubmit,
      KTextField,
    },

    data: () => ({
      email: '',
    }),

    validations: {
      email: {
        required,
        email,
        maxLength: maxLength(255)
      },
    },

    methods: {
      async createAction() {
        const {email} = this,
          variables = {
            email,
          };

        return await this.$apollo.mutate({
          mutation: Queries.Auth.Password.Forgot,
          variables,
        });
      },
    },
  }
</script>
