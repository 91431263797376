<template>
  <VRow>
    <VCol cols="12" xl="8">
      <KSelect
        v-model="form.interaction"
        :validator="$v.form.interaction"
        :items="interactionItems"
        label="models.projectRecord.active.interaction"
        hint="forms.general.required"
      >
        <template #append-outer>
          <VDialog>
            <template #activator="{on, attrs}">
              <VBtn fab x-small v-on="on" v-bind="attrs">
                <FaI icon="info"/>
              </VBtn>
            </template>
            <VCard>
              <VCardTitle v-t="'models.projectRecord.active.interaction'"/>
              <VCardText>
                <span v-t="'forms.projectRecord.active.interaction_help.heading'"/>
                <ul>
                  <li v-for="value in interactionValues" :key="value">
                    <span v-t="`forms.projectRecord.active.interaction.${value.toLowerCase()}`"/>:
                    <span v-t="`forms.projectRecord.active.interaction_help.${value.toLowerCase()}`"/>
                  </li>
                </ul>
              </VCardText>
            </VCard>
          </VDialog>
        </template>
      </KSelect>
    </VCol>

    <VCol cols="12" xl="8">
      <KTextField
        v-model="form.comment"
        :validator="$v.form.comment"
        label="models.projectRecord.active.comment"
        hint="forms.projectRecord.active.comment_hint"
      />
    </VCol>

    <VCol cols="12" sm="6" xl="2">
      <KDatePicker
        v-model="form.startDate"
        :validator="$v.form.startDate"
        label="models.projectRecord.active.startDate"
        hint="forms.projectRecord.active.startDate_hint"
        :min="startDateMin"
        :max="startDateMax"
      />
    </VCol>

    <VCol cols="12" sm="6" xl="2">
      <KDatePicker
        v-model="form.endDateEstimate"
        :validator="$v.form.endDateEstimate"
        label="models.projectRecord.active.endDateEstimate"
        hint="forms.general.required"
        :min="endDateEstimateMin"
        :max="endDateEstimateMax"
      />
    </VCol>

    <VCol cols="12">
      <FormProjectRecordDeviceCount v-model="form.deviceCount" @validator="deviceCountValidator = $event"/>
    </VCol>

    <VCol cols="12">
      <FormProjectRecordFinancials :project-type="projectType" :optional="financialsOptional" v-model="form.financials" @validator="financialsValidator = $event"/>
    </VCol>
  </VRow>
</template>

<script>
import FormProjectRecordDeviceCount from "../elements/FormProjectRecordDeviceCount.vue";
import FormProjectRecordFinancials from "../elements/FormProjectRecordFinancials.vue";
import {KDatePicker, KTextField, KSelect, scopedFormsMixin} from "forms/elements/index.js";
import {betweenDate, minDate, maxDate, betweenLength, required, validatorIsValid} from "validators/index.js";
import {formatISODate} from "top/date-fns-format.js";
import {addYears, subYears} from "date-fns";

const interactionValues = ['NORMAL', 'OCCASIONAL', 'BILLINGS'];

export default {
  mixins: [
    scopedFormsMixin,
  ],

  components: {
    KDatePicker,
    KTextField,
    KSelect,
    FormProjectRecordDeviceCount,
    FormProjectRecordFinancials,
  },

  data: () => ({
    interactionValues,
    form: {
      interaction: 'NORMAL',
      comment: null,
      startDate: null,
      endDateEstimate: null,
      deviceCount: null,
      financials: null,
    },
    deviceCountValidator: null,
    financialsValidator: null,
  }),

  props: {
    projectType: {
      type: String,
      required: true,
    },

    financialsOptional: Boolean,
  },

  validations() {
    const {startDateMin, startDateMax, endDateEstimateMin, endDateEstimateMax, form: {startDate, endDateEstimate}} = this;
    return {
      form: {
        interaction: {
          required,
        },
        comment: {
          required,
          betweenLength: betweenLength(2, 250),
        },
        startDate: {
          required,
          betweenDate: betweenDate(startDateMin, startDateMax),
          maxDate: maxDate(endDateEstimate),

        },
        endDateEstimate: {
          required,
          betweenDate: betweenDate(endDateEstimateMin, endDateEstimateMax),
          minDate: minDate(startDate),
        },
      },
      deviceCountValidator: {
        required,
        validatorIsValid,
      },
      financialsValidator: {
        validatorIsValid,
      },
    };
  },

  computed: {
    startDateMin() {
      return formatISODate(subYears(new Date(), 20));
    },

    startDateMax() {
      return formatISODate(addYears(new Date(), 1));
    },

    endDateEstimateMin() {
      return formatISODate(new Date());
    },

    endDateEstimateMax() {
      return formatISODate(addYears(new Date(), 10));
    },

    interactionItems() {
      return interactionValues.map((value) => ({
        name: this.$t(`forms.projectRecord.active.interaction.${value.toLowerCase()}`),
        id: value,
      }));
    },
  },

  watch: {
    '$v.$dirty'(v) {
      if (v) {
        this.deviceCountValidator.$touch();
        if (this.financialsValidator !== null) {
          this.financialsValidator.$touch();
        }
      }
    },
  },
}
</script>
