<template>
  <KApiSelect
    v-bind="$attrs"
    v-on="$listeners"
    :model="model"
    :value="value"
    @input="onSelection"
  >
    <template #append>
      <VScrollXTransition mode="out-in">
        <VProgressCircular v-if="isRunning" key="r" indeterminate size="18" width="2"/>
        <FaI v-else-if="isSuccess" key="s" icon="check" class="green--text text--lighten-2"/>
        <FaI v-else-if="isError" key="e" icon="xmark" class="red--text"/>
      </VScrollXTransition>
    </template>
  </KApiSelect>
</template>

<script>
  import KApiSelect from "./KApiSelect.vue";
  import Queries from "queries/index.js";
  import {get} from "lodash-es";

  export default {
    inheritAttrs: false,

    components: {
      KApiSelect,
    },

    data: () => ({
      isRunning: false,
      isSuccess: false,
      isError: false,
    }),

    props: {
      id: {
        type: [Number, String],
        required: true,
      },

      model: {
        type: String,
        required: true,
      },

      argument: String,

      mutation: {
        type: [String, Object],
        required: true,
      },

      value: {
        type: [Number, String],
        required: true,
      },
    },

    timers: {
      showFeedback: {
        time: 2000,
      },
    },

    methods: {
      showFeedback() {
        this.isSuccess = false;
        this.isError = false;
      },

      getMutation() {
        const {mutation} = this;
        let payload = undefined;

        if (typeof mutation === "object" && mutation !== null) {
          payload = mutation;
        } else if (typeof mutation === 'string') {
          payload = get(Queries, mutation);
        }

        if (payload === undefined) {
          throw new Error('Mutation is invalid');
        }

        return payload;
      },

      async onSelection(v) {
        const {value, id, argument, model, getMutation} = this;

        if (v === value) {
          return;
        }

        this.isRunning = true;

        try {
          await this.$apollo.mutate({
            mutation: getMutation(),
            variables: {
              id,
              [argument ?? model]: v,
            },
          });
          this.isSuccess = true;
        } catch (error) {
          this.isError = true;
          throw error;
        } finally {
          this.isRunning = false;
          this.$timer.stop('showFeedback');
          this.$timer.start('showFeedback');
        }
      },
    }
  }
</script>
