<template>
  <VExpansionPanel>
    <ProjectForRecordUpdateItemHeader :id="id"/>
    <VExpansionPanelContent :eager="eager">
      <FormProjectRecordContainer :project-id="id" hide-identifier @success="onSuccess"/>
    </VExpansionPanelContent>
  </VExpansionPanel>
</template>

<script>
import ProjectForRecordUpdateItemHeader from "./ProjectForRecordUpdateItemHeader.vue";
import FormProjectRecordContainer from "./forms/FormProjectRecordContainer.vue";

export default {
  components: {
    ProjectForRecordUpdateItemHeader,
    FormProjectRecordContainer,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },

    eager: Boolean,
  },

  methods: {
    onSuccess(response) {
      this.$emit('success', response);
    },
  },
}
</script>
