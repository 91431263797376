import gql from 'graphql-tag';
import PaginatorFragment from "queries/paginatorFragment";

// Single

const Suggestion = gql`
  query billingRateSuggestion(
    $user: ID!
    $project: ID
    $date: Date
  ) {
    billingRateSuggestion(
      user: $user
      project: $project
      date: $date
    ) {
      id
      rate
      strict
    }
  }
`;


// Browse

const List = gql`
  query billingRatesList(
    $context: BillingRateContext!
    $id: ID!
  ) {
    billingRates(
      context: $context
      id: $id
    ) {
      id
      rate
      startAt
      endAt
    }
  }
`;


// Write

const FormFill = gql`
  query billingRateFormFill(
    $id: ID!
  ) {
    billingRate(
      id: $id
    ) {
      id
      rate
      startAt
      endAt
    }
  }
`;

const Create = gql`
  mutation billingRateCreate(
    $rate: Float!
    $strict: Boolean
    $startAt: Date!
    $endAt: Date
    $users: [ID!]
    $projects: [ID!]
  )
  {
    billingRateCreate(
      input: {
        rate: $rate
        strict: $strict
        startAt: $startAt
        endAt: $endAt
        users: {
          connect: $users
        }
        projects: {
          connect: $projects
        }
      }
    ) {
      id
      rate
      strict
      startAt
      endAt
      createdAt
      updatedAt
      users {
          id
      }
      projects {
          id
      }
    }
  }
`;

const Update = gql`
  mutation billingRateUpdate(
    $id: ID!
    $rate: Float!
    $strict: Boolean
    $startAt: Date!
    $endAt: Date
  )
  {
    billingRateUpdate(
      id: $id
      input: {
        rate: $rate
        strict: $strict
        startAt: $startAt
        endAt: $endAt
      }
    ) {
      id
      rate
      strict
      startAt
      endAt
      createdAt
      updatedAt
      users {
          id
      }
      projects {
          id
      }
    }
  }
`;

const Delete = gql`
  mutation billingRateDelete(
    $id: ID!
  )
  {
    billingRateDelete(
      id: $id
    ) {
      id
      rate
      strict
      startAt
      endAt
      createdAt
      updatedAt
      users {
          id
      }
      projects {
          id
      }
    }
  }
`;


// Export

const Single = {
  Suggestion,
};

const Browse = {
  List,
};

const Write = {
  FormFill,
  Create,
  Update,
  Delete,
};

export default {
  Single,
  Browse,
  Write,
};
