<template>
  <VContainer>
    <VRow>
      <VCol>
        <VToolbar color="grey lighten-3">
          <VDialog max-width="250">
            <template #activator="{on, attrs}">
              <VBtn icon color="grey" v-bind="attrs" v-on="on">
                <FaI size="2x" icon="arrow-circle-left"/>
              </VBtn>
            </template>
            <VCard>
              <VCardTitle>
                Are you sure?
              </VCardTitle>
              <VCardText>
                Any progress will be discarded.
              </VCardText>
              <VCardActions>
                <VSpacer/>
                <VBtn color="primary" text :to="{name: 'projects'}" exact>
                  Confirm
                </VBtn>
              </VCardActions>
            </VCard>
          </VDialog>
          <VToolbarTitle>
            {{formTitle}}
          </VToolbarTitle>
        </VToolbar>
        <FormProject @success="onSuccess"/>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import FormProject from './FormProjectMain.vue'

export default {
  components: {
    FormProject,
  },

  computed: {
    formTitle() {
      return this.$t('forms.messages.create', {type: this.$tc('models.project.self')});
    },
  },

  methods: {
    onSuccess({id, slug, name}) {
      const nameMsg = `${name} (${id})`;
      this.$store.dispatch(
        'flash/success',
        {
          text: 'forms.messages.modelCreated',
          args: {
            type: 'models.project.self',
            name: nameMsg,
          },
        });

      this.$router.push({name: 'project', params: {id, slug}});
    },
  },
}
</script>
