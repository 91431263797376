<template>
  <VMenu
    :close-on-content-click="false"
    :nudge-right="40"
    :disabled="disabled"
    min-width="290px"
    offset-y
    transition="scale-transition"
    v-model="show"
  >
    <template v-slot:activator="{ on, attrs }">
      <VTextField
        :clearable="clearable"
        :error-messages="errorMessages"
        :hint="hintParsed"
        :label="labelParsed"
        :readonly="range || isOnMobile"
        :disabled="disabled"
        v-mask="mask"
        v-on="on"
        v-bind="attrs"
        v-model="textFieldValue"
        @input="onTextFieldInput"
        @focus="onFocus"
        @click:clear="onDateInput([])"
        @blur="onBlur"
      >
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
          <slot :name="name" v-bind="slotData" />
        </template>
        <template v-slot:append>
          <FaI :icon="['far', 'calendar-day']" color="grey"/>
        </template>
      </VTextField>
    </template>
    <VDatePicker
      :locale="locale"
      :min="min"
      :disabled="disabled"
      :max="max"
      :range="range"
      :type="localType"
      :value="value"
      @input="onDateInput"
      v-if="show"
    />
  </VMenu>
</template>

<script>
  import KFormElementMixin from './KFormElementMixin.js'
  import {mapGetters} from "vuex";
  import {isValid, isBefore} from "date-fns";

  export default {
    mixins: [
      KFormElementMixin,
    ],

    data: () => ({
      textFieldValue: undefined,
      show: false,
    }),

    props: {
      clearable: Boolean,

      min: String,

      max: String,

      range: Boolean,

      month: Boolean,

      value: {
        type: [Array, String],
        required: true,
      },
    },

    computed: {
      ...mapGetters('session', ['locale']),

      displayValue() {
        const {range, value} = this;

        if (!range || !Array.isArray(value)) {
          return value;
        }

        if (value.length === 0) {
          return undefined;
        }

        if (value.length === 1 || value[0] === value[1]) {
          return value[0];
        }

        return `${value[0]} ~ ${value[1]}`;
      },

      isOnMobile() {
        return this.$vuetify.breakpoint.xsOnly;
      },

      localType() {
        return this.month ? 'month' : 'date';
      },

      mask() {
        const maskPattern = this.month ? '####-##-##' : '####-##';
        return this.range ? `${maskPattern} ~ ${maskPattern}` : maskPattern;
      },
    },

    watch: {
      show(v) {
        const {range, value} = this;
        if (!v && range && value && value.length === 1) {
          value[1] = value[0];
        }
      },

      displayValue: {
        handler(v) {
          this.textFieldValue = v;
        },
        immediate: true,
      },
    },

    methods: {
      dateValidator(v) {
        return this.month
          ? /^[12]\d{3}-(0[1-9]|1[0-2])$/.test(v)
          : /^[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(v) && isValid(new Date(v));
      },

      onTextFieldInput(v) {
        if (!v && this.clearable) {
          this.onInput(undefined);
        }

        if (v && v.length === 1 && v !== "2") {
          const year = (new Date()).getFullYear();
          this.textFieldValue = `${year}-${v}`;
        } else if (this.dateValidator(v)) { // if it is a valid date
          this.onInput(v);
        }
      },

      onDateInput(v) {
        if (!this.range || v.length === 2) {
          this.show = false;
        }

        if (v.length === 2 && isBefore(new Date(v[1]), new Date(v[0]))) {
          this.onInput([v[1], v[0]]);
        } else {
          this.onInput(v);
        }
      },

      onFocus(event) {
        if (!this.isOnMobile) {
          event.target.select();
        }
      },

      onBlur() {
        this.textFieldValue = this.displayValue;
        this.doTouch();
      },
    },
  }
</script>

