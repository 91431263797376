<template>
  <VListItem link :disabled="disabled" :to="to" :exact="exact" @click="onClick">
    <VListItemAction>
      <slot>
        <FaI :icon="icon"/>
      </slot>
    </VListItemAction>
    <VListItemContent>
      <VListItemTitle v-t="title"/>
    </VListItemContent>
  </VListItem>
</template>

<script>
  export default {
    props: {
      to: [String, Object],

      disabled: Boolean,

      exact: Boolean,

      icon: {
        type: [String, Array],
        default: 'circle',
      },

      title: {
        type: [String, Object],
        required: true,
      },
    },

    methods: {
      onClick(e) {
        this.$emit('click', e);
      },
    }
  }
</script>
