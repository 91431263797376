<template>
  <VBtn :input-value="isOpen" @click="doOpen" :color="isActive ? 'green darken-4' : 'grey'" :outlined="!isActive">
    <FaI
      icon="timer"
      size="2x"
      :class="isActive ? 'green--text' : 'grey--text'"
    />
    <VProgressCircular v-if="isLoading" indeterminate width="3" size="34" color="white" style="position: absolute; bottom: 0"/>
    <ActiveTimeslipDuration v-else-if="isActive" class="font-weight-bold text-h6" shadow="black" style="position: absolute;"/>
  </VBtn>
</template>

<script>
  import Queries from "queries/index.js";
  import ActiveTimeslipDuration from "./ActiveTimeslipDuration.vue";
  import tickerMixin from "top/mixins/tickerMixin.js";
  import {mapGetters} from "vuex";

  export default {
    mixins: [
      tickerMixin,
    ],

    components: {
      ActiveTimeslipDuration,
    },

    data: () => ({
      showTimekeeper: false,
    }),

    apollo: {
      activeTimeslip: {
        query: Queries.Timeslip.Single.ActiveTimeslip,
        variables() {
          return {userId: this.meId};
        },
        skip() {
          return !this.isSignedIn;
        },
        subscribeToMore: {
          document: Queries.Timeslip.Subscriptions.ActiveTimeslipChanged,
          variables() {
            return {user: this.meId};
          },
          updateQuery: (previousResult, { subscriptionData }) => ({
            ...previousResult,
            activeTimeslip: subscriptionData?.data?.activeTimeslipChanged,
          }),
        },
      },
    },

    computed: {
      ...mapGetters('session', ['isSignedIn', 'meId']),

      isOpen() {
        return this.$route.query.timekeeper !== undefined;
      },

      isLoading() {
        return this.$apollo.queries.activeTimeslip.loading;
      },

      isActive() {
        return Boolean(this.activeTimeslip);
      },

      howLong() {
        const {activeTimeslip, getTimeDuration} = this;
        return activeTimeslip ? getTimeDuration(activeTimeslip.startAt) : null;
      },
    },

    methods: {
      doOpen() {
        const current = this.$router.currentRoute;
        const newRoute = {};
        newRoute.path = current.path;
        newRoute.query = current.query ? Object.assign({}, current.query) : {};
        newRoute.query.timekeeper = null;
        return this.$router.replace(newRoute);
      },
    },
  }
</script>
