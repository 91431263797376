<template>
  <VContainer>
    <VToolbar dense color="grey lighten-3" class="mx-1">
      <VToolbarTitle v-t="{path: 'models.invoice.self', choice: 2}"/>
      <VSpacer/>
      <VBtn @click="toggleFilter" text>
        <FaI size="2x" icon="chevron-down" :rotation="showFilter ? 180 : 0" style="transition: all 0.3s ease;"/>
        <div>
          <VFadeTransition leave-absolute>
            <FaI size="2x" key="a" v-if="isFiltered" icon="filter"/>
            <FaI size="2x" key="b" v-else :icon="['far', 'filter']"/>
          </VFadeTransition>
        </div>
      </VBtn>
      <template v-slot:extension>
        <VTabs icons-and-text centered show-arrows>
          <VTab v-for="page in pages" :key="page.id" :to="{name: page.to}" exact>
            <span v-t="page.name"/>
            <FaI :icon="page.icon" size="lg"/>
          </VTab>
        </VTabs>
      </template>
    </VToolbar>

    <VTabsItems :value="tab" touchless class="px-1">
      <VTabItem v-for="{id, disables, defaults, sort, sortDesc, simple} in pages" :key="id" :value="id">
        <InvoiceBrowseTab
          :filter-disables="disables"
          :filter-defaults="defaults"
          :sort-default="sort"
          :order-desc-default="sortDesc"
          :show-filter="showFilter"
          :simple="simple"
          @filtered="setIsFiltered"
        />
      </VTabItem>
    </VTabsItems>
  </VContainer>
</template>

<script>

  import InvoiceBrowseTab from "./elements/InvoiceBrowseTab.vue";
  import {mapGetters} from 'vuex';

  export default {
    components: {
      InvoiceBrowseTab,
    },

    data: () => ({
      showFilter: false,
      isFilteredStore: {},
    }),

    props: {
      tab: {
        type: String,
        default: 'default',
      },
    },

    apollo: {
      canReview() {
        return this.policyChecker({
          policy: 'viewScoped',
          type: 'Invoice',
          args: {
            user: this.meId,
          },
        });
      },

      canReviewNotPaid() {
        return this.policyChecker({
          policy: 'viewScoped',
          type: 'Invoice',
          args: {
            user: this.meId,
            isPaid: false,
          },
        });
      },

      canReviewOverdue() {
        return this.policyChecker({
          policy: 'viewScoped',
          type: 'Invoice',
          args: {
            user: this.meId,
            isPaid: false,
            isOverdue: true,
          },
        });
      },

      canViewAny() {
        return this.policyChecker({
          policy: 'viewAny',
          type: 'Invoice',
        });
      },
    },

    computed: {
      ...mapGetters('session', ['meId']),

      pages() {
        const {canReview, canReviewNotPaid, canReviewOverdue, canViewAny} = this;

        const payload = [];

        if (canReview) {
          payload.push({
            id: 'default',
            name: 'actions.review',
            to: 'invoicing',
            icon: 'clock-rotate-left',
            sort: 'TRANSACTION_DATE',
            sortDesc: true,
            defaults: {
              user: this.meId,
            },
            disables: {
              user: true,
            },
          });
        }

        if (canReviewNotPaid) {
          payload.push({
            id: 'unpaid',
            name: 'models.invoice.notPaid',
            to: 'invoicing.unpaid',
            icon: 'envelope-open-dollar',
            defaults: {
              isPaid: false,
              user: this.meId,
            },
            disables: {
              isPaid: true,
              isOverdue: true,
              user: true,
            },
            sort: 'TRANSACTION_DATE',
            sortDesc: true,
          });
        }

        if (canReviewOverdue) {
          payload.push({
            id: 'overdue',
            name: 'models.invoice.isOverdue',
            to: 'invoicing.overdue',
            icon: 'clock',
            defaults: {
              isPaid: false,
              isOverdue: true,
              user: this.meId,
            },
            disables: {
              isPaid: true,
              isOverdue: true,
              user: true,
            },
            sort: 'TRANSACTION_DATE',
            sortDesc: true,
          });
        }

        if (canViewAny) {
          payload.push({
            id: 'admin',
            name: 'ui.admin',
            to: 'invoicing.admin',
            icon: 'screwdriver-wrench',
            sort: 'TRANSACTION_DATE',
            sortDesc: true,
          });
        }

        return payload;
      },

      isFiltered() {
        const {isFilteredStore, tab} = this;
        return isFilteredStore[tab] || false;
      },
    },

    methods: {
      toggleFilter() {
        this.showFilter = !this.showFilter;
      },

      setIsFiltered(v) {
        this.$set(this.isFilteredStore, this.tab, v);
      },
    },
  }
</script>
