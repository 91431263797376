<template>
  <VDataTable
    dense
    :headers="headers"
    :items="items"
  >
    <template v-slot:top>
      <VToolbar flat>
        <VToolbarTitle v-t="'views.performance.overview.title'"/>
        <VSpacer/>
        <VBtn color="green lighten-3" @click="showExport = true">
          <FaI size="lg" icon="file-csv" class="mr-2"/>
          <span v-t="'actions.export'"/>
        </VBtn>
        <TableExportToCsv v-model="showExport" :headers="headers" :items="items" transpose prefix="views.performance.short"/>
      </VToolbar>
    </template>
  </VDataTable>
</template>

<script>
  import {ORDERED} from '../performance/perfConsts.js';
  import TableExportToCsv from './TableExportToCsv.vue';

  const speedCols = ['SpeedUp', 'SpeedDown'];

  export default {
    components: {
      TableExportToCsv,
    },

    data: () => ({
      showExport: false,
    }),

    props: {
      store: {
        type: Array,
        required: true,
      },

      settings: {
        type: Object,
        default: () => ({}),
      }
    },

    computed: {
      headers() {
        const payload = [{
          text: this.$tc('models.device.self', 1),
          value: 'name',
        }];

        const cols = this.settings?.speed === 'hide' ? ORDERED.filter(({name}) => !speedCols.includes(name)) : ORDERED;

        payload.push(...cols.map(({name, hasImperial = false}) => ({
          text: `${this.$t(`forms.inspectionPerformance.${name}.title`)} (${this.$t(`forms.inspectionPerformance.${name}.units${hasImperial ? ('.' + this.unitsType) : ''}`)})`,
          value: name,
        })));

        return payload;
      },

      unitsType() {
        return this.settings?.units ?? 'i';
      },

      items() {
        return this.store.map(({name, data}) => ({
          name,
          ...data,
        }));
      },
    },
  }
</script>
