<template>
  <VContainer>
    <ListBrowser :title="{path: 'models.company.self', choice: 2}" query="Company.Browse.List">
      <template #filter="{setVariables, setIsFiltered, showFilter}">
        <CompaniesFilter @input="setVariables" :defaults="filterDefaults" :disables="filterDisables" @filtered="setIsFiltered" :show-filter="showFilter"/>
      </template>
      <template #item="{item}">
        <CompanyListItem :id="item.id" :key="item.id"/>
      </template>
    </ListBrowser>
  </VContainer>
</template>

<script>
  import ListBrowser from "elements/list/ListBrowser.vue";
  import CompaniesFilter from "./elements/CompaniesFilter.vue";
  import CompanyListItem from "./elements/CompanyListItem.vue";

  export default {
    components: {
      ListBrowser,
      CompaniesFilter,
      CompanyListItem,
    },

    data: () => ({
      filterDefaults: {
        companyType: '3', // Clients
      },
    }),

    computed: {
      filterDisables() {
        const payload = {};
        Object.keys(this.filterDefaults).forEach((key) => {
          payload[key] = true;
        });

        return payload;
      }
    },
  }
</script>
