<template>
  <VRow justify-lg="space-between">
    <VCol cols="12" sm="4" lg="3">
      <KDatePicker
        v-model="form.clientReview"
        :validator="$v.form.clientReview"
        label="models.projectRecord.complete.clientReview"
        hint="forms.projectRecord.complete.clientReview_hint"
        :min="reviewDateMin"
        :max="reviewDateMax"
      />
    </VCol>

    <VCol cols="12" sm="4" lg="3">
      <KDatePicker
        v-model="form.marketingFollowUp"
        :validator="$v.form.marketingFollowUp"
        label="models.projectRecord.complete.marketingFollowUp"
        hint="forms.projectRecord.complete.marketingFollowUp_hint"
        :min="reviewDateMin"
        :max="reviewDateMax"
      />
    </VCol>

    <VCol cols="12" sm="4" lg="3">
      <KDatePicker
        v-model="form.accountingFollowUp"
        :validator="$v.form.accountingFollowUp"
        label="models.projectRecord.complete.accountingFollowUp"
        hint="forms.projectRecord.complete.accountingFollowUp_hint"
        :min="reviewDateMin"
        :max="reviewDateMax"
      />
    </VCol>
  </VRow>
</template>

<script>
import {KDatePicker, KTextField, scopedFormsMixin} from "forms/elements/index.js";
import {betweenDate} from "validators/index.js";
import {formatISODate} from "top/date-fns-format.js";
import {subYears} from "date-fns";

export default {
  mixins: [
    scopedFormsMixin,
  ],

  components: {
    KDatePicker,
    KTextField,
  },

  data: () => ({
    form: {
      clientReview: null,
      marketingFollowUp: null,
      accountingFollowUp: null,
    },
  }),

  validations() {
    const {reviewDateMin, reviewDateMax} = this;
    return {
      form: {
        clientReview: {
          betweenDate: betweenDate(reviewDateMin, reviewDateMax),
        },
        marketingFollowUp: {
          betweenDate: betweenDate(reviewDateMin, reviewDateMax),
        },
        accountingFollowUp: {
          betweenDate: betweenDate(reviewDateMin, reviewDateMax),
        },
      },
    };
  },

  computed: {
    reviewDateMin() {
      return formatISODate(subYears(new Date(), 2));
    },

    reviewDateMax() {
      return formatISODate(new Date());
    },
  },
}
</script>
