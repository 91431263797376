const STATUSES = { // Status code for app record broadcasting across components
  IDLE: 0,
  STAGED: 5,
  STARTING: 10,
  RUNNING: 20,
  STOPPING: 25,
  FINISHED: 30,
  ERROR: 99,
};

const TYPES = { // Symbols used for the action buttons
  o: {
    name: 'up',
    icon: 'circle-caret-up',
    help: 'up_down_help',
  },
  oe: {
    name: 'down',
    icon: 'circle-caret-down',
    help: 'up_down_help',
  },
  d7: {
    name: 'doors',
    icon: 'reflect-horizontal',
    help: 'doors_help',
  },
};

const OMEGA_BASE = { // Part of the recording profile which is common to both of the Omega collections
  DoorOpenTime: {
    start: 3,
    stop: 5,
  },
  DoorCloseTime: {
    start: 1,
    stop: 2,
  },
  CarCallDwellTime: {
    start: 5,
    stop: 6,
  },
  NudgingTime: {
    start: 7,
    stop: 8,
  },
};

const PROGRAMS = { // Performance measurement profiles
  o: {
    OperatingUpTime: {
      start: 1,
      stop: 4,
    },
    ...OMEGA_BASE,
  },
  oe: {
    OperatingDownTime: {
      start: 1,
      stop: 4,
    },
    ...OMEGA_BASE,
  },
  d7: {
    DoorOpenTime: {
      start: 1,
      stop: 2,
    },
    CarCallDwellTime: {
      start: 2,
      stop: 3,
    },
    DoorCloseTime: {
      start: 3,
      stop: 4,
    },
  },
};

const STYLES = {
  [STATUSES.STAGED]: {
    class: 'primary',
    icon: ['far', 'circle'],
  },
  [STATUSES.STARTING]: {
    class: 'warning',
    icon: ['far', 'circle-play'],
  },
  [STATUSES.RUNNING]: {
    class: 'secondary',
    icon: ['far', 'clock'],
  },
  [STATUSES.STOPPING]: {
    class: 'danger',
    icon: ['far', 'circle-stop'],
  },
  [STATUSES.FINISHED]: {
    class: 'success',
    icon: ['far', 'circle-check'],
  },
};

export {
  PROGRAMS,
  STATUSES,
  STYLES,
  TYPES,
};

export default {
  PROGRAMS,
  STATUSES,
  STYLES,
  TYPES,
};
