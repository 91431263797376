<template>
  <form @submit.prevent="doSubmit">
    <KTextField
      :validator="$v.token"
      autocomplete="one-time-code"
      disabled
      label="forms.passwordReset.token_title"
      v-model="token"
    />

    <KTextField
      :validator="$v.form.email"
      autocomplete="email"
      label="models.user.email"
      type="email"
      v-model="form.email"
    />

    <KTextField
      :validator="$v.form.password"
      autocomplete="new-password"
      label="forms.passwordReset.password_title"
      type="password"
      :value="form.password"
      @input="debouncedPasswordInput"
    />

    <PasswordTestFeedback :password="form.password" :email="form.email"/>

    <KTextField
      :validator="$v.form.passwordConfirmation"
      autocomplete="new-password"
      label="forms.passwordReset.passwordConfirmation_title"
      type="password"
      v-model="form.passwordConfirmation"
    />

    <KBtnSubmit :mode="mode" class="mt-3">
      <span v-t="'forms.passwordReset.submit_button'"/>
    </KBtnSubmit>

  </form>
</template>

<script>
  import FormMixin from 'forms/elements/FormMixin.js'
  import PasswordTestFeedback from "./PasswordTestFeedback.vue";
  import Queries from 'queries/index.js'
  import {KBtnSubmit, KTextField} from "forms/elements/index.js"
  import {alphaNum, email, length, maxLength, minLength, passwordTest, required, sameAs} from 'validators/index.js'
  import {debounce} from "lodash-es";

  export default {
    mixins: [
      FormMixin,
    ],

    components: {
      KBtnSubmit,
      KTextField,
      PasswordTestFeedback,
    },

    data: () => ({
      form: {
        email: '',
        password: '',
        passwordConfirmation: '',
      },
    }),

    props: {
      token: String,
    },

    validations: {
      token: {
        required,
        alphaNum,
        length: length(64),
      },
      form: {
        email: {
          required,
          email,
          maxLength: maxLength(255),
        },
        password: {
          required,
          // minLength: minLength(6),
          maxLength: maxLength(100),
          passwordTest: passwordTest('email'),
        },
        passwordConfirmation: {
          required,
          sameAs: sameAs('password'),
        },
      },
    },

    apollo: {
      passwordTest: {
        query: Queries.Auth.Password.Test,
        variables() {
          const {form: {password, email}} = this;
          return {
            password,
            email,
          };
        },
        throttle: 1000,
        skip() {
          return !this.form.password || this.form.email;
        },
      },
    },

    methods: {
      debouncedPasswordInput: debounce(
        function (v) {
          return this.form.password = v;
        }, 500, {maxWait: 2000}
      ),

      async createAction() {
        const {form, token} = this,
          {email, password, passwordConfirmation} = form,
          variables = {
            email,
            token,
            password,
            passwordConfirmation,
          };

        return await this.$apollo.mutate({
          mutation: Queries.Auth.Password.Reset,
          variables,
        });
      },
    },

    created() {
      const email = this.$route.query.email;
      if (email) {
        this.form.email = email;
      }
    },
  }
</script>
