<template>
  <VCard>
    <VCardTitle>
      <span>
        <FaI class="blue--text text--lighten-2 mr-2" icon="layer-group"/>
        <FaI icon="star" class="mr-1 orange--text text--darken-2"/>
      </span>
      <span v-t="'actions.new'"/>&nbsp;
      <span v-t="{path: 'models.partitionsSet.self', choice: 1}"/>
    </VCardTitle>
    <VCardText>
      <FormPartitionsSet v-bind="$attrs" v-on="$listeners"/>
    </VCardText>
  </VCard>
</template>

<script>
import FormPartitionsSet from './FormPartitionsSet.vue';

export default {
  components: {
    FormPartitionsSet,
  },
}
</script>
