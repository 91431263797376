import gql from 'graphql-tag';

// Fragments
const IsAllowedFragment = gql`
fragment isAllowedFragment on PolicyTest
  {
    id
    allowed
  }
`;

// Single

const Main = gql`
  query policyTest(
    $user: ID
    $policy: String!
    $contextType: String
    $contextId: ID
    $contextArguments: JSON
  ) {
    policyTest(
      user: $user
      policy: $policy
      contextType: $contextType
      contextId: $contextId
      contextArguments: $contextArguments
    ) {
      ...isAllowedFragment
    }
  }
  ${IsAllowedFragment}
`;


// Subscriptions

const MainSubscription = gql`
  subscription onPolicyTestUpdated(
    $user: ID
    $policy: String!
    $contextType: String!
    $contextId: ID!
    $contextArguments: JSON
  ) {
    policyTestUpdated(
      user: $user
      policy: $policy
      contextType: $contextType
      contextId: $contextId
      contextArguments: $contextArguments
    ) {
      ...isAllowedFragment
    }
  }
  ${IsAllowedFragment}
`;

const PoliciesOnModelUpdated = gql`
  subscription onPoliciesOnModelUpdated(
    $user: ID
    $type: String!
    $id: ID!
    $policies: [PolicyTestInput!]!
  ) {
    policiesOnModelUpdated(
      user: $user
      type: $type
      id: $id
      policies: $policies
    ) {
      ...isAllowedFragment
    }
  }
  ${IsAllowedFragment}
`;


// Export

const Single = {
  Main,
};

const Fragments = {
  IsAllowed: IsAllowedFragment
};

const Subscriptions = {
  Main: MainSubscription,
  PoliciesOnModelUpdated
};

export default {
  Fragments,
  Single,
  Subscriptions,
};
