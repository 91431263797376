<template>
    <div>
      <strong v-if="!isActive" v-t="'inspection.performance.awaitingDevice'"/>
      <VContainer v-else>
        <VRow>
          <VCol cols="2"/>
          <VCol :cols="cols" v-for="{field, cols} in $options.columns" :key="field">
            <span v-t="'forms.inspectionPerformance.' + field + '.title'"/>
          </VCol>
        </VRow>
        <VRow v-for="(device, index) in devices" :key="device">
          <VCol cols="2">
            {{device}}
          </VCol>
          <VCol :cols="cols" v-for="{field, cols} in $options.columns" :key="field">
            <PerformanceDataCell
              :device="device"
              :field="field"
              :settings="settings"
              :value="deviceData[index][field]"
              column
              @input="onInput(device, field, $event)"
            />
          </VCol>
        </VRow>
      </VContainer>
    </div>
</template>

<script>
  import PerformanceDataCell from './PerformanceDataCell.vue'

  const columns = [{
    field: 'DoorForce',
    cols: 4,
  }, {
    field: 'HallCallDwellTime',
    cols: 6,
  }];

  export default {
    columns,

    components: {
      PerformanceDataCell,
    },

    props: {
      devices: {
        type: Array,
        required: true,
      },

      store: {
        type: Object,
        required: true,
      },

      settings: {
        type: Object,
        default: () => ({}),
      }
    },

    inject: [
      'performancePattern',
    ],

    computed: {
      isActive() {
        return Boolean(this.devices?.length);
      },

      deviceData() {
        return this.devices ? this.store.filter((e) => this.devices.includes(e.name))?.map((e) => e.data) : [];
      },
    },

    methods: {
      onInput(name, key, value) {
        this.$emit('change', {
          name,
          key,
          value
        });
      },
    },
  }
</script>
