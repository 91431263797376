<template>
  <FormProject :target="id" @success="onSuccess" style="width: 100%"/>
</template>

<script>
  import FormProject from 'top/forms/FormProject.vue'

  export default {
    components: {
      FormProject,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      }
    },

    methods: {
      onSuccess({id, slug, name}) {
        this.$store.dispatch(
          'flash/success',
          {
            text: 'forms.messages.modelUpdated',
            args: {
              type: 'models.project.self',
              name,
            },
          });

        this.$router.push({name: 'project', params: {id, slug}});
      }
    }
  }
</script>
