<template>
  <div>
    <div v-if="$apollo.queries.myTimeslipSuggestions.loading" class="text-center">
      <VProgressCircular
        class="my-3"
        color="blue lighten-4"
        indeterminate
        size="64"
      />
    </div>
    <div v-else-if="isEmpty" class="text-center">
      <FaI class="grey--text my-3" icon="empty-set" size="lg"/>
    </div>

    <div v-else>
      <VList subheader three-line>
        <VScrollYTransition group>
          <VSubheader v-if="isActive" key="a-title" class="blue lighten-5 font-weight-black">
            <span v-t="'views.timekeeper.active'"/>
            <VSpacer/>
            <span v-t="'views.timekeeper.activityDuration'"/>
          </VSubheader>
          <TimekeeperItem v-if="isActive" :id="activeId" :key="activeId" :force-cancel="cancelMode"/>
        </VScrollYTransition>
      </VList>

      <VTabs centered icons-and-text>
        <VTab v-for="(tab, index) in tabs" :key="index">
          <span v-t="tab.label"/>
          <FaI :icon="tab.icon" size="lg"/>
        </VTab>
        <VTabItem v-for="(tab, index) in tabs" :key="index">
          <VList>
            <VListItem v-if="!tab.data || !tab.data.length" class="justify-center">
              <FaI icon="empty-set" size="lg" class="grey--text"/>
            </VListItem>
            <VScrollYTransition v-else group>
              <VSubheader key="title" class="font-weight-black">
                <VSpacer/>
                <span v-t="'views.timekeeper.activityDuration'"/>
              </VSubheader>
              <template v-for="(item, index) in tab.data">
                <VDivider v-if="index !== 0" :key="`d-${item.id}`"/>
                <TimekeeperItem :key="item.id" :id="item.id" :force-cancel="cancelMode"/>
              </template>
            </VScrollYTransition>
          </VList>
        </VTabItem>
      </VTabs>
    </div>
  </div>
</template>

<script>
  import Queries from "queries/index.js";
  import TimekeeperItem from "./TimekeeperItem.vue";
  import {differenceInMinutes, parseISO} from "date-fns";
  import {mapGetters, mapState} from "vuex";

  export default {
    components: {
      TimekeeperItem,
    },

    data: () => ({
      myTimeslipSuggestions: [],
    }),

    apollo: {
      activeTimeslip: {
        query: Queries.Timeslip.Single.ActiveTimeslip,
        variables() {
          return {userId: this.meId};
        },
        skip() {
          return !this.isSignedIn;
        },
      },

      myTimeslipSuggestionsFavorites: {
        query: Queries.TimeslipCollection.Browse.List,
        variables() {
          const {today, timekeeperDurationFrom} = this;
          return {
            isFavorite: true,
            limit: null,
            durationDates: {
              from: timekeeperDurationFrom,
              to: today,
            },
          };
        },
        skip() {
          return !this.isSignedIn;
        },
        update: (data) => data.myTimeslipSuggestions,
      },

      myTimeslipSuggestions: {
        query: Queries.TimeslipCollection.Browse.List,
        variables() {
          const {today, timekeeperDurationFrom} = this;
          return {
            limit: 10,
            durationDates: {
              from: timekeeperDurationFrom,
              to: today,
            },
          };
        },
        skip() {
          return !this.isSignedIn;
        },
      },
    },

    computed: {
      ...mapGetters('session', ['isSignedIn', 'meId', 'today', 'timekeeperDurationFrom']),

      ...mapState('session', ['now']),

      tabs() {
        return [
          {
            label: 'views.timekeeper.recentActivity',
            icon: 'clock',
            data: this.filteredRecent,
          },
          {
            label: 'views.timekeeper.favorites',
            icon: 'star',
            data: this.filteredFavorites,
          },
        ];
      },

      isEmpty() {
        return this.myTimeslipSuggestions.length === 0;
      },

      activeId() {
        return this.activeTimeslip?.timeslipCollection.id;
      },

      isActive() {
        return Boolean(this.activeId);
      },

      filteredFavorites() {
        const {activeTimeslip, myTimeslipSuggestionsFavorites} = this;
        return activeTimeslip && myTimeslipSuggestionsFavorites ? myTimeslipSuggestionsFavorites.filter((e) => e.id !== activeTimeslip.timeslipCollection.id) : myTimeslipSuggestionsFavorites;
      },

      filteredRecent() {
        const {activeTimeslip, myTimeslipSuggestions} = this;
        return activeTimeslip && myTimeslipSuggestions ? myTimeslipSuggestions.filter((e) => e.id !== activeTimeslip.timeslipCollection.id) : myTimeslipSuggestions;
      },

      startAtParsed() {
        const {activeTimeslip} = this;
        return activeTimeslip ? parseISO(activeTimeslip.startAt) : null;
      },

      cancelMode() {
        const {startAtParsed, now} = this;
        return startAtParsed ? differenceInMinutes(now, startAtParsed) === 0 : false;
      },
    },
  }
</script>
