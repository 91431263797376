<template>
  <form @submit.prevent="doSubmit">
    <KTextField
      label="models.partitionsSet.description"
      v-model="form.description"
      :validator="$v.form.description"
      hint="forms.general.required"
    />
    <VList>
      <template v-for="(partition, index) in form.partitions">
        <VDivider :key="`d-${partition.randomId}`" v-if="index !== 0"/>
        <VListItem :key="partition.randomId">
          <VListItemContent>
            <VListItemTitle>
              <VRow>
                <VCol
                  cols="12"
                  sm="12"
                  md="9"
                  lg="10"
                  xl="4"
                >
                  <ProjectPicker
                    v-model="partition.projectId"
                    hint="forms.general.required"
                    :validator="$v.form.partitions.$each[index].projectId"
                  />
                </VCol>
                <VCol
                  cols="12"
                  sm="4" order-sm="3"
                  md="3" order-md="1"
                  lg="2"
                  xl="1"
                >
                  <KTextField
                    type="number"
                    step="any"
                    min="0"
                    label="models.partition.weight"
                    v-model.number="partition.weight"
                    :validator="$v.form.partitions.$each[index].weight"
                    hint="forms.general.required"
                  />
                </VCol>
                <VCol
                  cols="12"
                  sm="6" order-sm="1"
                  md="4" order-md="2"
                  lg="3"
                  xl="2"
                >
                  <BillableCheckbox
                    v-model="partition.isTimeslipBillable"
                    :project="partition.projectId"
                    context="timeslip"
                    :is-update="context === 'timeslip' || isUpdate"
                  >
                    <template #label>
                      <FaI class="text--lighten-2" icon="clock"/>
                      <FaI class="orange--text text--darken-2 ml-1" icon="file-invoice-dollar"/>&nbsp;
                      <span v-t="'models.partition.isTimeslipBillable'"/>
                    </template>
                  </BillableCheckbox>
                </VCol>
                <VCol
                  cols="12"
                  sm="6" order-sm="2"
                  md="4" order-md="3"
                  lg="3"
                  xl="2"
                >
                  <BillableCheckbox
                    v-model="partition.isExpenseBillable"
                    :project="partition.projectId"
                    context="expense"
                    :is-update="context === 'expense' || isUpdate"
                  >
                    <template #label>
                      <FaI class="text--lighten-2" icon="receipt"/>
                      <FaI class="orange--text text--darken-2 ml-1" icon="file-invoice-dollar"/>&nbsp;
                      <span v-t="'models.partition.isExpenseBillable'"/>
                    </template>
                  </BillableCheckbox>
                </VCol>
                <VCol
                  cols="12"
                  sm="8" order-sm="4"
                  md="4" order-md="4"
                  lg="6"
                  xl="3"
                >
                  <KTextField
                    label="models.partition.comment"
                    v-model="partition.comment"
                    :validator="$v.form.partitions.$each[index].comment"
                  />
                </VCol>
              </VRow>
            </VListItemTitle>
          </VListItemContent>
          <VListItemAction>
            <VTooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <VBtn @click="removePartition(index)" fab small color="red lighten-4" v-on="on" v-bind="attrs">
                  <FaI icon="layer-minus" size="lg"/>
                </VBtn>
              </template>
              <span v-t="'forms.activityPartition.removePartition'"/>
            </VTooltip>
          </VListItemAction>
        </VListItem>
      </template>
    </VList>
    <VRow>
      <VCol cols="12">
        <VBtn @click="addPartition" color="green lighten-4" block>
          <FaI icon="layer-plus" size="lg" class="mr-1"/>
          <span v-t="'forms.activityPartition.addPartition'"/>
        </VBtn>
      </VCol>
      <VCol cols="12">
        <KBtnSubmit :mode="mode" :update="isUpdate"/>
        <VBtn @click="$emit('cancel')" class="ml-5">
          <span v-t="'actions.cancel'"/>
        </VBtn>
      </VCol>
    </VRow>
  </form>
</template>

<script>
import Queries from 'queries/index.js';
import {FormMixin, KBtnSubmit, KTextField, ProjectPicker} from 'top/forms/elements';
import BillableCheckbox from './BillableCheckbox.vue';
import {
  between,
  betweenLength,
  minLength,
  primaryKey,
  required,
} from "validators/index.js";
import {mapGetters} from "vuex";

const baseFormDefaults = {
  description: '',
};

const partitionDefaults = {
  weight: 1,
  comment: '',
  isTimeslipBillable: false,
  isExpenseBillable: false,
  projectId: '',
  randomId: 'notsorandom',
};

export default {
  mixins: [
    FormMixin,
  ],

  data() {
    return {
      form: this.getFormDefaults(),
    };
  },

  components: {
    BillableCheckbox,
    KBtnSubmit,
    KTextField,
    ProjectPicker,
  },

  props: {
    partitions: Array,

    context: {
      type: String,
      validator: (v) => ['timeslip', 'expense'].includes(v),
    },
  },

  apollo: {
    partitionsSet: {
      query: Queries.PartitionsSet.Write.FormFill,
      variables() {
        return {
          id: this.target,
        };
      },
      skip() {
        return !this.isUpdate;
      },
    },
  },

  validations() {
    return {
      form: {
        description: {
          required,
          betweenLength: betweenLength(2, 250),
        },
        partitions: {
          required,
          minLength: minLength(1),
          $each: {
            projectId: {
              primaryKey,
              required,
            },
            weight: {
              required,
              between: between(0.01, 1000),
            },
            comment: {
              betweenLength: betweenLength(2, 250),
            },
          },
        },
      },
    };
  },

  watch: {
    partitions: {
      handler(v) {
        if (v) {
          this.formReset();
        }
      },
      immediate: true,
    },

    partitionsSet: {
      handler(v) {
        if (v) {
          this.formReset();
        }
      },
      //immediate: true,
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),
  },

  methods: {
    partitionApiRow({weight, comment, isTimeslipBillable, isExpenseBillable, projectId}) {
      return {
        weight,
        comment,
        isTimeslipBillable,
        isExpenseBillable,
        projectId,
      };
    },

    async createAction() {
      const {form: {description, partitions}} = this;
      const variables = {
        description,
        partitions: partitions.map(this.partitionApiRow),
      };
      const {data: {partitionsSetCreate: response}} = await this.$apollo.mutate({
        mutation: Queries.PartitionsSet.Write.Create,
        variables,
      });

      return response;
    },

    async updateAction() {
      const {target, form: {description, partitions}} = this;
      const variables = {
        id: target,
        description,
        partitions: partitions.map(this.partitionApiRow),
      };
      const {data: {partitionsSetUpdate: response}} = await this.$apollo.mutate({
        mutation: Queries.PartitionsSet.Write.Update,
        variables,
      });

      this.formReset();

      return response;
    },

    addPartition() {
      const {form: {partitions}, getRandomId} = this;
      partitions.push(Object.assign({}, partitionDefaults, {randomId: getRandomId()}));
    },

    removePartition(index) {
      const {form: {partitions}} = this;
      if (partitions.length > 1) {
        partitions.splice(index, 1);
      }
    },

    generatePartitionRow({weight = 1, comment = '', isTimeslipBillable = false, isExpenseBillable = false, projectId = '', randomId = null}) {
      return {
        weight,
        comment,
        isTimeslipBillable,
        isExpenseBillable,
        projectId,
        randomId: randomId ?? this.getRandomId(),
      };
    },

    getFormDefaults() {
      const partitions = [
        this.generatePartitionRow({}),
        this.generatePartitionRow({}),
      ];
      return {
        ...baseFormDefaults,
        partitions,
      };
    },

    formReset() {
      const {partitionsSet, form, partitions, generatePartitionRow} = this;
      if (partitionsSet) {
        form.description = partitionsSet.description;
        form.partitions = partitionsSet.partitions.map((row) => generatePartitionRow({...row, projectId: row.project.id}));
      }

      if (partitions) {
        Object.entries(baseFormDefaults).forEach(([key, value]) => {
          form[key] = value;
        });
        form.partitions = partitions.map(generatePartitionRow);
      }
    },
  },

  mounted() {
    this.formReset();
  },
}
</script>
