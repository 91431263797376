<template>
  <VAlert
      v-model="show"
      :type="type"
      :dismissible="dismissible"
  >
    {{text}}
  </VAlert>
</template>

<script>
  const LONG_TIMEOUT = 10000;
  const TYPE_DEFAULT = 'info';

  export default {
    data: () => ({
      show: true,
    }),

    props: {
      message: {
        type: Object,
        required: true,
      }
    },

    computed: {
      type() {
        return this.message.type || TYPE_DEFAULT;
      },

      dismissible() {
        return !this.message.timeout || this.message.timeout >= LONG_TIMEOUT;
      },

      text() {
        const text = this.message.text;

        if (text.indexOf('.') !== -1 && text.indexOf(' ') === -1) {
          const args = this.message.args;
          const buffer = {};
          if (args) {
            Object.keys(args).forEach((key) => {
              const arg = args[key];
              buffer[key] = arg.indexOf('.') !== -1 && arg.indexOf(' ') === -1 ? this.$tc(arg, 1) : arg;
            });
          }


          return this.$t(text, buffer);
        }

        return text;
      },
    },

    watch: {
      show(v) {
        if (!v) {
          this.destroy();
        }
      }
    },

    methods: {
      destroy() {
        this.$store.dispatch('flash/destroy', this.message.id);
      },
    }
  }
</script>
