<template>
  <VContainer>
    <ListBrowser :title="{path: 'models.user.alt', choice: 2}" query="User.Browse.List" :create-route="canCreate ? {name: 'userCreate'} : undefined">
      <template v-slot:filter="{setVariables, setIsFiltered, showFilter}">
        <UsersFilter @input="setVariables" @filtered="setIsFiltered" :show-filter="showFilter" :order-desc-default="false"/>
      </template>
      <template v-slot:item="{item}">
        <UserListItem :id="item.id" :key="item.id"/>
      </template>
    </ListBrowser>
  </VContainer>
</template>

<script>
  import UsersFilter from "top/views/users/browse/UsersFilter.vue";
  import UserListItem from "./UserListItem.vue";
  import ListBrowser from "elements/list/ListBrowser.vue";

  export default {
    components: {
      ListBrowser,
      UserListItem,
      UsersFilter,
    },

    apollo: {
      canCreate() {
        return this.policyChecker({
          policy: 'create',
          type: 'User',
        });
      },
    },
  }
</script>
