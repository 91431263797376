import gql from 'graphql-tag';

// Single

const Main = gql`
  query(
    $id: ID!
  ) {
    timeslipPartition(
      id: $id
    ) {
      id
      weight
      duration
      amount
      isBillable
      billingRate
      comment
      project {
        id
        name
      }
    }
  }
`;


// Export

const Single = {
  Main,
};

export default {
  Single,
};
