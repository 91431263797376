<template>
  <VExpansionPanelContent class="grey lighten-4">
    <VExpandTransition mode="out-in">
      <VProgressLinear v-if="$apollo.loading" indeterminate rounded height="6" class="mt-4"/>
      <VContainer v-else-if="invoice">
        <VRow>
          <VCol cols="12">
            {{invoice.message}}
          </VCol>
          <VCol cols="12" md="7">
            <VCard flat>
              <ProjectListItem :id="invoice.project.id" dense icon/>
            </VCard>
          </VCol>
          <VCol v-if="invoice.client" cols="12" md="5">
            <VCard flat>
              <CompanyListItem :id="invoice.client.id"/>
            </VCard>
          </VCol>
          <VCol cols="12" class="d-flex flex-nowrap">
            <FaI :class="statusColor" :icon="statusIcon" size="lg" class="mr-2"/>
            <div>
              <span v-if="invoice.dueDate">
                <span v-t="'views.invoicing.due'"/>
                {{invoice.dueDate}},
              </span>
              <span v-if="invoice.isPaid">
                <span v-t="'models.invoice.isPaid'"/>
                <span v-if="invoice.paidDate">
                  {{invoice.paidDate}}
                </span>
              </span>
              <span v-else-if="invoice.isOverdue">
                <span v-t="'models.invoice.isOverdue'"/>
              </span>
              <span v-else v-t="'models.invoice.notPaid'"/>
              <span v-if="durationSince">
                ({{durationSince}})
              </span>
            </div>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" v-if="invoice.invoiceLines">
            <VCard outlined>
              <VList>
                <template v-for="({id}, index) in invoice.invoiceLines">
                  <VDivider :key="`d-${id}`" v-if="index !== 0"/>
                  <InvoiceListItemLine :id="id" :key="id"/>
                </template>
                <VDivider v-if="hasSalesTax" key="d-sales-tax"/>
                <VListItem disabled v-if="hasSalesTax" key="sales-tax">
                  <VListItemContent>
                    <VListItemTitle>
                      {{ invoice.salesTaxPercentage }}%
                      <span v-t="'models.invoice.salesTaxCode'"/>
                      <span v-if="invoice.salesTaxCode">
                        ({{invoice.salesTaxCode}})
                      </span>
                    </VListItemTitle>
                  </VListItemContent>
                  <VListItemIcon>
                    <strong>
                      {{salesTaxFormatted}}
                    </strong>
                  </VListItemIcon>
                </VListItem>
              </VList>
            </VCard>
          </VCol>
        </VRow>
        <VRow class="grey--text py-2" justify="space-between" no-gutters>
          <VCol class="d-flex flex-column" cols="auto">
            <small>
              <span v-t="'actions.created'"/> {{createdTimestamp}}
            </small>
            <small v-if="updatedTimestamp">
              <FaI icon="pen"/>
              <span v-t="'actions.updated'"/> {{updatedTimestamp}}
            </small>
          </VCol>
        </VRow>
      </VContainer>
    </VExpandTransition>
  </VExpansionPanelContent>
</template>

<script>
import tickerMixin from "top/mixins/tickerMixin.js";
import MediaListItem from "elements/media/MediaListItem.vue";
import InvoiceListItemLine from "./InvoiceListItemLine.vue";
import ProjectListItem from "../../../projects/browse/elements/ProjectListItem.vue";
import CompanyListItem from "../../../clients/browse/elements/CompanyListItem.vue";
import {UserThumbnail} from "elements/index.js";
import Queries from "queries/index.js";
import {parseISO, formatDistanceStrict} from 'date-fns';
import currencyMixin from "../../../../mixins/currencyMixin.js";

export default {
  mixins: [
    tickerMixin,
    currencyMixin,
  ],

  components: {
    CompanyListItem,
    MediaListItem,
    ProjectListItem,
    UserThumbnail,
    InvoiceListItemLine,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  inject: ['expansionPanel'],

  apollo: {
    invoice: {
      query: Queries.Invoice.Single.ListItemContent,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.isVisible;
      },
    },
  },

  computed: {
    isVisible() {
      return this.expansionPanel.isActive;
    },

    hasSalesTax() {
      return Boolean(this.invoice?.salesTaxTotal);
    },

    salesTaxFormatted() {
      const {invoice, getCurrency} = this;
      return getCurrency(invoice?.salesTaxTotal);
    },

    createdTimestamp() {
      const {invoice, getTickerForHumans} = this;
      return getTickerForHumans(invoice?.createdAt);
    },

    updatedTimestamp() {
      const {invoice, getTickerForHumans} = this;
      return invoice && invoice.updatedAt !== invoice.createdAt
          ? getTickerForHumans(invoice.updatedAt)
          : '';
    },

    statusColor() {
      const {invoice} = this;
      return invoice.isOverdue
        ? 'red--text text--darken-2'
        : invoice.isPaid
          ? 'green--text text--lighten-2'
          : 'orange--text text--darken-2';
    },

    statusIcon() {
      const {invoice} = this;
      return invoice.isOverdue
        ? 'circle-exclamation'
        : invoice.isPaid
          ? 'circle-check'
          : ['far', 'circle'];
    },

    durationSince() {
      const {invoice} = this;

      if (invoice.isPaid && invoice.paidDate) {
        return formatDistanceStrict(parseISO(invoice.dueDate), parseISO(invoice.paidDate));
      }
      if (!invoice.isPaid) {
        return formatDistanceStrict(parseISO(invoice.dueDate), new Date(), {addSuffix: true});
      }

      return null;
    },
  },
}
</script>
