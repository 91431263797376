<template>
  <VContainer>
    <Devices :devices="devices" @update:devices="$emit('update:devices', $event)"/>
    <VCard class="mt-3">
      <VCardTitle>
        Settings
      </VCardTitle>
      <VCardText>
        <VRow>
          <VCol cols="12" v-for="{name, title, options} in settingsScheduleParsed" :key="name">
            <span class="text-h6">
              {{title}}
            </span>
            <VRadioGroup hide-details row :value="settings?.[name]" @change="settingsUpdate(name, $event)">
              <VRadio v-for="{value, label} in options" :key="value" :label="label" :value="value"/>
            </VRadioGroup>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
    <VCard class="mt-3">
      <VCardTitle v-t="'views.performance.setup.data.title'"/>
      <VCardText>
        <VRow>
          <VCol cols="auto">
            <ClickConfirm color="yellow lighten-2" @confirm="$emit('clear')" :dialog-width="300">
              <FaI icon="rotate-left" class="mr-2"/>
              <span v-t="'views.performance.setup.data.clear'"/>
              <template v-slot:prompt>
                <span v-t="'views.performance.setup.data.clear'"/> - <span v-t="'actions.confirmPrompt'"/>
              </template>
            </ClickConfirm>
          </VCol>
          <VCol cols="auto" class="ml-auto">
            <ClickConfirm color="red lighten-2" @confirm="$emit('reset')" :dialog-width="300">
              <FaI icon="trash" class="mr-2"/>
              <span v-t="'views.performance.setup.data.reset'"/>
              <template v-slot:prompt>
                <span v-t="'views.performance.setup.data.reset'"/> - <span v-t="'actions.confirmPrompt'"/>
              </template>
            </ClickConfirm>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </VContainer>
</template>

<script>
import ClickConfirm from '../../../elements/ClickConfirm.vue';
import Devices from './Devices.vue';
import {FormEventIndicator} from "@/elements";

const settingsSchedule = [
  {
    name: 'units',
    values: ['i', 'm'],
  },
  {
    name: 'speed',
    values: ['show', 'hide'],
  },
];

export default {
  components: {
    FormEventIndicator,
    ClickConfirm,
    Devices,
  },

  props: {
    devices: {
      type: Array,
      required: true,
    },

    settings : {
      type: Object,
      required: true,
    },
  },

  computed: {
    settingsScheduleParsed() {
      return settingsSchedule.map(({name, values}) => ({
        name,
        title: this.$t(`views.performance.setup.settings.${name}.title`),
        options: values.map((value) => ({
          value,
          label: this.$t(`views.performance.setup.settings.${name}.${value}`),
        })),
      }));
    },
  },

  methods: {
    settingsUpdate(name, value) {
      const payload = {...this.settings};
      payload[name] = value;
      this.$emit('update:settings', payload);
    },
  },

  async created() {
    for (const {name, values} of settingsSchedule) {
      if (!this.settings?.[name]) {
        this.settingsUpdate(name, values[0]);
        await this.$nextTick();
      }
    }
  },
}
</script>
