import gql from 'graphql-tag'
import Team from './team.js'
import PolicyTest from './policyTest.js'
import DataExport from './dataExport.js'
import PaginatorFragment from './paginatorFragment.js'

const ListFragment = gql`
  fragment projectListFragment on Project {
    id
    name
    slug
    createdAt
    updatedAt
    office {
      id
      slug
      name
    }
    projectType {
      id
      name
    }
    projectStatus {
      id
      name
    }
    permissions(policies: [{policy: "view"}]) {
      ...isAllowedFragment
    }
  }
  ${PolicyTest.Fragments.IsAllowed}
`;

const SearchFragment = gql`
  fragment projectSearchFragment on Project {
    id
    name
    projectStatus {
      id
      name
    }
    projectType {
      id
      name
    }
    office {
      id
      name
    }
  }
`;


// Browse

const List = gql`
  query(
    $teamMember: ProjectTeamMemberInput
    $search: String
    $officeId: ID
    $projectTypeId: ID
    $projectStatusId: ID
    $recordDueBy: Date
    $orderBy: [QueryProjectsOrderByOrderByClause!]
    $page: Int
    $first: Int!
  ) {
    projects(
      teamMember: $teamMember
      search: $search
      office: $officeId
      projectType: $projectTypeId
      projectStatus: $projectStatusId
      recordDueBy: $recordDueBy
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        ...projectListFragment
      }
    }
  }
  ${PaginatorFragment}
  ${ListFragment}
`;

const Export = gql`
  mutation(
    $uuid: UUID!
    $teamMember: ProjectTeamMemberInput
    $search: String
    $projectTypeId: ID
    $projectStatusId: ID
    $officeId: ID
    $recordDueAt: DateRange
    $recordDueBy: Date
    $orderBy: [MutationProjectsExportOrderByOrderByClause!]
    $includeInvoicingActivity: Boolean
  ) {
    projectsExport(
      uuid: $uuid
      teamMember: $teamMember
      search: $search
      projectType: $projectTypeId
      projectStatus: $projectStatusId
      office: $officeId
      recordDueAt: $recordDueAt
      recordDueBy: $recordDueBy
      orderBy: $orderBy
      includeInvoicingActivity: $includeInvoicingActivity
    ) {
      ...dataExportDownloadFragment
    }
  }
  ${DataExport.Fragments.DownloadFragment}
`;

const Row = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      ...projectListFragment
    }
  }
  ${ListFragment}
`;

const RowDense = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      id
      name
      slug
      office {
        id
        slug
        name
      }
      projectType {
        id
        name
      }
      projectStatus {
        id
        name
      }
    }
  }
`;

const ProjectRecordsList = gql`
  query(
    $teamMember: ProjectTeamMemberInput
    $search: String
    $officeId: ID
    $projectTypeId: ID
    $projectStatusId: ID
    $recordDueBy: Date
    $orderBy: [QueryProjectsOrderByOrderByClause!]
    $page: Int
    $first: Int!
  ) {
    projects(
      teamMember: $teamMember
      search: $search
      office: $officeId
      projectType: $projectTypeId
      projectStatus: $projectStatusId
      recordDueBy: $recordDueBy
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        ...projectListFragment
        recordDueAt
      }
    }
  }
  ${PaginatorFragment}
  ${ListFragment}
`;

const ProjectRecordsFastFillList = gql`
  query projectRecordsFastFillList(
    $teamMember: ProjectTeamMemberInput
    $search: String
    $officeId: ID
    $projectTypeId: ID
    $projectStatusId: ID
    $recordDueBy: Date
    $orderBy: [QueryProjectsOrderByOrderByClause!]
    $page: Int
    $first: Int!
  ) {
    projects(
      teamMember: $teamMember
      search: $search
      office: $officeId
      projectType: $projectTypeId
      projectStatus: $projectStatusId
      recordDueBy: $recordDueBy
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        id
        recordDueAt
      }
    }
  }
  ${PaginatorFragment}
`;


// Forms

const Search = gql`
  query(
    $teamMember: ProjectTeamMemberInput
    $search: String!
    $first: Int!
  ) {
    projects(
      teamMember: $teamMember
      search: $search
#      scope: ALL
      first: $first
    ) {
      data {
        ...projectSearchFragment
      }
    }
  }
  ${SearchFragment}
`;

const Populate = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      ...projectSearchFragment
    }
  }
  ${SearchFragment}
`;

const ProjectRecordPopulate = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      id
      createdAt
      projectStatus {
        id
        name
      }
      projectType {
        id
        name
      }
    }
  }
`;

const UniqueTest = gql`
  query(
    $name: String
  ) {
    projects(
      name: $name
      first: 1
    ) {
      data {
        id
      }
    }
  }
`;

const DispatcherList = gql`
  query(
    $teamMember: ProjectTeamMemberInput
    $search: String
    $officeId: ID
    $projectTypeId: ID
    $projectStatusId: ID
    $orderBy: [QueryProjectsOrderByOrderByClause!]
    $page: Int
    $first: Int!
  ) {
    projects(
      teamMember: $teamMember
      search: $search
      office: $officeId
      projectType: $projectTypeId
      projectStatus: $projectStatusId
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        id
      }
    }
  }
  ${PaginatorFragment}
`;


// Single

const IdFromSlug = gql`
  query(
    $slug: String!
  ) {
    projectBySlug(
      slug: $slug
    )
    {
      id
      slug
    }
  }
`;

const Main = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      id
      name
      slug
    }
  }
`;

const Overview = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      id
      recordDueAt
      meta {
        balance
      }
      settings {
        locale
        workRegion
        isTaxExempt
        isTimeslipBillable
        isExpenseBillable
      }
      client {
        id
        name
      }
      office {
        id
        name
      }
      projectType {
        id
        name
      }
      projectStatus {
        id
        name
      }
    }
  }
`;

const ProjectRecordsFastFillItem = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      ...projectListFragment
      recordDueAt
    }
  }
  ${ListFragment}
`;

const ProjectTeam = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      id
      team {
        ...teamMembersDisplayFragment
      }
    }
  }
  ${Team.Fragments.MembersDisplay}
`;

const Devices = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      id
      buildings {
        id
        name
        slug
        location {
          id
          addressInline
        }
        devices {
          id
        }
      }
      devices {
        id
        designation
        building {
          id
          name
        }
        deviceType {
          id
          name
        }
      }
    }
  }
`;

const Inspections = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      id
      inspections {
        id
        name
        slug
        createdAt
      }
    }
  }
`;

const DispatcherItem = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      id
      name
      createdAt
      updatedAt
      projectType {
        id
      }
      projectStatus {
        id
      }
      office {
        id
        code
      }
      team {
        id
        roleGroups(roles: ["projectLead", "projectAdmin"]) {
          id
          role {
            id
            name
          }
          users {
            id
            name
          }
        }
      }
    }
  }
`;

const IsBillable = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      id
      settings {
        isTimeslipBillable
        isExpenseBillable
      }
    }
  }
`;


// Write

const FormFill = gql`
  query(
    $id: ID!
  ) {
    project(
      id: $id
    ) {
      id
      name
      settings {
        locale
        workRegion
        isTaxExempt
        isTimeslipBillable
        isExpenseBillable
      }
      client {
        id
        name
      }
      office {
        id
      }
      projectType {
        id
      }
      projectStatus {
        id
      }
    }
  }
`;

const Create = gql`
  mutation(
    $name: String!
    $locale: String
    $workRegion: ID!
    $isTaxExempt: Boolean!
    $clientId: ID
    $officeId: ID!
    $projectTypeId: ID!
    $projectStatusId: ID!
    $projectRecord: ProjectRecordInput!
    $teamMembers: [TeamAttachUserInput!]!
    $isTimeslipBillable: BillableType
    $isExpenseBillable: BillableType
  ) {
    projectCreate(
      input: {
        name: $name
        projectRecord: $projectRecord
        teamMembers: $teamMembers
        settings: {
          locale: $locale
          workRegion: $workRegion
          isTaxExempt: $isTaxExempt
          isTimeslipBillable: $isTimeslipBillable
          isExpenseBillable: $isExpenseBillable
        }
        client: {
          connect: $clientId
        }
        office: {
          connect: $officeId
        }
        projectType: {
          connect: $projectTypeId
        }
        projectStatus: {
          connect: $projectStatusId
        }
      }
    ) {
      id
      name
      slug
      settings {
        locale
        workRegion
        isTaxExempt
        isTimeslipBillable
        isExpenseBillable
      }
      client {
        id
      }
      office {
        id
      }
      projectType {
        id
      }
      projectStatus {
        id
      }
    }
  }
`;

const Update = gql`
  mutation(
    $id: ID!
    $name: String
    $locale: String
    $workRegion: ID
    $isTaxExempt: Boolean
    $clientId: ID
    $clientDisconnect: Boolean
    $officeId: ID
    $projectTypeId: ID
    $isTimeslipBillable: BillableType
    $isExpenseBillable: BillableType
  ) {
    projectUpdate(
      id: $id,
      input: {
        name: $name
        settings: {
          locale: $locale
          workRegion: $workRegion
          isTaxExempt: $isTaxExempt
          isTimeslipBillable: $isTimeslipBillable
          isExpenseBillable: $isExpenseBillable
        }
        client: {
          connect: $clientId
          disconnect: $clientDisconnect
        }
        office: {
          connect: $officeId
        }
        projectType: {
          connect: $projectTypeId
        }
      }
    ) {
      id
      name
      slug
      settings {
        locale
        workRegion
        isTaxExempt
        isTimeslipBillable
        isExpenseBillable
      }
      client {
        id
      }
      office {
        id
      }
      projectType {
        id
      }
      projectStatus {
        id
      }
    }
  }
`;

const DispatcherUpdate = gql`
  mutation projectDispatcherUpdate(
    $id: ID!
    $projectStatus: ID
    $projectType: ID
  ) {
    projectDispatcherUpdate(
      id: $id,
      input: {
        projectStatus: {
          connect: $projectStatus
        }
        projectType: {
          connect: $projectType
        }
      }
    ) {
      id
      projectStatus {
        id
      }
      projectType {
        id
      }
    }
  }
`;


// Export

const Fragments = {
  List: ListFragment
};

const Browse = {
  List,
  Export,
  Row,
  RowDense,
  ProjectRecordsList,
  ProjectRecordsFastFillList,
  Dispatcher: DispatcherList,
};

const Forms = {
  Populate,
  ProjectRecordPopulate,
  Search,
  UniqueTest,
};

const Single = {
  IdFromSlug,
  Main,
  Overview,
  Team: ProjectTeam,
  Devices,
  Inspections,
  ProjectRecordsFastFillItem,
  Dispatcher: DispatcherItem,
  IsBillable,
};

const Write = {
  FormFill,
  Create,
  Update,
  Dispatcher: DispatcherUpdate,
};

export default {
  Browse,
  Fragments,
  Forms,
  Single,
  Write,
};
