<template>
  <VExpansionPanel>
    <ProjectRecordItemHeader
      :id="id"
      @delete="onDelete"
      @update="onUpdate"
    />
    <ProjectRecordItemContext
      :id="id"
    />
  </VExpansionPanel>
</template>

<script>
  import ProjectRecordItemContext from './ProjectRecordItemContent.vue'
  import ProjectRecordItemHeader from './ProjectRecordItemHeader.vue'

  export default {
    components: {
      ProjectRecordItemContext,
      ProjectRecordItemHeader,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    methods: {
      onDelete(response) {
        this.$emit('delete', response);
      },

      onUpdate(response) {
        this.$emit('update', response);
      },
    },
  }
</script>
