<template>
  <div class="d-flex flex-column">
    <KTextField
      v-model="valueText"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <span v-show="valueText">
      <span v-t="'forms.messages.parsed'"/>:
      <span v-if="listForDisplay">
        {{listForDisplay}}
      </span>
      <em v-else v-t="'ui.none'"/>
    </span>
  </div>
</template>

<script>
import KTextField from "./KTextField.vue";
import complexRangeParser from '../../complexRangeParser.js';

export default {
  components: {
    KTextField,
  },

  data: () => ({
    valueText: undefined,
  }),

  props: {
    value: {
      type: Array,
      required: true,
    }
  },

  computed: {
    list() {
      const {valueText} = this;
      if (!valueText) {
        return [];
      }

      return complexRangeParser(valueText);
    },

    listForDisplay() {
      return this.list.join(', ');
    },
  },

  watch: {
    list(v) {
      this.$emit('input', v);
    },
  },
}
</script>
