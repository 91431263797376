<template>
  <VExpansionPanel>
    <InvoiceListItemHeader
      :id="id"
    />
    <InvoiceListItemContent
      :id="id"
    />
  </VExpansionPanel>
</template>

<script>
import InvoiceListItemHeader from "./InvoiceListItemHeader.vue";
import InvoiceListItemContent from "./InvoiceListItemContent.vue";

export default {
  components: {
    InvoiceListItemHeader,
    InvoiceListItemContent,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
}
</script>
