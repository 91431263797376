<template>
  <VSelect
    :autofocus="autofocus"
    :chips="chips"
    :clearable="clearable"
    :disabled="disabled"
    :error-messages="errorMessages"
    :hint="hintParsed"
    :item-text="itemText"
    :item-value="itemValue"
    :items="items"
    :label="labelParsed"
    :loading="loading || $apollo.queries.items.loading"
    :multiple="multiple"
    :outlined="outlined"
    :persistent-hint="localPersistentHint"
    :return-object="returnObject"
    :suffix="suffix"
    :value="value"
    @blur="doTouch"
    @input="onInput"
    :dense="dense"
    :hide-details="hideDetails"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </VSelect>
</template>

<script>
  import KFormTextElementMixin from "./KFormTextElementMixin.js";
  import Queries from "queries/index.js";
  import {get} from "lodash-es";

  export default {
    mixins: [
      KFormTextElementMixin,
    ],

    data: () => ({
      items: [],
    }),

    props: {
      model: {
        type: String,
        required: true,
      },

      queryName: String,

      skip: Boolean,

      query: {
        type: [Object, String],
      },

      variables: {
        type: Object,
        default: () => ({}),
      },

      updateFunction: [Function, String],

      chips: Boolean,

      itemText: {
        type: [String, Array, Function],
        default: 'name',
      },

      itemValue: {
        type: [String, Array, Function],
        default: 'id',
      },

      multiple: Boolean,

      returnObject: Boolean,

      dense: Boolean,

      hideDetails: Boolean,

      value: {
        required: true,
      },
    },

    apollo: {
      items: {
        query() {
          return this.itemsQuery;
        },
        variables() {
          return this.variables;
        },
        skip() {
          const {itemsQuery, skip} = this;
          return skip || itemsQuery === undefined;
        },
        update(response) {
          return this.itemsUpdate(response);
        },
      },
    },

    computed: {
      queryGroup() {
        const {model} = this;
        return model.charAt(0).toUpperCase() + model.slice(1);
      },

      itemsQuery() {
        const {query, queryGroup, model} = this;

        let payload = undefined;

        if (typeof query === "object" && query !== null) {
          payload = query;
        } else {
          const target = typeof query === "string" ? query : `${queryGroup}.Forms.Select`
          payload = get(Queries, target);
        }

        if (typeof payload !== "object" || payload === null) {
          console.warn(`Query provided for '${model}' is invalid.`);
          return undefined;
        }

        return payload;
      },

      itemsQueryName() {
        return this.itemsQuery?.definitions?.[0]?.selectionSet?.selections?.[0]?.name?.value;
      },

      labelParsed() {
        const {parseDisplayMessage, label, model} = this;
        return parseDisplayMessage(label || `models.${model}.self`);
      },
    },

    methods: {
      itemsUpdate(response) {
        const {updateFunction, itemsQueryName} = this;
        if (typeof updateFunction === 'string') {
          return get(response, updateFunction);
        }

        if (typeof updateFunction === 'function') {
          return updateFunction(response);
        }

        return response[itemsQueryName];
      },
    }
  }
</script>
