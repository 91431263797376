<template>
  <VTextField
    :hint="hintParsed"
    :error-messages="errorMessages"
    :label="labelParsed"
    :disabled="disabled"
    v-model="localValue"
    @focus="onFocus"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
    <template v-slot:append>
      <FaI :icon="['far', 'timer']" color="grey"/>
    </template>
  </VTextField>
</template>

<script>
import KFormElementMixin from './KFormElementMixin.js'

const matchRegex = /^(\d{0,2}\.)?\d{1,2}$/;

export default {
  mixins: [
    KFormElementMixin,
  ],

  data: () => ({
    textFieldValue: undefined,
  }),

  computed: {
    localValue: {
      get() {
        //return this.value/3600;
        return this.value || this.value === 0 ? (Math.round(this.value / 3600 * 100) / 100).toString() : undefined;
      },
      set(v) {
        if (!v) {
          this.onInput(undefined);
        }

        if (v.match(matchRegex)) {
          this.onInput(Math.round(v * 3600));
        }
      }
    },
  },

  methods: {
    onFocus(event) {
      if (!this.isOnMobile) {
        event.target.select();
      }
    },
  },
}
</script>
