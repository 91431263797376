<template>
  <VSkeletonLoader v-if="$apollo.queries.user.loading" type="list-item-two-line"/>
  <VListItem v-else :value="localSelectionValue" :disabled="disabled" :to="to" two-line #default="{active}" v-bind="$attrs">
    <VListItemAvatar size="32">
      <UserThumbnail :id="id" disabled no-tooltip size="sm"/>
    </VListItemAvatar>
    <VListItemContent>
      <VListItemTitle>
        {{user.name}}
      </VListItemTitle>
      <VListItemSubtitle>
        <slot name="default" :id="id" :user="user">
          {{user.email}}
        </slot>
      </VListItemSubtitle>
    </VListItemContent>
    <VListItemAction v-if="$scopedSlots.action || doSelection">
      <slot name="action" :id="id" :user="user">
        <VCheckbox
            v-if="doSelection"
            :input-value="active"
            dense
            :disabled="disabled"
        />
      </slot>
    </VListItemAction>
  </VListItem>
</template>

<script>
import UserThumbnail from "elements/UserThumbnail.vue";
import Queries from 'queries/index.js'

export default {
  components: {
    UserThumbnail,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },

    disabled: Boolean,

    selections: Array,

    selectionValue: [Number, String],
  },

  apollo: {
    user: {
      query: Queries.User.Info.Thumbnail,
      variables() {
        return {
          id: this.id,
        };
      },
    },

    canView() {
      return this.policyChecker({
        policy: 'view',
        type: 'User',
        id: this.id,
        skip: () => this.disabled,
      });
    },
  },

  computed: {
    localSelections: {
      get() {
        return this.selections;
      },
      set(v) {
        this.$emit('update:selections', v);
      },
    },

    doSelection() {
      return this.selections !== undefined;
    },

    to() {
      const {doSelection, canView, user} = this,
          {id, slug} = user;
      return !doSelection && canView && user ? {name: 'user', params: {id, slug}} : undefined;
    },

    localSelectionValue() {
      const {selectionValue, id} = this;
      return selectionValue || id;
    },
  },
}
</script>
