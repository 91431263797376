import gql from 'graphql-tag'
import DataExport from './dataExport.js'
import PaginatorFragment from './paginatorFragment.js'
import Media from './media.js'

// Fragments

const ExpenseFragment = gql`
  fragment expenseFragment on Expense
  {
    id
    date
    amount
    description
    isReimbursable
    createdAt
    updatedAt
    expenseType {
      id
    }
    salesTaxCode {
      id
      name
    }
    user {
      id
    }
    receipts {
      id
    }
    expensePartitions {
      id
      amount
      weight
      comment
      isBillable
      project {
        id
        name
      }
    }
  }
`;

const ReceiptThumbnailFragment = gql`
  fragment receiptThumbnailFragment on Expense
  {
    id
    receipts {
      id
      fileName
      conversions(filter: ["thumbnail"]) {
        id
        name
        url
      }
    }
  }
`;


// Browse

const List = gql`
  query(
    $date: DateRange
    $userId: ID
    $userOfficeId: ID
    $projectOfficeId: ID
    $projectId: ID
    $expenseTypeIds: [ID!]
    $isBillable: BillableScope
    $isReimbursable: Boolean
    $orderBy: [QueryExpensesOrderByOrderByClause!]
    $page: Int
    $first: Int!
  ) {
    expenses(
      date: $date
      user: $userId
      userOffice: $userOfficeId
      projectOffice: $projectOfficeId
      project: $projectId
      expenseTypes: $expenseTypeIds
      isBillable: $isBillable
      isReimbursable: $isReimbursable
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        ...expenseFragment
      }
    }
  }
  ${PaginatorFragment}
  ${ExpenseFragment}
`;

const Export = gql`
  mutation(
    $uuid: UUID!
    $date: DateRange
    $userId: ID
    $userOfficeId: ID
    $projectOfficeId: ID
    $projectId: ID
    $expenseTypeIds: [ID!]
    $isBillable: BillableScope
    $isReimbursable: Boolean
    $orderBy: [MutationExpensesExportOrderByOrderByClause!]
  ) {
    expensesExport(
      uuid: $uuid
      date: $date
      user: $userId
      userOffice: $userOfficeId
      projectOffice: $projectOfficeId
      project: $projectId
      expenseTypes: $expenseTypeIds
      isBillable: $isBillable
      isReimbursable: $isReimbursable
      orderBy: $orderBy
    ) {
      ...dataExportDownloadFragment
    }
  }
  ${DataExport.Fragments.DownloadFragment}
`;


// Single

const Main = gql`
  query(
    $id: ID!
  ) {
    expense(
      id: $id
    ) {
      ...expenseFragment
    }
  }
  ${ExpenseFragment}
`;

const ExpansionPanelHeader = gql`
  query(
    $id: ID!
  ) {
    expense(
      id: $id
    ) {
      id
      date
      amount
      isReimbursable
      expensePartitions {
        id
        isBillable
        project {
          id
          name
        }
      }
      expenseType {
        id
      }
      user {
        id
      }
      receipts {
        id
      }
    }
  }
`;

const ExpansionPanelContent = gql`
  query(
    $id: ID!
  ) {
    expense(
      id: $id
    ) {
      id
      description
      isReimbursable
      createdAt
      updatedAt
      salesTaxCode {
        id
        name
      }
      expensePartitions {
        id
        amount
        weight
        isBillable
        comment
        project {
          id
          name
        }
      }
      user {
        id
      }
      receipts {
        id
      }
    }
  }
`;

const Receipts = gql`
  query(
    $id: ID!
  ) {
    expense(
      id: $id
    ) {
      id
      user {
        id
      }
      receipts {
        id
      }
    }
  }
`;


// Write

const FormFill = gql`
  query(
    $id: ID!
  ) {
    expense(
      id: $id
    ) {
      id
      date
      amount
      description
      isReimbursable
      expenseType {
        id
      }
      salesTaxCode {
        id
      }
      receipts {
        ...mediaThumbnailFragment
      }
      expensePartitions {
        id
        amount
        weight
        isBillable
        comment
        project {
          id
          name
        }
      }
    }
  }
  ${Media.Fragments.MediaThumbnailFragment}
`;

const Create = gql`
  mutation(
    $date: Date!
    $amount: Float!
    $description: String!
    $isReimbursable: Boolean
    $userId: ID!
    $expenseTypeId: ID!
    $salesTaxCodeId: ID!
    $expensePartitions: [ExpensePartitionCreateOnExpenseRelationship!]!
    $receipts: [MediaAttach]
  ) {
    expenseCreate(
      input: {
        date: $date
        amount: $amount
        description: $description
        isReimbursable: $isReimbursable
        user: {
          connect: $userId
        }
        expenseType: {
          connect: $expenseTypeId
        }
        salesTaxCode: {
          connect: $salesTaxCodeId
        }
        expensePartitions: {
          create: $expensePartitions
        }
        receipts: $receipts
      }
    ) {
      ...expenseFragment
      receipts {
        ...mediaThumbnailFragment
      }
    }
  }
  ${ExpenseFragment}
  ${Media.Fragments.MediaThumbnailFragment}
`;

const Update = gql`
  mutation(
    $id: ID!
    $date: Date
    $amount: Float
    $description: String
    $isReimbursable: Boolean
    $expenseTypeId: ID
    $salesTaxCodeId: ID
    $expensePartitionsCreate: [ExpensePartitionCreateOnExpenseRelationship!]
    $expensePartitionsUpdate: [ExpensePartitionUpdateOnExpenseRelationship!]
    $expensePartitionsDelete: [ID!]
    $receipts: [MediaAttach]
  ) {
    expenseUpdate(
      id: $id
      input: {
        date: $date
        amount: $amount
        description: $description
        isReimbursable: $isReimbursable
        expenseType: {
          connect: $expenseTypeId
        }
        salesTaxCode: {
          connect: $salesTaxCodeId
        }
        expensePartitions: {
          create: $expensePartitionsCreate
          update: $expensePartitionsUpdate
          delete: $expensePartitionsDelete
        }
        receipts: $receipts
      }
    ) {
      ...expenseFragment
      receipts {
        ...mediaThumbnailFragment
      }
    }
  }
  ${ExpenseFragment}
  ${Media.Fragments.MediaThumbnailFragment}
`;

const ReceiptAttach = gql`
  mutation(
    $id: ID!
    $file: Upload!
  ) {
    expenseReceiptAttach(
      id: $id
      file: $file
    ) {
      ...expenseFragment
    }
  }
  ${ExpenseFragment}
`;

const ReceiptsDelete = gql`
  mutation(
    $id: ID!
    $mediaIds: [ID!]!
  ) {
    expenseReceiptsDelete(
      id: $id
      mediaIds: $mediaIds
    ) {
      ...expenseFragment
    }
  }
  ${ExpenseFragment}
`;

const Delete = gql`
  mutation(
    $id: ID!
  ) {
    expenseDelete(
      id: $id
    ) {
      id
    }
  }
`;


// Export

const Browse = {
  Export,
  List,
};

const Single = {
  Main,
  ExpansionPanelHeader,
  ExpansionPanelContent,
  Receipts,
};

const Write = {
  FormFill,
  Create,
  Update,
  ReceiptAttach,
  ReceiptsDelete,
  Delete,
};

export default {
  Browse,
  Single,
  Write,
};
