<template>
  <VMenu
    :close-on-content-click="false"
    :nudge-right="40"
    min-width="290px"
    offset-y
    ref="menu"
    transition="scale-transition"
    :disabled="disabled"
    v-model="show"
  >
    <template v-slot:activator="{on, attrs}">
      <slot name="activator" :on="on" :attrs="attrs" :value="value">
        <VTextField
          :hint="hintParsed"
          :error-messages="errorMessages"
          :label="labelParsed"
          :clearable="clearable"
          :disabled="disabled"
          :readonly="isOnMobile"
          @input="onTextFieldInput"
          @focus="onFocus"
          @blur="onBlur"
          @click:clear="onClear"
          :value="textFieldValue"
          v-on="on"
          v-bind="attrs"
        >
          <template v-slot:append-outer v-if="showTimeZonePicker">
            <KTimeZonePicker
              v-model="localTimeZone"
              :reference="pivot"
              :disabled="disabled"
            />
          </template>
          <template v-slot:append>
            <FaI :icon="['far', 'clock']" color="grey"/>
          </template>
        </VTextField>
      </slot>
    </template>
    <VTimePicker
      :value="pickerDefault"
      @input="onPickerInput"
      :allowed-hours="allowedHours"
      :allowed-minutes="allowedMinutes"
      ampm-in-title
      @change="onChange"
      :disabled="disabled"
      :format="parsedTimeFormat"
      full-width
      v-if="show"
    />
  </VMenu>
</template>

<script>
import KTimePickerMixin from './KTimePickerMixin.js'


export default {
  mixins: [
    KTimePickerMixin,
  ],

  data: () => ({
    show: false,
  }),

  methods: {
    onChange(v) {
      this.$emit('change', this.parseResponse(v));
      this.$refs.menu.save(v);
      this.show = false;
    },
  }
}
</script>
