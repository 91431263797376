import Main from './Main.vue'
import Overview from './Overview.vue'
import Timeslips from './Timeslips.vue'
import Edit from './Edit.vue'
import Team from './Team.vue'
import Expenses from './Expenses.vue'
import BillingRates from './BillingRates.vue'
import Invoicing from "./Invoicing.vue";

export default {Main, Overview, BillingRates, Timeslips, Edit, Expenses, Invoicing, Team}
