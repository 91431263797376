<template>
  <VCard tile>
    <VCardTitle class="pb-0 subtitle-2 grey--text text--darken-3" v-t="'views.timekeeper.bookedActivity'"/>
    <apexcharts type="bar" :height="isMobile ? '150' : '175'" :options="chartOptions" :series="chartSeries"/>
  </VCard>
</template>

<script>
import Queries from "queries/index.js";
import {mapGetters, mapState} from 'vuex'
import {sortBy} from 'lodash-es'
import {parseISO} from 'date-fns'
import {format} from "top/date-fns-format.js";

const labelFormat = 'LLL d';
const dayFormat = 'EEE';
const dataSets = [
  {
    goal: 'targetDuration',
    color: '#775DD0',
  },
  {
    name: 'durationBillable',
    color: '#F9A825',
  },
  {
    name: 'durationNotBillable',
    color: '#03A9F4',
  },
  {
    name: 'durationAdmin',
    color: '#B0BEC5',
  },
  {
    name: 'durationAway',
    color: '#A5D6A7',
  },
];

export default {
  props: {
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    ...mapState('session', ['now']),

    xAxisColors() {
      const {activityForCharts} = this;
      return !activityForCharts ? 0 : activityForCharts.map(function (item) {
        if (item.isHoliday) {
          return '#CD5C5C';
        }

        if (item.isWeekend) {
          return '#A0A0A0';
        }

        return 0;
      });
    },

    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    chartOptions() {
      return {
        chart: {
          id: 'timeslip-activity',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          parentHeightOffset: 0,
          stacked: true,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              total: {
                enabled: true,
                formatter: (v) => v ? v.toFixed(1) : '',
                style: {
                  fontSize: '12px',
                  colors: ['#304758'],
                }
              },
            },
          },
        },
        colors: dataSets.map((e) => e.color),
        xaxis: {
          labels: {
            style: {
              colors: this.xAxisColors,
            },
            formatter: this.xAxisFormatter,
          },
        },
        tooltip: {
          enabled: false,
        },
        noData: {
          text: this.$t('ui.loading'),
        },
        legend: {
          show: !this.isMobile,
        }
      };
    },

    chartSeries() {
      const {activityForCharts} = this;

      if (!activityForCharts) {
        return [];
      }

      return dataSets.map(({name, goal, color}) => ({
        name: this.$t(`models.activityForChart.${name ?? goal}`),
        data: activityForCharts.map((item) => ({
          x: item.date,
          ...name && {
            y: ((item[name]) / 3600).toFixed(1),
          },
          ...goal && {
            goals: !item[goal] ? null : [{
              name: this.$t(`models.activityForChart.${goal}`),
              value: item[goal] / 3600,
              strokeHeight: 2,
              strokeDashArray: 4,
              strokeColor: color,
            }],
          },
        })),
      }));
    },
  },

  apollo: {
    activityForCharts: {
      query: Queries.TimeslipDay.Browse.List,
      variables() {
        const {from, to, meId} = this;
        return {
          date: {
            from,
            to,
          },
          contextType: "USERS",
          contextId: meId,
        };
      },
      context: {batch: false},
      skip() {
        return !this.meId;
      },
      subscribeToMore: {
        document: Queries.TimeslipDay.Subscriptions.TimeslipDaySaved,
        variables() {
          const {from, to, meId} = this;
          return {
            from,
            to,
            contextType: "USERS",
            contextId: meId,
          };
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          const item = subscriptionData?.data?.activityForChartUpdated;

          if (!item) {
            return previousResult;
          }

          let buffer = [...previousResult?.activityForCharts];
          const match = buffer.findIndex((e) => e.date === item.date);

          if (match !== -1) {
            buffer.splice(match, 1, item);
          } else {
            buffer.push(item);
            buffer = sortBy(buffer, 'date');
          }

          return {
            activityForCharts: buffer,
          };
        },
      }
    },
  },

  methods: {
    xAxisFormatter(val) {
      const date = parseISO(val);

      if(isNaN(date)) {
        return val;
      }

      return this.isMobile
        ? format(date, labelFormat)
        : [
          format(date, dayFormat),
          format(date, labelFormat),
        ];
    }
  },
}
</script>
