<template>
  <VCheckbox
      :disabled="disabled"
      :error-messages="errorMessages"
      :false-value="false"
      :hint="hintParsed"
      :label="labelParsed"
      :loading="loading"
      :persistent-hint="localPersistentHint"
      :true-value="true"
      v-model="localValue"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </VCheckbox>
</template>

<script>
  import KFormElementMixin from './KFormElementMixin.js'

  export default {
    mixins: [
      KFormElementMixin,
    ],

    props: {
      value: {
        type: Boolean,
        required: true,
      },
    },

    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },
    },
  }
</script>
