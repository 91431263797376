<template>
  <NowLoading v-if="$apollo.loading"/>
  <VContainer v-else>
    <VToolbar dense color="grey lighten-3">
      <VToolbarTitle v-t="{path: 'models.projectRecord.self', choice: 2}"/>
      <VSpacer/>
      <VBtn text v-if="canCreate" @click="showForm = !showForm" v-model="showForm">
        <FaI icon="circle-plus" class="black--text mr-2" size="2x"/>
        <span v-t="'actions.new'"/>
      </VBtn>
      <VBtn @click="toggleFilter" text>
        <FaI size="2x" icon="chevron-down" :rotation="showFilter ? 180 : 0" style="transition: all 0.3s ease;"/>
        <div>
          <VFadeTransition leave-absolute>
            <FaI size="2x" key="a" v-if="isFiltered" icon="filter"/>
            <FaI size="2x" key="b" v-else :icon="['far', 'filter']"/>
          </VFadeTransition>
        </div>
      </VBtn>
    </VToolbar>
    <ProjectRecordBrowseTab
        :filter-disables="{project: true, projectTypes: true, offices: true}"
        :filter-defaults="{project: id}"
        :show-filter="showFilter"
        @filtered="setIsFiltered"
        v-slot:default="{onCreate}"
    >
      <VExpandTransition>
        <VCard v-if="showForm" flat class="mx-0">
          <VCardTitle>
            Create new Project Record
          </VCardTitle>
          <VCardText>
            <FormProjectRecordContainer :project-id="id" @success="onCreate($event); showForm = false" @reset="showForm = false"/>
          </VCardText>
        </VCard>
      </VExpandTransition>
    </ProjectRecordBrowseTab>
  </VContainer>
</template>

<script>
import FormProjectRecordContainer from "top/views/projectRecords/forms/FormProjectRecordContainer.vue";
import ProjectRecordBrowseTab from "top/views/projectRecords/elements/ProjectRecordBrowseTab.vue";

export default {
  components: {
    FormProjectRecordContainer,
    ProjectRecordBrowseTab,
  },

  data: () => ({
    showFilter: false,
    isFiltered: false,
    showForm: false,
  }),

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  apollo: {
    canCreate() {
      return this.policyChecker({
        policy: 'create',
        type: 'ProjectRecord',
        args: {
          project: {
            connect: this.id,
          },
        },
      });
    },
  },

  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    setIsFiltered(v) {
      this.isFiltered = v;
    },
  },
}
</script>
