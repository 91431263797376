<template>
  <VCard outlined class="mt-2">
    <!--    <VCardTitle>-->
    <!--      <span v-t="{path: 'models.project.self', choice: multiple ? 2 : 1}" class="subtitle-2"/>-->
    <!--      <VSpacer/>-->
    <!--      <VSwitch-->
    <!--        class="my-0"-->
    <!--        hide-details-->
    <!--        :value="multiple"-->
    <!--        :disabled="isTimeOff"-->
    <!--        @change="doMultipleToggle"-->
    <!--      >-->
    <!--        <template #label>-->
    <!--          <FaI icon="layer-group" class="blue&#45;&#45;text text&#45;&#45;lighten-2"/>&nbsp;-->
    <!--          <span v-t="'ui.split'"/>-->
    <!--        </template>-->
    <!--      </VSwitch>-->
    <!--    </VCardTitle>-->
    <VCardText>
      <VContainer fluid class="ma-0 pa-0">
        <VRow>
          <VCol cols="12" sm="3" md="2" xl="1">
            <VSwitch
              class="flex-grow-0"
              hide-details
              :value="multiple"
              :disabled="isTimeOff"
              @change="doMultipleToggle"
            >
              <template #label>
                <FaI icon="layer-group" class="blue--text text--lighten-2"/>&nbsp;
                <span v-t="'ui.split'"/>
              </template>
            </VSwitch>
          </VCol>
          <VCol cols="12" sm="9" md="10" xl="11" v-if="multiple">
            <VCard color="grey lighten-4" class="flex-grow-1">
              <PartitionsSetPicker v-model="partitionsSetId" :context="context" class="ma-1"/>
            </VCard>
          </VCol>
          <VCol cols="12" :md="multiple ? 12 : 10" :xl="multiple ? 11 : 10" class="ma-0 pa-0">
            <VList class="mb-0 pb-0">
                <template v-for="(partition, index) in localPartitions">
                  <VDivider v-if="index !== 0" :key="`d-${partition.randomId}`"/>
                  <VListItem :key="partition.randomId">
                    <VListItemContent>
                      <VListItemTitle>
                        <VRow>
                          <VCol
                            cols="12"
                            :md="contextIsExpense || multiple ? 9 : 12"
                            :lg="contextIsExpense || multiple ? 10 : 7"
                            xl="5"
                          >
                            <ProjectPicker
                              v-model="partition.projectId"
                              hint="forms.general.required"
                              :validator="$v.localPartitions.$each[index].projectId"
                              :disabled="isTimeOff"
                            />
                          </VCol>
                          <VCol
                            v-if="multiple"
                            cols="4"
                            md="3" order-md="2"
                            lg="2" order-lg="3"
                            xl="1"
                          >
                            <KTextField
                              type="number"
                              step="any"
                              min="0"
                              label="models.partition.weight"
                              v-model.number="partition.weight"
                              :validator="$v.localPartitions.$each[index].weight"
                              hint="forms.general.required"
                            />
                          </VCol>
                          <VCol
                            v-if="multiple && total !== undefined && !contextIsExpense"
                            cols="8"
                            md="3" order-md="3"
                            lg="2" order-lg="4"
                            xl="1"
                          >
                            <KDurationPicker
                              label="models.timeslip.duration"
                              disabled
                              :value="getTimeslipPartitionDuration(partition.weight)"
                            />
                          </VCol>
                          <VCol
                            v-if="multiple && total !== undefined && contextIsExpense"
                            cols="8"
                            sm="4"
                            md="3" order-md="3"
                            lg="2" order-lg="4"
                            xl="1"
                          >
                            <KCurrencyField
                              label="models.expense.amount"
                              disabled
                              :value="getExpensePartitionAmount(partition.weight)"
                            />
                          </VCol>
                          <VCol
                            cols="4"
                            sm="4"
                            md="3" order-md="1"
                            lg="2"
                            xl="1"
                          >
                            <BillableCheckbox
                              v-if="contextIsExpense"
                              v-model="partition.isExpenseBillable"
                              :project="partition.projectId"
                              context="expense"
                              :is-update="isUpdate"
                            >
                              <template #label>
                                <FaI class="orange--text text--darken-2" icon="file-invoice-dollar"/>&nbsp;
                                <span v-t="'models.expensePartition.isBillable'"/>
                              </template>
                            </BillableCheckbox>
                            <BillableCheckbox
                              v-else
                              v-model="partition.isTimeslipBillable"
                              :project="partition.projectId"
                              context="timeslip"
                              :is-update="isUpdate"
                              :lock="isSales"
                            >
                              <template #label>
                                <FaI class="orange--text text--darken-2" icon="file-invoice-dollar"/>&nbsp;
                                <span v-t="'models.timeslipPartition.isBillable'"/>
                              </template>
                            </BillableCheckbox>
                          </VCol>
                          <VCol
                            v-if="!contextIsExpense"
                            cols="8"
                            md="6" order-md="3"
                            lg="3" order-lg="4"
                            xl="2" order-xl="2"
                          >
                            <KBillingRate
                              :is-billable="partition.isTimeslipBillable"
                              :user-id="billingRateUser"
                              :project-id="partition.projectId"
                              :date="date"
                              :lock="isUpdate"
                              :validator="$v.localPartitions.$each[index].billingRate"
                              v-model="partition.billingRate"
                            />
                          </VCol>
                          <VCol
                            v-if="multiple"
                            cols="12"
                            :md="contextIsExpense ? 6 : 12" order-md="5"
                            :lg="contextIsExpense ? 8 : 5" order-lg="2"
                            :xl="contextIsExpense ? 4 : 2" order-xl="5"
                          >
                            <KTextField
                              label="models.partition.comment"
                              v-model="partition.comment"
                              :validator="$v.localPartitions.$each[index].comment"
                            />
                          </VCol>
                        </VRow>
                      </VListItemTitle>
                    </VListItemContent>
                    <VListItemAction v-if="multiple">
                      <VTooltip bottom>
                        <template v-slot:activator="{on, attrs}">
                          <VBtn @click="removePartition(index)" fab small color="red lighten-4" v-on="on" v-bind="attrs">
                            <FaI icon="layer-minus" size="lg"/>
                          </VBtn>
                        </template>
                        <span v-t="'forms.activityPartition.removePartition'"/>
                      </VTooltip>
                    </VListItemAction>
                  </VListItem>
                </template>
            </VList>
          </VCol>
        </VRow>
      </VContainer>
    </VCardText>
    <VCardActions v-if="multiple">
      <VRow>
        <VCol cols="12" sm="6" lg="8">
          <VBtn @click="addPartition" color="green lighten-4" block>
            <FaI icon="layer-plus" size="lg" class="mr-1"/>
            <span v-t="'forms.activityPartition.addPartition'"/>
          </VBtn>
        </VCol>
        <VCol cols="12" sm="6" lg="4">
          <PartitionsSetDialog :partitions="localPartitions" :context="context" class="ml-2" @success="partitionsSetId = $event.id">
            <template #activator="{on, attrs}">
              <VBtn
                :disabled="$v.localPartitions.$invalid && !partitionsSetId"
                color="orange lighten-4"
                class="orange--text text--darken-2"
                block
                v-bind="attrs"
                v-on="on"
              >
                <FaI icon="plus"/>
                <FaI icon="star" class="mr-1"/>
                <span  v-t="'forms.activityPartition.createTemplate'"/>
              </VBtn>
            </template>
          </PartitionsSetDialog>
        </VCol>
      </VRow>
    </VCardActions>
  </VCard>
</template>

<script>
import Queries from 'queries/index.js';
import PartitionsSetDialog from './PartitionsSetDialog.vue';
import {
  KApiSelect,
  KBillingRate,
  KCheckbox,
  KCurrencyField,
  KDurationPicker,
  KTextField,
  ProjectPicker,
} from 'top/forms/elements'
import BillableCheckbox from './BillableCheckbox.vue';
import {
  between,
  betweenLength,
  minLength,
  projectPolicyTest,
  primaryKey,
  required,
  requiredIf,
} from 'validators/index.js'
import PartitionsSetPicker from './PartitionsSetPicker.vue';
import {mapGetters} from "vuex";
import md5 from 'crypto-js/md5.js';
import Base64 from "crypto-js/enc-base64.js";
import currencyMixin from "top/mixins/currencyMixin.js";

const defaults = {
  weight: 1,
  comment: null,
  isTimeslipBillable: false,
  isExpenseBillable: false,
  billingRate: null,
  projectId: '',
};

export default {
  mixins: [
    currencyMixin,
  ],

  components: {
    BillableCheckbox,
    KBillingRate,
    KCheckbox,
    KCurrencyField,
    KDurationPicker,
    KApiSelect,
    KTextField,
    PartitionsSetDialog,
    PartitionsSetPicker,
    ProjectPicker,
  },

  data: () => ({
    localPartitions: [],
    partitionsSetId: undefined,
    partitionsHash: undefined,
  }),

  props: {
    partitions: Array,

    context: {
      type: String,
      required: true,
      validator: (v) => ['timeslip', 'expense'].includes(v),
    },

    userId: [Number, String],

    total: Number,

    date: [Number, String],

    isUpdate: Boolean,

    isSales: Boolean,

    isTimeOff: Boolean,

    bus: Object,
  },

  apollo: {
    partitionsSet: {
      query: Queries.PartitionsSet.Single.Main,
      variables() {
        return {
          id: this.partitionsSetId,
        };
      },
      skip() {
        return !this.partitionsSetId;
      },
    },
  },

  validations() {
    return {
      localPartitions: {
        required,
        minLength: minLength(1),
        $each: {
          projectId: {
            primaryKey,
            required,
            projectPolicyTest: projectPolicyTest(this.userId ?? this.meId, this.context),
          },
          weight: {
            requiredIf: requiredIf(function () {
              return this.multiple && !this.partitionByAmount;
            }),
            between: between(0.01, 1000),
          },
          billingRate: {
            requiredIf: requiredIf((nestedModel) => nestedModel.isTimeslipBillable),
            between: between(10, 9999.99),
          },
          comment: {
            betweenLength: betweenLength(2, 250),
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters('session', ['meId', 'today']),

    billingRateUser() {
      return this.userId ?? this.meId;
    },

    multiple() {
      return this.localPartitions.length > 1;
    },

    weightTotal() {
      return this.localPartitions.reduce((prev, e) => prev + (!isNaN(e.weight) ? Number(e.weight) : 0.0), 0.0);
    },

    contextIsExpense() {
      return this.context === 'expense';
    },
  },

  watch: {
    partitions: {
      handler(v) {
        const hash = md5(JSON.stringify(v)).toString(Base64);
        if (this.partitionsHash !== hash) {
          this.resetForm();
          this.partitionsHash = hash;
        }
      },
      immediate: true,
    },

    localPartitions: {
      handler(v) {
        this.$emit('update', v.map(this.fromLocalToForm));
        // this.partitionsSetId = undefined;
      },
      deep: true,
    },

    partitionsSet(v) {
      if (!v) {
        return;
      }

      this.localPartitions = v.partitions.map(this.fromModelToLocal);
    },

    '$v.localPartitions': {
      handler(v) {
        this.$emit('validator', v);
      },
      immediate: true,
      deep: true,
    },

    isSales(v) {
      if (v) {
        this.localPartitions.forEach((row) => {
          row.isTimeslipBillable = false;
          row.isExpenseBillable = false;
        });
      }
    },

    isTimeOff(v) {
      if (v) {
        this.resetForm();
      }
    },

    multiple(v) {
      if (!v) {
        this.partitionsSetId = undefined;
      }
    },
  },

  methods: {
    fromModelToLocal(row = undefined) {
      return {
        weight: row?.weight ?? defaults.weight,
        comment: row?.comment || defaults.comment,
        isTimeslipBillable: row?.isTimeslipBillable ?? defaults.isTimeslipBillable,
        isExpenseBillable: row?.isExpenseBillable ?? defaults.isExpenseBillable,
        billingRate: row?.billingRate ?? defaults.billingRate,
        projectId: row?.project?.id ?? defaults.projectId,
        randomId: row?.id ?? this.getRandomId(),
      };
    },

    fromFormToLocal(row = undefined) {
      const {contextIsExpense} = this;
      return {
        weight: row?.weight ?? defaults.weight,
        comment: row?.comment || defaults.comment,
        isTimeslipBillable: contextIsExpense ? defaults.isTimeslipBillable : (row?.isBillable ?? defaults.isTimeslipBillable),
        isExpenseBillable: contextIsExpense ? (row?.isBillable ?? defaults.isExpenseBillable) : defaults.isExpenseBillable,
        billingRate: !contextIsExpense ? (row?.billingRate ?? defaults.billingRate) : defaults.billingRate,
        projectId: row?.projectId ?? defaults.projectId,
        randomId: row?.id ?? row?.randomId ?? this.getRandomId(),
      };
    },

    fromLocalToForm(row) {
      const {contextIsExpense} = this;
      return {
        weight: row.weight,
        comment: row.comment,
        isBillable: contextIsExpense ? row.isExpenseBillable : row.isTimeslipBillable,
        ...!contextIsExpense && {billingRate: row.billingRate},
        projectId: row.projectId,
        randomId: row.randomId,
      };
    },

    doMultipleToggle() {
      const {localPartitions: partitions} = this;
      if (partitions.length === 1) {
        if (partitions.length < 2) {
          this.addPartition();
        }
      } else {
        partitions.splice(1);
        partitions[0].weight = 1;
      }
    },

    addPartition() {
      this.localPartitions.push(this.fromFormToLocal());
    },

    removePartition(index) {
      const {localPartitions: partitions} = this;
      if (partitions.length > 1) {
        partitions.splice(index, 1);
      }
    },

    getTimeslipPartitionDuration(weight) {
      const {total, weightTotal} = this;
      return (total && weight) ? Math.round(total*weight/weightTotal) : 0;
    },

    getExpensePartitionAmount(weight) {
      const {total, weightTotal} = this;
      return (total && weight) ? (total*weight/weightTotal + Number.EPSILON).toFixed(2) : '0.00';
    },

    resetForm() {
      if  (this.isTimeOff) {
        this.localPartitions = [this.fromFormToLocal({projectId: '201500'})];
      } else {
        this.localPartitions = this.partitions ? this.partitions.map(this.fromFormToLocal) : [this.fromFormToLocal()];
      }
      this.$v.$reset();
    },
  },

  created() {
    const {bus} = this;
    if (bus) {
      bus.on('reset', () => this.resetForm());
    }
  },
}
</script>
