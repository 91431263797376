<template>
  <VExpansionPanelHeader ripple>
    <VSkeletonLoader type="list-item" v-if="$apollo.loading"/>
    <div v-else class="d-flex flex-nowrap align-center" style="max-width: 100%; padding-right: 28px; margin-right: -24px">
      <CompanyThumbnail size="sm" :id="invoice.client.id" disabled no-tooltip class="flex-shrink-0"/>
      <div class="flex-grow-1 pl-2 d-inline-flex flex-column" style="max-width: 100%; padding-right: 28px; margin-right: -24px">
        <div class="d-flex flex-column flex-md-row">
          <div>
            <strong>{{invoice.reference}}</strong>
            <span>- {{invoice.transactionDate}}</span>
          </div>
          <div class="hidden-sm-and-down">
            &nbsp;-&nbsp;
          </div>
          <div>
            <FaI :class="statusColor" :icon="statusIcon" size="sm"/>
            <span>{{totalFormatted}}</span>
            <small v-if="hasSalesTax" class="grey--text">
              ({{subtotalFormatted}} + {{ taxName }})
            </small>
          </div>
        </div>
        <div class="d-flex flex-nowrap align-center">
          <div class="flex-shrink-1 text-truncate">
            {{project.name}}
          </div>
          <small class="ml-1 grey--text">({{project.id}})</small>
        </div>
      </div>
    </div>
  </VExpansionPanelHeader>
</template>

<script>
import Queries from 'queries/index.js'
import {CompanyThumbnail} from "elements/index.js";
import currencyMixin from "top/mixins/currencyMixin.js";

export default {
  mixins: [
    currencyMixin,
  ],

  components: {
    CompanyThumbnail,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  apollo: {
    invoice: {
      query: Queries.Invoice.Single.ListItemHeader,
      variables() {
        return {
          id: this.id,
        };
      },
    },

    project: {
      query: Queries.Project.Forms.Populate,
      variables() {
        return {
          id: this.invoice.project.id,
        }
      },
      skip() {
        return !this.invoice;
      }
    },
  },

  computed: {
    totalFormatted() {
      const {invoice, getCurrency} = this;
      return invoice ? getCurrency(invoice.subtotal + invoice.salesTaxTotal) : '';
    },

    hasSalesTax() {
      return Boolean(this.invoice?.salesTaxTotal);
    },

    subtotalFormatted() {
      const {invoice, getCurrency} = this;
      return getCurrency(invoice?.subtotal);
    },

    // salesTaxTotalFormatted() {
    //   const {invoice, getCurrency} = this;
    //   return invoice ? getCurrency(invoice.salesTaxTotal) : '';
    // },

    userId() {
      return this.invoice?.user?.id;
    },

    statusColor() {
      const {invoice} = this;
      return invoice.isOverdue
        ? 'red--text text--darken-2'
        : invoice.isPaid
          ? 'green--text text--lighten-2'
          : 'orange--text text--darken-2';
    },

    statusIcon() {
      const {invoice} = this;
      return invoice.isOverdue
        ? 'circle-exclamation'
        : invoice.isPaid
          ? 'circle-check'
          : ['far', 'circle'];
    },

    taxName() {
      return this.$t('models.invoice.tax').toLowerCase();
    },
  },
}
</script>
