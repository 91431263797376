<template>
  <div>
    <div
      :style="distanceStyle"
      style="position: relative; z-index: -1000; visibility: hidden;"
      v-intersect="onIntersect"
    />
    <div
      class="text-center" style="min-height: 100px"
    >
      <slot
        name="loading"
        v-if="status === $options.STATUSES.RUNNING"
      >
        <VProgressLinear
          class="mt-3"
          indeterminate
        />
      </slot>
      <slot
        name="empty"
        v-if="status === $options.STATUSES.EMPTY"
      >
        <FaI
          class="grey--text mt-3"
          icon="empty-set"
          size="lg"
        />
      </slot>
      <slot
        name="end"
        v-if="showDivider"
      >
        <VDivider/>
      </slot>
    </div>
  </div>
</template>

<script>
  import {Intersect} from 'vuetify/lib/directives'

  const STATUSES = {
    DISABLED: 0,
    READY: 5,
    RUNNING: 10,
    EMPTY: 20,
    COMPLETE: 30,
    ERROR: 99,
  };

  export default {
    STATUSES,

    directives: {
      Intersect,
    },

    data: () => ({
      isError: false,
      isIntersecting: false,
      isInView: true, // for vue-router keep-alive
      isRecentlyTriggered: false,
    }),

    props: {
      data: Object,

      disabled: Boolean,

      distance: {
        type: Number,
        default: 200,
        validator: (v) => v >= 0,
      },

      loading: {
        type: Boolean,
        required: true,
      },
    },

    computed: {
      hasMore() {
        const {data} = this;
        return data ? data.paginatorInfo.hasMorePages : false;
      },

      isEmpty() {
        const {data} = this;
        return data ? data.data.length === 0 : false;
      },

      status() {
        const {hasMore, disabled, isEmpty, isError, loading} = this;
        if (disabled) {
          return STATUSES.DISABLED;
        }

        if (loading) {
          return STATUSES.RUNNING;
        }

        if (isError) {
          return STATUSES.ERROR;
        }

        if (isEmpty) {
          return STATUSES.EMPTY;
        }

        if (!hasMore) {
          return STATUSES.COMPLETE;
        }

        return STATUSES.READY;
      },

      isTriggered() {
        const {isRecentlyTriggered, status, isInView, isIntersecting} = this;
        return !isRecentlyTriggered && (status === STATUSES.READY) && isInView && isIntersecting;
      },

      showDivider() {
        const {status} = this;
        return status === STATUSES.COMPLETE || status === STATUSES.EMPTY;
      },

      distanceStyle() {
        return {
          top: `${this.distance * -1}px`,
        };
      }
    },

    timers: {
      clearRecentlyTriggered: {time: 500},
    },

    methods: {
      clearRecentlyTriggered() {
        this.isRecentlyTriggered = false;
      },

      onIntersect(entries, observer, isIntersecting) {
        this.isIntersecting = isIntersecting;
      },
    },

    watch: {
      isTriggered(v) {
        if (v) {
          this.isRecentlyTriggered = true;
          this.$emit('triggered');
        }
      },

      status(v) {
        if (v === STATUSES.READY) {
          this.$timer.stop('clearRecentlyTriggered');
          this.$timer.start('clearRecentlyTriggered');
        }
      }
    },

    activated() {
      this.isInView = true;
    },

    deactivated() {
      this.isInView = false;
    },
  }
</script>
