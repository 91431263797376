<template>
  <VRow>
    <VCol cols="12" class="d-flex flex-nowrap">
      <UserThumbnail :id="meId" disabled class="mr-3" />
      <KApiSelect
        v-model="form.roleId"
        :validator="$v.form.roleId"
        :variables="{context: 'PROJECTS'}"
        item-text="displayName"
        label="forms.project.roleId_label"
        model="role"
        hint="forms.general.required"
        persistent-hint
      />
    </VCol>

    <VCol cols="12" class="d-flex flex-nowrap">
      <UserThumbnail :id="form.supervisorId" disabled class="mr-3" />
      <KSuggest
        model="user"
        label="forms.project.supervisorId_label"
        hint="forms.project.supervisorId_hint"
        v-model="form.supervisorId"
        :validator="$v.form.supervisorId"
        persistent-hint
        clearable
      />
    </VCol>
    <VCol cols="12" v-t="'forms.project.roles_hint'"/>
  </VRow>
</template>

<script>
import UserThumbnail from "elements/UserThumbnail.vue";
import {KApiSelect, KSuggest, scopedFormsMixin} from "forms/elements/index.js";
import {mapGetters} from "vuex";
import Queries from "queries/index.js";
import {primaryKey, required} from "validators/index.js";

export default {
  mixins: [
    scopedFormsMixin,
  ],

  components: {
    KApiSelect,
    KSuggest,
    UserThumbnail,
  },

  data: () => ({
    form: {
      supervisorId: null,
      roleId: null,
    },
  }),

  props: {
    officeId: [Number, String],

    // data: {
    //   type: Object,
    //   required: true,
    // },
  },

  apollo: {
    defaultSupervisor: {
      query: Queries.Office.Forms.GetManager,
      variables() {
        return {
          id: this.officeId,
        };
      },
      skip() {
        return !this.officeId;
      },
      update: (response) => response.office?.team?.roleGroups?.[0]?.users?.[0]?.id,
      fetchPolicy: 'cache-and-network',
    },
  },

  validations: {
    form: {
      roleId: {
        required,
        primaryKey,
      },
      supervisorId: {
        primaryKey,
      },
    },
  },

  watch: {
    defaultSupervisor(v) {
      if (v) {
        this.form.supervisorId = v.toString() === this.meId.toString() ? '' : v;
      }
    },

    // '$v.form': {
    //   handler(v) {
    //     this.$emit('validator', v);
    //   },
    //   immediate: true,
    // },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    // localRoleId: {
    //   get() {
    //     return this.data.roleId;
    //   },
    //   set(v) {
    //     this.updateFormData('roleId', v);
    //   },
    // },
    //
    // localSupervisorId: {
    //   get() {
    //     return this.data.supervisorId;
    //   },
    //   set(v) {
    //     this.updateFormData('supervisorId', v);
    //   },
    // },
  },

  // methods: {
  //   updateFormData(key, value) {
  //     this.$emit('update:data', Object.assign(this.data, {[key]: value}));
  //   },
  // },
}
</script>
