<template>
  <VSkeletonLoader v-if="$apollo.queries.timeslip.loading" type="card"/>
  <form v-else @submit.prevent="doSubmit" autocomplete="off">
    <VContainer>
      <VRow>
        <VCol cols="12">
          <VCard :color="linkTimeChanges ? 'grey lighten-5' : undefined">
            <VRow no-gutters>
              <VCol class="px-3">
                <VRow justify="center">
                  <VCol cols="6" lg="3">
                    <KDatePicker
                      :max="dateMax"
                      :min="dateMin"
                      :validator="$v.form.date"
                      hint="forms.general.required"
                      label="models.timeslip.date"
                      v-model="form.date"
                    />
                  </VCol>
                  <VCol cols="6" lg="3">
                    <KDurationPicker
                      :hint="showExpandedForm ? 'forms.timeslip.duration_helpblock' : 'forms.general.required'"
                      :validator="$v.form.duration"
                      label="models.timeslip.duration"
                      max="12:00"
                      v-model="form.duration"
                    />
                  </VCol>
                  <VExpandTransition>
                    <VCol cols="12" lg="3" sm="6" v-show="showExpandedForm">
                      <KTimePicker
                        :clearable="!form.stopAt"
                        :pivot="dateObject"
                        :time-zone.sync="form.startAtTimeZone"
                        :validator="$v.form.startAt"
                        get-date-object
                        hint="forms.timeslip.startAt_helpblock"
                        label="models.timeslip.startAt"
                        v-model="form.startAt"
                      />
                    </VCol>
                  </VExpandTransition>
                  <VExpandTransition>
                    <VCol cols="12" lg="3" sm="6" v-show="showExpandedForm">
                      <KTimePicker
                        :disabled="!form.startAt"
                        :pivot="form.startAt"
                        :time-zone.sync="form.stopAtTimeZone"
                        :validator="$v.form.stopAt"
                        clearable
                        get-date-object
                        hint="forms.timeslip.stopAt_helpblock"
                        label="models.timeslip.stopAt"
                        v-model="form.stopAt"
                      />
                    </VCol>
                  </VExpandTransition>
                  <VExpandTransition v-show="showExpandedForm">
                    <VCol cols="12" v-if="durationMismatch">
                      <VAlert text type="warning">
                      <span
                        v-t="{path: 'forms.timeslip.durationMismatch', args: {expectedDuration: expectedDurationText}}"
                      />
                      </VAlert>
                    </VCol>
                  </VExpandTransition>
                  <VExpandTransition v-show="showExpandedForm">
                    <VCol cols="12" v-if="stopsNextDay">
                      <VAlert text type="info">
                        <span v-t="{path: 'forms.timeslip.stopsNextDay', args: {date: form.date}}"/>
                      </VAlert>
                    </VCol>
                  </VExpandTransition>
                </VRow>
              </VCol>
              <VCol class="d-flex flex-column flex-lg-row-reverse align-center" cols="auto">
                <VTooltip :disabled="forceExpand" bottom>
                  <template #activator="{on, attrs}">
                    <div class="d-flex flex-column align-center" v-bind="attrs" v-on="on">
                      <VBtn icon @click="onFormExpandToggle">
                        <FaI :icon="showExpandedForm ? 'clock' : ['far', 'clock']" class="grey--text" size="2x"/>
                      </VBtn>
                      <VSwitch
                        :disabled="forceExpand || $apollo.queries.formExpand.loading"
                        :input-value="forceExpand || formExpand"
                        @change="onFormExpandToggle"
                        class="my-0 py-0"
                        hide-details
                      />
                    </div>
                  </template>
                  <span v-t="`forms.timeslip.times${formExpand ? 'Off' : 'On'}Tooltip`"/>
                </VTooltip>
                <VTooltip bottom v-if="showExpandedForm">
                  <template #activator="{on, attrs}">
                    <VBtn
                      :height="$vuetify.breakpoint.lgAndUp ? '100%' : undefined"
                      :input-value="linkTimeChanges"
                      @click="linkTimeChanges = !linkTimeChanges"
                      class="flex-grow-1 mt-2 mt-lg-0 mr-lg-2"
                      color="grey"
                      outlined
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <FaI :icon="linkTimeChanges ? 'link' : 'unlink'" size="lg"/>
                    </VBtn>
                  </template>
                  <span v-t="`forms.timeslip.link${linkTimeChanges ? 'Off' : 'On'}Tooltip`"/>
                </VTooltip>
              </VCol>
            </VRow>
          </VCard>
        </VCol>
      </VRow>
      <VRow>
        <VCol class="py-0" cols="12" md="4">
          <KApiSelect
            :validator="$v.form.timeslipTypeId"
            hint="forms.general.required"
            model="timeslipType"
            v-model="form.timeslipTypeId"
          />
        </VCol>

        <VCol class="py-0" cols="12" md="8">
          <KTextarea
            :validator="$v.form.description"
            auto-grow
            hint="forms.general.required"
            label="models.timeslip.description"
            rows="1"
            v-model="form.description"
          />
        </VCol>
      </VRow>

      <VRow>
        <VCol class="py-0" cols="12">
          <FormPartitions
            :partitions="partitionsInitial"
            context="timeslip"
            :bus="bus"
            :total="form.duration"
            :date="form.date"
            :user-id="billingRateUser"
            :is-update="isUpdate"
            :is-sales="isSales"
            :is-time-off="isTimeOff"
            @update="form.timeslipPartitions = $event"
            @validator="partitionsValidator = $event"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="auto">
          <KBtnSubmit :mode="mode"/>
        </VCol>
        <VCol cols="auto">
          <VBtn @click="$emit('cancel')" v-if="isUpdate">
            <span v-t="'actions.cancel'"/>
          </VBtn>
        </VCol>
      </VRow>
    </VContainer>
  </form>
</template>

<script>
import Queries from 'queries/index.js'
import timeslipActionsMixins from "./timeslipActionsMixins.js";
import FormPartitions from '../../partitionsSet/elements/FormPartitions.vue';
import {
  FormMixin,
  KApiSelect,
  KBtnSubmit,
  KDatePicker,
  KDurationPicker,
  KTextarea,
  KTimePicker,
  KTimeZonePicker,
} from "forms/elements/index.js"
import {
  betweenLength,
  integer,
  min,
  max,
  primaryKey,
  required,
  requiredIf,
  betweenDate,
  validatorIsValid,
} from 'validators/index.js'
import {
  addDays,
  addSeconds,
  differenceInCalendarDays,
  differenceInSeconds,
  min as minDates,
  parseISO,
  subDays,
} from 'date-fns'
import {utcToZonedTime} from "date-fns-tz";
import {format, formatISODate} from "top/date-fns-format.js";
import {mapGetters, mapState} from "vuex";
import store from 'store/index.js'
import getTimestampFromSeconds from "top/getTimestampFromSeconds.js";
import Vue from "vue";
import mitt from 'mitt';

const baseFormDefaults = {
  date: formatISODate(new Date()),
  duration: null,
  description: '',
  startAt: undefined,
  startAtTimeZone: store.state.session.timeZone,
  stopAt: undefined,
  stopAtTimeZone: store.state.session.timeZone,
  timeslipTypeId: '',
};

const timeslipPartitionDefaults = {
  weight: 1,
  comment: null,
  isBillable: false,
  billingRate: null,
  projectId: '',
  randomId: 'notsorandom',
};

const getFormDefaults = () => Object.assign(
  {},
  baseFormDefaults,
  {timeslipPartitions: [Object.assign({}, timeslipPartitionDefaults)]},
);

const timeslipPartitionVariables = ({id, weight, comment, isBillable, billingRate, projectId}) => ({
  weight,
  comment: comment || null,
  isBillable,
  billingRate,
  project: {connect: projectId},
  ...id && {id},
});

const timeslipPartitionToForm = ({id, weight, comment, isBillable, billingRate, project}) => {
  const payload = {};

  Vue.set(payload, 'weight', weight);
  Vue.set(payload, 'comment', comment || null);
  Vue.set(payload, 'isBillable', Boolean(isBillable));
  Vue.set(payload, 'billingRate', billingRate);
  Vue.set(payload, 'projectId', project?.id);
  Vue.set(payload, 'randomId', id);

  return payload;
};

const parseUpdatePartitions = (existing, form) => {
  const partitionsCreate = [];
  const partitionsUpdate = [];
  const partitionsDelete = [];

  for (const partition of form) {
    const match = existing.find((e) => partition.project.connect === e.project.id);
    if (match) {
      partitionsUpdate.push({id: match.id, ...partition});

    } else {
      partitionsCreate.push(partition);
    }
  }

  for (const partition of existing) {
    const match = form.findIndex((e) => e.project.connect === partition.project.id);
    if (match === -1) {
      partitionsDelete.push(partition.id);
    }
  }

  return {
    ...partitionsCreate && {create: partitionsCreate},
    ...partitionsUpdate && {update: partitionsUpdate},
    ...partitionsDelete && {delete: partitionsDelete},
  };
};

export default {
  mixins: [
    FormMixin,
    timeslipActionsMixins,
  ],

  components: {
    KBtnSubmit,
    KApiSelect,
    KTextarea,
    KTimePicker,
    KTimeZonePicker,
    KDatePicker,
    KDurationPicker,
    FormPartitions,
  },

  data: () => ({
    form: getFormDefaults(),
    defaults: getFormDefaults(),
    linkTimeChanges: true,
    partitionsValidator: null,
    bus: mitt(),
  }),

  props: {
    userId: [Number, String],
  },

  apollo: {
    timeslip: {
      query: Queries.Timeslip.Write.FormFill,
      variables() {
        return {
          id: this.target,
        };
      },
      skip() {
        return !this.isUpdate;
      },
    },

    formExpand: {
      query: Queries.User.Single.TimeslipsFormExpand,
      variables() {
        return {
          id: this.meId,
        };
      },
      update: ({user}) => user?.settings?.timeslipsFormExpand,
    },
  },

  validations() {
    return {
      form: {
        date: {
          required,
          betweenDate: betweenDate(this.dateMin, this.dateMax),
        },
        duration: {
          required,
          integer,
          min: min(1),
          max: max(43200), // seconds, 12 hours
        },
        description: {
          required,
          betweenLength: betweenLength(2, 1000),
        },
        timeslipTypeId: {
          primaryKey,
          required,
        },
        startAt: {
          requiredIf: requiredIf(function (nestedModel) {
            return this.showExpandedForm && nestedModel.stopAt;
          }),
        },
        stopAt: {
          requiredIf: requiredIf(function (nestedModel) {
            return this.showExpandedForm && nestedModel.startAt;
          }),
        },
      },
      partitionsValidator: {
        validatorIsValid,
      },
    };
  },

  computed: {
    ...mapState('session', ['timeZone']),

    ...mapGetters('session', ['meId', 'today']),

    partitionsInitial() {
      return this.timeslip ? this.timeslip.timeslipPartitions.map(timeslipPartitionToForm) : [{...timeslipPartitionDefaults}];
    },

    billingRateUser() {
      const {isUpdate, timeslip, meId} = this;
      return isUpdate ? timeslip.user.id : meId;
    },

    dateObject() {
      const {form: {date}} = this;
      return date ? parseISO(date) : undefined;
    },

    expectedDuration() {
      const {form: {startAt, stopAt}} = this;
      return startAt && stopAt ? differenceInSeconds(stopAt, startAt) : null;
    },

    expectedDurationText() {
      return getTimestampFromSeconds(this.expectedDuration);
    },

    durationMismatch() {
      const {showExpandedForm, expectedDuration, form: {duration}} = this;
      return showExpandedForm && duration && expectedDuration ? duration !== expectedDuration : false;
    },

    stopsNextDay() {
      const {form: {startAt, startAtTimeZone, stopAt, stopAtTimeZone}} = this;

      return startAt && stopAt && differenceInCalendarDays(utcToZonedTime(stopAt, stopAtTimeZone), utcToZonedTime(startAt, startAtTimeZone)) !== 0;
    },

    forceExpand() {
      const {isUpdate, timeslip} = this;
      return Boolean(isUpdate && timeslip?.startAt);
    },

    showExpandedForm() {
      const {formExpand, forceExpand} = this;
      return formExpand || forceExpand;
    },

    dateMin() {
      const {today, timeslip} = this;
      const reference = subDays(parseISO(today), 60);
      let date = undefined;

      if (timeslip) {
        date = minDates([reference, parseISO(timeslip.date)]);
      } else {
        date = reference;
      }

      return formatISODate(date);
    },

    dateMax() {
      return formatISODate(addDays(parseISO(this.today), 60));
    },

    isSales() {
      return this.form.timeslipTypeId === '6';
    },

    isTimeOff() {
      return ['5', '12'].includes(this.form.timeslipTypeId);
    },
  },

  watch: {
    timeslip(v) {
      if (!v) {
        return;
      }

      const {form} = this;

      form.date = v.date;
      form.description = v.description;
      if (v.startAt) {
        form.startAt = this.$set(form, 'startAt', parseISO(v.startAt));
        form.startAtTimeZone = v.startAtTimeZone;
      }
      if (v.stopAt) {
        form.stopAt = parseISO(v.stopAt);
        form.stopAtTimeZone = v.stopAtTimeZone;
      }
      form.duration = v.duration;
      form.timeslipTypeId = v.timeslipType.id;

      form.timeslipPartitions = [];
      for (const partition of v.timeslipPartitions) {
        const partitionForm = Object.assign({}, timeslipPartitionDefaults);
        partitionForm.projectId = partition.project.id;
        partitionForm.weight = partition.weight;
        partitionForm.isBillable = Boolean(partition.isBillable);
        partitionForm.billingRate = partition.billingRate;
        partitionForm.comment = partition.comment || null;
        partitionForm.randomId = this.getRandomId();
        form.timeslipPartitions.push(partitionForm);
      }

      for (const e of Object.values(this.partitionsValidator.$each.$iter)) {
        e.projectId.$touch();
      }
    },

    expectedDuration(v) {
      if (this.linkTimeChanges) {
        this.setDuration();
      }
    },

    linkTimeChanges(v) {
      const {form} = this;
      const {startAt, stopAt, duration} = form;
      if (v) {
        if (startAt && stopAt) {
          this.setDuration();
        } else if (startAt && duration) {
          form.stopAt = addSeconds(new Date(startAt.getTime()), duration);
        } else if (stopAt && duration) {
          form.startAt = addSeconds(new Date(stopAt.getTime()), -duration);
        }
      }
    },

    'form.date'(v) {
      this.$emit('date-update', v);
    },

    '$v.$dirty'(v) {
      if (v) {
        this.partitionsValidator.$touch();
      }
    },
  },

  methods: {
    setDuration() {
      const {form, expectedDuration} = this;

      if (expectedDuration) {
        form.duration = expectedDuration ?? null;
      }
    },

    onFormExpandToggle(v) {
      if (this.$apollo.queries.formExpand.loading || this.forceExpand) {
        return;
      }

      const {meId: id} = this;
      this.$apollo.mutate({
        mutation: Queries.User.Settings.TimeslipsFormExpand,
        variables: {
          id,
          timeslipsFormExpand: Boolean(v),
        },
        optimisticResponse: {
          userUpdate: {
            __typename: "User",
            id,
            settings: {
              __typename: "UserSettings",
              timeslipsFormExpand: Boolean(v),
            },
          },
        },
      });
    },

    async createAction() {
      const {
        form: {date, duration, startAt, startAtTimeZone, stopAt, stopAtTimeZone, description, timeslipTypeId, timeslipPartitions},
        setDefaults,
        timeslipCreate,
        userId,
        showExpandedForm,
      } = this;
      const variables = {
        date,
        duration,
        timeslipTypeId,
        description: description ?? null,
        timeslipPartitions: timeslipPartitions.map(timeslipPartitionVariables),
        ...userId && {userId},
        ...showExpandedForm && startAt && {startAt: format(startAt, 'yyyy-MM-dd\'T\'HH:mm:ssxxx'), startAtTimeZone},
        ...showExpandedForm && stopAt && {stopAt: format(stopAt, 'yyyy-MM-dd\'T\'HH:mm:ssxxx'), stopAtTimeZone},
      };

      const response = await timeslipCreate(variables);

      setDefaults(response);

      return response;
    },

    async updateAction() {
      const {
        form: {date, duration, startAt, startAtTimeZone, stopAt, stopAtTimeZone, description, projectId, timeslipTypeId, timeslipPartitions},
        target: id,
        timeslipUpdate,
        setDefaults,
        showExpandedForm,
        timeslip: {timeslipPartitions: partitionsCurrent}
      } = this;

      const partitionsOutput = parseUpdatePartitions(partitionsCurrent, timeslipPartitions.map(timeslipPartitionVariables));

      const variables = {
        id,
        date,
        duration,
        projectId,
        timeslipTypeId,
        description: description ?? null,
        startAt: showExpandedForm && startAt ? format(startAt, 'yyyy-MM-dd\'T\'HH:mm:ssxxx') : null,
        startAtTimeZone: showExpandedForm && startAt ? startAtTimeZone : null,
        stopAt: showExpandedForm && stopAt ? format(stopAt, 'yyyy-MM-dd\'T\'HH:mm:ssxxx') : null,
        stopAtTimeZone: showExpandedForm && stopAt ? stopAtTimeZone : null,

        ...partitionsOutput.create && {
          timeslipPartitionsCreate: partitionsOutput.create,
        },
        ...partitionsOutput.update && {
          timeslipPartitionsUpdate: partitionsOutput.update,
        },
        ...partitionsOutput.delete && {
          timeslipPartitionsDelete: partitionsOutput.delete
        },
      };
      const response = await timeslipUpdate(variables);

      setDefaults(response);

      return response;
    },

    setDefaults(response) {
      const {defaults} = this;

      defaults.date = response.date;
      defaults.timeslipTypeId = response.timeslipType.id;
      defaults.timeslipPartitions = [{...timeslipPartitionDefaults}];

      if (response.stopAt) {
        defaults.startAt = parseISO(response.stopAt);
        defaults.startAtTimeZone = response.stopAtTimeZone;
        defaults.stopAtTimeZone = response.stopAtTimeZone;
      } else {
        defaults.startAt = undefined;
        defaults.startAtTimeZone = store.state.session.timeZone;
        defaults.stopAtTimeZone = store.state.session.timeZone;
      }
    },

    formReset() {
      const {form, defaults} = this;
      Object.entries(defaults).forEach(([key, value]) => {
        form[key] = value;
      });
      form.timeslipPartitions = defaults.timeslipPartitions.map((row) => ({...row}));
      this.bus.emit('reset');
    },
  },

  created() {
    this.$on('success', this.formReset);
  },
}
</script>
