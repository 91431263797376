<template>
  <VCard tile color="blue lighten-5">
    <VCardTitle v-t="titleMsg" class="subtitle-2 grey--text text--darken-3"/>
    <VCardText>
      <VRow>
        <VCol class="py-0" cols="12" lg="6" xl="8">
          <KTextField
            :validator="$v.form.description"
            hint="forms.general.recommended"
            label="models.timeslip.description"
            v-model="form.description"
            autocomplete="off"
            @keypress.enter="doSubmit"
          />
        </VCol>
        <VCol class="py-0" cols="12" sm="6" lg="3" xl="2">
          <KApiSelect
            :validator="$v.form.timeslipTypeId"
            hint="forms.general.required"
            model="timeslipType"
            v-model="form.timeslipTypeId"
          />
        </VCol>
        <VCol class="py-0" cols="12" sm="6" lg="3" xl="2">
          <KTimePicker
            :disabled="!isUpdate"
            :pivot="startAtMin"
            :time-zone.sync="form.startAtTimeZone"
            :validator="$v.form.startAt"
            :min="startAtMin"
            :max="startAtMax"
            get-date-object
            hint="forms.timeslip.startAt_helpblock"
            label="models.timeslip.startAt"
            v-model="form.startAt"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol class="py-0" cols="12">
          <FormPartitions
            :partitions="partitionsInitial"
            context="timeslip"
            :bus="bus"
            :date="today"
            :user-id="meId"
            :is-update="isUpdate"
            :is-sales="isSales"
            :is-time-off="isTimeOff"
            @update="form.timeslipPartitions = $event"
            @validator="partitionsValidator = $event"
          />
        </VCol>
      </VRow>
      <VRow align="center">
        <VCol cols="12" sm>
          <VProgressLinear
            :value="updateCounter/100"
            color="info"
            height="4"
            query
            rounded
          />
        </VCol>
        <VCol class="ml-auto" cols="12" sm="auto">
          <VBtn
            :disabled="mode === $options.FormModes.RUNNING"
            @click="formReset"
            color="grey"
            v-show="isUpdate && hasChanges"
          >
            <FaI icon="rotate-left" class="mr-2"/>
            <span v-t="'actions.undo'"/>
          </VBtn>
          <ClickConfirm
            :disabled="mode === $options.FormModes.RUNNING"
            :loading="isDeleting"
            :message="'actions.cancel'"
            icon="circle-xmark"
            @confirm="deleteAction"
            color="orange lighten-1"
            v-if="isUpdate && !hasChanges"
          />
          <VBtn
            @click="doSubmit"
            v-show="isUpdate && hasChanges"
            :loading="mode === $options.FormModes.RUNNING"
            color="amber"
            class="ml-5"
          >
            <FaI icon="pen-to-square" class="mr-2"/>
            <span v-t="'forms.general.update'"/>
          </VBtn>
          <VBtn @click="doSubmit" color="primary" v-show="!isUpdate" :loading="mode === $options.FormModes.RUNNING">
            <FaI icon="timer" class="mr-2"/>
            <span v-t="'views.timekeeper.start'"/>
          </VBtn>
          <VBtn
            :loading="isStopping"
            @click="stopAction"
            color="red lighten-1"
            class="ml-5"
            :dark="!(isDeleting || forceCancel)"
            v-show="isUpdate && !hasChanges"
            :disabled="isDeleting || forceCancel"
          >
            <FaI icon="flag-checkered" class="mr-2"/>
            <span v-t="'views.timekeeper.finish'"/>
          </VBtn>
          <VBtn color="red lighten-1" class="ml-2" outlined :loading="isStopping" @click.stop="onShowStopAt" v-show="isUpdate && !hasChanges" :disabled="isDeleting || forceCancel">
            <FaI icon="clock" size="lg"/>
          </VBtn>
          <VDialog max-width="400" v-model="showStopAtPicker" @click:outside="cancelStopAt">
            <VCard>
              <VCardTitle v-t="'views.timekeeper.stopAtTitle'"/>
              <VCardText>
                <KTimePickerInline
                  :pivot="startAtMin"
                  :validator="$v.form.stopAt"
                  :min="stopAtMin"
                  :max="now"
                  get-date-object
                  label="models.timeslip.stopAt"
                  :value="form.stopAt"
                  :time-zone.sync="stopAtTimeZonePicker"
                  @input="setStopAt"
                />
              </VCardText>
              <VCardActions>
                <VBtn @click="cancelStopAt">
                  <FaI icon="arrow-left" class="mr-2"/>
                  <span v-t="'actions.back'"/>
                </VBtn>
                <VBtn @click="stopAction" class="ml-auto" color="red lighten-1" dark>
                  <FaI icon="flag-checkered" class="mr-2"/>
                  <span v-t="'views.timekeeper.finish'"/>
                </VBtn>
              </VCardActions>
            </VCard>
          </VDialog>
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>

<script>
  import Queries from "queries/index.js";
  import timeslipActionsMixins from "top/views/timeslips/elements/timeslipActionsMixins.js";
  import FormModes from "forms/elements/FormModes.js";
  import ClickConfirm from "elements/ClickConfirm.vue";
  import md5 from 'crypto-js/md5';
  import Base64 from 'crypto-js/enc-base64'
  import Vue from 'vue';
  import {mapGetters, mapState} from "vuex";
  import {
    FormMixin,
    KApiSelect,
    KBillingRate,
    KBtnSubmit,
    KCheckbox,
    KSuggest,
    KTextField,
    KTimePicker,
    ProjectPicker
  } from "forms/elements/index.js";
  import FormPartitions from '../partitionsSet/elements/FormPartitions.vue';
  import KTimePickerInline from "forms/elements/KTimePickerInline.vue";
  import {
    betweenDatetime,
    betweenLength,
    primaryKey,
    required,
    requiredIf,
    validatorIsValid,
  } from "validators/index.js";
  import {debounce} from "lodash-es";
  import {addSeconds, parseISO, subHours, addMinutes, differenceInMinutes, startOfMinute, endOfMinute} from "date-fns";
  import {format} from "top/date-fns-format.js";
  import mitt from 'mitt';

  const baseFormDefaults =  {
      description: '',
      timeslipTypeId: '',
      startAt: undefined,
      startAtTimeZone: undefined,
      stopAt: undefined,
      stopAtTimeZone: undefined,
    };

  const timeslipPartitionDefaults = {
    weight: 1,
    comment: null,
    isBillable: false,
    billingRate: null,
    projectId: '',
    randomId: 'notsorandom',
  };

  const getFormDefaults = () => Object.assign(
    {},
    baseFormDefaults,
    {timeslipPartitions: [Object.assign({}, timeslipPartitionDefaults)]},
  );

  const UPDATE_DELAY = 10000;
  const TIMER_INTERVAL = 1000;
  const STOP_AT_HOLD = 60000;

  const formStringify = ({timeslipTypeId, startAt, startAtTimeZone, description, timeslipPartitions}) =>
    `${timeslipTypeId}:${startAt?.getHours()}${startAt?.getMinutes()}:${startAtTimeZone}:${description};${timeslipPartitions.map(partitionStringify).join(';')}`;

  const partitionStringify = ({projectId, isBillable, billingRate, comment, weight}) =>
    `${projectId}:${weight}:${isBillable ? 1 : 0}:${isBillable ? billingRate : ''}:${comment}`;

  const timeslipPartitionVariables = ({id, weight, comment, isBillable, billingRate, projectId}) => ({
    weight,
    comment: comment || null,
    isBillable,
    billingRate,
    project: {connect: projectId},
    ...id && {id},
  });

  const timeslipPartitionToForm = ({id, weight, comment, isBillable, billingRate, project}) => {
    const payload = {};

    Vue.set(payload, 'weight', weight);
    Vue.set(payload, 'comment', comment || null);
    Vue.set(payload, 'isBillable', Boolean(isBillable));
    Vue.set(payload, 'billingRate', billingRate);
    Vue.set(payload, 'projectId', project?.id);
    Vue.set(payload, 'randomId', id);

    return payload;
  };

  const parseUpdatePartitions = (existing, form) => {
    const partitionsCreate = [];
    const partitionsUpdate = [];
    const partitionsDelete = [];

    for (const partition of form) {
      const match = existing.find((e) => partition.project.connect === e.project.id);
      if (match) {
        partitionsUpdate.push({id: match.id, ...partition});

      } else {
        partitionsCreate.push(partition);
      }
    }

    for (const partition of existing) {
      const match = form.findIndex((e) => e.project.connect === partition.project.id);
      if (match === -1) {
        partitionsDelete.push(partition.id);
      }
    }

    return {
      ...partitionsCreate && {create: partitionsCreate},
      ...partitionsUpdate && {update: partitionsUpdate},
      ...partitionsDelete && {delete: partitionsDelete},
    };
  };

  export default {
    FormModes,

    mixins: [
      FormMixin,
      timeslipActionsMixins,
    ],

    components: {
      ClickConfirm,
      FormPartitions,
      KBillingRate,
      KBtnSubmit,
      KCheckbox,
      KApiSelect,
      KSuggest,
      KTextField,
      KTimePicker,
      KTimePickerInline,
      ProjectPicker,
    },

    data: () => ({
      isDeleting: false,
      isStopping: false,
      form: getFormDefaults(),
      sourceHash: null,
      formHash: null,
      updateCounter: 0,
      showStopAtPicker: false,
      stopAtIsSet: false,
      partitionsValidator: null,
      bus: mitt(),
    }),

    apollo: {
      activeTimeslip: {
        query: Queries.Timeslip.Single.ActiveTimeslip,
        variables() {
          return {userId: this.meId};
        },
        skip() {
          return !this.isSignedIn;
        },
        result({errors}) {
          if (!errors) {
            this.formReset();
          }
        },
      },
    },

    validations() {
      return {
        form: {
          description: {
            required,
            betweenLength: betweenLength(2, 1000),
          },
          timeslipTypeId: {
            primaryKey,
            required,
          },
          startAt: {
            requiredIf: requiredIf(function (nestedModel) {
              return this.isUpdate;
            }),
            betweenDatetime: betweenDatetime(this.startAtMin, this.startAtMax),
          },
          stopAt: {
            betweenDatetime: betweenDatetime(this.stopAtMin, this.now),
          },
        },
        partitionsValidator: {
          validatorIsValid,
        },
      };
    },

    timers: {
      updateCountdown: {time: TIMER_INTERVAL},

      stopAtReset: {time: STOP_AT_HOLD},
    },

    computed: {
      ...mapState('session', ['now', 'timeZone']),

      ...mapGetters('session', ['isSignedIn', 'meId', 'today']),

      isUpdate() {
        return Boolean(this.activeTimeslip);
      },

      stopAtTimeZonePicker: {
        get() {
          return this.form.stopAtTimeZone ?? this.timeZone;
        },
        set(v) {
          this.form.stopAtTimeZone = v;
          this.$timer.stop('stopAtReset');
          this.$timer.start('stopAtReset');
        },
      },

      submitMsg() {
        return this.isUpdate ? 'forms.general.update' : 'views.timekeeper.start';
      },

      hasChanges() {
        const {formHash, sourceHash} = this;
        return formHash !== sourceHash;
      },

      allowAutoSubmit() {
        return this.mode === FormModes.IDLE && this.sourceHash !== this.formHash && !this.$v.$invalid;
      },

      titleMsg() {
        return `views.timekeeper.${this.isUpdate ? 'update' : 'create'}Title`;
      },

      startAtMin() {
        return startOfMinute(subHours(this.now, 12));
      },

      startAtMax() {
        return endOfMinute(addSeconds(this.now, 30));
      },

      stopAtMin() {
        return this.form.startAt ? startOfMinute(addMinutes(this.form.startAt, 1)) : undefined;
      },

      forceCancel() {
        return this.isUpdate && differenceInMinutes(this.now, this.form.startAt) < 1;
      },

      disableStopAt() {
        return !this.isUpdate || this.forceCancel;
      },

      multiple() {
        return this.form.timeslipPartitions.length > 1;
      },

      partitionsInitial() {
        return this.activeTimeslip ? this.activeTimeslip.timeslipPartitions.map(timeslipPartitionToForm) : [{...timeslipPartitionDefaults}];
      },

      isSales() {
        return this.form.timeslipTypeId === '6';
      },

      isTimeOff() {
        return ['5', '12'].includes(this.form.timeslipTypeId);
      },
    },

    watch: {
      form: {
        deep: true,
        handler: function (v) {
          this.stopUpdateCountdown();
          this.debouncedFormHash();
        },
      },
    },

    methods: {
      startUpdateCountdown() {
        this.$timer.stop('updateCountdown');
        this.updateCounter = UPDATE_DELAY;
        this.$timer.start('updateCountdown');
      },

      stopUpdateCountdown() {
        this.$timer.stop('updateCountdown');
        this.updateCounter = 0;
      },

      updateCountdown() {
        this.updateCounter -= TIMER_INTERVAL;

        this.$timer.stop('updateCountdown');
        if (this.updateCounter <= 0) {
          this.doSubmit();
        } else {
          this.$timer.start('updateCountdown');
        }
      },

      stopAtReset() {
        this.$timer.stop('stopAtReset');
        this.stopAtIsSet = false;
        if (this.isUpdate) {
          this.form.stopAt = undefined;
          this.form.stopAtTimeZone = this.timeZone;
        }
      },

      setStopAt(value) {
        this.stopAtIsSet = Boolean(value);
        this.form.stopAt = value;
        this.$timer.start('stopAtReset');
      },

      debouncedFormHash: debounce(function () {
        this.formHash = md5(formStringify(this.form)).toString(Base64);
        if (this.allowAutoSubmit) {
          this.startUpdateCountdown();
        }
      }, 500, {leading: true, maxWait: 2000}),

      async createAction() {
        const {form: {description, timeslipTypeId, timeslipPartitions}, timeslipStart} = this;

        return timeslipStart({timeslipTypeId, description, timeslipPartitions: timeslipPartitions.map(timeslipPartitionVariables)});
      },

      async updateAction() {
        const {form: {description, timeslipTypeId, startAt, startAtTimeZone, timeslipPartitions}, activeTimeslip: {id, timeslipPartitions: partitionsCurrent}, timeslipUpdate} = this;

        const partitionsOutput = parseUpdatePartitions(partitionsCurrent, timeslipPartitions.map(timeslipPartitionVariables));

        const variables = {
          id,
          timeslipTypeId,
          startAt: format(startAt, 'yyyy-MM-dd\'T\'HH:mm:ssxxx'),
          startAtTimeZone,
          description: description ? description : null,

          ...partitionsOutput.create && {
            timeslipPartitionsCreate: partitionsOutput.create,
          },
          ...partitionsOutput.update && {
            timeslipPartitionsUpdate: partitionsOutput.update,
          },
          ...partitionsOutput.delete && {
            timeslipPartitionsDelete: partitionsOutput.delete
          },
        };

        return timeslipUpdate(variables);
      },

      async deleteAction() {
        const {timeslipCancel} = this;

        this.isDeleting = true;
        try {
          return await timeslipCancel(true);
        } finally {
          this.isDeleting = false;
        }
      },

      stopAction: async function () {
        const {timeslipStop, form, now, timeZone} = this;

        const stopAt = form.stopAt ? format(form.stopAt, 'yyyy-MM-dd\'T\'HH:mm:ssxxx') : undefined;
        const stopAtTimeZone = form.stopAtTimeZone;
        if (!form.stopAt) {
          form.stopAt = now;
          form.stopAtTimeZone = timeZone;
        }
        this.isStopping = true;
        this.showStopAtPicker = false;
        try {
          return await timeslipStop(stopAt, stopAtTimeZone);
        } finally {
          this.isStopping = false;
        }
      },

      formReset() {
        const {getFormDefaults, form, isUpdate, activeTimeslip: target} = this;
        if (isUpdate) {
          form.description = target.description;
          form.timeslipTypeId = target.timeslipType.id;
          form.timeslipPartitions = [];
          target.timeslipPartitions.forEach((partition) => {
            form.timeslipPartitions.push(timeslipPartitionToForm(partition));
          });
          // form.timeslipPartitions = target.timeslipPartitions.map(timeslipPartitionToForm);
          form.startAt = parseISO(target.startAt);
          form.startAtTimeZone = target.startAtTimeZone;
          form.stopAt = undefined;
          form.stopAtTimeZone = this.timeZone;
        } else {
          const formDefaults = getFormDefaults();
          Object.entries(formDefaults).forEach(([key, value]) => {
            form[key] = value;
          });
        }
        this.sourceHash = md5(formStringify(form)).toString(Base64);

        this.stopAtIsSet = false;
        this.bus.emit('reset');
      },

      getFormDefaults() {
        return {
          ...getFormDefaults(),
          startAtTimeZone: this.timeZone,
        };
      },

      cancelStopAt() {
        this.showStopAtPicker = false;
        this.form.stopAt = undefined;
        this.form.stopAtTimeZone = undefined;
      },

      onShowStopAt() {
        this.form.stopAt = this.now;
        this.form.stopAtTimeZone = this.timeZone;
        this.showStopAtPicker = true;
      },

      doMultipleToggle() {
        const {form: {timeslipPartitions: partitions}} = this;
        if (partitions.length === 1) {
          if (partitions.length < 2) {
            this.addPartition();
          }
        } else {
          partitions.splice(1);
        }
      },

      addPartition() {
        const {form: {timeslipPartitions: partitions}, getRandomId} = this;
        partitions.push(timeslipPartitionToForm(Object.assign({}, timeslipPartitionDefaults, {id: getRandomId()})));
      },

      removePartition(index) {
        const {timeslipPartitions} = this.form;
        if (timeslipPartitions.length > 1) {
          timeslipPartitions.splice(index, 1);
        }
      },
    },

    created() {
      this.$on('reset', this.formReset);
      this.$on('submit', this.stopUpdateCountdown);
    },
  }
</script>
