import FormModes from './FormModes.js'

const SUCCESS_DURATION = 2000;

export default {
  data: () => ({
    mode: FormModes.IDLE,
  }),

  props: {
    target: {
      type: [String, Number],
    }
  },

  computed: {
    isUpdate() {
      return this.target !== undefined;
    },
  },

  timers: {
    successCountdown: {time: SUCCESS_DURATION},
  },

  methods: {
    addItemToSelectList(item, destination, key = 'id') {
      if (item && Array.isArray(destination) && destination.findIndex((e) => e[key] === item[key]) === -1) {
        destination.push(item);
      }
    },

    async doSubmit() {
      if(!this.validationCheck()) {
        return;
      }

      this.$emit('submit');
      await this.submitAction();
    },

    async submitAction() {
      this.mode = FormModes.RUNNING;

      try {
        const response = this.isUpdate ? await this.updateAction() : await this.createAction();
        this.$emit('success', response);
      } catch (error) {
        console.error('Form submission failed', {error});
        this.$emit('error', error);
      }
    },

    async createAction() {
      throw new Error('createAction method is not defined on this form.');
    },

    async updateAction() {
      throw new Error('updateAction method is not defined on this form.');
    },

    validationReset() {
      if (this.$v) {
        this.$v.$reset();
      }
    },

    validationCheck() {
      if (this.$v && this.$v.$invalid) {
        this.$v.$touch();
        this.$store.dispatch('flash/danger', 'forms.general.error');
        return false;
      }

      return true;
    },

    successCountdown() {
      this.$emit('reset');
    },
  },

  created() {
    this.$on('success', () => {
      this.mode = FormModes.SUCCESS;
      this.$timer.stop('successCountdown');
      this.$timer.start('successCountdown');
      this.validationReset();
    });

    this.$on('error', () => {
      this.mode = FormModes.ERROR;
    });

    this.$on('reset', () => {
      this.validationReset();
      this.mode = FormModes.IDLE;
    });
  }
}
