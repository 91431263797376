<template>
  <form @submit.prevent="doSubmit">
    <VStepper v-model="step" vertical class="rounded-t-0">
      <VStepperStep :complete="step > 1" :rules="[() => validators.general && !validators.general.$anyError]" step="1">
        <!--      <VStepperStep :complete="step > 1" step="1">-->
        <span v-t="'forms.project.steps.general'" />
      </VStepperStep>

      <VStepperContent step="1"> <!--Project-->
        <FormProjectGeneral v-model="form.general" @validator="validators.general = $event" />

        <div class="d-flex justify-end mt-2">
          <!--          <VBtn color="primary" @click="step++" :disabled="!validators.general || validators.general.$invalid">-->
          <VBtn :color="!validators.general || validators.general.$invalid ? 'warning' : 'primary'" @click="tryNextStep">
            <span v-t="'actions.next'" />
          </VBtn>
        </div>
      </VStepperContent>

      <VStepperStep :complete="step > 2" :rules="[() => validators.roles && !validators.roles.$anyError]" step="2">
        <!--      <VStepperStep :complete="step > 2" step="2">-->
        <span v-t="'forms.project.steps.roles'" />
      </VStepperStep>

      <VStepperContent step="2"> <!--Roles-->
        <FormProjectRoles v-model="form.roles" :office-id="form.general ? form.general.officeId : undefined" @validator="validators.roles = $event" />

        <div class="d-flex justify-space-between mt-2">
          <VBtn color="primary" @click="step--">
            <span v-t="'actions.back'" />
          </VBtn>
          <!--          <VBtn color="primary" @click="step++" :disabled="$v.roles.$invalid">-->
          <VBtn :color="!validators.roles || validators.roles.$invalid ? 'warning' : 'primary'" @click="tryNextStep">
            <span v-t="'actions.next'" />
          </VBtn>
        </div>
      </VStepperContent>

      <VStepperStep :complete="step > 3" :rules="[() => validators.status && !validators.status.$anyError]" step="3">
        <!--      <VStepperStep :complete="step > 3" step="3">-->
        <span v-t="'forms.project.steps.status'" />
      </VStepperStep>

      <VStepperContent step="3"> <!--Status-->
        <FormProjectStatus v-model="form.status" :project-type-id="form.general ? form.general.projectTypeId : undefined" @validator="validators.status = $event" />

        <div class="d-flex justify-space-between mt-2">
          <VBtn color="primary" @click="step--">
            <span v-t="'actions.back'" />
          </VBtn>
          <!--          <VBtn color="primary" @click="step++" :disabled="$v.status.$invalid">-->
          <VBtn :color="!validators.status || validators.status.$invalid ? 'warning' : 'primary'" @click="tryNextStep">
            <span v-t="'actions.next'" />
          </VBtn>
        </div>
      </VStepperContent>

      <VStepperStep step="4">
        <span v-t="'forms.project.steps.review'" />
      </VStepperStep>

      <VStepperContent step="4"> <!--Review-->
        <FormProjectReview :data="form" />

        <div class="d-flex justify-space-between mt-2">
          <VBtn color="primary" @click="step--">
            <span v-t="'actions.back'" />
          </VBtn>
          <KBtnSubmit :mode="mode" />
        </div>
      </VStepperContent>
    </VStepper>
  </form>
</template>

<script>
import FormMixin from "forms/elements/FormMixin.js";
import FormProjectGeneral from "./forms/FormProjectGeneral.vue";
import FormProjectReview from "./forms/FormProjectReview.vue";
import FormProjectRoles from "./forms/FormProjectRoles.vue";
import FormProjectStatus from "./forms/FormProjectStatus.vue";
import Queries from "queries/index.js";
import {KBtnSubmit} from "forms/elements/index.js";
import {required, validatorIsValid} from "validators/index.js";

const steps = [
  'general',
  'roles',
  'status',
];

export default {
  mixins: [
    FormMixin,
  ],

  components: {
    KBtnSubmit,
    FormProjectGeneral,
    FormProjectReview,
    FormProjectRoles,
    FormProjectStatus,
  },

  data() {
    return {
      form: {
        general: null,
        roles: null,
        status: null,
      },
      validators: {
        general: null,
        roles: null,
        status: null,
      },
      step: 1,
    };
  },

  validations: {
    validators: {
      general: {
        required,
        validatorIsValid,
      },
      roles: {
        required,
        validatorIsValid,
      },
      status: {
        required,
        validatorIsValid,
      },
    },
  },

  methods: {
    tryNextStep() {
      const stepName = steps[this.step - 1];
      const validator = this.validators[stepName];
      if (validator.$invalid) {
        validator.$touch();
        this.$store.dispatch('flash/warning', 'forms.project.step_error');
      } else {
        this.step++;
      }
    },

    async createAction() {
      const {form: {general, status, roles: {roleId, supervisorId}}} = this;
      const teamMembers = [{
        user: this.$store.getters['session/meId'],
        role: roleId,
      }];
      if (supervisorId) {
        teamMembers.push({
          user: supervisorId,
          role: 10,
        });
      }
      const {data: { projectCreate: response}} = await this.$apollo.mutate({
        mutation: Queries.Project.Write.Create,
        variables: {
          ...general,
          ...status,
          teamMembers,
        },
      });
      return response;
    },
  },
}
</script>
