<template>
  <VRow no-gutters align="baseline">
    <VCol v-if="!column" cols="5">
      {{title}}
    </VCol>
    <VCol :cols="fieldCols">
      <VTextField
        dense
        outlined
        :hint="units"
        :max="max"
        :min="min"
        :step="step"
        type="number"
        v-model="localValue"
        style="width: 100%"
      >
        <template v-slot:append>
          <span class="grey--text">
            {{units}}
          </span>
        </template>
      </VTextField>
    </VCol>
    <VCol v-if="!(column && !timerAllow)" :cols="timerCols" class="pl-2">
      <TimerButton
        :interval="timerInterval"
        :precision="timerPrecision"
        :running.sync="timerRunning"
        v-if="timerAllow"
        v-model="localValue"
      />
    </VCol>
  </VRow>
</template>

<script>
  import TimerButton from './TimerButton.vue'
  import {FIELDS} from './perfConsts.js'

  export default {
    components: {
      TimerButton,
    },

    data: () => ({
      timerRunning: false,
      localValue: '',
    }),

    props: {
      device: {
        type: [String, Number],
        required: true
      },

      field: {
        type: String,
        required: true
      },

      value: {
        type: [String, Number],
        required: true,
      },

      column: Boolean,

      settings: {
        type: Object,
        default: () => ({}),
      },
    },

    inject: ['inspectionMeta', 'performanceStatuses'],

    computed: {
      fieldProperties() {
        return FIELDS[this.field] || {};
      },

      min() {
        return this.fieldProperties.min || 0;
      },

      max() {
        return this.fieldProperties.max || 99;
      },

      isInteger() {
        return this.fieldProperties.type === 'i'
      },

      step() {
        return this.isInteger ? 1 : 0.1;
      },

      timerPrecision() {
        return this.isInteger ? 0 : 1;
      },

      timerInterval() {
        return this.isInteger ? 1000 : 100;
      },

      timerAllow() {
        return this.fieldProperties.timer === true;
      },

      units() {
        return this.$t(`forms.inspectionPerformance.${this.field}.units${this.fieldProperties?.hasImperial ? ('.' + this.unitsType) : ''}`);
      },

      title() {
        return this.$t(`forms.inspectionPerformance.${this.field}.title`);
      },

      fieldCols() {
        return !this.column ? 5 : (this.timerAllow ? false : 12);
      },

      timerCols() {
        return this.column ? 'auto' : 2;
      },

      unitsType() {
        return this.settings?.units ?? 'i';
      },
    },

    watch: {
      value: {
        handler(v) {
          this.localValue = v;
        },
        immediate: true,
      },

      localValue(v) {
        if (!this.timerRunning) {
          this.$emit('input', v);
        }
      },

      timerRunning(v) {
        if (!v) {
          this.$emit('input', this.localValue);
        }
      },
    },
  }
</script>
