<template>
  <ExpandPanelBrowser hide-toolbar query="ProjectRecord.Browse.List">
    <template v-slot:filter="{setVariables}">
      <ProjectRecordsFilter
        :defaults="filterDefaults"
        :disables="filterDisables"
        :order-desc-default="orderDescDefault"
        :show-filter="showFilter"
        :sort-default="sortDefault"
        @filtered="setIsFiltered"
        @input="setVariables"
      />
    </template>
    <template v-slot:default="{data, isLoading, onCreate, onUpdate, onDelete}">
      <slot
        :data="data"
        :is-loading="isLoading"
        :on-create="onCreate"
        :on-update="onUpdate"
        :on-delete="onDelete"
      />
    </template>
    <template v-slot:item="{item: {id}, onUpdate, onDelete}">
      <ProjectRecordItem :id="id" :key="id" @update="onUpdate" @delete="onDelete"/>
    </template>
  </ExpandPanelBrowser>
</template>

<script>
  import ExpandPanelBrowser from "elements/list/ExpandPanelBrowser.vue";
  import ProjectRecordItem from "./ProjectRecordItem.vue";
  import ProjectRecordsFilter from "./ProjectRecordsFilter.vue";

  export default {
    components: {
      ProjectRecordsFilter,
      ProjectRecordItem,
      ExpandPanelBrowser,
    },

    props: {
      filterDisables: Object,

      filterDefaults: Object,

      sortDefault: {
        type: String,
        default: 'CREATED_AT',
      },

      orderDescDefault: {
        type: Boolean,
        default: true,
      },

      showFilter: Boolean,
    },

    methods: {
      setIsFiltered(v) {
        this.$emit('filtered', v);
      },
    },
  }
</script>
