<template>
  <ListBrowserFilterWrapper
    export-mutation="Project.Browse.Export"
    :export-options="$options.exportOptions"
    :form.sync="form"
    :form-default="parsedDefaults"
    :order-options="parsedOrderOptions"
    :show-filter="showFilter"
    :form-function="$options.formFunction"
    :show-search="!hideSearch"
    @input="onInput"
    @filtered="onFiltered"
  >
    <VRow>
      <VCol class="py-0" cols="12" md="8">
        <KSuggest
          :disabled="disables.userId"
          clearable
          model="user"
          label="models.team.teamMember"
          v-model="form.userId"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="4">
        <KApiSelect
          clearable
          model="role"
          :disabled="!form.userId || disables.roleId"
          :label="{path: 'models.role.self', choice: 1}"
          v-model="form.roleId"
          item-text="displayName"
          :variables="{context: 'PROJECTS'}"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="6" lg="3">
        <KApiSelect
          clearable
          model="projectType"
          :disabled="disables.projectTypeId"
          :label="{path: 'models.projectType.shorthand', choice: 1}"
          v-model="form.projectTypeId"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="6" lg="3">
        <KApiSelect
          clearable
          model="projectStatus"
          :disabled="disables.projectStatusId"
          :label="{path: 'models.projectStatus.shorthand', choice: 1}"
          v-model="form.projectStatusId"
        />
      </VCol>
      <VCol class="py-0" cols="12" md="6" lg="3">
        <KApiSelect
          clearable
          model="office"
          :disabled="disables.officeId"
          v-model="form.officeId"
        />
      </VCol>
      <VCol
        class="py-0"
        cols="12"
        md="6"
        lg="3"
      >
        <KDatePicker
          :disabled="disables.recordDueBy"
          label="views.projectRecords.recordDueBy"
          clearable
          v-model="form.recordDueBy"
          :min="dateMin"
          :max="dateMax"
        />
      </VCol>
    </VRow>
  </ListBrowserFilterWrapper>
</template>

<script>
  import ListBrowserFilterWrapper from "elements/list/ListBrowserFilterWrapper.vue";
  import {KApiSelect, KSuggest, KDatePicker} from "forms/elements/index.js";
  import {formatISODate} from "@/date-fns-format";
  import {addYears, parseISO} from "date-fns";
  import {mapGetters} from "vuex";

  const filterDefaults = {
    officeId: undefined,
    projectTypeId: undefined,
    projectStatusId: undefined,
    userId: undefined,
    roleId: undefined,
    recordDueBy: undefined,
  };
  const orderOptions = [
    {
      text: 'models.general.id',
      value: 'ID',
      secondary: true,
    },
    {
      text: 'models.project.name',
      value: 'NAME',
    },
    {
      text: 'models.general.createdAt',
      value: 'CREATED_AT',
    },
    {
      text: 'models.general.updatedAt',
      value: 'UPDATED_AT',
    },
    {
      text: 'models.project.recordDueAt',
      value: 'RECORD_DUE_AT',
    },
  ];

  const formFunction = ({officeId, projectTypeId, projectStatusId, userId, roleId, recordDueBy}) => {
    return {
      ...officeId && {officeId},
      ...projectTypeId && {projectTypeId},
      ...projectStatusId && {projectStatusId},
      ...recordDueBy && {recordDueBy},
      ...userId && {teamMember: {user: userId, role: roleId}},
    };
  };

  const exportOptions = [
    {
      name: 'includeInvoicingActivity',
      text: 'views.projects.export.includeInvoicingActivity',
    },
  ];

  export default {
    formFunction,

    exportOptions,

    components: {
      ListBrowserFilterWrapper,
      KApiSelect,
      KSuggest,
      KDatePicker,
    },

    data() {
      return {
        form: Object.assign({}, filterDefaults, this.defaults),
      };
    },

    props: {
      showFilter: Boolean,

      defaults: {
        type: Object,
        default: () => ({}),
      },

      disables: {
        type: Object,
        default: () => ({}),
      },

      sortDefault: {
        type: String,
        default: 'ID',
      },

      orderDescDefault: {
        type: Boolean,
        default: true,
      },

      hideSearch: Boolean,
    },

    computed: {
      ...mapGetters('session', ['today']),

      parsedDefaults() {
        const payload = {};

        for (const key in filterDefaults) {
          payload[key] = this.defaults.hasOwnProperty(key) ? this.defaults[key] : filterDefaults[key];
        }

        return payload;
      },

      parsedOrderOptions() {
        const {sortDefault, orderDescDefault} = this;
        const payload = [];

        orderOptions.forEach((item) => {
          payload.push(item.value === sortDefault
              ? Object.assign({}, item, {default: true, defaultDesc: orderDescDefault})
              : item
          );
        });

        return payload;
      },

      dateMin() {
        return formatISODate(addYears(parseISO(this.today), -1));
      },

      dateMax() {
        return formatISODate(addYears(parseISO(this.today), 1));
      },
    },

    watch: {
      'form.userId'(v) {
        if (!v) {
          this.form.roleId = undefined;
        }
      },
    },

    methods: {
      onInput(v) {
        this.$emit('input', v);
      },

      onFiltered(v) {
        this.$emit('filtered', v);
      }
    },
  }
</script>
