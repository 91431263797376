import Vue from 'vue';
import VueRouter from 'vue-router';
import Top from './views/Top.vue';
import ProjectRecords from './views/projectRecords/Main.vue';
import Expenses from './views/expenses/Main.vue';
import Timeslips from './views/timeslips/Main.vue';
import Clients from './views/clients/browse/Main.vue';
import Settings from './views/settings/index.js';
import Admin from 'views/admin/index.js';
import Offices from './views/offices/Offices.vue';
import Office from 'views/offices/office/index.js';
// import Buildings from './views/buildings/Buildings.vue'
// import BuildingsCreate from './views/buildings/Create.vue'
import Projects from './views/projects/browse/Main.vue';
import ProjectCreate from './views/projects/create/Index.vue';
import Client from 'views/clients/client/index.js';
import Project from 'views/projects/project/index.js';
import Invoices from './views/invoices/browse/Main.vue';
// import Inspections from './views/inspections/Inspections.vue'
// import InspectionsEdit from './views/inspections/Edit.vue'
// import InspectionsCreate from './views/inspections/Create.vue'
// import Inspection from './views/inspections/inspection'
// import Inspect from './views/inspections/inspect'
import Dispatcher from './views/dispatcher/Main.vue';
import Users from './views/users/browse/Main.vue';
import User from "views/users/user/index.js";
import UserCreate from "./views/users/Create.vue";
// import UsersEdit from "./views/users/Edit.vue";
import Performance from 'views/performance/index.js';
import Auth from 'views/auth/index.js';
import Response404 from './views/Response404.vue';
import AuthGuard from './guards/AuthGuard.js';
import Queries from 'queries/index.js';
import {defaultClient} from "api/index.js";
import SlugGuard from "./guards/SlugGuard.js";
import store from 'store/index.js';
import i18n from 'i18n/index.js';
import stringify from 'json-stringify-deterministic';

Vue.use(VueRouter);

const STORAGE_USER_ID = 'user-id';
const getUserId = () => localStorage.getItem(STORAGE_USER_ID);

const getRouteGuardVariables = ({policy, type = undefined, id = undefined, args = undefined}) => ({
  policy,
  contextType: type,
  ...id && {contextId: id},
  ...args && {contextArguments: stringify(args)},
});

const getRouteGuardObject = ({policy, type = undefined, id = undefined, args = undefined}) => ({
  query: Queries.PolicyTest.Single.Main,
  variables: getRouteGuardVariables({policy, type, id, args}),
});

const getRouteGuardQuery = async ({policy, type = undefined, id = undefined, args = undefined}) => {
  return await defaultClient.query(getRouteGuardObject({policy, type, id, args}));
};

const routeGuardHandler = async (handler, next) => {


  const response = await handler;

  if (Boolean(response?.data?.policyTest?.allowed)) {
    next()
  } else {
    next({name: 'top'});
    store.dispatch('flash/error', i18n.t('auth.permissionGuard'));
  }
}

const staticRouteGuard = ({policy, type = undefined, args = undefined}) => async (to, from, next) => {
  await routeGuardHandler(getRouteGuardQuery({policy, type, args}), next);
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'top',
      component: Top,
      meta: {allowPublic: true},
    },
    {
      path: '/signin',
      name: 'signin',
      component: Auth.SignIn,
      meta: {allowPublic: true, disallowAuthorized: true},
    },
    {
      path: '/login',
      redirect: {name: 'signin'},
    },
    // {
    //   path: '/register',
    //   name: 'register',
    //   component: Auth.Register,
    //   meta: {allowPublic: true, disallowAuthorized: true},
    // },
    {
      path:'/password',
      name: 'forgotPassword',
      component: Auth.ForgotPassword,
      meta: {allowPublic: true, disallowAuthorized: true},
    },
    {
      path:'/password/reset/:token',
      name: 'recoverPassword',
      component: Auth.RecoverPassword,
      props: true,
      meta: {allowPublic: true, disallowAuthorized: true},
    },
    {
      path: '/settings',
      component: Settings.Main,
      children: [
        {
          path: '',
          name: 'settings',
          component: Settings.Profile,
        },
        {
          path: 'personalization',
          name: 'settings.personalization',
          component: Settings.Personalization,
        },
        {
          path: 'security',
          name: 'settings.security',
          component: Settings.Security,
        }
      ],
    },
    {
      path: '/admin',
      component: Admin.Main,
      // beforeEnter: staticRouteGuard('adminTools.view'),
      children: [
        // {
        //   path: '',
        //   name: 'admin',
        //   component: Settings.Profile,
        // },
        {
          path: 'export',
          name: 'admin.export',
          component: Admin.Export,
          beforeEnter: staticRouteGuard({
            policy: 'adminTools.accountingExport',
          }),
        },
        {
          path: 'project-records',
          name: 'admin.projectRecords',
          component: Admin.ProjectRecords,
          beforeEnter: staticRouteGuard({
            policy: 'adminTools.projectRecords',
          }),
        },
        {
          path: 'analytics',
          name: 'admin.analytics',
          component: Admin.Analytics,
          beforeEnter: staticRouteGuard({
            policy: 'adminTools.analytics',
          }),
        },
      ],
    },
    {
      path: '/expenses/review',
      name: 'expenses.review',
      component: Expenses,
      props: {tab: 'review'},
      beforeEnter: async (to, from, next) => {
        await routeGuardHandler(getRouteGuardQuery({
          policy: 'viewScoped',
          type: 'Expense',
          args: {user: getUserId()}
        }), next);
      },
    },
    {
      path: '/expenses/admin',
      name: 'expenses.admin',
      component: Expenses,
      props: {tab: 'admin'},
      beforeEnter: staticRouteGuard({
        policy: 'viewAny',
        type: 'Expense',
      }),
    },
    {
      path: '/expenses',
      name: 'expenses',
      component: Expenses,
      props: {tab: 'default'},
      beforeEnter: async (to, from, next) => {
        await routeGuardHandler(getRouteGuardQuery({
          policy: 'create',
          type: 'Expense',
          args: {
            user: {
              connect: getUserId(),
            },
          },
        }), next);
      },
    },
    {
      path: '/timeslips/review',
      name: 'timeslips.review',
      component: Timeslips,
      props: {tab: 'review'},
      beforeEnter: async (to, from, next) => {
        await routeGuardHandler(getRouteGuardQuery({
          policy: 'viewScoped',
          type: 'Timeslip',
          args: {
            user: getUserId(),
          },
        }), next);
      },
    },
    {
      path: '/timeslips/admin',
      name: 'timeslips.admin',
      component: Timeslips,
      props: {tab: 'admin'},
      beforeEnter: staticRouteGuard({
        policy: 'viewAny',
        type: 'Timeslip',
      }),
    },
    {
      path: '/timeslips',
      name: 'timeslips',
      component: Timeslips,
      props: {tab: 'default'},
      beforeEnter: async (to, from, next) => {
        await routeGuardHandler(getRouteGuardQuery({
          policy: 'create',
          type: 'Timeslip',
          args: {
            user: {
              connect: getUserId(),
            },
          },
        }), next);
      },
    },
    {
      path: '/records',
      name: 'projectRecords',
      component: ProjectRecords,
      // beforeEnter: async (to, from, next) => {
      //   await routeGuardHandler(getRouteGuardQuery({
      //     policy: 'create',
      //     type: 'ProjectRecord',
      //     args: {
      //       user: {
      //         connect: getUserId(),
      //       },
      //     },
      //   }), next);
      // },
    },
    // {
    //   path: '/buildings',
    //   name: 'buildings',
    //   component: Buildings,
    // },
    // {
    //   path: '/buildings/create',
    //   name: 'buildingsCreate',
    //   component: BuildingsCreate,
    // },
    {
      path: '/offices',
      name: 'offices',
      component: Offices,
      beforeEnter: staticRouteGuard({
        policy: 'viewAny',
        type: 'Office',
      }),
    },
    {
      path: '/offices/:slug',
      component: Office.Main,
      props: true,
      meta: {slugToID: Queries.Office.Single.IdFromSlug},
      children: [
        {
          path: '',
          name: 'office',
          component: Office.Overview,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'view',
              type: 'Office',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'team',
          name: 'office.team',
          component: Office.Team,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'viewOnContext',
              type: 'Team',
              args: {
                contextType: 'offices',
                contextId: to.params.id,
              },
            }), next);
          },
        },
        {
          path: 'projects',
          name: 'office.projects',
          component: Office.Projects,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'projectViewAny',
              type: 'Office',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'project-records',
          name: 'office.projectRecords',
          component: Office.ProjectRecords,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'projectRecordViewAny',
              type: 'Office',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'timeslips',
          name: 'office.timeslips',
          component: Office.Timeslips,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'timeslipViewAny',
              type: 'Office',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'expenses',
          name: 'office.expenses',
          component: Office.Expenses,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'expenseViewAny',
              type: 'Office',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'invoicing',
          name: 'office.invoicing',
          component: Office.Invoicing,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'invoiceViewAny',
              type: 'Office',
              id: to.params.id,
            }), next);
          },
        },
      ],
    },
    {
      path: '/invoicing/unpaid',
      name: 'invoicing.unpaid',
      component: Invoices,
      props: {tab: 'unpaid'},
      beforeEnter: async (to, from, next) => {
        await routeGuardHandler(getRouteGuardQuery({
          policy: 'viewScoped',
          type: 'Invoice',
          args: {user: getUserId(), isPaid: false}
        }), next);
      },
    },
    {
      path: '/invoicing/overdue',
      name: 'invoicing.overdue',
      component: Invoices,
      props: {tab: 'overdue'},
      beforeEnter: async (to, from, next) => {
        await routeGuardHandler(getRouteGuardQuery({
          policy: 'viewScoped',
          type: 'Invoice',
          args: {user: getUserId(), isPaid: false, isOverdue: true},
        }), next);
      },
    },
    {
      path: '/invoicing/admin',
      name: 'invoicing.admin',
      component: Invoices,
      props: {tab: 'admin'},
      beforeEnter: staticRouteGuard({
        policy: 'viewAny',
        type: 'Invoice',
      }),
    },
    {
      path: '/invoicing',
      name: 'invoicing',
      component: Invoices,
      beforeEnter: async (to, from, next) => {
        await routeGuardHandler(getRouteGuardQuery({
          policy: 'viewScoped',
          type: 'Invoice',
          args: {user: getUserId()}
        }), next);
      },
    },
    {
      path: '/clients/:slug',
      component: Client.Main,
      props: true,
      meta: {slugToID: Queries.Company.Single.IdFromSlug},
      children: [
        {
          path: 'invoicing',
          name: 'client.invoicing',
          component: Client.Invoicing,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'invoiceViewAny',
              type: 'Company',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: '',
          name: 'client',
          component: Client.Overview,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'view',
              type: 'Company',
              id: to.params.id,
            }), next);
          },
        },
      ],
    },
    {
      path: '/clients',
      name: 'clients',
      component: Clients,
      beforeEnter: staticRouteGuard({
        policy: 'viewScoped',
        type: 'Company',
        args: {companyType: '3'}
      }),
    },
    {
      path: '/projects',
      name: 'projects',
      component: Projects,
      beforeEnter: staticRouteGuard({
        policy: 'viewAny',
        type: 'Project',
      }),
    },
    {
      path: '/projects/create',
      name: 'projectCreate',
      component: ProjectCreate,
      beforeEnter: staticRouteGuard({
        policy: 'create',
        type: 'Project',
      }),
    },
    // {
    //   path: '/projects/:slug/expenses',
    //   name: 'project.expenses',
    //   component: Project.Main,
    //   props: projectPropsFn('expenses'),
    //   //props: true,
    // },
    // {
    //   path: '/projects/:slug/timeslips',
    //   name: 'project.timeslips',
    //   component: Project.Main,
    //   props: projectPropsFn('timeslips'),
    //   //props: true,
    // },
    // {
    //   path: '/projects/:slug',
    //   name: 'project',
    //   component: Project.Main,
    //   props: projectPropsFn('default'),
    //   //props: true,
    // },
    {
      path: '/projects/:slug',
      component: Project.Main,
      props: true,
      meta: {slugToID: Queries.Project.Single.IdFromSlug},
      children: [
        {
          path: 'team',
          name: 'project.team',
          component: Project.Team,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'viewOnContext',
              type: 'Team',
              args: {
                contextType: 'projects',
                contextId: to.params.id,
              },
            }), next);
          },
        },
        {
          path: 'records',
          name: 'project.projectRecords',
          component: Project.ProjectRecord,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'projectRecordViewAny',
              type: 'Project',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'billing-rates',
          name: 'project.billingRates',
          component: Project.BillingRates,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'billingRateViewAny',
              type: 'Project',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'timeslips',
          name: 'project.timeslips',
          component: Project.Timeslips,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'timeslipViewAny',
              type: 'Project',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'expenses',
          name: 'project.expenses',
          component: Project.Expenses,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'expenseViewAny',
              type: 'Project',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'invoicing',
          name: 'project.invoicing',
          component: Project.Invoicing,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'invoiceViewAny',
              type: 'Project',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'edit',
          name: 'project.edit',
          component: Project.Edit,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'update',
              type: 'Project',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: '',
          name: 'project',
          component: Project.Overview,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'view',
              type: 'Project',
              id: to.params.id,
            }), next);
          },
        },
        // {
        //   path: 'team',
        //   name: 'projectTeam',
        //   component: Project.Team,
        //   props: true,
        // },
        // {
        //   path: 'devices',
        //   name: 'projectDevices',
        //   component: Project.Devices,
        //   props: true,
        // },
        // {
        //   path: 'inspections',
        //   name: 'projectInspections',
        //   component: Project.Inspections,
        //   props: true,
        // },
        // {
        //   path: 'inspections/create',
        //   name: 'projectInspectionCreate',
        //   component: Project.CreateInspections,
        //   props: true,
        // },
      ],
    },
    // {
    //   path: '/inspections/create',
    //   name: 'inspectionCreate',
    //   component: InspectionsCreate,
    // },
    // {
    //   path: '/inspections/:slug/edit',
    //   component: InspectionsEdit,
    //   name: 'inspectionEdit',
    //   props: true,
    // },
    // {
    //   path: '/inspections/:slug/inspect',
    //   components: {default: Inspect.Main, bottom: Inspect.BottomNavigation},
    //   props: {default: true, bottom: true},
    //   children: [
    //     {
    //       path: '',
    //       name: 'inspect',
    //       component: Inspect.Overview,
    //       props: true,
    //     },
    //     {
    //       path: 'issues',
    //       name: 'inspectIssues',
    //       component: Inspect.Issues,
    //       props: true,
    //     },
    //     {
    //       path: 'performance',
    //       name: 'inspectPerformance',
    //       component: Inspect.Performance,
    //       props: true,
    //     },
    //   ],
    // },
    // {
    //   path: '/inspections/:slug',
    //   component: Inspection.Main,
    //   props: true,
    //   children: [
    //     {
    //       path: '',
    //       name: 'inspection',
    //       component: Inspection.Overview,
    //       props: true,
    //     },
    //     {
    //       path: 'issues',
    //       name: 'inspectionIssues',
    //       component: Inspection.Issues,
    //       props: true,
    //     },
    //   ],
    // },
    // {
    //   path: '/inspections',
    //   name: 'inspections',
    //   component: Inspections,
    // },
    {
      path: '/performance',
      name: 'performance',
      component: Performance.Main,
      beforeEnter: staticRouteGuard({
        policy: 'performanceTool.interface',
      }),
    },
    // {
    //   path: '/performance',
    //   components: {default: Performance.Main, bottom: Performance.BottomNavigation},
    //   props: {default: true, bottom: true},
    //   children: [
    //     {
    //       path: '',
    //       name: 'performance',
    //       component: Performance.Performance,
    //       props: true,
    //     },
    //     {
    //       path: 'setup',
    //       name: 'performance.setup',
    //       component: Performance.Setup,
    //       props: true,
    //     },
    //   ],
    // },
    {
      path: '/devices',
      name: 'users',
      component: Users,
      beforeEnter: staticRouteGuard({
        policy: 'viewAny',
        type: 'User',
      }),
    },
    {
      path: '/devices/create',
      name: 'userCreate',
      component: UserCreate,
      beforeEnter: staticRouteGuard({
        policy: 'create',
        type: 'User',
      }),
    },
    // {
    //   path: '/devices/:slug/edit',
    //   component: UsersEdit,
    //   name: 'usersEdit',
    //   props: true,
    //   meta: {slugToID: Queries.User.Single.IdFromSlug},
    //   beforeEnter: async (to, from, next) => {
    //     await routeGuardHandler(getRouteGuardQuery({
    //       policy: 'update',
    //       type: 'User',
    //       id: to.params.id,
    //     }), next);
    //   },
    // },
    {
      path: '/devices/:slug',
      component: User.Main,
      props: true,
      meta: {slugToID: Queries.User.Single.IdFromSlug},
      children: [
        {
          path: 'timeslips',
          name: 'user.timeslips',
          component: User.Timeslips,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'timeslipViewAny',
              type: 'User',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'expenses',
          name: 'user.expenses',
          component: User.Expenses,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'expenseViewAny',
              type: 'User',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'invoicing',
          name: 'user.invoicing',
          component: User.Invoicing,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'invoiceViewAny',
              type: 'User',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'team',
          name: 'user.team',
          component: User.Team,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'viewOnContext',
              type: 'Team',
              args: {
                contextType: 'users',
                contextId: to.params.id,
              },
            }), next);
          },
        },
        {
          path: 'billing-rates',
          name: 'user.billingRates',
          component: User.BillingRates,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'billingRateViewAny',
              type: 'User',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: 'edit',
          name: 'user.edit',
          component: User.Edit,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'update',
              type: 'User',
              id: to.params.id,
            }), next);
          },
        },
        {
          path: '',
          name: 'user',
          component: User.Overview,
          props: true,
          beforeEnter: async (to, from, next) => {
            await routeGuardHandler(getRouteGuardQuery({
              policy: 'view',
              type: 'User',
              id: to.params.id,
            }), next);
          },
        },
      ],
    },
    {
      path: '/dispatcher',
      name: 'dispatcher',
      component: Dispatcher,
      props: true,
      // beforeEnter: async (to, from, next) => {
      //   await routeGuardHandler(getRouteGuardQuery({
      //     policy: 'expenseViewAny',
      //     type: 'User',
      //     id: to.params.id,
      //   }), next);
      // },
    },
    // {
    //   path: '/test',
    //   name: 'test',
    //   component: MediaSandbox,
    //   // props: true,
    //   // beforeEnter: async (to, from, next) => {
    //   //   await routeGuardHandler(getRouteGuardQuery({
    //   //     policy: 'expenseViewAny',
    //   //     type: 'User',
    //   //     id: to.params.id,
    //   //   }), next);
    //   // },
    // },
    {
      path: '*',
      name: '404',
      component: Response404
    }
  ]
});

router.beforeEach((to, from, next) => {
  store.dispatch('session/startLoading');
  next();
});

router.beforeEach(AuthGuard);

router.beforeEach(SlugGuard);

router.afterEach(() => {
  store.dispatch('session/stopLoading');
});

export default router;
