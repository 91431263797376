<template>
  <VRow>
    <VCol cols="12">
      Name: <span v-if="dataFlat.name">{{dataFlat.name}}</span><em v-else>{{ notSetMsg }}</em>
    </VCol>
    <VCol cols="12">
      Type: <span v-if="projectType">{{projectType.name}}</span><em v-else>{{ notSetMsg }}</em>
    </VCol>
    <VCol cols="12">
      Office: <span v-if="office">{{office.name}}</span><em v-else>{{ notSetMsg }}</em>
    </VCol>
    <VCol cols="12">
      Client locale: <span v-if="dataFlat.locale">{{dataFlat.locale}}</span><em v-else>{{ notSetMsg }}</em>
    </VCol>
    <VCol cols="12">
      Work Region: <span v-if="countryDivision">{{countryDivision.name}}</span><em v-else>{{ notSetMsg }}</em>
    </VCol>
    <VCol cols="12">
      Is tax exempt: <span v-if="dataFlat.isTaxExempt" v-t="'ui.yes'"/><span v-else v-t="'ui.no'"/>
    </VCol>
<!--    <VCol cols="12">-->
<!--      Client: <span v-if="company">{{company.name}}</span><em v-else>Not set</em>-->
<!--    </VCol>-->
    <VCol cols="12">
      Status: <span v-if="projectStatus">{{projectStatus.name}}</span><em v-else>{{ notSetMsg }}</em>
    </VCol>
    <VCol cols="12">
      Role: <span v-if="role">{{role.displayName}}</span><em v-else>{{ notSetMsg }}</em>
    </VCol>
    <VCol cols="12">
      Supervisor: <span v-if="dataFlat.supervisorId && supervisor">{{supervisor.name}}</span><em v-else>{{ notSetMsg }}</em>
    </VCol>
    <VCol cols="12">
      Record data:
      <JsonPretty :data="dataFlat.projectRecord"/>
    </VCol>
  </VRow>
</template>

<script>
import Queries from 'queries/index.js';
import {JsonPretty} from "elements/index.js";

export default {
  components: {
    JsonPretty,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  apollo: {
    office: {
      query: Queries.Office.Forms.Populate,
      variables() {
        return {
          id: this.dataFlat.officeId,
        };
      },
      skip() {
        return !this.dataFlat?.officeId;
      },
    },
    countryDivision: {
      query: Queries.CountryDivision.Single.Name,
      variables() {
        return {
          id: this.dataFlat.workRegion,
        }
      },
      skip() {
        return !this.dataFlat?.workRegion;
      },
    },
    projectType: {
      query: Queries.ProjectType.Forms.Populate,
      variables() {
        return {
          id: this.dataFlat.projectTypeId,
        };
      },
      skip() {
        return !this.dataFlat?.projectTypeId;
      },
    },
    projectStatus: {
      query: Queries.ProjectStatus.Forms.Populate,
      variables() {
        return {
          id: this.dataFlat.projectStatusId,
        };
      },
      skip() {
        return !this.dataFlat?.projectStatusId;
      },
    },
    company: {
      query: Queries.Company.Forms.Populate,
      variables() {
        return {
          id: this.dataFlat.clientCompanyId,
        };
      },
      skip() {
        return !this.dataFlat?.clientCompanyId;
      },
    },
    role: {
      query: Queries.Role.Forms.Populate,
      variables() {
        return {
          id: this.dataFlat.roleId,
        };
      },
      skip() {
        return !this.dataFlat?.roleId;
      },
    },

    supervisor: {
      query: Queries.User.Forms.Populate,
      variables() {
        return {
          id: this.dataFlat.supervisorId,
        };
      },
      skip() {
        return !this.dataFlat?.supervisorId;
      },
      update: ({user}) => user,
    },
  },

  computed: {
    dataFlat() {
      const payload = {};
      Object.values(this.data).forEach((v) => {
        Object.assign(payload, v);
      });

      return payload;
    },

    projectRecordForDisplay() {
      const {dataFlat: {projectRecord}} = this;
      return projectRecord ? JSON.stringify(projectRecord) : null;
    },

    notSetMsg() {
      return this.$t('ui.notSet');
    }
  },
}
</script>
