<template>
  <VExpansionPanel>
    <PartitionSetItemHeader
      :id="id"
      :simple="simple"
    />
    <PartitionSetItemContent
      :id="id"
      @deleted="onDelete"
      @updated="onUpdate"
    />
  </VExpansionPanel>
</template>

<script>
  import PartitionSetItemContent from './PartitionSetItemContent.vue'
  import PartitionSetItemHeader from './PartitionSetItemHeader.vue'

  export default {
    components: {
      PartitionSetItemContent,
      PartitionSetItemHeader,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    methods: {
      onDelete(response) {
        this.$emit('delete', response);
      },

      onUpdate(response) {
        this.$emit('update', response);
      },
    }
  }
</script>
