import {client as defaultClient} from "api/index.js";
import store from "store/index.js";

const getIdFromSlugQuery = async (query, slug) => {
  const response = await defaultClient.query({
    query,
    variables: {slug},
  });

  if (!response || !response.data) {
    return undefined;
  }

  const payload = Object.values(response.data)[0];

  return payload && payload.id ? payload.id : undefined;
};

export default (query, backup= {name: 'top'}) => {
  return async (to, from, next) => {
    const {params} = to;
    const {slug} = params;

    if (!params.id) {
      const id = await getIdFromSlugQuery(query, slug);
      if (!id) {
        store.dispatch('flash/warning', 'auth.404redirect');
        next(backup);
      }

      params.id = id;
    } else {
      // Fire query if not done already to populate cache, intentionally not waiting for response in this case
      getIdFromSlugQuery(query, slug);
    }

    next();
  };
};
