<template>
  <VDialog max-width="500">
    <template #activator="{on, attrs}">
      <VBtn color="grey" icon elevation="0" v-on="on" v-bind="attrs" :disabled="disabled">
        <FaI :icon="['far', 'circle-info']"/>
      </VBtn>
    </template>
    <template #default="dialog">
      <VCard>
        <VCardTitle v-t="'forms.project.searchScope.title'"/>
        <VCardText class="black--text">
          <VContainer>
            <VRow>
              <VCol cols="12">
                <ProjectPickerScopeBtn/>
              </VCol>
              <VCol cols="12" v-t="'forms.project.searchScope.help.description'"/>
            </VRow>
            <VRow align="center">
              <VCol cols="auto">
                <FaI size="lg" icon="globe"/>
              </VCol>
              <VCol cols="auto" class="d-flex flex-nowrap">
                <span v-t="'forms.project.searchScope.help.all'"/>
              </VCol>
            </VRow>
            <VRow align="center">
              <VCol cols="auto">
                <FaI size="lg" icon="user"/>
              </VCol>
              <VCol class="d-flex flex-nowrap">
                <span v-t="'forms.project.searchScope.help.member'"/>
              </VCol>
            </VRow>
          </VContainer>
        </VCardText>
        <VCardActions>
          <VSpacer/>
          <VBtn text @click="dialog.value = false">
            <span v-t="'actions.close'"/>
          </VBtn>
        </VCardActions>
      </VCard>
    </template>
  </VDialog>
</template>

<script>
import ProjectPickerScopeBtn from "./ProjectPickerScopeBtn.vue";

export default {
  components: {
    ProjectPickerScopeBtn,
  },

  props: {
    disabled: Boolean,
  },
}
</script>
