<template>
  <VExpandTransition>
    <VSkeletonLoader type="article" v-if="$apollo.loading"/>
    <div v-else>
      <div v-if="!hideIdentifier">
        <span v-t="'forms.projectRecord.submittingFor'"/>:
        <ProjectListItem :id="projectId" disabled/>
      </div>
      <VCard v-if="!latest" class="my-3" color="light-blue lighten-5">
        <VCardText>
          <VAlert type="info" text>
            <span v-t="'forms.projectRecord.firstRecordHelp'"/>
          </VAlert>
          <KSubmitOnSelect
            :value="project.projectType.id"
            :id="projectId"
            mutation="Project.Write.Update"
            model="projectType"
            argument="projectTypeId"
          />
        </VCardText>
      </VCard>
      <form @submit.prevent="doSubmit">
        <VCard color="grey lighten-5">
          <VCardText>
            <KApiSelect
              v-model="form.projectStatusId"
              model="projectStatus"
              hint="forms.general.required"
              persistent-hint
            />
          </VCardText>
        </VCard>
        <FormProjectRecord v-model="form.projectRecord" :project-type="project.projectType.id" :project-status="form.projectStatusId" :financials-optional="financialsOptional" @validator="validator = $event" @success="onSuccess" class="mt-2"/>
        <VRow justify="space-between">
          <VCol cols="auto">
            <KBtnSubmit :mode="mode"/>
          </VCol>
          <VCol cols="auto">
            <VBtn @click="$emit('reset')" class="ml-auto">
              <span v-t="'actions.cancel'"/>
            </VBtn>
          </VCol>
        </VRow>
      </form>
    </div>
  </VExpandTransition>
</template>

<script>
import FormProjectRecord from "./FormProjectRecord.vue";
import ProjectListItem from "top/views/projects/browse/elements/ProjectListItem.vue";
import {FormMixin, KApiSelect, KSubmitOnSelect, KBtnSubmit} from "forms/elements/index.js";
import Queries from "queries/index.js";
import {required, primaryKey, validatorIsValid} from "validators/index.js";
import {parseISO, differenceInDays} from 'date-fns';
import {mapState} from 'vuex';

const FINANCIALS_OPTIONAL_DAYS = 7;

export default {
  mixins: [
    FormMixin,
  ],

  components: {
    FormProjectRecord,
    KApiSelect,
    KSubmitOnSelect,
    KBtnSubmit,
    ProjectListItem,
  },

  data: () => ({
    form: {
      projectRecord: null,
      projectStatusId: null,
    },
    validator: null,
  }),

  props: {
    projectId: {
      type: [Number, String],
      required: true,
    },

    hideIdentifier: Boolean,
  },

  apollo: {
    project: {
      query: Queries.Project.Forms.ProjectRecordPopulate,
      variables() {
        return {
          id: this.projectId,
        };
      },
    },

    latest: {
      query: Queries.ProjectRecord.Single.Latest,
      variables() {
        return {
          projectId: this.projectId,
        };
      },
      update: (response) => response?.projectRecords?.data?.[0],
      fetchPolicy: 'cache-and-network',
    },
  },

  computed: {
    ...mapState('session', ['now']),

    financialsOptional() {
      return !this.project || differenceInDays(this.now, parseISO(this.project.createdAt)) < FINANCIALS_OPTIONAL_DAYS;
    },
  },

  watch: {
    project(v) {
      if (v) {
        this.form.projectStatusId = v.projectStatus.id;
      }
    },

    '$v.$dirty'(v) {
      if (v) {
        this.validator.$touch();
      }
    },

    latest(v) {
      this.$nextTick(() => {
        this.populateForm();
      });
    },

    'form.projectStatusId'(v) {
      this.$nextTick(() => {
        this.populateForm();
      });
    },
  },

  validations: {
    form: {
      projectRecord: {
        required,
      },
      projectStatusId: {
        required,
        primaryKey,
      }
    },
    validator: {
      validatorIsValid,
    },
  },

  methods: {
    populateForm() {
      const {latest, form: {projectRecord}} = this;

      if (!latest || !projectRecord) {
        return;
      }

      const formType = Object.keys(projectRecord)?.[0];
      const targetRecord = projectRecord[formType];
      const sourceRecord = latest.record;

      Object.entries(sourceRecord).forEach(([key, value]) => {
        if (targetRecord.hasOwnProperty(key)) {
          if (typeof targetRecord[key] === 'object' && targetRecord[key] !== null && typeof sourceRecord[key] === 'object' && sourceRecord[key] !== null) {
            let subtarget = targetRecord[key];
            if (key === 'financials') {
              subtarget = Object.values(subtarget)?.[0];
            }
            if (typeof subtarget === 'object') {
              Object.entries(sourceRecord[key]).forEach(([subkey, value]) => {
                if (subtarget.hasOwnProperty(subkey)) {
                  subtarget[subkey] = value;
                }
              });

              // Fix for v1.3.0 changing boolean "expensesBillable" to enumerated "expensesBillableType"
              if (key === 'financials') {
                if (!sourceRecord?.financials?.expensesBillableType) {
                  subtarget.expensesBillableType = sourceRecord?.financials?.expensesBillable ? 'USUALLY' : 'SOMETIMES';
                }

                if (!sourceRecord?.financials?.timeslipsBillableType) {
                  subtarget.timeslipsBillableType = 'SOMETIMES';
                }
              }
            }
          } else {
            targetRecord[key] = value;
          }
        }
      });
    },

    async createAction() {
      const {projectId, form: {projectStatusId, projectRecord}} = this;
      const {data: { projectRecordCreate: response}} = await this.$apollo.mutate({
        mutation: Queries.ProjectRecord.Write.Create,
        variables: {
          projectId,
          projectStatusId,
          record: projectRecord,
        },
      });

      return response;
    },

    onSuccess(response) {
      this.$emit('success', response);
    },
  },
}
</script>
