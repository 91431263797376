import {formatDistance as sourceFormatDistance, formatISO} from "date-fns";
import {format as sourceFormat} from "date-fns-tz";
import store from "store/index.js";

const getLocale = () => store.getters['session/dateLocaleObject'];

const format = (date, formatStr = 'yyyy-MM-dd, p', options = {}) =>
  sourceFormat(date, formatStr, {
    locale: getLocale(),
    ...options,
  });

const formatDistance = (date, baseDate, options = {}) =>
  sourceFormatDistance(date, baseDate, {
    locale: getLocale(),
    ...options,
  });

const formatISODate = (date) =>
  formatISO(date, {representation: 'date'});

export {
  format,
  formatDistance,
  formatISODate,
}
