<template>
  <div>
    <VTextField
      :hint="hintParsed"
      :error-messages="errorMessages"
      :label="labelParsed"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="isOnMobile"
      @input="onTextFieldInput"
      @focus="onFocus"
      @blur="onBlur"
      @click:clear="onClear"
      :value="textFieldValue"
      v-on="on"
      v-bind="attrs"
    >
      <template v-slot:append-outer v-if="showTimeZonePicker">
        <KTimeZonePicker
          v-model="localTimeZone"
          :reference="pivot"
          :disabled="disabled"
        />
      </template>
      <template v-slot:append>
        <FaI :icon="['far', 'clock']" color="grey"/>
      </template>
    </VTextField>
    <VTimePicker
      :value="pickerDefault"
      @input="onPickerInput"
      ampm-in-title
      @change="onChange"
      :disabled="disabled"
      :format="parsedTimeFormat"
      :allowed-hours="allowedHours"
      :allowed-minutes="allowedMinutes"
      full-width
    />
</div>
</template>

<script>
import KTimePickerMixin from './KTimePickerMixin.js'

export default {
  mixins: [
    KTimePickerMixin,
  ],
}
</script>
