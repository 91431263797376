<template>
  <VContainer>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar color="grey lighten-3" elevation="0">
            <VBtn icon color="grey" :to="{name: 'users'}" exact>
              <FaI size="2x" icon="arrow-circle-left"/>
            </VBtn>
            <VToolbarTitle>
              {{formTitle}}
            </VToolbarTitle>
          </VToolbar>
          <VCardText>
            <FormUserCreate @success="onSuccess"/>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import FormUserCreate from 'top/forms/FormUserCreate.vue'

export default {
  components: {
    FormUserCreate,
  },

  computed: {
    formTitle() {
      return this.$t('forms.messages.create', {type: this.$tc('models.user.self')});
    },
  },

  methods: {
    onSuccess({id, slug, name}) {
      this.$store.dispatch(
        'flash/success',
        {
          text: 'forms.messages.modelCreated',
          args: {
            type: 'models.user.self',
            name,
          },
        });

      this.$router.push({name: 'user', params: {id, slug}});
    },
  },
}
</script>
