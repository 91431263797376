<template>
  <div>
    <VNavigationDrawer v-model="showDrawer" :permanent="!isMobile" :absolute="isMobile" :app="!isMobile" :clipped="!isMobile" color="grey lighten-3">
      <VList nav>
        <VListItem two-line>
          <VListItemContent>
            <VListItemTitle class="text-h5" v-t="'ui.settings'"/>
          </VListItemContent>
        </VListItem>

        <VDivider/>

        <VListItem :to="{name: 'settings'}" v-t="'views.settings.menu.profile'" exact/>
        <VListItem :to="{name: 'settings.personalization'}" v-t="'views.settings.menu.personalization'"/>
        <VListItem :to="{name: 'settings.security'}" v-t="'views.settings.menu.security'"/>
        <VListItem v-t="'views.settings.menu.notifications'" disabled/>
      </VList>
    </VNavigationDrawer>
    <div>
      <VToolbar dense v-if="isMobile" color="grey lighten-3">
        <VAppBarNavIcon @click="drawerActivated = !drawerActivated"/>
        <VToolbarTitle v-t="'ui.settings'"/>
      </VToolbar>
      <RouterView/>
    </div>
  </div>
</template>

<script>
  export default {
    data: () => ({
      drawerActivated: false,
    }),

    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      },

      showDrawer: {
        set(v) {
          this.drawerActivated = v;
        },
        get() {
          return !this.isMobile || this.drawerActivated;
        }
      },
    },

    watch: {
      isMobile(v) {
        this.drawerActivated = false;
      },
    },
  }
</script>
