import gql from "graphql-tag";
import PolicyTest from "./policyTest.js";

const ThumbnailFragment = gql`
  fragment userThumbnailFragment on User {
    id
    name
    slug
    email
    identicon {
      id
      url
    }
    permissions(policies: [{policy: "view"}]) {
      ...isAllowedFragment
    }
  }
  ${PolicyTest.Fragments.IsAllowed}
`;

export default ThumbnailFragment;
