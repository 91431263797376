<template>
  <VSkeletonLoader type="card" v-if="$apollo.loading"/>
  <VCard v-else>
    <VCardTitle>
      <span v-t="{path: 'models.partitionsSet.self', choice: 1}"/>
    </VCardTitle>
    <VCardText v-if="partitionsSet.description">
        {{partitionsSet.description}}
    </VCardText>
    <VList>
      <template v-for="(partition, index) in partitionsSet.partitions">
        <VDivider :key="`d-${partition.id}`" v-if="index !== 0"/>
        <ProjectListItem :id="partition.project.id">
          <template #action>
            <strong>
              {{getPartitionWeight(partition)}}%
            </strong>
            <VChip v-if="partition.isTimeslipBillable" outlined>
              <FaI icon="clock" class="text--lighten-3 mr-1"/>
              <FaI icon="file-invoice-dollar" class="orange--text text--darken-2"/>
            </VChip>
            <VChip v-if="partition.isExpenseBillable" outlined>
              <FaI icon="receipt" class="text--lighten-3 mr-1"/>
              <FaI icon="file-invoice-dollar" class="orange--text text--darken-2"/>
            </VChip>
          </template>
        </ProjectListItem>
        <VListItem v-if="partition.comment" :key="`c-${partition.id}`">
          <VListItemContent>
            <VListItemSubtitle>
              {{partition.comment}}
            </VListItemSubtitle>
          </VListItemContent>
        </VListItem>
      </template>
    </VList>
    <VCardActions>
      <VBtn @click="doCancel" text>
        <FaI icon="times"/>&nbsp;
        Close
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import Queries from 'queries/index.js';
import ProjectListItem from '../../projects/browse/elements/ProjectListItem.vue';

export default {
  components: {
    ProjectListItem,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  apollo: {
    partitionsSet: {
      query: Queries.PartitionsSet.Single.Main,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },

  computed: {
    getTotalWeight() {
      return this.partitionsSet.partitions.reduce((accum, current) => accum + current.weight, 0);
    },
  },

  methods: {
    getPartitionWeight(partition) {
      return Math.round(partition.weight/this.getTotalWeight*100);
    },

    doCancel() {
      this.$emit('cancel', true);
    }
  },
}
</script>
