import gql from 'graphql-tag'
import User from './user.js'
import DataExport from './dataExport.js'
import PaginatorFragment from './paginatorFragment.js'

const ListFragment = gql`
  fragment projectRecordListFragment on ProjectRecord {
    id
    createdAt
    updatedAt
    recordRaw
    project {
      id
      recordDueAt
      projectStatus {
        id
      }
      projectType {
        id
      }
    }
    createdBy {
      id
    }
    updatedBy {
      id
    }
  }
`;

const UserProjectRecordStatusFragment = gql`
  fragment userProjectRecordStatusFragment on User {
    id
    projectRecordDue
    projectRecordsOverdueCount
  }
`;

const DeviceCountFragment = gql`
  fragment deviceCountFragment on ProjectRecordDeviceCount {
    elevators
    escalators
  }
`;

const FinancialsOpenScopeFragment = gql`
  fragment financialsOpenScopeFragment on ProjectRecordOpenScopeFinancials {
    feesAnnumEstimate
  }
`;

const FinancialsClosedScopeFragment = gql`
  fragment financialsClosedScopeFragment on ProjectRecordClosedScopeFinancials {
    scheduledFees
    timeslipsBillableType
    subconsultantCost
    subconsultantCostBillable
    subconsultantCostMarkup
    expenses
    expensesBillableType
    expensesBillable
    expensesMarkup
    netFees
    markupFees
    hourlyRateEstimate
    hoursEstimate
  }
`;

const FinancialsFragment = gql`
  fragment financialsFragment on ProjectRecordFinancials {
    __typename
    ...on ProjectRecordOpenScopeFinancials {
      ...financialsOpenScopeFragment
    }
    ...on ProjectRecordClosedScopeFinancials {
      ...financialsClosedScopeFragment
    }
  }
  ${FinancialsOpenScopeFragment}
  ${FinancialsClosedScopeFragment}
`;


// Single

const Latest = gql`
  query latestRecordOnProject (
    $projectId: ID!
  ) {
    projectRecords(
      project: $projectId
      first: 1
    ) {
      data {
        id
        version
        notes
        createdAt
        project {
          id
          name
          recordDueAt
          projectType {
            id
          }
          projectStatus {
            id
          }
        }
        projectType {
          id
        }
        projectStatus {
          id
        }
        record {
          __typename
          ...on ProjectRecordSales {
            comment
            awardProbability
            isCompetitive
            awardDateEstimate
            deviceCount {
              ...deviceCountFragment
            }
            financials {
              ...financialsFragment
            }
          }
          ...on ProjectRecordInternal {
            hoursAnnumBudgeted
          }
          ...on ProjectRecordLost {
            comment
            awardedFirm
            awardedFee
          }
          ...on ProjectRecordActive {
            interaction
            comment
            startDate
            endDateEstimate
            deviceCount {
              ...deviceCountFragment
            }
            financials {
              ...financialsFragment
            }
          }
          ...on ProjectRecordHold {
            comment
            scheduledResumption
          }
          ...on ProjectRecordComplete {
            clientReview
            marketingFollowUp
            accountingFollowUp
          }
        }
      }
    }
  }
  ${DeviceCountFragment}
  ${FinancialsFragment}
`;


// Browse

const List = gql`
  query(
    $project: ID
    $projectTypes: [ID!]
    $projectStatuses: [ID!]
    $offices: [ID!]
    $teamMember: ProjectTeamMemberInput
    $recordDueBy: Date
    $createdBy: ID
    $createdAt: DateRange
    $orderBy: [QueryProjectRecordsOrderByOrderByClause!]
    $page: Int
    $first: Int!
  ) {
    projectRecords(
      project: $project
      projectTypes: $projectTypes
      projectStatuses: $projectStatuses
      offices: $offices
      teamMember: $teamMember
      recordDueBy: $recordDueBy
      createdBy: $createdBy
      createdAt: $createdAt
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      paginatorInfo {
        ...standardPaginator
      }
      data {
        ...projectRecordListFragment
      }
    }
  }
  ${PaginatorFragment}
  ${ListFragment}
`;

const Snapshot = gql`
  mutation(
    $uuid: UUID!
    $date: Date
    $project: ID
    $projectTypes: [ID!]
    $projectStatuses: [ID!]
    $offices: [ID!]
    $teamMember: ProjectTeamMemberInput
    $recordDueBy: Date
    $createdBy: ID
    $orderBy: [MutationProjectRecordsSnapshotOrderByOrderByClause!]
  ) {
    projectRecordsSnapshot(
      uuid: $uuid
      date: $date
      project: $project
      projectTypes: $projectTypes
      projectStatuses: $projectStatuses
      offices: $offices
      teamMember: $teamMember
      recordDueBy: $recordDueBy
      createdBy: $createdBy
      orderBy: $orderBy
    ) {
      ...dataExportDownloadFragment
    }
  }
  ${DataExport.Fragments.DownloadFragment}
`;

const Export = gql`
  mutation(
    $uuid: UUID!
    $project: ID
    $projectTypes: [ID!]
    $projectStatuses: [ID!]
    $offices: [ID!]
    $teamMember: ProjectTeamMemberInput
    $recordDueBy: Date
    $createdBy: ID
    $createdAt: DateRange
    $orderBy: [MutationProjectRecordsExportOrderByOrderByClause!]
  ) {
    projectRecordsExport(
      uuid: $uuid
      project: $project
      projectTypes: $projectTypes
      projectStatuses: $projectStatuses
      offices: $offices
      teamMember: $teamMember
      recordDueBy: $recordDueBy
      createdBy: $createdBy
      createdAt: $createdAt
      orderBy: $orderBy
    ) {
      ...dataExportDownloadFragment
    }
  }
  ${DataExport.Fragments.DownloadFragment}
`;

const Row = gql`
  query(
    $id: ID!
  ) {
    projectRecord(
      id: $id
    ) {
      ...projectRecordListFragment
    }
  }
  ${ListFragment}
`;

const StatusReportList = gql `
  query projectRecordsStatusUsersList {
    users(
      first: 200
    ) {
      data {
        ...userThumbnailFragment
        ...userProjectRecordStatusFragment
      }
    }
  }
  ${User.Fragment.ThumbnailFragment}
  ${UserProjectRecordStatusFragment}
`;


// Single

const Main = gql`
  query(
    $id: ID!
  ) {
    projectRecord(
      id: $id
    ) {
      ...projectRecordListFragment
    }
  }
  ${ListFragment}
`;

const ExpansionPanelHeader = gql`
  query(
    $id: ID!
  ) {
    projectRecord(
      id: $id
    ) {
      id
      createdAt
      updatedAt
      project {
        id
        name
        recordDueAt
      }
      projectStatus {
        id
      }
      createdBy {
        ...userThumbnailFragment
      }
      record {
        ...on ProjectRecordCommentInterface {
          comment
        }
      }
    }
  }
  ${User.Fragment.ThumbnailFragment}
`;

const ExpansionPanelContent = gql`
  query(
    $id: ID!
  ) {
    projectRecord(
      id: $id
    ) {
      id
      recordRaw
      createdAt
      updatedAt
      project {
        id
        recordDueAt
      }
      createdBy {
        ...userThumbnailFragment
      }
      updatedBy {
        ...userThumbnailFragment
      }
    }
  }
  ${User.Fragment.ThumbnailFragment}
`;

const StatusReportItem = gql `
  query projectRecordsStatusUserItem (
    $id: ID!
  ) {
    user(
      id: $id
    ) {
      ...userProjectRecordStatusFragment
    }
  }
  ${UserProjectRecordStatusFragment}
`;

// Write

const Create = gql`
  mutation(
    $projectId: ID!
    $projectStatusId: ID!
    $record: ProjectRecordInput!
  ) {
    projectRecordCreate(
      input: {
        project: {
          connect: $projectId
        },
        projectStatus: {
          connect: $projectStatusId
        }
        record: $record
      }
    ) {
      ...projectRecordListFragment
    }
  }
  ${ListFragment}
`;


// Export

const Fragments = {
  List: ListFragment
};

const Browse = {
  List,
  Snapshot,
  Export,
  Row,
  StatusReport: StatusReportList,
};

const Single = {
  Main,
  Latest,
  ExpansionPanelHeader,
  ExpansionPanelContent,
  StatusReport: StatusReportItem,
};

const Write = {
  Create,
};

export default {
  Browse,
  Fragments,
  Single,
  Write,
};
