<template>
  <VAutocomplete
    auto-select-first
    :autofocus="autofocus"
    :clearable="clearable"
    :counter="counter"
    :disabled="disabled"
    :error-messages="errorMessages"
    :filter="filter"
    :hide-no-data="hideNoData"
    :hint="hintParsed"
    :item-text="itemText"
    :item-value="itemValue"
    :items="items"
    :label="labelParsed"
    :loading="loading"
    :multiple="multiple"
    :no-data-text="noDataTextParsed"
    :no-filter="noFilter"
    :outlined="outlined"
    :persistent-hint="localPersistentHint"
    :placeholder="localPlaceholder"
    :return-object="returnObject"
    :search-input.sync="searchString"
    :suffix="suffix"
    :value="value"
    @focus="onFocus"
    @blur="onBlur"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </VAutocomplete>
</template>

<script>
  import KFormTextElementMixin from "./KFormTextElementMixin.js";
  import Queries from "queries/index.js";

  export default {
    mixins: [
      KFormTextElementMixin,
    ],

    data: () => ({
      items: [],
      isFocused: false,
      searchString: '',
    }),

    props: {
      model: {
        type: String,
        required: true,
      },

      queryName: String,

      skip: Boolean,

      value: {
        required: true,
      },

      itemText: {
        type: [String, Array, Function],
        default: 'name',
      },

      itemValue: {
        type: [String, Array, Function],
        default: 'id',
      },

      multiple: Boolean,

      returnObject: Boolean,

      // For autocomplete

      filter: {
        type: Function,
        default: (item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1,
      },

      placeholder: {
        type: String,
        default: 'forms.general.searchPlaceholder',
      },

      noDataText: {
        type: String,
        default: 'ui.noMatches',
      },

      noFilter: {
        type: Boolean,
        default: true,
      },

      // For API retrieval

      variables: {
        type: Object,
        default: () => ({}),
      },

      throttle: {
        type: Number,
        default: 1000,
      },

      field: {
        type: String,
        default: 'search',
      },

      fetchPolicy: {
        type: String,
        default: 'cache-first',
        validator: (v) => ['cache-first', 'cache-and-network', 'network-only', 'cache-only', 'no-cache'].includes(v),
      },
    },

    apollo: {
      items: {
        query() {
          return this.itemsQuery;
        },
        throttle: 300,
        variables() {
          const {variables, field, searchString} = this;
          return {
            [field]: searchString,
            ...variables,
            ...!variables.first && {first: 10},
          };
        },
        skip() {
          const {skip, searchString, disabled} = this;
          return disabled || skip || !searchString || searchString.length <= 1;
        },
        update(response) {
          const {selection, itemsQueryName} = this;
          const payload = response[itemsQueryName].data;

          if (selection && payload.find((e) => e.id === selection.id) === undefined) {
            const altered = [...payload];
            altered.push(selection);
            return altered;
          }

          return payload;
        },
      },

      selection: {
        query() {
          return this.selectionQuery;
        },
        variables() {
          return {id: this.value};
        },
        skip() {
          return !this.value;
        },
        update(response) {
          return response[this.selectionQueryName];
        },
      },
    },

    watch: {
      selection(v) {
        if (v) {
          this.addItemToSelectList(v);
          //this.searchString = v.name;
        }
      },

      fetchPolicy(v) {
        this.setFetchPolicy(v);
      },
    },

    computed: {
      queryGroup() {
        const {model} = this;
        return model.charAt(0).toUpperCase() + model.slice(1);
      },

      itemsQuery() {
        return Queries[this.queryGroup].Forms.Search;
      },

      itemsQueryName() {
        return this.itemsQuery?.definitions?.[0]?.selectionSet?.selections?.[0]?.name?.value;
      },

      selectionQuery() {
        return Queries[this.queryGroup].Forms.Populate;
      },

      selectionQueryName() {
        return this.selectionQuery?.definitions?.[0]?.selectionSet?.selections?.[0]?.name?.value;
      },

      noDataTextParsed() {
        return this.parseDisplayMessage(this.noDataText);
      },

      loading() {
        return this.$apollo.queries.items.loading
      },

      hideNoData() {
        const {searchString, loading} = this;
        return loading || !searchString || searchString.length <= 1;
      },

      localPlaceholder() {
        const {placeholderParsed, isFocused} = this;
        return isFocused ? placeholderParsed : undefined;
      },
    },

    methods: {
      setFetchPolicy(fetchPolicy) {
        this.$apollo.queries.items.setOptions({
          fetchPolicy,
        });
      },

      addItemToSelectList(item, key = 'id') {
        const destination = this.items;
        if (item && Array.isArray(destination) && destination.findIndex((e) => e[key] === item[key]) === -1) {
          destination.push(item);
        }
      },

      onFocus(e) {
        this.isFocused = true;
      },

      onBlur(e) {
        this.isFocused = false;
        this.doTouch();
      },
    },

    created() {
      this.setFetchPolicy(this.fetchPolicy);
    },
  }
</script>
