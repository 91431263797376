<template>
  <div>
    <span class="text-h6">
      {{title}}
    </span>
    <form @submit.prevent="doSubmit">
      <VRow>
        <VCol>
          <VFileInput
            :error-messages="errorMessages"
            :hint="fileHint"
            :label="fileLabel"
            :show-size="1024"
            persistent-hint
            v-model="form.file"
          />
          <div class="d-flex justify-space-between mt-2">
            <KBtnSubmit :mode="mode"/>
            <VDialog max-width="600">
              <template v-slot:activator="{on}">
                <VBtn outlined color="blue lighten-2" v-on="on">
                  <FaI icon="circle-info" class="mr-2"/>
                  <span v-t="'actions.help'"/>
                </VBtn>
              </template>
              <VCard>
                <VCardTitle v-t="'views.identicon.help.title'"/>
                <VCardText>
                  <ul>
                    <li v-for="row in [10, 30, 40, 50]" v-t="`views.identicon.help.list.${row}`" :key="row"/>
                    <li>
                      <span v-t="'views.identicon.help.list.60'"/>
                      <ul>
                        <li v-for="(url, index) in ['https://getavataaars.com/', 'https://avachara.com/']" :key="index">
                          <a :href="url" target="_blank" v-t="`views.identicon.help.list.60-${index+1}_title`"/>
                          <span v-t="`views.identicon.help.list.60-${index+1}`"/>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </VCardText>
              </VCard>
            </VDialog>
          </div>
        </VCol>
        <VCol cols="auto">
          <VCard outlined>
            <VImg
              :key="url"
              :src="url"
              contain
              height="100"
              width="100"
              :class="{'thumb-background': url}"
            >
              <template v-slot:placeholder>
                <VRow align="center" class="fill-height ma-0" justify="center">
                  <FaI class="grey--text lighten-3" :icon="icon" size="3x"/>
                </VRow>
              </template>
            </VImg>
          </VCard>
        </VCol>
      </VRow>
    </form>
    <VExpandTransition>
      <div v-if="url" class="mt-4">
        <span class="text-subtitle-2" v-t="'views.identicon.preview.title'"/>
        <VContainer>
          <VRow align="end">
            <VCol v-for="size in identiconSizes" :key="size" cols="auto">
              <VAvatar :key="size" :size="size" color="grey lighten-3">
                <img :src="url" style="object-fit: cover;">
              </VAvatar>
            </VCol>
          </VRow>
        </VContainer>
      </div>
    </VExpandTransition>
  </div>
</template>

<script>
import FormMixin from "forms/elements/FormMixin.js";
import Queries from "queries/index.js";
import {images as allowed} from "elements/media/mimeTypes.js"
import {KBtnSubmit} from "forms/elements/index.js";
import {required, file, mimeType, maxFileSize} from "validators/index.js";

const allowedTypes = Object.values(allowed).map((value) => value.type);

const identiconSizes = [96, 48, 32, 24];

export default {
  mixins: [
    FormMixin,
  ],

  components: {
    KBtnSubmit,
  },

  data: () => ({
    identiconSizes,
    form: {
      file: undefined,
    },
  }),

  props: {
    target: {
      type: [Number, String],
      required: true,
    },
  },

  validations: {
    form: {
      file: {
        required,
        file,
        mimeType: mimeType(allowedTypes),
        maxFileSize: maxFileSize(10*1024*1024),
      },
    },
  },

  computed: {
    title() {
      return this.$t('forms.messages.submitNew', {type: this.$t('models.user.identicon')});
    },

    fileHint() {
      return this.$t('forms.general.maxSize', {size: '10 MB'})
    },

    fileLabel() {
      return this.$t('views.settings.identicon.file_label');
    },

    icon() {
      const {type} = this;
      return type ? Object.values(allowed).find((e) => e.type === type)?.icon || 'ban' : 'file';
    },

    errorMessages() {
      const validator = this.$v.form.file;
      if (!(validator && validator.$error)) {
        return [];
      }

      const buffer = [];
      const keys = Object.keys(validator);

      for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        if (!key.startsWith('$') && typeof validator[key] === 'boolean' && !validator[key]) {
          buffer.push(
            this.$t(
              `vuelidate.${key}`,
              Object.assign({attribute: this.fileLabel || ''}, validator.$params[key])
            )
          );
        }
      }

      return buffer;
    },

    url() {
      const {form: {file}} = this;
      const canPreview = Boolean(file && Object.values(allowed).find((e) => e.type === file.type)?.canPreview);
      return canPreview ? URL.createObjectURL(file) : undefined;
    },

    type() {
      return this.form.file?.type;
    },

    size() {
      return this.form.file?.size;
    },
  },

  methods: {
    async updateAction() {
      const {target: id, form: {file}} = this,
        variables = {
          id,
          file,
        },
        {data: {userIdenticonSet: response}} = await this.$apollo.mutate({
          mutation: Queries.User.Write.IdenticonSet,
          variables,
          context: {hasUpload: true},
        });

      return response;
    },

    formReset() {
      this.form.file = undefined;
    },
  },

  created() {
    this.$on('success', this.formReset);
  },
}
</script>
