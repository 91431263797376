<template>
  <VBtn v-if="isVisible" :color="btnColor" @click="doAction" :loading="isRunning" v-bind="$attrs">
    <FaI :icon="btnIcon"/>&nbsp;
    <span v-t="btnMsg"/>
  </VBtn>
</template>

<script>
import ClickConfirm from "elements/ClickConfirm.vue";
import Queries from 'queries/index.js';
import {mapGetters} from 'vuex';

export default {
  inheritAttrs: false,

  components: {
    ClickConfirm,
  },

  data: () => ({
    isRunning: false,
  }),

  props: {
    id: {
      type: [String, Number],
      required: true,
    },

    role: {
      type: [String, Number],
      required: true,
    },

    users: {
     type: Array,
     required: true,
    }
  },

  apollo: {
    canJoin() {
      const {role, policyChecker, id} = this;
      return policyChecker({
        policy: 'join',
        type: 'Team',
        id,
        args: {
          role,
        },
        fetchPolicy: 'cache-and-network',
      });
    },

    canLeave() {
      const {role, policyChecker, id} = this;
      return policyChecker({
        policy: 'leave',
        type: 'Team',
        id,
        args: {
          role,
        },
        fetchPolicy: 'cache-and-network',
      });
    },
  },

  computed: {
    ...mapGetters('session', ['meId']),

    userHasRole() {
      return this.users.findIndex((e) => e.id === this.meId) !== -1;
    },

    isVisible() {
      const {canLeave, userHasRole, canJoin} = this;
      return userHasRole ? canLeave : canJoin;
    },

    btnColor() {
      return `${this.userHasRole ? 'red' : 'blue'} darken-1`;
    },

    btnMsg() {
      return `actions.${this.userHasRole ?  'leave' : 'join'}`;
    },

    btnIcon() {
      return `right-${this.userHasRole ? 'from' : 'to'}-bracket`;
    },

    variables() {
      return {
        user: this.meId,
        team: this.id,
        role: this.role,
      };
    },
  },

  methods: {
    async doAction() {
      this.isRunning = true;
      try {
        this.userHasRole ? await this.doLeave() : await this.doJoin();
      } finally {
        this.isRunning = false;
      }
    },

    async doJoin() {
      const {id, meId, role} = this;
      return await this.$apollo.mutate({
        mutation: Queries.Team.Write.AttachUsers,
        variables: {
          id,
          input: [{
            user: meId,
            role,
          }],
        },
        // update: (response) => {
        //   this.refetchQueries();
        // },
      });
    },

    async doLeave() {
      const {id, meId, role} = this;
      return await this.$apollo.mutate({
        mutation: Queries.Team.Write.DetachUsers,
        variables: {
          id,
          input: [{
            user: meId,
            role,
          }],
        },
        // update: (response) => {
        //   this.refetchQueries();
        // },
      });
    },

    // refetchQueries() {
    //   const queries = ['userHasRole', 'canJoin', 'canLeave'];
    //   queries.forEach((name) => this.$apollo.queries[name].refetch());
    // },
  },
}
</script>
