import Queries from 'queries/index.js';
import vuex from 'store/index.js';

const getSessionUserId = () => vuex.getters['session/meId'];

const getDurationDates = () => ({
  from: vuex.getters['session/timekeeperDurationFrom'],
  to: vuex.getters['session/today'],
});

const myActiveTimeslipUpdate = (store, payload = null) => {
  const query = Queries.Timeslip.Single.ActiveTimeslip;
  const variables = {userId: getSessionUserId()};
  let data = undefined;

  try {
    data = store.readQuery({query, variables});
  } catch (e) {
    // Ignore - means query is not downloaded and cached yet
    return;
  }

  const newData = {
    ...data,
    activeTimeslip: payload,
  };

  store.writeQuery({query, variables, data: newData});
};

const myActiveTimeslipPurge = (store, id) => {
  let data = undefined;
  const query = Queries.Timeslip.Single.ActiveTimeslip;
  const variables = {userId: getSessionUserId()};

  try {
    data = store.readQuery({query, variables});
  } catch (e) {
    // Means not cached yet, OK
    return;
  }
  const {activeTimeslip} = data;

  if (activeTimeslip && activeTimeslip.id === id) {
    const newData = {
      ...data,
      activeTimeslip: null,
    };

    store.writeQuery({query, variables, data: newData});
  }
};

const myTimeslipSuggestionsUpdate = (store, timeslip, maxlen = 10) => {
  const query = Queries.TimeslipCollection.Browse.List;
  const variables = {
    limit: maxlen,
    durationDates: getDurationDates(),
  };

  const data = store.readQuery({ query, variables });

  if (data === null) {
    // Means query hasn't been cached yet, no worries
    return;
  }

  const list = [...data.myTimeslipSuggestions];
  const {description, timeslipType, timeslipCollection: {id}, timeslipPartitions} = timeslip;
  const match = list.findIndex((i) => i.id === id);
  let item;

  if (match !== -1) {
    item = list.splice(match, 1)[0];
  } else {
    item = {
      __typename: 'TimeslipCollection',
      id,
      description,
      isFavorite: false,
      timeslipType: {
        __typename: "TimeslipType",
        id: timeslipType.id
      },
      partitions: timeslipPartitions.map(({id, weight, comment, isBillable, billingRate, project}) => ({
        id,
        __typename: "TimeslipPartition",
        weight,
        comment,
        isBillable,
        billingRate,
        project: {
          __typename: "Project",
          id: project.id,
          slug: project.slug,
        },
      })),
      duration: 0,
    };
  }

  list.splice(0, 0, item);
  list.splice(maxlen);
  const newData = {
    myTimeslipSuggestions: list,
  };

  store.writeQuery({ query, variables, data: newData });
};

export default {
  methods: {
    async timeslipStart({timeslipTypeId, description, timeslipPartitions}) {
      vuex.dispatch('session/refreshTimeZone');
      const timeZone = vuex.state.session.timeZone;
      const variables = {
        description,
        timeZone,
        timeslipTypeId,
        timeslipPartitions,
      };
      return this.$apollo.mutate({
        mutation: Queries.Timeslip.Write.Start,
        variables,
        update: (store, {data: {timeslipStart: response}}) => {
          myActiveTimeslipUpdate(store, response);
          myTimeslipSuggestionsUpdate(store, response);
        },
      });
    },

    async timeslipStop(stopAt = undefined, stopAtTimeZone = undefined) {
      vuex.dispatch('session/refreshTimeZone');
      const timeZone = stopAtTimeZone ?? vuex.state.session.timeZone;
      const variables = {
        timeZone,
        durationDates: getDurationDates(),
        ...stopAt && {stopAt},
      };
      return this.$apollo.mutate({
        mutation: Queries.Timeslip.Write.Stop,
        variables,
        update: (store) => {
          myActiveTimeslipUpdate(store, null);
        },
      });
    },

    async timeslipCancel(silent = false) {
      const response = await this.$apollo.mutate({
        mutation: Queries.Timeslip.Write.Cancel,
        update: (store, {data: {timeslipCancel: {id}}}) => {
          myActiveTimeslipPurge(store, id);
        },
      });

      if (!silent) {
        vuex.dispatch('flash/warning', {text: 'views.timekeeper.onCancel', args: {project: response.data.timeslipCancel.project.name}});
      }

      return response;
    },

    async timeslipCreate({date, duration, description, startAt, startAtTimeZone, stopAt, stopAtTimeZone, userId = null, timeslipTypeId, timeslipPartitions}) {
      const variables = {
        date,
        duration,
        userId: userId ?? getSessionUserId(),
        timeslipTypeId,
        timeslipPartitions,
        ...description !== undefined && {description},
        ...startAt && {startAt},
        ...startAtTimeZone && {startAtTimeZone},
        ...stopAt && {stopAt},
        ...stopAtTimeZone && {stopAtTimeZone},
      };
      const {data, errors} = await this.$apollo.mutate({
        mutation: Queries.Timeslip.Write.Create,
        variables,
        update: (store, {data: {timeslipCreate: response}}) => {
          myTimeslipSuggestionsUpdate(store, response);
        },
      });

      if (errors) {
        return undefined;
      }

      return data.timeslipCreate;
    },

    async timeslipUpdate({id, date, duration, description, startAt, startAtTimeZone, stopAt, stopAtTimeZone, timeslipTypeId, timeslipPartitionsCreate, timeslipPartitionsUpdate, timeslipPartitionsDelete}) {
      const variables = {
        id,
        ...date && {date},
        ...duration && {duration},
        ...timeslipTypeId && {timeslipTypeId},
        ...description !== undefined && {description},
        ...startAt !== undefined && {startAt},
        ...startAtTimeZone !== undefined && {startAtTimeZone},
        ...stopAt !== undefined && {stopAt},
        ...stopAtTimeZone !== undefined && {stopAtTimeZone},
        ...timeslipPartitionsCreate && {timeslipPartitionsCreate},
        ...timeslipPartitionsUpdate && {timeslipPartitionsUpdate},
        ...timeslipPartitionsDelete && {timeslipPartitionsDelete},
      };
      const {data, errors} = await this.$apollo.mutate({
        mutation: Queries.Timeslip.Write.Update,
        variables,
        update: (store, {data: {timeslipUpdate: response}}) => {
          myTimeslipSuggestionsUpdate(store, response);
        },
      });

      if (errors) {
        return undefined;
      }

      return data.timeslipUpdate;
    },

    async timeslipDelete(id) {
      const variables = {
          id,
        },
        {data, errors} = await this.$apollo.mutate({
          mutation: Queries.Timeslip.Write.Delete,
          variables,
          update: (store, {data: {timeslipDelete: {id}}}) => {
            myActiveTimeslipPurge(store, id);
          },
        });

      if (errors) {
        return undefined;
      }

      return data.timeslipDelete;
    },
  },
}
