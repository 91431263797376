<template>
  <VExpansionPanels>
    <VExpansionPanel>
      <VExpansionPanelHeader>
        <VCard flat>
          <div v-if="!hasDevices">
            <FaI icon="exclamation-triangle"/>
            <strong v-t="'inspection.inspectionArea.whereAreYou'"/>
          </div>
          <VLayout v-else>
            <VFlex class="mr-2" shrink>
              <FaI class="grey--text text--darken-1" icon="location" size="lg"/>
            </VFlex>
            <VFlex grow>
              <VLayout justify-space-between>
                <span v-if="currentInspectionArea">{{currentInspectionArea.name}}</span>
                <em v-else v-t="'inspection.inspectionArea.notSelected'"/>
                <div>
                  <span v-t="{path: 'models.device.self', choice: devicesCount || 1}"/>: {{devicesIdent}}
                </div>
              </VLayout>
            </VFlex>
          </VLayout>
        </VCard>
      </VExpansionPanelHeader>
      <VExpansionPanelContent>
        <KSelect
          :items="inspectionAreasFiltered"
          :value="inspectionArea"
          :label="{path: 'models.inspectionArea.self', choice: 1}"
          @input="$emit('update:inspectionArea', $event)"
          clearable
        />

        <KSelect
          :items="devices"
          :label="{path: 'models.device.self', choice: 2}"
          chips
          clearable
          multiple
          :value="currentDevices"
          @input="onCurrentDevicesChange"
        />
      </VExpansionPanelContent>
    </VExpansionPanel>
  </VExpansionPanels>
</template>

<script>
  import Queries from 'queries/index.js'
  import {KApiSelect, KSelect} from "forms/elements/index.js"

  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base'
  }).compare;

  export default {
    components: {
      KApiSelect,
      KSelect,
    },

    props: {
      devices: {
        type: Array,
        required: true,
      },

      inspectionArea: [Number, String],

      currentDevices: Array,
    },

    apollo: {
      inspectionAreas: {
        query: Queries.InspectionArea.Forms.Select,
      },
    },

    computed: {
      inspectionAreasFiltered() {
        const allowed = [
          '2', // Cab
          '3', // Lobby
          '4', // Machine Room
        ];

        return this.inspectionAreas?.filter((e) => allowed.includes(e.id)) ?? [];
      },

      devicesCount() {
        return this.currentDevices.length ?? 0;
      },

      devicesIdent() {
        return this.currentDevices.join(', ');
      },

      hasDevices() {
        return this.devicesCount !== 0;
      },

      currentInspectionArea() {
        return (this.inspectionArea && this.inspectionAreas) ? this.inspectionAreas.find((e) => e.id === this.inspectionArea) : undefined;
      },
    },

    methods: {
      onCurrentDevicesChange(list) {
        this.$emit('update:currentDevices',  list.toSorted(collator));
      }
    },
  }
</script>
