<template>
  <NowLoading v-if="$apollo.loading"/>
  <VList v-else>
    <VListItem two-line>
      <VListItemAction>
        <FaI size="lg" icon="map-location" color="grey lighten-1"></FaI>
      </VListItemAction>
      <VListItemContent>
        <VListItemTitle>
          {{office.contact.location.street}}
        </VListItemTitle>
        <VListItemSubtitle class="text--primary">
          {{office.contact.location.city.name}}, {{office.contact.location.countryDivision.code}}  {{office.contact.location.postalCode}}
        </VListItemSubtitle>
      </VListItemContent>
    </VListItem>
  </VList>
</template>

<script>
  import Queries from 'queries/index.js'

  export default {
    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },

    apollo: {
      office: {
        query: Queries.Office.Single.Overview,
        variables() {
          return {
            id: this.id,
          };
        },
      },
    },
  }
</script>
