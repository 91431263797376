<template>
  <VContainer>
    <ExpandPanelBrowser title="views.projectRecords.title" query="Project.Browse.ProjectRecordsFastFillList" :bus="bus">
      <template #filter="{setVariables, setIsFiltered, showFilter}">
        <ProjectsFilter @input="setVariables" :defaults="defaults" :disables="disables" @filtered="setIsFiltered" :show-filter="showFilter" sort-default="RECORD_DUE_AT" :order-desc-default="false" hide-search/>
      </template>
      <template #item="{item, index}">
        <ProjectForRecordUpdateItem :id="item.id" :key="item.id" :eager="index === 0" @success="onSuccess"/>
      </template>
    </ExpandPanelBrowser>
  </VContainer>
</template>

<script>
import ExpandPanelBrowser from "elements/list/ExpandPanelBrowser.vue";
import ProjectsFilter from "../projects/browse/elements/ProjectsFilter.vue";
import ProjectForRecordUpdateItem from "./ProjectForRecordUpdateItem.vue";
import mitt from 'mitt';
import {mapGetters} from "vuex";
import {addYears, parseISO} from "date-fns";
import {formatISODate} from "top/date-fns-format.js";

export default {
  data: () => ({
    panel: undefined,
    bus: mitt(),
  }),

  components: {
    ExpandPanelBrowser,
    ProjectsFilter,
    ProjectForRecordUpdateItem,
  },

  computed: {
    ...mapGetters('session', ['meId', 'today']),

    defaults() {
      return {
        userId: this.meId,
        roleId: '9', // ProjectLead,
        recordDueBy: this.recordDueByDate,
      };
    },

    disables() {
      return {
        userId: true,
        roleId: true,
        recordDueBy: true,
      };
    },

    recordDueByDate() {
      return formatISODate(addYears(parseISO(this.today), 1));
    },
  },

  methods: {
    onSuccess(response) {
      this.bus.emit('collapse');
      this.bus.emit('refetch');
    },
  },
}
</script>
