import {createAvatar} from '@dicebear/avatars';
import * as sprites from '@dicebear/avatars-initials-sprites';

const avatarCache = {};

const generateAvatar = (seed) => {
  if (!avatarCache.hasOwnProperty(seed)) {
    const svg = createAvatar(sprites, {seed});
    const blob = new Blob([svg], {type: 'image/svg+xml'});
    avatarCache[seed] = URL.createObjectURL(blob);
  }

  return avatarCache[seed];
};

export default generateAvatar;
