<template>
  <VCard>
    <VCardTitle v-t="'models.projectRecord.deviceCount.self'"/>
    <VCardText>
      <VRow>
        <VCol cols="6" sm="6">
          <KTextField
            :validator="$v.form.elevators"
            hint="forms.general.whenKnown"
            label="models.projectRecord.deviceCount.elevators"
            v-model.number="form.elevators"
            type="number"
          />
        </VCol>
        <VCol cols="6" sm="6">
          <KTextField
            :validator="$v.form.escalators"
            hint="forms.general.whenKnown"
            label="models.projectRecord.deviceCount.escalators"
            v-model.number="form.escalators"
            type="number"
          />
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>

<script>
import {KTextField, scopedFormsMixin} from "forms/elements/index.js";
import {between, integer} from "validators/index.js";

export default {
  mixins: [
    scopedFormsMixin,
  ],

  components: {
    KTextField,
  },

  data: () => ({
    form: {
      elevators: null,
      escalators: null,
    },
  }),

  validations: {
    form: {
      elevators: {
        integer,
        between: between(0, 9999),
      },
      escalators: {
        integer,
        between: between(0, 9999),
      },
    },
  },
}
</script>
