const IDLE = 10;

const CHANGES = 20;

const RUNNING = 35;

const SUCCESS = 40;

const ERROR = 50;

export default {
    IDLE,
    CHANGES,
    RUNNING,
    SUCCESS,
    ERROR,
};
