
\<template>
  <VSkeletonLoader type="list-item-two-line" v-if="$apollo.loading"/>
  <VListItem v-else :class="{'blue lighten-5': isActive}">
    <VListItemAction>
      <VBtn fab small :loading="isMutating" :disabled="isMutating" :color="actionColor" @click="onAction">
        <FaI :icon="actionIcon" class="white--text"/>
      </VBtn>
    </VListItemAction>
    <ActiveTimeslipDuration v-if="isActive" shadow="white" class="mr-3 font-weight-black text-h6"/>
    <VListItemContent>
      <VListItemTitle class="d-flex flex-column flex-md-row align-baseline">
        <strong class="text-truncate">
          {{project.name}}
        </strong>
        <strong class="ml-md-1">
          <small>({{project.id}})</small>
          <VBtn v-if="canViewProject" x-small icon :to="{name: 'project', params: {id: project.id, slug: project.slug}}">
            <FaI icon="up-right-from-square"/>
          </VBtn>
          <TimekeeperItemDialog v-if="multiple" :id="id"/>
        </strong>
      </VListItemTitle>
      <VListItemSubtitle class="text--primary">
        {{timeslipType.name}}
      </VListItemSubtitle>
      <VListItemSubtitle>
        <FaI
          class="orange--text text--darken-2"
          icon="file-invoice-dollar"
          v-if="isBillable"
        />
        <em class="text-truncate" v-if="item.description">
          {{item.description}}
        </em>
      </VListItemSubtitle>
    </VListItemContent>
    <VListItemAction>
      <VListItemActionText class="font-weight-bold text-body-2">
        <ActiveTimeslipDuration v-if="isActive" :base="item.duration"/>
        <span v-else>
          {{duration}}
        </span>
      </VListItemActionText>
      <VBtn icon class="mx-0" :color="item.isFavorite ? 'yellow darken-3' : 'grey lighten-1'" @click="toggleFavorite">
        <FaI :icon="item.isFavorite ? 'star' : ['far', 'star']" size="lg"/>
      </VBtn>
    </VListItemAction>
  </VListItem>
</template>

<script>
  import timekeeperActionsMixins from "top/views/timeslips/elements/timeslipActionsMixins.js";
  import ActiveTimeslipDuration from "./ActiveTimeslipDuration.vue";
import TimekeeperItemDialog from './TimekeeperItemDialog.vue';
  import Queries from 'queries/index.js';
  import getTimestampFromSeconds from "top/getTimestampFromSeconds.js";
  import {mapGetters} from 'vuex';

const timeslipPartitionVariables = ({weight, comment, isBillable, billingRate, project}) => ({
  weight,
  comment: comment ?? null,
  isBillable,
  billingRate,
  project: {connect: project.id},
});

export default {
  mixins: [
    timekeeperActionsMixins,
  ],

  components: {
    ActiveTimeslipDuration,
    TimekeeperItemDialog,
  },

  data: () => ({
    isMutating: false,
  }),

  props: {
    id: {
      type: [Number, String],
      required: true,
    },

    forceCancel: Boolean,
  },

  apollo: {
    item: {
      query: Queries.TimeslipCollection.Single.Main,
      variables() {
        const {id, today, timekeeperDurationFrom} = this;
        return {
          id,
          durationDates: {
            from: timekeeperDurationFrom,
            to: today,
          },
        };
      },
      update(response) {
        return response.timeslipCollection;
      },
    },

    activeTimeslipCollection: {
      query: Queries.Timeslip.Single.ActiveTimeslipCollection,
      variables() {
        return {userId: this.meId};
      },
      update: (response) => response?.activeTimeslip?.timeslipCollection.id || null,
    },

    project: {
      query: Queries.Project.Single.Main,
      variables() {
        return {id: this.item.partitions[0].project.id};
      },
      skip() {
        return !this.item;
      },
    },

    timeslipType: {
      query: Queries.TimeslipType.Forms.Populate,
      variables() {
        return {id: this.item.timeslipType.id};
      },
      skip() {
        return !this.item;
      },
    },

    activeTimeslip: {
      query: Queries.Timeslip.Single.ActiveTimeslip,
      variables() {
        return {userId: this.meId};
      },
    },

    canViewProject() {
      return this.policyChecker({
        policy: 'view',
        type: 'Project',
        id: this.item.partitions[0].project.id,
        skip: () => Boolean(!this.item),
      });
    },
  },

  computed: {
    ...mapGetters('session', ['meId', 'today', 'timekeeperDurationFrom']),

    isActive() {
      const {item, activeTimeslipCollection} = this;
      return (item && activeTimeslipCollection) ? item.id === activeTimeslipCollection : false;
    },

    actionIcon() {
      const {forceCancel, isActive} = this;
      if (!isActive) {
        return 'play';
      }

      if (forceCancel) {
        return 'rotate-left';
      }

      return 'stop';
    },

    actionColor() {
      const {forceCancel, isActive} = this;
      if (!isActive) {
        return 'green lighten-1';
      }

      if (forceCancel) {
        return 'orange lighten-1';
      }

      return 'red lighten-1';
    },

    duration() {
      return getTimestampFromSeconds(this.item?.duration);
    },

    multiple() {
      return this.item?.partitions?.length > 1;
    },

    isBillable() {
      const partitions = this.item?.partitions ?? [];
      return partitions.some((e) => e.isBillable);
    },
  },

  methods: {
    async onAction() {
      const {activeTimeslipCollection, isActive, item: {description, timeslipType: {id: timeslipTypeId}, partitions}, timeslipCancel, timeslipStop, timeslipStart, forceCancel} = this;

      this.isMutating = true;
      try {
        if (isActive) {
          return forceCancel ? await timeslipCancel() : await timeslipStop();
        }
        if (activeTimeslipCollection) {
          // this will batch with next query and execute in order server-side
          forceCancel ? timeslipCancel() : timeslipStop();
        }
        return await timeslipStart({timeslipTypeId, description, timeslipPartitions: partitions.map(timeslipPartitionVariables)});
      } finally {
        this.isMutating = false;
      }
    },

    async toggleFavorite() {
      const name = 'timeslipCollectionUpdate';
      const {data: {[name]: response}} = await this.$apollo.mutate({
        mutation: Queries.TimeslipCollection.Write.Update,
        variables: {
          id: this.item.id,
          isFavorite: Boolean(!this.item?.isFavorite),
        },
        optimisticResponse: {
          __typename: 'Mutation',
          [name]: {
            __typename: 'TimeslipCollection',
            id: this.item.id,
            isFavorite: Boolean(!this.item?.isFavorite),
          },
        },
      });

      const isFavorite = response.isFavorite;

      const client = this.$apollo.provider.defaultClient;
      const query = Queries.TimeslipCollection.Browse.List;
      const queryName = 'myTimeslipSuggestions';
      const variables = {
        isFavorite: true,
        limit: null,
        durationDates: {
          from: this.$store.getters["session/timekeeperDurationFrom"],
          to: this.$store.getters["session/today"],
        },
      };

      const data = client.readQuery({query: Queries.TimeslipCollection.Browse.List, variables});
      const list = [...data[queryName]];
      const timeslipCollection = this.item;

      const newList = list.filter((item) => item.id !== timeslipCollection.id);

      if (isFavorite) {
        newList.unshift({
          ...timeslipCollection,
          isFavorite: true,
        });
      }

      const newData = {
        [queryName]: newList,
      };

      client.writeQuery({query, variables, data: newData});
    },
  },
}
</script>
