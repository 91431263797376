import gql from "graphql-tag";


// Single

const Identity = gql`
  query(
    $id: ID!
  ) {
    issueEventType(
      id: $id
    ) {
      id
      name
    }
  }
`;


// Forms

const Select = gql`
  query {
    issueEventTypes{
      id
      name
    }
  }
`;

const Populate = gql`
  query(
    $id: ID!
  ) {
    issueEventType (
      id: $id
    ) {
      id
      name
    }
  }
`;


// Export

const Single = {
  Identity,
};

  const Forms = {
  Select,
  Populate,
};

export default {
  Single,
  Forms,
};
